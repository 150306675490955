import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// useeffects cleaned wef 23 jul 23
// params cleared 10 sep 23
// images preloaded 14 sep 23
// axios caught 21 sep 23
import axios from "axios";
import "../../styles/leaveRequests.css";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";

import CheckUser from "../../tools/CheckUser";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import LeaveReqModal from "../requests/LeaveReqModal";
import UserLeaveReqModal from "../requests/UserLeaveReqModal";
import MyReqsDataBox from "../requests/MyReqsDataBox";
import MgrReqsDataBox from "../requests/MgrReqsDataBox";

import Navbar from "../Navbar";
import serverURL from "../../serverURL";

import CalendarSubBar from "../calendar/CalendarSubBar";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import whiteGo from "../../img/general/whiteGo.svg";
import popupNavy from "../../img/general/popupNavy.svg";
import lateArr from "../../img/general/lateArr.svg";
import rotaClock2 from "../../img/general/rotaClock2.svg";
import formImg from "../../img/general/form.svg";
import lateImg from "../../img/general/late.svg";
import greenTick from "../../img/general/greenTick.svg";
import absentShiftIcon from "../../img/general/absentShiftIcon.svg";
import periodIcon from "../../img/general/periodIcon.svg";
import tickWhite from "../../img/general/tickWhite.svg";
import ongoingPeriod from "../../img/general/ongoingPeriod.svg";
import teamIcon from "../../img/general/teamIcon2.svg";

import TopBar from "../../components/TopBar";
import MyAbsencePerformanceDataBox from "./MyAbsencePerformanceDataBox";

const Absence = ({ isTeam }) => {
  const {
    showReqStats,
    setShowReqStats,
    setShowUserReqFromNav,
    setShowMyShiftModal,
    absencePage,
    setAbsencePage,
    setShowForm,
    pushForm,
  } = useContext(DataContext);
  const { setCalPage } = useContext(CalendarContext);
  const { mob, full, width, wide } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      showReqStats,
      setShowReqStats,
      setShowUserReqFromNav,
      setShowMyShiftModal,
      absencePage,
      setAbsencePage,
      setShowForm,
      pushForm,
      setCalPage,
      mob,
      full,
      width,
      wide,
    }),
    [
      showReqStats, //
      setShowReqStats, //
      setShowUserReqFromNav, //
      setShowMyShiftModal, //
      absencePage, //
      setAbsencePage, //
      setShowForm, //
      pushForm, //
      setCalPage, //
      mob, //
      full, //
      width, //
      wide, //
    ]
  );

  useEffect(() => {
    memoVals.setAbsencePage("shifts");
  }, []);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 11) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded, memoVals.absencePage]);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}

      {/* Additional images */}
      <img
        src={whiteGo}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popupNavy}
        alt="Popup 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lateArr}
        alt="Late Arrival"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock2}
        alt="Rota Clock 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={formImg}
        alt="Form"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lateImg}
        alt="Late"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greenTick}
        alt="Green Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={absentShiftIcon}
        alt="Absent Shift Icon"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={periodIcon}
        alt="Period Icon"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tickWhite}
        alt="Tick White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={ongoingPeriod}
        alt="Ongoing Period"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  CheckUser();

  let showReqs = useRef();
  useEffect(() => {
    if (isTeam) {
      memoVals.setCalPage("team-absence");
    } else {
      memoVals.setCalPage("absence");
    }
  }, [isTeam]);
  let [enableRank, setEnableRank] = useState(false);

  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  useEffect(() => {
    if (window.location.href.includes("forms")) {
      memoVals.setAbsencePage("forms");
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.formID) {
      memoVals.setAbsencePage("forms");
      memoVals.setShowForm((x) => {
        return { isMgr: false, formID: params.formID };
      });
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);
  // let [memoVals.absencePage, setAbsencePage] = useState("periods"); // periods or shifts
  document.title = "FlowRota - My absence";

  useEffect(() => {
    // Clean not needed
    if (
      memoVals.pushForm &&
      memoVals.pushForm.updating &&
      !memoVals.pushForm.isMgr
    ) {
      let awaiting = [];
      let remaining = [];
      forms.forEach((f) => {
        if (!f.awaiting && !f.split) {
          if (f.formID === memoVals.pushForm.formID) {
            if (memoVals.pushForm.filledIn) {
              remaining.push(memoVals.pushForm);
            } else {
              awaiting.push(memoVals.pushForm);
            }
          } else {
            if (f.filledIn) {
              remaining.push(f);
            } else {
              awaiting.push(f);
            }
          }
        }
      });

      let output = [];
      if (awaiting[0]) {
        output.push({ awaiting: true });
        awaiting.forEach((a) => {
          output.push(a);
        });
      }
      if (awaiting[0]) {
        output.push({ split: true });
      }
      if (remaining[0]) {
        remaining.forEach((r) => {
          output.push(r);
        });
      }
      setForms(output);
    }
  }, [memoVals.pushForm]);

  const { checkUserState, currencySymbol } = useContext(UserContext);

  let [absences, setAbsences] = useState([]);
  let [shifts, setShifts] = useState([]);
  let [lates, setLates] = useState([]);
  let [forms, setForms] = useState([]);
  let shortenTitle = (val) => {
    if (val) {
      if (val.length > 48) {
        return `${val.substr(0, 44)}...`;
      } else {
        return val;
      }
    }
  };
  let generateForms = React.useMemo(() => {
    return forms.map((form, i) => {
      if (form.awaiting) {
        return (
          <p className="pendingReviewFormsTitle">Forms for you to complete</p>
        );
      }

      if (form.split) {
        return <div className="toReviewSplitter"></div>;
      }

      return (
        <div
          className={`formUnitParent myFormUnit ${
            i === 0 ? "firstFormUnitParentMy" : ""
          }`}
        >
          <div
            className={`formUnitMy noAnimation ${
              !form.completedDs ? "formLeftIncompleteBorder pendClock" : ""
            } formUnitY ${memoVals.mob ? "borderRadius10" : ""}`}
            onClick={() => {
              // open form here
              memoVals.setShowForm((x) => {
                return { isMgr: false, formID: form.formID };
              });
            }}
          >
            <div className="absentShiftLeftParent">
              <div className="formLeftWrapper">
                <div className="formIconLeft">
                  <img src={formImg} alt="Form" className="formIcon" />
                </div>
                <div className="reqColBarDiv reqColBarAdjust2 divPend"></div>

                <div className="absentShiftUnitLeft">
                  <div
                    className={`absenceShiftUnitLeftUpper font14 ${
                      form.title.length > 26 ? "formTitleSmaller" : ""
                    } ${!form.filledIn ? "x18489181" : ""}`}
                  >
                    {shortenTitle(form.title)}
                  </div>
                  <div className="absenceShiftUnitLeftLower noBorder x13141423">
                    {form.filledIn ? (
                      <div className="formMgrApproved usrCompleteForm">
                        <img
                          src={tickWhite}
                          alt="Approved"
                          className="formTick"
                        />
                        Completed by you
                      </div>
                    ) : (
                      <p className="notYetFilledInMy">Not yet completed</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="absenceShiftUnitRight paddingTop5">
                <div
                  className={`absenceShiftUnitLeftUpper colourAbsenceBlue x1849781 ${
                    !form.signedByMgr ? "margiTop10" : ""
                  } flexEnd`}
                >
                  {/* {form.mins ? (
                    <span className="absentShiftScheduledSpan">Absent:</span>
                  ) : (
                    ""
                  )}{" "} */}
                  <span
                    className={`absenceSpanColour ${
                      !form.mins ? "unkDurStr" : "formMinsStr"
                    }`}
                  >
                    {form.mins
                      ? dateStringer.formatMinsDurationToHours(form.mins)
                      : "Unknown duration"}
                  </span>
                </div>
                <div className="absenceShiftUnitLeftLower displayFlexEnd noBorder x13141423">
                  {form.signedByMgr ? (
                    <div className="formMgrApproved">
                      <img
                        src={tickWhite}
                        alt="Approved"
                        className="formTick"
                      />
                      Signed by {memoVals.mob ? "mgr" : "manager"}
                    </div>
                  ) : (
                    <div
                      className={`${
                        form.filledIn ? "notYetSignedOff" : "none"
                      }`}
                    >
                      Not yet signed off
                    </div>
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="itemTongue formTongue">
              <p className="tongueTxt">Form</p>
            </div>
          </div>
          {/* <div className="formUnitName">
            {form.fName} {form.lName}
          </div> */}
          {!form.filledIn ? (
            <div className="formCreatedTab">
              Issued{" "}
              {dateStringer.printedDateWithYrFromDWithoutDay(form.createdDs)}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  }, [forms, memoVals.absencePage]);
  useEffect(() => {
    // clean not needed
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.reqID) {
      memoVals.setShowUserReqFromNav(`*${params.reqID}`);
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let [selectedYear, setSelectedYear] = useState(checkUserState.currClientYear);
  let [yearsArr, setYearsArr] = useState([selectedYear]);

  let [selectedType, setSelectedType] = useState("allTypes");
  let [types, setTypes] = useState([]);

  let [showSideModal, setShowSideModal] = useState(false);

  useEffect(() => {
    // clean not needed

    if (memoVals.mob) {
      setSelectedType("allTypes");
    }
  }, [memoVals.mob]);

  // filter states
  let [allNames, setAllNames] = useState([]); // array of objects w/ fName, lName and userID

  let shortenLeaveName = (string) => {
    if (string) {
      if (memoVals.mob) {
        if (string.length > 17) {
          return `${string.substr(0, 15)}...`;
        } else {
          return string;
        }
      } else {
        if (memoVals.width < 1160) {
          if (string.length > 22) {
            return `${string.substr(0, 19)}...`;
          } else {
            return string;
          }
        } else {
          return string;
        }
      }
    }
  };

  let renderSideModal = () => {
    if (showSideModal) {
      return (
        <div
          className="reqStatsUnderlay"
          onClick={() => {
            setShowSideModal(false);
          }}
        >
          <MyAbsencePerformanceDataBox
            modal={true}
            profileSideBox={false}
            selectedYear={selectedYear}
            setSelectedYearParent={setSelectedYear}
            availableYears={yearsArr}
            setShowSideModal={setShowSideModal}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    // cleaned
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();
    const cancelSource3 = axios.CancelToken.source();
    const cancelSource4 = axios.CancelToken.source();
    const cancelSource5 = axios.CancelToken.source();

    if (!yearsArr[1]) {
      axios
        .post(
          `${serverURL}/get-available-years`,
          {
            userOnly: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setYearsArr(response.data.availableYears || []);
          }
        })
        .catch((e) => {
          console.error("ERR", e);
        });
    }
    if (memoVals.absencePage === "periods") {
      axios
        .post(
          `${serverURL}/get-absences-for-user`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: parseInt(selectedYear),
            getCosts: memoVals.mob ? false : true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token, // Pass the cancel token to the request
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // setYearsArr(response.data.yearsArr || []);
            setTypes(response.data.absenceTypes);
            setAbsences(response.data.arr);
            setEnableRank(response.data.enableRank);
            setDataLoaded(true);
          }
        })
        .catch((e) => {
          console.error("ERR", e);
        });
    }

    if (memoVals.absencePage === "shifts") {
      axios
        .post(
          `${serverURL}/get-absent-shifts-for-user`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: parseInt(selectedYear),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource3.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setShifts(response.data.arr);
            setDataLoaded(true);
            setEnableRank(response.data.enableRank);
            // setYearsArr(response.data.yearsArr || []);
          }
        })
        .catch((e) => {
          console.error("ERR", e);
        });
    }

    if (memoVals.absencePage === "late") {
      axios
        .post(
          `${serverURL}/get-late-shifts-for-user`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: parseInt(selectedYear),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource4.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setLates(response.data.arr);
            setDataLoaded(true);
            setEnableRank(response.data.enableRank);

            // setYearsArr(response.data.yearsArr || []);
          }
        })
        .catch((e) => {
          console.error("ERR", e);
        });
    }

    if (memoVals.absencePage === "forms") {
      axios
        .post(
          `${serverURL}/get-forms-for-user`,
          {
            someData: 1,
            year: parseInt(selectedYear),
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource5.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            let awaiting = [];
            let remaining = [];
            response.data.forms.forEach((f) => {
              if (f.filledIn) {
                remaining.push(f);
              } else {
                awaiting.push(f);
              }
            });

            let output = [];
            if (awaiting[0]) {
              output.push({ awaiting: true });
              awaiting.forEach((a) => {
                output.push(a);
              });
            }
            if (awaiting[0]) {
              output.push({ split: true });
            }
            if (remaining[0]) {
              remaining.forEach((r) => {
                output.push(r);
              });
            }
            setForms(output);
            setEnableRank(response.data.enableRank);

            setDataLoaded(true);
            console.log(response.data);
            // setYearsArr(response.data.yearsArr || []);
          }
        })
        .catch((e) => {
          console.error("ERR", e);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
      cancelSource5.cancel("Component unmounted");
    };
  }, [selectedYear, memoVals.absencePage, memoVals.mob]);

  useEffect(() => {
    // clean not needed
    if (showReqs.current) {
      showReqs.current.scrollTop = 0;
    }
  }, []);

  let createDate = (start, end, ongoing) => {
    if (start && end) {
      let startDate = parseInt(start.substr(9, 2));
      let startMonth = parseInt(start.substr(6, 2));
      let endDate = parseInt(end.substr(9, 2));
      let endMonth = parseInt(end.substr(6, 2));
      let yr = `'${JSON.stringify(parseInt(end.substr(1, 4))).substr(2, 2)}`;
      let startMonthOutput = "";
      if (startMonth === 0) {
        startMonthOutput = "Jan";
      } else if (startMonth === 1) {
        startMonthOutput = "Feb";
      } else if (startMonth === 2) {
        startMonthOutput = "Mar";
      } else if (startMonth === 3) {
        startMonthOutput = "Apr";
      } else if (startMonth === 4) {
        startMonthOutput = "May";
      } else if (startMonth === 5) {
        startMonthOutput = "Jun";
      } else if (startMonth === 6) {
        startMonthOutput = "Jul";
      } else if (startMonth === 7) {
        startMonthOutput = "Aug";
      } else if (startMonth === 8) {
        startMonthOutput = "Sep";
      } else if (startMonth === 9) {
        startMonthOutput = "Oct";
      } else if (startMonth === 10) {
        startMonthOutput = "Nov";
      } else if (startMonth === 11) {
        startMonthOutput = "Dec";
      }

      let endMonthOutput = "";
      if (endMonth === 0) {
        endMonthOutput = "Jan";
      } else if (endMonth === 1) {
        endMonthOutput = "Feb";
      } else if (endMonth === 2) {
        endMonthOutput = "Mar";
      } else if (endMonth === 3) {
        endMonthOutput = "Apr";
      } else if (endMonth === 4) {
        endMonthOutput = "May";
      } else if (endMonth === 5) {
        endMonthOutput = "Jun";
      } else if (endMonth === 6) {
        endMonthOutput = "Jul";
      } else if (endMonth === 7) {
        endMonthOutput = "Aug";
      } else if (endMonth === 8) {
        endMonthOutput = "Sep";
      } else if (endMonth === 9) {
        endMonthOutput = "Oct";
      } else if (endMonth === 10) {
        endMonthOutput = "Nov";
      } else if (endMonth === 11) {
        endMonthOutput = "Dec";
      }

      if (ongoing) {
        return `Started ${startDate} ${startMonthOutput}`;
      } else {
        if (startMonthOutput !== endMonthOutput) {
          return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput} ${yr}`;
        } else if (
          startMonthOutput === endMonthOutput &&
          startDate === endDate
        ) {
          return `${startDate} ${endMonthOutput} ${yr}`;
        } else {
          return `${startDate} - ${endDate} ${endMonthOutput} ${yr}`;
        }
      }
    }
  };

  let generateTypes = React.useMemo(() => {
    return types.map((t) => {
      return <option value={t.absenceTypeID}>{t.name}</option>;
    });
  }, [types]);

  let generateYearsArr = useMemo(() => {
    yearsArr.sort();
    return (
      yearsArr &&
      yearsArr.map((year) => {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
      })
    );
  }, [yearsArr]);

  let generateAbsences = React.useMemo(() => {
    return absences.map((item) => {
      if (selectedType === "allTypes" || selectedType === item.leaveTypeID) {
        let countdown = () => {
          let startDateObj = new Date(
            dateStringer.createTimestampFromString(item.reqStart)
          );

          let endDateObj = new Date(
            dateStringer.createTimestampFromString(item.reqEnd)
          );
          let todayObj = new Date();
          todayObj.setHours(0, 0, 0, 0);

          let dayDiff = Math.round(
            (startDateObj.getTime() - todayObj.getTime()) / 86400000
          );

          if (!item.ongoing && todayObj.getTime() >= endDateObj.getTime()) {
            // has ended already, or ended today
            let endedDiff = Math.round(
              (todayObj.getTime() - endDateObj.getTime()) / 86400000
            );

            if (endedDiff === 0) {
              return `Ended today`;
            } else {
              if (endedDiff === 1) {
                return `Ended yesterday`;
              } else {
                return `Ended ${endedDiff} days ago`;
              }
            }
          } else {
            if (dayDiff === 1) {
              return "Starts tomorrow";
            } else {
              if (dayDiff === 0) {
                return "Starts today";
              } else {
                if (dayDiff < 0) {
                  let val = dayDiff * -1;

                  if (val === 1) {
                    return `Started yesterday`;
                  } else {
                    return `Started ${val} days ago`;
                  }
                } else {
                  return `Starts in ${dayDiff} days`;
                }
              }
            }
          }
        };

        let durationString = () => {
          let exc = 0;
          if (item.excessMins) {
            exc = item.excessMins;
          }
          return `${dateStringer.formatMinsDurationToHours(
            item.totMins + exc,
            false
          )}`;
        };

        return (
          <div
            onClick={() => {
              memoVals.setShowUserReqFromNav(`*${item.reqID}`);
            }}
            className={`mgrReqItem ${
              item.ongoing ? "mgrReqItemPendingBorder" : ""
            } ${memoVals.mob ? "borderRadius10" : ""}`}
            key={item.reqID}
          >
            {" "}
            <img
              src={item.ongoing ? ongoingPeriod : periodIcon}
              alt="Period"
              className={`absencePeriodIcon ${
                memoVals.mob ? "absencePeriodIconMob" : ""
              }`}
            />
            <div className="reqColBarDiv divPend"></div>
            <div className="mgrReqItemCol2 absColBorder">
              <div
                className={`mgrReqTopRow ${
                  createDate(item.reqStart, item.reqEnd, item.ongoing).length >
                  14
                    ? "x819741"
                    : ""
                }`}
              >
                {/* <p className="mgrReqName">{item.leaveTypeName}</p> */}

                <div className="mgrReqName staffAbsentShiftsName">
                  {shortenLeaveName(item.absenceTypeName || "")}
                </div>

                <p
                  className={`mgrReqTypeName startsInTxtAbsence ${
                    createDate(item.reqStart, item.reqEnd, item.ongoing)
                      .length > 14
                      ? "x108401840"
                      : ""
                  }`}
                >
                  {countdown()}
                </p>
              </div>
              <div className="mgrReqBottomRow">
                <p
                  className={`mgrReqDateStrip ${
                    createDate(item.reqStart, item.reqEnd, item.ongoing)
                      .length > 14
                      ? "mgrReqDateStripLong"
                      : ""
                  }`}
                >
                  {createDate(item.reqStart, item.reqEnd, item.ongoing)}{" "}
                  {createDate(item.reqStart, item.reqEnd, item.ongoing).length >
                  14 ? (
                    <br />
                  ) : (
                    ""
                  )}
                  <span
                    className={`mgrReqDayLengthSpan ${
                      createDate(item.reqStart, item.reqEnd, item.ongoing)
                        .length > 14
                        ? "mgrReqDayLengthSpanNewLine"
                        : "x9928927492"
                    }`}
                  >
                    {item.totDays} day
                    {item.totDays !== 1 ? "s" : ""}
                  </span>
                </p>
                <div
                  className={`mgrReqDurAndCostDiv  ${
                    createDate(item.reqStart, item.reqEnd, item.ongoing)
                      .length > 14
                      ? "x1084018402"
                      : ""
                  }`}
                >
                  {item.salaried && memoVals.mob && 2 + 2 === 5 ? (
                    ""
                  ) : (
                    <p
                      className={`mgrReqDurTxt ${
                        item.ongoing ? "ongoingTab" : ""
                      } ${
                        !checkUserState.showCosts || (!item.unpaid && mob)
                          ? "marginRight0"
                          : ""
                      }`}
                      onClick={() => {
                        console.log(item);
                      }}
                    >
                      {item.ongoing ? "Ongoing" : durationString()}
                    </p>
                  )}
                  {item.unpaid ? (
                    <p className="mgrReqCostTxt unpaidCostColours">Unpaid</p>
                  ) : !checkUserState.showCosts ? (
                    ""
                  ) : item.salaried && !item.ongoing ? (
                    <p
                      className={`mgrReqCostTxt ${memoVals.mob ? "none" : ""}`}
                    >
                      Salary{" "}
                      {item.cost > 0 ? (
                        <span className="reqSalPlusSpan">
                          + {currencySymbol}
                          {item.cost}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <p
                      className={`mgrReqCostTxt ${
                        item.ongoing || memoVals.mob ? "none" : ""
                      }`}
                    >
                      {currencySymbol}
                      {Math.round(item.cost)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="itemTongue absentShiftTongue">
              <p className="tongueTxt">Absence</p>
            </div>
          </div>
        );
      } else {
        return;
      }
    });
  }, [absences, selectedType]);

  let generateShifts = React.useMemo(() => {
    return shifts.map((item) => {
      let printShiftDurationSubTxt = () => {
        let dur = 0;
        if (item.type === "shift") {
          dur = item.absenceDurMins;
        } else {
          dur = item.absenceDurMins;
        }
        return `${dateStringer.formatMinsDurationToHours(dur)}`;

        // if (
        //   // unit.type === "shift" &&
        //   item.absent &&
        //   !item.partialAbsent &&
        //   item.unpaidAbsent
        // ) {
        //   return "0 hours";
        // } else {
        //   return `${dateStringer.formatMinsDurationToHours(dur)}`;
        // }
      };

      let dateStrip = dateStringer.tsToDayAndDate(
        dateStringer.createTimestampFromString(item.start),
        true
      );

      return (
        <div
          className={`myTilShiftUnitParent ${
            item.tags[0] ? "myTilShiftUnitWithTags" : ""
          } `}
        >
          <div
            onClick={() => {
              // click();
              memoVals.setShowMyShiftModal((x) => {
                return {
                  unitType: item.type === "shift" ? "shift" : "til",
                  unitID: item.type === "shift" ? item.shiftID : item.tilID,
                };
              });
            }}
            className={`${`tilShiftUnit myTilShiftUnit ${
              memoVals.mob ? "borderRadius10" : ""
            }`} 

   `}
            key={item.shiftID || item.tilID}
          >
            {/* {unit.partialAbsent ? (
      <div
        className={`${
          unit.startTs <= tonight2359.getTime()
            ? `partialAbsentBlockWorked 
            
              `
            : `partialAbsentBlockUnWorked `
        } `}
      ></div>
    ) : (
      <div
        className={`partAbsDivPlaceholder ${
          unit.ghost ? "ghostAbsDivPlaceholderColour" : ""
        }`}
      ></div>
    )} */}
            {/* <div className={`tilShiftSection0 none`}>
      <div
        className={`${
          unit.startTs <= tonight2359.getTime() &&
          !unit.data.absent &&
          !unit.data.partialAbsent
            ? "myShiftWorkedDot"
            : `${
                unit.data.absent
                  ? `${
                      unit.data.partialAbsent
                        ? "myShiftWorkedDotAbsentPartial"
                        : "myShiftWorkedDotAbsent"
                    }`
                  : `${
                      unit.data.ghost
                        ? "myShiftGhostDot"
                        : "myShiftUnWorkedDot"
                    }`
              }
            }`
        }`}
      ></div>
    </div> */}
            <div className="myHoursLeftWrapper">
              <img
                src={absentShiftIcon}
                alt="My hours"
                className="timeHoursImg"
              />
              <div className="reqColBarDiv reqColBarAdjust1 divDec"></div>

              <div
                className={`myHoursShiftSecion1 ${
                  memoVals.mob
                    ? `mobMyHoursShiftSecion1 ${
                        item.tags.length > 1
                          ? "tagsMobMyHoursShiftsSection1"
                          : ""
                      }`
                    : ""
                }`}
              >
                <div
                  className={`tilShiftDs ${
                    printShiftDurationSubTxt() === "0m" ? "noBorder" : ""
                  }`}
                >
                  <div className="tilShiftDateAndNth">
                    {dateStringer.printedDateFromDs(item.start, true)}
                    {/* {dateStrip}
                    <span className="ndStRdSpan">
                      {dateStrip[dateStrip.length - 1] === "1"
                        ? dateStrip[dateStrip.length - 2] === "1"
                          ? "th"
                          : "st"
                        : dateStrip[dateStrip.length - 1] === "2"
                        ? "nd"
                        : dateStrip[dateStrip.length - 1] === "3"
                        ? "rd"
                        : "th"}
                    </span>{" "} */}
                  </div>
                  {/* <span
            className={`${!unit.data.ghost ? "shiftTilNameTxt" : "none"}`}
          >
            {unit.type === "shift"
              ? "Shift"
              : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`}
          </span> */}
                </div>
                {/* if til strings are needed: */}
                <div className="timesAndTagContainer x198313">
                  {/* if not a til, no til string needed, just use normal time string: */}
                  {item.partialAbsent ? (
                    <p className="tilShiftTimes colorRed2">
                      <img
                        src={rotaClock2}
                        alt="Shift times"
                        className="hoursUnitRotaClockImg"
                      />
                      {`${item.partialStart.substr(
                        12,
                        2
                      )}:${item.partialStart.substr(
                        15,
                        2
                      )} - ${item.partialEnd.substr(
                        12,
                        2
                      )}:${item.partialEnd.substr(15, 2)}`}
                    </p>
                  ) : (
                    <p className="tilShiftTimes colorRed2">
                      <img
                        src={rotaClock2}
                        alt="Shift times"
                        className="hoursUnitRotaClockImg"
                      />
                      {`${item.start.substr(12, 2)}:${item.start.substr(
                        15,
                        2
                      )} - ${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`}
                    </p>
                  )}
                  {item.unpaidAbsent ? (
                    <p className="absShUnpaid">Unpaid</p>
                  ) : (
                    <p className="absShUnpaid">Paid</p>
                  )}
                  {/* <p
                    className={`tilShiftTimes x3891974 ${
                      dateStringer.formatMinsDurationToHours(
                        item.breakMins,
                        true
                      ).length > 6
                        ? "x39142318"
                        : ""
                    }`}
                  >
                    <img
                      src={rotaCoffee2}
                      alt="Shift times"
                      className="hoursUnitRotaClockImgCoffee"
                    />

                    {dateStringer.formatMinsDurationToHours(
                      item.breakMins,
                      true
                    )}
                  </p>

                  <p className={`${item.ghost ? "tilShiftTimes" : "none"}`}>
                    Paid day off
                  </p> */}
                  {/* {unit.pTags && unit.pTags.length > 0 ? (
              <div
                className={`pTagsSplitter ${memoVals.mob ? "mobpTagsSplitter" : ""}`}
              ></div>
            ) : (
              ""
            )}
            <p
              className={`${
                unit.pTags && unit.pTags.length > 0 ? "pTagTxt" : "none"
              }`}
            >
              {formatTag(unit.pTags[0])}
            </p>
            <p
              className={`${
                unit.pTags.length > 1 ? "severalTagsTxt" : "none"
              }`}
            >
              +{unit.pTags.length - 1}
            </p> */}
                </div>
              </div>
            </div>
            <div
              className={`myHoursShiftSecion2 ${
                memoVals.mob ? "" : "desktopMyHoursShiftSection2"
              }`}
            >
              <p
                className={`tilShiftTypeTxt ${
                  item.absent
                    ? item.partialAbsent
                      ? "partialAbsentShiftDurString"
                      : "fullyAbsentShiftDurString"
                    : ""
                }
        `}
              >
                {/* {unit.type === "shift"
        ? "Shift"
        : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`} */}
                {printShiftDurationSubTxt()}
              </p>
              {/* {unit.cost ? (
          <p
            className={`myShiftsUnitDurationSubTxt ${
              unit.startTs <= tonight2359.getTime()
                ? ""
                : "unworkedShiftTilItem"
            } `}
          >
            {currencySymbol}
            {Math.round(unit.cost)}

          </p>
        ) : (
          ""
        )} */}
              <p className="myHoursUnitTeamName">
                {dateStringer.shorten(item.teamName, memoVals.mob ? 26 : 35)}
                <img src={teamIcon} alt="Team" className="teamIconAbs" />
              </p>
            </div>
            <div
              className={`itemTongue shiftTongue
    
              overtimeTongue
              ${item.ghost ? "dayOffTongue" : ""}`}
            >
              <p className={`tongueTxt shTongueTxt`}>
                {item.ghost ? "Day off" : "Hours"}
              </p>
            </div>
          </div>
          {item.tags[0] ? (
            <div
              className={`myShiftUnitTagsRow ${
                memoVals.mob ? "myShiftUnitTagsRowMobOnly" : ""
              }`}
            >
              {" "}
              <div className="usrShiftTagsContainer">
                <p className="teamShiftTagItem">{formatTag(item.tags[0])}</p>
                <p
                  className={`${
                    item.tags[1] && !memoVals.mob ? "teamShiftTagItem" : "none"
                  }`}
                >
                  {formatTag(item.tags[1])}
                </p>
                <p
                  className={`${
                    item.tags[memoVals.mob ? 1 : 2]
                      ? "teamShiftTagsSurplusIndicator"
                      : "none"
                  }`}
                >
                  +{memoVals.mob ? item.tags.length - 1 : item.tags.length - 2}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  }, [shifts]);

  let generateLates = React.useMemo(() => {
    return lates.map((item) => {
      let printShiftDurationSubTxt = () => {
        let dur = 0;
        if (item.type === "shift") {
          dur = item.lateDurMins;
        } else {
          dur = item.lateDurMins;
        }
        return `${dateStringer.formatMinsDurationToHours(dur)}`;
      };

      let dateStrip = dateStringer.tsToDayAndDate(
        dateStringer.createTimestampFromString(item.start),
        true
      );

      return (
        <div
          className={`myTilShiftUnitParent ${
            item.tags[0] ? "myTilShiftUnitWithTags" : ""
          } `}
        >
          <div
            onClick={() => {
              // click();
              memoVals.setShowMyShiftModal((x) => {
                return {
                  unitType: item.type === "shift" ? "shift" : "til",
                  unitID: item.type === "shift" ? item.shiftID : item.tilID,
                };
              });
            }}
            className={`${`tilShiftUnit myTilShiftUnit ${
              memoVals.mob ? "borderRadius10" : ""
            }`} 

   `}
            key={item.shiftID || item.tilID}
          >
            {/* {unit.partialAbsent ? (
      <div
        className={`${
          unit.startTs <= tonight2359.getTime()
            ? `partialAbsentBlockWorked 
            
              `
            : `partialAbsentBlockUnWorked `
        } `}
      ></div>
    ) : (
      <div
        className={`partAbsDivPlaceholder ${
          unit.ghost ? "ghostAbsDivPlaceholderColour" : ""
        }`}
      ></div>
    )} */}
            {/* <div className={`tilShiftSection0 none`}>
      <div
        className={`${
          unit.startTs <= tonight2359.getTime() &&
          !unit.data.absent &&
          !unit.data.partialAbsent
            ? "myShiftWorkedDot"
            : `${
                unit.data.absent
                  ? `${
                      unit.data.partialAbsent
                        ? "myShiftWorkedDotAbsentPartial"
                        : "myShiftWorkedDotAbsent"
                    }`
                  : `${
                      unit.data.ghost
                        ? "myShiftGhostDot"
                        : "myShiftUnWorkedDot"
                    }`
              }
            }`
        }`}
      ></div>
    </div> */}
            <div className="myHoursLeftWrapper">
              <img src={lateImg} alt="My hours" className="timeHoursImg" />
              <div className="reqColBarDiv reqColBarAdjust1 divDec"></div>

              <div
                className={`myHoursShiftSecion1 ${
                  memoVals.mob
                    ? `mobMyHoursShiftSecion1 ${
                        item.tags.length > 1
                          ? "tagsMobMyHoursShiftsSection1"
                          : ""
                      }`
                    : ""
                }`}
              >
                <div
                  className={`tilShiftDs ${
                    printShiftDurationSubTxt() === "0m" ? "noBorder" : ""
                  }`}
                >
                  <div className="tilShiftDateAndNth">
                    {dateStrip}
                    <span className="ndStRdSpan">
                      {dateStrip[dateStrip.length - 1] === "1"
                        ? dateStrip[dateStrip.length - 2] === "1"
                          ? "th"
                          : "st"
                        : dateStrip[dateStrip.length - 1] === "2"
                        ? "nd"
                        : dateStrip[dateStrip.length - 1] === "3"
                        ? "rd"
                        : "th"}
                    </span>{" "}
                  </div>
                  {/* <span
            className={`${!unit.data.ghost ? "shiftTilNameTxt" : "none"}`}
          >
            {unit.type === "shift"
              ? "Shift"
              : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`}
          </span> */}
                </div>
                {/* if til strings are needed: */}
                <div className="timesAndTagContainer x198313">
                  {/* if not a til, no til string needed, just use normal time string: */}

                  <p className="tilShiftTimes">
                    <img
                      src={rotaClock2}
                      alt="Shift times"
                      className="hoursUnitRotaClockImg"
                    />
                    {`${item.start.substr(12, 2)}:${item.start.substr(15, 2)}
                   
                      `}
                  </p>
                  <img src={lateArr} alt="late" className="lateArrImg" />
                  <p className="lateArrStart">
                    {dateStringer.dsToTimeStrip(item.lateStartDs, true)}
                  </p>

                  {/* {item.unpaidAbsent ? (
                    <p className="absShUnpaid">Unpaid</p>
                  ) : (
                    <p className="absShUnpaid">Paid</p>
                  )} */}
                  {/* <p
                    className={`tilShiftTimes x3891974 ${
                      dateStringer.formatMinsDurationToHours(
                        item.breakMins,
                        true
                      ).length > 6
                        ? "x39142318"
                        : ""
                    }`}
                  >
                    <img
                      src={rotaCoffee2}
                      alt="Shift times"
                      className="hoursUnitRotaClockImgCoffee"
                    />

                    {dateStringer.formatMinsDurationToHours(
                      item.breakMins,
                      true
                    )}
                  </p>

                  <p className={`${item.ghost ? "tilShiftTimes" : "none"}`}>
                    Paid day off
                  </p> */}
                  {/* {unit.pTags && unit.pTags.length > 0 ? (
              <div
                className={`pTagsSplitter ${memoVals.mob ? "mobpTagsSplitter" : ""}`}
              ></div>
            ) : (
              ""
            )}
            <p
              className={`${
                unit.pTags && unit.pTags.length > 0 ? "pTagTxt" : "none"
              }`}
            >
              {formatTag(unit.pTags[0])}
            </p>
            <p
              className={`${
                unit.pTags.length > 1 ? "severalTagsTxt" : "none"
              }`}
            >
              +{unit.pTags.length - 1}
            </p> */}
                </div>
              </div>
            </div>
            <div
              className={`myHoursShiftSecion2 ${
                memoVals.mob ? "" : "desktopMyHoursShiftSection2"
              }`}
            >
              <p
                className={`tilShiftTypeTxt fullyAbsentShiftDurString 
        `}
              >
                {/* {unit.type === "shift"
        ? "Shift"
        : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`} */}
                {printShiftDurationSubTxt()}
              </p>
              {/* {unit.cost ? (
          <p
            className={`myShiftsUnitDurationSubTxt ${
              unit.startTs <= tonight2359.getTime()
                ? ""
                : "unworkedShiftTilItem"
            } `}
          >
            {currencySymbol}
            {Math.round(unit.cost)}

          </p>
        ) : (
          ""
        )} */}
              <p className="myHoursUnitTeamName">
                {dateStringer.shorten(item.teamName, memoVals.mob ? 26 : 35)}
                <img src={teamIcon} alt="Team" className="teamIconAbs" />
              </p>
            </div>
            <div
              className={`itemTongue shiftTongue overtimeTongue ${
                item.ghost ? "dayOffTongue" : ""
              }`}
            >
              <p className={`tongueTxt shTongueTxt`}>
                {item.ghost ? "Day off" : "Hours"}
              </p>
            </div>
          </div>
          {item.tags[0] ? (
            <div
              className={`myShiftUnitTagsRow ${
                memoVals.mob ? "myShiftUnitTagsRowMobOnly" : ""
              }`}
            >
              {" "}
              <div className="usrShiftTagsContainer">
                <p className="teamShiftTagItem">{formatTag(item.tags[0])}</p>
                <p
                  className={`${
                    item.tags[1] && !memoVals.mob ? "teamShiftTagItem" : "none"
                  }`}
                >
                  {formatTag(item.tags[1])}
                </p>
                <p
                  className={`${
                    item.tags[memoVals.mob ? 1 : 2]
                      ? "teamShiftTagsSurplusIndicator"
                      : "none"
                  }`}
                >
                  +{memoVals.mob ? item.tags.length - 1 : item.tags.length - 2}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  }, [lates]);

  // MASTER RETURN
  return (
    <div className="reqsPageHolder">
      <div className="reqsPage">
        <div className="imgPreloads">
          <img src={greenTick} alt="1" className="imgPre" />
          <img src={tickWhite} alt="1" className="imgPre" />
          <img src={periodIcon} alt="1" className="imgPre" />
          <img src={lateImg} alt="1" className="imgPre" />
          <img src={formImg} alt="1" className="imgPre" />
        </div>
        <div
          className={`reqsPageUpperContainer ${
            !memoVals.mob ? "pageLeft240pxDesktop" : "noBorderBottom"
          }`}
        >
          {!memoVals.mob ? <TopBar /> : ""}
          {/* Reqs page header here */}

          <div className="shiftsHeaderContentContainer">
            <div
              className={`shiftsHeaderLeft ${memoVals.wide ? "wideTitle" : ""}`}
            >
              <div
                className={`myRequestsHeaderLeftTitle ${
                  !memoVals.mob ? "headerTitleSize myReqsTitle2" : ""
                } ${memoVals.mob ? "myRequestsHeaderLeftTitleMob" : ""} `}
                onClick={() => {}}
              >
                {`${memoVals.mob ? "My absence" : "My absence"}`}
              </div>

              <div
                className={`absenceHeaderMenuRow ${
                  memoVals.mob ? "absenceHeaderMenuMob" : ""
                }`}
              >
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.absencePage === "shifts"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.absencePage === "shifts") {
                      // nil
                    } else {
                      memoVals.setAbsencePage("shifts");
                      setDataLoaded(false);
                      setLoading(true);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">
                    {" "}
                    {memoVals.mob ? "Shifts" : "Absent shifts"}
                  </p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "shifts" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "shifts" ? "" : "none"
                    }  `}
                  ></p>
                </div>{" "}
                {/* <div className="shifHeaderMenuSplitter"></div>{" "} */}{" "}
                <div className="subHeadSplit"></div>
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.absencePage === "periods"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.absencePage === "periods") {
                      // nil
                    } else {
                      memoVals.setAbsencePage("periods");
                      setDataLoaded(false);
                      setLoading(true);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">Periods</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "periods" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "periods" ? "" : "none"
                    }  `}
                  ></p>
                </div>
                <div className="subHeadSplit"></div>
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.absencePage === "late"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    console.log("memoVals.absencePage; ", memoVals.absencePage);

                    if (memoVals.absencePage === "late") {
                      // nil
                    } else {
                      memoVals.setAbsencePage("late");
                      setDataLoaded(false);
                      setLoading(true);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">Lateness</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "late" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "late" ? "" : "none"
                    }  `}
                  ></p>
                </div>{" "}
                <div className="subHeadSplit"></div>
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.absencePage === "forms"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    console.log("memoVals.absencePage; ", memoVals.absencePage);

                    if (memoVals.absencePage === "forms") {
                      // nil
                    } else {
                      memoVals.setAbsencePage("forms");
                      setDataLoaded(false);
                      setLoading(true);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">Forms</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "forms" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "forms" ? "" : "none"
                    }  `}
                  ></p>
                </div>
                <div className="subHeadSplit"></div>
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.absencePage === "rank"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.absencePage === "rank") {
                      // nil
                    } else {
                      memoVals.setAbsencePage("rank");
                      setDataLoaded(false);
                      setLoading(true);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">My rank</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "rank" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.absencePage === "rank" ? "" : "none"
                    }  `}
                  ></p>
                </div>
              </div>
            </div>

            {checkUserState.permissions < 3 || checkUserState.indiOnly ? (
              <NavLink
                to="/staff-absence"
                onClick={() => {
                  memoVals.setCalPage("staff-absence");
                }}
                // to={`${!shiftsPage.includes("team") ? "/team-hours" : "/hours"}`}
              >
                <div className={`staffHoursBtn staffReqsBtn minWidth164`}>
                  <p className="teamHrsBtn">
                    {memoVals.full || !memoVals.full
                      ? "Staff absence"
                      : "Staff"}
                  </p>
                  <img src={whiteGo} alt="Team" className="whiteGoImg" />
                </div>
              </NavLink>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className={`requestPageParent ${
            !memoVals.mob ? "pageLeft240pxDesktop borderLeftGrey" : ""
          } `}
        >
          {" "}
          <div
            className={`desktopMaster ${memoVals.mob ? "desktopWhiteBg" : ""}`}
          >
            <div
              className={`desktopMasterMain  ${
                memoVals.mob ? "mob100Witdth" : ""
              } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
            >
              <div
                className={`requestsContainer ${
                  memoVals.mob ? "mobReqsContainerFullWidth" : ""
                }`}
              >
                {/* requests container starts */}

                <div
                  className={`reqsPageFilterHolder ${
                    !memoVals.mob ? "borderRightGrey" : "noBorderRight"
                  } ${loading ? "noBorder" : ""} `}
                >
                  <div className={`reqPageFilterBarMy`}>
                    <div
                      className={`myAbsencesFiltBar ${
                        memoVals.mob ? "mobMyReqsFilterbar" : ""
                      } ${
                        !memoVals.full && !memoVals.mob ? "minWidth312_" : ""
                      }`}
                    >
                      <div
                        className={`myAbsTopLeft ${
                          memoVals.full ? "x038141" : ""
                        }`}
                      >
                        <select
                          className={`myReqsYearDropdown`}
                          value={selectedYear}
                          onChange={(e) => {
                            if (e.target.value) {
                              setDataLoaded(false);
                              setLoading(true);

                              setSelectedYear(parseInt(e.target.value));
                              setSelectedType("allTypes");
                            }
                          }}
                        >
                          {!yearsArr.includes(selectedYear) ? (
                            <option value="">--</option>
                          ) : (
                            ""
                          )}
                          {generateYearsArr}
                        </select>
                        {/* {enableRank ? (
                          <p className="rankBtn">
                            My rank{" "}
                            <img
                              src={popup}
                              alt="Ranking"
                              className="rankPopUpImg"
                            />
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>
                      {memoVals.full ? (
                        ""
                      ) : (
                        <div
                          className={`myAbsencePerfWhiteBtn  ${
                            memoVals.mob ? "myAllowsWhiteBtnMob" : ""
                          }`}
                          onClick={() => {
                            setShowSideModal(true);
                          }}
                        >
                          {memoVals.mob ? "Performance" : "Performance"}
                          <img
                            src={popupNavy}
                            alt="Staff rota"
                            className="availPopupImg"
                          />
                        </div>
                      )}
                      {/* <p className="myAbsenceExplain">
                        {memoVals.absencePage === "periods"
                          ? "Periods of absence"
                          : memoVals.absencePage === "shifts"
                          ? "Individual absent shifts"
                          : "Shifts started late"}
                      </p> */}
                      {/* <select
                        className={`myReqsTypesDropdown ${
                          memoVals.mob ? "myReqsTypesDropdownMob" : ""
                        } ${memoVals.absencePage !== "periods" ? "invis" : ""}`}
                        value={selectedType}
                        onChange={(e) => {
                          console.log(
                            "setting selected type to: ",
                            e.target.value
                          );
                          setSelectedType(e.target.value);
                        }}
                      >
                        {" "}
                        <option value="allTypes">All types</option>
                        {generateTypes}
                      </select> */}
                    </div>
                  </div>
                </div>

                <div
                  className={`Container borderRightGrey  ${
                    loading || !memoVals.full ? "noBorder" : ""
                  }`}
                >
                  {!loading ? <div className="reqFilterSplitter"></div> : ""}
                </div>

                <div
                  ref={showReqs}
                  className={`showReqs ${
                    memoVals.mob ? "noBorder minW101pc" : ""
                  }  ${memoVals.mob ? "absBoxMob" : "absBox"} ${
                    memoVals.absencePage === "forms"
                      ? memoVals.mob
                        ? "formsShowReqsMob"
                        : "formsShowReqs"
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      !loading &&
                      memoVals.absencePage === "periods" &&
                      absences.length === 0 &&
                      selectedType === "allTypes"
                        ? `noShiftsTxtContainer noUpcomingReqs ${
                            memoVals.mob ? "mobNoShiftsTxt" : ""
                          }`
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {dataLoaded ? (
                      <p className="noShiftsTxt">
                        No periods of absence in {selectedYear}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="noShiftsSideBar"></div>
                  </div>
                  <div
                    className={`${
                      !loading &&
                      memoVals.absencePage === "late" &&
                      lates.length === 0 &&
                      selectedType === "allTypes"
                        ? `noShiftsTxtContainer noUpcomingReqs ${
                            memoVals.mob ? "mobNoShiftsTxt" : ""
                          }`
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {dataLoaded ? (
                      <p className="noShiftsTxt">
                        No shifts started late in {selectedYear}
                      </p>
                    ) : (
                      ""
                    )}

                    <div className="noShiftsSideBar"></div>
                  </div>
                  <div
                    className={`${
                      !loading &&
                      memoVals.absencePage === "shifts" &&
                      shifts.length === 0 &&
                      selectedType === "allTypes"
                        ? `noShiftsTxtContainer noUpcomingReqs ${
                            memoVals.mob ? "mobNoShiftsTxt" : ""
                          }`
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {dataLoaded ? (
                      <p className="noShiftsTxt">
                        No absent shifts in {selectedYear}
                      </p>
                    ) : (
                      ""
                    )}

                    <div className="noShiftsSideBar"></div>
                  </div>

                  <div
                    className={`${
                      !loading &&
                      memoVals.absencePage === "forms" &&
                      forms.length === 0
                        ? `noShiftsTxtContainer noUpcomingReqs ${
                            memoVals.mob ? "mobNoShiftsTxt" : ""
                          }`
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {dataLoaded ? (
                      <p className="noShiftsTxt">
                        No absence forms in {selectedYear}
                      </p>
                    ) : (
                      ""
                    )}

                    <div className="noShiftsSideBar"></div>
                  </div>
                  {/* LOADING */}
                  {loading ? (
                    <img
                      src={horizontalBalls}
                      alt="loading"
                      className="blueLoadingReqs"
                    />
                  ) : memoVals.absencePage === "periods" ? (
                    generateAbsences
                  ) : memoVals.absencePage === "shifts" ? (
                    generateShifts
                  ) : memoVals.absencePage === "late" ? (
                    generateLates
                  ) : (
                    generateForms
                  )}
                  {/* END OF LOADING */}
                </div>
              </div>
            </div>
            {memoVals.full ? (
              <div className="desktopMasterSecondary">
                <MyAbsencePerformanceDataBox
                  modal={false}
                  profileSideBox={true}
                  selectedYear={selectedYear}
                  setSelectedYearParent={setSelectedYear}
                  availableYears={yearsArr}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of requests */}

        {/* Stats modal */}
        {showReqStats === "myStats" ? (
          <div
            className="reqStatsUnderlay"
            onClick={(e) => {
              memoVals.setShowReqStats("");
            }}
          >
            <MyReqsDataBox
              modal={true}
              setShowAllows={memoVals.setShowReqStats}
              selectedYear={selectedYear}
              setSelectedYearParent={setSelectedYear}
            />
          </div>
        ) : (
          ""
        )}

        {renderSideModal()}

        {/* mgr Stats modal */}
        {showReqStats === "mgr" ? (
          <div
            className="reqStatsUnderlay"
            onClick={(e) => {
              memoVals.setShowReqStats("");
            }}
          >
            <MgrReqsDataBox modal={true} allNames={allNames} />
          </div>
        ) : (
          ""
        )}

        {/* end of stats modals */}

        <Navbar />
        {memoVals.mob && <CalendarSubBar />}
      </div>

      {imgPreload}
    </div>
  );
};

export default Absence;
