import React, { useContext, useState, useEffect, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import dateStringer from "../../../tools/dateStringer";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { UserContext } from "../../../contexts/UserContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";

import serverURL from "../../../serverURL";
import edit from "../../../img/general/editGrey.svg";

import navyBin from "../../../img/general/navyBin.svg";
import popup from "../../../img/general/popup.svg";
import editUserEdit1 from "../../../img/general/editUserEdit1.svg";
import whiteAddThin from "../../../img/general/whiteAddThin.svg";
import generate from "../../../img/general/generate.svg";
import payDayArr from "../../../img/general/payDayArr.svg";
import tickWhite from "../../../img/general/tickWhite.svg";
import bin1 from "../../../img/general/bin1.svg";

import checkbox from "../../../img/general/navyTick.svg";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

const General = () => {
  const {
    country,
    setCountry,
    setCurrencySymbol,
    currencySymbol,
    checkUserState,
    setCheckUserState,
  } = useContext(UserContext);

  const { setAccNav, mob } = useContext(StyleContext);

  const {
    setSelectedNav,
    setIndicate,
    viewZoneModal,
    setViewZoneModal,
    gpsArray,
    setGpsArray,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      country,
      setCountry,
      setCurrencySymbol,
      checkUserState,
      setCheckUserState,
      setAccNav,
      mob,
      setSelectedNav,
      setIndicate,
      viewZoneModal,
      setViewZoneModal,
      gpsArray,
      setGpsArray,
      currencySymbol,
    }),
    [
      country,
      setCountry,
      setCurrencySymbol,
      checkUserState,
      setCheckUserState,
      setAccNav,
      mob,
      setSelectedNav,
      setIndicate,
      viewZoneModal,
      setViewZoneModal,
      gpsArray,
      setGpsArray,
      currencySymbol,
    ]
  );

  let [enableGPS, setEnableGPS] = useState(false);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
    memoVals.setSelectedNav((x) => 5.1);
  }, []);
  let [updateGeneral, setUpdateGeneral] = useState(false);
  let [toggleEditOrg, setToggleEditOrg] = useState(false);
  let [toggleEditCountry, setToggleEditCountry] = useState(false);
  let [usersCanSeeCost, setUsersCanSeeCost] = useState(false);
  let [authSupport, setAuthSupport] = useState(false);
  let [weeklyMonthly, setWeeklyMonthly] = useState("monthly");
  let [every, setEvery] = useState(1);
  let [on, setOn] = useState(1);
  let [payDayWeekDay, setPayDayWeekDay] = useState(5);
  let [monthlySameOrDiff, setMonthlySameOrDiff] = useState("same");
  let [everyNum, setEveryNum] = useState(24);
  let [everyDay, setEveryDay] = useState(5);
  let [payDays, setPayDays] = useState([]);
  let [weeklyStartDs, setWeeklyStartDs] = useState("");
  let [weeklyStartOptions, setWeeklyStartOptions] = useState([]);
  let [beforeOrAfter, setBeforeOrAfter] = useState("before");
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [showEarnings, setShowEarnings] = useState(false);
  let [avoidWeekend, setAvoidWeekend] = useState(false);
  let [avoidBankHol, setAvoidBankHol] = useState(false);
  let [showStarting, setShowStarting] = useState(false);
  let [showPayDays, setShowPayDays] = useState(false);
  let [showGenPayDays, setShowGenPayDays] = useState(false);
  let [editingPayDayDs, setEditingPayDayDs] = useState("");
  let [newPayDayDateInput, setNewPayDayDateInput] = useState("");
  let [addPayDay, setAddPayDay] = useState(false);
  useEffect(() => {
    if (editingPayDayDs) {
      setEditingPayDayDs("");
    }
    if (newPayDayDateInput) {
      setEditingPayDayDs("");
    }
  }, [payDays]);

  useEffect(() => {
    // jamie
    if (
      weeklyMonthly === "weekly" &&
      every &&
      typeof payDayWeekDay === "number" &&
      !isNaN(payDayWeekDay)
    ) {
      // payDayVal = every XX weeks
      // payDayWeekDay = mon/1 tue/2 etc
      // in js, create me a function that takes in two parameters (weeks and dayNumber). It should return an array of date objects of all dates that fall on the dayNumber (ie dayNumber 1 is a monday and 0 is a sunday). It the dates should be no later than today, and the  quantity of dates in the output should equal to the weeks number provided in the first argument.
      function getDatesOfWeekdays(weeks, dayNumber) {
        const dates = [];
        const currentDate = new Date(); // Get the current date
        const targetDay = dayNumber % 7; // Ensure dayNumber is within 0-6 range

        // Iterate backward to find the dates with the specified weekday
        for (let i = 0; i < weeks * 7; i++) {
          const date = new Date(currentDate); // Create a new date object

          // Calculate the date to check
          date.setDate(currentDate.getDate() - i);

          // Check if the date matches the target weekday and is not in the future
          if (date.getDay() === targetDay && date <= currentDate) {
            dates.push(date); // Add the date to the array
          }

          // Stop iteration if enough dates are collected
          if (dates.length === weeks) {
            break;
          }
        }

        console.log({ dates });
        return dates;
      }
      console.log({ every, payDayWeekDay });

      let output = getDatesOfWeekdays(every, payDayWeekDay);
      let dsArr = [];
      if (Array.isArray) {
        output.forEach((dateObj) => {
          dsArr.push(dateStringer.createStringFromTimestamp(dateObj.getTime()));
        });
      }
      setWeeklyStartDs(dsArr[0]);
      setWeeklyStartOptions(dsArr);
    }
  }, [every, weeklyMonthly, payDayWeekDay]);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 8) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={checkbox}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={bin1}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={tickWhite}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyBin}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={generate}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editUserEdit1}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteAddThin}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [newOrg, setNewOrg] = useState("");

  let [availableStaff, setAvailableStaff] = useState([]);

  let [companyName, setCompanyName] = useState("");

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/is-user-admin`,
        {
          data: 1,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (
          response.data.message === "success" &&
          response.data.permissions === 1
        ) {
          memoVals.setAccNav((x) => "admin");
          memoVals.setCheckUserState((x) => {
            return {
              permissions: 1,
              companyName: response.data.companyName,
            };
          });
          memoVals.setCountry((x) => response.data.country);
        } else {
          window.location.href = "/account";
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (memoVals.viewZoneModal.gpsID) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-all-available-users-on-client`,
          {
            someData: 1,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setAvailableStaff(response.data.users);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.viewZoneModal]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-general-admin-data`,
        {
          data: 1,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setCompanyName(response.data.companyName);
          setEnableGPS(response.data.enableGPS);
          memoVals.setGpsArray((x) => response.data.gpsArray);
          memoVals.setCountry((x) => response.data.country);
          setUsersCanSeeCost(response.data.usersCanSeeCost);
          setAuthSupport(response.data.authSupport);
          setWeeklyMonthly(response.data.payDayFreq);
          setEvery(response.data.payDayVal);
          setPayDayWeekDay(response.data.payDayWeekDay);
          setOn(response.data.payDayOn);
          setMonthlySameOrDiff(response.data.monthlySameOrDiff);
          setEveryNum(response.data.everyNum);
          setEveryDay(response.data.everyDay);
          setPayDays(response.data.upcomingPayDays);
          setDataLoaded(true);
          setAvoidWeekend(response.data.avoidPayDayWeekend);
          setAvoidBankHol(response.data.avoidPayDayBankHol);
          setBeforeOrAfter(response.data.beforeOrAfter);
          setShowGenPayDays(response.data.genPayDaysAvail);
          setShowEarnings(response.data.showEarnings);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [updateGeneral]);

  let shortenLocation = (val) => {
    if (val.length > 25) {
      return `${val.substr(0, 22)}...`;
    } else return val;
  };

  memoVals.gpsArray.sort((a, b) => {
    return a.tsAdded - b.tsAdded;
  });
  let populateGPSArray = memoVals.gpsArray.map((item) => {
    return (
      <div key={item.gpsID}>
        <div
          className="specialDayUnit"
          onClick={() => {
            memoVals.setViewZoneModal((x) => item.gpsID);
          }}
        >
          <span onClick={() => {}}>
            <p className="specialDayDate">{shortenLocation(item.name)}</p>
          </span>
          <img
            className="gpsZoneDeleteCrossImg"
            src={navyBin}
            alt="Delete"
            onClick={(e) => {
              e.stopPropagation();

              setSureRemoveZone(item.gpsID);
            }}
          />
        </div>
      </div>
    );
  });

  availableStaff.sort(function (a, b) {
    return a.fName.localeCompare(b.fName);
  });

  const {
    allowSwaps,
    setAllowSwaps,
    selfGenSched,
    setSelfGenSched,
    dailyRates,
    setDailyRates,
    genSchedDaysAheadQty,
    setGenSchedDaysAheadQty,
    showNextYear,
    setShowNextYear,
    beyondYearStart2,
    setBeyondYearStart2,
    monthReset,
    setMonthReset,
    monRate,
    setMonRate,
    tueRate,
    setTueRate,
    wedRate,
    setWedRate,
    thuRate,
    setThuRate,
    friRate,
    setFriRate,
    satRate,
    setSatRate,
    sunRate,
    setSunRate,
    monClosed,
    setMonClosed,
    tueClosed,
    setTueClosed,
    wedClosed,
    setWedClosed,
    thuClosed,
    setThuClosed,
    friClosed,
    setFriClosed,
    satClosed,
    setSatClosed,
    sunClosed,
    setSunClosed,
    refPeriod,
    setRefPeriod,
    weekStart,
    setWeekStart,
    clockOnPeriod,
    setClockOnPeriod,
    refPeriodMaxFarBack,
    setRefPeriodMaxFarBack,

    setPublicHolExclusions,
    setSureRemoveZone,
  } = useContext(AdminContext);

  const { setShowClosedDaysHistory } = useContext(DataContext);

  // if the page is refreshed, set the AccNav to settings
  // useEffect(() => {
  //   memoVals.setAccNav(x => "admin");
  // }, []);

  let [regenMon, setRegenMon] = useState(false);
  let [regenTue, setRegenTue] = useState(false);
  let [regenWed, setRegenWed] = useState(false);
  let [regenThu, setRegenThu] = useState(false);
  let [regenFri, setRegenFri] = useState(false);
  let [regenSat, setRegenSat] = useState(false);
  let [regenSun, setRegenSun] = useState(false);

  let [loadRegenMon, setLoadRegnMon] = useState(false);
  let [loadRegenTue, setLoadRegnTue] = useState(false);
  let [loadRegenWed, setLoadRegnWed] = useState(false);
  let [loadRegenThu, setLoadRegnThu] = useState(false);
  let [loadRegenFri, setLoadRegnFri] = useState(false);
  let [loadRegenSat, setLoadRegnSat] = useState(false);
  let [loadRegenSun, setLoadRegnSun] = useState(false);

  let [autoUnpaidLate, setAutoUnpaidLate] = useState(false);

  let updateDaysOnceRateHasChanges = (dayN) => {
    axios
      .post(
        `${serverURL}/update-shifts-and-tils-when-admin-rate-is-changed`,
        { dayN: dayN },
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (dayN === 0) {
            setRegenSun(false);
          }
          if (dayN === 1) {
            setRegenMon(false);
          }
          if (dayN === 2) {
            setRegenTue(false);
          }
          if (dayN === 3) {
            setRegenWed(false);
          }
          if (dayN === 4) {
            setRegenThu(false);
          }
          if (dayN === 5) {
            setRegenFri(false);
          }
          if (dayN === 6) {
            setRegenSat(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-schedule-admin-data`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setAllowSwaps(response.data.allowSwaps);
          setSelfGenSched(response.data.selfGenSched);
          setDailyRates(response.data.dailyRates);
          setGenSchedDaysAheadQty(response.data.genSchedDaysAheadQty);
          setShowNextYear(response.data.showNextYear);
          setBeyondYearStart2(response.data.beyondYearStart);
          setMonthReset(response.data.monthReset);
          setRefPeriod(response.data.refPeriod);
          setRefPeriodMaxFarBack(response.data.refPeriodMaxFarBack);
          setClockOnPeriod(response.data.clockOnPeriod);
          setWeekStart(response.data.weekStart);
          setPublicHolExclusions(response.data.publicHolExclusions);
          setAutoUnpaidLate(response.data.autoUnpaidLate);

          response.data.closedDays.forEach((cd) => {
            if (cd.day === 0) {
              setSunClosed(cd.closed);
            } else if (cd.day === 1) {
              setMonClosed(cd.closed);
            } else if (cd.day === 2) {
              setTueClosed(cd.closed);
            } else if (cd.day === 3) {
              setWedClosed(cd.closed);
            } else if (cd.day === 4) {
              setThuClosed(cd.closed);
            } else if (cd.day === 5) {
              setFriClosed(cd.closed);
            } else if (cd.day === 6) {
              setSatClosed(cd.closed);
            }
          });

          let filterMon = response.data.dailyRates.filter((item) => {
            return item.day === "mon";
          });
          setMonRate(filterMon[0].rate);

          let filterTue = response.data.dailyRates.filter((item) => {
            return item.day === "tue";
          });
          setTueRate(filterTue[0].rate);

          let filterWed = response.data.dailyRates.filter((item) => {
            return item.day === "wed";
          });
          setWedRate(filterWed[0].rate);

          let filterThu = response.data.dailyRates.filter((item) => {
            return item.day === "thu";
          });
          setThuRate(filterThu[0].rate);

          let filterFri = response.data.dailyRates.filter((item) => {
            return item.day === "fri";
          });
          setFriRate(filterFri[0].rate);

          let filterSat = response.data.dailyRates.filter((item) => {
            return item.day === "sat";
          });
          setSatRate(filterSat[0].rate);

          let filterSun = response.data.dailyRates.filter((item) => {
            return item.day === "sun";
          });
          setSunRate(filterSun[0].rate);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let nxtYr = "";
  let nowObj = new Date();
  if (beyondYearStart2) {
    nxtYr = nowObj.getFullYear() + 1;
  } else {
    nxtYr = nowObj.getFullYear();
  }

  let weeksToMap = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100, 101, 102, 103, 104,
  ];

  let hoursToMap = [
    0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
    8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16,
    16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5,
    24,
  ];

  let generateClockOnPeriodOptions = hoursToMap.map((num) => {
    let val = 60 * num;
    return (
      <option key={val} value={val}>
        {dateStringer.formatMinsDurationToHours(val)}
      </option>
    );
  });

  let generateRefPeriodOptions = weeksToMap.map((num) => {
    return (
      <option key={num} value={num}>
        {num}
      </option>
    );
  });

  let generateEarliestPeriodOptions = weeksToMap.map((num) => {
    if (num >= refPeriod) {
      return (
        <option key={num} value={num}>
          {num}
        </option>
      );
    }
  });

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob ? "AdminSettingsContainerMob" : "borderRightGrey"
      }`}
    >
      {/* <div className='whiteGradientAdmin'></div> */}
      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer adminRowNoSubTxt ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <p className={`settingRowTitle`}>
                Company {toggleEditOrg && window.innerWidth < 405 ? <br /> : ""}
                name
              </p>
              {toggleEditOrg ? (
                <div
                  className={`editSettingToggledDiv editSettingToggledDiv2 ${
                    memoVals.mob ? "mobSettingsToggler" : ""
                  }`}
                >
                  <input
                    type="text"
                    className={`settingInputBox ${
                      memoVals.mob ? "settingInputBoxMob" : ""
                    }`}
                    value={newOrg}
                    onChange={(e) => {
                      setNewOrg(e.target.value);
                    }}
                  ></input>
                  <p
                    className="saveSettBtn"
                    onClick={() => {
                      setToggleEditOrg(false);
                      if (newOrg !== companyName) {
                        setCompanyName(newOrg);
                        axios
                          .post(
                            `${serverURL}/change-company-name`,
                            { data: newOrg },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: "Company name changed",
                                  colour: "blue",
                                  duration: 4000,
                                };
                              });
                              setCompanyName(response.data.companyName);
                              memoVals.setCheckUserState((x) => {
                                return {
                                  companyName: response.data.companyName,
                                };
                              });
                            } else {
                              setCompanyName(
                                memoVals.checkUserState.companyName
                              );
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Save
                  </p>
                </div>
              ) : (
                <p className="settingValue">
                  {companyName}{" "}
                  <img
                    src={editUserEdit1}
                    alt="Edit"
                    className="adminEditBtnImg"
                    onClick={() => {
                      setNewOrg(companyName);
                      setToggleEditOrg(true);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          {/* ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer adminRowNoSubTxt ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <p className="settingRowTitle">Country</p>
              {toggleEditCountry ? (
                <div
                  className={`editSettingToggledDiv ${
                    memoVals.mob ? "mobSettingsToggler" : ""
                  }`}
                >
                  <select
                    className="currencyDropdown"
                    value={memoVals.country}
                    onChange={(e) => {
                      memoVals.setCountry((x) => e.target.value);

                      setToggleEditCountry(false);
                      axios
                        .post(
                          `${serverURL}/change-client-country`,
                          { data: e.target.value },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setCountry((x) => response.data.country);

                            memoVals.setCurrencySymbol(
                              (x) => response.data.currency
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Åland">Åland</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua and Barbuda">Antigua</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia and Herzegovina">Bosnia</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>

                    <option value="British Virgin Islands">B.V.I</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      C. African Rep.
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos [Keeling] Islands">
                      Cocos [Keeling] Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czechia">Czechia</option>
                    <option value="Democratic Republic of the Congo">
                      D.R.Congo
                    </option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Rep.</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>

                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>

                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Ivory Coast">Ivory Coast</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar [Burma]">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn Islands">Pitcairn Islands</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of the Congo">Rep. Congo</option>
                    <option value="Réunion">Réunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Barthélemy">Saint Barthélemy</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Martin">Saint Martin</option>
                    <option value="Saint Pierre and Miquelon">
                      Saint Pierre and Miquelon
                    </option>
                    <option value="Saint Vincent and the Grenadines">
                      Saint V, Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="São Tomé and Príncipe">São Tomé</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Sint Maarten">Sint Maarten</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and the South Sandwich Islands">
                      South Georgia
                    </option>
                    <option value="South Korea">South Korea</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad, Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">UAE</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City">Vatican City</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Wallis and Futuna">Wallis, Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <p
                    className="saveSettBtn saveSettBtnCanx"
                    onClick={() => {
                      setToggleEditCountry(false);
                    }}
                  >
                    Cancel
                  </p>
                </div>
              ) : (
                <p className="settingValue">
                  {memoVals.country}{" "}
                  <img
                    src={editUserEdit1}
                    alt="Edit"
                    className="adminEditBtnImg"
                    onClick={() => {
                      setToggleEditCountry(true);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          {/* ROW ------------------------------------------------------------------------ */}{" "}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-auth-support`,
                  { data: !authSupport },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setAuthSupport(response.data.authSupport);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Authorise support access</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Do you authorise FlowRota's support team to access your
                  account to provide better assistance?
                  {/* <br />
                  <br />
                  We take privacy extremely seriously. FlowRota's UK-based
                  support team will not amend your organisation's data without
                  your permission.
                  <br />
                  <br />
                  If disabled, we can still help - it might take a little longer
                  to reach a resolution though. */}
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      authSupport ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <div className="rowWithSubText narrowRowWithSubText">
                <p className="absenceRowMainText">Week starts</p>
                <p className="absenceRowSubText overtimeRowSubText narrowerSub">
                  The first day of the week
                </p>
              </div>
              <div className="monthResetDropdownHolder">
                <select
                  value={weekStart}
                  className="monthResetDropdown"
                  onChange={(e) => {
                    axios
                      .post(
                        `${serverURL}/edit-week-start`,
                        { data: e.target.value },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setWeekStart(response.data.data);
                          console.log(response.data.data);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">Sun</option>
                  <option value="1">Mon</option>
                  <option value="2">Tue</option>
                  <option value="3">Wed</option>
                  <option value="4">Thu</option>
                  <option value="5">Fri</option>
                  <option value="6">Sat</option>
                </select>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/show-next-year-toggle`,
                  { data: !showNextYear },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setShowNextYear(response.data.data);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Make {nxtYr} available</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Make next year's calendar viewable and available for requests
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      showNextYear ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <div
                className={`rowWithSubText payDayLeftWidth ${
                  window.innerWidth < 969 ? "mobpayDayleft" : ""
                }`}
              >
                <p className="absenceRowMainText">Pay day</p>
                <p className="absenceRowSubText overtimeRowSubText narrowerSub">
                  When are your employees paid?
                </p>
              </div>
              <div className="payDaySelectorParent">
                <select
                  value={weeklyMonthly}
                  className="monthResetDropdown payDayDrop"
                  onChange={(e) => {
                    axios
                      .post(
                        `${serverURL}/pay-day-freq`,
                        {
                          weekly: e.target.value === "weekly",
                          monthly: e.target.value === "monthly",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setWeeklyMonthly(response.data.payDayFreq);
                          setPayDays(response.data.payDays || []);
                          if (response.data.payDayFreq === "weekly") {
                            setShowStarting(true);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="monthly">Monthly</option>
                  <option value="weekly">Weekly</option>
                </select>
                {weeklyMonthly === "weekly" && (
                  <div className="payDaySubSelectDiv">
                    <p className="payDaySubTxt">Every</p>
                    <select
                      value={every}
                      className="monthResetDropdown weeksPayDayDrop"
                      onChange={(e) => {
                        axios
                          .post(
                            `${serverURL}/pay-day-freq`,
                            {
                              // weekly: e.target.value === "weekly",
                              // monthly: e.target.value === "monthly",
                              payDayVal: parseInt(e.target.value),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setEvery(response.data.payDayVal);
                              setPayDays(response.data.payDays || []);
                              setShowStarting(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    <p className="payDaySubTxt">week{every === 1 ? "" : "s"}</p>
                  </div>
                )}
                {weeklyMonthly === "weekly" && (
                  <div className="payDaySubSelectDiv">
                    <p className="payDaySubTxt">On</p>
                    <select
                      value={payDayWeekDay}
                      className="monthResetDropdown payDayWeekDayDrop"
                      onChange={(e) => {
                        axios
                          .post(
                            `${serverURL}/pay-day-freq`,
                            {
                              // weekly: e.target.value === "weekly",
                              // monthly: e.target.value === "monthly",
                              // payDayVal: parseInt(e.target.value),
                              payDayWeekDay: parseInt(e.target.value),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setPayDayWeekDay(response.data.payDayWeekDay);
                              setPayDays(response.data.payDays || []);
                              setShowStarting(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">Sunday</option>
                      <option value="1">Monday</option>
                      <option value="2">Tuesday</option>
                      <option value="3">Wednesday</option>
                      <option value="4">Thursday</option>
                      <option value="5">Friday</option>
                      <option value="6">Saturday</option>
                    </select>
                  </div>
                )}
                {weeklyMonthly === "weekly" &&
                  (showStarting || !payDays[0]) && (
                    <div className="payDaySubSelectDiv">
                      <p className="payDaySubTxt">Starting</p>
                      <select
                        value={weeklyStartDs}
                        className="monthResetDropdown payDayWeekDayDrop"
                        onChange={(e) => {
                          setWeeklyStartDs(e.target.value);
                          // axios
                          //   .post(
                          //     `${serverURL}/pay-day-freq`,
                          //     {
                          //       // weekly: e.target.value === "weekly",
                          //       // monthly: e.target.value === "monthly",
                          //       // payDayVal: parseInt(e.target.value),
                          //       payDayWeekDay: parseInt(e.target.value),
                          //     },

                          //     {
                          //       withCredentials: true,
                          //       credentials: "include",
                          //     }
                          //   )
                          //   .then((response) => {
                          //     if (response.data.message === "success") {
                          //       setPayDayWeekDay(response.data.payDayWeekDay);
                          //       setPayDays(response.data.payDays || []);

                          //     }
                          //   })
                          //   .catch((err) => {
                          //     console.error(err);
                          //   });
                        }}
                      >
                        {weeklyStartOptions.map((x, i) => {
                          return (
                            <option value={x} key={i}>
                              {dateStringer.printedDateFromDs(x)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                {weeklyMonthly === "monthly" && (
                  <div className="payDaySubSelectDiv">
                    <p className="payDaySubTxt">
                      on {monthlySameOrDiff === "same" ? "the" : "a"}
                    </p>
                    <select
                      value={monthlySameOrDiff}
                      className="monthResetDropdown sameDiffDrop"
                      onChange={(e) => {
                        axios
                          .post(
                            `${serverURL}/pay-day-freq`,
                            {
                              monthlySameOrDiff: e.target.value,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setMonthlySameOrDiff(
                                response.data.monthlySameOrDiff
                              );
                              setPayDays(response.data.payDays || []);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="same">same day</option>
                      <option value="diff">different day</option>
                    </select>
                  </div>
                )}
                {weeklyMonthly === "monthly" &&
                  monthlySameOrDiff === "same" && (
                    <div className="payDaySubSelectDiv">
                      <p className="payDaySubTxt">on the</p>
                      <select
                        value={on}
                        className="monthResetDropdown monthPayDayDrop"
                        onChange={(e) => {
                          axios
                            .post(
                              `${serverURL}/pay-day-freq`,
                              {
                                payDayMonthDate: parseInt(e.target.value),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setOn(response.data.payDayOn);
                                setPayDays(response.data.payDays || []);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        <option value="1">1st</option>
                        <option value="2">2nd</option>
                        <option value="3">3rd</option>
                        <option value="4">4th</option>
                        <option value="5">5th</option>
                        <option value="6">6th</option>
                        <option value="7">7th</option>
                        <option value="8">8th</option>
                        <option value="9">9th</option>
                        <option value="10">10th</option>
                        <option value="11">11th</option>
                        <option value="12">12th</option>
                        <option value="13">13th</option>
                        <option value="14">14th</option>
                        <option value="15">15th</option>
                        <option value="16">16th</option>
                        <option value="17">17th</option>
                        <option value="18">18th</option>
                        <option value="19">19th</option>
                        <option value="20">20th</option>
                        <option value="21">21st</option>
                        <option value="22">22nd</option>
                        <option value="23">23rd</option>
                        <option value="24">24th</option>
                        <option value="25">25th</option>
                        <option value="26">26th</option>
                        <option value="27">27th</option>
                        <option value="28">28th</option>
                      </select>
                    </div>
                  )}

                {weeklyMonthly === "monthly" &&
                  monthlySameOrDiff === "diff" && (
                    <div className="payDaySubSelectDiv">
                      <p className="payDaySubTxt">every</p>
                      <select
                        value={everyNum}
                        className="monthResetDropdown everyNumDrop"
                        onChange={(e) => {
                          axios
                            .post(
                              `${serverURL}/pay-day-freq`,
                              {
                                everyNum: parseInt(e.target.value),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEveryNum(response.data.everyNum);
                                setPayDays(response.data.payDays || []);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        <option value="1">1st</option>
                        <option value="2">2nd</option>
                        <option value="3">3rd</option>
                        <option value="4">4th</option>
                      </select>
                      <select
                        value={everyDay}
                        className="monthResetDropdown monthPayDayDrop"
                        onChange={(e) => {
                          axios
                            .post(
                              `${serverURL}/pay-day-freq`,
                              {
                                everyDay: parseInt(e.target.value),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEveryDay(response.data.everyDay);
                                setPayDays(response.data.payDays || []);
                                if (
                                  response.data.everyDay === 0 ||
                                  response.data.everyDay === 6
                                ) {
                                  setAvoidWeekend(false);
                                }
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        <option value="1">Mon</option>
                        <option value="2">Tue</option>
                        <option value="3">Wed</option>
                        <option value="4">Thu</option>
                        <option value="5">Fri</option>
                        <option value="6">Sat</option>
                        <option value="0">Sun</option>
                      </select>
                    </div>
                  )}

                {weeklyMonthly === "monthly" && (
                  <div
                    className={`avoidWeekend `}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/toggle-avoid-pay-day`,
                          {
                            avoidWeekend: true,
                            data: !avoidWeekend,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setAvoidWeekend(response.data.avoidPayDayWeekend);
                            setPayDays([]);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Avoid weekends{" "}
                    <div className="avoidCheck">
                      <img
                        src={checkbox}
                        alt="Avoid weekends"
                        className={`avoidWeekendCheckImg ${
                          avoidWeekend ? "" : "invis"
                        }`}
                      />
                    </div>
                  </div>
                )}
                {weeklyMonthly === "monthly" && (
                  <div
                    className={`avoidWeekend ${
                      monthlySameOrDiff === "diff" &&
                      (everyDay === 0 || everyDay === 6) &&
                      !avoidBankHol
                        ? "none_"
                        : ""
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/toggle-avoid-pay-day`,
                          {
                            avoidBankHol: true,
                            data: !avoidBankHol,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setAvoidBankHol(response.data.avoidPayDayBankHol);
                            setPayDays([]);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Avoid {memoVals.currencySymbol !== "£" ? "public" : "bank"}{" "}
                    holidays{" "}
                    <div className="avoidCheck">
                      <img
                        src={checkbox}
                        alt="Avoid weekends"
                        className={`avoidWeekendCheckImg ${
                          avoidBankHol ? "" : "invis"
                        }`}
                      />
                    </div>
                  </div>
                )}

                {weeklyMonthly === "monthly" &&
                  (avoidBankHol || avoidWeekend) && (
                    <div className="payDaySubSelectDiv">
                      <p className="payDaySubTxt nearestTxt">
                        Re-schedule payday to the nearest week day
                      </p>
                      <select
                        value={beforeOrAfter}
                        className="monthResetDropdown beforeAfterDrop"
                        onChange={(e) => {
                          axios
                            .post(
                              `${serverURL}/toggle-avoid-pay-day`,
                              {
                                nearest: e.target.value,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setBeforeOrAfter(response.data.beforeOrAfter);
                                setPayDays([]);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        <option value="before">before</option>
                        <option value="after">after</option>
                      </select>
                    </div>
                  )}
                {(!payDays[0] || showGenPayDays) && (
                  <p
                    className="generatePayDaysBtn"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/generate-pay-days`,
                          {
                            deleteAll: false,
                            weeklyStartDs,
                            monthly: weeklyMonthly === "monthly",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setShowStarting(false);
                            setPayDays(response.data.payDays);
                            if (showGenPayDays) {
                              setShowGenPayDays(false);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Generate paydays{" "}
                    <img
                      src={generate}
                      alt="Generate"
                      className="genPayDayImg"
                    />
                  </p>
                )}
                {!payDays[0] && (
                  <p className="genpayDayInfo">
                    Generates a series of paydays between now and the end of the
                    current company year
                  </p>
                )}
                {!payDays[0] && <p className="noPays">No paydays generated</p>}
                <div className="payDaysMap">
                  {payDays[0] && (
                    <p
                      className="showPayDaysBtn"
                      onClick={() => {
                        setShowPayDays(!showPayDays);
                        if (editingPayDayDs) {
                          setEditingPayDayDs("");
                        }
                        if (newPayDayDateInput) {
                          setNewPayDayDateInput("");
                        }
                      }}
                    >
                      {payDays.length} generated payday
                      {payDays.length === 1 ? "" : "s"}{" "}
                      <img
                        src={payDayArr}
                        alt="Show pay days"
                        className={`payDayArrImg ${
                          showPayDays ? "showPayDaysActive" : ""
                        }`}
                      />
                    </p>
                  )}
                  {showPayDays &&
                    payDays
                      .sort((a, b) => {
                        return a.ts - b.ts;
                      })
                      .map((x, i) => {
                        return (
                          <div key={i}>
                            <div className="payDayUnit">
                              {editingPayDayDs === x.ds ? (
                                <input
                                  type="date"
                                  value={newPayDayDateInput}
                                  className="editPayDayDate"
                                  onChange={(e) => {
                                    setNewPayDayDateInput(e.target.value);
                                  }}
                                ></input>
                              ) : (
                                <p className="payDayTxt">
                                  {dateStringer.printedDateFromDs(x.ds, true)}
                                </p>
                              )}
                              {editingPayDayDs !== x.ds ? (
                                <img
                                  className="editPayDay"
                                  src={edit}
                                  alt="Delete"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (addPayDay) {
                                      setAddPayDay(false);
                                    }
                                    setNewPayDayDateInput(
                                      dateStringer.createDateInputFromDateString(
                                        x.ds
                                      )
                                    );
                                    setEditingPayDayDs(x.ds);
                                  }}
                                />
                              ) : (
                                <div className="savePayDayBtns">
                                  <img
                                    className="editPayDay binPayDay"
                                    src={bin1}
                                    alt="Save"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      axios
                                        .post(
                                          `${serverURL}/edit-pay-day`,
                                          { ds: x.ds, deleting: true },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            if (editingPayDayDs === x.ds) {
                                              setEditingPayDayDs("");
                                            }
                                            if (newPayDayDateInput) {
                                              setNewPayDayDateInput("");
                                            }
                                            setPayDays(
                                              payDays.filter((xx) => {
                                                return xx.ds !== x.ds;
                                              })
                                            );
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                      //
                                    }}
                                  />
                                  <img
                                    className={`editPayDay savePayDay ${
                                      newPayDayDateInput.length === 10
                                        ? ""
                                        : "disable opac04"
                                    }`}
                                    src={tickWhite}
                                    alt="Save"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      axios
                                        .post(
                                          `${serverURL}/edit-pay-day`,
                                          {
                                            ds: x.ds,
                                            newDs:
                                              dateStringer.createStringFromDateInput(
                                                newPayDayDateInput
                                              ),
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            if (editingPayDayDs === x.ds) {
                                              setEditingPayDayDs("");
                                            }
                                            if (newPayDayDateInput) {
                                              setNewPayDayDateInput("");
                                            }

                                            let newArr = [];
                                            payDays.forEach((p) => {
                                              if (p.ds === x.ds) {
                                                newArr.push({
                                                  ds: response.data.payDayItem
                                                    .ds,
                                                  ts: response.data.payDayItem
                                                    .ts,
                                                });
                                              } else {
                                                newArr.push(p);
                                              }
                                            });

                                            setPayDays(newArr);
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                      //
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  {addPayDay && (
                    <div className="payDayUnit">
                      <input
                        type="date"
                        value={newPayDayDateInput}
                        className="editPayDayDate"
                        onChange={(e) => {
                          setNewPayDayDateInput(e.target.value);
                        }}
                      ></input>

                      <div className="savePayDayBtns">
                        <img
                          className="editPayDay binPayDay invis_"
                          src={bin1}
                          alt="Save"
                          onClick={() => {
                            setAddPayDay(false);
                            setNewPayDayDateInput("");
                          }}
                        />
                        <img
                          className={`editPayDay savePayDay ${
                            newPayDayDateInput.length === 10
                              ? ""
                              : "disable opac04"
                          }`}
                          src={tickWhite}
                          alt="Save"
                          onClick={(e) => {
                            e.stopPropagation();
                            axios
                              .post(
                                `${serverURL}/edit-pay-day`,
                                {
                                  ds: dateStringer.createStringFromDateInput(
                                    newPayDayDateInput
                                  ),
                                  add: true,
                                  newDs:
                                    dateStringer.createStringFromDateInput(
                                      newPayDayDateInput
                                    ),
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  if (newPayDayDateInput) {
                                    setNewPayDayDateInput("");
                                  }

                                  let newArr = [
                                    ...payDays,
                                    {
                                      ts: response.data.payDayItem.ts,
                                      ds: response.data.payDayItem.ds,
                                    },
                                  ];

                                  setAddPayDay(false);
                                  // payDays.forEach((p) => {
                                  //   if (p.ds === x.ds) {
                                  //     newArr.push({
                                  //       ds: response.data.createPayDay.ds,
                                  //       ts: response.data.createPayDay.ts,
                                  //     });
                                  //   } else {
                                  //     newArr.push(p);
                                  //   }
                                  // });

                                  setPayDays(newArr);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                            //
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {(showPayDays || !payDays) && !addPayDay ? (
                    <p
                      className="addGPSBtn addPayDayBtn"
                      onClick={() => {
                        if (editingPayDayDs) {
                          setEditingPayDayDs("");
                        }
                        setNewPayDayDateInput(
                          dateStringer.createDateInputFromDateString(
                            dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            )
                          )
                        );
                        setAddPayDay(true);
                      }}
                    >
                      Add payday{" "}
                      <img
                        src={whiteAddThin}
                        alt="Add zone"
                        className="addZonePlus"
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-show-earnings`,
                  { data: !showEarnings },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setShowEarnings(response.data.showEarnings);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">
                  Show employees their earnings
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Allow staff to view how much they have earned, including their
                  salary, deductions, overtime pay and pay from paid leave and
                  absence.
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      showEarnings ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-swaps`,
                  { data: !allowSwaps },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then(() => {
                  axios
                    .post(
                      `${serverURL}/get-schedule-admin-data`,
                      { someData: 1 },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setAllowSwaps(response.data.allowSwaps);
                        // setSelfGenSched(response.data.selfGenSched);
                        // setDailyRates(response.data.dailyRates);
                        // setGenSchedDaysAheadQty(response.data.genSchedDaysAheadQty);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Shift swaps</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Allow staff to swap shifts with each other (within the same
                  team)
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      allowSwaps ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-users-can-see-cost`,
                  { data: !usersCanSeeCost },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setUsersCanSeeCost(response.data.usersCanSeeCost);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Show staff their earnings</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  If enabled, FlowRota will display to employees their earnings
                  from paid hours from shifts and leave. If disabled, managers
                  can view the costs of their staff - and administrators can
                  view the costs of all staff in the company.
                  <br />
                  <br />
                  This requires employees to have up to date pay data (salary or
                  hourly rate) saved in each of their profile settings.
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      usersCanSeeCost ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <div className="lookBackOnExplainerTxt">
                <p
                  className="absenceRowMainText"
                  onClick={() => {
                    console.log("ref period is: ", clockOnPeriod);
                  }}
                >
                  Clock on period
                </p>
                <p className="absenceRowSubText clockOnRefSubTxt">
                  How long before an employee's scheduled start time can they
                  clock on?
                  <br /> <br />
                  <span className="ukRequirementSpan">
                    Staff can still clock on but they won't clock onto their
                    scheduled shift - instead, a separate shift will be created.
                  </span>{" "}
                </p>
              </div>
              <div>
                <div className="monthResetDropdownHolder">
                  <select
                    value={clockOnPeriod}
                    className="clockOnPeriodDropdown"
                    onChange={(e) => {
                      setRefPeriod(e.target.value);
                      axios
                        .post(
                          `${serverURL}/edit-client-clock-on-period`,
                          { data: parseInt(e.target.value) },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setClockOnPeriod(response.data.data);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    {generateClockOnPeriodOptions}
                  </select>
                </div>
                <p className="weeksBelowRefPeriodDrop">&nbsp;</p>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
              memoVals.mob ? "clickShiftRowAdminMob" : ""
            } ${memoVals.mob ? "mobileAdminRow" : ""}`}
            onClick={() => {
              setAutoUnpaidLate(!autoUnpaidLate);
              axios
                .post(
                  `${serverURL}/toggle-unpaid-late`,
                  { data: !autoUnpaidLate },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setAutoUnpaidLate(response.data.autoUnpaidLate);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">
                  Pay employees who clocked on late
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  If an employee clocks on late for a scheduled shift, should
                  FlowRota pay them for the period between the scheduled start
                  and the clocked start?
                  <br />
                  <br />
                  If unselected, the employee will only be paid for time worked
                  from the moment they clocked on by default.
                  <br />
                  <br />
                  This setting can be changed on each shift once the clock card
                  has been approved.
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer knobContainerSettings   ">
                  <div
                    className={`switchKnob ${
                      autoUnpaidLate ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <div className="lookBackOnExplainerTxt">
                <p
                  className="absenceRowMainText"
                  onClick={() => {
                    console.log("ref period is: ", refPeriod);
                  }}
                >
                  Reference period
                </p>
                <p className="absenceRowSubText">
                  How far back should FlowRota look when calculating how much to
                  pay an employee for their leave?
                  <br />
                  <br />
                  This is only used when an employee's amount of payable leave
                  is based on their average hours per day.
                  {/* <br />
                  <br />
                  FlowRota will exclude any 7-day period whereby an employee was
                  not paid (for shifts, overtime and leave). For any excluded
                  7-day periods, FlowRota will go further beyond the reference
                  period in order to calculate the amount payable.
                  <br />
                  <br />
                  If an employee joined the company within the reference period,
                  the reference period will be shortened to the number of weeks
                  the employee has been employed.
                  <br />
                  <br /> */}
                  {memoVals.currencySymbol === "£" && <br />}{" "}
                  {memoVals.currencySymbol === "£" && <br />}{" "}
                  {memoVals.currencySymbol === "£" && (
                    <span className="ukRequirementSpan">
                      In the UK, since 2020, the law requires at least 52 weeks
                      as a reference period - unless the employee joined less
                      than 52 weeks ago.
                    </span>
                  )}
                  <br /> <br />
                  <a
                    href="https://assets.publishing.service.gov.uk/media/5f1563823a6f405c096c34f7/calculating-holiday-pay-for-worker-without-fixed-hours-or-pay.pdf"
                    target="_blank"
                    className="readMoreHere"
                  >
                    Read more here
                  </a>
                </p>
              </div>
              <div>
                <div className="monthResetDropdownHolder">
                  <select
                    value={refPeriod}
                    className="monthResetDropdown"
                    onChange={(e) => {
                      setRefPeriod(e.target.value);
                      axios
                        .post(
                          `${serverURL}/edit-client-ref-period`,
                          { data: parseInt(e.target.value) },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setRefPeriod(response.data.data);
                            setRefPeriodMaxFarBack(
                              response.data.refPeriodMaxFarBack
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    {generateRefPeriodOptions}
                  </select>
                </div>
                <p className="weeksBelowRefPeriodDrop">weeks</p>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <div className="lookBackOnExplainerTxt">
                <p
                  className="absenceRowMainText"
                  onClick={() => {
                    console.log(
                      "refPeriodMaxFarBack period is: ",
                      refPeriodMaxFarBack
                    );
                  }}
                >
                  Earliest reference
                </p>
                <p className="absenceRowSubText">
                  If an employee isn't paid for a 7-day period in the reference
                  period defined above, how far back should FlowRota go in order
                  to calculate the leave pay?
                  {memoVals.currencySymbol === "£" && <br />}
                  {memoVals.currencySymbol === "£" && <br />}
                  {memoVals.currencySymbol === "£" && (
                    <span className="ukRequirementSpan">
                      In the UK, since 2020, the law states that you can count
                      back a maximum of 104 weeks if an employee isn't paid
                      within a 7-day period of the required reference period
                    </span>
                  )}
                </p>
              </div>
              <div>
                <div className="monthResetDropdownHolder">
                  <select
                    value={refPeriodMaxFarBack}
                    className="monthResetDropdown"
                    onChange={(e) => {
                      setRefPeriodMaxFarBack(e.target.value);
                      axios
                        .post(
                          `${serverURL}/edit-ref-period-max-far-back`,
                          { data: parseInt(e.target.value) },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setRefPeriodMaxFarBack(response.data.data);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    {generateEarliestPeriodOptions}
                  </select>
                </div>
                <p className="weeksBelowRefPeriodDrop">weeks</p>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <div>
                <p className="absenceRowMainText">
                  Day rates &amp; closed days
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Define the rates of pay for each day, and whether or not your
                  company is closed. You can still schedule shifts on closed
                  days.
                </p>
                <p
                  className="closedDaysHistoryBtn"
                  onClick={() => {
                    setShowClosedDaysHistory(true);
                  }}
                >
                  History <img src={popup} className="closedDaysHistoryPopUp" />
                </p>
              </div>
            </div>
            {/* <div className="dailyRatesWrapper"> */}
            <div className="dailyRatesDataContainer">
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Monday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={monRate}
                  onChange={(e) => {
                    setRegenMon(true);
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "mon" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          axios
                            .post(
                              `${serverURL}/get-schedule-admin-data`,
                              { someData: 1 },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                // setAllowSwaps(response.data.allowSwaps);
                                // setSelfGenSched(response.data.selfGenSched);
                                setDailyRates(response.data.dailyRates);
                                // setGenSchedDaysAheadQty(response.data.genSchedDaysAheadQty);

                                let filterMon = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "mon";
                                  }
                                );
                                setMonRate(filterMon[0].rate);

                                let filterTue = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "tue";
                                  }
                                );
                                setTueRate(filterTue[0].rate);

                                let filterWed = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "wed";
                                  }
                                );
                                setWedRate(filterWed[0].rate);

                                let filterThu = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "thu";
                                  }
                                );
                                setThuRate(filterThu[0].rate);

                                let filterFri = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "fri";
                                  }
                                );
                                setFriRate(filterFri[0].rate);

                                let filterSat = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sat";
                                  }
                                );
                                setSatRate(filterSat[0].rate);

                                let filterSun = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sun";
                                  }
                                );
                                setSunRate(filterSun[0].rate);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <div className="openClosedTxtContainer">
                    <p
                      className="closedDayTxt"
                      onClick={() => {
                        let today = new Date();
                        today.setHours(0, 0, 0, 0);
                        axios
                          .post(
                            `${serverURL}/edit-closed-day`,
                            {
                              day: 1,
                              data: !monClosed,
                              ds: dateStringer.createStringFromTimestamp(
                                today.getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data) {
                              if (response.data.message === "success") {
                                setMonClosed(!monClosed);
                              }
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >{`${monClosed ? "Closed" : "Open"}`}</p>
                  </div>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 1,
                          data: !monClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setMonClosed(!monClosed);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !monClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="regenUpdateParent">
                <div
                  className={`${regenMon ? "regenDayRatesContainer" : "none"}`}
                >
                  <p className="updateRateShiftsTxt">
                    Update all shifts that fall on a{" "}
                    <span className="rateDaySpanBold">Monday</span> (from today)
                    with the new pay rate of{" "}
                    <span className="rateDaySpanBold">{monRate}x</span>
                  </p>
                  <p
                    className="regenRateBtn"
                    onClick={() => {
                      updateDaysOnceRateHasChanges(1);
                      setRegenMon(false);
                      setLoadRegnMon(true);
                      setTimeout(() => {
                        setLoadRegnMon(false);
                      }, 3000);
                    }}
                  >
                    Update
                  </p>
                </div>
                <div
                  className={`${
                    loadRegenMon && !regenMon ? "loadingRegenDay" : "none"
                  }`}
                >
                  Monday shifts updated
                </div>
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow ">
                <p className="dailyPayRateTxt">Tuesday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={tueRate}
                  onChange={(e) => {
                    setRegenTue(true);
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "tue" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          axios
                            .post(
                              `${serverURL}/get-schedule-admin-data`,
                              { someData: 1 },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                // setAllowSwaps(response.data.allowSwaps);
                                // setSelfGenSched(response.data.selfGenSched);
                                setDailyRates(response.data.dailyRates);
                                // setGenSchedDaysAheadQty(response.data.genSchedDaysAheadQty);

                                let filterMon = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "mon";
                                  }
                                );
                                setMonRate(filterMon[0].rate);

                                let filterTue = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "tue";
                                  }
                                );
                                setTueRate(filterTue[0].rate);

                                let filterWed = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "wed";
                                  }
                                );
                                setWedRate(filterWed[0].rate);

                                let filterThu = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "thu";
                                  }
                                );
                                setThuRate(filterThu[0].rate);

                                let filterFri = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "fri";
                                  }
                                );
                                setFriRate(filterFri[0].rate);

                                let filterSat = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sat";
                                  }
                                );
                                setSatRate(filterSat[0].rate);

                                let filterSun = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sun";
                                  }
                                );
                                setSunRate(filterSun[0].rate);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 2,
                            data: !tueClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setTueClosed(!tueClosed);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${tueClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 2,
                          data: !tueClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setTueClosed(!tueClosed);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !tueClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="regenUpdateParent">
                <div
                  className={`${regenTue ? "regenDayRatesContainer" : "none"}`}
                >
                  <p className="updateRateShiftsTxt">
                    Update all shifts that fall on a{" "}
                    <span className="rateDaySpanBold">Tuesday</span> (from
                    today) with the new pay rate of{" "}
                    <span className="rateDaySpanBold">{tueRate}x</span>
                  </p>
                  <p
                    className="regenRateBtn"
                    onClick={() => {
                      updateDaysOnceRateHasChanges(2);
                      setRegenTue(false);
                      setLoadRegnTue(true);
                      setTimeout(() => {
                        setLoadRegnTue(false);
                      }, 3000);
                    }}
                  >
                    Update
                  </p>
                </div>{" "}
                <div
                  className={`${
                    loadRegenTue && !regenTue ? "loadingRegenDay" : "none"
                  }`}
                >
                  Tuesday shifts updated
                </div>
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Wednesday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={wedRate}
                  onChange={(e) => {
                    setRegenWed(true);
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "wed" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          axios
                            .post(
                              `${serverURL}/get-schedule-admin-data`,
                              { someData: 1 },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setAllowSwaps(response.data.allowSwaps);
                                setSelfGenSched(response.data.selfGenSched);
                                setDailyRates(response.data.dailyRates);

                                let filterMon = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "mon";
                                  }
                                );
                                setMonRate(filterMon[0].rate);

                                let filterTue = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "tue";
                                  }
                                );
                                setTueRate(filterTue[0].rate);

                                let filterWed = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "wed";
                                  }
                                );
                                setWedRate(filterWed[0].rate);

                                let filterThu = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "thu";
                                  }
                                );
                                setThuRate(filterThu[0].rate);

                                let filterFri = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "fri";
                                  }
                                );
                                setFriRate(filterFri[0].rate);

                                let filterSat = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sat";
                                  }
                                );
                                setSatRate(filterSat[0].rate);

                                let filterSun = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sun";
                                  }
                                );
                                setSunRate(filterSun[0].rate);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 3,
                            data: !wedClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setWedClosed(!wedClosed);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${wedClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}

                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 3,
                          data: !wedClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setWedClosed(!wedClosed);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !wedClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="regenUpdateParent">
                <div
                  className={`${regenWed ? "regenDayRatesContainer" : "none"}`}
                >
                  <p className="updateRateShiftsTxt">
                    Update all shifts that fall on a{" "}
                    <span className="rateDaySpanBold">Wednesday</span> (from
                    today) with the new pay rate of{" "}
                    <span className="rateDaySpanBold">{wedRate}x</span>
                  </p>
                  <p
                    className="regenRateBtn"
                    onClick={() => {
                      updateDaysOnceRateHasChanges(3);
                      setRegenWed(false);
                      setLoadRegnWed(true);
                      setTimeout(() => {
                        setLoadRegnWed(false);
                      }, 3000);
                    }}
                  >
                    Update
                  </p>
                </div>{" "}
                <div
                  className={`${
                    loadRegenWed && !regenWed ? "loadingRegenDay" : "none"
                  }`}
                >
                  Wednesday shifts updated
                </div>
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Thursday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={thuRate}
                  onChange={(e) => {
                    setRegenThu(true);
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "thu" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          axios
                            .post(
                              `${serverURL}/get-schedule-admin-data`,
                              { someData: 1 },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setAllowSwaps(response.data.allowSwaps);
                                setSelfGenSched(response.data.selfGenSched);
                                setDailyRates(response.data.dailyRates);

                                let filterMon = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "mon";
                                  }
                                );
                                setMonRate(filterMon[0].rate);

                                let filterTue = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "tue";
                                  }
                                );
                                setTueRate(filterTue[0].rate);

                                let filterWed = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "wed";
                                  }
                                );
                                setWedRate(filterWed[0].rate);

                                let filterThu = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "thu";
                                  }
                                );
                                setThuRate(filterThu[0].rate);

                                let filterFri = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "fri";
                                  }
                                );
                                setFriRate(filterFri[0].rate);

                                let filterSat = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sat";
                                  }
                                );
                                setSatRate(filterSat[0].rate);

                                let filterSun = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sun";
                                  }
                                );
                                setSunRate(filterSun[0].rate);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 4,
                            data: !thuClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setThuClosed(!thuClosed);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${thuClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 4,
                          data: !thuClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setThuClosed(!thuClosed);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !thuClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="regenUpdateParent">
                <div
                  className={`${regenThu ? "regenDayRatesContainer" : "none"}`}
                >
                  <p className="updateRateShiftsTxt">
                    Update all shifts that fall on a{" "}
                    <span className="rateDaySpanBold">Thursday</span> (from
                    today) with the new pay rate of{" "}
                    <span className="rateDaySpanBold">{thuRate}x</span>
                  </p>
                  <p
                    className="regenRateBtn"
                    onClick={() => {
                      updateDaysOnceRateHasChanges(4);
                      setRegenThu(false);
                      setLoadRegnThu(true);
                      setTimeout(() => {
                        setLoadRegnThu(false);
                      }, 3000);
                    }}
                  >
                    Update
                  </p>
                </div>{" "}
                <div
                  className={`${
                    loadRegenThu && !regenThu ? "loadingRegenDay" : "none"
                  }`}
                >
                  Thursday shifts updated
                </div>
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Friday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={friRate}
                  onChange={(e) => {
                    setRegenFri(true);
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "fri" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          axios
                            .post(
                              `${serverURL}/get-schedule-admin-data`,
                              { someData: 1 },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setAllowSwaps(response.data.allowSwaps);
                                setSelfGenSched(response.data.selfGenSched);
                                setDailyRates(response.data.dailyRates);

                                let filterMon = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "mon";
                                  }
                                );
                                setMonRate(filterMon[0].rate);

                                let filterTue = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "tue";
                                  }
                                );
                                setTueRate(filterTue[0].rate);

                                let filterWed = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "wed";
                                  }
                                );
                                setWedRate(filterWed[0].rate);

                                let filterThu = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "thu";
                                  }
                                );
                                setThuRate(filterThu[0].rate);

                                let filterFri = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "fri";
                                  }
                                );
                                setFriRate(filterFri[0].rate);

                                let filterSat = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sat";
                                  }
                                );
                                setSatRate(filterSat[0].rate);

                                let filterSun = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sun";
                                  }
                                );
                                setSunRate(filterSun[0].rate);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 5,
                            data: !friClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setFriClosed(!friClosed);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${friClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 5,
                          data: !friClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setFriClosed(!friClosed);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !friClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="regenUpdateParent">
                <div
                  className={`${regenFri ? "regenDayRatesContainer" : "none"}`}
                >
                  <p className="updateRateShiftsTxt">
                    Update all shifts that fall on a{" "}
                    <span className="rateDaySpanBold">Friday</span> (from today)
                    with the new pay rate of{" "}
                    <span className="rateDaySpanBold">{friRate}x</span>
                  </p>
                  <p
                    className="regenRateBtn"
                    onClick={() => {
                      updateDaysOnceRateHasChanges(5);
                      setRegenFri(false);
                      setLoadRegnFri(true);
                      setTimeout(() => {
                        setLoadRegnFri(false);
                      }, 3000);
                    }}
                  >
                    Update
                  </p>
                </div>{" "}
                <div
                  className={`${
                    loadRegenFri && !regenFri ? "loadingRegenDay" : "none"
                  }`}
                >
                  Friday shifts updated
                </div>
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Saturday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={satRate}
                  onChange={(e) => {
                    setRegenSat(true);
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "sat" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          axios
                            .post(
                              `${serverURL}/get-schedule-admin-data`,
                              { someData: 1 },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setAllowSwaps(response.data.allowSwaps);
                                setSelfGenSched(response.data.selfGenSched);
                                setDailyRates(response.data.dailyRates);

                                let filterMon = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "mon";
                                  }
                                );
                                setMonRate(filterMon[0].rate);

                                let filterTue = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "tue";
                                  }
                                );
                                setTueRate(filterTue[0].rate);

                                let filterWed = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "wed";
                                  }
                                );
                                setWedRate(filterWed[0].rate);

                                let filterThu = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "thu";
                                  }
                                );
                                setThuRate(filterThu[0].rate);

                                let filterFri = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "fri";
                                  }
                                );
                                setFriRate(filterFri[0].rate);

                                let filterSat = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sat";
                                  }
                                );
                                setSatRate(filterSat[0].rate);

                                let filterSun = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sun";
                                  }
                                );
                                setSunRate(filterSun[0].rate);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 6,
                            data: !satClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setSatClosed(!satClosed);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${satClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 6,
                          data: !satClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setSatClosed(!satClosed);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !satClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="regenUpdateParent">
                <div
                  className={`${regenSat ? "regenDayRatesContainer" : "none"}`}
                >
                  <p className="updateRateShiftsTxt">
                    Update all shifts that fall on a{" "}
                    <span className="rateDaySpanBold">Saturday</span> (from
                    today) with the new pay rate of{" "}
                    <span className="rateDaySpanBold">{satRate}x</span>
                  </p>
                  <p
                    className="regenRateBtn"
                    onClick={() => {
                      updateDaysOnceRateHasChanges(6);
                      setRegenSat(false);
                      setLoadRegnSat(true);
                      setTimeout(() => {
                        setLoadRegnSat(false);
                      }, 3000);
                    }}
                  >
                    Update
                  </p>
                </div>{" "}
                <div
                  className={`${
                    loadRegenSat && !regenSat ? "loadingRegenDay" : "none"
                  }`}
                >
                  Saturday shifts updated
                </div>
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Sunday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={sunRate}
                  onChange={(e) => {
                    setRegenSun(true);
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "sun" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          axios
                            .post(
                              `${serverURL}/get-schedule-admin-data`,
                              { someData: 1 },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setAllowSwaps(response.data.allowSwaps);
                                setSelfGenSched(response.data.selfGenSched);
                                setDailyRates(response.data.dailyRates);

                                let filterMon = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "mon";
                                  }
                                );
                                setMonRate(filterMon[0].rate);

                                let filterTue = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "tue";
                                  }
                                );
                                setTueRate(filterTue[0].rate);

                                let filterWed = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "wed";
                                  }
                                );
                                setWedRate(filterWed[0].rate);

                                let filterThu = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "thu";
                                  }
                                );
                                setThuRate(filterThu[0].rate);

                                let filterFri = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "fri";
                                  }
                                );
                                setFriRate(filterFri[0].rate);

                                let filterSat = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sat";
                                  }
                                );
                                setSatRate(filterSat[0].rate);

                                let filterSun = response.data.dailyRates.filter(
                                  (item) => {
                                    return item.day === "sun";
                                  }
                                );
                                setSunRate(filterSun[0].rate);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 0,
                            data: !sunClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setSunClosed(!sunClosed);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${sunClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 0,
                          data: !sunClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setSunClosed(!sunClosed);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !sunClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>{" "}
              <div className="regenUpdateParent">
                <div
                  className={`${regenSun ? "regenDayRatesContainer" : "none"}`}
                >
                  <p className="updateRateShiftsTxt">
                    Update all shifts that fall on a{" "}
                    <span className="rateDaySpanBold">Sunday</span> (from today)
                    with the new pay rate of{" "}
                    <span className="rateDaySpanBold">{sunRate}x</span>
                  </p>
                  <p
                    className="regenRateBtn"
                    onClick={() => {
                      updateDaysOnceRateHasChanges(0);
                      setRegenSun(false);
                      setLoadRegnSun(true);
                      setTimeout(() => {
                        setLoadRegnSun(false);
                      }, 3000);
                    }}
                  >
                    Update
                  </p>
                </div>{" "}
                <div
                  className={`${
                    loadRegenSun && !regenSun ? "loadingRegenDay" : "none"
                  }`}
                >
                  Sunday shifts updated
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer ${
              enableGPS ? "noBorder" : ""
            } clickShiftRowAdmin  ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-client-gps`,
                  {
                    data: !enableGPS,
                  },
                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  setUpdateGeneral(!updateGeneral);
                  memoVals.setCheckUserState((x) => {
                    return {
                      enableGPS: !enableGPS,
                    };
                  });
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Enable GPS</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Record employees' GPS locations from their devices when they
                  clock on and off.
                  <br />
                  <br />
                  Managers can disable this setting for individual employees.
                </p>
              </div>{" "}
              {/*  KNOB BEGIN */}
              <div className="knobContainer knobContainerSettings ">
                <div
                  className={`switchKnob ${enableGPS ? "knobOn" : "knobOff"}`}
                ></div>
                <div className="knobBg"></div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
          {/* ROW ------------------------------------------------------------------------ */}
          {enableGPS ? (
            <div
              className={`gpsAdminRow ${memoVals.mob ? "gpsAdminRowMob" : ""}`}
            >
              <div className={`settingsRow`}>
                <div className="rowWithSubTextGPS">
                  <p className="absenceRowMainText">GPS zones</p>
                  <p className="absenceRowSubText overtimeRowSubText">
                    Set specific locations of where you expect employees to
                    clock in. FlowRota will show the distance between an
                    employee's clock-in location and their nearest GPS zone.{" "}
                    <br />
                    <br />
                    We recommend using{" "}
                    <a
                      href="https://www.gps-coordinates.net/"
                      target="_blank"
                      className="coordLinkTxt"
                    >
                      GPS-coordinates
                    </a>{" "}
                    to obtain GPS co-ordinates.
                  </p>
                </div>{" "}
              </div>
              <div
                className={`${
                  memoVals.gpsArray.length > 0 && enableGPS ? "gpsList" : "none"
                }`}
              >
                <div className="specialDayUnitsContainer">
                  {populateGPSArray}
                </div>
              </div>{" "}
              <div className={`addZoneParent ${enableGPS ? "" : "none"}`}>
                <p
                  className="addGPSBtn"
                  onClick={() => {
                    memoVals.setViewZoneModal((x) => "add");
                  }}
                >
                  Add zone{" "}
                  <img
                    src={whiteAddThin}
                    alt="Add zone"
                    className="addZonePlus"
                  />
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <br />
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default General;
