import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import navyClose from "../img/general/navycross.svg";

import editUserEdit1 from "../img/general/editUserEdit1.svg";
import dateStringer from "../tools/dateStringer";

const ViewSpecialDayModal = () => {
  const { mobModal } = useContext(StyleContext);
  const {
    showSpecialDayModal,
    setShowSpecialDayModal,
    setSureDeleteSpecialDay,
    setUpdatedSpecDay,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      showSpecialDayModal, //
      setShowSpecialDayModal, //
      setSureDeleteSpecialDay, //
      setUpdatedSpecDay, //
      mobModal, //
    }),
    [
      showSpecialDayModal, //
      setShowSpecialDayModal, //
      setSureDeleteSpecialDay, //
      setUpdatedSpecDay, //
      mobModal, //
    ]
  );

  let [name, setName] = useState("");

  let [toggleEditName, setToggleEditName] = useState(false);

  let [newName, setNewName] = useState("");

  let [editDate, setEditDate] = useState(false);
  let [dateValue, setDateValue] = useState(
    dateStringer.createDateInputFromDateString(
      memoVals.showSpecialDayModal.date
    )
  );

  let [specialDayRate, setSpecialDayRate] = useState(
    memoVals.showSpecialDayModal.rate
  );
  let closeModal = () => {
    memoVals.setShowSpecialDayModal((x) => {
      return { show: false };
    });
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`dayCommentsModal ${
          memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperSpecDay"></div>
        )}
        <div
          className="mySwapsHeader spaceBetween"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={memoVals.mobModal ? navyClose : navyClose}
            alt="Close"
            className="closeMySwapsModalImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p>Special day</p>

          {/* <p></p> */}
        </div>
        <div className="dayCommentsModalBody">
          <div className="gpsZoneRow noBorder">
            <p className="lonLatTitles">Special day name</p>

            {toggleEditName ? (
              <div className={`lonLatInputDiv`}>
                {" "}
                <input
                  type="text"
                  defaultValue="Current location"
                  className={`latLonInput`}
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                ></input>
                <p
                  className={`saveSettBtn`}
                  onClick={() => {
                    setToggleEditName(false);
                    if (newName !== name) {
                      setName(newName);

                      axios
                        .post(
                          `${serverURL}/update-special-day`,
                          {
                            updateWhat: "name",
                            id: memoVals.showSpecialDayModal.id,
                            data: newName,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setShowSpecialDayModal((x) => {
                              return {
                                show: true,
                                name: newName,
                                companyClosed:
                                  memoVals.showSpecialDayModal.companyClosed,
                                closedPay:
                                  memoVals.showSpecialDayModal.closedPay,
                                rate: memoVals.showSpecialDayModal.rate,
                                id: memoVals.showSpecialDayModal.id,
                                ts: memoVals.showSpecialDayModal.ts,
                                date: memoVals.showSpecialDayModal.date,
                              };
                            });

                            memoVals.setUpdatedSpecDay((x) => {
                              return {
                                data: true,
                                name: newName,
                                companyClosed:
                                  memoVals.showSpecialDayModal.companyClosed,
                                closedPay:
                                  memoVals.showSpecialDayModal.closedPay,
                                rate: memoVals.showSpecialDayModal.rate,
                                id: memoVals.showSpecialDayModal.id,
                                ts: memoVals.showSpecialDayModal.ts,
                                date: memoVals.showSpecialDayModal.date,
                              };
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Save
                </p>
              </div>
            ) : (
              <p
                className={`lonLatVal ${
                  memoVals.showSpecialDayModal.name > 24 ? "smallZoneName2" : ""
                }`}
              >
                {memoVals.showSpecialDayModal.name}
                <img
                  src={editUserEdit1}
                  alt="Edit"
                  className="adminEditBtnImg"
                  onClick={() => {
                    setNewName(memoVals.showSpecialDayModal.name);

                    setToggleEditName(true);
                  }}
                />
              </p>
            )}
          </div>{" "}
          <div className="gpsZoneRow">
            <p className="lonLatTitles">Date</p>

            {/* <p className={`lonLatVal x249898233`}>
              {dateStringer.printedDateWithYrFromDs(memoVals.showSpecialDayModal.date)}

              <img
                src={editUserEdit1}
                alt="Edit"
                className="adminEditBtnImg"
                onClick={() => {
                    setEditDate(true);

                }}
              />
            </p> */}
            {editDate ? (
              <div className={`lonLatInputDiv`}>
                {" "}
                <input
                  type="date"
                  defaultValue="Current location"
                  className={`specDayDsEdit`}
                  value={dateValue}
                  //   id="dateFocus"
                  //   ref={inputRef2}
                  onChange={(e) => {
                    setDateValue(e.target.value);
                  }}
                ></input>
                <p
                  className={`saveSettBtn`}
                  onClick={() => {
                    if (dateValue.length === 10) {
                      setEditDate(false);

                      axios
                        .post(
                          `${serverURL}/update-special-day`,
                          {
                            updateWhat: "date",
                            id: memoVals.showSpecialDayModal.id,
                            data: dateStringer.createStringFromDateInput(
                              dateValue
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setShowSpecialDayModal((x) => {
                              return {
                                show: true,
                                name: memoVals.showSpecialDayModal.name,
                                companyClosed:
                                  memoVals.showSpecialDayModal.companyClosed,
                                closedPay:
                                  memoVals.showSpecialDayModal.closedPay,
                                rate: memoVals.showSpecialDayModal.rate,
                                id: memoVals.showSpecialDayModal.id,
                                ts: dateStringer.createTimestampFromString(
                                  dateStringer.createStringFromDateInput(
                                    dateValue
                                  )
                                ),
                                date: dateStringer.createStringFromDateInput(
                                  dateValue
                                ),
                              };
                            });

                            memoVals.setUpdatedSpecDay((x) => {
                              return {
                                data: true,
                                name: memoVals.showSpecialDayModal.name,
                                companyClosed:
                                  memoVals.showSpecialDayModal.companyClosed,
                                closedPay:
                                  memoVals.showSpecialDayModal.closedPay,
                                rate: memoVals.showSpecialDayModal.rate,
                                id: memoVals.showSpecialDayModal.id,
                                ts: dateStringer.createTimestampFromString(
                                  dateStringer.createStringFromDateInput(
                                    dateValue
                                  )
                                ),
                                date: dateStringer.createStringFromDateInput(
                                  dateValue
                                ),
                              };
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Save
                </p>
              </div>
            ) : (
              <p className={`lonLatVal`}>
                {dateStringer.printedDateWithYrFromDs(
                  memoVals.showSpecialDayModal.date
                )}
                <img
                  src={editUserEdit1}
                  alt="Edit"
                  className="adminEditBtnImg"
                  onClick={() => {
                    setEditDate(true);
                  }}
                />
              </p>
            )}
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles">Company closed</p>

            <p className={`lonLatVal x249898233`}>
              {memoVals.showSpecialDayModal.companyClosed ? "Yes" : "No"}

              {/*  KNOB BEGIN */}
              <div
                className="knobContainer specDayEditKnob"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/update-special-day`,
                      {
                        updateWhat: "closed",
                        id: memoVals.showSpecialDayModal.id,
                        data: !memoVals.showSpecialDayModal.companyClosed,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setShowSpecialDayModal((x) => {
                          return {
                            show: true,
                            name: memoVals.showSpecialDayModal.name,
                            companyClosed:
                              !memoVals.showSpecialDayModal.companyClosed,
                            closedPay: memoVals.showSpecialDayModal.closedPay,
                            rate: memoVals.showSpecialDayModal.rate,
                            id: memoVals.showSpecialDayModal.id,
                            ts: memoVals.showSpecialDayModal.ts,
                            date: memoVals.showSpecialDayModal.date,
                          };
                        });

                        memoVals.setUpdatedSpecDay((x) => {
                          return {
                            data: true,
                            name: memoVals.showSpecialDayModal.name,
                            companyClosed:
                              !memoVals.showSpecialDayModal.companyClosed,
                            closedPay: memoVals.showSpecialDayModal.closedPay,
                            rate: memoVals.showSpecialDayModal.rate,
                            id: memoVals.showSpecialDayModal.id,
                            ts: memoVals.showSpecialDayModal.ts,
                            date: memoVals.showSpecialDayModal.date,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <div
                  className={`switchKnob ${
                    memoVals.showSpecialDayModal.companyClosed
                      ? "knobOn"
                      : "knobOff"
                  }`}
                ></div>
                <div className="knobBg"></div>
              </div>
              {/*  KNOB END */}
            </p>
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles">Pay staff</p>

            <p className={`lonLatVal x249898233`}>
              {memoVals.showSpecialDayModal.closedPay ? "Yes" : "No"}

              {/*  KNOB BEGIN */}
              <div
                className="knobContainer specDayEditKnob"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/update-special-day`,
                      {
                        updateWhat: "pay",
                        id: memoVals.showSpecialDayModal.id,
                        data: !memoVals.showSpecialDayModal.closedPay,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setShowSpecialDayModal((x) => {
                          return {
                            show: true,
                            name: memoVals.showSpecialDayModal.name,
                            companyClosed:
                              memoVals.showSpecialDayModal.companyClosed,
                            closedPay: !memoVals.showSpecialDayModal.closedPay,
                            rate: memoVals.showSpecialDayModal.rate,
                            id: memoVals.showSpecialDayModal.id,
                            ts: memoVals.showSpecialDayModal.ts,
                            date: memoVals.showSpecialDayModal.date,
                          };
                        });
                        memoVals.setUpdatedSpecDay((x) => {
                          return {
                            data: true,
                            name: memoVals.showSpecialDayModal.name,
                            companyClosed:
                              memoVals.showSpecialDayModal.companyClosed,
                            closedPay: !memoVals.showSpecialDayModal.closedPay,
                            rate: memoVals.showSpecialDayModal.rate,
                            id: memoVals.showSpecialDayModal.id,
                            ts: memoVals.showSpecialDayModal.ts,
                            date: memoVals.showSpecialDayModal.date,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <div
                  className={`switchKnob ${
                    memoVals.showSpecialDayModal.closedPay
                      ? "knobOn"
                      : "knobOff"
                  }`}
                ></div>
                <div className="knobBg"></div>
              </div>
              {/*  KNOB END */}
            </p>
          </div>
          {memoVals.showSpecialDayModal.companyClosed ||
          !memoVals.showSpecialDayModal.closedPay ? (
            ""
          ) : (
            <div className="gpsZoneRow">
              <p className="lonLatTitles x248984234">Pay rate</p>

              <p className={`lonLatVal x240893823`}>
                {/* {memoVals.showSpecialDayModal.rate}x */}
                <p></p>
                <select
                  className="specialDaysRateDropDown x298492812"
                  onChange={(e) => {
                    setSpecialDayRate(e.target.value);
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/update-special-day`,
                        {
                          updateWhat: "rate",
                          id: memoVals.showSpecialDayModal.id,
                          data: e.target.value,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setShowSpecialDayModal((x) => {
                            return {
                              show: true,
                              name: memoVals.showSpecialDayModal.name,
                              companyClosed:
                                memoVals.showSpecialDayModal.companyClosed,
                              closedPay: memoVals.showSpecialDayModal.closedPay,
                              rate: val,
                              id: memoVals.showSpecialDayModal.id,
                              ts: memoVals.showSpecialDayModal.ts,
                              date: memoVals.showSpecialDayModal.date,
                            };
                          });
                          memoVals.setUpdatedSpecDay((x) => {
                            return {
                              data: true,
                              name: memoVals.showSpecialDayModal.name,
                              companyClosed:
                                memoVals.showSpecialDayModal.companyClosed,
                              closedPay: memoVals.showSpecialDayModal.closedPay,
                              rate: val,
                              id: memoVals.showSpecialDayModal.id,
                              ts: memoVals.showSpecialDayModal.ts,
                              date: memoVals.showSpecialDayModal.date,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  value={specialDayRate}
                  placeholder={specialDayRate}
                >
                  <option value="0">0x</option>
                  <option value="0.3">0.3x</option>
                  <option value="0.5">0.5x</option>
                  <option value="0.6">0.6x</option>
                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>
              </p>
            </div>
          )}
        </div>
        <div className="dayCommentsModalFooter">
          <div className="viewMapsAndSaveDiv">
            <a
              className="viewGpsZoneMapBtn"
              onClick={() => {
                memoVals.setSureDeleteSpecialDay(
                  (x) => memoVals.showSpecialDayModal.id
                );
              }}
            >
              Remove
            </a>
          </div>

          <p
            className="closeSeeUserIDBtn"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewSpecialDayModal;
