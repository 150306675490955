import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { DataContext } from '../../contexts/DataContext';
import { StyleContext } from '../../contexts/StyleContext';
import { NavLink } from 'react-router-dom';



import serverURL from '../../serverURL';

import Navbar from '../Navbar';
// import CheckUser from '../../tools/CheckUser';
import MgrAccountNavbar from './MgrAccountNavbar';

import General from './admin/General'
import Users from './admin/Users'
import Teams from './admin/Teams'

import Admins from './admin/Admins'
import Billing from './admin/Billing'
import Absence from './admin/Absence'
import LeaveTypes from './admin/LeaveTypes'
import Overtime from './admin/Overtime'
import Schedule from './admin/Schedule'
import Blank from './admin/Blank'


import ErrorModal from './admin/subcomponents/ErrorModal'


import '../../styles/admin.css' 

import LoaderSplash from '../../LoaderSplash'

import statsPie from '../../img/general/statsPie.svg'
import StatsModal from './admin/StatsModal'



// content components
import AccountSettingsSettings from './AccountSettingsSettings';
import AccountSettingsAllowances from './AccountSettingsAllowances';







const Manage = () => {

    // CheckUser();


    const { adminPage, setAdminPage, setAccNav, showStatsModal, setShowStatsModal } = useContext(StyleContext);


    const {totUsrs, setTotUsrs, costPerMonth, setCostPerMonth, workforceAbsence, setWorkforceAbsence, yrReset, setYrReset, selectedEditTeam, setSelecteEditTeam, setSelectedUser,
      adminsArray, setAdminsArray
    } = useContext(DataContext);


    useEffect(() => {
      setAccNav('manage');
    }, []);


      // pre-load
      useEffect(() => {
        axios
        .post(
          `${serverURL}/get-admins`,
          { someData: 1 },
  
          {
            withCredentials: true,
            credentials: 'include',
          }
        ).then(response => {
          
            setAdminsArray(response.data.adminsArray);
          
        })
      }, [])



  const {
    validUser,
    fName,
    lName,
    email,
    companyName,
    permissions,
    profPic,
    jobTitle
  } = useContext(UserContext);



const toggleAdminComponents = () => {
if (adminPage === "General") { 
  return <General />
} else if (adminPage === "Users") {
  return <Users />
} else if (adminPage === "Teams") {
  return <Teams />
} else if (adminPage === "Admins") {
  return <Admins />
} else if (adminPage === "Billing") {
  return <Billing />
} else if (adminPage === "Absence") {
  return <Absence />
} else if (adminPage === "Leave Types") {
  return <LeaveTypes />
} else if (adminPage === "Overtime") {
  return <Overtime />
} else if (adminPage === "Schedule") {
  return <Schedule />
} else if (adminPage === "Blank") {
  return <Blank />
}
 }




  return (
    
    <div className='adminBg'>
            {/* <img src={whiteCurveUpper} alt="." className="whiteCurveL"/> */}
            <div className="adminCurverCropper"><div className="adminCurve"></div></div>

    <ErrorModal />

      <Navbar />
      <MgrAccountNavbar/>

      
      <StatsModal />
      <div className="adminTopContainer">
        <div className="adminTopInnerContainer">
          <p className="companySettings">Manage your staff</p>
          <img className="statsPie" src={statsPie} alt="Statistics" onClick={() => {
            setShowStatsModal(!showStatsModal);
            axios.get(`${serverURL}/get-stats`,
        {
          withCredentials: true,
          credentials: 'include',
        }).then((response) => {
            if (response.data) {
                setTotUsrs(response.data.totalUsers);
                setCostPerMonth(response.data.costPerMonth);
                setWorkforceAbsence(response.data.workforceAbsence);
                setYrReset(response.data.yearStarts);
            }
        })
            
            }}/>
        </div>
        <div className="adminMenuHolder">
            <div className="adminMenuContainer">
              <p className={`adminMenuUnit adminMenuUnitFirst ${adminPage === "General" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("General")}}>General</p>
              <p className={`adminMenuUnit ${adminPage === "Users" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Users"); setSelectedUser();}}>Users</p>
              <p className={`adminMenuUnit ${adminPage === "Teams" || adminPage === "Blank" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Teams"); setSelecteEditTeam();}}>Teams</p>
              <p className={`adminMenuUnit ${adminPage === "Admins" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Admins"); 
                axios
                .post(
                  `${serverURL}/get-admins`,
                  { someData: 1 },
          
                  {
                    withCredentials: true,
                    credentials: 'include',
                  }
                ).then(response => {
                  
                    setAdminsArray(response.data.adminsArray);
                  
                })
            }}>Admins</p>
              <p className={`adminMenuUnit ${adminPage === "Billing" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Billing")}}>Billing</p>
              <p className={`adminMenuUnit ${adminPage === "Absence" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Absence")}}>Absence</p>
              <p className={`adminMenuUnit ${adminPage === "Leave Types" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Leave Types")}}>Leave Types</p>
              <p className={`adminMenuUnit ${adminPage === "Overtime" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Overtime");
            }}>Overtime</p>
              <p className={`adminMenuUnit ${adminPage === "Schedule" ? "adminTitleSel" : ""}`} onClick={() => {setAdminPage("Schedule")}}>Schedule</p>
            </div>
            <div className="whiteGradientOverScrollBar"></div>
          </div>
      </div>

      {/* Toggle between components depending on chosen adminPage */}

        {toggleAdminComponents()}
  
   

    </div>
  );
  

};

export default Manage;





