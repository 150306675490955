import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import serverURL from "../../serverURL";
import dateStringer from "../../tools/dateStringer";
import towerLogo from "../tower/img/towerLogo.png";
import { TowerContext } from "../../contexts/TowerContext";

const TowerHeader = () => {
  const { fName, setFName, page, setGetBatchData } = useContext(TowerContext);

  useEffect(() => {
    console.log("Getting tower user info");
    axios
      .post(
        `${serverURL}/get-tower-data-init`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message2 === "success") {
          setFName(response.data.fName);
        }
      });
  }, []);
  return (
    <div className="towerHeaderContainer">
      <div className="towerHeaderSide"></div>
      <img
        src={towerLogo}
        alt="Logo"
        className="towerLogoImg"
        onClick={() => {}}
      />
      <div className="towerHeaderSide">
        <p className="towerHelloTxt">Hello, {fName}</p>
        <p
          className="towerSignoutBtn"
          onClick={(event) => {
            axios.get(`${serverURL}/logout`, {
              withCredentials: true,
              credentials: "include",
            });
            window.location.href = "/signin";
          }}
        >
          Sign out
        </p>
      </div>
    </div>
  );
};

export default TowerHeader;
