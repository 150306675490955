import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  Fragment,
} from "react";

// this is a subcomponent of the Dashboard -- the dashboard file is already big enough

import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";
import websiteURL from "../../websiteURL";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import allowArr from "../../img/general/allowArr.svg";
import cog from "../../img/general/cog.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import edit from "../../img/general/edit.svg";
import convert from "../../img/general/convert.svg";
import convArr from "../../img/general/navySwap.svg";
import popup from "../../img/general/popup.svg";

import close from "../../img/modals/close.svg";
import dateStringer from "../../tools/dateStringer";

const DashboardTOIL = ({ availableYears, setAvailableYears }) => {
  const { checkUserState } = useContext(UserContext);

  const { mob, full, fullButNarrow, mobModal } = useContext(StyleContext);
  const {
    setShowEditUser,
    setShowEditShiftModal,
    updateSingleUserAllowances,

    setUpdateSingleUserAllowances,
    setShowConvertToilModal,
  } = useContext(DataContext);

  let [sureDeleteConversion, setSureDeleteConversion] = useState({
    show: false,
  });
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let [selectedUserIDs, setSelectedUserIDs] = useState([]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [toilWeeksLimit, setToilWeeksLimit] = useState(52);

  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();

  let [users, setUsers] = useState([]);
  let [tilDisabled, setTilDisabled] = useState(false);

  let [toilPage, setToilPage] = useState(1); // 1 = accrued 2 = taken 3 = conversions

  useEffect(() => {
    // get allows here
    axios
      .post(
        `${serverURL}/dash-toil`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("disable")) {
          setTilDisabled(true);
          setLoading(false);
        }
        if (response.data.message === "success") {
          setUsers(response.data.users);
          setToilWeeksLimit(response.data.toilWeeksLimit);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    // get allows here -- for single when updateSingleUserAllowances is updated with a userID

    if (updateSingleUserAllowances) {
      axios
        .post(
          `${serverURL}/dash-toil-user`,
          {
            userID: updateSingleUserAllowances,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            let arr = [];
            users.map((usr) => {
              if (usr.userID === updateSingleUserAllowances) {
                let newObj = usr;
                newObj.arr = response.data.arr;
                newObj.balance = response.data.availableToil;
                arr.push(newObj);
              } else {
                arr.push(usr);
              }
            });
            setUsers(arr);
            setUpdateSingleUserAllowances("");
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [updateSingleUserAllowances]);

  // master return
  return (
    <div className="dashAllowancesContainer">
      <div className="dashAllowsTopBar">
        {" "}
        <div className="toilWeeksLimitTxt">
          <p className={`toilWeeksLimTitle ${tilDisabled ? "fontSize14" : ""}`}>
            {loading
              ? ""
              : tilDisabled
              ? "TOIL disabled"
              : "TOIL accrual period"}
          </p>
          {!tilDisabled && (
            <p className={`toilWeeksLimValue ${loading ? "invis" : ""}`}>
              {toilWeeksLimit} {toilWeeksLimit === 1 ? "week" : "weeks"}
            </p>
          )}
        </div>
        <div className="toilHelpBtns">
          <a
            href={`${websiteURL}/support/toil-manager`}
            target="_blank"
            className="toilDashHelpbtn"
          >
            Info <img src={popup} alt="Support" className="toilPopup" />
          </a>

          {checkUserState.permissions === 1 ? (
            <NavLink to="/admin/leave-settings" className="allowAdminBtn">
              {/* Allowances admin{" "} */}
              <img
                src={cog}
                alt="Allowances admin"
                className="allowancesAdminCogImg"
              />
            </NavLink>
          ) : (
            <p></p>
          )}
        </div>
      </div>
      <div className="dashAllowsUsersBox">
        {loading ? (
          <img
            src={horizontalBalls}
            alt="Loading user"
            className="dashUserLoadingimg"
          />
        ) : (
          users
            .sort((a, b) => a.fName.localeCompare(b.fName))
            .map((user) => {
              return (
                <div
                  className={`dashAllowRow ${
                    selectedUserIDs.includes(user.userID)
                      ? "dashAllowRowSelected"
                      : ""
                  }`}
                  key={user.userID}
                  onClick={() => {
                    if (selectedUserIDs.includes(user.userID)) {
                      setSelectedUserIDs(
                        selectedUserIDs.filter((x) => {
                          return x !== user.userID;
                        })
                      );
                    } else {
                      setSelectedUserIDs([...selectedUserIDs, user.userID]);
                      axios
                        .post(
                          `${serverURL}/dash-toil-user`,
                          {
                            userID: user.userID,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                            cancelToken: cancelSource1.token,
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            let arr = [];
                            users.map((usr) => {
                              if (usr.userID === user.userID) {
                                let newObj = usr;
                                newObj.arr = response.data.arr;
                                arr.push(newObj);
                              } else {
                                arr.push(usr);
                              }
                            });
                            setUsers(arr);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  <div className="dashAllowMain">
                    <div className="dashAllowUserInfo">
                      <img
                        src={user.profPicUrl || profilePicturePlaceholder}
                        className="dashAllowProPic"
                        alt={user.fName}
                        onClick={() => {
                          setShowEditUser({
                            userID: user.userID,
                          });
                        }}
                      />

                      <p
                        className={`dashAllowUserName ${
                          mob ? "dashAllowUserNameMob" : ""
                        }`}
                      >
                        {user.fName} {user.lName}
                      </p>
                    </div>

                    <div className="dashAllowDataSec">
                      <div className="dashAllowItem toilBal">
                        {user.enabled && (
                          <p className="dashAllowItemTitle">Available TOIL</p>
                        )}
                        {user.enabled ? (
                          <p
                            className={`dashAllowItemValue fontSize16_ minWith76 ${
                              user.balance < 0 ? "colourRed" : ""
                            }`}
                          >
                            {" "}
                            {user.balance < 0 ? "-" : ""}
                            {dateStringer.formatMinsDurationToHours(
                              user.balance || 0
                            )}
                          </p>
                        ) : (
                          <p className="dashAllowItemValue bbcada">Disabled</p>
                        )}
                      </div>

                      <img
                        src={allowArr}
                        alt="View other allowances"
                        className={`dashallowArrImg ${
                          selectedUserIDs.includes(user.userID)
                            ? "dashAllowArrSel"
                            : ""
                        }`}
                      />
                    </div>
                  </div>

                  {selectedUserIDs.includes(user.userID) && (
                    <div
                      className="dashAllowsUserTable"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="enableTilRowDiv">
                        <p
                          className="enableTilRowBtn"
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/toggle-user-til-blocked`,
                                { data: !user.enabled, userID: user.userID },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  let arr = [];
                                  users.map((x) => {
                                    if (x.userID === user.userID) {
                                      let newObj = x;
                                      newObj.enabled = !user.enabled;
                                      arr.push(newObj);
                                    } else {
                                      arr.push(x);
                                    }
                                  });

                                  setUsers(arr);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Enable TOIL {/*  KNOB BEGIN */}
                          <div className="knobContainer tilRowKnob">
                            <div
                              className={`switchKnob ${
                                user.enabled ? "knobOn" : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                          {/*  KNOB END */}
                        </p>
                        <p
                          className={`editProfileBtnDash toilDashEditProf`}
                          onClick={() => {
                            setShowEditUser(user.userID);
                          }}
                        >
                          Profile{" "}
                          <img
                            src={edit}
                            alt="Edit profile"
                            className={`editProfilePenIcon `}
                          />
                        </p>{" "}
                      </div>
                      {user.enabled ? (
                        <div className="dashAllowEditBtnHolder noBorder">
                          <div
                            className="allLeaveDashController marginRight0"
                            onClick={(e) => {
                              e.stopPropagation();

                              setShowConvertToilModal(user.userID);
                              console.log("!", user.userID);
                            }}
                          >
                            <div className="dashAddDeductTitle">
                              Convert TOIL to leave
                              <img
                                src={convert}
                                alt="Edit"
                                className="convertImg"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {user.enabled && user.arr && user.arr[0] && (
                        <div className="dashTilList">
                          <div className="dashTilKey">
                            <p
                              className={`dashTilKeyTitle ${
                                toilPage === 1 ? "dashTilKeyTitleActive" : ""
                              }`}
                              onClick={() => {
                                setToilPage(1);
                              }}
                            >
                              Accrued TOIL
                            </p>
                            <p
                              className={`dashTilKeyTitle ${
                                toilPage === 2 ? "dashTilKeyTitleActive" : ""
                              }`}
                              onClick={() => {
                                setToilPage(2);
                              }}
                            >
                              Used TOIL
                            </p>
                            <p
                              className={`dashTilKeyTitle ${
                                toilPage === 3 ? "dashTilKeyTitleActive" : ""
                              }`}
                              onClick={() => {
                                setToilPage(3);
                              }}
                            >
                              Converted TOIL
                            </p>
                          </div>
                          {user.arr &&
                            user.arr.map((item, i) => {
                              if (
                                (toilPage === 3 && item.convert) ||
                                (toilPage === 2 && item.type === "tilTaken") ||
                                (toilPage === 1 &&
                                  item.type !== "tilTaken" &&
                                  !item.convert)
                              ) {
                                return (
                                  <div
                                    className={`dashTilListItem ${
                                      item.convert
                                        ? "defaultCursor x248924894"
                                        : ""
                                    }`}
                                    key={i}
                                    onClick={() => {
                                      if (item.convert) {
                                        // ---
                                      } else {
                                        setShowEditShiftModal({
                                          itemID: item.shiftID || item.tilID,
                                          itemType:
                                            item.type === "tilTaken"
                                              ? item.shiftID
                                                ? "shift"
                                                : "overtime"
                                              : item.tilID
                                              ? "til"
                                              : "shift",
                                          itemIsNew: false,
                                          userID: user.userID,
                                        });
                                      }
                                    }}
                                  >
                                    <div className="dashTilListItemLeft">
                                      <p
                                        className={`dashTilType ${
                                          item.type === "til"
                                            ? "padRight62"
                                            : ""
                                        }`}
                                      >
                                        {item.convert
                                          ? "Conversion"
                                          : item.type === "tilTaken"
                                          ? "TOIL taken"
                                          : item.type === "til"
                                          ? "Overtime worked in lieu"
                                          : "Shift worked in lieu"}
                                      </p>
                                      <p className="dashTilListItemDs">
                                        {item.convert ? (
                                          <img
                                            src={convArr}
                                            alt="Conversion"
                                            className="convArrinline"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {dateStringer.printedDateFromDs(
                                          item.convert ? item.ds : item.start,
                                          true
                                        )}
                                      </p>
                                      <p
                                        className={`dashTilListItemTimeStrip ${
                                          item.type === "tilTaken" ||
                                          (item.convert && item.toLeave)
                                            ? "colour00ccff"
                                            : ""
                                        }`}
                                      >
                                        {item.convert
                                          ? item.toLeave
                                            ? "TOIL to annual leave"
                                            : "Annual leave to TOIL"
                                          : dateStringer.dsToTimeStrip(
                                              item.type === "tilTaken"
                                                ? item.tilStart
                                                : item.start
                                            )}{" "}
                                        {!item.convert ? "-" : ""}{" "}
                                        {!item.convert
                                          ? dateStringer.dsToTimeStrip(item.end)
                                          : ""}
                                      </p>
                                      {item.convert &&
                                      item.addedByFName &&
                                      item.addedByLName ? (
                                        <p className="itemconvAddedBy">
                                          By {item.addedByFName}{" "}
                                          {item.addedByLName[0]}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="dashTilListItemRight">
                                      <p
                                        className={`dashTilListItemDurMins ${
                                          item.type === "tilTaken" ||
                                          (item.convert && item.toLeave)
                                            ? "colour00ccff"
                                            : ""
                                        }`}
                                      >
                                        {item.type === "tilTaken" ||
                                        (item.convert && item.toLeave)
                                          ? "-"
                                          : "+"}
                                        {dateStringer.formatMinsDurationToHours(
                                          item.convert
                                            ? item.mins
                                            : item.type === "tilTaken"
                                            ? item.tilMins
                                            : item.durMins
                                        )}
                                      </p>
                                      <p className="dashTilListItemType">
                                        {item.type === "tilTaken"
                                          ? "deducted from TOIL balance"
                                          : item.convert && item.toLeave
                                          ? "deducted from TOIL balance, added to annual leave"
                                          : "added to TOIL balance"}
                                      </p>
                                      {item.convert && (
                                        <p
                                          className="deleteToilConvBtn"
                                          onClick={() => {
                                            console.log(user.userID);
                                            setSureDeleteConversion({
                                              show: true,
                                              conversionID: item.conversionID,
                                              userID: user.userID,
                                            });
                                          }}
                                        >
                                          Remove
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}

                          {toilPage === 3 &&
                          user.arr.filter((x) => {
                            return x.convert;
                          }).length === 0 ? (
                            <p className="tilListNil">
                              No TOIL to annual leave conversions over the last{" "}
                              {toilWeeksLimit} week
                              {toilWeeksLimit === 1 ? "" : "s"}
                            </p>
                          ) : (
                            ""
                          )}

                          {toilPage === 2 &&
                          user.arr.filter((x) => {
                            return x.type === "tilTaken";
                          }).length === 0 ? (
                            <p className="tilListNil">
                              No TOIL taken over the last {toilWeeksLimit} week
                              {toilWeeksLimit === 1 ? "" : "s"}
                            </p>
                          ) : (
                            ""
                          )}

                          {toilPage === 1 &&
                          user.arr.filter((x) => {
                            return x.type !== "tilTaken" && !x.convert;
                          }).length === 0 ? (
                            <p className="tilListNil">
                              No TOIL accrued over the last {toilWeeksLimit}{" "}
                              week
                              {toilWeeksLimit === 1 ? "" : "s"}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })
        )}

        {sureDeleteConversion.show ? (
          <div
            className="areYouSureModalUnderlay"
            onClick={() => {
              setSureDeleteConversion({ show: false });
            }}
          >
            <div
              className="formCreatedModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Are you sure you want to remove this TOIL conversion?
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setSureDeleteConversion({ show: false });
                    console.log({ sureDeleteConversion });
                    axios
                      .post(
                        `${serverURL}/delete-toil-conversion`,
                        {
                          userID: sureDeleteConversion.userID,
                          conversionID: sureDeleteConversion.conversionID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setUpdateSingleUserAllowances(
                            sureDeleteConversion.userID
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureDeleteConversion({ show: false });
                  }}
                >
                  No
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default DashboardTOIL;
