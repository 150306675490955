import React, { useContext, useEffect, useState, useMemo } from "react";
// cleaned wed 24 jul 23
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";
import close from "../img/modals/close.svg";

import iosAddToHomeScreen from "../img/general/iosAddToHomeScreen.svg";
import chromeAddToHomeScreen from "../img/general/chromeAddToHomeScreen.svg";
import addToHomeScreen from "../img/general/addToHomeScreen.svg";

import installCloud from "../img/general/installCloud.svg";
import logo from "../img/general/logo.svg";
import androidIns from "../img/general/androidIns.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const InstallModal = ({ setShowInstallModal, device, popUp }) => {
  const { mobModal } = useContext(StyleContext);
  const { setCheckUserState, checkUserState } = useContext(UserContext);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={iosAddToHomeScreen}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={chromeAddToHomeScreen}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={addToHomeScreen}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={installCloud}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={logo}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={androidIns}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    if (imagesLoaded === 7) {
      setLoading(false);
    }
  }, [imagesLoaded]);

  const memoVals = useMemo(
    () => ({
      mobModal,
      setCheckUserState,
      checkUserState, //
    }),
    [mobModal, setCheckUserState, checkUserState]
  );

  let [deviceToggler, setDeviceToggler] = useState("ios"); // or "chrome"
  useEffect(() => {
    // cleaned - not needed
    if (device.browser.toLowerCase() === "chrome") {
      setDeviceToggler("chrome");
    }
  }, [device]);

  // useEffect(() => {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener("popstate", function (event) {
  //     window.history.pushState(null, document.title, window.location.href);
  //     setShowInstallModal(false);
  //   });
  // }, []);

  let [hide, setHide] = useState(false);

  let closeModal = () => {
    if (popUp) {
      let ds = dateStringer.createStringFromTimestamp(new Date().getTime());
      axios
        .post(
          `${serverURL}/close-install`,
          {
            ds: ds,
            hide: hide,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            memoVals.setCheckUserState((x) => {
              return {
                hideInstall: hide ? ds : memoVals.checkUserState.hideInstall,
                lastClosedInstall: ds,
              };
            });
          }
        });
      setShowInstallModal(false);
    } else {
      setShowInstallModal(false);
    }
  };

  return (
    <div
      className={`clockOnModalUnderlay underlayInstall ${
        loading ? "invis" : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`clockOnModalBox ${
          deviceToggler === "chrome" ? "chromeInstallModalBox" : "x3841941"
        } ${
          memoVals.mobModal
            ? "mobInstallModal mobModalShoulder slideUp"
            : "zoomIn"
        } x3841941`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="clockOnModalHeader none"
          onClick={() => {
            if (memoVals.mobModal) {
              closeModal();
            }
          }}
        >
          <div className="clockOnModalHeaderSideUnit">
            <img
              src={close}
              alt="Close"
              className="closeClockOnModalImg"
              onClick={() => {
                closeModal();
              }}
            />
          </div>
          <p className="clockOnModalHeaderTitle"></p>
        </div>

        {deviceToggler === "chrome" ? (
          <div className="installModalContentBox">
            <p className="dockInstructTxt inSafari fontSize16">
              Install the app
            </p>

            <img
              src={installCloud}
              alt="Install FlowRota"
              className="installCloudImg"
            />
            <p className="installPWAinfo">
              FlowRota is a web app installable from your mobile web browser.
              {/* This provides users with a better experience increasing privacy
              and security whilst using a fraction of your smartphone’s storage
              compared to apps downloaded from the app store. */}
            </p>
            <div className="dockInstructDiv">
              <p className="dockInstructNum">1</p>
              <p className="dockInstructTxt">Tap on</p>
              <img
                src={chromeAddToHomeScreen}
                alt="add to home screen"
                className="addToHSiosImg"
              />
              <p className="dockInstructTxt">in the top-right corner</p>
            </div>

            <div className="dockInstructDiv">
              <p className="dockInstructNum">2</p>
              <p className="dockInstructTxt">Scroll and select</p>
              <div className="addToHomeScreenBubble androidBub">
                <img
                  src={androidIns}
                  alt="add to home screen"
                  className="aths aths2"
                />
                <p className="addToHomeScreenTxt2">Install</p>
              </div>
            </div>

            <div className="dockInstructDiv">
              <p className="dockInstructNum">3</p>
              <p className="dockInstructTxt">Look for the </p>
              <img
                src={logo}
                alt="add to home screen"
                className="addToHSiosImg lookForTheLogo"
              />
              <p className="dockInstructTxt">icon</p>
            </div>
            <div className="dockInstructDiv finalLineInstallModal">
              <p className="dockInstructNum invis">3</p>
              <p className="dockInstructTxt">in your app menu </p>
            </div>
          </div>
        ) : (
          <div className="installModalContentBox">
            <p className="dockInstructTxt inSafari fontSize16">
              Install the app
            </p>

            <img
              src={installCloud}
              alt="Install FlowRota"
              className="installCloudImg"
            />

            <div className="dockInstructDiv">
              <p className="dockInstructNum">1</p>
              <p className="dockInstructTxt">Tap on</p>
              <img
                src={iosAddToHomeScreen}
                alt="add to home screen"
                className="addToHSiosImg"
              />
              <p className="dockInstructTxt">in the tab bar</p>
            </div>

            <div className="dockInstructDiv">
              <p className="dockInstructNum">2</p>
              <p className="dockInstructTxt">Scroll and select</p>
              <div className="addToHomeScreenBubble">
                <p className="addToHomeScreenTxt">Add to home screen</p>
                <img
                  src={addToHomeScreen}
                  alt="add to home screen"
                  className="aths"
                />
              </div>
            </div>

            <div className="dockInstructDiv">
              <p className="dockInstructNum">3</p>
              <p className="dockInstructTxt">Look for the </p>
              <img
                src={logo}
                alt="add to home screen"
                className="addToHSiosImg lookForTheLogo"
              />
              <p className="dockInstructTxt">icon on your home screen</p>
            </div>
          </div>
        )}

        {device.browser === "chrome" ? (
          <button
            className="progressier-install-button closeClockOnDiv x9183812 x32984197411"
            data-icons="false"
            data-install="Install now"
            data-installed="Launch the app"
          ></button>
        ) : (
          ""
        )}

        <p
          className="closeClockOnDiv x9183812"
          onClick={() => {
            sessionStorage.setItem("blockInstall", true);
            closeModal();
          }}
        >
          Use in browser
        </p>
      </div>
      {imgPreload}
    </div>
  );
};

export default InstallModal;
