import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";

import dateStringer from "../../../tools/dateStringer";
import serverURL from "../../../serverURL";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import ShiftsHeader from "./ShiftsHeader";

import "../../../styles/calendar.css";

// import AddShiftModal from "../../account/admin/subcomponents/AddShiftModal";
import AddShiftModal2 from "../../account/admin/subcomponents/AddShiftModal2";

// import AddTilModal from "../../account/admin/subcomponents/AddTilModal";
import ManagerShiftModal from "./ManagerShiftModal";

import TeamHoursDataBox from "./TeamHoursDataBox";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";

import checkboxInstall1 from "../../../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../../../img/general/checkboxInstall2purp.svg";
import shiftChangeArrow from "../../../img/general/shiftChangeArrow.svg";
import shiftChangeArrowGreen from "../../../img/general/shiftChangeArrowGreen.svg";
import shiftChangeArrowRed from "../../../img/general/shiftChangeArrowRed.svg";
import time from "../../../img/general/time.svg";
import unitTick from "../../../img/general/unitTick.svg";
import unitCross from "../../../img/general/unitCross.svg";
import filterWhite from "../../../img/general/filterWhite.svg";
import rotaClock from "../../../img/general/rotaClock.svg";
import whitecross from "../../../img/general/whitecross.svg";
import exclaimWhite from "../../../img/general/exclaimWhite.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import doubleTick from "../../../img/general/doubleTick.svg";
import lightBlueQuestionMark from "../../../img/general/lightBlueQuestionMark.svg";
import teamIcon from "../../../img/general/teamIcon.svg";
import zzz from "../../../img/general/zzz.svg";

const TeamShifts = () => {
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 14) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}

      {/* Additional images */}
      <img
        src={lightBlueQuestionMark}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall2}
        alt="Checkbox Install 2 Purple"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrow}
        alt="Shift Change Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowGreen}
        alt="Shift Change Arrow Green"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowRed}
        alt="Shift Change Arrow Red"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={time}
        alt="Time"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitCross}
        alt="Unit Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterWhite}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="Rota Clock"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  const { currencySymbol } = useContext(UserContext);
  const { mob, full, fullButNarrow, iPad } = useContext(StyleContext);
  const { calPage, updatedItem, setCalPage } = useContext(CalendarContext);
  const {
    device,
    selectedShiftTeam,
    setSelectedShiftTeam,
    setAddShiftPage,
    setAddShiftOrigin,
    setAddTilUserID,
    setAddTilSelf,
    setAddTilTeam,
    showAddTilModal,
    setShowAddTilModal,
    setAddTilPage,
    setAddTilUserKnown,
    updateShiftsOnceItemAdded,

    setShowEditShiftModal,
    updateShifts,
    setUpdateShifts,
    updateShiftReqs,
    update2,
    setOpenManagerShiftModal,
    setPage,
    loadUser,
    setLoadUser,
    selectedName,
    setSelectedName,
    selectedDay,
    setSelectedDay,
    availableTeamsForShifts,
    setIndicate,
    showAddLeaveOnTeam,
    setShowAddLeaveOnTeam,
    setShowTeamSwapsModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      currencySymbol, //
      mob, //
      full, //
      fullButNarrow, //
      iPad, //
      calPage, //
      updatedItem, //
      setCalPage, //
      device, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      setAddShiftPage, //
      setAddShiftOrigin, //
      setAddTilUserID, //
      setAddTilSelf, //
      setAddTilTeam, //
      showAddTilModal, //
      setShowAddTilModal, //
      setAddTilPage, //
      setAddTilUserKnown, //
      updateShiftsOnceItemAdded, //
      setShowEditShiftModal, //
      updateShifts, //
      setUpdateShifts, //
      updateShiftReqs, //
      update2, //
      setOpenManagerShiftModal, //
      setPage, //
      loadUser, //
      setLoadUser, //
      selectedName, //
      setSelectedName, //
      selectedDay, //
      setSelectedDay, //
      availableTeamsForShifts, //
      setIndicate, //
      showAddLeaveOnTeam, //
      setShowAddLeaveOnTeam, //
      setShowTeamSwapsModal, //
    }),
    [
      currencySymbol, //
      mob, //
      full, //
      fullButNarrow, //
      iPad, //
      calPage, //
      updatedItem, //
      setCalPage, //
      device, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      setAddShiftPage, //
      setAddShiftOrigin, //
      setAddTilUserID, //
      setAddTilSelf, //
      setAddTilTeam, //
      showAddTilModal, //
      setShowAddTilModal, //
      setAddTilPage, //
      setAddTilUserKnown, //
      updateShiftsOnceItemAdded, //
      setShowEditShiftModal, //
      updateShifts, //
      setUpdateShifts, //
      updateShiftReqs, //
      update2, //
      setOpenManagerShiftModal, //
      setPage, //
      loadUser, //
      setLoadUser, //
      selectedName, //
      setSelectedName, //
      selectedDay, //
      setSelectedDay, //
      availableTeamsForShifts, //
      setIndicate, //
      showAddLeaveOnTeam, //
      setShowAddLeaveOnTeam, //
      setShowTeamSwapsModal, //
    ]
  );

  let [totDur, setTotDur] = useState(0);

  let [showTilOrShiftPopUp, setShowTilOrShiftPopUp] = useState(false);

  let [tilOrOvertime, setTilOrOvertime] = useState("overtime");

  let [showAddShift, setShowAddShift] = useState(false);
  let [reqMy, setReqMy] = useState(
    `${
      new Date().getMonth() < 10 ? "0" : ""
    }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
      2,
      2
    )}`
  );
  useEffect(() => {
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setReqMy(`${monthNum}${yearNum}`);

    memoVals.setCalPage((x) => "team-shifts");
  }, []);

  let hoursScrollBox = useRef();
  useEffect(() => {
    hoursScrollBox.current.scrollTop = 0;
  }, [memoVals.selectedShiftTeam]);

  let [showFilter, setShowFilter] = useState(false);
  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);
  let [availableMonths, setAvailableMonths] = useState([]);
  let [allShiftsAndTils, setAllShiftsAndTils] = useState([]);
  let [my, setMy] = useState(
    `${
      new Date().getMonth() < 10 ? "0" : ""
    }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
      2,
      2
    )}`
  );
  let [showOutstanding, setShowOutstanding] = useState(false);
  let [names, setNames] = useState([]);

  let [days, setDays] = useState([]);

  let [showRequests, setShowRequests] = useState(false);

  let [reqQty, setReqQty] = useState(0);
  let [shiftReqsLoaded, setShiftReqsLoaded] = useState(false);

  let [shiftReqArr, setShiftReqArr] = useState([]);
  let [shiftReqsLoading, setShiftReqsLoading] = useState(true);

  let [changesArr, setChangesArr] = useState([]);

  useEffect(() => {
    // clean not needed

    let arr = shiftReqArr.sort((a, b) => {
      return (
        dateStringer.createTimestampFromString(a.oldDate) -
        dateStringer.createTimestampFromString(b.oldDate)
      );
    });

    let pending = [];
    let nonPending = [];
    arr.forEach((x) => {
      if (!x.approved && !x.declined) {
        pending.push(x);
      } else {
        nonPending.push(x);
      }
    });

    if (pending[0]) {
      pending.push({ split: true });
    }

    setChangesArr([...pending, ...nonPending]);
  }, [shiftReqArr]);

  let [namesOfShiftReqs, setNamesOfShiftReqs] = useState([]);

  let [highlightedEditID, setHighlightedEditID] = useState("");

  useEffect(() => {
    if (highlightedEditID) {
      setHighlightedEditID("");
    }
  }, [changesArr, showRequests]);

  useEffect(() => {
    // clean not needed

    document.title = "Team hours - FlowRota";

    memoVals.setPage((x) => "team-shifts");
  }, []);

  useEffect(() => {
    // clean not needed

    memoVals.setSelectedShiftTeam((x) => "allStaff");
  }, []);

  useEffect(() => {
    // clean not needed

    let newArr = [];
    if (memoVals.updatedItem.data) {
      let original = allShiftsAndTils.filter((x) => {
        return x.typeID === memoVals.updatedItem.key;
      });

      let outstandingChanges = 0;
      if (original[0]) {
        outstandingChanges = original[0].outstandingChanges;
      }

      let formattedItem = {
        picUrl: original[0] ? original[0].picUrl : "",
        tags: memoVals.updatedItem.tags,
        fName: memoVals.updatedItem.fName,
        lName: memoVals.updatedItem.lName,
        key: memoVals.updatedItem.key,
        startTs: memoVals.updatedItem.startTs,
        tilApproved: memoVals.updatedItem.tilApproved,
        tilStart: memoVals.updatedItem.tilStart,
        tilEnd: memoVals.updatedItem.tilEnd,
        type: memoVals.updatedItem.type,
        typeID: memoVals.updatedItem.key,
        cost: memoVals.updatedItem.cost,
        userID: memoVals.updatedItem.userID,
        teamName:
          memoVals.updatedItem.teamName || memoVals.updatedItem.data.teamName,
        outstandingChanges: outstandingChanges || 0,
        data: {
          duration: memoVals.updatedItem.data.duration,
          durMins: memoVals.updatedItem.data.durMins,
          selfLoggedAbsence: memoVals.updatedItem.selfLoggedAbsence,

          absent: memoVals.updatedItem.data.absent,
          ghost: memoVals.updatedItem.data.ghost,
          start:
            memoVals.updatedItem.type === "shift"
              ? memoVals.updatedItem.data.start
              : `${memoVals.updatedItem.data.start.substr(
                  12,
                  2
                )}:${memoVals.updatedItem.data.start.substr(15, 2)}`,
          end:
            memoVals.updatedItem.type === "shift"
              ? memoVals.updatedItem.data.end
              : `${memoVals.updatedItem.data.end.substr(
                  12,
                  2
                )}:${memoVals.updatedItem.data.end.substr(15, 2)}`,
          partialAbsent: memoVals.updatedItem.data.partialAbsent,
          unpaidAbsent: memoVals.updatedItem.data.unpaidAbsent,
          requireAccept: original[0] && original[0].data.requireAccept,
          accepted: memoVals.updatedItem.accepted
            ? memoVals.updatedItem.accepted
            : original[0] && original[0].data.accepted,
        },
      };

      if (!memoVals.updatedItem.deleted) {
        console.log({ formattedItem });
        newArr.push(formattedItem);
      }

      allShiftsAndTils.forEach((item) => {
        if (item.key !== memoVals.updatedItem.key) {
          newArr.push(item);
        }
      });

      setAllShiftsAndTils(newArr);
    }
  }, [memoVals.updatedItem]);

  useEffect(() => {
    // clean not needed

    setShiftReqsLoading(true);

    let tempMy = "0000";
    if (my === "0000") {
      let today = new Date();
      let monthNum = today.getMonth();
      if (monthNum < 10) {
        monthNum = `0${monthNum}`;
      } else {
      }
      let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

      tempMy = `${monthNum}${yearNum}`;
    }
  }, [reqMy, memoVals.updateShiftReqs, memoVals.selectedShiftTeam]);

  useEffect(() => {
    // clean not needed

    setShowOutstanding(false);
    setShowRequests(false);
  }, [memoVals.selectedShiftTeam]);

  let [selectedNameReq, setSelectedNameReq] = useState("allStaff");

  let generateNamesForShiftReqs = useMemo(() => {
    return namesOfShiftReqs.map((name) => {
      return (
        <option key={Math.random()} value={name.userID}>
          {name.name}
        </option>
      );
    });
  }, [namesOfShiftReqs]);

  if (my === "0000") {
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);
    setReqMy(`${monthNum}${yearNum}`);
  }

  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();

  useEffect(() => {
    // set my to today's date
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);

    if (!availableMonths[0]) {
      axios
        .post(
          `${serverURL}/get-month-year-dropdown-options`,
          { teamID: "allStaff" },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // setAvailableMonths(response.data.arr);

            if (!response.data.arr.includes(my)) {
              setAvailableMonths([my, ...response.data.arr]);
            } else {
              setAvailableMonths(response.data.arr);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setLoading(true);
    axios
      .post(
        `${serverURL}/get-shift-edits-for-mgr`,
        {
          teamID: memoVals.selectedShiftTeam,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          countOnly: true,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource2.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setReqQty(response.data.qty || 0);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, [memoVals.calPage, memoVals.updateShiftReqs, memoVals.selectedShiftTeam]);

  const cancelSource3 = axios.CancelToken.source();
  const cancelSource4 = axios.CancelToken.source();
  const cancelSource5 = axios.CancelToken.source();
  const cancelSource6 = axios.CancelToken.source();

  useEffect(() => {
    if (!showRequests) {
      let m = my.substr(0, 2);
      let y = my.substr(2, 2);

      if (m === "00" && y === "00") {
        let today = new Date();
        m = today.getMonth();
        y = today.getFullYear();
      }
      axios
        .post(
          `${serverURL}/get-shifts-and-till-all-staff`,
          {
            month: parseInt(m),
            year: parseInt(y),
            teamID: memoVals.selectedShiftTeam || "allStaff",
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource3.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // setReverse(response.data.reverse);

            setAllShiftsAndTils(response.data.data);
            // response.data.data.forEach()
            if (response.data.permissions === 3) {
              window.location.href = "/shifts";
            }
            setTotDur(response.data.totalDurationMins);
            let arr = [];
            let uids = [];
            let dayNums = [];
            response.data.names.forEach((unit) => {
              if (!uids.includes(unit.userID)) {
                uids.push(unit.userID);
                arr.push({
                  fName: unit.fName,
                  lName: unit.lName,
                  userID: unit.userID,
                });
              }

              // let fullName = `${unit.fName} ${unit.lName[0]}`;
              // if (!arr.includes(fullName)) {
              //   arr.push(fullName);
              // }
              let ds = dateStringer.createStringFromTimestamp(unit.startTs);
              if (!dayNums.includes(parseInt(`${ds.substr(9, 2)}`))) {
                dayNums.push(parseInt(`${ds.substr(9, 2)}`));
              }
            });

            arr.sort((a, b) => a.fName.localeCompare(b.fName));
            dayNums.sort(function (a, b) {
              return a - b;
            });
            setNames(arr);
            setDays(dayNums);

            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params && params.requests) {
              setShowRequests(true);
              setHighlightedEditID(params.editID);
              // clear the params
              window.history.pushState(
                {},
                document.title,
                window.location.pathname
              );
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (showRequests) {
      setShiftReqsLoading(true);
      // jamie
      axios
        .post(
          `${serverURL}/get-shift-edits-for-mgr`,
          {
            teamID: memoVals.selectedShiftTeam,
            countOnly: true,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource4.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (response.data.qty > 0) {
              // open new because there are new requests that haven't been actioned yet
              setShowOutstanding(true);
              setSelectedChangeID("");
              setSelectedNameReq("allStaff");
              setShiftReqsLoading(true);

              axios
                .post(
                  `${serverURL}/get-shift-edits-for-mgr`,
                  {
                    teamID: memoVals.selectedShiftTeam,
                    my: "undecided",
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                    cancelToken: cancelSource5.token,
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    let names = [];
                    let userIDs = [];
                    response.data.arr.forEach((ch) => {
                      if (!userIDs.includes(ch.userID)) {
                        userIDs.push(ch.userID);
                        names.push({
                          userID: ch.userID,
                          name: `${ch.fName} ${ch.lName[0]}`,
                        });
                      }
                    });
                    setNamesOfShiftReqs(names);

                    setShiftReqArr(response.data.arr);
                    setShiftReqsLoading(false);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              axios
                .post(
                  `${serverURL}/get-shift-edits-for-mgr`,
                  {
                    teamID: memoVals.selectedShiftTeam,
                    my: reqMy === "0000" ? tempMy : reqMy,
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                    cancelToken: cancelSource6.token,
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    let names = [];
                    let userIDs = [];
                    response.data.arr.forEach((ch) => {
                      if (!userIDs.includes(ch.userID)) {
                        userIDs.push(ch.userID);
                        names.push({
                          userID: ch.userID,
                          name: `${ch.fName} ${ch.lName[0]}`,
                        });
                      }
                    });
                    setNamesOfShiftReqs(names);

                    setShiftReqArr(response.data.arr);
                    setShiftReqsLoading(false);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
      cancelSource5.cancel("Component unmounted");
      cancelSource6.cancel("Component unmounted");
    };
  }, [
    memoVals.updateShifts,
    memoVals.updateShiftsOnceItemAdded,
    memoVals.update2,
    memoVals.selectedShiftTeam,
    showRequests,
  ]);

  useEffect(() => {
    // clean not neede
    if (memoVals.loadUser && memoVals.loadUser.userID) {
      setMy(memoVals.loadUser.my);
      memoVals.setSelectedName((x) => memoVals.loadUser.userID);
      setShowFilter(true);
      // coils
      memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
    } else {
      memoVals.setSelectedName((x) => "allNames");
      setShowFilter(false);
    }
  }, [memoVals.loadUser]);

  let ddmmToMon21 = (month) => {
    let formatMonth = "Jan";
    let formatYear = month.substr(2, 2);
    if (month.substr(0, 2) === "00") {
      formatMonth = "Jan";
    }
    if (month.substr(0, 2) === "01") {
      formatMonth = "Feb";
    }
    if (month.substr(0, 2) === "02") {
      formatMonth = "Mar";
    }
    if (month.substr(0, 2) === "03") {
      formatMonth = "Apr";
    }
    if (month.substr(0, 2) === "04") {
      formatMonth = "May";
    }
    if (month.substr(0, 2) === "05") {
      formatMonth = "Jun";
    }
    if (month.substr(0, 2) === "06") {
      formatMonth = "Jul";
    }
    if (month.substr(0, 2) === "07") {
      formatMonth = "Aug";
    }
    if (month.substr(0, 2) === "08") {
      formatMonth = "Sep";
    }
    if (month.substr(0, 2) === "09") {
      formatMonth = "Oct";
    }
    if (month.substr(0, 2) === "10") {
      formatMonth = "Nov";
    }
    if (month.substr(0, 2) === "11") {
      formatMonth = "Dec";
    }

    return `${formatMonth} ${formatYear}`;
  };

  let getTeamName = (teamID, teams) => {
    if (teamID) {
      if (teamID === "allStaff") {
        return "all staff you manage";
      } else {
        let teamFilt = teams.filter((item) => {
          return item.teamID === memoVals.selectedShiftTeam;
        });

        if (teamFilt[0]) {
          console.log("!!!", teamFilt[0]);
          return teamFilt[0].teamName;
        }
      }
    }
  };

  let generateTeamsSelectBar = useMemo(() => {
    return memoVals.availableTeamsForShifts
      ? memoVals.availableTeamsForShifts.map((team) => {
          return (
            <option key={team.teamID} value={team.teamID}>
              {dateStringer.shorten(team.teamName, 22)}
            </option>
          );
        })
      : [];
  }, [memoVals.availableTeamsForShifts]);
  let generateMonthOptions = useMemo(() => {
    return availableMonths.map((month) => {
      let formatMonth = "January";
      let formatYear = month.substr(2, 2);
      if (month.substr(0, 2) === "00") {
        formatMonth = "January";
      }
      if (month.substr(0, 2) === "01") {
        formatMonth = "February";
      }
      if (month.substr(0, 2) === "02") {
        formatMonth = "March";
      }
      if (month.substr(0, 2) === "03") {
        formatMonth = "April";
      }
      if (month.substr(0, 2) === "04") {
        formatMonth = "May";
      }
      if (month.substr(0, 2) === "05") {
        formatMonth = "June";
      }
      if (month.substr(0, 2) === "06") {
        formatMonth = "July";
      }
      if (month.substr(0, 2) === "07") {
        formatMonth = "August";
      }
      if (month.substr(0, 2) === "08") {
        formatMonth = "September";
      }
      if (month.substr(0, 2) === "09") {
        formatMonth = "October";
      }
      if (month.substr(0, 2) === "10") {
        formatMonth = "November";
      }
      if (month.substr(0, 2) === "11") {
        formatMonth = "December";
      }

      return (
        <option key={month} value={month}>
          {memoVals.mob ? formatMonth.substr(0, 3) : formatMonth} {formatYear}
        </option>
      );
    });
  }, [availableMonths, memoVals.mob]);

  // allShiftsAndTils.sort(function (a, b) {
  //   return b.startTs - a.startTs;
  // });

  function customSort(a, b) {
    // Compare by startTs first
    if (a.startTs < b.startTs) {
      return -1;
    } else if (a.startTs > b.startTs) {
      return 1;
    } else {
      // If startTs is equal, compare by fName alphabetically
      return a.fName.localeCompare(b.fName);
    }
  }

  allShiftsAndTils.sort(customSort);
  // allShiftsAndTils.sort(function (a, b) {
  //   //   if (!reverse) {
  //   // return b.startTs - a.startTs;
  //   //   } else {
  //   return a.startTs - b.startTs;
  //   //   }
  // });

  // ------------------------------------------------------------------------
  let [itemType, setItemType] = useState("");
  let [itemID, setItemID] = useState("");

  let [hoveredTick, setHoveredTick] = useState("");

  // ------------------------------------------------------------------------

  useEffect(() => {
    // clean not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.swaps) {
      memoVals.setShowTeamSwapsModal((x) => true);
      window.history.pushState({}, document.title, window.location.pathname);
    }
    if (params && params.shiftID && params.type && params.mgr) {
      if (params.type === "unknown") {
        axios
          .post(
            `${serverURL}/check-shift-til`,
            {
              shiftID: params.shiftID,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              memoVals.setShowEditShiftModal((x) => {
                return {
                  itemID: params.shiftID,
                  itemType: response.data.type,
                  itemFName: params.fName,
                  itemLName: params.lName,
                  itemIsNew: false,
                  userID: params.userID,
                };
              });
            }
          });
      } else {
        memoVals.setShowEditShiftModal((x) => {
          return {
            itemID: params.shiftID,
            itemType: params.type,
            itemFName: params.fName,
            itemLName: params.lName,
            itemIsNew: false,
            userID: params.userID,
          };
        });
      }
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  // ------------

  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  // ------------------------------------------------------------------------

  let generateNameOptions = useMemo(() => {
    return names.map((name) => {
      return (
        <option key={Math.random()} value={name.userID}>
          {name.fName} {name.lName[0]}
        </option>
      );
    });
  }, [names]);

  shiftReqArr.sort(function (a, b) {
    return (
      dateStringer.createTimestampFromString(b.dateMade) -
      dateStringer.createTimestampFromString(a.dateMade)
    );
  });

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  let [selectedChangeID, setSelectedChangeID] = useState("");

  let generateShiftRequests = useMemo(() => {
    return changesArr
      .sort(function (a, b) {
        // Custom sorting logic
        if (a.editID === highlightedEditID) {
          return -1; // 'a' comes first
        } else if (b.editID === highlightedEditID) {
          return 1; // 'b' comes first
        } else {
          return 0; // no change in order
        }
      })
      .map((change) => {
        if (change.split && !showOutstanding) {
          return <div className="pendingTsSplitter"></div>;
        } else {
          if (!change.split) {
            if (!change.takeTilRequest) {
              return (
                <div
                  className={`${
                    selectedChangeID === change.editID
                      ? "shiftChangeRow selectedShiftChangeRow"
                      : "shiftChangeRow selectedShiftChangeRow"
                  } ${
                    highlightedEditID === change.editID
                      ? "highlighedChangeID"
                      : ""
                  } ${
                    selectedNameReq === change.userID ||
                    selectedNameReq === "allStaff"
                      ? ""
                      : "none"
                  }  ${
                    !change.approved && !change.declined
                      ? "undeciedBorderLeftChange"
                      : ""
                  }
            
            ${
              !change.approved && change.declined
                ? "declinedBorderLeftChange"
                : ""
            }

            ${
              change.approved && !change.declined
                ? "approvedBorderLeftChange"
                : ""
            } ${memoVals.mob ? "borderRadius10" : ""}`}
                  key={change.editID}
                  onClick={() => {
                    if (change.editID === selectedChangeID) {
                      setSelectedChangeID("");
                    } else {
                      setSelectedChangeID(change.editID);
                    }
                    // setShiftID("");
                    // setIsTilTake(false);
                  }}
                >
                  <div className="shiftChangeTitleAndNote">
                    <p className="shiftChangeTitleTilOrChange">Shift change</p>
                    <p></p>
                  </div>

                  <div className="shiftChangeTitleRow">
                    <p className="shiftChangeType">
                      <span className="shiftTypeSpan">{`${change.fName} ${change.lName[0]}`}</span>{" "}
                      {`requested a change to their `}
                      <span className="shiftTypeSpan">
                        {change.newType === "shift"
                          ? "shift"
                          : `${
                              change.newType === "overtime"
                                ? "overtime"
                                : "shift in lieu"
                            }`}
                      </span>{" "}
                    </p>
                  </div>
                  <div className="changeTitleRowSubBar">
                    <p className="shiftChangeMadeDate">
                      <span className="reqOnSpan">
                        {`${
                          !change.approved && !change.declined
                            ? "Requested"
                            : `${
                                change.approved && !change.declined
                                  ? "Approved"
                                  : "Declined"
                              }`
                        }`}{" "}
                        on
                      </span>{" "}
                      {dateStringer.printedDateFromDs(change.dateMade)}
                      <span className="reqOnSpan"> at</span>{" "}
                      {dateStringer.tsToHHMM(
                        dateStringer.createTimestampFromString(change.dateMade)
                      )}
                    </p>
                    <p
                      className={`${
                        change.requested
                          ? `${
                              change.approved && !change.declined
                                ? "changeStatusApproved"
                                : `${
                                    change.declined
                                      ? "changeStatusDeclined"
                                      : "changeStatus"
                                  }`
                            } `
                          : "none"
                      }`}
                    >
                      {`${
                        change.approved && !change.declined
                          ? "Approved"
                          : `${change.declined ? "Declined" : "Pending"}`
                      }`}
                    </p>
                  </div>
                  <div
                    className={`changeRowMain ${
                      change.requested && change.declined
                        ? "declinedChange"
                        : ""
                    }`}
                  >
                    <div
                      className="changeRowLeft"
                      onClick={() => {
                        // setItemID(change.shiftID);
                        // setItemType(change.newType || change.type);
                        console.log({ change });
                        memoVals.setShowEditShiftModal((x) => {
                          return {
                            itemID: change.shiftID,
                            itemType: change.newType || change.type,
                            itemFName: change.fName,
                            itemLName: change.lName,
                            itemIsNew: false,
                            userID: change.userID,
                          };
                        });
                      }}
                    >
                      <p className="oldShiftDate textAlignLeft">
                        {dateStringer.printedDateWithYrFromDs(change.oldDate)}
                      </p>
                      <p className="oldShiftTimes textAlignLeft">
                        {change.oldStart} - {change.oldEnd}
                      </p>
                      <p className="oldShiftBrk textAlignLeft">
                        {change.oldBrk}m break
                      </p>
                    </div>
                    <div className="changeRowMiddle">
                      <img
                        src={shiftChangeArrow}
                        // src={`${
                        //   change.requested
                        //     ? `${
                        //         !change.declined && change.approved
                        //           ? shiftChangeArrowGreen
                        //           : `${
                        //               change.declined
                        //                 ? shiftChangeArrowRed
                        //                 : shiftChangeArrow
                        //             }`
                        //       }`
                        //     : shiftChangeArrow
                        // }`}
                        alt="arrow"
                        className="changeShiftArrowImg"
                      />
                    </div>
                    <div
                      className="changeRowRight"
                      onClick={() => {
                        // setItemID(change.shiftID);
                        // setItemType(change.newType || change.type);

                        // memoVals.setShowEditShiftModal(x => {returntrue);
                        memoVals.setShowEditShiftModal((x) => {
                          return {
                            itemID: change.shiftID,
                            itemType: change.newType || change.type,
                            itemFName: change.fName,
                            itemLName: change.lName,
                            itemIsNew: false,
                            userID: change.userID,
                          };
                        });
                      }}
                    >
                      <p
                        className={`oldShiftDate ${
                          change.newDate.substr(0, 11) !==
                          change.oldDate.substr(0, 11)
                            ? "highlightedChangeElement"
                            : ""
                        }`}
                      >
                        {dateStringer.printedDateWithYrFromDs(change.newDate)}
                      </p>
                      <p
                        className="oldShiftTimes"
                        // {`oldShiftTimes
                        // ${
                        //   change.oldStart !== change.newStart ||
                        //   change.oldEnd !== change.newEnd
                        //     ? "highlightedChangeElement"
                        //     : ""
                        // }
                        // `}
                      >
                        <span
                          className={`${
                            change.newStart !== change.oldStart
                              ? "highlightedChangeElement"
                              : ""
                          }`}
                        >
                          {change.newStart}
                        </span>{" "}
                        -{" "}
                        <span
                          className={`${
                            change.newEnd !== change.oldEnd
                              ? "highlightedChangeElement"
                              : ""
                          }`}
                        >
                          {change.newEnd}
                        </span>
                      </p>
                      <p
                        className={`${
                          change.newBrk !== change.oldBrk
                            ? "highlightedChangeElement"
                            : ""
                        } oldShiftBrk`}
                      >
                        {change.newBrk}m break
                      </p>
                    </div>
                  </div>
                  <div className="decisionRequestShiftDiv">
                    {change.note ? (
                      <p className="changeNoteDiv">
                        <span className="changeNoteTitleSpan">
                          Note left by {change.fName}:{" "}
                        </span>
                        {change.note}
                      </p>
                    ) : (
                      ""
                    )}
                    <div
                      className={`${
                        selectedChangeID === change.editID
                          ? "deleteChangeUnit"
                          : "deleteChangeUnit"
                      }`}
                    >
                      <div className="deleteChangeUnitLeft">
                        <p className="oldChangeUnitDur">
                          <span className="oldDurationTitleSpan">
                            Old duration:&nbsp;
                          </span>
                          {dateStringer.formatMinsDurationToHours(
                            change.oldDuration,
                            false,
                            true
                          )}
                        </p>
                        <p className="oldChangeUnitDur">
                          <span className="oldDurationTitleSpan">
                            New duration:
                          </span>{" "}
                          {dateStringer.formatMinsDurationToHours(
                            change.newDuration,
                            false,
                            true
                          )}
                        </p>
                      </div>
                      {change.approved || change.declined ? (
                        <p
                          className={`${
                            change.approved || change.declined
                              ? "shiftEditDecidedBy"
                              : "none"
                          }`}
                        >
                          <span className="decidedBySpan">{`${
                            change.approved ? "Approved by " : "Declined by "
                          }`}</span>
                          <br />
                          {change.decidedBy || "unknown"}
                        </p>
                      ) : (
                        <div className="approveOrDeclineChangeReqDiv">
                          <p
                            className="approveShChangeReqBtn"
                            onClick={() => {
                              // setApproveLoading(true);
                              setShiftReqsLoading(true);
                              let newArr = [];
                              shiftReqArr.forEach((obj) => {
                                if (
                                  obj.editID !== change.editID ||
                                  change.takeTilRequest
                                ) {
                                  newArr.push(obj);
                                }
                              });

                              let qty = reqQty;
                              qty--;
                              setReqQty(qty);
                              setShiftReqArr(newArr);
                              axios
                                .post(
                                  `${serverURL}/approve-or-decline-shift-edit`,
                                  {
                                    editID: change.editID,
                                    userID: change.userID,
                                    decision: "approve",
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    // setApproveLoading(false);

                                    let newArr = [];
                                    shiftReqArr.forEach((obj) => {
                                      if (
                                        obj.editID !== change.editID ||
                                        change.takeTilRequest
                                      ) {
                                        newArr.push(obj);
                                      }
                                    });

                                    let qty = reqQty;
                                    qty--;
                                    setReqQty(qty);
                                    setShiftReqArr(newArr);
                                    setTimeout(() => {
                                      setShiftReqsLoading(false);
                                    }, 1000);

                                    // setUpdateShiftReqs(!memoVals.updateShiftReqs);
                                  }

                                  if (
                                    response.data.message.includes(
                                      "no longer exist"
                                    )
                                  ) {
                                    // setApproveLoading(false);

                                    let newArr = [];
                                    shiftReqArr.forEach((obj) => {
                                      if (
                                        obj.editID !== change.editID ||
                                        change.takeTilRequest
                                      ) {
                                        newArr.push(obj);
                                      }
                                    });

                                    let qty = reqQty;
                                    qty--;
                                    setReqQty(qty);
                                    setShiftReqArr(newArr);
                                    setShiftReqsLoading(false);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Approve
                          </p>
                          <p
                            className="declineShChangeReqBtn"
                            onClick={() => {
                              // setApproveLoading(true);
                              setShiftReqsLoading(true);

                              let newArr = [];
                              shiftReqArr.forEach((obj) => {
                                if (
                                  obj.editID !== change.editID ||
                                  change.takeTilRequest
                                ) {
                                  newArr.push(obj);
                                }
                              });

                              let qty = reqQty;
                              qty--;
                              setReqQty(qty);
                              setShiftReqArr(newArr);
                              axios
                                .post(
                                  `${serverURL}/approve-or-decline-shift-edit`,
                                  {
                                    editID: change.editID,
                                    userID: change.userID,
                                    decision: "decline",
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    // setApproveLoading(false);

                                    let newArr = [];
                                    shiftReqArr.forEach((obj) => {
                                      if (
                                        obj.takeTilRequest ||
                                        obj.editID !== change.editID
                                      ) {
                                        newArr.push(obj);
                                      }
                                    });
                                    let qty = reqQty;
                                    qty--;
                                    setReqQty(qty);
                                    setShiftReqArr(newArr);
                                    setTimeout(() => {
                                      setShiftReqsLoading(false);
                                    }, 1000);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Decline
                          </p>
                        </div>
                      )}
                    </div>

                    {/* end of sub bar */}
                  </div>
                  {/* end of decision sub bar */}

                  {/* <p>{change.oldDate}</p> */}
                </div>
              );
            }

            if (change.takeTilRequest) {
              return (
                <div
                  className={`${
                    selectedChangeID === change.takeTilID
                      ? "shiftChangeRow selectedShiftChangeRow"
                      : "shiftChangeRow"
                  } ${
                    selectedNameReq === change.userID ||
                    selectedNameReq === "allStaff"
                      ? ""
                      : "none"
                  } ${
                    !change.approved && !change.declined
                      ? "undeciedBorderLeftChange"
                      : ""
                  }
            
            ${
              !change.approved && change.declined
                ? "declinedBorderLeftChange"
                : ""
            }

            ${
              change.approved && !change.declined
                ? "approvedBorderLeftChange"
                : ""
            } ${memoVals.mob ? "borderRadius10" : ""}
            
            `}
                  key={change.takeTilID}
                  onClick={() => {
                    console.log({
                      approved: change.approved,
                      declined: change.declined,
                    });
                    if (change.takeTilID === selectedChangeID) {
                      setSelectedChangeID("");
                    } else {
                      setSelectedChangeID(change.takeTilID);
                    }

                    // setIsTilTake(true);
                    // setShiftID(change.shiftID);
                  }}
                >
                  <div className="shiftChangeTitleAndNote">
                    <p className="shiftChangeTitleTilOrChange">Take TOIL</p>
                    <p></p>
                  </div>{" "}
                  <div className="shiftChangeTitleRow">
                    <p className="shiftChangeType">
                      <span className="shiftTypeSpan">{`${change.fName} ${change.lName[0]}`}</span>{" "}
                      {`asked to shorten their `}
                      <span className="shiftTypeSpan">
                        {change.type === "shift" ? "shift" : "overtime"}
                      </span>{" "}
                      with their TOIL allowance
                    </p>
                  </div>{" "}
                  <div className="changeTitleRowSubBar">
                    <p className="shiftChangeMadeDate">
                      <span className="reqOnSpan">
                        {`${
                          !change.approved && !change.declined
                            ? "Requested"
                            : `${
                                change.approved && !change.declined
                                  ? "Approved"
                                  : "Declined"
                              }`
                        }`}{" "}
                        on
                      </span>{" "}
                      {dateStringer.printedDateFromDs(change.dateMade)}
                      <span className="reqOnSpan"> at</span>{" "}
                      {dateStringer.tsToHHMM(
                        dateStringer.createTimestampFromString(change.dateMade)
                      )}
                    </p>
                    <p
                      className={`${
                        change.requested
                          ? `${
                              change.approved && !change.declined
                                ? "changeStatusApproved"
                                : `${
                                    change.declined
                                      ? "changeStatusDeclined"
                                      : "changeStatus"
                                  }`
                            } `
                          : "none"
                      }`}
                    >
                      {`${
                        change.approved && !change.declined
                          ? "Approved"
                          : `${change.declined ? "Declined" : "Pending"}`
                      }`}
                    </p>
                  </div>
                  <div className="changeTitleRowSubBar toilAllowRow">
                    <div className="shiftChangeMadeDate">
                      {change.tilMins <= change.availableTil ? (
                        <span className="sufficientTil">
                          Sufficient allowance{" "}
                          <img
                            src={unitTick}
                            alt="Sufficient"
                            className="sufficientTilTick"
                          />
                        </span>
                      ) : (
                        <span className="insufficientTil">
                          Insufficient allowance{" "}
                          <img
                            src={unitCross}
                            alt="Sufficient"
                            className="insufficientTilTick"
                          />
                        </span>
                      )}{" "}
                      <p className="x2831981s1981">
                        {dateStringer.formatMinsDurationToHours(
                          change.availableTil,
                          false,
                          true
                        )}{" "}
                        TOIL available
                      </p>
                    </div>
                    {/* <p
                className={`${
                  change.requested
                    ? `${
                        change.approved && !change.declined
                          ? "changeStatusApproved"
                          : `${
                              change.declined
                                ? "changeStatusDeclined"
                                : "changeStatus"
                            }`
                      } `
                    : "none"
                }`}
              >
                {`${
                  change.approved && !change.declined
                    ? "Approved"
                    : `${change.declined ? "Declined" : "Pending"}`
                }`}
              </p> */}
                  </div>
                  {/*  */}
                  <div
                    className={`changeRowMain ${
                      change.requested && change.declined
                        ? "declinedChange"
                        : ""
                    }`}
                  >
                    <div
                      className="changeRowLeft"
                      onClick={() => {
                        memoVals.setShowEditShiftModal((x) => {
                          return {
                            itemID: change.shiftID,
                            itemType: change.newType || change.type,
                            itemFName: change.fName,
                            itemLName: change.lName,
                            itemIsNew: false,
                            userID: change.userID,
                          };
                        });
                      }}
                    >
                      <p className="oldShiftDate textAlignLeft">
                        {dateStringer.printedDateWithYrFromDs(change.oldDs)}
                      </p>
                      <p className="oldShiftTimes textAlignLeft">
                        {change.oldStart} - {change.oldEnd}
                      </p>
                      <p className="oldShiftBrk textAlignLeft">
                        {change.oldBrk}m break
                      </p>
                    </div>
                    <div className="changeRowMiddle">
                      <img
                        src={`${
                          change.requested
                            ? `${
                                !change.declined && change.approved
                                  ? shiftChangeArrowGreen
                                  : `${
                                      change.declined
                                        ? shiftChangeArrowRed
                                        : shiftChangeArrow
                                    }`
                              }`
                            : shiftChangeArrow
                        }`}
                        alt="arrow"
                        className="changeShiftArrowImg"
                      />
                    </div>
                    <div
                      className="changeRowRight"
                      onClick={() => {
                        memoVals.setShowEditShiftModal((x) => {
                          return {
                            itemID: change.shiftID,
                            itemType: change.newType || change.type,
                            itemFName: change.fName,
                            itemLName: change.lName,
                            itemIsNew: false,
                            userID: change.userID,
                          };
                        });
                      }}
                    >
                      <p
                        className={`oldShiftDate ${
                          change.oldDs.substr(0, 11) !==
                          change.oldDs.substr(0, 11)
                            ? "highlightedChangeElement"
                            : ""
                        } ${
                          change.tilMins > change.availableTil ? "colorRed" : ""
                        }`}
                      >
                        <span className="useTilTxt">TOIL to use:</span>{" "}
                        <span className="spanNoBreakLine cx020948">
                          {dateStringer.formatMinsDurationToHours(
                            change.tilMins,
                            false,
                            true
                          )}
                        </span>
                      </p>
                      <div className="tilTimesStrip">
                        {/* <p className="tilStripTitle">TOIL: </p> */}
                        {change.start === change.oldStart &&
                        change.end === change.oldEnd ? (
                          <p className="oldShiftTimes highlighedTilTimes noBorder">
                            Whole shift
                          </p>
                        ) : (
                          <p className="oldShiftTimes highlighedTilTimes">
                            <span className="">{change.start}</span> -{" "}
                            <span className="">{change.end}</span>
                          </p>
                        )}
                      </div>
                      <p
                        className={`tilNewShiftTime ${
                          change.start !== change.oldStart &&
                          change.end !== change.oldEnd
                            ? "smallerTilTwoShifts"
                            : ""
                        } ${
                          dateStringer
                            .generateTilShiftString(
                              change.start,
                              change.end,
                              change.oldStart,
                              change.oldEnd,
                              false,
                              false,
                              change.type,
                              true
                            )
                            .includes("Whole")
                            ? "none"
                            : ""
                        }`}
                      >
                        {dateStringer.generateTilShiftString(
                          change.start,
                          change.end,
                          change.oldStart,
                          change.oldEnd,
                          false,
                          false,
                          change.type,
                          true
                        )}
                      </p>
                    </div>
                  </div>
                  {change.note ? (
                    <p className="changeNoteDiv">
                      <span className="changeNoteTitleSpan">
                        Note left by {change.fName}:{" "}
                      </span>
                      {change.note}
                    </p>
                  ) : (
                    ""
                  )}
                  <div
                    className={`${
                      selectedChangeID === change.takeTilID
                        ? "deleteChangeUnit"
                        : "deleteChangeUnit"
                    }`}
                  >
                    <div className="deleteChangeUnitLeft">
                      <p className="oldChangeUnitDur">
                        <span className="oldDurationTitleSpan">
                          Old duration:&nbsp;
                        </span>
                        {dateStringer.formatMinsDurationToHours(
                          change.oldDur,
                          false,
                          true
                        )}
                      </p>
                      <p className="oldChangeUnitDur">
                        <span className="oldDurationTitleSpan">
                          New duration:
                        </span>{" "}
                        {dateStringer.formatMinsDurationToHours(
                          change.oldDur - change.tilMins,
                          false,
                          true
                        )}
                      </p>
                    </div>

                    {change.approved || change.declined ? (
                      <p
                        className={`${
                          change.approved || change.declined
                            ? "shiftEditDecidedBy"
                            : "none"
                        }`}
                      >
                        <span className="decidedBySpan">{`${
                          change.approved ? "Approved by " : "Declined by "
                        }`}</span>
                        <br />
                        {change.decidedBy || "unknown"}
                      </p>
                    ) : (
                      <div className="approveOrDeclineChangeReqDiv">
                        <p
                          className="approveShChangeReqBtn"
                          onClick={() => {
                            // setApproveLoading(true);
                            setShiftReqsLoading(true);

                            let newArr = [];
                            shiftReqArr.forEach((obj) => {
                              if (
                                !obj.takeTilRequest ||
                                obj.takeTilID !== change.takeTilID
                              ) {
                                newArr.push(obj);
                              }
                            });
                            let qty = reqQty;
                            qty--;
                            setReqQty(qty);
                            setShiftReqArr(newArr);
                            axios
                              .post(
                                `${serverURL}/approve-or-decline-take-til-request`,
                                {
                                  takeTilID: change.takeTilID,
                                  userID: change.userID,
                                  decision: "approve",
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (
                                  response.data.message === "success" ||
                                  response.data.message.includes("no longer")
                                ) {
                                  // setApproveLoading(false);
                                  if (
                                    response.data.message.includes("no longer")
                                  ) {
                                    memoVals.setIndicate((x) => {
                                      return {
                                        show: true,
                                        message: "Request no longer exists",
                                        colour: "red",
                                        duration: 4000,
                                      };
                                    });
                                  }

                                  setShiftReqArr(newArr);
                                  setTimeout(() => {
                                    setShiftReqsLoading(false);
                                  }, 1000);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Approve
                        </p>
                        <p
                          className="declineShChangeReqBtn"
                          onClick={() => {
                            // setApproveLoading(true);
                            setShiftReqsLoading(true);

                            let newArr = [];
                            shiftReqArr.forEach((obj) => {
                              if (
                                !obj.takeTilRequest ||
                                obj.takeTilID !== change.takeTilID
                              ) {
                                newArr.push(obj);
                              }
                            });
                            let qty = reqQty;
                            qty--;
                            setReqQty(qty);
                            axios
                              .post(
                                `${serverURL}/approve-or-decline-take-til-request`,
                                {
                                  takeTilID: change.takeTilID,
                                  userID: change.userID,
                                  decision: "decline",
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (
                                  response.data.message === "success" ||
                                  response.data.message.includes("no longer")
                                ) {
                                  // setApproveLoading(false);
                                  if (
                                    response.data.message.includes("no longer")
                                  ) {
                                    memoVals.setIndicate((x) => {
                                      return {
                                        show: true,
                                        message: "Request no longer exists",
                                        colour: "red",
                                        duration: 4000,
                                      };
                                    });
                                  }

                                  setShiftReqArr(newArr);
                                  setTimeout(() => {
                                    setShiftReqsLoading(false);
                                  }, 1000);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Decline
                        </p>
                      </div>
                    )}
                  </div>
                  {/*  */}
                  {/* end of decision sub bar */}
                </div>
              );
            }
          }
        }
      });
  }, [changesArr, selectedChangeID]);

  let pendingsExist = () => {
    let pend = 0;

    shiftReqArr.forEach((x) => {
      if (!x.approved && !x.declined) {
        pend++;
      }
    });
    return pend;
  };

  //  - - - - - - - - -
  let generateTeamShiftsAndTils = useMemo(() => {
    return allShiftsAndTils.map((unit) => {
      if (
        (showShifts && unit.type === "shift") ||
        (showTils && unit.type === "til") ||
        (showOvertimes && unit.type === "overtime")
      ) {
        let printShiftDurationSubTxt = () => {
          // house
          if (unit.type === "shift") {
            if (unit.data.duration === 0 || !unit.data.duration) {
              return "0 mins";
            }
          } else {
            if (unit.data.durMins === 0 || !unit.data.durMins) {
              return "0 mins";
            }
          }
          if (
            // unit.type === "shift" &&
            unit.data.absent &&
            !unit.data.partialAbsent &&
            unit.data.unpaidAbsent
          ) {
            return "0 hours";
          } else {
            return `${dateStringer.formatMinsDurationToHours(
              `${
                unit.type === "shift"
                  ? `${(unit.data.duration || 0) / 1000 / 60}`
                  : `${unit.data.durMins || 0}`
              }`,
              false,
              true
            )}`;
          }
        };

        let oldStart = "00:00";
        let oldEnd = "00:00";
        if (unit.type === "shift") {
          oldStart = `${unit.data.start.substr(12, 2)}:${unit.data.start.substr(
            15,
            2
          )}`;
          oldEnd = `${unit.data.end.substr(12, 2)}:${unit.data.end.substr(
            15,
            2
          )}`;
        } else {
          if (unit.data) {
            oldStart = unit.data.start || "11:11";
            oldEnd = unit.data.end || "11:11";
          }
        }

        let dateObj = new Date(unit.startTs);
        let dateN = dateObj.getDate();

        let isFuture = () => {
          let tonight2359 = new Date();
          tonight2359.setHours(23, 59, 0, 0);
          return unit.startTs > tonight2359.getTime();
        };
        let dateStrip1 = dateStringer.tsToDayAndDate(
          unit.startTs,
          true
          // window.innerWidth < 100
        );
        let dateStrip = dateStringer.tsToDayAndDate(
          unit.startTs,
          dateStrip1.includes("Wed") && unit.type === "overtime" ? false : true
          // window.innerWidth < 100
        );
        return (
          <div
            key={unit.key}
            className={`teamShiftUnit ${
              (unit.data.absent || unit.data.selfLoggedAbsence) && unit.tags[0]
                ? "tagsAndAbsent"
                : ""
            } ${
              (unit.data.absent || unit.data.selfLoggedAbsence) && !unit.tags[0]
                ? "noTagsAbsent"
                : ""
            }
        
        
        ${
          ((!showShifts && !showTils && !showOvertimes) ||
            (showShifts && unit.type === "shift") ||
            (showTils && unit.type === "til") ||
            (showOvertimes && unit.type === "overtime")) &&
          (memoVals.selectedName === "allNames" ||
            memoVals.selectedName === unit.userID) &&
          (memoVals.selectedDay === "allDays" ||
            parseInt(memoVals.selectedDay) === dateN)
            ? ""
            : "none"
        }
        
        `}
          >
            <div
              onClick={() => {
                memoVals.setOpenManagerShiftModal((x) => {
                  return {
                    shiftID: unit.type === "shift" ? unit.typeID : "",
                    tilID: unit.type !== "shift" ? unit.typeID : "",
                    fName: unit.fName,
                    lName: unit.lName,
                    openedFromShiftsPage: true,
                    type: unit.type,
                  };
                });
              }}
              className={`${
                ((showShifts && unit.type === "shift") ||
                  (showTils && unit.type === "til") ||
                  (showOvertimes && unit.type === "overtime")) &&
                (memoVals.selectedName === "allNames" ||
                  memoVals.selectedName === unit.userID) &&
                (memoVals.selectedDay === "allDays" ||
                  parseInt(memoVals.selectedDay) === dateN)
                  ? `${
                      loading
                        ? "none"
                        : `tilShiftUnit teamTilShiftUnit ${
                            memoVals.mob ? "borderRadius10" : ""
                          } ${
                            (unit.tags && unit.tags[0]) ||
                            unit.data.absent ||
                            unit.data.selfLoggedAbsence
                              ? "tagsOnShiftExtend"
                              : ""
                          } ${unit.data.ghost ? "ghostBgShiftTilItem" : ""}`
                    }`
                  : "none"
              } 
        
        
     
        `}
              key={unit.key}
            >
              {/* stella */}{" "}
              <div
                className={`myHoursLeftWrapper ${
                  unit.tags && unit.tags[0] && unit.outstandingChanges
                    ? `outstandingWithTagsHeight `
                    : ""
                } ${
                  unit.data.selfLoggedAbsence && unit.outstandingChanges
                    ? "x9991112293312"
                    : ""
                }`}
              >
                {unit.outstandingChanges ? (
                  <p
                    className={`shiftUnitOutstandingChanges2 ${
                      unit.tags && unit.tags[0] ? "tagsAndOutstandingCh" : ""
                    } ${unit.data.selfLoggedAbsence ? "x09838444" : ""}`}
                  >
                    {/* {unit.outstandingChanges > 9 ? "9+" : unit.outstandingChanges}{" "} */}
                    Change request{unit.outstandingChanges > 1 ? "s" : ""}
                  </p>
                ) : (
                  ""
                )}
                <div
                  className="myHoursLeftSec"
                  onMouseEnter={() => {
                    if (!memoVals.mob) {
                      console.log(unit.typeID);
                      setHoveredTick(unit.typeID);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!memoVals.mob) {
                      setHoveredTick("");
                    }
                  }}
                >
                  {unit.data.selfLoggedAbsence || unit.data.ghost ? (
                    ""
                  ) : unit.data.requireAccept ? (
                    !unit.data.accepted ? (
                      <Fragment>
                        {/* <div
                      className={`reqAcceptIcon reqAcceptIconMgr ${
                        unit.outstandingChanges ? "xjd98222" : ""
                      }`}
                      onMouseEnter={() => {
                        if (!memoVals.mob) {
                          console.log(unit.typeID);
                          setHoveredTick(unit.typeID);
                        }
                      }}
                      onMouseLeave={() => {
                        if (!memoVals.mob) {
                          setHoveredTick("");
                        }
                      }}
                    ></div> */}
                        <img
                          src={lightBlueQuestionMark}
                          alt="Accepted"
                          className={`acceptedDoubleTickMyHours xg27381`}
                        />
                        {unit.typeID === hoveredTick &&
                        hoveredTick &&
                        !loading ? (
                          <p
                            className={`accHover x3d11 ${
                              unit.outstandingChanges
                                ? "accHoverWithChange"
                                : ""
                            }`}
                          >
                            Not yet accepted by {unit.fName}
                          </p>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {" "}
                        <img
                          src={doubleTick}
                          alt="Accepted"
                          className={`acceptedDoubleTickMyHours ${
                            unit.outstandingChanges ? "acceptedByChangeReq" : ""
                          }`}
                          onMouseEnter={() => {
                            if (!memoVals.mob) {
                              console.log(unit.typeID);
                              setHoveredTick(unit.typeID);
                            }
                          }}
                          onMouseLeave={() => {
                            if (!memoVals.mob) {
                              setHoveredTick("");
                            }
                          }}
                        />
                        {unit.typeID === hoveredTick && hoveredTick ? (
                          <p
                            className={`accHover ${
                              unit.outstandingChanges
                                ? "accHoverWithChange"
                                : ""
                            }`}
                          >
                            Accepted by {unit.fName} on{" "}
                            {dateStringer.printedDateFromDs(unit.data.accepted)}
                          </p>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    )
                  ) : (
                    ""
                  )}
                  <img
                    src={
                      unit.data.ghost
                        ? zzz
                        : unit.data.selfLoggedAbsence
                        ? exclaimWhite
                        : time
                    }
                    alt="My hours"
                    className={`timeHoursImg ${
                      unit.data.selfLoggedAbsence ? "timeHoursImgAbs" : ""
                    }`}
                  />
                  {/* <img src={time} alt="My hours" className="timeHoursImg" /> */}
                </div>

                {/* <img
              src={unit.data.selfLoggedAbsence ? exclaimWhite : time}
              alt="My hours"
              className={`timeHoursImg ${
                unit.data.selfLoggedAbsence ? "timeHoursImgAbs" : ""
              }`}
            /> */}
                <div
                  className={`reqColBarDiv ${
                    unit.startTs <= new Date().getTime()
                      ? "reqColBarAdjust3 divApp"
                      : "reqColBarAdjust6"
                  } divPend ${unit.absent ? "divDec" : ""} `}
                ></div>
                <div
                  className={`tilShiftSection1team ${
                    unit.outstandingChanges ? "pushSec1Down" : ""
                  } ${!memoVals.mob ? "tilShiftSection1teamDesktop" : ""}`}
                >
                  <p
                    className={`tilShiftUnitName ${
                      unit.tilApproved && !unit.wholeTil ? "x9749725" : ""
                    } ${unit.data.ghost ? "colourWhite" : ""} ${
                      dateStrip.includes("Wed") && unit.type === "overtime"
                        ? "x9939232_"
                        : ""
                    }`}
                    onClick={() => {
                      console.log("unit: ", unit);
                    }}
                  >
                    {dateStrip}
                    <span className="ndStRdSpan2">
                      {dateStringer.nd(
                        dateStringer
                          .createStringFromTimestamp(unit.startTs)
                          .substr(9, 2)
                      )}
                      {/* {dateStrip[dateStrip.length - 1] === "1"
                    ? dateStrip[dateStrip.length - 2] === "1"
                      ? "th"
                      : "st"
                    : dateStrip[dateStrip.length - 1] === "2"
                    ? "nd"
                    : dateStrip[dateStrip.length - 1] === "3"
                    ? "rd"
                    : "th"} */}
                    </span>
                    {!unit.data.ghost && (
                      <p className="shiftTilNameTxt shiftTilNameTxtHours">
                        {unit.type === "til"
                          ? "Lieu"
                          : unit.type === "shift"
                          ? "Shift"
                          : "Overtime"}
                      </p>
                    )}
                    {/* {dateStringer.nd(unit.data.start.substr(9, 2))} */}

                    {/* {unit.fName} {unit.lName[0]} */}
                  </p>
                  <div
                    className={`tilShiftDsTeam ${
                      unit.data.ghost ? "x08141" : ""
                    }`}
                  >
                    {/* <p className="teamHoursDsStrip">
                  {dateStringer.tsToDayAndDate(unit.startTs)}
                </p>{" "} */}
                    {unit.data.ghost ? (
                      <p className="tilShiftTimes colourwhite">Paid day off</p>
                    ) : (
                      <p
                        className={`${
                          unit.tilApproved && !unit.wholeTil
                            ? `tilShiftTimes x1972141 ${
                                dateStringer.generateTilShiftString(
                                  unit.tilStart || "00:00",
                                  unit.tilEnd || "00:00",
                                  oldStart || "00:00",
                                  oldEnd || "00:00",
                                  false,
                                  unit.type
                                ).length > 17
                                  ? "smallerTxtTimeTilTeamStrip"
                                  : ""
                              }`
                            : dateStringer
                                .generateTilShiftString(
                                  unit.tilStart || "00:00",
                                  unit.tilEnd || "00:00",
                                  oldStart || "00:00",
                                  oldEnd || "00:00",
                                  false,
                                  unit.type
                                )
                                .includes("Whole")
                            ? ""
                            : "none"
                        }`}
                      >
                        <img
                          src={rotaClock}
                          alt="Clock"
                          className={`teamHoursRotaClock ${
                            unit.tilApproved && !unit.wholeTil
                              ? "x19749111"
                              : ""
                          }`}
                        />{" "}
                        {dateStringer.generateTilShiftString(
                          unit.tilStart || "00:00",
                          unit.tilEnd || "00:00",
                          oldStart || "00:00",
                          oldEnd || "00:00",
                          false,
                          false,
                          unit.type
                        )}
                      </p>
                    )}
                    {/* if not a til, no til string needed, just use normal time string: */}
                    {!unit.data.ghost && (
                      <p
                        className={`${
                          !unit.tilApproved ? "tilShiftTimes x1972141 " : "none"
                        }`}
                      >
                        <img
                          src={rotaClock}
                          alt="Clock"
                          className="teamHoursRotaClock"
                        />
                        {unit.type === "shift"
                          ? `${unit.data.start.substr(
                              12,
                              2
                            )}:${unit.data.start.substr(
                              15,
                              2
                            )} - ${unit.data.end.substr(
                              12,
                              2
                            )}:${unit.data.end.substr(15, 2)}`
                          : `${unit.data.start} - ${unit.data.end}`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="tilShiftRightParent">
                <div className="tilShiftSection2team">
                  <p
                    className={`${
                      unit.data.ghost ? "none" : "tilShiftTypeTxtTeam"
                    }`}
                  >
                    {unit.type === "shift"
                      ? "Shift"
                      : `${
                          unit.type === "overtime"
                            ? "Overtime"
                            : "Shift in lieu"
                        }`}
                  </p>
                  <div className="hoursAndCostContainer">
                    <p
                      className={`tilShiftDurationTxtTeam ${
                        // unit.type === "shift" &&
                        unit.data.absent && !unit.data.partialAbsent
                          ? // &&
                            // unit.data.unpaidAbsent
                            `${
                              unit.data.unpaidAbsent
                                ? "absentSubTxtBarShifts"
                                : "absentButPaid"
                            }`
                          : ""
                      } ${
                        // unit.type === "shift" &&
                        unit.data.absent && unit.data.partialAbsent
                          ? `${
                              unit.data.unpaidAbsent
                                ? "partialAbsentSubTxtBarShifts"
                                : "partialAbsentSubTxtBarShiftsPaid"
                            }`
                          : ""
                      } ${unit.data.ghost ? "none" : ""}`}
                    >
                      {printShiftDurationSubTxt()}
                    </p>
                    {unit.cost ? (
                      <p
                        className={`hoursCostItem ${
                          isFuture() ? "isFutureTeamHoursCost" : ""
                        }`}
                      >
                        {memoVals.currencySymbol}
                        {Math.round(unit.cost)}
                      </p>
                    ) : (
                      ""
                    )}
                    <p
                      className={`${
                        unit.data.ghost ? "paidDayOffSubTxt" : "none"
                      }`}
                    >
                      Paid day off
                    </p>
                  </div>
                  <p
                    className={`teamClockBreakDurStrip x131453678 ${
                      memoVals.mob || memoVals.fullButNarrow
                        ? "myHoursUnitTeamNameMob"
                        : ""
                    } ${unit.data.ghost ? "colourWhite" : ""}`}
                  >
                    {dateStringer.shorten(
                      unit.teamName,
                      memoVals.mob || memoVals.fullButNarrow ? 39 : 35
                    )}{" "}
                    <img src={teamIcon} alt="Team" className="teamIconHours" />
                  </p>{" "}
                </div>
                <div
                  className={`itemTongue shiftTongueMgr_ ${
                    unit.type === "shift"
                      ? ""
                      : unit.type === "til"
                      ? "tilTongueMgr_"
                      : "overtimeTongueMgr"
                  } overtimeTongueMgr`}
                >
                  <p className="tongueTxt leaveTongueTxt hoursTongueMgrTxt">
                    HOURS
                    {/* {unit.type === "til"
                  ? "TOIL"
                  : unit.type === "shift"
                  ? "Shift"
                  : "Overtime"} */}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`${
                (unit.data.absent || unit.data.selfLoggedAbsence) && !loading
                  ? "subShiftAbsenceContainer"
                  : "none"
              }`}
            >
              <div
                className={`subShiftAbsenceHolder ${
                  unit.data.selfLoggedAbsence ? "selfLoggAbsColours" : ""
                }`}
              >
                <p
                  className={`${
                    unit.data.absent && !unit.data.partialAbsent ? "" : "none"
                  }`}
                >
                  Absent
                </p>
                <p
                  className={`${
                    unit.data.absent && unit.data.partialAbsent ? "" : "none"
                  }`}
                >
                  Partially absent
                </p>
                <p className={`${unit.data.selfLoggedAbsence ? "" : "none"}`}>
                  Reported absent
                </p>
              </div>
            </div>

            <div
              className={`mgrClockNameDiv teamShiftNameTab ${
                memoVals.iPad ? "x113198391" : ""
              } ${
                (!unit.tags && unit.data.absent) ||
                (unit.tags && !unit.tags[0] && unit.data.absent) ||
                (unit.data.selfLoggedAbsence && unit.tags && !unit.tags[0]) ||
                (unit.data.selfLoggedAbsence && !unit.tags)
                  ? "x1941971"
                  : ""
              } ${
                unit.tilApproved && !unit.wholeTil
                  ? `${unit.tags && unit.tags[0] ? "x1974971" : "x08917471"}`
                  : unit.tags && unit.tags[0]
                  ? "x29749711"
                  : ""
              } ${unit.outstandingChanges ? "x0841" : ""} ${
                (unit.data.partialAbsent && unit.data.absent) ||
                unit.data.selfLoggedAbsence
                  ? "x104811"
                  : ""
              } ${unit.data.ghost ? "x2084971" : ""}`}
            >
              <img
                src={unit.picUrl || profilePicturePlaceholder}
                alt={unit.fName}
                className="itemNameTabImg"
              />{" "}
              {unit.fName} {unit.lName}
            </div>
            {unit.tags && unit.tags[0] ? (
              <div
                className={`${
                  unit.tags && unit.tags[0]
                    ? `teamShiftTagsContainer ${
                        unit.tilApproved && !unit.wholeTil ? "x1084971971" : ""
                      } ${
                        (unit.data.absent && unit.data.partialAbsent) ||
                        unit.data.selfLoggedAbsence
                          ? "x1794911"
                          : ""
                      }`
                    : "none"
                }`}
              >
                <p className="teamShiftTagItem">{formatTag(unit.tags[0])}</p>
                <p
                  className={`${
                    unit.tags[1] && !memoVals.mob ? "teamShiftTagItem" : "none"
                  }`}
                >
                  {formatTag(unit.tags[1])}
                </p>
                <p
                  className={`${
                    unit.tags[memoVals.mob ? 1 : 2]
                      ? "teamShiftTagsSurplusIndicator"
                      : "none"
                  }`}
                >
                  +{memoVals.mob ? unit.tags.length - 1 : unit.tags.length - 2}
                </p>
              </div>
            ) : (
              ""
            )}
            {unit.data.requireAccept &&
            !unit.data.accepted &&
            !unit.data.absent &&
            !unit.data.ghost &&
            !loading ? (
              <div className={`mgrHoursUsrNotYetAccept `}>
                Not yet accepted by {unit.fName}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }
    });
  }, [
    loading,
    showShifts,
    showTils,
    showOvertimes,
    showRequests,
    memoVals.selectedName,
  ]);
  return (
    <div
      className={`userShiftsPage  ${
        memoVals.mob ? "" : "pageLeft240pxDesktop"
      }`}
    >
      {" "}
      <ShiftsHeader isTeam={true} my={my} setMy={setMy} />
      <div className="adminCurverCropper bgColUpcoming absenceShoulder hardPurpleBg"></div>
      <div className={`desktopMaster ${memoVals.mob ? "desktopPurpBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth mob100WitdthPlus2" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""} ${
            memoVals.mob ? "mgrHoursShoulderBar" : ""
          }`}
        >
          <div
            className={`${
              memoVals.mob ? "teamShiftsPageFilterBar" : "shiftsPageFilterBar"
            } ${memoVals.mob ? "userHoursShoulderBar minHeight106px" : ""} ${
              !memoVals.mob && "borderLeftGrey"
            } noBorderBottom ${!memoVals.full ? "noBorderRight" : ""}`}
          >
            {!memoVals.mob ? (
              <div className="shiftsPageFilterBarLeft">
                <select
                  className={`${
                    showRequests ? "makeFilterInvisAndInactive" : ""
                    // makeFilterInvisAndInactive
                  } shiftsTilMonthDropdown`}
                  value={my}
                  onChange={(e) => {
                    setLoading(true);
                    memoVals.setSelectedShiftTeam((x) => "allStaff");
                    memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                    setMy(e.target.value);
                    setSelectedChangeID("");
                  }}
                >
                  {generateMonthOptions}
                </select>
              </div>
            ) : (
              ""
            )}
            {!memoVals.mob ? (
              <div className="shiftsPageFilterBarRight">
                <div
                  className={`${
                    shiftReqsLoaded
                      ? "requestsBtnContainer"
                      : "requestsBtnContainer"
                  }`}
                >
                  {" "}
                  {!memoVals.mob ? (
                    <div
                      className={`${
                        reqQty > 0
                          ? "none"
                          : `${
                              showRequests ||
                              showFilter ||
                              memoVals.selectedDay !== "allDays" ||
                              memoVals.selectedName !== "allNames" ||
                              !showOvertimes ||
                              !showShifts ||
                              !showTils ||
                              loading
                                ? "none"
                                : "requestsBtnEmpty"
                            }`
                      }`}
                      onClick={() => {
                        setShowRequests(!showRequests);
                        // setShowOutstanding(false);

                        if (!showRequests) {
                          setShiftReqsLoading(true);
                        }
                      }}
                    >
                      Requests
                    </div>
                  ) : (
                    ""
                  )}
                  {!memoVals.mob ? (
                    <div
                      className={`${
                        showFilter ||
                        memoVals.selectedDay !== "allDays" ||
                        memoVals.selectedName !== "allNames" ||
                        !showOvertimes ||
                        !showShifts ||
                        !showTils ||
                        loading
                          ? "none"
                          : `${
                              showRequests
                                ? `shiftAmendmentRequestsBtnActive ${
                                    memoVals.fullButNarrow ? "fontSize12" : ""
                                  }`
                                : `${
                                    reqQty === 0
                                      ? "none"
                                      : "shiftAmendmentRequestsBtn"
                                  }`
                            }
                    `
                      }
          `}
                      onClick={() => {
                        // setUpdateShiftReqs(!memoVals.updateShiftReqs);

                        setSelectedChangeID("");
                        setShowRequests(!showRequests);
                        // setShowOutstanding(false);

                        if (!showRequests) {
                          setShiftReqsLoading(true);
                        }
                      }}
                    >
                      Requests&nbsp;
                      <p
                        className={`${
                          showRequests ? "none" : "amendmentsNumber"
                        }`}
                      >
                        {reqQty > 9 ? "9+" : reqQty}
                      </p>
                      <img
                        src={whitecross}
                        className={`${
                          showRequests ? "closeShiftBtnCrossImg" : "none"
                        }`}
                        alt="Close"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {memoVals.full ? (
                  <p
                    className={`addShiftShoulderBtn ${
                      loading ? "disable" : ""
                    }`}
                    onClick={() => {
                      memoVals.setAddShiftPage((x) => 0);
                      memoVals.setAddShiftOrigin((x) => "onTeam");
                      setShowTilOrShiftPopUp(false);

                      setShowAddShift(true);
                    }}
                  >
                    +
                  </p>
                ) : (
                  ""
                )}

                <div
                  className={`${
                    showFilter
                      ? "shiftTilFilterBtnActive"
                      : `${
                          memoVals.selectedDay !== "allDays" ||
                          memoVals.selectedName !== "allNames" ||
                          !showOvertimes ||
                          !showShifts ||
                          !showTils
                            ? "shiftTilFilterBtnEngaged"
                            : "shiftTilFilterBtn"
                        }`
                  } ${showRequests ? "makeFilterInvisAndInactive" : ""}`}
                  onClick={() => {
                    if (showFilter) {
                      memoVals.setSelectedDay((x) => "allDays");
                      memoVals.setSelectedName((x) => "allNames");
                      setShowOvertimes(true);
                      setShowShifts(true);
                      setShowTils(true);
                    }
                    setShowFilter(!showFilter);
                  }}
                >
                  <img
                    src={filterWhite}
                    alt="Close filter"
                    className={`filtImg ${!showFilter ? "x19831" : ""}`}
                  />{" "}
                  <img
                    className={`${
                      showFilter ? "closeFilterImgTilShifts" : "none"
                    }`}
                    alt="close"
                    src={whitecross}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {memoVals.mob ? (
              <div
                className={`teamShiftsPageFilterBarUpper -- ${
                  memoVals.mob ? "upperMobTeamHours_" : ""
                }`}
              >
                {" "}
                <div className="mobTeamShiftsUpperLeft">
                  <select
                    className={`${
                      showRequests ? "makeFilterInvisAndInactive" : ""
                      // makeFilterInvisAndInactive
                    } shiftsTilMonthDropdown`}
                    value={my}
                    onChange={(e) => {
                      setLoading(true);
                      memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                      setMy(e.target.value);
                      setSelectedChangeID("");
                    }}
                  >
                    {generateMonthOptions}
                  </select>
                </div>
                <select
                  className={`shiftsHeaderTeamDropdown ${
                    memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                  } ${
                    memoVals.selectedShiftTeam !== "allStaff"
                      ? `teamSelectedDrop ${
                          memoVals.mob ? "mobteamSelectedDrop" : ""
                        }`
                      : ""
                  }`}
                  value={memoVals.selectedShiftTeam}
                  onChange={(e) => {
                    let val = e.target.value;
                    memoVals.setLoadUser((x) => {
                      return {};
                    });

                    memoVals.setSelectedShiftTeam((x) => val);
                  }}
                >
                  <option value="allStaff">-- All of your teams</option>
                  {generateTeamsSelectBar}
                </select>
              </div>
            ) : (
              ""
            )}

            {memoVals.mob ? (
              <div
                className={`teamShiftsPageFilterBarLower ${
                  memoVals.mob ? "upperMobTeamHours" : ""
                }`}
              >
                {" "}
                <div
                  className={`${
                    reqQty > 0
                      ? "none"
                      : `${
                          showRequests ||
                          showFilter ||
                          memoVals.selectedDay !== "allDays" ||
                          memoVals.selectedName !== "allNames" ||
                          !showOvertimes ||
                          !showShifts ||
                          !showTils ||
                          loading
                            ? "none"
                            : "requestsBtnEmpty"
                        } marginTop0`
                  }`}
                  onClick={() => {
                    setShowRequests(!showRequests);
                    setShowOutstanding(false);

                    if (!showRequests) {
                      setShiftReqsLoading(true);

                      axios
                        .post(
                          `${serverURL}/get-shift-edits-for-mgr`,
                          {
                            teamID: memoVals.selectedShiftTeam,
                            my: showOutstanding
                              ? "undecided"
                              : reqMy === "0000"
                              ? tempMy
                              : reqMy,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            let names = [];
                            let userIDs = [];
                            response.data.arr.forEach((ch) => {
                              if (!userIDs.includes(ch.userID)) {
                                userIDs.push(ch.userID);
                                names.push({
                                  userID: ch.userID,
                                  name: `${ch.fName} ${ch.lName[0]}`,
                                });
                              }
                            });
                            setNamesOfShiftReqs(names);

                            setShiftReqArr(response.data.arr);
                            setShiftReqsLoading(false);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Requests
                </div>
                <div
                  className={`${
                    showFilter ||
                    memoVals.selectedDay !== "allDays" ||
                    memoVals.selectedName !== "allNames" ||
                    !showOvertimes ||
                    !showShifts ||
                    !showTils ||
                    loading
                      ? "none"
                      : `${
                          showRequests
                            ? `shiftAmendmentRequestsBtnActive ${
                                memoVals.fullButNarrow ? "fontSize12" : ""
                              }`
                            : `${
                                reqQty === 0
                                  ? "none"
                                  : "shiftAmendmentRequestsBtn"
                              }`
                        }
               marginTop0`
                  }
      }`}
                  onClick={() => {
                    // setUpdateShiftReqs(!memoVals.updateShiftReqs);

                    setSelectedChangeID("");
                    setShowRequests(!showRequests);
                    setShowOutstanding(false);
                  }}
                >
                  Requests&nbsp;
                  <p
                    className={`${showRequests ? "none" : "amendmentsNumber"}`}
                  >
                    {reqQty > 9 ? "9+" : reqQty}
                  </p>
                  <img
                    src={whitecross}
                    className={`${
                      showRequests ? "closeShiftBtnCrossImg" : "none"
                    }`}
                    alt="Close"
                  />
                </div>
                {/* PLACE HERE */}
                {showFilter ? (
                  <div
                    className={`mobShiftsFilterBarActive ${
                      !memoVals.full ? "noBorder" : ""
                    }`}
                  >
                    <select
                      className={`${
                        memoVals.selectedName === "allNames"
                          ? "shiftNamesDropdown unselectedShiftOrTil_"
                          : "shiftNamesDropdown"
                      }`}
                      value={memoVals.selectedName}
                      onChange={(e) => {
                        let val = e.target.value;
                        // setLoading(true);
                        // memoVals.setUpdateShifts(x =>!memoVals.updateShifts)
                        console.log({ e });
                        console.log({ names });
                        memoVals.setSelectedName((x) => val);
                        setSelectedChangeID("");
                      }}
                    >
                      <option key={Math.random()} value="allNames">
                        -- All of your staff
                      </option>
                      {generateNameOptions}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className={`${
                    showFilter
                      ? "shiftTilFilterBtnActive"
                      : `${
                          memoVals.selectedDay !== "allDays" ||
                          memoVals.selectedName !== "allNames" ||
                          !showOvertimes ||
                          !showShifts ||
                          !showTils
                            ? "shiftTilFilterBtnEngaged"
                            : "shiftTilFilterBtn marginRight0"
                        }`
                  } ${showRequests ? "makeFilterInvisAndInactive" : ""} ${
                    memoVals.mob ? "marginTop0" : ""
                  } ${loading ? "none" : ""}`}
                  onClick={() => {
                    if (showFilter) {
                      memoVals.setSelectedDay((x) => "allDays");
                      memoVals.setSelectedName((x) => "allNames");
                      setShowOvertimes(true);
                      setShowShifts(true);
                      setShowTils(true);
                    }
                    setShowFilter(!showFilter);
                  }}
                >
                  <img
                    src={filterWhite}
                    alt="Close filter"
                    className={`filtImg ${!showFilter ? "x19831" : ""}`}
                  />{" "}
                  <img
                    className={`${
                      showFilter ? "closeFilterImgTilShifts" : "none"
                    }`}
                    alt="close"
                    src={whitecross}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${showFilter ? "filterNameShiftsDropdown" : "none"}`}
          >
            {memoVals.mob ? (
              ""
            ) : (
              <div
                className={`nameAndDateDropdownsContainer paddingLeft5pc ${
                  !memoVals.full ? "noBorder" : ""
                }`}
              >
                <select
                  className={`shiftsHeaderTeamDropdown x10924014 ${
                    memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                  } ${
                    memoVals.selectedShiftTeam !== "allStaff"
                      ? `teamSelectedDrop ${
                          memoVals.mob ? "mobteamSelectedDrop" : ""
                        }`
                      : ""
                  }`}
                  value={memoVals.selectedShiftTeam}
                  onChange={(e) => {
                    let val = e.target.value;
                    memoVals.setSelectedShiftTeam((x) => val);
                  }}
                >
                  <option value="allStaff">-- All of your teams</option>
                  {generateTeamsSelectBar}
                </select>
                <select
                  className={`${
                    memoVals.selectedName === "allNames"
                      ? "shiftNamesDropdown unselectedShiftOrTil_"
                      : "shiftNamesDropdown"
                  } ${
                    memoVals.fullButNarrow
                      ? "fullButNarrowShiftNamesDropdown"
                      : ""
                  }`}
                  value={memoVals.selectedName}
                  onChange={(e) => {
                    let val = e.target.value;
                    // setLoading(true);
                    // memoVals.setUpdateShifts(x =>!memoVals.updateShifts);
                    memoVals.setSelectedName((x) => val);
                    setSelectedChangeID("");
                  }}
                >
                  <option key={Math.random()} value="allNames">
                    -- All staff
                  </option>
                  {generateNameOptions}
                </select>
                {/* <select
                  className={`${
                    memoVals.selectedDay === "allDays"
                      ? "shiftDayDropdown unselectedShiftOrTil"
                      : "shiftDayDropdown"
                  }`}
                  value={memoVals.selectedDay}
                  onChange={(e) => {
                    // setLoading(true);
                    // memoVals.setUpdateShifts(x =>!memoVals.updateShifts);
                    memoVals.setSelectedDay(x =>e.target.value);
                    setSelectedChangeID("");
                  }}
                >
                  <option value={"allDays"} key={Math.random()}>
                    Date
                  </option>
                  {generateDayOptions}
                </select> */}
              </div>
            )}
          </div>
          <div className="shiftTilFilterHolder2">
            <div
              className={`${
                showFilter
                  ? `shiftTilFilterBtnsContainer ${
                      !memoVals.full ? "noBorder" : ""
                    }`
                  : "none"
              }`}
            >
              <div
                className={`shiftsOrTilFilterTeam ${
                  showShifts ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowShifts(!showShifts);
                }}
              >
                Shifts{" "}
                <img
                  src={showShifts ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilterTeam ${
                  showOvertimes ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowOvertimes(!showOvertimes);
                }}
              >
                Overtime{" "}
                <img
                  src={showOvertimes ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilterTeam ${
                  showTils ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowTils(!showTils);
                }}
              >
                Shifts in lieu{" "}
                <img
                  src={showTils ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
            </div>
          </div>
          <div
            className={`filterSplitterContainer ${
              !memoVals.full ? "noBorder" : ""
            }`}
          >
            <div
              className={`${showRequests ? "none" : "filterSplitter"}`}
            ></div>
          </div>
          <div className={`${showRequests ? "shiftReqsPage" : "none"}`}>
            <div
              className={`shiftRequestsKeyContainer ${
                memoVals.mob || (!memoVals.mob && !memoVals.full)
                  ? "noBorderRight"
                  : ""
              }`}
              onClick={() => {
                console.log(shiftReqArr);
              }}
            >
              <div
                className={
                  // 'outstandingShiftReqContainer'
                  `outstandingShiftReqContainer ${reqQty === 0 ? "invis" : ""}`
                }
              >
                <div
                  onClick={() => {
                    setShowOutstanding(!showOutstanding);
                    setSelectedChangeID("");
                    setSelectedNameReq("allStaff");
                    setShiftReqsLoading(true);

                    axios
                      .post(
                        `${serverURL}/get-shift-edits-for-mgr`,
                        {
                          teamID: memoVals.selectedShiftTeam,
                          my: !showOutstanding
                            ? "undecided"
                            : reqMy === "0000"
                            ? tempMy
                            : reqMy,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          let names = [];
                          let userIDs = [];
                          response.data.arr.forEach((ch) => {
                            if (!userIDs.includes(ch.userID)) {
                              userIDs.push(ch.userID);
                              names.push({
                                userID: ch.userID,
                                name: `${ch.fName} ${ch.lName[0]}`,
                              });
                            }
                          });
                          setNamesOfShiftReqs(names);

                          setShiftReqArr(response.data.arr);
                          setShiftReqsLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  className={`${
                    showOutstanding
                      ? `outstandingShiftReqsBtnActive ${
                          memoVals.fullButNarrow ? "fontSize12" : ""
                        }`
                      : "outstandingShiftReqsBtn"
                  } ${reqQty === 0 ? "none" : ""}`}
                >
                  {showOutstanding ? (reqQty > 9 ? "9+" : reqQty) : ""}
                  {showOutstanding && reqQty > 0 ? " n" : "N"}ew{" "}
                  {/* {showOutstanding ? "change requests" : ""} */}
                  <img
                    src={whitecross}
                    alt="close"
                    className={`${
                      showOutstanding
                        ? "whiteCrossCloseOutstandingShiftRequests"
                        : "none"
                    }`}
                  />
                  <p
                    className={`${
                      reqQty && !showOutstanding
                        ? "amendmentsNumber amendmentsNumberInOutstanding"
                        : "none"
                    }`}
                  >
                    {reqQty > 9 ? "9+" : reqQty}
                  </p>
                </div>
              </div>
              <div className="shiftReqMonthSelectContainer">
                <select
                  className={`shiftReqNamesDropdown `}
                  value={selectedNameReq}
                  onChange={(e) => {
                    setSelectedNameReq(e.target.value);
                    setShowOutstanding(false);
                    setSelectedChangeID("");
                  }}
                >
                  <option value="allStaff">-- All staff</option>
                  {generateNamesForShiftReqs}{" "}
                </select>

                <select
                  className={`shiftsTilMonthDropdown x398294822 ${
                    !memoVals.mob ? "x3474782832" : ""
                  }`}
                  value={reqMy}
                  onChange={(e) => {
                    setReqMy(e.target.value);
                    setShowOutstanding(false);
                    setSelectedChangeID("");
                    setShiftReqsLoading(true);

                    axios
                      .post(
                        `${serverURL}/get-shift-edits-for-mgr`,
                        {
                          teamID: memoVals.selectedShiftTeam,
                          my: e.target.value,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          let names = [];
                          let userIDs = [];
                          response.data.arr.forEach((ch) => {
                            if (!userIDs.includes(ch.userID)) {
                              userIDs.push(ch.userID);
                              names.push({
                                userID: ch.userID,
                                name: `${ch.fName} ${ch.lName[0]}`,
                              });
                            }
                          });
                          setNamesOfShiftReqs(names);

                          setShiftReqArr(response.data.arr);
                          setShiftReqsLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  {generateMonthOptions}
                </select>
              </div>
            </div>
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            <div
              className={`shiftRequestsContainerSplitterLineWrapper ${
                memoVals.mob || (!memoVals.full && !memoVals.mob)
                  ? "noBorderRight"
                  : ""
              }`}
            >
              <div className="shiftRequestsContainerSplitterLine"></div>
            </div>

            <div
              className={`shiftReqsContainer ${
                memoVals.mob ? "shiftReqsContainerMob" : ""
              }`}
            >
              <img
                src={horizontalBalls}
                className={`${
                  shiftReqsLoading ? "loadingHoriztonalImg" : "none"
                }`}
                alt="Loading"
              />
              <div
                className={`${
                  shiftReqsLoading
                    ? "none"
                    : `shiftChangesParent ${
                        memoVals.mob ? "shiftChangesParentMob" : ""
                      }`
                } ${!memoVals.full ? "noBorderRight" : ""}`}
              >
                {!shiftReqsLoading && shiftReqArr.length === 0 ? (
                  <div
                    className={`noShiftsTxtContainer noUpcomingReqs ${
                      memoVals.mob ? "x1984971" : ""
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>

                    <p className={`noShiftsTxt x19873`}>
                      No change requests for any shifts in {ddmmToMon21(reqMy)}
                    </p>

                    <div className="noShiftsSideBar"></div>
                  </div>
                ) : (
                  ""
                )}

                {/* <div
                  className={`${
                    !shiftReqsLoading && shiftReqArr.length === 0
                      ? "noShiftsTxtContainer"
                      : "none"
                  }`}
                >
                  <div className="noShiftsSideBar"></div>
                  <p className="noShiftsTxt">
                    No change requests for
                    <br />
                    any shifts in {ddmmToMon21(reqMy)}
                  </p>
                  <div className="noShiftsSideBar"></div>
                </div> */}
                {!loading && pendingsExist() && !showOutstanding ? (
                  <p className="clocksAwaitingTitles notYetSubmitTitleArr x9813114">
                    Outstanding - {pendingsExist()}
                  </p>
                ) : (
                  ""
                )}
                {generateShiftRequests}
              </div>
            </div>
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
          </div>
          <div
            ref={hoursScrollBox}
            className={`${
              showRequests
                ? "none"
                : `teamShiftsPage ${
                    !memoVals.mob && !memoVals.full
                      ? `${
                          showFilter
                            ? "shiftsContainerNonFullNonMobWithFilt"
                            : "shiftsContainerNonFullNonMobWithoutFilt"
                        }`
                      : ""
                  } ${!memoVals.full ? "noBorderRight" : ""}`
            } ${
              memoVals.mob
                ? `shiftsContainerMobOnly ${
                    showFilter ? "showFilterMobOnlyShiftsContainer" : ""
                  }`
                : showFilter
                ? "nonMobShiftsContainerWithFilt"
                : "nonMobShiftsContainerWithoutFilt"
            } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
              !memoVals.mob ? "b221" : ""
            }`}
          >
            {/* Teammms, selected team: {memoVals.selectedShiftTeam} */}
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />

            {!loading && allShiftsAndTils.length === 0 ? (
              <div
                className={`noShiftsTxtContainer noUpcomingReqs ${
                  memoVals.mob ? "x1984971" : ""
                }`}
              >
                <div className="noShiftsSideBar"></div>

                <p className={`noShiftsTxt x19873`}>
                  No hours in{" "}
                  {dateStringer.printMonthAndYearFromMy(my, true, true)} from
                  staff in{" "}
                  {getTeamName(
                    memoVals.selectedShiftTeam,
                    memoVals.availableTeamsForShifts
                  )}
                </p>

                <div className="noShiftsSideBar"></div>
              </div>
            ) : (
              ""
            )}

            {generateTeamShiftsAndTils}

            <div
              className={`${
                totDur > 0 && !loading ? "teamShiftsDataBubble" : "none"
              }`}
            >
              <p className="totalDurMinsTxt">
                {dateStringer.formatMinsDurationToHours(totDur, false, true)}
              </p>
            </div>
          </div>{" "}
        </div>
        {memoVals.full ? (
          <div className="desktopMasterSecondary">
            <TeamHoursDataBox
              setLoadUser={setLoadUser}
              setShowRequests={setShowRequests}
              // setShowTeamHoursStatsModal={setShowTeamHoursStatsModal}
            />
          </div>
        ) : (
          ""
        )}

        {!showRequests ? (
          <p
            className={`addShiftBtnOnTeamShifts ${
              memoVals.full ? "none" : ""
            } ${loading ? "disableAddBtn" : ""}`}
            onClick={() => {
              if (!loading) {
                setShowTilOrShiftPopUp(true);
              }
            }}
          >
            +
          </p>
        ) : (
          ""
        )}
      </div>
      {/* {ren   derMgrShiftModal()} */}
      {/* {renderEditShiftModal()} */}
      {showAddShift ? (
        <AddShiftModal2
          loadedFromMgrShiftsPage={true}
          loadedTeamName={""}
          loadedFromTeamSched={false}
          teamID={memoVals.selectedShiftTeam}
          teamIDpreSelected={true}
          type="shift"
          setShowAddShift={setShowAddShift}
        />
      ) : (
        ""
      )}
      {memoVals.showAddTilModal ? (
        <AddShiftModal2
          loadedFromMgrShiftsPage={true}
          loadedTeamName={""}
          loadedFromTeamSched={false}
          teamID={memoVals.selectedShiftTeam}
          teamIDpreSelected={true}
          type={tilOrOvertime}
          setShowAddTilModal={setShowAddTilModal}
        />
      ) : (
        // <AddTilModal
        //   loadedFromMgrShiftsPage={true}
        //   loadedTeamName={""}
        //   loadedFromTeamSched={false}
        // />
        ""
      )}
      <div
        className={`${
          showTilOrShiftPopUp ? "addTilOrShiftOptionsUnderlay" : "none"
        }`}
        onClick={() => {
          setShowTilOrShiftPopUp(false);
        }}
      >
        {" "}
        <div
          className="addShiftBtnOnTeamShiftsOnOverlay"
          onClick={() => {
            setShowTilOrShiftPopUp(false);
          }}
        >
          <p className="diagPlus">+</p>
        </div>
        <div className="addTilShiftBtnsContainer">
          <p
            className="addTilBtn extendPurpleLeftBorder"
            onClick={() => {
              memoVals.setShowAddLeaveOnTeam((x) => true);
            }}
          >
            Leave / Absence
          </p>
          <p
            className="addTilBtn extendGreyLeftBorder"
            onClick={() => {
              memoVals.setAddTilSelf((x) => false);
              memoVals.setAddTilTeam((x) => memoVals.selectedShiftTeam);
              memoVals.setAddTilUserKnown((x) => false);
              memoVals.setAddTilPage((x) => 0);
              memoVals.setAddTilUserID((x) => "");
              if (showTilOrShiftPopUp) {
                memoVals.setShowAddTilModal((x) => true);
              }
              setTilOrOvertime("til");
            }}
          >
            Shift in lieu
          </p>
          <p
            className="addTilBtn extendGreyLeftBorder"
            onClick={() => {
              memoVals.setAddTilSelf((x) => false);
              memoVals.setAddTilTeam((x) => memoVals.selectedShiftTeam);
              memoVals.setAddTilUserKnown((x) => false);
              memoVals.setAddTilPage((x) => 0);
              memoVals.setAddTilUserID((x) => "");
              if (showTilOrShiftPopUp) {
                memoVals.setShowAddTilModal((x) => true);
              }
              setTilOrOvertime("overtime");
            }}
          >
            Overtime
          </p>
          <p
            className="addTilBtn extendBlueLeftBorder"
            onClick={() => {
              memoVals.setAddShiftPage((x) => 0);
              memoVals.setAddShiftOrigin((x) => "onTeam");
              setShowTilOrShiftPopUp(false);

              setShowAddShift(true);
            }}
          >
            Shift
          </p>
        </div>
      </div>
      {memoVals.showAddLeaveOnTeam ? (
        <AddShiftModal2
          teamID={memoVals.selectedShiftTeam}
          type="leave"
          setShowAddLeaveOnTeam={setShowAddLeaveOnTeam}
        />
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default TeamShifts;
