// CSS for this modal is in calendar.css

// INSTRUCTIONS TO LOAD MODAL
// use props
// show modal with showEditMyShiftModal stored in CalendarContext

import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { DataContext } from "../../../contexts/DataContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";
import close from "../../../img/modals/close.svg";
import blueLoader from "../../../img/general/blueLoader.gif";
import toilConvertArr from "../../../img/general/toilConvertArr.svg";

import dateStringer from "../../../tools/dateStringer";

// type = til, overtime, shift
const EditMyShiftRequestModal = ({
  unitType,
  unitID,
  unitTeamID,
  unitUserID,
  unitDs,
  unitStart,
  unitEnd,
  unitBreak,
  setUpdateChangeRequests,
}) => {
  const { mob, mobModal } = useContext(StyleContext);
  const { setShowEditMyShiftModal, setUpdateChanges, updateChanges } =
    useContext(CalendarContext);
  const {
    setRemoveMyChangeEditID,
    updateShifts,
    setUpdateShifts,
    setIndicate,
    amendReqSent,
    setAmendReqSent,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setShowEditMyShiftModal,
      setUpdateChanges,
      updateChanges,
      setRemoveMyChangeEditID,
      updateShifts,
      setUpdateShifts,
      setIndicate,
      amendReqSent,
      setAmendReqSent,
      mob,
      mobModal,
    }),
    [
      setShowEditMyShiftModal, //
      setUpdateChanges, //
      updateChanges, //
      setRemoveMyChangeEditID, //
      updateShifts, //
      setUpdateShifts, //
      setIndicate, //
      amendReqSent, //
      setAmendReqSent, //
      mob, //
      mobModal, //
    ]
  );

  let [itemType, setItemType] = useState("");
  let [itemID, setItemID] = useState("");
  let [itemTeamID, setItemTeamID] = useState("");
  let [itemUserID, setItemUserID] = useState("");
  let [itemDs, setItemDs] = useState("");
  let [itemStart, setItemStart] = useState("");
  let [itemEnd, setItemEnd] = useState("");
  let [itemBreak, setItemBreak] = useState(0);

  let [selectedDate, setSelectedDate] = useState("");
  let [selectedStart, setSelectedStart] = useState("00:00");
  let [selectedEnd, setSelectedEnd] = useState("00:00");
  let [selectedBreak, setSelectedBreak] = useState(0);
  let [selectedType, setSelectedType] = useState("shift");
  let [note, setNote] = useState("");
  let [loading, setLoading] = useState(false);

  let [fadeSubmit, setFadeSubmit] = useState(false);
  let [changeMade, setChangeMade] = useState(false);

  const dateInput = document.getElementById("dateInput");
  if (dateInput && !memoVals.mob) {
    dateInput.addEventListener("focus", function (evt) {
      if (this.getAttribute("type") === "date") {
        this.showPicker();
      }
    });
  }

  const startInput = document.getElementById("startInput");
  if (startInput && !memoVals.mob) {
    startInput.addEventListener("focus", function (evt) {
      if (this.getAttribute("type") === "time") {
        this.showPicker();
      }
    });
  }

  const endInput = document.getElementById("endInput");
  if (endInput && !memoVals.mob) {
    endInput.addEventListener("focus", function (evt) {
      if (this.getAttribute("type") === "time") {
        this.showPicker();
      }
    });
  }

  useEffect(() => {
    // clean not needed
    setItemType(unitType);
    setItemID(unitID);
    setItemTeamID(unitTeamID);
    setItemUserID(unitUserID);

    setSelectedDate(dateStringer.createDateInputFromDateString(unitDs));
    // setItemDs(unitDs);

    // setItemStart(unitStart);
    // setItemEnd(unitEnd);

    setSelectedStart(unitStart);
    setSelectedEnd(unitEnd);

    // setItemBreak(unitBreak);
    setSelectedBreak(unitBreak);

    setSelectedType(unitType);
  }, []);

  let closeModal = () => {
    memoVals.setShowEditMyShiftModal((x) => false);
    setItemType("");
    setItemDs("");
    setItemStart("");
    setItemEnd("");
    setItemBreak("");
    setSelectedType("shift");
    setNote("");
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className="editShiftRequestModalUnderlay"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className={`editShiftRequestModalBox ${
          memoVals.mobModal ? "mobModalShoulder" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperEditShift"></div>
        )}
        <div
          className="editMyShiftModalHeaderContainer"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          {/* <div
            className="editMyShiftCloseHolder"
         
          > */}
          <img
            src={close}
            alt="Close"
            onClick={() => {
              closeModal();
            }}
            className="closeMyShiftModalImg"
          />
          {/* </div> */}
          <p className="myShiftModalHeaderTitleTxt">
            Amend&nbsp;
            {itemType === "shift"
              ? "shift"
              : `${itemType === "til" ? "time in lieu" : "overtime"}`}
          </p>
          {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
        </div>
        <div className="editMyShiftOrigianal">
          {/* <p className="originalEditShTitle">Current:</p> */}
          <div className="originalEditSValRow noBorder">
            <p className="origTitle">Date</p>
            <p className="origValue">
              {dateStringer.printedDateFromDs(unitDs)}
            </p>
          </div>
          <p className="originalEditSValRow">
            <p className="origTitle">Times</p>
            <p className="origValue">
              {unitStart} - {unitEnd}
            </p>
          </p>
          <p className="originalEditSValRow">
            <p className="origTitle">Break</p>
            <p className="origValue">
              {dateStringer.formatMinsDurationToHours(unitBreak)} break
            </p>
          </p>
        </div>
        <img src={toilConvertArr} alt="Amend to" className="editShArr" />
        <div className="editMyShiftRow">
          <p className="editMyShiftRowTitle">Date</p>

          <input
            className="addShiftDateInput"
            type="date"
            id="dateInput"
            value={selectedDate}
            onChange={(e) => {
              setChangeMade(true);
              setSelectedDate(e.target.value);
            }}
          />
        </div>
        <div className="editMyShiftRow">
          <p className="editMyShiftRowTitle">Start</p>

          <input
            className="shiftTimeInput"
            type="time"
            id="startInput"
            value={selectedStart}
            onChange={(e) => {
              setChangeMade(true);

              setSelectedStart(e.target.value);
            }}
          />
        </div>{" "}
        <div className="editMyShiftRow">
          <p className="editMyShiftRowTitle">End</p>

          <input
            className="shiftTimeInput"
            type="time"
            id="endInput"
            value={selectedEnd}
            onChange={(e) => {
              setChangeMade(true);

              setSelectedEnd(e.target.value);
            }}
          />
        </div>
        <div className="editMyShiftRow">
          <p className="editMyShiftRowTitle">
            Break <span className="breakMinsSpanEditShift">(mins)</span>
          </p>

          <input
            className="shiftBrkInput animateZoomIn"
            type="number"
            id="addShiftDateInput"
            value={selectedBreak}
            onChange={(e) => {
              setChangeMade(true);

              setSelectedBreak(e.target.value);
            }}
          />
        </div>
        {/* <div
          className={`${
            itemType === "til" || itemType === "overtime"
              ? "editMyShiftRow"
              : "none"
          }`}
        >
          <p className='editMyShiftRowTitle'>Type</p>

          <select
            className='editMyShiftTypeDropdown'
            value={selectedType}
            onChange={(e) => {
              setChangeMade(true);

              setSelectedType(e.target.value);
            }}
          >
            <option value='til'>Time in lieu</option>
            <option value='overtime'>Overtime</option>
          </select>
        </div> */}
        <div className="editMyShiftRow noEditShiftBorder">
          <p className="editMyShiftRowTitle editMyShiftNoteTitle">Note</p>

          <textarea
            className="EditMyShiftNoteTextBox"
            // type=''
            id="addShiftDateInput"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
        </div>
        <div className="editMyShiftFooter">
          {/* <div className="editMyShiftFooterSideUnit">
            <p className="closeEditMyShiftModalTxt"></p>
          </div> */}
          {/* <div className="submitContainerEditMyShift"> */}
          <p
            className={`${loading ? "none" : "requestEditMyShiftBtn"} ${
              changeMade &&
              selectedDate.length === 10 &&
              selectedStart.length === 5 &&
              selectedEnd.length === 5
                ? ""
                : "fadeSubmitMyShiftChange"
            }`}
            onClick={() => {
              if (
                changeMade &&
                selectedDate.length === 10 &&
                selectedStart.length === 5 &&
                selectedEnd.length === 5
              ) {
                setLoading(true);

                axios
                  .post(
                    `${serverURL}/request-change-to-my-shift`,
                    {
                      userID: itemUserID,
                      shiftID: itemID,
                      type: itemType,
                      newDate:
                        dateStringer.createStringFromDateInput(selectedDate),
                      newStart: selectedStart,
                      newEnd: selectedEnd,
                      newBrk: selectedBreak,
                      note: note,
                      newType: selectedType,
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      // setTimeout(() => {
                      memoVals.setAmendReqSent((x) => !amendReqSent);
                      setLoading(false);
                      memoVals.setUpdateChanges((x) => !memoVals.updateChanges);
                      closeModal();
                      memoVals.setRemoveMyChangeEditID(Math.random());
                      memoVals.setUpdateShifts(!memoVals.updateShifts);
                      memoVals.setIndicate((x) => {
                        return {
                          show: true,
                          message: "Change request made",
                          colour: "blue",
                          duration: 4000,
                        };
                      });
                      if (setUpdateChangeRequests) {
                        response.data.changesArr.sort();
                        // .sort((a, b) => {
                        //   return (
                        //     dateStringer.createTimestampFromString(a.dsMade) -
                        //     dateStringer.createStringFromTimestamp(b.dsMade)
                        //   );
                        // });
                        setUpdateChangeRequests(response.data.changesArr);
                      }
                      // }, 900);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            Request
          </p>
          <img
            src={blueLoader}
            className={`${loading ? "blueLoaderEditMyShiftImg" : "none"}`}
            alt="Loading"
            onClick={() => {
              setLoading(false);
            }}
          />
          {/* </div> */}
          {/* <div
            className="editMyShiftFooterSideUnit"
            onClick={() => {
              closeModal();
            }}
          > */}
          <p
            className={`${loading ? "none" : "closeEditMyShiftModalTxt"}`}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </p>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditMyShiftRequestModal;
