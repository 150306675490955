import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import serverURL from "../serverURL";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import whitecross from "../img/general/whitecross.svg";
import unclockedCheck from "../img/general/checkboxInstall4.svg";
import unclocked from "../img/general/unclocked.svg";
import unclockTime from "../img/general/unclockTime.svg";
import unclockCal from "../img/general/unclockCal.svg";
import teamIcon from "../img/general/teamIcon2.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const UnclocksModal = () => {
  const {
    setShowUnclocked,
    setOpenManagerShiftModal,
    loadUnclocks,
    setUnclockedQty,
    showEditUser,
    updateUnclocked,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowUnclocked,
      setOpenManagerShiftModal,
      loadUnclocks,
      setUnclockedQty,
      showEditUser,
      updateUnclocked,
      mobModal,
    }),
    [
      setShowUnclocked, //
      setOpenManagerShiftModal, //
      loadUnclocks, //
      setUnclockedQty, //
      showEditUser, //
      updateUnclocked, //
      mobModal, //
    ]
  );

  let [unclocks, setUnclocks] = useState([]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 6) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={unclockCal}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={unclockTime}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclockedCheck}
        alt="Unclocked Check"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclocked}
        alt="Unclocked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [multiSelect, setMultiSelect] = useState(false);
  let [selectedShiftIDs, setSelectedShiftIDs] = useState([]);
  let [selectedTilIDs, setSelectedTilIDs] = useState([]);
  let [selectedShiftID, setSelectedShiftID] = useState("");
  let [selectedTilID, setSelectedTilID] = useState("");
  let [initialLoad, setInitialLoad] = useState(false);
  let [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-header-clocks`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          previewOnly: false,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          console.log(response.data);
          response.data.unclocks.sort((a, b) => {
            return (
              dateStringer.createTimestampFromString(a.start) -
              dateStringer.createTimestampFromString(b.start)
            );
          });
          setUnclocks(response.data.unclocks);
          if (response.data.unclocks.length === 0) {
            memoVals.setShowUnclocked((x) => false);
          }
          memoVals.setUnclockedQty((x) => response.data.qty);
          if (dataLoaded) {
            setDataLoaded(false);
          }
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.loadUnclocks, memoVals.updateUnclocked]);

  let printMonth = (ds) => {
    let m = ds.substr(6, 2);
    if (m === "00") {
      return "Jan";
    }
    if (m === "01") {
      return "Feb";
    }
    if (m === "02") {
      return "Mar";
    }
    if (m === "03") {
      return "Apr";
    }
    if (m === "04") {
      return "May";
    }
    if (m === "05") {
      return "Jun";
    }
    if (m === "06") {
      return "Jul";
    }
    if (m === "07") {
      return "Aug";
    }
    if (m === "08") {
      return "Sep";
    }
    if (m === "09") {
      return "Oct";
    }
    if (m === "10") {
      return "Nov";
    }
    if (m === "11") {
      return "Dec";
    }
  };

  let actionFct = (action, singleShift, singleTil) => {
    // action: makeWorked, delete, absentPaid, absentUnpaid
    setShowActions(false);
    // setLoading(true);
    setInitialLoad(true);

    axios
      .post(
        `${serverURL}/unclock-item-action`,

        {
          makeWorked: action === "makeWorked" ? true : false,
          deleteItems: action === "delete" ? true : false,
          makeAbsentUnpaid: action === "absentUnpaid" ? true : false,
          makeAbsentPaid: action === "absentPaid" ? true : false,
          shiftIDs: singleShift ? [selectedShiftID] : selectedShiftIDs,
          tilIDs: singleTil ? [selectedTilID] : selectedTilIDs,
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          let newArr = [];
          if (singleShift) {
            unclocks.map((c) => {
              if (c.shiftID !== selectedShiftID) {
                newArr.push(c);
              }
            });
          } else if (singleTil) {
            unclocks.map((c) => {
              if (c.tilID !== selectedTilID) {
                newArr.push(c);
              }
            });
          } else {
            unclocks.forEach((c) => {
              if (
                selectedShiftIDs.includes(c.shiftID) ||
                selectedTilIDs.includes(c.tilID)
              ) {
              } else {
                newArr.push(c);
              }
            });
          }

          setUnclocks(newArr);

          setSelectedShiftIDs([]);
          setSelectedTilID([]);
          // setLoadUnclocks(!memoVals.loadUnclocks);
          // console.log(response.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let shortenTeamName = (val) => {
    if (val && val.length > 11) {
      return `${val.substr(0, 8)}...`;
    } else {
      return val;
    }
  };

  let generateUnclocks = unclocks.map((unc) => {
    let startDs = unc.start;
    let endDs = unc.end;
    let type = "shift";
    if (unc.tilID) {
      type = unc.tilType;
      startDs = `${unc.ds.substr(0, 11)}H${unc.start.substr(
        0,
        2
      )}M${unc.start.substr(3, 2)}`;
      endDs = `${unc.ds.substr(0, 11)}H${unc.end.substr(0, 2)}M${unc.end.substr(
        3,
        2
      )}`;
    }

    let printShiftDurationSubTxt = () => {
      let dur = 0;
      if (type === "shift") {
        dur = unc.duration / 1000 / 60;
      } else {
        dur = unc.durMins;
      }

      return `${dateStringer.formatMinsDurationToHours(dur)}`;
    };

    return (
      <div className="unclockParent">
        <div
          className={`unclockedUnit ${
            (selectedShiftID && selectedShiftID === unc.shiftID) ||
            (selectedTilID && selectedTilID === unc.tilID) ||
            selectedShiftIDs.includes(unc.shiftID) ||
            selectedTilIDs.includes(unc.tilID)
              ? "selectedUnclockUnit"
              : ""
          }`}
          onClick={() => {
            if (multiSelect) {
              if (unc.shiftID) {
                if (selectedShiftIDs.includes(unc.shiftID)) {
                  let newArr = [];
                  selectedShiftIDs.forEach((sh) => {
                    if (sh !== unc.shiftID) {
                      newArr.push(sh);
                    }
                  });
                  setSelectedShiftIDs(newArr);
                } else {
                  let newArr = [...selectedShiftIDs, unc.shiftID];
                  setSelectedShiftIDs(newArr);
                }
              }
              if (unc.tilID) {
                if (selectedTilIDs.includes(unc.tilID)) {
                  let newArr = [];
                  selectedTilIDs.forEach((til) => {
                    if (til !== unc.tilID) {
                      newArr.push(til);
                    }
                  });
                  setSelectedTilIDs(newArr);
                } else {
                  let newArr = [...selectedTilIDs, unc.tilID];
                  setSelectedTilIDs(newArr);
                }
              }
            } else {
              if (
                (selectedShiftID && selectedShiftID === unc.shiftID) ||
                (selectedTilID && selectedTilID === unc.tilID)
              ) {
                setSelectedShiftID("");
                setSelectedTilID("");
              } else {
                if (unc.shiftID) {
                  console.log("shiftID: ", unc.shiftID, unc);
                  setSelectedShiftID(unc.shiftID);
                  setSelectedTilID("");
                } else {
                  console.log("tilID: ", unc.tilID, unc);
                  setSelectedShiftID("");
                  setSelectedTilID(unc.tilID);
                }
              }
            }
          }}
          key={unc.shiftID || unc.tilID}
        >
          <div className="unclockUnitNameAndTeamDiv">
            <div className="unclockNameAndIconDiv">
              <div className="unclockIconDiv">
                <img
                  src={unclocked}
                  className="unclockedIcon"
                  alt="Unclocked shift"
                />
              </div>
              <p className="unclockNameString">
                {unc.fName} {unc.lName ? unc.lName[0] : ""}
              </p>
            </div>

            <p className="unclockTeamName">
              <img src={teamIcon} alt="Team" className="teamIconAccept" />{" "}
              {dateStringer.shorten(unc.teamName, 22)}
            </p>
          </div>
          <div className="unclockUnitBody">
            {!multiSelect ? (
              ""
            ) : (
              <div className="unclockedSectionLeft0">
                <div
                  className={`${
                    multiSelect ? "multiSelectUnlockRadio" : "none"
                  }`}
                  onClick={() => {}}
                >
                  <img
                    src={unclockedCheck}
                    className={`${
                      selectedShiftIDs.includes(unc.shiftID) ||
                      selectedTilIDs.includes(unc.tilID)
                        ? "unclockTickImg"
                        : "none"
                    }`}
                    alt="Select"
                  />
                  <div
                    className={`${
                      selectedShiftIDs.includes(unc.shiftID) ||
                      selectedTilIDs.includes(unc.tilID)
                        ? "none"
                        : "unselectedUnclockRadio"
                    }`}
                  ></div>
                </div>
              </div>
            )}
            <div
              className={`tilShiftSection1Unclock ${
                multiSelect
                  ? "tilShiftSection1MultiSelected"
                  : `${
                      memoVals.mobModal
                        ? "unclockSection1 x2312121"
                        : "unclockSection1DesktopX"
                    } noBorderLeft`
              }`}
            >
              <p
                className={`unclockedDsString ${
                  printShiftDurationSubTxt() === "0m" ? "noBorder" : ""
                }`}
              >
                <img
                  src={unclockCal}
                  className="unclockCal unclockCal1"
                  alt="Date"
                />
                {dateStringer.tsToDayAndDate(
                  dateStringer.createTimestampFromString(startDs)
                )}{" "}
                {printMonth(startDs)}
                <span
                  className={`${
                    multiSelect
                      ? "unclockedShiftTilTxt"
                      : "unclockedShiftTilTxt"
                  }`}
                >
                  {type === "shift"
                    ? "Shift"
                    : `${type === "overtime" ? "Overtime" : "Lieu shift"}`}
                </span>
              </p>

              <div className="timesAndTagContainer">
                <p className="unclockedShiftTimes">
                  <img src={unclockTime} className="unclockCal" alt="Date" />
                  {startDs.substr(12, 2)}:{startDs.substr(15, 2)} -{" "}
                  {endDs.substr(12, 2)}:{endDs.substr(15, 2)}
                </p>
              </div>
            </div>
            <div className="tilShiftSection2 unclockSection2">
              <p className="unclockedDurString">
                {/* {unit.type === "shift"
              ? "Shift"
              : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`} */}
                {printShiftDurationSubTxt()}
              </p>
              {/* <p
                className={`unclockedBreakDurStrip  ${
                  printShiftDurationSubTxt() === "0m" ? "none" : ""
                }`}
              >
                {type === "shift"
                  ? `${unc.break || 0}m break`
                  : `${unc.brk || 0}m break`}
              </p> */}
            </div>
          </div>
        </div>
        <div
          className={`${
            (selectedShiftID && selectedShiftID === unc.shiftID) ||
            (selectedTilID && selectedTilID === unc.tilID)
              ? "unclockItemMenuDiv"
              : "none"
          }`}
        >
          <div
            className={`unclockMenuL ${loading ? "disableUnclockActions" : ""}`}
          >
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "makeWorked",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark as worked
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "delete",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Delete{" "}
              {type === "shift"
                ? "shift"
                : `${type === "til" ? "TOIL" : "overtime"}`}
            </p>
            <div className="unclockBtnDivider"></div>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentUnpaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - unpaid
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentPaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - paid
            </p>{" "}
          </div>
          <div className="unclockMenuR">
            <p
              className="viewUnclockItem"
              onClick={() => {
                memoVals.setOpenManagerShiftModal((x) => {
                  return {
                    shiftID: unc.shiftID ? unc.shiftID : "",
                    tilID: unc.tilID ? unc.tilID : "",
                    fName: unc.fName,
                    lName: unc.lName,
                    openedFromShiftsPage: false,
                    openedFromUnclockedPage: true,
                    type: unc.shiftID ? "shift" : "til",
                  };
                });
              }}
            >
              View
            </p>
          </div>
        </div>{" "}
      </div>
    );
  });

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowUnclocked((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      memoVals.setShowUnclocked((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className={`clockOnModalUnderlay ${memoVals.showEditUser ? "none" : ""}`}
      onClick={() => {
        memoVals.setShowUnclocked((x) => false);
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`clockOnModalBox whiteBg ${
            memoVals.mobModal
              ? "mobUnclockModalBox slideUp mobModalShoulder"
              : "zoomIn"
          } `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperUnclocked"></div>
          )}
          <div
            className="clockOnModalHeader"
            onClick={() => {
              if (memoVals.mobModal) {
                memoVals.setShowUnclocked((x) => false);
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  memoVals.setShowUnclocked((x) => false);
                }}
              />
            </div>
            <p className="clockOnModalHeaderTitle" onClick={() => {}}>
              Unclocked shifts
            </p>
            <div className="clockOnModalHeaderSideUnit"></div>
            {/* <div className={`clockTicker ${durString ? "" : "clockTickerInvis"}`}>
            {durString}
          </div> */}
          </div>
          <p className="unclocksExplainer">
            Shifts requiring staff to clock on, but no clock activity took
            place.
          </p>
          <div
            className={`${
              memoVals.mobModal ? "mobUnclocksModalBody" : ""
            } unclocksModalContentBox
          `}
          >
            <div
              className={`unclockedContainer ${
                showActions
                  ? `${
                      memoVals.mobModal
                        ? "minus140pxForActionsMob"
                        : "minus140pxForActions"
                    }`
                  : ""
              }`}
            >
              {generateUnclocks}
            </div>
          </div>
          <div
            className={`${showActions ? "unclockMultiActionsBox" : "none"} ${
              !memoVals.mobModal ? "desktopMultiActionBox" : ""
            } ${loading ? "disableUnclockActions" : ""}`}
          >
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("makeWorked", false, false);
              }}
            >
              Mark as worked
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("delete", false, false);
              }}
            >
              Delete selected
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("absentUnpaid", false, false);
              }}
            >
              Mark absent - unpaid
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("absentPaid", false, false);
              }}
            >
              Mark absent - paid
            </p>{" "}
          </div>
          <div className="blankModalFooter">
            <div className="backAndDeleteShiftContainer">
              <div
                className={`pubShiftBtn ${
                  multiSelect ? "multiSelectUnclocks" : ""
                }`}
                onClick={() => {
                  setMultiSelect(!multiSelect);
                  setSelectedShiftIDs([]);
                  setSelectedTilIDs([]);
                  setSelectedShiftID("");
                  setSelectedTilID("");
                  setShowActions(false);
                }}
              >
                {multiSelect ? "Cancel" : "Select"}
              </div>{" "}
              {/* &nbsp; */}
              {!selectedShiftIDs[0] && !selectedTilIDs[0] ? (
                <div
                  className={`pubShiftBtn selectAllBtn ${
                    multiSelect ? "multiSelectUnclocks" : "none"
                  }`}
                  onClick={() => {
                    let sIDs = [];
                    let tIDs = [];
                    unclocks.forEach((cl) => {
                      if (cl.shiftID) {
                        sIDs.push(cl.shiftID);
                      }
                      if (cl.tilID) {
                        tIDs.push(cl.tilID);
                      }
                    });

                    setSelectedShiftIDs(sIDs);
                    setSelectedTilIDs(tIDs);
                  }}
                >
                  Select all
                </div>
              ) : (
                ""
              )}
              {/* &nbsp; */}
              <div
                className={`pubShiftBtn ${
                  selectedShiftIDs.length > 0 || selectedTilIDs.length > 0
                    ? "actionsUnclockActive"
                    : "none"
                }`}
                onClick={() => {
                  setShowActions(!showActions);
                }}
              >
                {" "}
                <img
                  src={whitecross}
                  className={`${
                    showActions ? "showActionsWhiteCrossImg" : "none"
                  }`}
                  alt="Close"
                />
                Actions
              </div>
            </div>
            <p
              className="closeEditShiftBtn"
              onClick={() => {
                memoVals.setShowUnclocked((x) => false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default UnclocksModal;
