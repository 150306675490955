import React, { useState, useContext, useEffect, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";

import { NavLink } from "react-router-dom";

import serverURL from "../../../serverURL";

import "../../../styles/calendar.css";

import TopBar from "../../../components/TopBar";

import whiteGo from "../../../img/general/whiteGo.svg";

import popup from "../../../img/general/popup.svg";
import popup2 from "../../../img/general/popup2.svg";

import HoursDataBox from "./HoursDataBox";
import TeamHoursDataBox from "./TeamHoursDataBox";

const ShiftsHeader = ({ isTeam, setMy, my }) => {
  const { shiftsPage, setShiftsPage, userShiftsPage, setUserShiftsPage } =
    useContext(CalendarContext);
  const {
    setSideBoxData,
    showStats,
    setShowStats,
    availableTeamsForShifts,
    setAvailableTeamsForShifts,
    setSelectedShiftTeam,
    setShowAddTilModal,
    showEditUser,
    setShowChanges,
    setSelectedDesktop,
    notYetAccepted,
    showYetToAcceptOnly,
    setShowYetToAcceptOnly,
    setPage,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);
  const { mob, full, wide } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      shiftsPage, //
      setShiftsPage, //
      userShiftsPage, //
      setUserShiftsPage, //
      setSideBoxData, //
      showStats, //
      setShowStats, //
      availableTeamsForShifts, //
      setAvailableTeamsForShifts, //
      setSelectedShiftTeam, //
      setShowAddTilModal, //
      showEditUser, //
      setShowChanges, //
      setSelectedDesktop, //
      notYetAccepted, //
      showYetToAcceptOnly, //
      setShowYetToAcceptOnly, //
      checkUserState, //
      mob, //
      full, //
      wide, //
      setPage,
    }),
    [
      shiftsPage, //
      setShiftsPage, //
      userShiftsPage, //
      setUserShiftsPage, //
      setSideBoxData, //
      showStats, //
      setShowStats, //
      availableTeamsForShifts, //
      setAvailableTeamsForShifts, //
      setSelectedShiftTeam, //
      setShowAddTilModal, //
      showEditUser, //
      setShowChanges, //
      setSelectedDesktop, //
      notYetAccepted, //
      showYetToAcceptOnly, //
      setShowYetToAcceptOnly, //
      checkUserState, //
      mob, //
      full, //
      wide, //
      setPage,
    ]
  );

  let shortenTeamNameForMobOnly = (val) => {
    let lim = memoVals.full ? 25 : memoVals.mob ? 20 : 25;
    let cap = memoVals.full ? 25 : memoVals.mob ? 16 : 25;
    if (val.length > lim) {
      return `${val.substr(0, cap)}...`;
    } else {
      return val;
    }
  };

  let navlinker = (sp, currentlyMyHours) => {
    // return "";
    if (!currentlyMyHours) {
      if (sp === "submissions") {
        return "/team-timesheets";
      } else if (sp === "shifts") {
        return "/team-hours";
      } else if (sp === "clock-ons") {
        return "/team-clock-ons";
      } else {
        return "/team-hours";
      }
    } else {
      if (sp === "team-submissions") {
        return "/timesheets";
      } else if (sp === "team-shifts") {
        return "/hours";
      } else if (sp === "team-clock-ons") {
        return "/clock-ons";
      } else {
        return "/hours";
      }
    }
  };

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (window.location.href.includes("team")) {
      memoVals.setSelectedDesktop((x) => "team-shifts");
    } else {
      memoVals.setSelectedDesktop((x) => "shifts");
    }
    memoVals.setSelectedShiftTeam((x) => "allStaff");

    if (
      window.location.href.includes("team") &&
      !memoVals.availableTeamsForShifts[0]
    ) {
      axios
        .post(
          `${serverURL}/get-teams-user-manages`,
          { teamlessIfApplic: true },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((resp) => {
          if (resp.data.message === "success") {
            memoVals.setAvailableTeamsForShifts((x) => resp.data.array);
            if (window.location.href.includes("team-shifts")) {
              if (
                resp.data.permissions === 3 &&
                !memoVals.checkUserState.indiOnly
              ) {
                window.location.href = "/hours";
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);
  let generateTeamsSelectBar =
    memoVals.availableTeamsForShifts &&
    memoVals.availableTeamsForShifts
      .sort((a, b) => {
        return a.teamName.localeCompare(b.teamName);
      })
      .map((team) => {
        return (
          <option key={team.teamID} value={team.teamID}>
            {shortenTeamNameForMobOnly(team.teamName)}
          </option>
        );
      });

  let renderStatsModal = () => {
    if (memoVals.showStats) {
      return (
        <div
          className="statsUnderlay"
          onClick={() => {
            memoVals.setShowStats((x) => false);
          }}
        >
          <div
            className="hoursStatsModalBox"
            onClick={(e) => e.stopPropagation()}
          >
            <HoursDataBox modal={true} my={my} setMy={setMy} />
          </div>
        </div>
      );
    }
  };

  let [showTeamHoursStatsModal, setShowTeamHoursStatsModal] = useState(false);

  let renderTeamHoursStatsModal = () => {
    if (showTeamHoursStatsModal && !memoVals.showEditUser) {
      return (
        <div
          className="statsUnderlay"
          onClick={() => {
            setShowTeamHoursStatsModal(false);
          }}
        >
          <div
            className="hoursStatsModalBox"
            onClick={(e) => e.stopPropagation()}
          >
            <TeamHoursDataBox
              modal={true}
              my={my}
              setMy={setMy}
              setShowTeamHoursStatsModal={setShowTeamHoursStatsModal}
            />
          </div>
        </div>
      );
    }
  };

  let item = () => {
    if (memoVals.shiftsPage.includes("shifts")) {
      return "shifts";
    }
    if (memoVals.shiftsPage.includes("clock")) {
      return "clock ons";
    }
    // if (memoVals.shiftsPage.includes("swaps")) {
    //   return "swaps";
    // }
    if (memoVals.shiftsPage.includes("submissions")) {
      return "submissions";
    }
  };

  useEffect(() => {
    // clean not needed
    memoVals.setShowAddTilModal((x) => false);
    // set my to today's date
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);
  }, []);

  // useEffect(() => {
  //   // cleaned
  //   const handlePopstate = () => {
  //     window.history.pushState(null, document.title, window.location.href);
  //     if (memoVals.shiftsPage === "team-shifts") {
  //       setCalPage("shifts");
  //       memoVals.setShiftsPage(x =>"shifts");
  //       memoVals.setUserShiftsPage( x =>"shifts");
  //     }

  //     if (memoVals.shiftsPage === "team-clock-ons") {
  //       setCalPage("clock-ons");
  //       memoVals.setShiftsPage(x =>"clock-ons");
  //       memoVals.setUserShiftsPage( x =>"clock-ons");
  //     }
  //   };

  //   // Add the event listener for "popstate" event
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener("popstate", handlePopstate);

  //   // Cleanup function to remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, []);

  return (
    <div
      className={`userShiftsPageUpperContainer ${
        window.location.href.includes("team") && memoVals.mob
          ? "teamUpperHoursColours"
          : ""
      } ${memoVals.mob ? "noBorderBottom" : ""}`}
    >
      {renderStatsModal()}
      {renderTeamHoursStatsModal()}
      {!memoVals.mob ? <TopBar /> : ""}
      <div
        className={`shiftsHeaderContentContainer ${
          memoVals.shiftsPage.includes("team") ||
          memoVals.shiftsPage.includes("staff")
            ? "x29498284"
            : ""
        }`}
      >
        <div
          className={`shiftsHeaderLeft ${
            window.location.href.includes("team") && memoVals.mob
              ? "mobTeamShiftsHeaderLeft shiftsHeaderMob2"
              : ""
          } ${
            memoVals.mob && !window.location.href.includes("team")
              ? "shiftsHeaderMob3"
              : ""
          } ${memoVals.wide ? "wideTitle" : ""}`}
        >
          <div
            className={`shiftHeaderLeftTitle
             ${
               window.location.href.includes("team")
                 ? `staffColour ${memoVals.mob ? "colorWhite" : ""}`
                 : "myHoursTitle"
             } ${
              !memoVals.mob
                ? `headerTitleSize hoursSizer ${
                    memoVals.shiftsPage.includes("team") ||
                    memoVals.shiftsPage.includes("staff")
                      ? "headerTitleColourMgr"
                      : ""
                  }`
                : ""
            } ${
              memoVals.mob && !window.location.href.includes("team")
                ? "myHoursTitleMob"
                : ""
            } ${
              memoVals.shiftsPage.includes("team") ||
              memoVals.shiftsPage.includes("staff")
                ? "x9349714"
                : ""
            }
             `}
          >
            {memoVals.mob || !window.location.href.includes("team") ? (
              ""
            ) : (
              <span className="staffTitleLeftBlob">.</span>
            )}{" "}
            {!window.location.href.includes("team")
              ? `${memoVals.mob ? "My hours" : "My hours"}`
              : "Staff hours"}{" "}
            {/* {fullButNarrow ? "Narrow" : ""} */}
          </div>

          <div
            className={`shiftHeaderMenuRow myHoursMenuRow ${
              window.location.href.includes("team")
                ? `shiftHeaderMenuRowTeam staffShiftHeaderMenuRow ${
                    memoVals.mob ? "shiftHeaderMenuRowMob" : ""
                  }`
                : memoVals.notYetAccepted &&
                  !memoVals.shiftsPage.includes("team") &&
                  !memoVals.showYetToAcceptOnly
                ? "menuRowWithNotYetAccWidth"
                : ""
            } `}
          >
            {/* <NavLink
              to={memoVals.shiftsPage.includes("team") ? "/team-hours" : "/hours"}
            > */}
            <div
              className={`shiftHeaderMenuItem ${
                memoVals.userShiftsPage.includes("shifts")
                  ? `shiftHeaderMenuItemSelect ${
                      window.location.href.includes("team")
                        ? `staffColour ${
                            memoVals.mob ? "colorWhite" : ""
                          } purBor`
                        : ""
                    }`
                  : ""
              } ${
                memoVals.mob && window.location.href.includes("team")
                  ? "noHover"
                  : ""
              }  ${
                memoVals.shiftsPage.includes("team")
                  ? "teamShiftsHeaderHover"
                  : ""
              }`}
              onClick={() => {
                if (memoVals.userShiftsPage.includes("team")) {
                  memoVals.setShiftsPage((x) => "team-shifts");
                  memoVals.setUserShiftsPage((x) => "team-shifts");
                } else {
                  memoVals.setShiftsPage((x) => "shifts");
                  memoVals.setUserShiftsPage((x) => "shifts");
                  memoVals.setShowChanges((x) => false);
                }
                if (memoVals.showYetToAcceptOnly) {
                  memoVals.setShowYetToAcceptOnly((x) => false);
                }
              }}
            >
              <p className="headerMenuTopRow">Hours</p>
              <p
                className={`headerMenuBottomRow ${
                  memoVals.userShiftsPage.includes("shifts") ? "" : "invis"
                } ${
                  memoVals.userShiftsPage.includes("team")
                    ? "purpeMenuBottom"
                    : ""
                }`}
              ></p>
            </div>{" "}
            {/* </NavLink> */}
            <div className="subHeadSplit"></div>
            <div
              className={`${
                memoVals.shiftsPage.includes("team")
                  ? `shifHeaderMenuSplitterTeam ${
                      memoVals.mob ? "mobTeamHoursSplitterTeam" : ""
                    }`
                  : "shifHeaderMenuSplitter"
              }`}
            ></div>{" "}
            {/* <NavLink
              to={
                memoVals.shiftsPage.includes("team") ? "/team-clock-ons" : "/clock-ons"
              }
            > */}
            <div
              className={`shiftHeaderMenuItem ${
                memoVals.userShiftsPage.includes("clock")
                  ? `shiftHeaderMenuItemSelect ${
                      memoVals.shiftsPage.includes("team")
                        ? `staffColour ${
                            memoVals.mob ? "colorWhite" : ""
                          } purBor`
                        : ""
                    }`
                  : ""
              } ${
                memoVals.mob && memoVals.shiftsPage.includes("team")
                  ? "noHover"
                  : ""
              }  ${
                memoVals.shiftsPage.includes("team")
                  ? "teamShiftsHeaderHover"
                  : ""
              }`}
              onClick={() => {
                if (memoVals.shiftsPage.includes("team")) {
                  memoVals.setShiftsPage((x) => "team-clock-ons");
                  memoVals.setUserShiftsPage((x) => "team-clock-ons");
                } else {
                  memoVals.setShiftsPage((x) => "clock-ons");
                  memoVals.setUserShiftsPage((x) => "clock-ons");
                }
                if (memoVals.showYetToAcceptOnly) {
                  memoVals.setShowYetToAcceptOnly((x) => false);
                }
              }}
            >
              <p className="headerMenuTopRow">Clock cards</p>
              <p
                className={`headerMenuBottomRow ${
                  memoVals.userShiftsPage.includes("clock") ? "" : "invis"
                }  ${
                  memoVals.userShiftsPage.includes("team")
                    ? "purpeMenuBottom"
                    : ""
                }`}
              ></p>
            </div>
            {/* </NavLink> */}
            <div className="subHeadSplit"></div>
            <div
              className={`shiftHeaderMenuItem ${
                memoVals.userShiftsPage.includes("submiss") ||
                memoVals.shiftsPage.includes("team-submissions")
                  ? `shiftHeaderMenuItemSelect  ${
                      memoVals.shiftsPage.includes("team")
                        ? `${
                            memoVals.mob ? "colorWhite" : "staffColour"
                          } purBor`
                        : ""
                    }`
                  : ""
              } ${
                memoVals.mob && memoVals.shiftsPage.includes("team")
                  ? "noHover"
                  : ""
              }  ${
                memoVals.shiftsPage.includes("team")
                  ? "teamShiftsHeaderHover"
                  : ""
              }`}
              onClick={() => {
                if (memoVals.shiftsPage.includes("team")) {
                  memoVals.setShiftsPage((x) => "team-submissions");
                  memoVals.setUserShiftsPage((x) => "team-submissions");
                } else {
                  memoVals.setPage((x) => "submissions");
                  memoVals.setShiftsPage((x) => "submissions");
                  memoVals.setUserShiftsPage((x) => "submissions");
                }

                if (memoVals.showYetToAcceptOnly) {
                  memoVals.setShowYetToAcceptOnly((x) => false);
                }
              }}
            >
              <p className="headerMenuTopRow">Timesheets</p>
              <p
                className={`headerMenuBottomRow ${
                  memoVals.userShiftsPage.includes("submiss") ? "" : "invis"
                }  ${
                  memoVals.userShiftsPage.includes("team")
                    ? "purpeMenuBottom"
                    : ""
                }`}
              ></p>
            </div>
            {/* {memoVals.notYetAccepted &&
            !memoVals.userShiftsPage.includes("team") &&
            !memoVals.showYetToAcceptOnly ? (
              <div className="subHeadSplit"></div>
            ) : (
              ""
            )} */}
            {memoVals.notYetAccepted &&
            !memoVals.userShiftsPage.includes("team") &&
            !memoVals.showYetToAcceptOnly ? (
              <div
                className="notYetAccBtn"
                onClick={() => {
                  memoVals.setShowYetToAcceptOnly((x) => true);

                  memoVals.setShiftsPage((x) => "shifts");
                  memoVals.setUserShiftsPage((x) => "shifts");
                  memoVals.setShowChanges((x) => false);
                }}
              >
                Shifts to accept{" "}
                <p className="shiftsToAcceptQty">
                  {memoVals.notYetAccepted > 99
                    ? "99+"
                    : memoVals.notYetAccepted}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {!window.location.href.includes("team") &&
        (memoVals.checkUserState.permissions < 3 ||
          memoVals.checkUserState.indiOnly) ? (
          <NavLink
            to={navlinker(memoVals.shiftsPage, false)}
            // to="/team-hours"
            // to={`${!memoVals.shiftsPage.includes("team") ? "/team-hours" : "/hours"}`}
            onClick={() => {
              if (memoVals.checkUserState.permissions !== 3) {
                // if (memoVals.shiftsPage.includes("team")) {
                //   setCalPage("shifts");
                //   memoVals.setShiftsPage(x =>"shifts");
                //   memoVals.setUserShiftsPage( x =>"shifts");
                // } else {
                //   setCalPage("team-shifts");
                //   memoVals.setShiftsPage(x =>"team-shifts");
                //   memoVals.setUserShiftsPage( x =>"team-shifts");
                // }
              }
            }}
          >
            <div className="staffHoursBtn x010842081">
              <p className="teamHrsBtn">Staff hours</p>
              <img src={whiteGo} alt="Team" className="whiteGoImg" />
            </div>
          </NavLink>
        ) : (
          ""
        )}
        {memoVals.full ? (
          ""
        ) : (
          <div
            className={` hoursStatsBtn ${
              memoVals.checkUserState.permissions > 2 ? "nonMgrAvailBtn" : ""
            } ${
              memoVals.shiftsPage.includes("team")
                ? `teamStatsBtnStyle ${
                    memoVals.mob ? "teamStatsBtnStyleMob" : ""
                  }`
                : memoVals.mob
                ? "none"
                : ""
            }`}
            onClick={() => {
              if (memoVals.shiftsPage.includes("team")) {
                setShowTeamHoursStatsModal(true);
                console.log("open team hours modal");
              } else {
                memoVals.setShowStats((x) => true);

                axios
                  .post(
                    `${serverURL}/get-my-hours-data`,
                    { my: my, ytd: false },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      console.log(response.data.data);
                      memoVals.setSideBoxData((x) => response.data.data);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <p
              className={`statsBtnTxt ${
                memoVals.shiftsPage.includes("team")
                  ? `statsBtnTxtTeam ${memoVals.mob ? "colorWhite" : ""}`
                  : ""
              }`}
            >
              Stats
            </p>

            <img
              src={
                memoVals.mob
                  ? memoVals.shiftsPage.includes("team")
                    ? popup
                    : popup2
                  : memoVals.shiftsPage.includes("team")
                  ? popup2
                  : popup2
              }
              alt="Stats"
              className={`${
                memoVals.mob
                  ? `statsImg statsImgMob myHoursStatsImgOnly ${
                      window.location.href.includes("team")
                        ? "teamStatsImgMob"
                        : ""
                    }`
                  : "statsImg"
              }`}
            />
          </div>
        )}
        {memoVals.shiftsPage.includes("team") ? (
          <div
            className={`shiftHeaderTeamRightContainer ${
              memoVals.mob ? "shiftHeaderTeamRightContainerMob" : ""
            }`}
          >
            <NavLink
              to={navlinker(memoVals.shiftsPage, true)}
              // to="/hours"
              onClick={() => {
                // setCalPage("shifts");
                // memoVals.setShiftsPage(x =>"shifts");
                // memoVals.setUserShiftsPage( x =>"shifts");
              }}
            >
              <div
                className={`myHoursBtn ${memoVals.mob ? "myHoursBtnMob" : ""}`}
              >
                <p className="teamHrsBtn">My hours</p>
                <img src={whiteGo} alt="Team" className="whiteGoImg" />
              </div>
            </NavLink>
            {/* {!memoVals.mob ? (
              <select
                className={`shiftsHeaderTeamDropdown ${
                  memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                } ${
                  selectedShiftTeam !== "allStaff"
                    ? `teamSelectedDrop ${memoVals.mob ? "mobteamSelectedDrop" : ""}`
                    : ""
                }`}
                value={selectedShiftTeam}
                onChange={(e) => {
                  setLoadUser({});

                  memoVals.setSelectedShiftTeam(x=>e.target.value);
                }}
              >
                <option value="allStaff">-- All teams you manage</option>
                {generateTeamsSelectBar}
              </select>
            ) : (
              ""
            )} */}
            {/* <p>team only</p> */}
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <p
        className={`${memoVals.shiftsPage.includes("team") ? "teamDropTitle" : "none"} ${
          memoVals.full ? "fullTeamDropTitle" : ""
        }`}
      >
        Team
      </p>
      <select
        className={`${
          memoVals.shiftsPage.includes("team") ? "teamDropdownContainer" : "none"
        } ${!memoVals.mob ? "pushTeamDropDown" : "pushTeamDropLeft"} ${
          memoVals.full ? "fullTeamDrop" : ""
        } ${selectedShiftTeam !== "allStaff" ? "selectedTeamColours" : ""}`}
        value={selectedShiftTeam}
        onChange={(e) => {
          memoVals.setSelectedShiftTeam(x=>e.target.value);
        }}
      >
        <option value="allStaff">All of your staff</option>
        {generateTeamsSelectBar}
      </select> */}
      <div
        className={`shiftsMenuBar ${memoVals.mob ? "shiftsMenuBarMob" : ""} ${
          memoVals.mob && memoVals.shiftsPage.includes("team")
            ? "fontSizeMobTeamHoursMenu"
            : ""
        }`}
      >
        {/* <div className="shiftsMenuLeft">
          <div className="shiftsMenuBtnHolder">
            <p
              className={`${
                memoVals.userShiftsPage.includes("shifts")
                  ? "absenceMenuTxt shiftTopMenuItem reqMenuTxtSelected"
                  : "absenceMenuTxt shiftTopMenuItem"
              } ${
                memoVals.userShiftsPage === "team-shifts" && memoVals.mob
                  ? "teamItemSelected"
                  : ""
              }`}
              onClick={() => {
                if (memoVals.userShiftsPage.includes("team")) {
                  memoVals.setShiftsPage(x =>"team-shifts");
                  memoVals.setUserShiftsPage( x =>"team-shifts");
                } else {
                  memoVals.setShiftsPage(x =>"shifts");
                  memoVals.setUserShiftsPage( x =>"shifts");
                }
              }}
            >
              Hours
            </p>
          </div>
          <div
            className={`hoursBorderRight ${
              memoVals.shiftsPage.includes("team") && memoVals.mob ? "purpleHoursDivider" : ""
            }`}
          ></div>
          <div className="swapsMenuBtnHolder">
            <p
              className={`${
                memoVals.userShiftsPage.includes("swaps")
                  ? "absenceMenuTxt shiftTopMenuItem reqMenuTxtSelected"
                  : "absenceMenuTxt shiftTopMenuItem"
              } ${
                memoVals.userShiftsPage === "team-swaps" && memoVals.mob ? "teamItemSelected" : ""
              }`}
              onClick={() => {
                if (memoVals.userShiftsPage.includes("team")) {
                  memoVals.setShiftsPage(x =>"team-swaps");
                  memoVals.setUserShiftsPage( x =>"team-swaps");
                } else {
                  memoVals.setShiftsPage(x =>"swaps");
                  memoVals.setUserShiftsPage( x =>"swaps");
                }
              }}
            >
              Swaps
            </p>
          </div>{" "}
          <div className="availabilityMenuBtnHolder clockCardsMenuItem">
            <p
              className={`${
                memoVals.userShiftsPage.includes("clock-ons")
                  ? "absenceMenuTxt shiftTopMenuItem reqMenuTxtSelected"
                  : "absenceMenuTxt shiftTopMenuItem"
              } ${
                memoVals.userShiftsPage === "team-clock-ons" && memoVals.mob
                  ? "teamItemSelected"
                  : ""
              } `}
              onClick={() => {
                if (memoVals.shiftsPage.includes("team")) {
                  memoVals.setShiftsPage(x =>"team-clock-ons");
                  memoVals.setUserShiftsPage( x =>"team-clock-ons");
                } else {
                  memoVals.setShiftsPage(x =>"clock-ons");
                  memoVals.setUserShiftsPage( x =>"clock-ons");
                }
              }}
            >
              Clock cards
            </p>
          </div>
          <div className="availabilityMenuBtnHolder submissionsHolder">
            <p
              className={`${
                memoVals.userShiftsPage.includes("submissions")
                  ? "absenceMenuTxt shiftTopMenuItem reqMenuTxtSelected"
                  : "absenceMenuTxt shiftTopMenuItem"
              } ${
                memoVals.userShiftsPage === "team-submissions" && memoVals.mob
                  ? "teamItemSelected"
                  : ""
              } timesheetsItem `}
              onClick={() => {
                if (memoVals.shiftsPage.includes("team")) {
                  memoVals.setShiftsPage(x =>"team-submissions");
                  memoVals.setUserShiftsPage( x =>"team-submissions");
                } else {
                  memoVals.setShiftsPage(x =>"submissions");
                  memoVals.setUserShiftsPage( x =>"submissions");
                }
              }}
            >
              Submissions
            </p>
          </div>
        </div> */}
      </div>{" "}
    </div>
  );
};

export default ShiftsHeader;
