import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const TowerContext = createContext();
// general mixed data context

const TowerContextProvider = (props) => {
  // states here
  let [fName, setFName] = useState("user");
  let [page, setPage] = useState("settings");

  let [getBatchData, setGetBatchData] = useState(false);
  return (
    <TowerContext.Provider
      value={{
        // values here
        fName,
        setFName,
        page,
        setPage,
        getBatchData,
        setGetBatchData,
      }}
    >
      {props.children}
    </TowerContext.Provider>
  );
};

export default TowerContextProvider;
