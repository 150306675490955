// css is in calendar.css
import React, { useContext, useMemo } from "react";
// cleaned wed 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import swap2 from "../img/general/swap2.svg";
import whiteQuestionMark from "../img/general/whiteQuestionMark.svg";
import topBarClock from "../img/general/topBarClock.svg";
import msg from "../img/general/msg.svg";
import person from "../img/general/person.svg";

const TopBar = ({ teamSchedule }) => {
  const { checkUserState } = useContext(UserContext);

  const {
    setShowUnclocked,
    activeClocksQty,
    setShowActiveClocks,
    setShowMessages,
    msgQty,
    inboundSwapsQty,
    outboundSwapsQty,
    setShowSwapsModal,
    setShowTeamSwapsModal,
    teamSwapsQty,
    upcomingRequiringClocks,
    unclockedQty,
    mgrHasAcceptTeams,
    acceptDecline,
    setShowMgrAcceptanceModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      checkUserState, //
      setShowUnclocked, //
      activeClocksQty, //
      setShowActiveClocks, //
      setShowMessages, //
      msgQty, //
      inboundSwapsQty, //
      outboundSwapsQty, //
      setShowSwapsModal, //
      setShowTeamSwapsModal, //
      teamSwapsQty, //
      upcomingRequiringClocks, //
      unclockedQty, //
      mgrHasAcceptTeams, //
      acceptDecline, //
      setShowMgrAcceptanceModal, //
    }),
    [
      checkUserState, //
      setShowUnclocked, //
      activeClocksQty, //
      setShowActiveClocks, //
      setShowMessages, //
      msgQty, //
      inboundSwapsQty, //
      outboundSwapsQty, //
      setShowSwapsModal, //
      setShowTeamSwapsModal, //
      teamSwapsQty, //
      upcomingRequiringClocks, //
      unclockedQty, //
      mgrHasAcceptTeams, //
      acceptDecline, //
      setShowMgrAcceptanceModal, //
    ]
  );

  return (
    <div
      className={`TopBar ${
        teamSchedule
          ? `teamSchedTopBarSize ${
              window.location.href.includes("rota") ||
              window.location.href.includes("staff-calendar")
                ? "teamSchedTopBarSizeRota"
                : ""
            }`
          : ""
      }`}
    >
      <div
        className={`unclockedTab ${memoVals.msgQty > 0 ? "blueBg" : ""}`}
        onClick={() => {
          memoVals.setShowMessages((x) => true);
        }}
      >
        <img src={msg} className="topBarMsgIcon" alt="Messages" />
        {/* <p className="unclockedTabTxt">Messages</p> */}
        <p className={`unclockedQty xc209233 ${memoVals.msgQty > 0 ? "" : ""}`}>
          {memoVals.msgQty}
        </p>
      </div>
      <div
        className={`${memoVals.unclockedQty > 0 ? "unclockedTab" : "none"}`}
        onClick={() => {
          memoVals.setShowUnclocked((x) => true);
        }}
      >
        <img
          src={whiteQuestionMark}
          className="unclockedTabMessagesImg"
          alt="Unclocked"
        />

        <p className="unclockedTabTxt">Unclocked</p>
        <p className="unclockedQty">{memoVals.unclockedQty}</p>
      </div>

      <div
        className={`unclockedTab ${
          memoVals.activeClocksQty > 0 || memoVals.upcomingRequiringClocks
            ? ""
            : "none"
        }`}
        onClick={() => {
          memoVals.setShowActiveClocks((x) => true);
        }}
      >
        {" "}
        <img src={topBarClock} className="topBarClockedOnIcon" alt="Messages" />
        <p className="unclockedTabTxt">
          Today
          {/* {memoVals.activeClocksQty > 0 ? "Clocked on" : "Upcoming"} */}
        </p>
        {memoVals.activeClocksQty > 0 ? (
          <p className="unclockedQty greenBg">
            {memoVals.activeClocksQty > 99 ? "99+" : memoVals.activeClocksQty}
          </p>
        ) : memoVals.upcomingRequiringClocks ? (
          <p className="unclockedQty">
            {memoVals.upcomingRequiringClocks > 99
              ? `99+`
              : memoVals.upcomingRequiringClocks}
          </p>
        ) : (
          ""
        )}
      </div>
      {memoVals.checkUserState.permissions < 3 &&
      memoVals.mgrHasAcceptTeams &&
      memoVals.acceptDecline.accepted + memoVals.acceptDecline.declined ? (
        <div
          className="unclockedTab"
          onClick={() => {
            memoVals.setShowMgrAcceptanceModal((x) => true);
          }}
        >
          <img
            src={person}
            className="unclockedTabMessagesImg x238929j"
            alt="Messages"
          />
          <p className="unclockedTabTxt">Accepted</p>
          <p className={`unclockedQty greenBg`}>
            {memoVals.acceptDecline.accepted > 99
              ? "99+"
              : memoVals.acceptDecline.accepted}
          </p>{" "}
          <p className={`unclockedQty redBg`}>
            {memoVals.acceptDecline.declined > 99
              ? "99+"
              : memoVals.acceptDecline.declined}
          </p>
        </div>
      ) : (
        ""
      )}

      <div
        className="unclockedTab"
        onClick={() => {
          memoVals.setShowSwapsModal((x) => true);
        }}
      >
        <img src={swap2} className="unclockedTabMessagesImg" alt="Messages" />
        <p className="unclockedTabTxt">My swaps</p>
        {memoVals.inboundSwapsQty > 0 || memoVals.outboundSwapsQty > 0 ? (
          <p
            className={`unclockedQty ${
              memoVals.inboundSwapsQty > 0 ? "blueBg" : "greyBg"
            }`}
          >
            {memoVals.inboundSwapsQty > 99 ? "99+" : memoVals.inboundSwapsQty}
          </p>
        ) : (
          ""
        )}
        {memoVals.inboundSwapsQty > 0 || memoVals.outboundSwapsQty > 0 ? (
          <p
            className={`unclockedQty ${
              memoVals.outboundSwapsQty > 0 ? "greyBg" : "greyBg"
            }`}
          >
            {memoVals.outboundSwapsQty > 99 ? "99+" : memoVals.outboundSwapsQty}
          </p>
        ) : (
          <p className="emptyOutboundQty"></p>
        )}
      </div>

      {memoVals.checkUserState.permissions < 3 ? (
        <div
          className="unclockedTab"
          onClick={() => {
            memoVals.setShowTeamSwapsModal((x) => true);
          }}
        >
          <img src={swap2} className="unclockedTabMessagesImg" alt="Messages" />
          <p className="unclockedTabTxt">Staff swaps</p>
          <p className={`unclockedQty `}>
            {memoVals.teamSwapsQty > 99 ? "99+" : memoVals.teamSwapsQty}
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TopBar;
