import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import serverURL from "../../serverURL";
import dateStringer from "../../tools/dateStringer"; 
import closeGrey from "../tower/img/closeGrey.svg";
import cancel from "../tower/img/cancel.svg";
import whitecross from "../tower/img/whitecross.svg";

import { TowerContext } from "../../contexts/TowerContext";

const TowerNotepad = () => {
  const { fName, setFName, page, setPage } = useContext(TowerContext);

  let [notes, setNotes] = useState([]);
  let [todoNumber, setTodoNumber] = useState(0);
  let [doneNumber, setDoneNumber] = useState(0);
  let [towerUsers, setTowerUsers] = useState([]);
  let [showAddTowerUserToNoteModal, setShowAddTowerUserToNoteModal] =
    useState(false);
  let [myUserID, setMyUserID] = useState("");
  let [noteID, setNoteID] = useState("");
  let [updateNotes, setUpdateNotes] = useState([]);
  let [showAddNote, setShowAddNote] = useState(false);
  let [alreadyAddedUserIDs, setAlreadyAddedUserIDs] = useState([]);
  let [editNoteTxt, setEditNoteTxt] = useState("noteID");
  let [editNoteStore, setEditNoteStore] = useState("");
  let [filterTodo, setFilterTodo] = useState(false);
  let [updateTodoCount, setUpdateToDoCount] = useState(false);

  notes.sort((a, b) => {
    return b.tsMade - a.tsMade;
  });

  useEffect(() => {
    console.log("Getting tower user info");
    axios
      .post(
        `${serverURL}/get-tower-notes`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setMyUserID(response.data.myUserID);
          setNotes(response.data.data);
          let todo = 0;
          let done = 0;
          response.data.data.forEach((note) => {
            if (note.todo && !note.done) {
              todo++;
            }

            if (note.done) {
              done++;
            }
          });
          setTimeout(() => {
            setTodoNumber(todo);
            setDoneNumber(done);
          }, 500);
        }
      });

    // get tower users to add to notes
    axios
      .post(
        `${serverURL}/get-tower-users`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setTowerUsers(response.data.users);
        }
      });
  }, []);

  useEffect(() => {
    if (updateNotes[0] && updateNotes[0].delete) {
      let newArr = [];
      notes.forEach((n) => {
        if (n.noteID === updateNotes[0].noteID) {
        } else {
          newArr.push(n);
        }
      });

      setNotes(newArr);
    } else {
      let newArr = [];
      let noteIDs = [];
      noteIDs.push(updateNotes.noteID);
      newArr.push(updateNotes);

      notes.forEach((note) => {
        if (!noteIDs.includes(note.noteID)) {
          noteIDs.push(note.noteID);
          newArr.push(note);
        }
      });

      setNotes(newArr);
    }
  }, [updateNotes]);

  useEffect(() => {
    let todos = 0;
    notes.forEach((n) => {
      if (n.todo) {
        todos++;
      }
      setTodoNumber(todos);
    });
  }, [updateTodoCount]);

  let generateNotes = notes.map((note) => {
    let alreadyNoteOwners = [];

    if (note.otherUserIDs) {
      note.otherUserIDs.forEach((us) => {
        alreadyNoteOwners.push(us.userID);
      });
    }

    let generateAddUsersToTowerNote = towerUsers.map((usr) => {
      return (
        <p
          className={`towerUserToAdd ${
            myUserID === usr.userID || alreadyAddedUserIDs.includes(usr.userID)
              ? "none"
              : ""
          }`}
          onClick={() => {
            axios
              .post(
                `${serverURL}/add-tower-user-to-note`,
                {
                  userID: usr.userID,
                  fName: usr.fName,
                  lName: usr.lName,
                  noteID: noteID,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  setUpdateNotes(response.data.data);
                }
              });
          }}
        >
          {usr.fName} {usr.lName}
        </p>
      );
    });

    let generateTowerUsersAlreadyAdded = note.otherUserIDs
      ? note.otherUserIDs.map((person) => {
          let personID = person.userID;
          return (
            <div className='towerUserAddedToNoteItem'>
              <p className='towerUserAddedToNoteItemTxt' key={person.userID}>
                {person.fName} {person.lName[0]}
              </p>
              <img
                src={cancel}
                alt='cancel'
                className={`removeTowerUserFromNoteImg ${
                  note.userID !== myUserID ? "disableRemoveUserFromNote" : ""
                }`}
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/remove-tower-user-from-note`,
                      {
                        userID: personID,
                        noteID: note.noteID,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setUpdateNotes(response.data.data);
                      }
                    });
                }}
              />
            </div>
          );
        })
      : "";

    let getOwnerName = (thisUserID) => {
      let name = "";
      towerUsers.forEach((usr) => {
        console.log(usr.userID, thisUserID);
        if (usr.userID === thisUserID) {
          name = `${usr.fName} ${usr.lName[0]}`;
        }
      });

      return name;
    };
    if (note.noteID) {
      return (
        <div
          className={`towerNoteUnit ${note.todo ? "todoNoteBorder" : ""}  ${
            filterTodo && !note.todo ? "none" : ""
          }`}
          onClick={() => {
            console.log(towerUsers);
          }}
          key={note.noteID}
        >
          <div className='towerNoteUnitHeader'>
            <p className='towerNoteTs'>
              <span className='yourNoteSpan'>
                {myUserID === note.userID
                  ? "My note"
                  : `${getOwnerName(note.userID)}'s note`}
              </span>
              &nbsp;
              {dateStringer.createShortStripFromDateString(
                dateStringer.createStringFromTimestamp(note.tsMade)
              )}{" "}
              <span className='noteTsMadeTimeSpan'>
                {dateStringer.tsToHHMM(note.tsMade)}
              </span>
            </p>
            <img
              src={closeGrey}
              alt='close'
              className={`${
                note.userID === myUserID ? "closeTowerNoteUnitImg" : "none"
              }`}
              onClick={() => {
                if (note.userID === myUserID) {
                  axios
                    .post(
                      `${serverURL}/remove-tower-note`,
                      { noteID: note.noteID },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      setUpdateNotes([{ delete: true, noteID: note.noteID }]);
                    });
                }
              }}
            />
          </div>
          <div className='towerNoteUnitSplitter'></div>

          <p
            className={`${
              editNoteTxt === note.noteID ? "none" : "towetNoteContentTxt"
            }`}
            onClick={() => {
              setEditNoteTxt(note.noteID);
              setEditNoteStore(note.content);
            }}
          >
            {note.content || "Add note content"}
          </p>
          <textarea
            className={`${
              editNoteTxt === note.noteID ? "towerNoteInput" : "none"
            }`}
            value={editNoteStore}
            onChange={(e) => {
              setEditNoteStore(e.target.value);
            }}
          ></textarea>
          <div className='towerNoteUnitSplitter'></div>
          <div className='towerNoteUnitFooter'>
            <div className='towerNoteFooterLeft'>
              <p
                className={`${
                  note.noteID === editNoteTxt ? "saveNoteContentBtn" : "none"
                }`}
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/edit-tower-note-content`,
                      {
                        noteID: note.noteID,
                        content: editNoteStore,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setUpdateNotes(response.data.data);
                        setEditNoteTxt("");
                      }
                    });
                }}
              >
                Save note
              </p>
              <div
                className={`${
                  note.noteID === editNoteTxt
                    ? "saveBtnContentNoteDivider"
                    : "none"
                }`}
              ></div>
              <p
                className={`${
                  note.assocClientName ? "towerNoteFooterClient" : "none"
                }`}
              >
                {note.assocClientName}
              </p>
              <p
                className={`${
                  note.assocUserName ? "towerNoteFooterClient" : "none"
                }`}
              >
                {note.assocUserName}
              </p>
              <div
                className='towerNoteTodoContainer'
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/toggle-tower-note-todo`,
                      { noteID: note.noteID, todo: !note.todo },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setUpdateNotes(response.data.data);
                        setUpdateToDoCount(!updateTodoCount);
                      }
                    });
                }}
              >
                <p className='towerTodoTxt'>To do</p>
                {/*  KNOB BEGIN */}
                <div className='knobContainer  '>
                  <div
                    className={`switchKnob ${note.todo ? "knobOn" : "knobOff"}`}
                  ></div>

                  <div className='knobBg'></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
            <div className='towerNoteFooterRight'>
              <p
                className='addTowerUserToNoteBtn'
                onClick={() => {
                  setNoteID(note.noteID);
                  if (note.otherUserIDs) {
                    let arr = [];
                    note.otherUserIDs.forEach((id) => {
                      arr.push(id.userID);
                    });
                    setAlreadyAddedUserIDs(arr);
                  } else {
                    setAlreadyAddedUserIDs([]);
                  }
                  setShowAddTowerUserToNoteModal(true);
                }}
              >
                Add user
              </p>
              <div className='listOfTowerUsersAddedToNoteContainer'>
                {generateTowerUsersAlreadyAdded}
              </div>
            </div>
          </div>
          <div
            className={`${
              showAddTowerUserToNoteModal
                ? "addTowerUserToNoteUnderlay"
                : "none"
            }`}
            onClick={() => {
              setShowAddTowerUserToNoteModal(false);
            }}
          >
            <div className='addTowerUserToNoteModalBox'>
              <p className='addUserToNoteTowerTitle'>
                Add a Tower user to this note
              </p>
              <div className='towerUsersToAddGenerateBox'>
                {generateAddUsersToTowerNote}
              </div>
            </div>
          </div>
        </div>
      );
    }
  });

  return (
    <div className='towerBodyInnerContainer'>
      <div className='towerNotePadHeader'>
        <div className='towerNoteHeaderDataContainer'>
          <p
            className={`towerAddNoteBtn ${
              showAddNote ? "cancelTowerAddNote" : ""
            }`}
            onClick={() => {
              // setShowAddNote(!showAddNote);
              axios
                .post(
                  `${serverURL}/create-tower-note`,
                  { someData: 1 },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setUpdateNotes(response.data.data);
                  }
                });
            }}
          >
            {`${showAddNote ? "Cancel" : "Add note"}`}
          </p>
          {/* <p
            className={`${showAddNote ? "none" : "towerViewDoneBtn"}`}
            onClick={() => {
              console.log(myUserID);
            }}
          >
            Archived{" "}
            <span className='viewDoneNotesNumberSpan'>{doneNumber}</span>
          </p> */}
        </div>

        <div className='towerNoteHeaderDataContainer'>
          <div
            className={`${
              filterTodo
                ? "numberOfNotesTxt selectedNumberOfTodoNotes"
                : "numberOfNotesTxt"
            }`}
            onClick={() => {
              setFilterTodo(!filterTodo);
            }}
          >
            <img
              src={whitecross}
              className={`${filterTodo ? "whitecrossFilterToDoImg" : "none"}`}
              alt='close'
            />
            {todoNumber} todo
          </div>
          <p
            className='numberOfNotesTxtLeft'
            onClick={() => {
              setFilterTodo(false);
            }}
          >
            {notes.length} note{`${notes.length === 1 ? "" : "s"}`}
          </p>
        </div>
      </div>
      <div className={`${showAddNote ? "showAddNoteContainer" : "none"}`}>
        Add a note
      </div>
      <div className='towerNotesContainer'>{generateNotes}</div>
    </div>
  );
};

export default TowerNotepad;
