import React, { useState, useContext, useEffect } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// Not in use, as of 4 oct 23
import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";

import navyClose from "../../img/general/navyClose.svg";
import navyClose2 from "../../img/general/navyClose2.svg";
import whitecross from "../../img/general/whitecross.svg";

import editGrey from "../../img/general/editGrey.svg";

import whiteGoBack from "../../img/general/whiteGoBack.svg";

import addShiftToAbsenceSel from "../../img/calendar/addShiftToAbsenceSel.svg";
import addShiftToAbsenceUnsel from "../../img/calendar/addShiftToAbsenceUnsel.svg";

import blueLoader from "../../img/general/blueLoader.gif";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import { NavLink } from "react-router-dom";

// all css for this is on calendar.css
import "../../styles/calendar.css";

import CalendarSubBar from "./CalendarSubBar";

import Navbar from "../Navbar";

const AbsenceUnitModal = ({ absenceID, isMgr }) => {
  const {
    calPage,
    setCalPage,
    selectedAbsenceTeam,
    setSelectedAbsenceTeam,
    userAbsenceArr,
    setUserAbsencesArr,
    showAbsenceUnitModalOnUser,
    setShowAbsenceUnitModalOnUser,
    absTypeName,
    setAbsTypeName,
    absFirstShift,
    setAbsFirstShift,
    absLastShift,
    setAbsLastShift,
    absShiftsArr,
    setAbsShiftsArr,
    absRate,
    setAbsRate,
    absUnpaid,
    setAbsUnpaid,
    absDurationMins,
    setAbsDurationMins,
    absNote,
    setAbsNote,
    absRtw,
    setAbsRtw,
    absRtwData,
    setAbsRtwData,
    absAbsenceID,
    setAbsAbsenceID,
    absShiftCount,
    setAbsShiftCount,
    modalMgr,
    setModalMgr,
    absFName,
    setAbsFName,
    absLName,
    setAbsLName,
    setMgrAbsenceArr,
    absenceIDLink,
    setAbsenceIDLink,
    absUserID,
    setAbsUserID,
    absTypeID,
    setAbsTypeID,
    absenceTypes,
    setAbsenceTypes,
  } = useContext(CalendarContext);
  const { permissions, userID } = useContext(UserContext);
  const { mob, mobModal } = useContext(StyleContext);

  const {
    showUserAbsenceModal,
    setShowUserAbsenceModal,
    setOpenManagerShiftModal,
    setShowMyShiftModal,
    selectedDatePeriod,
  } = useContext(DataContext);

  // local states for the shift add/remove modal
  let [shiftsToSelect, setShiftsToSelect] = useState([]);
  let [shiftsAdded, setShiftsAdded] = useState([]);
  let [showShiftSelectModal, setShowShiftSelectModal] = useState(false);
  let [selectShiftsLastYear, setSelectShiftsLastYear] = useState(false);
  let [selectedShiftIDs, setSelectedShiftIDs] = useState([]);
  let [absenceDuration, setAbsenceDuration] = useState(0);
  let [loading, setLoading] = useState(true);
  // removing shifts modal
  let [showRemovingShiftsModal, setShowRemovingShiftsModal] = useState(false);
  let [shiftHasBeenRemovedTripped, setShiftHasBeenRemovedTripped] =
    useState(false);
  let [unmarkRemoved, setUnmarkRemoved] = useState(false);

  let [showEditAbsenceNoteModal, setShowEditAbsenceNoteModal] = useState(false);
  let [absNoteVal, setAbsNoteVal] = useState("");

  // if absenceIDLink, load that instead
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (absenceID) {
      if (isMgr) {
        setModalMgr(true);
      }

      axios
        .post(
          `${serverURL}/get-single-absence-unit`,
          { absenceID: absenceID },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setAbsAbsenceID(response.data.absenceData[0].absenceID);
            setAbsTypeName(response.data.absenceData[0].typeName);
            setAbsFirstShift(response.data.absenceData[0].firstShift);
            setAbsLastShift(response.data.absenceData[0].lastShift);
            setAbsShiftsArr(response.data.absenceData[0].absenceShifts);
            setAbsFName(response.data.absenceData[0].fName);
            setAbsLName(response.data.absenceData[0].lName);

            setAbsRate(response.data.absenceData[0].rate);
            setAbsUnpaid(response.data.absenceData[0].unpaid);
            setAbsDurationMins(
              response.data.absenceData[0].absenceDurationMins
            );
            setAbsNote(response.data.absenceData[0].note);
            setAbsRtw(response.data.absenceData[0].rtw);
            setAbsRtwData(response.data.absenceData[0].rtwData);
            setAbsUserID(response.data.absenceData[0].absenceUserID);
            setAbsTypeID(response.data.absenceData[0].absenceTypeID);
            let countShifts = 0;
            response.data.absenceData[0].absenceShifts.forEach((sh) => {
              if (sh.partial) {
                countShifts += 0.5;
              } else {
                countShifts += 1;
              }
            });
            setLoading(false);
            setAbsShiftCount(countShifts);

            // this data is also set from locally stored array of absences on AbsenceUser.js in the generateUserAbsences. This one from the request should be used when the single absence needs updating
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [editAbsenceType, setEditAbsenceType] = useState(false);

  let updateAbsenceUnit = () => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-single-absence-unit`,
        { absenceID: absenceID },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setAbsAbsenceID(response.data.absenceData[0].absenceID);
          setAbsTypeName(response.data.absenceData[0].typeName);
          setAbsFirstShift(response.data.absenceData[0].firstShift);
          setAbsLastShift(response.data.absenceData[0].lastShift);
          setAbsShiftsArr(response.data.absenceData[0].absenceShifts);
          setAbsRate(response.data.absenceData[0].rate);
          setAbsUnpaid(response.data.absenceData[0].unpaid);
          setAbsDurationMins(response.data.absenceData[0].absenceDurationMins);
          setAbsNote(response.data.absenceData[0].note);
          setAbsRtw(response.data.absenceData[0].rtw);
          setAbsRtwData(response.data.absenceData[0].rtwData);
          setAbsUserID(response.data.absenceData[0].absenceUserID);

          let countShifts = 0;
          response.data.absenceData[0].absenceShifts.forEach((sh) => {
            if (sh.partial) {
              countShifts += 0.5;
            } else {
              countShifts += 1;
            }
          });

          setAbsShiftCount(countShifts);

          // this data is also set from locally stored array of absences on AbsenceUser.js in the generateUserAbsences. This one from the request should be used when the single absence needs updating
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  };

  let getMonthString = (num) => {
    if (num === "00") {
      return "Jan";
    }
    if (num === "01") {
      return "Feb";
    }
    if (num === "02") {
      return "Mar";
    }
    if (num === "03") {
      return "Apr";
    }
    if (num === "04") {
      return "May";
    }
    if (num === "05") {
      return "Jun";
    }
    if (num === "06") {
      return "Jul";
    }
    if (num === "07") {
      return "Aug";
    }
    if (num === "08") {
      return "Sep";
    }
    if (num === "09") {
      return "Oct";
    }
    if (num === "10") {
      return "Nov";
    }
    if (num === "11") {
      return "Dec";
    }
  };

  let [sureCancelAbsenceModal, setSureCancelAbsenceModal] = useState(false);
  let [unmarkShifts, setUnmarkShifts] = useState(true);
  let [showRtwOptionsModal, setShowRtwOptionsModal] = useState(false);
  let [sentReminder, setSendReminder] = useState(false);

  let clearDataOnAbenceModal = () => {
    setShowAbsenceUnitModalOnUser(false);
    setShowUserAbsenceModal({});
    setShowRtwOptionsModal(false);
    setAbsenceModalPage(1);
    setModalMgr(false);
    setAbsShiftCount(0);
    setSendReminder(false);
    setEditAbsenceType(false);
    if (absenceIDLink) {
      setCalPage("team-absence");
      setAbsenceIDLink("");
    }
  };

  let [absenceModalPage, setAbsenceModalPage] = useState(1);
  // 1 = home, 2 = rtwData, 3 = shifts

  let [removeAbsenceLoading, setRemoveAbsenceLoading] = useState(false);

  let generateRtwData = absRtwData.map((rtw, i) => {
    return (
      <div className="rtwAbsenceModalRow" key={i}>
        <p className="rtwQNum">Question {i + 1}</p>

        <p className="rtwAbsenceModalQuestion">{rtw.question}</p>
        <p className="rtwAbsenceModalAnswer">{rtw.answer}</p>
      </div>
    );
  });

  let generateAbsenceTypes = absenceTypes.map((type, i) => {
    return (
      <option key={i} value={type.absenceTypeID}>
        {type.name}
      </option>
    );
  });

  absShiftsArr.sort((a, b) => {
    return (
      dateStringer.createTimestampFromString(
        `${a.ds.substr(0, 11)}H${a.startTime.substr(0, 2)}M${a.startTime.substr(
          3,
          2
        )}`
      ) -
      dateStringer.createTimestampFromString(
        `${b.ds.substr(0, 11)}H${b.startTime.substr(0, 2)}M${b.startTime.substr(
          3,
          2
        )}`
      )
    );
  });

  let generateAbsenceModalShifts = absShiftsArr.map((shift, i) => {
    return (
      <div
        className="absShiftGenerateUnit"
        key={i}
        onClick={() => {
          if (modalMgr) {
            setOpenManagerShiftModal({
              shiftID: shift.type === "shift" ? shift.shiftID : "",
              tilID: shift.type !== "shift" ? shift.shiftID : "",
              fName: shift.fName,
              lName: shift.lName,
              openedFromShiftsPage: false,
              type: shift.type,
            });
          } else {
            setShowMyShiftModal({
              unitID: shift.shiftID,
              unitType: shift.type,
            });
          }
        }}
      >
        <div className="absShiftUnitTopTxt">
          <p className="absShiftUnitDate">
            {dateStringer.createDatePeriod(shift.ds, shift.ds)}
          </p>
          <p className="absShiftUnitDuration">
            {dateStringer.formatMinsDurationToHours(shift.absentDurationMins)}
          </p>
        </div>

        <div className="absShiftUnitTimeContainer">
          <p className="absShiftUnitTimeStart">
            {shift.startTime} - {shift.endTime}
          </p>
        </div>
      </div>
    );
  });

  let generateShiftsForSelection = shiftsToSelect.map((item) => {
    return (
      <div
        className={`${
          selectedShiftIDs.includes(item.shiftID)
            ? "shiftToAddUnit selectedShiftToAddToAbsenceBg"
            : "shiftToAddUnit"
        }`}
        key={item.shiftID}
        onClick={() => {
          if (selectedShiftIDs.includes(item.shiftID)) {
            // remove
            let newArr = [];
            selectedShiftIDs.forEach((sh) => {
              if (sh === item.shiftID) {
              } else {
                newArr.push(sh);
              }
            });
            setSelectedShiftIDs(newArr);
            setShiftHasBeenRemovedTripped(true);

            let newArrOfShifts = [];
            shiftsAdded.forEach((shift) => {
              if (shift.shiftID === item.shiftID) {
              } else {
                newArrOfShifts.push(shift);
              }
            });
            setShiftsAdded(newArrOfShifts);
          } else {
            // add
            let oldArr = selectedShiftIDs;
            let newArr = [item.shiftID, ...selectedShiftIDs];
            setSelectedShiftIDs(newArr);

            let oldArrOfShifts = [];
            let newArrOfShifts = [...shiftsAdded, item];
            setShiftsAdded(newArrOfShifts);
          }
        }}
      >
        <div className="shiftToAddUnitLeft">
          <p
            className={`shiftToSelectDateStripTxt ${
              selectedShiftIDs.includes(item.shiftID)
                ? "whiteTxtForAddShiftToAbsence"
                : ""
            }`}
          >
            {dateStringer.printedDateFromDs(item.start)}{" "}
            {item.start.substr(3, 2)}
          </p>
          <p
            className={`shiftToSelectTimesTxt ${
              selectedShiftIDs.includes(item.shiftID)
                ? "whiteTxtForAddShiftToAbsence"
                : ""
            }`}
          >
            {item.startTime} - {item.endTime}
          </p>
        </div>
        <div className="shiftToAddUnitMiddle">
          <p
            className={`${
              item.partial || item.absent
                ? "absentIndicatorAddShiftToAbsence"
                : "none"
            }`}
          >{`${item.partial ? "Partially absent" : "Absent all day"}`}</p>
        </div>
        <div className="shiftToAddUnitRight">
          <img
            src={`${
              selectedShiftIDs.includes(item.shiftID)
                ? addShiftToAbsenceSel
                : addShiftToAbsenceUnsel
            }`}
            className="selectedShiftRadioBtnImg"
            alt="Selected"
          />
        </div>
      </div>
    );
  });

  return (
    <div
      className={`absenceUnitModalUnderlay`}
      onClick={() => {
        clearDataOnAbenceModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`absenceUnitModalBox ${
            modalMgr ? "mgrAbsenceUnitModalBoxBorder" : ""
          } ${mobModal ? "mobMyShiftModalBox" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="absenceModalHeader">
            <div
              className="absenceModalHeaderUnit1"
              onClick={() => {
                clearDataOnAbenceModal();
              }}
            >
              <img
                src={navyClose}
                className="closeAbsenceUnitBtnImg"
                alt="Close"
              />
            </div>
            <div className="absenceModalHeaderUnit2">
              <img
                src={horizontalBalls}
                className={`${loading ? "loadingAbsenceHorizImg" : "none"}`}
                alt="loading"
              />
              <p
                className={`absenceHeaderTitleTxt ${loading ? "none" : ""}`}
                onClick={() => {}}
              >
                {`${
                  modalMgr
                    ? `${absFName}${
                        absFName[absFName.length - 1] === "s" ? "'" : "'s"
                      } absence`
                    : `${absTypeName}`
                }`}
              </p>
            </div>
            <div className="absenceModalHeaderUnit3">
              {/* {modalMgr ? "mgr" : "usr"} */}
              {""}
            </div>
          </div>
          <div
            className={`${
              modalMgr && !editAbsenceType ? "mgrModalAbsenceName" : "none"
            } ${loading ? "hideAbsenceContent" : ""}`}
            onClick={() => {
              if (modalMgr) {
                axios
                  .post(
                    `${serverURL}/get-absence-types`,
                    { someData: 1 },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setAbsenceTypes(response.data.absenceTypes);
                      setEditAbsenceType(true);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <p className="mgrModalAbsenceNameTxt">{absTypeName}</p>
            <img src={editGrey} alt="edit" className="editAbsTypeImgPencil" />
          </div>
          <select
            className={`${
              modalMgr && editAbsenceType ? "editAbsTypeDropdown" : "none"
            }`}
            value={absTypeID}
            onChange={(e) => {
              axios
                .post(
                  `${serverURL}/edit-absence-type`,
                  { absenceID: absAbsenceID, absenceTypeID: e.target.value },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setAbsTypeName(response.data.name);
                    setAbsTypeID(response.data.data);
                    setEditAbsenceType(false);
                    setAbsRate(response.data.rate);
                    // updateAbsenceUnit();
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            {generateAbsenceTypes}
          </select>

          <div className={`absenceModalBody`}>
            <div
              className={`${
                absenceModalPage === 1 ? "absenceModalBodyLeft" : "none"
              }  ${
                mobModal
                  ? `${
                      modalMgr
                        ? "mobAbsenceUnitModalBodyMgr"
                        : "mobAbsenceUnitModalBody"
                    }`
                  : ""
              }`}
            >
              <div
                className="absenceModalLeftRow"
                onClick={() => {
                  setAbsenceModalPage(3);
                }}
              >
                <p className="absenceModalLeftRowTitle">Period</p>
                <p
                  className={`absenceModalLeftRowValue cursorPointer ${
                    loading ? "hideAbsenceContent" : ""
                  }`}
                >
                  {dateStringer.createDatePeriod(absFirstShift, absLastShift)}
                  {/* 28 Aug - 28 Sep '22 */}
                </p>
              </div>
              <div className="absenceModelLeftRowSplitter"></div>
              <div
                className="absenceModalLeftRow durationAbsenceRow"
                onClick={() => {
                  setAbsenceModalPage(3);
                }}
              >
                <p className="absenceModalLeftRowTitle ">Duration</p>
                <p
                  className={`absenceModalLeftRowValue cursorPointer ${
                    loading ? "hideAbsenceContent" : ""
                  }`}
                >
                  {dateStringer.formatMinsDurationToHours(absDurationMins)} /{" "}
                  <span className="durationSmallerTxtAbsenceSpan">
                    {absShiftCount} shift
                    {absShiftCount === 1 || absShiftCount === 0.5 ? "" : "s"}
                  </span>
                </p>
              </div>
              <div className="absenceModelLeftRowSplitter"></div>
              <div
                className="absenceModalLeftRow"
                onClick={() => {
                  // setAbsenceModalPage(3);
                }}
              >
                <p className="absenceModalLeftRowTitle ">Rate</p>
                <p
                  className={`absenceModalLeftRowValue ${
                    loading ? "hideAbsenceContent" : ""
                  }`}
                >
                  {absRate === 0
                    ? `Unpaid`
                    : `${absRate === 555 ? "Regular rate" : `${absRate}x`}`}
                </p>
              </div>
              <div
                className={`${
                  absNote || modalMgr ? "absenceModelLeftRowSplitter" : "none"
                }`}
              ></div>
              <div
                className={`${
                  modalMgr || absNote
                    ? `${
                        absNote
                          ? "absenceModalLeftRow"
                          : "absenceModalLeftRow maxHeight50"
                      }`
                    : "none"
                }`}
              >
                <div
                  className={`${
                    absNote || modalMgr
                      ? "absenceNoteRowHeaderContainer"
                      : "none"
                  }`}
                >
                  <p className="absenceModalLeftRowTitle">Note</p>
                  <p
                    // src={
                    //   modalMgr && absNote
                    //     ? editGrey
                    //     : `${modalMgr && !absNote ? plusGrey : editGrey}`
                    // }
                    // alt="Edit note"
                    className={`${
                      modalMgr
                        ? `${absNote ? "noteBtnAbsence" : "noteBtnAbsence"}`
                        : "none"
                    } ${loading ? "hideAbsenceContent" : ""}`}
                    onClick={() => {
                      setAbsNoteVal(absNote);
                      setShowEditAbsenceNoteModal(true);
                    }}
                  >
                    {modalMgr && absNote
                      ? "Edit"
                      : `${modalMgr && !absNote ? "Add" : "Edit"}`}
                  </p>
                </div>
                <p
                  className={`absenceModalLeftRowValue absenceNoteValue ${
                    absNote.length > 30 ? "noteAbsenceBorder" : ""
                  } ${absNote.length === 0 ? "none" : ""} ${
                    loading ? "hideAbsenceContent" : ""
                  }`}
                >
                  {absNote}
                </p>
              </div>
              {/* <div className='absenceModelLeftRowSplitter'></div> */}
            </div>
            {/* modal page 2 -- absence data */}
            <div
              className={`${
                absenceModalPage === 2 ? "absenceModalBodyLeft" : "none"
              }`}
            >
              <div className="rtwDataContainer">
                {absRtwData.length > 0 ? generateRtwData : ""}
              </div>
            </div>

            {/* modal page 3 --- shift list */}
            <div
              className={`${
                absenceModalPage === 3 ? "absenceModalBodyLeft" : "none"
              }`}
            >
              <div className="shiftsOnAbsenceHeader">
                <div
                  className="absenceShiftPageHeaderContainer"
                  onClick={() => {
                    setAbsenceModalPage(1);
                  }}
                >
                  <img
                    src={whiteGoBack}
                    className="goBackAbsenceModalFromShiftPageImg"
                    alt="Back"
                  />
                  <p className="absenceModalShiftsTitleTxt">Shifts</p>
                </div>

                <div className="addRemoveShiftsOnAbsenceContainer">
                  <p
                    className={`${
                      modalMgr ? "addRemoveShiftsOnAbsenceTxt" : "none"
                    }`}
                    onClick={() => {
                      if (permissions < 3) {
                        // get shifts for if editing shifts
                        axios
                          .post(
                            `${serverURL}/get-user-shifts-for-absence-maker`,
                            {
                              userID: absUserID,
                              editingAbsenceID: absAbsenceID,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setShiftsToSelect(response.data.shifts);
                              let arr = [];

                              absShiftsArr.forEach((sh) => {
                                arr.push(sh.shiftID);
                              });

                              setSelectedShiftIDs(arr);
                              setShiftsAdded(arr);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                        //
                        setShowShiftSelectModal(true);
                      }
                    }}
                  >
                    Edit
                  </p>
                </div>
              </div>
              <div className="absenceModalShiftsContainer">
                {generateAbsenceModalShifts}
              </div>
            </div>
            {/* end of page 3 */}
            <div
              className={`absenceModalBodyRight ${
                loading ? "hideAbsenceContent" : ""
              }`}
            >
              <div
                className={`${
                  modalMgr && !absRtw && !absRtwData.length
                    ? "formNotRequiredDiv"
                    : "none"
                }`}
                onClick={() => {
                  setShowRtwOptionsModal(true);
                }}
              >
                Form not required
              </div>
              <div
                className={`${
                  modalMgr && absRtw && !absRtwData.length
                    ? "formNotFilledInDiv"
                    : "none"
                }`}
                onClick={() => {
                  setShowRtwOptionsModal(true);
                }}
              >
                Form not filled in
              </div>
              <div
                className={`${
                  modalMgr && absRtw && !absRtwData.length ? "none" : ""
                }`}
              >
                <NavLink
                  to={`${
                    absRtwData.length === 0 && absRtw && !modalMgr
                      ? "/absence-form"
                      : "#"
                  }`}
                >
                  <div
                    className={`${
                      absRtw
                        ? `rtwBtn ${
                            !absRtwData.length > 0 ? `rtwBtnWider` : ""
                          }`
                        : "none"
                    } ${absenceModalPage === 2 ? "page2RTWBtnBg" : ""} `}
                    onClick={() => {
                      if (absRtwData.length > 0) {
                        if (absenceModalPage === 1 || absenceModalPage === 3) {
                          setAbsenceModalPage(2);
                        } else {
                          setAbsenceModalPage(1);
                        }
                      } else {
                        if (absRtwData.length === 0) {
                          // open form
                        }
                      }
                    }}
                  >
                    <div
                      className={`${
                        !absRtwData.length > 0 &&
                        (absenceModalPage === 1 ||
                          absenceModalPage === 2 ||
                          absenceModalPage === 3)
                          ? `${
                              modalMgr && !absRtwData.length && absRtw
                                ? ""
                                : "rtwDot"
                            }`
                          : "rtwCross"
                      } `}
                    >
                      <img
                        src={whitecross}
                        className={`${
                          absenceModalPage === 2
                            ? "closeWhiteRtwPageImg"
                            : "none"
                        }`}
                        alt="Close"
                      />
                    </div>
                    <p
                      className={` ${
                        !absRtwData.length > 0 || absenceModalPage === 2
                          ? `rtwTxtWider ${
                              absenceModalPage === 2 ? "color140351" : ""
                            }`
                          : "nonActiveRtwTxtWidth"
                      }`}
                    >
                      {/* Return to work form */}
                      Form
                    </p>
                  </div>
                </NavLink>
              </div>

              {/* absence timeline */}
              <div className="absenceTimelineContainer">
                <div
                  className="absTimelineIcon"
                  onClick={() => {
                    setAbsenceModalPage(3);
                  }}
                >
                  <p className="absTimelineDateNumber">
                    {absFirstShift.substr(9, 2)}
                  </p>
                  <div className="absIconBlob">
                    {getMonthString(absFirstShift.substr(6, 2))}
                  </div>
                </div>

                <div className="absMiddleTimelineBar">
                  <div className="absTimelineBarCircle"></div>
                  <div
                    className={`${
                      absShiftCount > 1
                        ? "absTimelineBarLine"
                        : "shortenAbsBarline"
                    }`}
                  ></div>
                  <p
                    className={`${
                      absShiftCount > 1
                        ? "absTimelineDur"
                        : "absTimelineDur pullAbsTimelineDurUp"
                    }`}
                    onClick={() => {
                      setAbsenceModalPage(3);
                    }}
                  >
                    {absShiftCount} shift
                    {absShiftCount === 1 || absShiftCount === 0.5 ? "" : "s"}
                  </p>
                  <div
                    className={`${
                      absShiftCount > 1 ? "absTimelineBarCircle" : "none"
                    }`}
                  ></div>
                </div>

                <div
                  className={`${
                    absShiftCount > 1 ? "absTimelineIcon" : "none"
                  }`}
                  onClick={() => {
                    setAbsenceModalPage(3);
                  }}
                >
                  <p className="absTimelineDateNumber">
                    {absLastShift.substr(9, 2)}
                  </p>
                  <div className="absIconBlob">
                    {getMonthString(absLastShift.substr(6, 2))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* modal footer */}
          <div className="absenceModalFooter">
            <div className="footerLeftContainerAbsenceModal">
              <div
                className={`${modalMgr ? "cancelAbsenceBtnHolder" : "none"}`}
                onClick={() => {
                  if (modalMgr) {
                    setSureCancelAbsenceModal(true);
                  }
                }}
              >
                {/* <img
                src={sureCancelAbsence ? cancelReq2 : cancelReq1}
                className={`${modalMgr ? "deleteAbsenceImg" : "none"}`}
                alt='Remove'
              /> */}
                <p className="cancelAbsenceBtnTxt">Remove</p>
              </div>
            </div>
            <p
              className="closeAbsenceModalTxt"
              onClick={() => {
                clearDataOnAbenceModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      <div
        className={`${
          sureCancelAbsenceModal ? "cancelAbsenceModalUnderlay" : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setSureCancelAbsenceModal(false);
        }}
      >
        <div
          className="cancelAbsenceModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="removeAbsenceModalHeaderContainer">
            <div
              className="removeAbsenceModalHeaderSideUnit"
              onClick={() => {
                setSureCancelAbsenceModal(false);
              }}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeRemoveAbsenceModal"
              />
            </div>
            <p className="allShiftsMarkedAbsenceHeaderTxt">Remove absence</p>
            <div className="removeAbsenceModalHeaderSideUnit"></div>
          </div>
          {/* <p className='markedAbsentExplainer'></p> */}
          <div
            className={`${
              removeAbsenceLoading
                ? "removeAbsenceBtnContainer opac5DisableUnmarkBox"
                : "removeAbsenceBtnContainer"
            }`}
            onClick={() => {
              setUnmarkShifts(!unmarkShifts);
            }}
          >
            <div
              className={`markShiftsAbsent ${
                unmarkShifts ? "" : "markShiftsAbsentUnsel"
              }`}
            >
              <p className="keepShiftsAbsentTxt">Keep shifts marked absent</p>
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer">
                <div className="knobContainer unmarkShiftsKnob">
                  <div
                    className={`switchKnob ${
                      unmarkShifts ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>

          <div className="removeAbsenceModalFooter">
            <p
              className="removeAbsenceCancel"
              onClick={() => {
                setUnmarkShifts(true);
                setSureCancelAbsenceModal(false);
              }}
            >
              Cancel
            </p>
            <div className="submitRemoveAbsenceAndLoaderImgContainer">
              <p
                className={`${
                  removeAbsenceLoading ? "none" : "removeAbsenceSubmit"
                }`}
                onClick={() => {
                  setRemoveAbsenceLoading(true);
                  axios
                    .post(
                      `${serverURL}/cancel-absence`,
                      { absenceID: absAbsenceID, unmark: !unmarkShifts },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        axios
                          .post(
                            `${serverURL}/get-absences-for-mgr`,
                            { userID: userID, startDs: selectedDatePeriod },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              response.data.arrOfTeams.sort((a, b) =>
                                dateStringer.createTimestampFromString(
                                  b.firstShift
                                ) >
                                dateStringer.createTimestampFromString(
                                  a.firstShift
                                )
                                  ? 1
                                  : dateStringer.createTimestampFromString(
                                      a.firstShift
                                    ) >
                                    dateStringer.createTimestampFromString(
                                      b.firstShift
                                    )
                                  ? -1
                                  : 0
                              );
                              setMgrAbsenceArr(response.data.arrOfTeams);
                              clearDataOnAbenceModal();
                              setRemoveAbsenceLoading(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Remove
              </p>
              <img
                src={blueLoader}
                className={`${
                  removeAbsenceLoading ? "removeAbsenceLoaderImg" : "none"
                }`}
                alt="Removing"
              />
            </div>
          </div>
        </div>
      </div>

      {/* rtw options modal */}
      <div
        className={`${
          showRtwOptionsModal ? "rtwOptionsModalUnderlay" : "none"
        }`}
        onClick={(e) => {
          setShowRtwOptionsModal(false);
          setSendReminder(false);

          e.stopPropagation();
        }}
      >
        <div
          className="rtwOptionsModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="rtwOptionsModalHeaderContainer">
            <div className="rtwOptionsModalHeaderSideUnit">
              <img
                src={navyClose}
                alt="Close"
                className="closeBtnForRTWOptionsModal"
                onClick={() => {
                  setShowRtwOptionsModal(false);
                  setSendReminder(false);
                }}
              />
            </div>
            <p className="rtwOptionsModalTitle">Absence form</p>
            <div className="rtwOptionsModalHeaderSideUnit"></div>
          </div>

          {/* rtw options modal body */}
          <div className="rtwOptionsModalBodyContainer">
            <div
              className={`rtwOptionsRequiredContainer ${
                absRtw ? "" : "rtwOptionsDisabled"
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/toggle-rtw-form-required-on-absence`,
                    { absenceID: absAbsenceID, data: !absRtw },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setAbsRtw(response.data.data);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              <p className="requiredRtwTxt">Required</p>
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer">
                <div className="knobContainer rtwRequireKnob">
                  <div
                    className={`switchKnob ${
                      absRtw ? "knobOn knobOnRtw" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
            <p
              className={`${
                sentReminder ? "sentReminderBtn" : "sendReminderBtn"
              } ${absRtw ? "" : "hideReminderBtn"}`}
              onClick={() => {
                setSendReminder(true);
                // todo: send the user a reminder when i've learned how to send emails -- in the request, make sure the form has rtw active
              }}
            >
              {!sentReminder
                ? "Send reminder"
                : `${absFName} has been emailed a reminder`}
            </p>
          </div>
        </div>
      </div>
      {/* end of rtw options modal */}

      {/* shift selector modal */}
      <div
        className={`${
          showShiftSelectModal ? "shiftSelectForAbsenceModalUnderlay" : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setShowShiftSelectModal(false);
          setShiftHasBeenRemovedTripped(false);
          setShowRemovingShiftsModal(false);
        }}
      >
        <div
          className="shiftSelectForAbsenceModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="addShiftToAbsenceYearSelectContainer">
            <p className="addShiftsModalTitleTxt">Select shifts</p>
            <div
              className={`${
                selectShiftsLastYear
                  ? "lastYearSelectorHolder activeLastYearToggleShiftSelect"
                  : "lastYearSelectorHolder"
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/get-user-shifts-for-absence-maker`,
                    {
                      userID: absUserID,
                      lastYear: !selectShiftsLastYear,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setShiftsToSelect(response.data.shifts);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
                setSelectedShiftIDs([]);
                setShiftsAdded([]);
                setSelectShiftsLastYear(!selectShiftsLastYear);
              }}
            >
              <p className="lastYearSelectShiftsTxt">Last year</p>
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer">
                <div className="knobContainer knobAddShiftsToAbsenceToggleLastYear">
                  <div
                    className={`switchKnob ${
                      selectShiftsLastYear ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
          <div className="shiftSelectorBox">{generateShiftsForSelection}</div>
          <div className="shiftSelectorFooter">
            <p
              className="removeSelectingShiftsBtn"
              onClick={() => {
                setShiftsAdded([]);
                setSelectedShiftIDs([]);
              }}
            >
              Clear
            </p>

            <p
              className={`saveSelectingShiftsBtn ${
                selectedShiftIDs.length === 0 ? "disableSaveBtn" : ""
              }`}
              onClick={() => {
                if (shiftHasBeenRemovedTripped) {
                  setShowRemovingShiftsModal(true);
                } else {
                  // no shifts have been removed so you can just go ahead and proceed with unmark set to false
                  // axios request to save new shifts, make sure unmark: false
                  axios
                    .post(
                      `${serverURL}/edit-absence-shift-arr`,
                      {
                        absenceID: absAbsenceID,
                        dataArr: selectedShiftIDs,
                        unmark: !unmarkRemoved,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then(() => {
                      setTimeout(() => {
                        updateAbsenceUnit();
                        setShowRemovingShiftsModal(false);
                        setShowShiftSelectModal(false);
                        setShiftHasBeenRemovedTripped(false);
                      }, 100);
                    })
                    .catch((err) => {
                      console.error(err);
                    });

                  setShiftHasBeenRemovedTripped(false);
                  setShowRemovingShiftsModal(false);
                  let absentDuration = 0;
                  shiftsAdded.forEach((sh) => {
                    absentDuration += sh.absentDuration;
                  });
                  setAbsenceDuration(absentDuration);
                }
              }}
            >
              Save
            </p>

            <p
              className="closeSelectingShiftsBtn"
              onClick={() => {
                setShowShiftSelectModal(false);
                setShiftHasBeenRemovedTripped(false);
                setShowRemovingShiftsModal(false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      </div>
      {/* end of shift selector modal */}

      {/* modal for is editing shifts array, and a shift has been removed -- shoudl the removed shifts be unmarked? */}
      <div
        className={`${
          showRemovingShiftsModal ? "cancelAbsenceModalUnderlay" : "none"
        }`}
        onClick={() => {
          setShowRemovingShiftsModal(false);
        }}
      >
        <div
          className="cancelAbsenceModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="removeAbsenceModalHeaderContainer">
            <div
              className="removeAbsenceModalHeaderSideUnit"
              onClick={() => {
                setShowRemovingShiftsModal(false);
              }}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeRemoveAbsenceModal"
              />
            </div>
            <p className="allShiftsMarkedAbsenceHeaderTxt">Removing shifts</p>
            <div className="removeAbsenceModalHeaderSideUnit"></div>
          </div>
          {/* <p className='markedAbsentExplainer'></p> */}
          <div
            className={`${
              removeAbsenceLoading
                ? "removeAbsenceBtnContainer opac5DisableUnmarkBox"
                : "removeAbsenceBtnContainer"
            }`}
            onClick={() => {
              setUnmarkRemoved(!unmarkRemoved);
            }}
          >
            <div
              className={`markShiftsAbsent ${
                unmarkRemoved ? "" : "markShiftsAbsentUnsel"
              }`}
            >
              <p className="keepShiftsAbsentTxt">Keep shifts marked absent</p>
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer">
                <div className="knobContainer unmarkShiftsKnob">
                  <div
                    className={`switchKnob ${
                      unmarkRemoved ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>

          <div className="removeAbsenceModalFooter">
            <p
              className="removeAbsenceCancel"
              onClick={() => {
                setUnmarkRemoved(false);
                setShowRemovingShiftsModal(false);
              }}
            >
              Cancel
            </p>
            <div className="submitRemoveAbsenceAndLoaderImgContainer">
              <p
                className={`${
                  removeAbsenceLoading ? "none" : "removeAbsenceSubmit"
                }`}
                onClick={() => {
                  setRemoveAbsenceLoading(true);
                  // save the new shifts -- at the end of the req, set absenceloading false, set showRemoveshift to false, set showShiftselect to false
                  // hello
                  axios
                    .post(
                      `${serverURL}/edit-absence-shift-arr`,
                      {
                        absenceID: absAbsenceID,
                        dataArr: selectedShiftIDs,
                        unmark: !unmarkRemoved,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then(() => {
                      setTimeout(() => {
                        updateAbsenceUnit();
                        setShowRemovingShiftsModal(false);
                        setShowShiftSelectModal(false);
                        setShiftHasBeenRemovedTripped(false);
                      }, 100);
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Save
              </p>
              <img
                src={blueLoader}
                className={`${
                  removeAbsenceLoading ? "removeAbsenceLoaderImg" : "none"
                }`}
                alt="Removing"
              />
            </div>
          </div>
        </div>
      </div>
      {/* end of modal for unmarking edited shifts */}

      {/* modal for editing/adding absence note */}
      <div
        className={`${
          showEditAbsenceNoteModal ? "editAbsenceNoteModalUnderlay" : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setShowEditAbsenceNoteModal(false);
          setAbsNoteVal("");
        }}
      >
        <div
          className="editAbsNoteModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="removeAbsenceModalHeaderContainer">
            <div
              className="removeAbsenceModalHeaderSideUnit navyCrossUnitOnAbsenceEditNote"
              onClick={() => {
                setShowEditAbsenceNoteModal(false);
                setAbsNoteVal("");
              }}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeRemoveAbsenceModal"
              />
            </div>
            <p className="allShiftsMarkedAbsenceHeaderTxt">Absence note</p>
            <div className="removeAbsenceModalHeaderSideUnit"></div>
          </div>{" "}
          <textarea
            className="absenceNoteEditInput"
            value={absNoteVal}
            onChange={(e) => {
              setAbsNoteVal(e.target.value);
            }}
          ></textarea>
          <div className="editAbsenceNoteFooterContainer">
            <div className="editAbsenceNoteFooterUnit">
              {" "}
              <p
                className="closeEditAbsenceNoteBtn removeAbsNoteBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/edit-absence-note`,
                      {
                        absenceID: absAbsenceID,
                        data: absNoteVal,
                        remove: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setAbsNote("");
                        setShowEditAbsenceNoteModal(false);
                        setAbsNoteVal("");
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Remove
              </p>
            </div>
            <div className="editAbsenceNoteFooterUnit">
              <p
                className={`saveEditAbsenceNoteBtn ${
                  absNoteVal ? "" : "disableSaveAbsenceNoteBtn"
                }`}
                onClick={() => {
                  if (absNoteVal) {
                    axios
                      .post(
                        `${serverURL}/edit-absence-note`,
                        { absenceID: absAbsenceID, data: absNoteVal },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setAbsNote(response.data.data);
                          setShowEditAbsenceNoteModal(false);
                          setAbsNoteVal("");
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Save
              </p>
            </div>
            <div className="editAbsenceNoteFooterUnit">
              <p
                className="closeEditAbsenceNoteBtn"
                onClick={() => {
                  setShowEditAbsenceNoteModal(false);
                  setAbsNoteVal("");
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* end of modal for editing/adding absence note */}
    </div>
  );
};

export default AbsenceUnitModal;
