import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  Fragment,
} from "react";
// cleaned wef 24 july 23
// proleaded images 19 sep 23
// axios caught 21 sep 23

import { NavLink } from "react-router-dom";
import axios from "axios";
import serverURL from "../serverURL";
import DemoUserSelect from "./DemoUserSelect";

import "../styles/navbar.css";

import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";

const Navbar = () => {
  const { indicate, setIndicate, indicating, setIndicating } =
    useContext(DataContext);
  const { mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      indicate,
      setIndicate,
      indicating,
      setIndicating,
      mob, //
    }),
    [indicate, setIndicate, indicating, setIndicating, mob]
  );

  let [show, setShow] = useState(false);
  let [hasOpened, setHasOpened] = useState(false);

  useEffect(() => {
    // clean not needed
    if (memoVals.indicate.show) {
      setShow(true);
      // setHasOpened(true);
      setTimeout(() => {
        memoVals.setIndicating((x) => true);
      }, 500);

      setTimeout(() => {
        if (memoVals.indicate.duration < 999999) {
          memoVals.setIndicate((x) => {
            return { show: false };
          });
          memoVals.setIndicating((x) => false);
        }
      }, memoVals.indicate.duration);
    } else {
      setHasOpened(false);
    }
  }, [memoVals.indicate]);

  if (show || !show) {
    return (
      <Fragment>
        {!memoVals.mob && (
          <div
            className="desktopIndicateUnderlay"
            onMouseEnter={() => {
              memoVals.setIndicate((x) => false);
            }}
          ></div>
        )}
        <div
          className={`${
            memoVals.indicate.show
              ? `indicate ${!memoVals.indicating ? "animateIndicate" : ""} ${
                  !memoVals.mob ? "desktopIndicate" : ""
                } ${
                  memoVals.indicate.colour === "orange"
                    ? "instructIndicate"
                    : ""
                } ${
                  memoVals.indicate.colour === "green" ? "approveIndicate" : ""
                } ${
                  memoVals.indicate.colour === "red" ? "declineIndicate" : ""
                }`
              : "none"
          } ${memoVals.mob ? "" : "whiteBg"}`}
          onClick={() => {
            console.log(memoVals.indicate);
            memoVals.setIndicate((x) => false);
          }}
        >
          <div
            className={`indicateTxt ${
              memoVals.mob
                ? `mobIndicateTxt ${
                    memoVals.indicate &&
                    memoVals.indicate.message &&
                    memoVals.indicate.message.length > 33
                      ? "indicateSmall"
                      : ""
                  }`
                : ""
            }`}
          >
            {!memoVals.mob && (
              <p
                className={`blueIndDesktopBlob ${
                  memoVals.indicate.colour === "orange"
                    ? "orangeIndDesktopBlob"
                    : memoVals.indicate.colour === "green"
                    ? "greenIndDesktopBlob"
                    : memoVals.indicate.colour === "red"
                    ? "redIndDesktopBlob"
                    : ""
                }`}
              ></p>
            )}
            {memoVals.indicate.message}
          </div>
        </div>
      </Fragment>
    );
  } else {
    return "";
  }
};

export default Navbar;
