import React, { createContext, useState } from "react";
import axios from "axios";

export const AdminContext = createContext();
// context for the administrator page

const AdminContextProvider = (props) => {
  // public holidays page
  let [enablePublicHols, setEnablePublicHols] = useState();
  let [closedPublicHols, setClosedPublicHols] = useState();
  let [payPublicHols, setPayPublicHols] = useState();

  // special days component (within public holidays page)
  // store the array in local state
  let [specialDaysData, setSpecialDaysData] = useState([]);
  // should it show previous special days?
  let [showPrevSpecDays, setShowPrevSpecDays] = useState(false);
  // state for the content of the specialday modal
  let [specialDayModalContent, setSpecialDayModalContent] = useState("");

  // users page
  // loading
  let [userLoading, setUserLoading] = useState(false);

  // teams page
  let [showTeamlessModal, setShowTeamlessModal] = useState(false);

  // edit team
  let [editTeamNameToggle, setEditTeamNameToggle] = useState(false);
  let [teamNamePurg, setTeamNamePurg] = useState();
  let [sureDeleteTeam, setSureDeleteTeam] = useState(false);
  let [allUsersArray, setAllUsersArray] = useState([]);
  let [showMgrsNotUsers, setShowMgrsNotUsers] = useState(false);
  let [mgrNotUsers, setMgrNotUsers] = useState([]);
  let [showAddUserToTeamModal, setShowAddUserToTeamModal] = useState(false);

  // leave types page
  // // local states for data
  let [annLeavePercentage, setAnnLeavePercentage] = useState(0);
  let [enableTil, setEnableTil] = useState();
  let [customLeaveTypes, setCustomLeaveTypes] = useState([{}]);
  let [showUnpublished, setShowUnpublished] = useState(false);
  let [removeCustomLeaveType, setRemoveCustomLeaveType] = useState();
  let [showEditCustomLeaveTypeNameModal, setShowEditCustomLeaveTypeNameModal] =
    useState(false);
  let [editNameLeaveTypeID, setEditNameLeaveTypeID] = useState();
  let [editLeaveTypeNameData, setEditLeaveTypeNameData] = useState();
  let [hideFor1S, setHideFor1S] = useState("none");
  let [hideFor1SEnabled, setHideFor1SEnabled] = useState("none");

  // schedule page
  let [allowSwaps, setAllowSwaps] = useState();
  let [selfGenSched, setSelfGenSched] = useState();
  let [dailyRates, setDailyRates] = useState();
  let [genSchedDaysAheadQty, setGenSchedDaysAheadQty] = useState();
  let [showNextYear, setShowNextYear] = useState();
  let [beyondYearStart2, setBeyondYearStart2] = useState();
  let [monthReset, setMonthReset] = useState(1);
  let [monRate, setMonRate] = useState();
  let [tueRate, setTueRate] = useState();
  let [wedRate, setWedRate] = useState();
  let [thuRate, setThuRate] = useState();
  let [friRate, setFriRate] = useState();
  let [satRate, setSatRate] = useState();
  let [sunRate, setSunRate] = useState();
  let [monClosed, setMonClosed] = useState(false);
  let [tueClosed, setTueClosed] = useState(false);
  let [wedClosed, setWedClosed] = useState(false);
  let [thuClosed, setThuClosed] = useState(false);
  let [friClosed, setFriClosed] = useState(false);
  let [satClosed, setSatClosed] = useState(false);
  let [sunClosed, setSunClosed] = useState(false);

  // absence page
  let [allowSelfLogAbsence, setAllowSelfLogAbsence] = useState();
  let [enableAbsenceRanking, setEnableAbsenceRanking] = useState();
  let [rtwFormEnabled, setRtwFormEnabled] = useState();
  let [rtwQuestions, setRtwQuestions] = useState([{}]);
  let [absenceTypes, setAbsenceTypes] = useState([{}]);
  let [showAddRtwModal, setShowAddRtwModal] = useState(false);
  let [question, setQuestion] = useState();
  let [showAddAbsenceTypeModal, setShowAddAbsenceTypeModal] = useState(false);
  let [addAbsenceTypeName, setAddAbsenceTypeName] = useState();
  let [addAbsenceTypePaid, setAddAbsenceTypePaid] = useState(false);
  let [addAbsenceTypePage, setAddAbsenceTypePage] = useState(1);
  let [addAbsenceTypeRate, setAddAbsenceTypeRate] = useState(0);
  let [publicHolExclusions, setPublicHolExclusions] = useState([]);
  // overtime page
  let [adminEnableOvertime, setAdminEnableOvertime] = useState();
  let [adminEnableOvertimeSelfLogging, setAdminEnableOvertimeSelfLogging] =
    useState();
  let [excessMinsPaidInRequests, setExcessMinsPaidInRequests] = useState(false);
  let [addExcessMinsToAllowanceInstead, setAddExcessMinsToAllowanceInstead] =
    useState(false);
  let [overtimeIndividuals, setOvertimeIndividuals] = useState([]);
  let [showInfo1, setShowInfo1] = useState(false);
  let [showInfo2, setShowInfo2] = useState(false);

  let [refPeriod, setRefPeriod] = useState(52);
  let [refPeriodMaxFarBack, setRefPeriodMaxFarBack] = useState(104);

  let [weekStart, setWeekStart] = useState("0");

  let [clockOnPeriod, setClockOnPeriod] = useState(60);

  let [hourlyRateTimelineIssues, setHourlyRateTimelineIssues] = useState([]);
  let [mpwTimelineIssues, setMpwTimelineIssues] = useState([]);

  let [refreshUsers, setRefreshUsers] = useState(false);
  let [showAddSpecialDayModal, setShowAddSpecialDayModal] = useState(false);
  let [sureRemoveZone, setSureRemoveZone] = useState("");

  return (
    <AdminContext.Provider
      value={{
        sureRemoveZone,
        setSureRemoveZone,
        showAddSpecialDayModal,
        setShowAddSpecialDayModal,
        refPeriodMaxFarBack,
        setRefPeriodMaxFarBack,
        refreshUsers,
        setRefreshUsers,
        hourlyRateTimelineIssues,
        setHourlyRateTimelineIssues,
        mpwTimelineIssues,
        setMpwTimelineIssues,
        publicHolExclusions,
        setPublicHolExclusions,
        clockOnPeriod,
        setClockOnPeriod,
        weekStart,
        setWeekStart,
        refPeriod,
        setRefPeriod,

        enablePublicHols,
        setEnablePublicHols,
        closedPublicHols,
        setClosedPublicHols,
        payPublicHols,
        setPayPublicHols,
        specialDaysData,
        setSpecialDaysData,
        showPrevSpecDays,
        setShowPrevSpecDays,

        specialDayModalContent,
        setSpecialDayModalContent,
        userLoading,
        setUserLoading,
        showTeamlessModal,
        setShowTeamlessModal,
        editTeamNameToggle,
        setEditTeamNameToggle,
        teamNamePurg,
        setTeamNamePurg,
        sureDeleteTeam,
        setSureDeleteTeam,
        allUsersArray,
        setAllUsersArray,
        showMgrsNotUsers,
        setShowMgrsNotUsers,
        mgrNotUsers,
        setMgrNotUsers,
        showAddUserToTeamModal,
        setShowAddUserToTeamModal,
        annLeavePercentage,
        setAnnLeavePercentage,
        enableTil,
        setEnableTil,
        customLeaveTypes,
        setCustomLeaveTypes,

        showUnpublished,
        setShowUnpublished,
        removeCustomLeaveType,
        setRemoveCustomLeaveType,
        showEditCustomLeaveTypeNameModal,
        setShowEditCustomLeaveTypeNameModal,
        editNameLeaveTypeID,
        setEditNameLeaveTypeID,
        editLeaveTypeNameData,
        setEditLeaveTypeNameData,
        hideFor1S,
        setHideFor1S,
        hideFor1SEnabled,
        setHideFor1SEnabled,
        allowSwaps,
        setAllowSwaps,
        selfGenSched,
        setSelfGenSched,
        dailyRates,
        setDailyRates,
        genSchedDaysAheadQty,
        setGenSchedDaysAheadQty,
        showNextYear,
        setShowNextYear,
        beyondYearStart2,
        setBeyondYearStart2,
        monthReset,
        setMonthReset,
        monRate,
        setMonRate,
        tueRate,
        setTueRate,
        wedRate,
        setWedRate,
        thuRate,
        setThuRate,
        friRate,
        setFriRate,
        satRate,
        setSatRate,
        sunRate,
        setSunRate,
        monClosed,
        setMonClosed,
        tueClosed,
        setTueClosed,
        wedClosed,
        setWedClosed,
        thuClosed,
        setThuClosed,
        friClosed,
        setFriClosed,
        satClosed,
        setSatClosed,
        sunClosed,
        setSunClosed,
        allowSelfLogAbsence,
        setAllowSelfLogAbsence,
        enableAbsenceRanking,
        setEnableAbsenceRanking,
        rtwFormEnabled,
        setRtwFormEnabled,
        rtwQuestions,
        setRtwQuestions,
        absenceTypes,
        setAbsenceTypes,

        showAddRtwModal,
        setShowAddRtwModal,
        question,
        setQuestion,
        showAddAbsenceTypeModal,
        setShowAddAbsenceTypeModal,
        addAbsenceTypeName,
        setAddAbsenceTypeName,
        addAbsenceTypePaid,
        setAddAbsenceTypePaid,
        addAbsenceTypePage,
        setAddAbsenceTypePage,
        adminEnableOvertime,
        setAdminEnableOvertime,
        adminEnableOvertimeSelfLogging,
        setAdminEnableOvertimeSelfLogging,
        excessMinsPaidInRequests,
        setExcessMinsPaidInRequests,
        addExcessMinsToAllowanceInstead,
        setAddExcessMinsToAllowanceInstead,
        overtimeIndividuals,
        setOvertimeIndividuals,
        showInfo1,
        setShowInfo1,
        showInfo2,
        setShowInfo2,
        addAbsenceTypeRate,
        setAddAbsenceTypeRate,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
