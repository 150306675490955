import React, { useState, useContext, useEffect } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// this component is not used
import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import serverURL from "../../../serverURL";

import dateStringer from "../../../tools/dateStringer";
import CheckUser from "../../../tools/CheckUser";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import whitecross from "../../../img/general/whitecross.svg";
import TopBar from "../../TopBar";
import reverse1 from "../../../img/general/reverse1.svg";
import "../../../styles/calendar.css";

import CalendarSubBar from "../CalendarSubBar";

import Navbar from "../../Navbar";

import UserShifts from "./UserShifts";
import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";

const Swaps = ({ sideBoxData, my, setMy }) => {
  const { shiftsPage, setShiftsPage } = useContext(CalendarContext);
  const { calPage, setCalPage, userShiftsPage, setUserShiftsPage } =
    useContext(CalendarContext);
  const { permissions } = useContext(UserContext);
  const {
    device,
    setPage,
    availableMonths,
    setAvailableMonths,
    // my, setMy
  } = useContext(DataContext);
  const { mob, full, setFull } = useContext(StyleContext);

  let [reverse, setReverse] = useState(false);
  let [loading, setLoading] = useState(true);
  let [clockOns, setClockOns] = useState([]);

  useEffect(() => {
    // clean not needed
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (my) {
      axios
        .post(
          `${serverURL}/get-user-clock-ons`,
          { my: my },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setClockOns(response.data.clockOns);
            setTimeout(() => {
              setLoading(false);
            }, 250);
          }
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [my]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!loading) {
      let count = 0;
      clockOns.forEach((item) => {
        if (item) {
          count++;
        }
      });
      if (!count) {
        axios
          .post(
            `${serverURL}/get-user-clock-ons`,
            { my: my },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setClockOns(response.data.clockOns);
            }
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [loading]);

  let generateMonthOptions = availableMonths.map((month) => {
    let formatMonth = "Jan";
    let formatYear = month.substr(2, 2);
    if (month.substr(0, 2) === "00") {
      formatMonth = "Jan";
    }
    if (month.substr(0, 2) === "01") {
      formatMonth = "Feb";
    }
    if (month.substr(0, 2) === "02") {
      formatMonth = "Mar";
    }
    if (month.substr(0, 2) === "03") {
      formatMonth = "Apr";
    }
    if (month.substr(0, 2) === "04") {
      formatMonth = "May";
    }
    if (month.substr(0, 2) === "05") {
      formatMonth = "Jun";
    }
    if (month.substr(0, 2) === "06") {
      formatMonth = "Jul";
    }
    if (month.substr(0, 2) === "07") {
      formatMonth = "Aug";
    }
    if (month.substr(0, 2) === "08") {
      formatMonth = "Sep";
    }
    if (month.substr(0, 2) === "09") {
      formatMonth = "Oct";
    }
    if (month.substr(0, 2) === "10") {
      formatMonth = "Nov";
    }
    if (month.substr(0, 2) === "11") {
      formatMonth = "Dec";
    }

    return (
      <option key={month} value={month}>
        {formatMonth} {formatYear}
      </option>
    );
  });

  // set calendarSubBar:
  useEffect(() => {
    // clean not needed
    setCalPage("shifts");
    setPage("clock-ons");
  }, []);
  // shiftsPage, setShiftsPage:
  // shifts, stats, availability, team-shifts

  let [showFilter, setShowFilter] = useState(false);

  let generateClockOns = clockOns.map((clock) => {
    if (clock.startDs && clock.endDs) {
      let calcClockedMins = (ds1, ds2, brkMins) => {
        if (ds1 && ds2) {
          let ds1Ts = dateStringer.createTimestampFromString(ds1);
          let ds2Ts = dateStringer.createTimestampFromString(ds2);

          let msDiff = ds2Ts - ds1Ts;

          let brkMs = brkMins * 60 * 1000;
          let totMs = msDiff - brkMs;

          return totMs / 1000;
        }
      };

      return (
        <div
          className="clockUnit"
          key={clock.clockOnID}
          onClick={() => {
            axios
              .post(
                `${serverURL}/get-single-clock-on-data`,
                { clockOnID: clock.clockOnID },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  console.log(response.data);
                }
              });
          }}
        >
          <div className="tilShiftSection0">
            <div
              className={`${
                clock.approved ? "myShiftWorkedDot" : "myShiftUnWorkedDot"
              } ${clock.declined ? "myShiftWorkedDotAbsent" : ""}`}
            ></div>
          </div>
          <div className="tilShiftSection1">
            <p className={`tilShiftDs `}>
              {dateStringer.tsToDayAndDate(
                dateStringer.createTimestampFromString(clock.startDs)
              )}
              <span className="shiftTilNameTxt">
                {clock.type === "shift"
                  ? "Clocked shift"
                  : `${
                      clock.type === "Clocked overtime"
                        ? "Overtime"
                        : "Clocked time in lieu"
                    }`}
              </span>
            </p>
            <div className="timesAndTagContainer">
              <p className="tilShiftTimes">
                {`${clock.startDs.substr(12, 2)}:${clock.startDs.substr(
                  15,
                  2
                )} - ${clock.endDs.substr(12, 2)}:${clock.endDs.substr(15, 2)}`}
              </p>
            </div>
          </div>
          <div className="tilShiftSection2">
            <p className="tilShiftTypeTxt">
              {dateStringer.formatMinsDurationToHours(
                calcClockedMins(clock.startDs, clock.endDs, clock.totBrkMins) /
                  60
              )}
            </p>
            <p className={`myShiftsUnitDurationSubTxt`}>
              {dateStringer.formatMinsDurationToHours(clock.totBrkMins)} break
            </p>
          </div>
        </div>
      );
    }
  });

  return (
    <div className={`userShiftsPage ${mob ? "" : "pageLeft240pxDesktop"} none`}>
      <ShiftsHeader my={my} setMy={setMy} />
      {/* <div className="adminCurverCropper bgColUpcoming absenceShoulder">
        <div className="adminCurve"></div>
      </div>{" "} */}
      <div className={`desktopMaster`}>
        <div
          className={`desktopMasterMain  ${mob ? "mob100Witdth" : ""} ${
            !full && !mob ? "midContentWidth" : ""
          }`}
        >
          <div className={`shiftsPageFilterBar ${!mob && "borderLeftGrey"}`}>
            <div className="shiftsPageFilterBarLeft">
              <select
                className="shiftsTilMonthDropdown"
                value={my}
                onChange={(e) => {
                  setLoading(true);
                  // setUpdateShifts(!updateShifts);
                  setMy(e.target.value);
                }}
              >
                {generateMonthOptions}
              </select>
              <img
                src={reverse ? reverse1 : reverse1}
                className={`reverseShiftsIcon  ${
                  reverse ? "" : "reversedIcon"
                }`}
                alt="Reverse"
                onClick={() => {
                  setReverse(!reverse);

                  axios
                    .post(
                      `${serverURL}/reverse-my-shifts`,
                      { data: !reverse },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                      }
                    });
                }}
              />
            </div>{" "}
            <div className="shiftsPageFilterBarRight">
              <div
                className={`${
                  showFilter ? "shiftTilFilterBtnActive" : "shiftTilFilterBtn"
                }`}
                onClick={() => {
                  setShowFilter(!showFilter);
                  console.log(clockOns);
                }}
              >
                Filter{" "}
                <img
                  className={`${
                    showFilter ? "closeFilterImgTilShifts" : "none"
                  }`}
                  alt="close"
                  src={whitecross}
                />
              </div>
            </div>
          </div>
          <div className="shiftTilFilterHolder"></div>
          <div className="filterSplitterContainer">
            <div className="filterSplitter"></div>
          </div>
          <div className="availabilityBodyContainer" onClick={() => {}}>
            {/* render here */}
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />
            <div
              className={`${!loading ? "clockOnsContainer" : "none"} ${
                device.vhIssue ? "vhIssue" : ""
              }`}
            >
              {generateClockOns}
            </div>
          </div>
        </div>
        <div className={`${mob || !full ? "none" : "desktopMasterSecondary"}`}>
          <HoursDatabox sideBoxData={sideBoxData} my={my} setMy={setMy} />{" "}
        </div>
      </div>
    </div>
  );
};

export default Swaps;
