import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";

import axios from "axios";

import logoicon from "../../img/signIn/smalllogo.png";
import download from "../../img/signIn/download.svg";
import eye from "../../img/signIn/eye.svg";
import logotext from "../../img/signIn/logotext.png";

import dateStringer from "../../tools/dateStringer";

import "../../styles/signup.css";

import serverURL from "../../serverURL";

import AddTeams from "./AddTeams";

// function will return false if the day and month doesn't exist
import checkDayExists from "../../tools/checkDayExists";
import { now } from "jquery";

const Signup = () => {
  // is the form filled?
  const [formFilledP1, setFormFilledP1] = useState(false);

  // PAGE NUMBER - there are 4 pages on the signup page.
  // each page is hidden by class
  // 1.) email & password
  // 2.) fname & lname
  // 3.) company name
  // 4.) year start
  // 5.) teams
  // 6.) When did user start
  // 7.) How many mins per week until today?
  // 8.) overview - ready to submit (validate)

  const [pageNumber, setPageNumber] = useState(1);

  // FORM INPUT VALUES
  // value of email input field
  const [upEmailInput, setUpEmailInput] = useState("");

  // value of password input field
  const [upPassInput, setUpPassInput] = useState("");

  // value of fName
  const [upFirstName, setUpFirstName] = useState("");

  // value of lName
  const [upLastName, setUpLastName] = useState("");

  // value of company name
  const [companyName, setCompanyName] = useState("");

  // value of start day
  const [startDay, setStartDay] = useState("01");

  // value of start month
  const [startMonth, setStartMonth] = useState("01");

  // get signUpTeams from datacontext - the signUp teams is stored in the datacontext as I coulnd't find another way to pass the data from child (AddTeams.js) to parent
  const { user, setUser, signUpTeams, setValidUser } = useContext(DataContext);

  // is the email address unique?
  const [unique, setUnique] = useState(true);

  // is the password long enough?
  const [passLong, setPassLong] = useState(true);

  let [historyHoursPerWeek, setHistoryHoursPerWeek] = useState(0);
  let [historyMinsPerWeek, setHistoryMinsPerWeek] = useState(0);

  let [userStartDate, setUserStartDate] = useState("");

  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let [todayDs, setTodayDs] = useState(
    dateStringer.createStringFromTimestamp(today.getTime())
  );

  // p1: check all fields on page 1 are filled
  useEffect(() => {
    if (
      upEmailInput &&
      upPassInput &&
      upPassInput.length > 6 &&
      upEmailInput.includes("@") &&
      upEmailInput.includes(".")
    ) {
      setFormFilledP1(true);
    } else {
      setFormFilledP1(false);
    }
  }, [upEmailInput, upPassInput]);

  const convertStart = (staDay, staMon) => {
    return `D${staDay}M${staMon}`;
  };

  // use the checkDayExists.js tool to validate day.
  // sets var of supStartDateOK to true if valid
  var supStartDateOK = true;
  supStartDateOK = checkDayExists(startDay, startMonth);

  // state for the show password button to change the password input type
  const [showPassword, setShowPassword] = useState(false);

  let [startDateValString, setStartDateValString] = useState("");

  let [yrStartDs, setYrStartDs] = useState("");

  let [
    arrayOfDatesBetweenUserStartAndToday,
    setArrayOfDatesBetweenUserStartAndToday,
  ] = useState([]);

  let [beyondStart, setBeyondStart] = useState(true);
  useEffect(() => {
    let nowDate = new Date();
    let beyondYrStart = false;
    if (
      nowDate.getDate() >= parseInt(startDay) &&
      nowDate.getMonth() >= parseInt(startMonth) - 1
    ) {
      beyondYrStart = true;
      setBeyondStart(true);
    } else {
      beyondYrStart = false;
      setBeyondStart(false);
    }

    let formatMonth = (n) => {
      if (n === 1) {
        return "Jan";
      }
      if (n === 2) {
        return "Feb";
      }
      if (n === 3) {
        return "Mar";
      }
      if (n === 4) {
        return "Apr";
      }
      if (n === 5) {
        return "May";
      }
      if (n === 6) {
        return "Jun";
      }
      if (n === 7) {
        return "Jul";
      }
      if (n === 8) {
        return "Aug";
      }
      if (n === 9) {
        return "Sep";
      }
      if (n === 10) {
        return "Oct";
      }
      if (n === 11) {
        return "Nov";
      }
      if (n === 12) {
        return "Dec";
      }
    };

    if (startDay && startMonth) {
      let str = `${parseInt(startDay)} ${formatMonth(parseInt(startMonth))} ${
        beyondYrStart
          ? `${nowDate.getFullYear()}`
          : `${nowDate.getFullYear() - 1}`
      }`;
      setStartDateValString(str);

      let dateObj = new Date();
      dateObj.setFullYear(
        beyondYrStart ? nowDate.getFullYear() : nowDate.getFullYear() - 1
      );
      dateObj.setMonth(parseInt(startMonth) - 1);
      dateObj.setDate(parseInt(startDay));
      dateObj.setHours(0, 0, 0, 0);

      setUserStartDate(
        dateStringer.createStringFromTimestamp(dateObj.getTime())
      );

      setYrStartDs(dateStringer.createStringFromTimestamp(dateObj.getTime()));

      // generate array of dates between yrStart and today
      let todayAt00 = new Date();
      todayAt00.setHours(0, 0, 0, 0);
      let totalDays =
        Math.round((todayAt00.getTime() - dateObj.getTime()) / 86400000) + 1;
      let dateIterator = new Date(dateObj.getTime());
      let arr = [];
      for (var i = 0; i < totalDays - 1; i++) {
        // console.log(dateIterator);
        dateIterator.setDate(dateIterator.getDate() + 1);

        arr.push(
          dateStringer.createStringFromTimestamp(dateIterator.getTime())
        );
        dateIterator.setHours(0, 0, 0, 0);
      }
      setArrayOfDatesBetweenUserStartAndToday(arr);
    } else {
      setStartDateValString("");
    }
  }, [startDay, startMonth]);

  let [startedAfterYrStart, setStartedAfterYrStart] = useState(false);

  let generateManualDays = arrayOfDatesBetweenUserStartAndToday.map((ds) => {
    let todayDateObj = new Date();
    todayDateObj.setHours(0, 0, 0, 0);
    let todayDs = dateStringer.createStringFromTimestamp(
      todayDateObj.getTime()
    );
    return (
      <option key={ds} value={ds}>
        {`${
          ds === todayDs
            ? "Today"
            : dateStringer.createShortStripFromDateString(ds)
        }`}
      </option>
    );
  });
  // render
  return (
    <div className="signUpBackground">
      <div className="downloadBar">
        <div className="downloadBarContainer">
          <img src={download} alt="download pwa" id="mobIcon" />
          <p className="downloadBarText">Install FlowRota on your smartphone</p>
        </div>
      </div>
      <div className="signUpContainer">
        <img className="signUpLogo" alt="FlowRota logo" src={logoicon} />
        <div className="signUpForm">
          {/* EMAIL EXISTS WARNING */}
          <div className={`${unique ? "none" : ""}`}>
            <NavLink to="/signin">
              <p className="emailExists" onClick={() => {}}>
                Email already exists - Sign in instead?
              </p>
            </NavLink>
          </div>
          {/* PASSWORD NOT LONG ENOUGH WARNING */}
          <div className={`${passLong ? "none" : ""}`}>
            <p className="passwordNotLongEnough">
              Password must have 8+ characters
            </p>
          </div>
          <input
            type="email"
            id="email"
            className="signinInput signUpEmailInput"
            ontouchstart="this.removeAttribute('readonly')"
            onfocus="this.removeAttribute('readonly')       "
            autoComplete="off"
            name="email"
            placeholder="email"
            onChange={(e) => {
              setUpEmailInput(e.target.value);
              // checkFormFieldsP1();
            }}
          />
          <br />
          <input
            type={`${showPassword ? "text" : "password"}`}
            id="password"
            autoComplete="new-password"
            name="password"
            placeholder="password (8+ characters)"
            onChange={(e) => {
              setUpPassInput(e.target.value);
              // checkFormFieldsP1();
            }}
          ></input>
          <img
            className="showPassword"
            src={eye}
            alt="show password"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
          <br /> <br />
          <p
            className={`signinBtn ${!formFilledP1 ? "hide" : ""}`}
            onClick={(event) => {
              if (formFilledP1) {
                setPageNumber(2);
              } else {
                setPassLong(false);
              }
            }}
          >
            Let's go
          </p>
          <div className="signupDivider"></div>
          <NavLink to="/signin">
            <p className="alternativeIn">Sign in</p>
          </NavLink>
          {/* PAGE 2 */}
          <div className={`${pageNumber !== 2 ? "hidePage" : "page2bg"}`}>
            <div className="page2Container">
              {/* <p className='page2Text'>Firstly, about you...</p> */}

              <label htmlFor="fName">
                <p className="firstNameText">first name</p>
              </label>
              <input
                type="text"
                autoComplete="off"
                autofill="none"
                // placeholder='first name'
                id="fName"
                name="fName"
                value={upFirstName}
                onChange={(e) => {
                  setUpFirstName(e.target.value);
                }}
              />
              <label htmlFor="lName">
                <p className="lastNameText">last name</p>
              </label>
              <input
                type="text"
                autoComplete="off"
                // placeholder='last name'
                autofill="none"
                id="lName"
                name="lName"
                value={upLastName}
                onChange={(e) => {
                  setUpLastName(e.target.value);
                }}
              />

              {/* next button: 2 to 3 */}
              <p
                className="nextBtn"
                type="submit"
                onClick={(event) => {
                  setPageNumber(3);
                }}
              >
                Next
              </p>
              <div className="chapterParent">
                <div className="chapterContainer">
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  ></div>
                  <div className="chapHighlighted"></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(3);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(4);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(5);
                    }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* PAGE 3 */}
          <div
            className={`'' ${
              pageNumber !== 3 ? "page3bg hidePage" : "page3bg"
            }`}
          >
            <div className="page3Container">
              {/* <p className='page3Text'>And your company...</p> */}

              {/* input for company name */}
              <label htmlFor="companyName">
                <p className="formCompanyName">company name</p>
              </label>
              <input
                type="text"
                autoComplete="off"
                autofill="none"
                id="companyName"
                name="companyName"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />

              {/* Next button 3 to 4 */}
              <p
                className="nextBtn"
                type="submit"
                onClick={(event) => {
                  setPageNumber(4);
                }}
              >
                Next
              </p>
              <div className="chapterParent">
                <div className="chapterContainer">
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(2);
                    }}
                  ></div>
                  <div className="chapHighlighted"></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(4);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(5);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      // setPageNumber(6);
                    }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                </div>{" "}
              </div>
            </div>
          </div>
          {/* PAGE 4: Start day and month */}
          <div
            className={`'' ${
              pageNumber !== 4 ? "page4bg hidePage" : "page4bg"
            }`}
          >
            <div className="page3Container">
              {/* input for start day */}
              <label htmlFor="startDate">
                <p className="formYrStartDate" onClick={() => {}}>
                  When does your year start?
                </p>
              </label>

              <br />
              <br />
              <div className="startendContainer">
                <label id="startDayLabel">Day </label>
                <select
                  id="daySelectDropdown"
                  placeholder="1"
                  type="number"
                  value={startDay}
                  onChange={(e) => {
                    setStartDay(e.target.value);
                    //   doesDayExist();
                  }}
                >
                  <option value="01">1st</option>
                  <option value="02">2nd</option>
                  <option value="03">3rd</option>
                  <option value="04">4th</option>
                  <option value="05">5th</option>
                  <option value="06">6th</option>
                  <option value="07">7th</option>
                  <option value="08">8th</option>
                  <option value="09">9th</option>
                  <option value="10">10th</option>
                  <option value="11">11th</option>
                  <option value="12">12th</option>
                  <option value="13">13th</option>
                  <option value="14">14th</option>
                  <option value="15">15th</option>
                  <option value="16">16th</option>
                  <option value="17">17th</option>
                  <option value="18">18th</option>
                  <option value="19">19th</option>
                  <option value="20">20th</option>
                  <option value="21">21st</option>
                  <option value="22">22nd</option>
                  <option value="23">23rd</option>
                  <option value="24">24th</option>
                  <option value="25">25th</option>
                  <option value="26">26th</option>
                  <option value="27">27th</option>
                  <option value="28">28th</option>
                  <option value="29">29th</option>
                  <option value="30">30th</option>
                  <option value="31">31st</option>
                </select>
                <br />
                <br />
                <label id="monthLabel">Month </label>
                <select
                  id="monthSelect"
                  placeholder="0"
                  value={startMonth}
                  onChange={(e) => {
                    setStartMonth(e.target.value);

                    //   doesDayExist();
                  }}
                >
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>

              {/* Next button 4 to 5 */}
              <p
                className={`${supStartDateOK ? "nextBtn" : "nextBtnInactive"}`}
                type="submit"
                onClick={(event) => {
                  setPageNumber(5);
                }}
              >
                Next
              </p>
              <div className="chapterParent">
                <div className="chapterContainer">
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(2);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(3);
                    }}
                  ></div>
                  <div className="chapHighlighted"></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(5);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      // setPageNumber(6);
                    }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                </div>{" "}
              </div>
            </div>
          </div>
          {/* PAGE 5: Add teams */}
          <div
            className={`'' ${
              pageNumber !== 5 ? "page5bg hidePage" : "page5bg"
            }`}
          >
            <div className="page3Container">
              {/* input for start day */}
              <label htmlFor="startDate">
                <p className="formYrStartDate">Add a team or two...</p>
                <p className="addMoreLater">(you can add more later)</p>
              </label>

              <AddTeams />

              {/* Submit button */}
              <p
                className="nextBtnTeams"
                type="submit"
                onClick={(event) => {
                  // request to submit and signup user
                  // axios
                  //   .post(
                  //     `${serverURL}/signup`,
                  //     {
                  //       email: upEmailInput,
                  //       password: upPassInput,
                  //       fName: upFirstName,
                  //       lName: upLastName,
                  //       yearStart: convertStart(startDay, startMonth),
                  //       companyName: companyName,
                  //       signUpTeams: signUpTeams,
                  //     },

                  //     {
                  //       withCredentials: true,
                  //       credentials: "include",
                  //     }
                  //   )
                  //   .then((response) => {
                  //     // console.log(response.data.email);

                  //     if (
                  //       response.data.email ===
                  //       "That email is already registered"
                  //     ) {
                  //       setUnique(false);
                  //       setPageNumber(1);
                  //       setPassLong(true);
                  //     } else {
                  //     }
                  //     if (
                  //       response.data.password ===
                  //       "Minimum password length is 8 characters"
                  //     ) {
                  //       setPassLong(false);
                  //       setPageNumber(1);
                  //     } else {
                  //       // setValidUser(true);
                  //       setPassLong(true);
                  //     }
                  //   });

                  setPageNumber(6);
                }}
              >
                {`${signUpTeams.length > 0 ? "Next" : "Skip"}`}
              </p>

              <div className="chapterParent">
                <div className="chapterContainer">
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(2);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(3);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(4);
                    }}
                  ></div>
                  <div className="chapHighlighted"></div>

                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(5);
                    }}
                  ></div>

                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* - - - - - - - - - - - - - -  */}
          {/* PAGE 6: when did you start?? */}
          <div
            className={`'' ${
              pageNumber !== 6 ? "page6bg hidePage" : "page6bg"
            }`}
          >
            <div className="page3Container">
              {/* input for start day */}
              <label htmlFor="startDate">
                <p
                  className="formYrStartDate"
                  onClick={() => {
                    console.log("start date to send on request", userStartDate);
                  }}
                >
                  When did you start working at {companyName}?
                </p>
                {/* <p className='addMoreLater'>An approximate date is needed</p> */}
              </label>

              <div className="whenDidYouStartOptionsContainer">
                <p
                  className={`whenDidYouStartButton ${
                    startedAfterYrStart ? "" : "whenDidYouStartSelected"
                  }`}
                >
                  Before or on
                  <br />
                  <span
                    className="startDateValSpan"
                    onClick={() => {
                      setStartedAfterYrStart(false);
                      setUserStartDate(yrStartDs);
                    }}
                  >
                    {startDateValString}
                  </span>
                </p>
                <p
                  className={`whenDidYouStartButton ${
                    startedAfterYrStart ? "whenDidYouStartSelected" : ""
                  }`}
                  onClick={() => {
                    setStartedAfterYrStart(true);
                    setUserStartDate(todayDs);
                  }}
                >
                  After
                  <br />
                  <span className="startDateValSpan">{startDateValString}</span>
                </p>
              </div>

              <div className="manualDateInput">
                <select
                  value={todayDs}
                  onChange={(e) => {
                    setTodayDs(e.target.value);
                    setUserStartDate(e.target.value);
                  }}
                  className={`${
                    startedAfterYrStart ? "manualDayInput" : "none"
                  }`}
                >
                  {generateManualDays}
                </select>
              </div>

              {/* Submit button */}
              <p
                className="nextBtnTeams"
                type="submit"
                onClick={(event) => {
                  let todayAtMidnight = new Date();
                  todayAtMidnight.setHours(0, 0, 0, 0);
                  let todayDateString = dateStringer.createStringFromTimestamp(
                    todayAtMidnight.getTime()
                  );

                  if (userStartDate !== todayDateString) {
                    setPageNumber(7);
                  } else {
                    axios
                      .post(
                        `${serverURL}/signup`,
                        {
                          email: upEmailInput,
                          password: upPassInput,
                          fName: upFirstName,
                          lName: upLastName,
                          yearStart: convertStart(startDay, startMonth),
                          companyName: companyName,
                          signUpTeams: signUpTeams,
                          startDate: userStartDate,
                          schedMinsTotal: 0,
                          historyMpw:
                            historyHoursPerWeek * 60 + historyMinsPerWeek,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (
                          response.data.email ===
                          "That email is already registered"
                        ) {
                          setUnique(false);
                          setPageNumber(1);
                          setPassLong(true);
                        } else {
                        }
                        if (
                          response.data.password ===
                          "Minimum password length is 8 characters"
                        ) {
                          setPassLong(false);
                          setPageNumber(1);
                        } else {
                          // setValidUser(true);
                          setPassLong(true);
                        }
                      });

                    setPageNumber(8);
                  }
                }}
              >
                Next
              </p>
              <div className="chapterParent">
                <div className="chapterContainer">
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(2);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(3);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(4);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(6);
                    }}
                  ></div>
                  <div className="chapHighlighted"></div>

                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                  <div
                    className="chap"
                    // onClick={() => {
                    //   setPageNumber(6);
                    // }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {/* PAGE 7: if started before today, how many mins per week did user work since yearstart date or user's start date, which ever is later */}
          <div
            className={`'' ${
              pageNumber !== 7 ? "page7bg hidePage" : "page7bg"
            }`}
          >
            <div className="page3Container">
              {/* input for start day */}
              <label htmlFor="startDate">
                <p className="formYrStartDate">
                  Finally, how many hours&nbsp;
                  <span className="perWeekSpan">per week</span> have you worked
                  on average between&nbsp;
                  <span className="startDateSpanNoBreak">
                    {dateStringer.createShortStripFromDateString(userStartDate)}
                  </span>
                  &nbsp;and today?
                </p>
                {/* <p className='addMoreLater'>(you can add more later)</p> */}
              </label>

              {/* mins per week inputters */}
              <div className="initUserMpwHistoryInput">
                <div className="initUserHoursMinsContainer">
                  <p className="hoursMinsLabelOnInitCreate"></p>

                  <input
                    className="mpwInputForNewUserHistory"
                    type="number"
                    value={historyHoursPerWeek}
                    onChange={(e) => {
                      setHistoryHoursPerWeek(e.target.value);
                    }}
                    onClick={() => {
                      setHistoryHoursPerWeek("");
                    }}
                  ></input>
                  <p className="hoursMinsLabelOnInitCreate">hours</p>
                </div>
                <div className="initUserHoursMinsContainer">
                  <p className="hoursMinsLabelOnInitCreate"></p>

                  <input
                    className="mpwInputForNewUserHistory"
                    type="number"
                    value={historyMinsPerWeek}
                    onChange={(e) => {
                      setHistoryMinsPerWeek(e.target.value);
                    }}
                    onClick={() => {
                      setHistoryMinsPerWeek("");
                    }}
                  ></input>
                  <p className="hoursMinsLabelOnInitCreate">minutes</p>
                </div>
              </div>

              {/* Submit button */}
              <p
                className="nextBtnTeams"
                type="submit"
                onClick={(event) => {
                  axios
                    .post(
                      `${serverURL}/signup`,
                      {
                        email: upEmailInput,
                        password: upPassInput,
                        fName: upFirstName,
                        lName: upLastName,
                        yearStart: convertStart(startDay, startMonth),
                        companyName: companyName,
                        signUpTeams: signUpTeams,
                        startDate: userStartDate,
                        schedMinsTotal: 0,
                        historyMpw:
                          historyHoursPerWeek * 60 + historyMinsPerWeek,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (
                        response.data.email ===
                        "That email is already registered"
                      ) {
                        setUnique(false);
                        setPageNumber(1);
                        setPassLong(true);
                      } else {
                      }
                      if (
                        response.data.password ===
                        "Minimum password length is 8 characters"
                      ) {
                        setPassLong(false);
                        setPageNumber(1);
                      } else {
                        // setValidUser(true);
                        setPassLong(true);
                      }
                    });

                  setPageNumber(8);
                }}
              >
                Next
              </p>
              <div className="chapterParent">
                <div className="chapterContainer">
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(2);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(3);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(4);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(6);
                    }}
                  ></div>

                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(7);
                    }}
                  ></div>
                  <div className="chapHighlighted"></div>

                  <div className="chap"></div>
                </div>
              </div>
            </div>
          </div>
          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
          {/* PAGE 8: Ready to submit */}
          <div
            className={`'' ${
              pageNumber !== 8 ? "page8bg hidePage" : "page8bg"
            }`}
          >
            <div className="page3Container">
              {/* input for start day */}
              <label htmlFor="startDate">
                <p className="goodToGo">
                  All seems to be in order, {upFirstName}.
                </p>
                <p className="goodToGo2">
                  We've set {companyName} up and everything's ready to go.
                </p>
              </label>

              {/* Submit button */}

              {/* the teams are stored as an array in signUpTeams var (from the datacontext) */}
              {/* <NavLink to='/requests'> */}

              <div>
                <NavLink to="team-rota">
                  <p className="nextBtn" type="submit">
                    FINISH
                  </p>
                </NavLink>
              </div>
              {/* </NavLink> */}
              <div className="chapterParent">
                <div className="chapterContainer">
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(1);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(2);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(3);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(4);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(5);
                    }}
                  ></div>
                  <div
                    className="chap"
                    onClick={() => {
                      setPageNumber(6);
                    }}
                  ></div>
                  <div
                    className={`${startedAfterYrStart ? "chap" : "none"}`}
                    onClick={() => {
                      setPageNumber(7);
                    }}
                  ></div>
                  <div className="chapHighlighted"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
