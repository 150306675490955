import React, { useState, useContext, useEffect, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import serverURL from "../../../serverURL";

import dateStringer from "../../../tools/dateStringer";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import timesheetImg from "../../../img/general/ts.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import teamIcon from "../../../img/general/teamIcon.svg";

import "../../../styles/calendar.css";

import ShiftsHeader from "./ShiftsHeader";
import TeamHoursDataBox from "./TeamHoursDataBox";

const TeamTimesheets = ({ sideBoxData, my, setMy }) => {
  const { setCalPage } = useContext(CalendarContext);
  const {
    setPage,
    availableMonths,
    selectedShiftTeam,
    setShowViewTimesheet,
    updateUserTimesheets,
  } = useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setPage, //
      availableMonths, //
      selectedShiftTeam, //
      setShowViewTimesheet, //
      updateUserTimesheets, //
      setCalPage, //
      mob, //
      full, //
      fullButNarrow, //
    }),
    [
      setPage, //
      availableMonths, //
      selectedShiftTeam, //
      setShowViewTimesheet, //
      updateUserTimesheets, //
      setCalPage, //
      mob, //
      full, //
      fullButNarrow, //
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 3) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      {" "}
      <img
        src={timesheetImg}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.timesheetID) {
      memoVals.setShowViewTimesheet((x) => {
        return {
          show: true,
          timesheetID: params.timesheetID,
          isMgr: true,
        };
      });

      let currentUrl = new URL(window.location.href);

      // Remove query parameters by setting search to an empty string
      currentUrl.search = "";

      // Update the URL without the query parameters
      window.history.replaceState({}, document.title, currentUrl.href);
    }
  }, []);
  let [clockOns, setClockOns] = useState([]);
  let [pendingExist, setPendingExist] = useState(0);

  let [timesheets, setTimesheets] = useState([]);

  useEffect(() => {
    // clean not needed
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);

    memoVals.setCalPage((x) => "team-shifts");
  }, []);

  let [users, setUsers] = useState([]);
  let [selectedUserID, setSelectedUserID] = useState("");
  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();

  useEffect(() => {
    if (!users[0]) {
      axios
        .post(
          `${serverURL}/get-users-user-can-manage`,
          { someData: 1 },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setUsers(
              response.data.usersArr.sort((a, b) => {
                return a.fName.localeCompare(b.fName);
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);

  let [reloading, setReloading] = useState(false);
  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-team-timesheets`,
        { teamID: memoVals.selectedShiftTeam, my },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource2.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setDataLoaded(true);
          let pending = 0;
          response.data.teamTimesheets.forEach((ts) => {
            if (!ts.approved && !ts.declined) {
              pending++;
            }
          });

          if (reloading) {
            setReloading(false);
          }

          let pends = [];
          let decided = [];
          if (response.data.teamTimesheets) {
            response.data.teamTimesheets.forEach((ts) => {
              if (!ts.approved && !ts.declined) {
                pends.push(ts);
              } else {
                decided.push(ts);
              }
            });
          }

          let arr = [...pends];
          if (pending && decided[0]) {
            arr.push({ split: true });
          }

          arr = [...arr, ...decided];

          setPendingExist(pending);
          setTimesheets(arr || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.selectedShiftTeam, my, memoVals.updateUserTimesheets]);

  let generateMonthOptions = memoVals.availableMonths.map((month) => {
    if (dateStringer.getTsFromMy(month) <= new Date().getTime()) {
      let formatMonth = "Jan";
      let formatYear = month.substr(2, 2);
      if (month.substr(0, 2) === "00") {
        formatMonth = "January";
      }
      if (month.substr(0, 2) === "01") {
        formatMonth = "February";
      }
      if (month.substr(0, 2) === "02") {
        formatMonth = "March";
      }
      if (month.substr(0, 2) === "03") {
        formatMonth = "April";
      }
      if (month.substr(0, 2) === "04") {
        formatMonth = "May";
      }
      if (month.substr(0, 2) === "05") {
        formatMonth = "June";
      }
      if (month.substr(0, 2) === "06") {
        formatMonth = "July";
      }
      if (month.substr(0, 2) === "07") {
        formatMonth = "August";
      }
      if (month.substr(0, 2) === "08") {
        formatMonth = "September";
      }
      if (month.substr(0, 2) === "09") {
        formatMonth = "October";
      }
      if (month.substr(0, 2) === "10") {
        formatMonth = "November";
      }
      if (month.substr(0, 2) === "11") {
        formatMonth = "December";
      }

      return (
        <option key={month} value={month}>
          {formatMonth} {formatYear}
        </option>
      );
    }
  });

  useEffect(() => {
    memoVals.setPage((x) => "team-submissions");
  }, []);

  let [showFilter, setShowFilter] = useState(false);

  document.title = "FlowRota - Staff timesheets";

  let generateTimesheets = useMemo(() => {
    return timesheets.map((ts, i) => {
      if (ts.split) {
        return <div className="pendingTsSplitter"></div>;
      } else {
        return (
          <div
            className={`
           mgrClockUnitWrapperOpen x9879741 ${
             memoVals.mob && i === 0 ? "pullFirstMobClockUnitUp" : ""
           } animateZoomIn`}
            onClick={() => {
              memoVals.setShowViewTimesheet((x) => {
                return {
                  show: true,
                  timesheetID: ts.timesheetID,
                  isMgr: true,
                };
              });
            }}
          >
            <div className="clockNameAndTeamDiv">
              <div
                className={`mgrClockNameDiv ${
                  !ts.approved && !ts.declined ? "pendClockName" : ""
                }`}
              >
                <img
                  src={ts.picUrl || profilePicturePlaceholder}
                  alt={ts.fName}
                  className="itemNameTabImg"
                />{" "}
                {ts.fName} {ts.lName}
              </div>
              <div
                className={`mgrClockNameDiv clockTeamNameTopRight x981452 ${
                  !ts.approved && !ts.declined ? "pendClockName" : ""
                }`}
              >
                {memoVals.mob || memoVals.fullButNarrow
                  ? dateStringer.shorten(ts.teamName, 15)
                  : dateStringer.shorten(ts.teamName, 26)}{" "}
                <img src={teamIcon} alt="Team" className="teamIconHours" />
              </div>{" "}
            </div>

            <div
              className={`mgrReqItem noAni ${
                !ts.approved && !ts.declined ? "pend" : ""
              } ${memoVals.mob ? "borderRadius10" : ""}`}
              onClick={() => {}}
            >
              <div
                className={`tsUnitLeft ${
                  !ts.submitted
                    ? `tsUnitLeftNonSubmitted ${
                        memoVals.mob && (ts.approved || ts.declined)
                          ? "x88381831"
                          : ""
                      }`
                    : ""
                }`}
              >
                {!ts.approved && !ts.declined ? (
                  <div className="unsubmittedTsBlob"></div>
                ) : (
                  ""
                )}
                <img
                  src={timesheetImg}
                  alt="Timesheet"
                  className={`tsUnitImg ${!ts.submitted ? "x308491" : ""}`}
                />
              </div>
              <div
                className={`reqColBarDiv reqColBarAdjust5 ${
                  ts.approved ? "" : "divPend"
                } ${ts.declined ? "divDec" : ""}`}
              ></div>
              <div
                className={`tsUnitMiddle ${
                  memoVals.mob ? "tsUnitMiddleMob" : ""
                }`}
              >
                <div className="tsUnitMidLeft">
                  <div
                    className={`tsFromTilSection ${
                      !ts.approved && !ts.declined ? "x32897478243" : ""
                    }`}
                  >
                    <div className="tsUnitFromToSection">
                      {/* <p className="tsUnitDsTitle">From</p> */}
                      <p
                        className={`tsUnitDsValue x2456788743 ${
                          memoVals.mob ? "tsUnitDsValueMob" : ""
                        }`}
                      >
                        {/* {ts.fName} {ts.lName[0]} */}
                        {dateStringer.printedDateFromDs(ts.startDs)}{" "}
                        {ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00`
                          ? "-"
                          : ""}{" "}
                        {ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00`
                          ? dateStringer.printedDateFromDs(ts.endDs)
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="tsUnitTeamName x3413567">
                    {ts.approved || ts.declined ? (
                      <p
                        className={`submittedTsBadgeMgr notYetSubMgr ${
                          ts.declined ? "x091131" : "approvedTsTxt"
                        }`}
                      >
                        {ts.approved ? "Approved" : "Declined"}
                      </p>
                    ) : (
                      <p className={`submittedTsBadgeMgr notYetSubMgr`}>
                        Awaiting approval
                      </p>
                    )}
                  </div>
                </div>
                <div className="tsUnitMidRight">
                  <div className="tsUnitFromToSection x1341">
                    <p className="tsUnitDsTitle">Records</p>
                    <p className="tsUnitDsValue">{ts.itemsQty}</p>
                  </div>
                </div>
              </div>
              <div className="itemTongue tsTongue">
                <p className="tongueTxt clockTongueTxt">Timesheet</p>
              </div>
              {/* <div className="tsUnitRight"></div> */}
            </div>
          </div>
        );
      }
    });
  }, [timesheets, memoVals.mob, window.innerWidth]);
  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      <ShiftsHeader my={my} setMy={setMy} />
      {/* <div className="adminCurverCropper bgColUpcoming absenceShoulder">
        <div className="adminCurve"></div>
      </div>{" "} */}
      <div className={`desktopMaster ${memoVals.mob ? "desktopPurpBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth mob100WitdthPlus2" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}  ${
            memoVals.mob ? "mgrHoursShoulderBar" : ""
          }`}
        >
          <div
            className={`shiftsPageFilterBar ${
              !memoVals.mob && "borderLeftGrey"
            }`}
          >
            <div className="shiftsPageFilterBarLeft">
              <select
                className="shiftsTilMonthDropdown"
                value={my}
                onChange={(e) => {
                  setReloading(true);
                  // setUpdateShifts(!updateShifts);
                  setMy(e.target.value);
                }}
              >
                {generateMonthOptions}
              </select>
            </div>{" "}
            <div className="shiftsPageFilterBarRight">
              <select
                className={`shiftNamesDropdown teamTsUsersDropper ${
                  memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                }`}
                value={selectedUserID}
                onChange={(e) => {
                  setSelectedUserID(e.target.value);
                }}
              >
                <option value="allStaff">-- All of your staff</option>
                {users.map((usr, i) => {
                  return (
                    <option value={usr.userID} key={i}>
                      {usr.fName} {usr.lName[0]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="shiftTilFilterHolder"></div>
          <div className="filterSplitterContainer">
            <div className="filterSplitter"></div>
          </div>
          {/* - - - - */}

          <div
            className={`tsContainer ${!memoVals.full ? "noBorderRight" : ""} ${
              memoVals.mob ? "tsContainerStaffMob" : ""
            }`}
          >
            <img
              src={horizontalBalls}
              className={`${
                loading || reloading ? "loadingHoriztonalImg" : "none"
              }`}
              alt="Loading"
            />

            {!loading && !reloading && pendingExist ? (
              <p className="clocksAwaitingTitles notYetSubmitTitleArr">
                Awaiting approval
              </p>
            ) : (
              ""
            )}
            {!loading && !reloading ? generateTimesheets : ""}
          </div>
        </div>
        <div
          className={`${
            memoVals.mob || !memoVals.full ? "none" : "desktopMasterSecondary"
          }`}
        >
          <TeamHoursDataBox setLoadUser={false} setShowRequests={false} />{" "}
        </div>
      </div>
      {imgPreload}
    </div>
  );
};

export default TeamTimesheets;
