import React, { useEffect, useState, useContext, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import serverURL from "../../../../serverURL";

import "../../../../styles/newUser.css";

import back from "../../../../img/general/back.svg";

import AddUserSeries from "./AddUserSeries";

import { StyleContext } from "../../../../contexts/StyleContext";

const AddNewUserModals = () => {
  let {
    showAddUserModal,
    setShowAddUserModal,
    setAddUserPage,
    addUserModalCalOpen,
    setAddUserModalCalOpen,
  } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      showAddUserModal,
      setShowAddUserModal,
      setAddUserPage,
      addUserModalCalOpen,
      setAddUserModalCalOpen,
    }),
    [
      showAddUserModal,
      setShowAddUserModal,
      setAddUserPage,
      addUserModalCalOpen,
      setAddUserModalCalOpen,
    ]
  );

  let [refPeriod, setRefPeriod] = useState(0);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-ref-period`,
        {
          someData: 1,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setRefPeriod(response.data.refPeriod);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  return (
    /* --------------------------------------------------------------------------- */

    <div
      className={`${
        memoVals.showAddUserModal ? "addNewUserModalUnderlay" : "none"
      }`}
      onClick={() => {
        memoVals.setShowAddUserModal((x) => false);
        memoVals.setAddUserModalCalOpen((x) => false);
        memoVals.setAddUserPage((x) => "initial");
      }}
    >
      <div
        className={`${
          memoVals.addUserModalCalOpen
            ? "addNewUserModalBox fullHeight"
            : "addNewUserModalBox"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="addNewUserContentBox">
          {/* header */}
          <div className="addNewUserModalHeader"></div>

          <AddUserSeries refPeriod={refPeriod} />
        </div>
      </div>
    </div>

    /* --------------------------------------------------------------------------- */
  );
};

export default AddNewUserModals;
