import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useMemo,
  useRef,
} from "react";
// cleaned wed 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import serverURL from "../serverURL";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import close from "../img/modals/close.svg";
import bell2 from "../img/general/bell2.svg";
import blueArrL from "../img/general/arrGrey.svg";
import refreshRequest from "../img/general/refreshRequest.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import chat from "../img/general/chat.svg";
import left from "../img/general/left.svg";
import right from "../img/general/right.svg";
import blueArr from "../img/general/arrGrey.svg";
// import { clearScreenDown } from "readline";
const MgrAvailModal = ({ selectedUserID }) => {
  const {
    showMgrAvailModal,
    setShowMgrAvailModal,
    setShowEditUser,
    showEditUser,
    showMessages,
    setShowMessages,
    setShowEditShiftModal,
    setIndicate,
  } = useContext(DataContext);
  const { mobModal, mob } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      showMgrAvailModal,
      setShowMgrAvailModal,
      setShowEditUser,
      showEditUser,
      showMessages,
      setShowMessages,
      setShowEditShiftModal,
      setIndicate,
      checkUserState,
      mobModal,
      mob,
    }),
    [
      showMgrAvailModal, //
      setShowMgrAvailModal, //
      setShowEditUser, //
      showEditUser, //
      showMessages, //
      setShowMessages, //
      setShowEditShiftModal, //
      setIndicate, //
      checkUserState, //
      mobModal, //
      mob, //
    ]
  );

  let [monWeekMode, setMonWeekMode] = useState(true);
  let [hoveredUserID, setHoveredUserID] = useState("");

  let [showRequestAllModal, setShowRequestAllModal] = useState(false);

  let todayDs = `${dateStringer
    .createStringFromTimestamp(new Date().getTime())
    .substr(0, 11)}H00M00`;
  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      dateInput: dateStringer.createDateInputFromDateString(
        typeof memoVals.showMgrAvailModal === "object"
          ? memoVals.showMgrAvailModal.ds
          : `${dateStringer
              .createStringFromTimestamp(new Date().getTime())
              .substr(0, 11)}H00M00`
      ),
      mgrOf: [],
      teamName: "",
      usersArr: [],
      teamID:
        typeof memoVals.showMgrAvailModal === "object"
          ? memoVals.showMgrAvailModal.team
          : "",
      ds:
        typeof memoVals.showMgrAvailModal === "object"
          ? memoVals.showMgrAvailModal.ds
          : `${dateStringer
              .createStringFromTimestamp(new Date().getTime())
              .substr(0, 11)}H00M00`,
      managesIndividuals: false,
      closedDays: [],
    }
  );
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bell2}
        alt="Bell"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueArrL}
        alt="Blue Arrow Left"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={refreshRequest}
        alt="Refresh Request"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Profile Picture Placeholder"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={chat}
        alt="Chat"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={left}
        alt="Left Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={right}
        alt="Right Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueArr}
        alt="Blue Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [monWeekState, setMonWeekState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedMonWeek: "",
      currMonWeek: "",
    }
  );
  let [weekStarts, setWeekStarts] = useState([]);
  let generateMonWeeks = weekStarts.map((monWeek) => {
    return (
      <option value={monWeek} key={monWeek}>
        {dateStringer.printedDateFromDs(monWeek, true, false, false, true)}
      </option>
    );
  });

  let getTeamName = (teamID) => {
    let tName = "";
    state.mgrOf.forEach((t) => {
      if (t.teamID === teamID) {
        tName = t.teamName;
      }
    });

    return tName;
  };

  useEffect(() => {
    // clean not needed
    // sets state.ds within boundaries of weekStarts;

    let minDs = weekStarts[0];
    let maxDateObj = new Date(
      dateStringer.createTimestampFromString(weekStarts[weekStarts.length - 1])
    );
    maxDateObj.setDate(maxDateObj.getDate() + 6);
    let maxDs = dateStringer.createStringFromTimestamp(maxDateObj.getTime());

    let ts = dateStringer.createTimestampFromString(state.ds);
    let minTs = dateStringer.createTimestampFromString(minDs);
    let maxTs = dateStringer.createTimestampFromString(maxDs);

    if (ts < minTs) {
      setState({ ds: minDs });
      setDateInput(dateStringer.createDateInputFromDateString(minDs));
      setMonWeekState({ selectedMonWeek: minDs });
    } else if (ts > maxTs) {
      setState({ ds: weekStarts[weekStarts.length - 1] });
      setMonWeekState({ selectedMonWeek: weekStarts[weekStarts.length - 1] });
      setDateInput(
        dateStringer.createDateInputFromDateString(
          weekStarts[weekStarts.length - 1]
        )
      );
    } else {
      //   let monWeek = dateStringer.getCurrMonWeek(state.ds);
      console.log(
        "setting selected mon week to: ",
        dateStringer.getCurrMonWeek(state.ds),
        "which is the monweek of: ",
        state.ds
      );
      setMonWeekState({
        selectedMonWeek: dateStringer.getMonWeekOfDs(state.ds),
      });
    }

    console.log({ minDs, maxDs, "state.ds": state.ds });
  }, [state.ds]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();
    if (monWeekMode && !weekStarts[0]) {
      if (!weekStarts[0]) {
        axios
          .post(
            `${serverURL}/get-week-starts-for-schedule`,
            { someData: 1 },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              let arr = [];
              let firstDateObj = new Date(
                dateStringer.createTimestampFromString(response.data.firstDs)
              );
              let lastDateObj = new Date(
                dateStringer.createTimestampFromString(response.data.lastDs)
              );
              let weeks = Math.round(
                (lastDateObj.getTime() - firstDateObj.getTime()) /
                  1000 /
                  60 /
                  60 /
                  24 /
                  7
              );

              for (var i = 0; i < weeks; i++) {
                arr.push(
                  dateStringer.createStringFromTimestamp(firstDateObj.getTime())
                );
                firstDateObj.setHours(0, 0, 0, 0);
                firstDateObj.setDate(firstDateObj.getDate() + 7);
              }

              setWeekStarts(arr);

              let tName = "";
              response.data.teamObjs.forEach((t) => {
                if (t.teamID === response.data.teamObjs[0].teamID) {
                  tName = t.teamName;
                }
              });

              setMonWeekState({
                //   teamName: tName,
                //   teams: response.data.teamObjs,
                //   teamID: response.data.teamObjs[0].teamID,
                selectedMonWeek:
                  typeof memoVals.showMgrAvailModal === "object"
                    ? memoVals.showMgrAvailModal.ds
                    : response.data.current,
                currMonWeek: response.data.current,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
    // setLoading(true);
    axios
      .post(
        `${serverURL}/get-daily-avail-by-team`,
        {
          teamID: state.teamID,
          ds: monWeekMode ? monWeekState.selectedMonWeek : state.ds,
          monWeekMode,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource2.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          //   console.log(response.data.mgrOf);
          let newArr = [];
          response.data.usersArr.map((item) => {
            if (selectedUserID === item.userID) {
              item.sort = 0;
            } else {
              item.sort = 1;
            }
            newArr.push(item);
          });

          setState({
            teamName: response.data.teamName,
            usersArr: newArr,
            mgrOf: response.data.mgrOf,
            managesIndividuals: response.data.managesIndividuals,
            dsArr: response.data.dsArr,
            closedDays: response.data.closedDays,
          });

          if (state.teamID === "-") {
            setState({ teamID: response.data.teamID });
          }
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, [state.teamID, state.ds, monWeekMode, monWeekState.selectedMonWeek]);

  let shortenTeamName = (val) => {
    if (val && val.length > 26) {
      return `${val.substr(0, 23)}...`;
    } else {
      return val;
    }
  };

  let generateTeams = state.mgrOf.map((team) => {
    return (
      <option value={team.teamID}>{shortenTeamName(team.teamName)}</option>
    );
  });

  let [teamName, setTeamName] = useState("the team");

  let teamFilt = state.mgrOf.filter((item) => {
    item.teamID === state.teamID;
  });

  if (teamFilt[0]) {
    setTeamName(teamFilt[0].teamName);
  }

  let [dateInput, setDateInput] = useState(
    dateStringer.createDateInputFromDateString(
      typeof memoVals.showMgrAvailModal === "object"
        ? memoVals.showMgrAvailModal.ds
        : `${dateStringer
            .createStringFromTimestamp(new Date().getTime())
            .substr(0, 11)}H00M00`
    )
  );

  let [usersArr, setUsersArr] = useState([]);
  useEffect(() => {
    let newArr = [];
    state.usersArr.forEach((obj) => {
      if (obj.sort === 0) {
        newArr.push(obj);
      }
    });

    state.usersArr.forEach((obj) => {
      if (obj.sort !== 0) {
        newArr.push(obj);
      }
    });

    setUsersArr(newArr);
  }, [state.usersArr]);

  let weekViewRequested = (ds) => {
    let todayDs = `${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(0, 11)}H00M00`;
    if (todayDs === `${ds.substr(0, 11)}H00M00`) {
      return `${dateStringer.dsToTimeStrip(ds)}`;
    } else {
      return dateStringer.dateAndMonth(ds);
    }
    // {dateStringer.dsToTimeStrip(user.requested)},{" "}
    // {dateStringer.printedDateFromDs(user.requested)}
  };

  let generateMonWeekNames = usersArr.map((user) => {
    return (
      <div
        className={`monWeekAvailUserRow ${
          user.sort === 0 ? "highlightAvailUser" : ""
        }`}
        // onMouseEnter={() => {
        //   if (!memoVals.mobModal && user.userID !== memoVals.checkUserState.userID) {
        //     setHoveredUserID(user.userID);
        //   }
        // }}
        // onMouseLeave={() => {
        //   if (!memoVals.mobModal) {
        //     setHoveredUserID("");
        //   }
        // }}
      >
        <div className="monWeekAvailUserRowImgAndReqBtnContainer">
          <img
            src={user.profPicUrl ? user.profPicUrl : profilePicturePlaceholder}
            alt={user.fName}
            className="monWeekAvailUserProPicImg"
            onClick={() => {
              memoVals.setShowEditUser((x) => user.userID);
            }}
          />
          {user.submitted || user.userID === memoVals.checkUserState.userID ? (
            ""
          ) : (
            <div className="reqAndMsgDiv">
              {" "}
              <img
                className="availMsgIcon"
                src={chat}
                onClick={() => {
                  memoVals.setShowMessages((x) => user.userID);
                }}
              ></img>
              {user.requested ? (
                <p className="mgrAvailReqInfo">
                  Requested {dateStringer.printedDateFromDs(user.requested)}
                </p>
              ) : (
                <div
                  className={`monWeekAvailRowRequestBtn2 `}
                  onClick={() => {
                    let nowDs = dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    );
                    let newArr = [];
                    usersArr.map((obj) => {
                      if (obj.userID === user.userID) {
                        obj.requested = nowDs;
                      }

                      newArr.push(obj);
                    });

                    setUsersArr(newArr);
                    axios
                      .post(
                        `${serverURL}/request-availability`,
                        {
                          teamID: state.teamID,
                          ds:
                            monWeekState.selectedMonWeek ||
                            dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          userID: user.userID,
                          nowDs: nowDs,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `${user.fName}${
                                user.fName[user.fName.length - 1] === "s"
                                  ? "'"
                                  : "'s"
                              } availbilty requested`,
                              colour: "blue",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  {" "}
                  <img src={bell2} className="reqAvailBenIcon" alt="Request" />
                  {/* Request */}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="monWeekAvailNameContainer">
          <p className="monWeekAvailName">{user.fName}</p>

          <p className="monWeekAvailName">{user.lName}</p>
        </div>
      </div>
    );
  });

  let generateUsersMonWeek = state.dsArr
    ? state.dsArr.map((day) => {
        let dateObj = new Date(dateStringer.createTimestampFromString(day));
        let generateInlineUser = usersArr.map((usr) => {
          let data = usr && usr.monWeekData && usr.monWeekData.availData;
          let dateObj = new Date(dateStringer.createTimestampFromString(day));
          let dayNum = dateObj.getDay();
          let active = false;
          let start1 = "";
          let end1 = "";
          let start2 = "";
          let end2 = "";
          let count = 0;
          if (data) {
            switch (dayNum) {
              case 0:
                active = data.sunActive;
                start1 = data.sunStart;
                end1 = data.sunEnd;
                start2 = data.sunStart2;
                end2 = data.sunEnd2;
                count = data.sunCount;
                break;

              case 1:
                active = data.monActive;
                start1 = data.monStart;
                end1 = data.monEnd;
                start2 = data.monStart2;
                end2 = data.monEnd2;
                count = data.monCount;
                break;

              case 2:
                active = data.tueActive;
                start1 = data.tueStart;
                end1 = data.tueEnd;
                start2 = data.tueStart2;
                end2 = data.tueEnd2;
                count = data.tueCount;
                break;
              case 3:
                active = data.wedActive;
                start1 = data.wedStart;
                end1 = data.wedEnd;
                start2 = data.wedStart2;
                end2 = data.wedEnd2;
                count = data.wedCount;
                break;
              case 4:
                active = data.thuActive;
                start1 = data.thuStart;
                end1 = data.thuEnd;
                start2 = data.thuStart2;
                end2 = data.thuEnd2;
                count = data.thuCount;
                break;
              case 5:
                active = data.friActive;
                start1 = data.friStart;
                end1 = data.friEnd;
                start2 = data.friStart2;
                end2 = data.friEnd2;
                count = data.friCount;
                break;
              case 6:
                active = data.satActive;
                start1 = data.satStart;
                end1 = data.satEnd;
                start2 = data.satStart2;
                end2 = data.satEnd2;
                count = data.satCount;
                break;
              case 0:
                active = data.sunActive;
                start1 = data.sunStart;
                end1 = data.sunEnd;
                start2 = data.sunStart2;
                end2 = data.sunEnd2;
                count = data.sunCount;
                break;
            }
          }
          return (
            <div
              className={`mgrAvailNameDayRow ${
                usr.sort === 0 ? "selectedUserAvailRowInline" : ""
              }  ${
                state.closedDays.includes(dateObj.getDay())
                  ? "closedAvailDayCol"
                  : ""
              }`}
              onMouseEnter={() => {
                if (
                  !memoVals.mobModal &&
                  usr.userID !== memoVals.checkUserState.userID
                ) {
                  setHoveredUserID(usr.userID);
                }
              }}
              onMouseLeave={() => {
                if (!memoVals.mobModal) {
                  setHoveredUserID("");
                }
              }}
              onClick={() => {
                // console.log(usr);
                console.log({ active, start1, end1, start2, end2, count });
              }}
            >
              <p className="mgrAvailRowDsCell">
                {dateStringer.tsToDayAndDate(
                  dateStringer.createTimestampFromString(day)
                )}{" "}
                {!active ? (
                  " "
                ) : (
                  <p
                    className="availAddShiftBtn"
                    onClick={() => {
                      let today = new Date();
                      axios
                        .post(
                          `${serverURL}/schedule-new-shift`,
                          {
                            teamID: state.teamID,
                            ds: day, // steve
                            userID: usr.userID,
                            type: "shift",
                            nowDs: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setShowMgrAvailModal((x) => false);
                            memoVals.setShowEditShiftModal((x) => {
                              return {
                                itemID: response.data.shiftID,
                                itemType: response.data.type,
                                itemFName: response.data.fName,
                                itemLName: response.data.lName,
                                itemIsNew: true,
                                itemUserID: usr.userID,
                              };
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    +
                  </p>
                )}
              </p>
              <div className="mgrAvailCellBox">
                {usr.submitted && !active ? (
                  <p className="mgrAvailUserCellUnavailable">Unavailable</p>
                ) : (
                  ""
                )}
                {active ? (
                  <div className="mgrAvailUserInlineTimes1Row">
                    <span className="x248149">Available:</span>
                    <br />
                    {start1} - {end1}
                  </div>
                ) : (
                  ""
                )}
                {active && count === 2 ? (
                  <div className="mgrAvailUserInlineTimesAmper">&</div>
                ) : (
                  ""
                )}
                {active && count === 2 ? (
                  <div className="mgrAvailUserInlineTimes2Row">
                    {start2} - {end2}
                  </div>
                ) : (
                  ""
                )}

                {/* {usr.fName} {dayNum} */}
              </div>
            </div>
          );
        });

        return (
          <div
            className="teamAvailDsCol"
            onClick={() => {
              console.log(day, dateObj.getDay());
            }}
          >
            {/* <div className="availUserColDayCell">
              {dateStringer.tsToDayAndDate(
                dateStringer.createTimestampFromString(day)
              )}
            </div> */}
            {generateInlineUser}
          </div>
        );
      })
    : "";
  //   state.dsArr.map((day) => {
  //     return day;
  //   });

  let generateUsers = useMemo(() => {
    return usersArr.map((user) => {
      return (
        <div className="mgrAvailUserRow">
          <div className="mgrAvailUserRowTop">
            <div className="mgrAvailUserRowTopLeft">
              <img
                src={
                  user.profPicUrl ? user.profPicUrl : profilePicturePlaceholder
                }
                alt={user.fName}
                className="mgrAvailUserRowProfPic"
                onClick={() => {
                  memoVals.setShowEditUser((x) => user.userID);
                }}
              />

              <p
                className="mgrAvailUserRowNameTxt"
                onClick={() => {
                  memoVals.setShowEditUser((x) => user.userID);
                }}
              >
                {user.fName} {user.lName}
              </p>
            </div>

            <div className="mgrAvailUserRowTopRight">
              {user.userID !== memoVals.checkUserState.userID ? (
                <p
                  className="mgrAvailUserRowTopRightViewAvailBtn"
                  onClick={() => {
                    memoVals.setShowMessages((x) => user.userID);
                  }}
                >
                  Message
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="mgrAvailUserRowBottom">
            {user.submitted && user.active ? (
              <p className="mgrAvailUserRowBottomSubmittedBtn">Available</p>
            ) : (
              ""
            )}
            {user.submitted && !user.active ? (
              <div className="mgrAvailUserRowTimes">
                <p className="mgrUnavailUserRowTimeStrip">Unavailable</p>{" "}
              </div>
            ) : (
              ""
            )}
            {user.active && user.start1 ? (
              <div className="mgrAvailUserRowTimes">
                <p className="mgrAvailUserRowTimeStrip">
                  {user.start1} - {user.end1}
                </p>{" "}
                {user.count > 1 ? (
                  <span className="mgrAvailUserTimesAmperSand"> & </span>
                ) : (
                  ""
                )}{" "}
                {user.count > 1 ? (
                  <p className="mgrAvailUserRowTimeStrip">
                    {user.start2} - {user.end2}
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {!user.start1 && !user.requested ? (
              <div
                className="requestAvailBtn"
                onClick={() => {
                  let nowDs = dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  );
                  let newArr = [];
                  state.usersArr.map((usr) => {
                    if (usr.userID !== user.userID) {
                      if (selectedUserID === user.userID) {
                        user.sort = 0;
                      } else {
                        user.sort = 1;
                      }
                      newArr.push(usr);
                    } else {
                      if (selectedUserID === user.userID) {
                        user.sort = 0;
                      } else {
                        user.sort = 1;
                      }
                      usr.requested = nowDs;
                      newArr.push(usr);
                    }
                  });

                  newArr.sort(function (a, b) {
                    return a.fName.localeCompare(b.fName);
                  });
                  setState({ usersArr: newArr });
                  axios
                    .post(
                      `${serverURL}/request-availability`,
                      {
                        teamID: state.teamID,
                        ds:
                          state.ds ||
                          dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        userID: user.userID,
                        nowDs: nowDs,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `${user.fName}${
                              user.fName[user.fName.length - 1] === "s"
                                ? "'"
                                : "'s"
                            } availbilty requested`,
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <img src={bell2} className="reqAvailBenIcon" alt="Request" />
                Request availability
              </div>
            ) : (
              ""
            )}

            {!user.start1 && user.requested ? (
              <div className="mgrAvailRequestedStrip">
                Requested {dateStringer.dsToTimeStrip(user.requested)},{" "}
                {dateStringer.createShortStripFromDateString(user.requested)}{" "}
                <img
                  src={refreshRequest}
                  alt="Rerequest"
                  className={`rerequestAvailBtn ${
                    user.requested ===
                    dateStringer.createStringFromTimestamp(new Date().getTime())
                      ? "invis"
                      : ""
                  }`}
                  onClick={() => {
                    let newArr = [];
                    let nowDs = dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    );
                    state.usersArr.map((usr) => {
                      if (usr.userID !== user.userID) {
                        if (selectedUserID === user.userID) {
                          user.sort = 0;
                        } else {
                          user.sort = 1;
                        }
                        newArr.push(usr);
                      } else {
                        if (selectedUserID === user.userID) {
                          user.sort = 0;
                        } else {
                          user.sort = 1;
                        }
                        usr.requested = nowDs;
                        newArr.push(usr);
                      }
                    });

                    newArr.sort(function (a, b) {
                      return a.fName.localeCompare(b.fName);
                    });
                    setState({ usersArr: newArr });
                    axios
                      .post(
                        `${serverURL}/request-availability`,
                        {
                          teamID: state.teamID,
                          ds: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          userID: user.userID,
                          nowDs: nowDs,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `${user.fName}${
                                user.fName[user.fName.length - 1] === "s"
                                  ? "'"
                                  : "'s"
                              } availbilty requested`,
                              colour: "blue",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });
  }, [state.usersArr]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 140) {
      memoVals.setShowMgrAvailModal((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`clockOnModalUnderlay ${
        memoVals.showEditUser ? "teamAvailWithShowUser" : ""
      } ${memoVals.showMessages ? "none" : ""}`}
      onClick={() => {
        memoVals.setShowMgrAvailModal((x) => false);
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`clockOnModalBox ${
            monWeekMode ? "mobileMgrAvailModalBoxMediaQ" : ""
          } ${
            memoVals.mobModal
              ? "mobMgrAvailModalBox slideUp mobModalShoulder"
              : "zoomIn"
          } `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperMgrAvail"></div>
          )}
          <div
            className={`clockOnModalHeader ${
              memoVals.mobModal ? "x298429712sss" : ""
            }`}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
            onClick={() => {
              if (memoVals.mobModal) {
                memoVals.setShowMgrAvailModal((x) => false);
              }
            }}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  memoVals.setShowMgrAvailModal((x) => false);
                }}
              />
            </div>
            <p
              className={`clockOnModalHeaderTitle ${
                !state.teamName ? "invis" : ""
              }`}
            >
              Team availability
            </p>
            <div className="clockOnModalHeaderSideUnit"></div>
            {/* <div className={`clockTicker ${durString ? "" : "clockTickerInvis"}`}>
            {durString}
          </div> */}
          </div>
          <div
            className={`${
              memoVals.mobModal ? "mgrAvailModalBodyMob" : "mgrAvailModalBody"
            }`}
          >
            <div
              className={`mgrAvailSelectorBar ${
                monWeekMode
                  ? `selectorBarDynamic_ ${
                      window.innerWidth > 1170 ? "selectorBarDynamic2" : ""
                    }`
                  : ""
              }`}
            >
              {monWeekMode ? (
                <div className="availSelectRow availSelectRowSelectedWeek ">
                  <p
                    className={`selectDateRange selectDateRangeMgrAvail selectDateRangeSelectWeek ${
                      window.innerWidth <= 1170
                        ? "selectDateRangeSelectWeek2"
                        : ""
                    } ${
                      memoVals.mobModal
                        ? "mobModalWeekTitle"
                        : window.innerWidth < 820
                        ? "x389248754"
                        : ""
                    } ${
                      window.innerWidth > 499 && window.innerWidth < 800
                        ? "x28394293"
                        : ""
                    }`}
                  >
                    Week
                  </p>
                  <div className="teamSchedFilterBarLeftAvail">
                    <img
                      src={
                        monWeekState.currMonWeek ===
                        monWeekState.selectedMonWeek
                          ? blueArrL
                          : blueArrL
                      }
                      alt="Left"
                      className={`leftMonWeek ${
                        !memoVals.mob ? "leftMonWeekDesktop" : ""
                      } ${
                        monWeekState.selectedMonWeek === weekStarts[0]
                          ? "fadeSelMonWeekBtn"
                          : ""
                      }  ${
                        monWeekState.currMonWeek !==
                        monWeekState.selectedMonWeek
                          ? "nonCurrColours"
                          : ""
                      }`}
                      onClick={() => {
                        let currTs = dateStringer.createTimestampFromString(
                          monWeekState.selectedMonWeek
                        );
                        let dateObj = new Date(currTs);
                        dateObj.setDate(dateObj.getDate() - 7);
                        dateObj.setHours(0, 0, 0, 0);

                        let newMonWeek = dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        );

                        if (weekStarts.includes(newMonWeek)) {
                          console.log(
                            "--- setting new selectedMonWeek to: ",
                            newMonWeek
                          );
                          setMonWeekState({ selectedMonWeek: newMonWeek });
                        }
                      }}
                    />
                    <select
                      className={`monWeeksDropdown x991199 ${
                        monWeekState.currMonWeek ===
                        monWeekState.selectedMonWeek
                          ? "currMonWeekBgDrop"
                          : ""
                      }`}
                      value={monWeekState.selectedMonWeek}
                      onChange={(e) => {
                        setMonWeekState({ selectedMonWeek: e.target.value });
                        //   setLoading(true);
                      }}
                    >
                      {generateMonWeeks}
                    </select>{" "}
                    <img
                      src={
                        monWeekState.currMonWeek ===
                        monWeekState.selectedMonWeek
                          ? blueArr
                          : blueArr
                      }
                      alt="Right"
                      className={`rightMonWeek ${
                        !memoVals.mob ? "rightMonWeekDesktop" : ""
                      } ${
                        monWeekState.selectedMonWeek ===
                        weekStarts[weekStarts.length - 1]
                          ? "fadeSelMonWeekBtn"
                          : ""
                      } ${
                        monWeekState.currMonWeek !==
                        monWeekState.selectedMonWeek
                          ? "nonCurrColours"
                          : ""
                      }`}
                      onClick={() => {
                        let currTs = dateStringer.createTimestampFromString(
                          monWeekState.selectedMonWeek
                        );
                        let dateObj = new Date(currTs);
                        dateObj.setDate(dateObj.getDate() + 7);
                        dateObj.setHours(0, 0, 0, 0);

                        let newMonWeek = dateStringer.createStringFromTimestamp(
                          dateObj.getTime()
                        );

                        if (weekStarts.includes(newMonWeek)) {
                          console.log(
                            "--- setting new selectedMonWeek to: ",
                            newMonWeek
                          );
                          setMonWeekState({ selectedMonWeek: newMonWeek });
                          // setLoading(true);
                        }
                      }}
                    />
                  </div>{" "}
                </div>
              ) : (
                <div className="availSelectRow topAvailSelectRow">
                  <p className="selectDateRange">Day</p>
                  <div className="teamAvailDayDiv">
                    <img
                      src={todayDs === state.ds ? blueArrL : left}
                      alt="Left"
                      className={`leftMonWeek teamAvailLeftRightBtn`}
                      onClick={() => {
                        let dateObj = new Date(
                          dateStringer.createTimestampFromString(state.ds)
                        );

                        dateObj.setDate(dateObj.getDate() - 1);
                        setDateInput(
                          dateStringer.createDateInputFromDateString(
                            dateStringer.createStringFromTimestamp(
                              dateObj.getTime()
                            )
                          )
                        );
                        setState({
                          ds: dateStringer.createStringFromTimestamp(
                            dateObj.getTime()
                          ),
                          dateInput: dateStringer.createDateInputFromDateString(
                            state.ds
                          ),
                        });
                      }}
                    />

                    <input
                      type="date"
                      className="addShiftDateInput editShiftDateInputBox"
                      id="addShiftDateInput"
                      value={dateInput}
                      onChange={(e) => {
                        let ds = dateStringer.createStringFromDateInput(
                          e.target.value
                        );

                        setDateInput(e.target.value);
                        if (
                          ds &&
                          ds[0] === "Y" &&
                          ds[1] === "2" &&
                          ds[2] === "0"
                        ) {
                          setState({ dateInput: e.target.value, ds: ds });
                        }
                      }}
                    ></input>

                    <img
                      src={state.ds === todayDs ? blueArr : right}
                      alt="Right"
                      className={`rightMonWeek teamAvailLeftRightBtn`}
                      onClick={() => {
                        let dateObj = new Date(
                          dateStringer.createTimestampFromString(state.ds)
                        );

                        dateObj.setDate(dateObj.getDate() + 1);
                        setDateInput(
                          dateStringer.createDateInputFromDateString(
                            dateStringer.createStringFromTimestamp(
                              dateObj.getTime()
                            )
                          )
                        );
                        setState({
                          ds: dateStringer.createStringFromTimestamp(
                            dateObj.getTime()
                          ),
                          dateInput: dateStringer.createDateInputFromDateString(
                            state.ds
                          ),
                        });
                      }}
                    />
                  </div>{" "}
                </div>
              )}
              <div
                className={`availSelectRow availSelectRowTeam ${
                  !monWeekMode && !memoVals.mobModal
                    ? "availSelectRowTeamDay"
                    : "dayViewAvailModalBox"
                } noBorder`}
              >
                <p className="selectDateRange selectDateRangeTeam selectDateRangeMgrAvail">
                  Team
                </p>
                <select
                  value={state.teamID}
                  className="availTeamDropdown"
                  onChange={(e) => {
                    //   if (monWeekMode) {
                    //     setMonWeekState({ teamID: e.target.value });
                    //   } else {
                    setState({ teamID: e.target.value });
                    //   }
                  }}
                >
                  {generateTeams}
                </select>
              </div>
            </div>
            {loading ? (
              <img
                src={horizontalBalls}
                className={`${loading ? "loadingReqCalTeamPreview" : "none"}`}
                alt="Loading"
              />
            ) : (
              <div
                className={`mgrAvailUserDiv ${
                  memoVals.mob || memoVals.mobModal ? "mgrAvailUserDivMob" : ""
                } ${
                  (memoVals.mob && !memoVals.mobModal) ||
                  (!memoVals.mobModal && window.innerWidth < 1170)
                    ? "x283874821"
                    : ""
                }`}
              >
                {monWeekMode ? (
                  <div className="usersMonWeekAvailContainer">
                    <div className="usersMonWeeksUserCol">
                      {/* <div className="teamAvailNameRowBlankTitle"></div> */}
                      {generateMonWeekNames}
                    </div>
                    <div className="usersMonWeeksDataCol">
                      {generateUsersMonWeek}
                    </div>
                  </div>
                ) : (
                  generateUsers
                )}
              </div>
            )}
          </div>
          <div className="mgrAvailFooter">
            <div className="mgrAvailFooterViewModeDiv">
              {/* <p
                className={`mgrAvailDayModeBtn ${
                  !monWeekMode ? "dayMonWeekSelectedMode" : ""
                }`}
                onClick={() => {
                  setMonWeekMode(false);
                }}
              >
                Day view
              </p> */}
              <div
                className="monWeekAvailRowRequestBtn reqTeamAvailBtnTxt"
                onClick={() => {
                  setShowRequestAllModal(true);
                }}
              >
                {" "}
                <img
                  src={bell2}
                  className="reqAvailBenIcon x310852"
                  alt="Request"
                />
                Request availability
              </div>
              {showRequestAllModal ? (
                <div
                  className="addAbsenceModalUnderlay"
                  onClick={() => {
                    setShowRequestAllModal(false);
                  }}
                >
                  {" "}
                  <div
                    className="formCreatedModal"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <p className="overlapsRenTxt">
                      Send an email to everyone in {getTeamName(state.teamID)}{" "}
                      requesting their availability for the week commencing{" "}
                      {dateStringer.printedDateWithYrFromDs(
                        monWeekState.selectedMonWeek
                      )}
                      ?
                    </p>
                    <div className="areYouModalBtnsDiv">
                      <p
                        className="areYouSureModalYesBtn"
                        onClick={() => {
                          let nowDs = dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          );
                          let newArr = [];
                          usersArr.map((obj) => {
                            obj.requested = nowDs;

                            newArr.push(obj);
                          });
                          setShowRequestAllModal(false);

                          setUsersArr(newArr);
                          let uids = [];
                          newArr.forEach((u) => {
                            uids.push(u.userID);
                          });

                          // axios
                          axios
                            .post(
                              `${serverURL}/request-availability`,
                              {
                                teamID: state.teamID,
                                ds:
                                  monWeekState.selectedMonWeek ||
                                  dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                userID: "",
                                userIDarr: uids,
                                nowDs: nowDs,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message:
                                      "Availability requested from whole team",
                                    colour: "blue",
                                    duration: 4000,
                                  };
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Email team{" "}
                      </p>
                      <p
                        className="areYouModalNoBtn"
                        onClick={() => {
                          setShowRequestAllModal(false);
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* <p
                className={`mgrAvailDayModeBtn ${
                  monWeekMode ? "dayMonWeekSelectedMode" : ""
                }`}
                onClick={() => {
                  // check mon week of state.ds exists in the weekStarts array. If it doesn't, set monWeekState.selectedMonWeek to current mon week
                  //   let monWeekOfSelected = dateStringer.getMonWeekOfDs(state.ds);
                  setMonWeekMode(!monWeekMode);

                  //   if (!weekStarts.includes(monWeekOfSelected)) {
                  //     console.log("week starts do not include current monweek", {
                  //       monWeekOfSelected,
                  //       curr: monWeekState.currMonWeek,
                  //     });
                  //     // let nowDs = `${dateStringer.createStringFromTimestamp(
                  //     //   new Date().getTime()
                  //     // )}H00M00`;
                  //     // let nowMonWeek = dateStringer.getMonWeekOfDs(nowDs);

                  //     setMonWeekState({
                  //       selectedMonWeek: monWeekState.currMonWeek,
                  //     });
                  //   }
                }}
              >
                {" "}
                <img
                  src={!monWeekMode ? checkboxInstall2 : checkboxInstall1}
                  alt="Month view"
                  className="monthViewAvailImg"
                />
                Month
              </p> */}
            </div>
            <p
              className="closeMgrAvailReqBtn"
              onClick={() => {
                memoVals.setShowMgrAvailModal((x) => false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default MgrAvailModal;
