import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import serverURL from "../../../serverURL";
import websiteURL from "../../../websiteURL";

import dateStringer from "../../../tools/dateStringer";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import plus from "../../../img/general/plus.svg";
import whitecross from "../../../img/general/whitecross.svg";
import unlinked from "../../../img/general/unlink.svg";
import checkbox1 from "../../../img/general/checkboxInstall1.svg";
import checkbox2 from "../../../img/general/checkboxInstall2purp.svg";
import noShiftScheduled from "../../../img/general/noShiftScheduled.svg";
import filterWhite from "../../../img/general/filterWhite.svg";
import popup from "../../../img/general/popup.svg";
import clockcard from "../../../img/general/clockcard.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import teamIcon from "../../../img/general/teamIcon.svg";

import "../../../styles/calendar.css";

import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";
import ClockOns from "./ClockOns";
import TeamHoursDataBox from "./TeamHoursDataBox";
import { data } from "jquery";

const TeamClockOns = ({ sideBoxData, my, setMy }) => {
  const { setShiftsPage } = useContext(CalendarContext);
  const { setCalPage } = useContext(CalendarContext);
  const {
    setPage,
    availableMonths,
    device,
    setClockOnID,
    selectedShiftTeam,
    setSelectedShiftTeam,
    reloadTeamClockOns,
    changedClockCard,
    setIndicate,
    availableTeamsForShifts,
  } = useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShiftsPage, //
      setCalPage, //
      setPage, //
      availableMonths, //
      device, //
      setClockOnID, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      reloadTeamClockOns, //
      changedClockCard, //
      setIndicate, //
      availableTeamsForShifts, //
      mob, //
      full, //
      fullButNarrow, //
    }),
    [
      setShiftsPage, //
      setCalPage, //
      setPage, //
      availableMonths, //
      device, //
      setClockOnID, //
      selectedShiftTeam, //
      setSelectedShiftTeam, //
      reloadTeamClockOns, //
      changedClockCard, //
      setIndicate, //
      availableTeamsForShifts, //
      mob, //
      full, //
      fullButNarrow, //
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}

      {/* Additional images */}
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plus}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unlinked}
        alt="Unlinked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2}
        alt="Checkbox Install 2 Purple"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noShiftScheduled}
        alt="No Shift Scheduled"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterWhite}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={clockcard}
        alt="Clock Card"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 11) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [showUnlinkedInfoModal, setShowUnlinkedInfoModal] = useState(false);

  useEffect(() => {
    // clean not needed
    setSelectedNameUserID("allNames"); // egypt
  }, [memoVals.selectedShiftTeam]);

  let [selectedNameUserID, setSelectedNameUserID] = useState("allNames");
  let [nameObjects, setNameObjects] = useState([]);
  let [selectedDay, setSelectedDay] = useState("allDays");
  let [reverse, setReverse] = useState(false);
  let [clockOns, setClockOns] = useState([]);

  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);

  let [days, setDays] = useState([]);

  let [showFilter, setShowFilter] = useState(false);

  let [awaitingClockOnIDs, setAwaitingClockOnIDs] = useState([]);
  let [awaitingClocks, setAwaitingClocks] = useState([]);

  let [showUnlinked, setShowUnlinked] = useState(false);
  let [unlinkedExist, setUnlinkedExist] = useState(0);

  let [notYetClockedOff, setNotYetClockedOff] = useState([]);
  let [approveLoading, setApproveLoading] = useState(false);

  useEffect(() => {
    // clean not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.clockCard) {
      // open clock card here
      memoVals.setClockOnID((x) => {
        return {
          clockOnID: params.clockCard,
          isMgr: true,
        };
      });
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let clocksScrollBox = useRef();
  useEffect(() => {
    // clean not needed

    clocksScrollBox.current.scrollTop = 0;
  }, [memoVals.selectedShiftTeam]);

  useEffect(() => {
    // clean not needed

    let count = 0;
    clockOns.forEach((c) => {
      if (c.unlinked) {
        count++;
      }
    });
    setUnlinkedExist(count);
  }, [clockOns]);

  let [currMy, setCurrMy] = useState("");

  useEffect(() => {
    // clean not needed

    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);
    setCurrMy(`${monthNum}${yearNum}`);
  }, []);

  let [filteredUserHasOutstanding, setFilteredUserHasOutstanding] =
    useState(false);
  useEffect(() => {
    // clean not needed

    setSelectedDay("allDays");
    setSelectedNameUserID("allNames");
  }, [my]);

  let [reload, setReload] = useState(false);

  useEffect(() => {
    // clean not needed

    if (memoVals.changedClockCard) {
      console.log("memoVals.changedClockCard: ", memoVals.changedClockCard);
      let liveArr = [];
      clockOns.forEach((c) => {
        if (c.clockOnID === memoVals.changedClockCard.clockOnID) {
          if (
            !memoVals.changedClockCard.deleting &&
            !memoVals.changedClockCard.justApproved
          ) {
            liveArr.push(memoVals.changedClockCard);
          }
        } else {
          liveArr.push(c);
        }
      });

      let awaitingArr = [];

      awaitingClocks.forEach((c) => {
        if (c.clockOnID === memoVals.changedClockCard.clockOnID) {
          if (memoVals.changedClockCard.justApproved) {
            let newArr = [];
            awaitingClockOnIDs.forEach((id) => {
              if (memoVals.changedClockCard.clockOnID !== id) {
                newArr.push(id);
              }
            });

            setAwaitingClockOnIDs(newArr);
            liveArr.push(memoVals.changedClockCard);
          } else {
            if (!memoVals.changedClockCard.deleting) {
              awaitingArr.push(memoVals.changedClockCard);
            }
          }
        } else {
          awaitingArr.push(c);
        }
      });

      if (memoVals.changedClockCard.justCreated) {
        console.log("clock card just created: ", memoVals.changedClockCard);
        if (memoVals.changedClockCard.approved) {
          liveArr.push(memoVals.changedClockCard);
        } else {
          awaitingArr.push(memoVals.changedClockCard);
        }
      }

      console.log(liveArr);
      setClockOns(liveArr);
      setAwaitingClocks(awaitingArr);
    }
  }, [memoVals.changedClockCard]);

  useEffect(() => {
    clockOns.forEach((c) => {
      if (c.userID === selectedNameUserID && c.approved === null) {
        setFilteredUserHasOutstanding(true);
      }
    });
  }, [selectedNameUserID]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (my) {
      setLoading(true);
      axios
        .post(
          `${serverURL}/get-mgr-clock-ons`,
          {
            my: my,
            teamID: memoVals.selectedShiftTeam || "allStaff",
            awaitingDecision: false,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.message === "success") {
            setReverse(response.data.reverse);
            let names = [];
            let userIDs = [];
            let dayNums = [];
            if (response.data.clockOns && response.data.clockOns[0]) {
              let unlinkedCounter = 0;
              response.data.clockOns.forEach((c) => {
                if (c.unlinked) {
                  unlinkedCounter++;
                }
                if (c.startDs && c.userID) {
                  if (!userIDs.includes(c.userID)) {
                    console.log({
                      fName: c.fName,
                      lName: c.lName,
                      userID: c.userID,
                    });
                    names.push({
                      fName: c.fName,
                      lName: c.lName,
                      userID: c.userID,
                    });
                    userIDs.push(c.userID);
                  }
                }

                let ds = c.startDs;

                if (
                  ds &&
                  ds[0] === "Y" &&
                  !dayNums.includes(parseInt(`${ds.substr(9, 2)}`))
                ) {
                  dayNums.push(parseInt(`${ds.substr(9, 2)}`));
                }
              });

              setUnlinkedExist(unlinkedCounter);

              dayNums.sort(function (a, b) {
                return a - b;
              });
              setDays(dayNums);
              console.log("names: ", names);
              setNameObjects(names);
              setClockOns(response.data.clockOns);
            }
            axios
              .post(
                `${serverURL}/get-mgr-clock-ons`,
                {
                  my: my,
                  teamID: memoVals.selectedShiftTeam || "allStaff",
                  awaitingDecision: true,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response1) => {
                if (response1.data.message === "success") {
                  setAwaitingClockOnIDs(response1.data.clocksAwaitingDecision);
                  setAwaitingClocks(response1.data.clockOns);
                  setNotYetClockedOff(response1.data.notYetClockedOff);
                }
                if (dataLoaded) {
                  setDataLoaded(false);
                }
                setDataLoaded(true);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.selectedShiftTeam, my, reload, memoVals.reloadTeamClockOns]);

  clockOns.sort(function (a, b) {
    if (!reverse) {
      return b.clockOnTs - a.clockOnTs;
    } else {
      return a.clockOnTs - b.clockOnTs;
    }
  });

  awaitingClocks.sort(function (a, b) {
    if (!reverse) {
      return b.clockOnTs - a.clockOnTs;
    } else {
      return a.clockOnTs - b.clockOnTs;
    }
  });

  let generateMonthOptions = useMemo(() => {
    return memoVals.availableMonths.map((month) => {
      if (dateStringer.getTsFromMy(month) <= new Date().getTime()) {
        let formatMonth = "January";
        let formatYear = month.substr(2, 2);
        if (month.substr(0, 2) === "00") {
          formatMonth = "January";
        }
        if (month.substr(0, 2) === "01") {
          formatMonth = "February";
        }
        if (month.substr(0, 2) === "02") {
          formatMonth = "March";
        }
        if (month.substr(0, 2) === "03") {
          formatMonth = "April";
        }
        if (month.substr(0, 2) === "04") {
          formatMonth = "May";
        }
        if (month.substr(0, 2) === "05") {
          formatMonth = "June";
        }
        if (month.substr(0, 2) === "06") {
          formatMonth = "July";
        }
        if (month.substr(0, 2) === "07") {
          formatMonth = "August";
        }
        if (month.substr(0, 2) === "08") {
          formatMonth = "September";
        }
        if (month.substr(0, 2) === "09") {
          formatMonth = "October";
        }
        if (month.substr(0, 2) === "10") {
          formatMonth = "November";
        }
        if (month.substr(0, 2) === "11") {
          formatMonth = "December";
        }

        return (
          <option key={month} value={month}>
            {formatMonth} {formatYear}
          </option>
        );
      }
    });
  }, [
    memoVals.availableMonths,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    memoVals.selectedShiftTeam,
  ]);

  let generateDayOptions = useMemo(() => {
    return days.map((day) => {
      let nth = "th";
      if (day === 2 || day === 22) {
        nth = "nd";
      }
      if (day === 3 || day === 23) {
        nth = "rd";
      }
      if (day === 1 || day === 21 || day === 31) {
        nth = "st";
      }
      return (
        <option key={Math.random()} value={day}>
          {day}
          {nth}
        </option>
      );
    });
  }, [
    days,
    ,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    memoVals.selectedShiftTeam,
  ]);

  // set calendarSubBar:
  useEffect(() => {
    // clean not needed
    document.title = "Team clock cards - FlowRota";

    memoVals.setCalPage((x) => "team-shifts");
    memoVals.setCalPage((x) => "team-clock-ons");
    memoVals.setShiftsPage((x) => "team-clock-ons");
    memoVals.setPage((x) => "team-clock-ons");
  }, []);
  // shiftsPage, setShiftsPage:
  // shifts, stats, availability, team-shifts

  let [selectedClock, setSelectedClock] = useState("");

  let shortenTeamName = (val, limit) => {
    if (val.length > limit) {
      return `${val.substr(0, limit - 2)}...`;
    } else {
      return val;
    }
  };

  let generateClocksAwaiting = useMemo(() => {
    // if (!showUnlinked) {
    return awaitingClocks.map((clock, i) => {
      let dateObj = new Date(
        dateStringer.createTimestampFromString(clock.startDs)
      );
      let dateN = dateObj.getDate();
      if (
        (!showUnlinked || (showUnlinked && clock.unlinked)) &&
        (clock.userID === selectedNameUserID ||
          selectedNameUserID === "allNames") &&
        (selectedDay === "allDays" || parseInt(selectedDay) === dateN) &&
        ((showShifts && clock.type === "shift") ||
          (showTils && clock.type === "til") ||
          (showOvertimes && clock.type === "overtime")) &&
        (memoVals.selectedShiftTeam === "allStaff" ||
          memoVals.selectedShiftTeam === clock.teamID)
      ) {
        if (clock.startDs && clock.endDs) {
          let calcClockedMins = (ds1, ds2, brkMins) => {
            if (ds1 && ds2) {
              let ds1Ts = dateStringer.createTimestampFromString(ds1);
              let ds2Ts = dateStringer.createTimestampFromString(ds2);

              let msDiff = ds2Ts - ds1Ts;

              let brkMs = brkMins * 60 * 1000;
              let totMs = msDiff - brkMs;

              return totMs / 1000;
            }
          };

          let clockMy = `${clock.startDs.substr(6, 2)}${clock.startDs.substr(
            3,
            2
          )}`;

          return (
            <div
              className={`${
                !memoVals.mob ||
                selectedClock === clock.clockOnID ||
                2 + 2 === 4
                  ? "mgrClockUnitWrapperOpen"
                  : "mgrClockUnitWrapperClosed"
              } ${clock.oneOfTwo === "two" ? "x3848848482" : ""} ${
                selectedClock === clock.clockOnID ||
                (2 + 2 === 4 && memoVals.mob && i === 0)
                  ? "pullFirstMobClockUnitUp"
                  : ""
              } animateZoomIn_ ${
                approveLoading === clock.clockOnID ? "zoomClockCardOutUnit" : ""
              }`}
              // onClick={() => {
              //   console.log(clock);
              // }}
            >
              {clock.oneOfTwo === "one" ? (
                ""
              ) : (
                <div className="clockNameAndTeamDiv">
                  <div className="mgrClockNameDiv pendClockName">
                    <img
                      src={clock.picUrl || profilePicturePlaceholder}
                      alt={clock.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {clock.fName} {clock.lName}
                  </div>

                  {/* <div className="mgrClockNameDiv clockTeamNameTopRight">
                    {memoVals.mob
                      ? shortenTeamName(clock.teamName, 10)
                      : shortenTeamName(clock.teamName, 16)}
                  </div> */}
                </div>
              )}
              <div
                className={`mgrClockUnit mgrClockUnitPending_ pend ${
                  !memoVals.mob ||
                  selectedClock === clock.clockOnID ||
                  2 + 2 === 4
                    ? "noBorderRadiusBottomRight"
                    : ""
                } leftReqItemPendingBorder_ mgrReqItemPendingBorderMgr_ ${
                  memoVals.mob ? "borderRadius10" : ""
                }`}
                key={clock.clockOnID}
                onClick={() => {
                  memoVals.setClockOnID((x) => {
                    return {
                      clockOnID: clock.clockOnID,
                      isMgr: true,
                    };
                  });
                }}
              >
                {!clock.approved && !clock.declined ? (
                  <div className="unsubmittedTsBlob clockAwaitBlob"></div>
                ) : (
                  ""
                )}
                <div className="teamClockLeftParent">
                  <div className="tilShiftSection0">
                    {!clock.unlinked && (
                      <div
                        className={`${
                          clock.approved
                            ? "myShiftWorkedDot"
                            : "myShiftUnWorkedDot"
                        } ${clock.declined ? "myShiftWorkedDotAbsent" : ""}`}
                      ></div>
                    )}
                    {clock.unlinked && <p className="unlinkedExlaim">!</p>}
                  </div>
                  <div className="tilShiftSection1Clock pendingClockSection1">
                    <p className={`tilShiftDs flexStart`}>
                      {dateStringer.tsToDayAndDate(
                        dateStringer.createTimestampFromString(clock.startDs)
                      )}
                      <span className="ndStRdSpan3">
                        {dateStringer.nd(clock.startDs.substr(9, 2))}
                      </span>

                      {/* {outsideOfMy && ( */}
                      <span className="outsideOfMyMonthSpan">
                        {dateStringer.getMonthThreeLettersFromDs(clock.startDs)}
                      </span>
                      {/* )} */}
                      <span className="shiftTilNameTxt">
                        {clock.type === "shift"
                          ? "Shift"
                          : `${
                              clock.type === "overtime"
                                ? "Overtime"
                                : `Time in lieu`
                            }`}
                      </span>
                    </p>
                    <div className="timesAndTagContainer">
                      <div
                        className={`clockOnOffTimesFlex ${
                          !clock.approved ? "x3832874827" : ""
                        }`}
                      >
                        {" "}
                        <div className="clockOnOffSection">
                          <p className="clockOnSpan">On: </p>
                          <p className="clockOnValTxt">{`${clock.startDs.substr(
                            12,
                            2
                          )}:${clock.startDs.substr(15, 2)}`}</p>
                        </div>
                        <div className="clockOnOffSection clockOnOffSection2">
                          <p className="clockOnSpan">Off: </p>
                          <p className="clockOnValTxt">{`${clock.endDs.substr(
                            12,
                            2
                          )}:${clock.endDs.substr(15, 2)}`}</p>
                        </div>
                        <div className="clockOnOffSection clockOnOffSection3">
                          <p className="clockOnSpan">Break: </p>
                          <p className="clockOnValTxt">
                            {clock.totBrkMins === 60 ||
                            clock.totBrkMins === 120 ||
                            clock.totBrkMins === 180 ||
                            clock.totBrkMins === 240
                              ? dateStringer.formatMinsDurationToHours(
                                  clock.totBrkMins,
                                  false,
                                  true
                                )
                              : `${clock.totBrkMins}m`}
                          </p>
                        </div>
                        {/* &nbsp;&nbsp;&nbsp;
                      <span className="clockOnSpan">Off: </span>
                      {clock.endDs.substr(12, 2)}:{clock.endDs.substr(15, 2)} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teamClockRightParent">
                  <div className="tilShiftSection2 pendingClockSection2">
                    <p className="tilShiftTypeTxt">
                      {dateStringer.formatMinsDurationToHours(
                        calcClockedMins(
                          clock.startDs,
                          clock.endDs,
                          clock.totBrkMins
                        ) / 60,
                        false,
                        true
                      )}
                    </p>
                    <p className={`teamClockBreakDurStrip`}>
                      {dateStringer.shorten(
                        clock.teamName,
                        memoVals.mob || memoVals.fullButNarrow ? 17 : 35
                      )}{" "}
                      <img
                        src={teamIcon}
                        alt="Team"
                        className="teamIconHours"
                      />
                    </p>
                  </div>

                  <div className="itemTongue clockTongue">
                    <p className="tongueTxt clockTongueTxt">Clock card</p>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  !memoVals.mob ||
                  selectedClock === clock.clockOnID ||
                  2 + 2 === 4
                    ? `mgrClockSubBar ${
                        memoVals.mob ? "mgrClockSubBarMob" : ""
                      }`
                    : "none"
                } pendClockSub`}
              >
                <div
                  className={`clocksSchedSubLeft ${
                    memoVals.mob ? "font12px" : ""
                  } ${clock.unlinked ? "cursorP" : ""}`}
                  onClick={() => {
                    if (clock.unlinked) {
                      setShowUnlinkedInfoModal(true);
                    }
                  }}
                >
                  <div
                    className={`clockSchedTitle ${
                      memoVals.mob && clock.createdByClock
                        ? "originallyClockedMobFont"
                        : ""
                    } ${clock.createdByClock ? "createdByDynamic" : ""}  ${
                      clock.unlinked || clock.scheduledRemoved ? "ba0000" : ""
                    }`}
                  >
                    <img
                      src={unlinked}
                      className={`${
                        clock.unlinked ? "unlinkedClockImg" : "none"
                      }`}
                      alt="Unlinked"
                    />
                    <img
                      src={clock.oneOfTwo === "two" ? plus : noShiftScheduled}
                      className={`${
                        clock.unlinked || clock.scheduled
                          ? "none"
                          : "noShiftSchedImg"
                      } ${clock.oneOfTwo === "two" ? "x49578274" : ""}`}
                      alt="No shift scheduled"
                    />
                    {clock.scheduled
                      ? `${
                          clock.createdByClock
                            ? "Originally clocked"
                            : "Scheduled"
                        }`
                      : `${
                          clock.unlinked || clock.scheduledRemoved
                            ? clock.unlinked
                              ? "Unlinked shift"
                              : "Scheduled shift removed"
                            : clock.oneOfTwo === "two"
                            ? "Clocked overtime"
                            : "No shift scheduled"
                        }`}
                    {clock.unlinked ? (
                      <p className="unlinkedExplainBtn">?</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <p
                    className={`${
                      clock.scheduled ? "clockSchedValue" : "none"
                    }`}
                  >
                    {dateStringer.dsToTimeStrip(clock.scheduledStart, true)} -{" "}
                    {dateStringer.dsToTimeStrip(clock.scheduledEnd, true)}{" "}
                    {/* <span className="clockBrkSpan">/ 60m </span> */}
                  </p>
                </div>
                <div
                  className={`clocksSchedSubRight ${
                    approveLoading === clock.clockOnID
                      ? "clocksLoadingApprove"
                      : ""
                  } ${memoVals.mob ? "flexEnd" : ""}`}
                >
                  {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                  <p
                    className="approveClock"
                    onClick={() => {
                      if (!clock.approved && !clock.declined) {
                        setApproveLoading(clock.clockOnID);
                        // setApproveLoading(false);
                        axios
                          .post(
                            `${serverURL}/approve-clock-card`,
                            {
                              clockOnID: clock.clockOnID,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              if (clock.unlinked) {
                                // remove from unlinked count
                                setUnlinkedExist(unlinkedExist - 1);
                              }

                              let awaitArr = [];
                              let liveArr = clockOns.filter((item) => {
                                return item.clockOnID !== clock.clockOnID;
                              });
                              awaitingClocks.forEach((c) => {
                                if (c.clockOnID !== clock.clockOnID) {
                                  awaitArr.push(c);
                                }

                                if (c.clockOnID === clock.clockOnID) {
                                  clock.approved = true;
                                  clock.declined = false;

                                  clock.order = 99999999;

                                  console.log("NEWLY APPROVED CLOCK: ", clock);

                                  liveArr.push(clock);
                                }
                              });

                              // remove clockOnID from awaitingClockOnIDs
                              let newArr = [];
                              awaitingClockOnIDs.forEach((id) => {
                                if (id !== clock.clockOnID) {
                                  newArr.push(id);
                                }
                              });
                              console.log("liveArr: ", liveArr);
                              setAwaitingClockOnIDs(newArr);

                              setAwaitingClocks(awaitArr);

                              setClockOns(liveArr);
                              // setTimeout(() => {
                              // setApproveLoading("");
                              // }, 200);
                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: `${dateStringer.possession(
                                    clock.fName
                                  )} clock card approved`,
                                  colour: "blue",
                                  duration: 4000,
                                };
                              });
                              // setReload(!reload);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Approve
                  </p>
                  {/* )} */}
                  <p
                    className={`${
                      clock.approved ? "clockIsApproved" : "none"
                    } ${
                      memoVals.mob ? "font12px mobClockStatusBringLeft" : ""
                    }`}
                  >
                    Approved
                  </p>

                  <p
                    className={`openClock ${memoVals.mob ? "none" : ""}`}
                    onClick={() => {
                      memoVals.setClockOnID((x) => {
                        return {
                          clockOnID: clock.clockOnID,
                          isMgr: true,
                        };
                      });
                    }}
                  >
                    Open
                  </p>
                </div>
              </div>
              {clock.oneOfTwo === "two" ? (
                <div className="x485487597252"></div>
              ) : (
                ""
              )}{" "}
            </div>
          );
        }
      }
    });
    // }
  }, [
    awaitingClocks,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    selectedNameUserID,
    memoVals.selectedShiftTeam,
    selectedClock,
    reverse,
    approveLoading,
  ]);

  let generateClockOns = useMemo(() => {
    return clockOns.map((clock, i) => {
      if (
        (showUnlinked && clock.unlinked) ||
        (!showUnlinked && (clock.approved || clock.declined))
      ) {
        if (!awaitingClockOnIDs.includes(clock.clockOnID)) {
          let dateObj = new Date(
            dateStringer.createTimestampFromString(clock.startDs)
          );
          let dateN = dateObj.getDate();
          if (
            (clock.userID === selectedNameUserID ||
              selectedNameUserID === "allNames") &&
            (selectedDay === "allDays" || parseInt(selectedDay) === dateN) &&
            ((showShifts && clock.type === "shift") ||
              (showTils && clock.type === "til") ||
              (showOvertimes && clock.type === "overtime")) &&
            (memoVals.selectedShiftTeam === "allStaff" ||
              memoVals.selectedShiftTeam === clock.teamID)
          ) {
            if (clock.startDs && clock.endDs) {
              let calcClockedMins = (ds1, ds2, brkMins) => {
                if (ds1 && ds2) {
                  let ds1Ts = dateStringer.createTimestampFromString(ds1);
                  let ds2Ts = dateStringer.createTimestampFromString(ds2);

                  let msDiff = ds2Ts - ds1Ts;

                  let brkMs = brkMins * 60 * 1000;
                  let totMs = msDiff - brkMs;

                  return totMs / 1000;
                }
              };
              // if (clock.zap) {
              //   return "HELLO THIS IS THE CLOCK";
              // } else
              return (
                <div
                  className={`${
                    !memoVals.mob ||
                    selectedClock === clock.clockOnID ||
                    2 + 2 === 4
                      ? "mgrClockUnitWrapperOpen"
                      : "mgrClockUnitWrapperClosed"
                  } ${clock.oneOfTwo === "two" ? "x3848848482" : ""} ${
                    selectedClock === clock.clockOnID ||
                    (2 + 2 === 4 && memoVals.mob && i === 0)
                      ? "pullFirstMobClockUnitUp"
                      : ""
                  }`}
                  onClick={() => {
                    // console.log(dateN);
                    console.log(clock);
                  }}
                >
                  {clock.oneOfTwo === "one" ? (
                    ""
                  ) : (
                    <div className="clockNameAndTeamDiv">
                      <div className="mgrClockNameDiv">
                        {clock.fName} {clock.lName}
                      </div>

                      {/* <div className="mgrClockNameDiv clockTeamNameTopRight">
                        {memoVals.mob
                          ? shortenTeamName(clock.teamName, 10)
                          : shortenTeamName(clock.teamName, 16)}
                      </div> */}
                    </div>
                  )}
                  <div
                    onClick={() => {
                      memoVals.setClockOnID((x) => {
                        return {
                          clockOnID: clock.clockOnID,
                          isMgr: true,
                        };
                      });
                    }}
                    className={`mgrClockUnit ${
                      !memoVals.mob ? "mgrClockUnitNonPending" : ""
                    } ${
                      !memoVals.mob ||
                      selectedClock === clock.clockOnID ||
                      2 + 2 === 4
                        ? "noBorderRadiusBottomRight"
                        : ""
                    } ${clock.approved ? "leftBorderGreen" : "leftBorderRed"} ${
                      clock.unlinked ? "unlinkedBorder" : ""
                    }`}
                    key={clock.clockOnID}
                  >
                    <div className="teamClockLeftParent">
                      <div className={`tilShiftSection0`}>
                        {/* {!clock.unlinked && (
                      <div
                        className={`${
                          clock.approved
                            ? "myShiftWorkedDot"
                            : "myShiftUnWorkedDot"
                        } ${clock.declined ? "myShiftWorkedDotAbsent" : ""}`}
                      ></div>
                    )} */}
                        {clock.unlinked ? (
                          <p className="unlinkedExlaim">!</p>
                        ) : (
                          <img
                            src={clockcard}
                            alt="Clock card"
                            className="teamClockCardIcon"
                          />
                        )}
                      </div>
                      <div
                        className={`reqColBarDiv reqColBarAdjust7 divPend ${
                          clock.approved
                            ? "divApp"
                            : clock.declined
                            ? "divDec"
                            : "divPend"
                        } ${clock.unlinked ? "noBorder" : ""}
                        `}
                      ></div>
                      <div
                        className={`teamClockSection1 ${
                          !memoVals.mob ? "desktopTeamClockSection1" : ""
                        } ${
                          !memoVals.mob && !clock.unlinked
                            ? "desktopUnlinkedTeamClockSection1"
                            : ""
                        } ${clock.declined ? "leftRedBorder_" : ""} `}
                      >
                        <p className={`tilShiftDs `}>
                          {dateStringer.tsToDayAndDate(
                            dateStringer.createTimestampFromString(
                              clock.startDs
                            ),
                            true
                          )}

                          <span className="shiftTilNameTxt">
                            {clock.type === "shift"
                              ? "Shift"
                              : `${
                                  clock.type === "overtime"
                                    ? "Overtime"
                                    : "Time in Lieu"
                                }`}
                          </span>
                        </p>
                        <div className="timesAndTagContainer">
                          <div
                            className={`clockOnOffTimesFlex ${
                              !clock.approved ? "x3832874827" : ""
                            }`}
                          >
                            <div className="clockOnOffSection">
                              <p className="clockOnSpan">On: </p>
                              <p className="clockOnValTxt">{`${clock.startDs.substr(
                                12,
                                2
                              )}:${clock.startDs.substr(15, 2)}`}</p>
                            </div>
                            <div className="clockOnOffSection clockOnOffSection2">
                              <p className="clockOnSpan">Off: </p>
                              <p className="clockOnValTxt">{`${clock.endDs.substr(
                                12,
                                2
                              )}:${clock.endDs.substr(15, 2)}`}</p>
                            </div>
                            <div className="clockOnOffSection clockOnOffSection3">
                              <p className="clockOnSpan">Break: </p>
                              <p className="clockOnValTxt">
                                {clock.totBrkMins === 60 ||
                                clock.totBrkMins === 120 ||
                                clock.totBrkMins === 180 ||
                                clock.totBrkMins === 240
                                  ? dateStringer.formatMinsDurationToHours(
                                      clock.totBrkMins,
                                      false,
                                      true
                                    )
                                  : `${clock.totBrkMins}m`}
                              </p>
                            </div>
                            {/* &nbsp;&nbsp;&nbsp;
                      <span className="clockOnSpan">Off: </span>
                      {clock.endDs.substr(12, 2)}:{clock.endDs.substr(15, 2)} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="teamClockRightParent">
                      <div
                        className={`teamClockSection2 ${
                          memoVals.mob ? "mobTeamClockSection2" : ""
                        }`}
                      >
                        <p className="tilShiftTypeTxt">
                          {dateStringer.formatMinsDurationToHours(
                            calcClockedMins(
                              clock.startDs,
                              clock.endDs,
                              clock.totBrkMins
                            ) / 60,
                            false,
                            true
                          )}
                        </p>
                        <p className={`teamClockBreakDurStrip`}>
                          {dateStringer.shorten(
                            clock.teamName,
                            memoVals.mob || memoVals.fullButNarrow ? 22 : 29
                          )}
                        </p>
                      </div>

                      {/* tongue here */}
                      <div className="itemTongue clockTongue">
                        <p className="tongueTxt clockTongueTxt">Clock card</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      !memoVals.mob ||
                      selectedClock === clock.clockOnID ||
                      2 + 2 === 4
                        ? `mgrClockSubBar ${
                            memoVals.mob ? "mgrClockSubBarMob" : ""
                          }`
                        : "none"
                    }`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      } ${clock.unlinked ? "cursorP" : ""}`}
                      onClick={() => {
                        if (clock.unlinked) {
                          setShowUnlinkedInfoModal(true);
                        }
                      }}
                    >
                      <div
                        className={`clockSchedTitle ${
                          memoVals.mob && clock.createdByClock
                            ? "originallyClockedMobFont"
                            : ""
                        } ${clock.createdByClock ? "createdByDynamic" : ""} ${
                          clock.unlinked || clock.scheduledRemoved
                            ? "ba0000"
                            : ""
                        }`}
                      >
                        <img
                          src={unlinked}
                          className={`${
                            clock.unlinked ? "unlinkedClockImg" : "none"
                          }`}
                          alt="Unlinked"
                        />
                        <img
                          src={
                            clock.oneOfTwo === "two" ? plus : noShiftScheduled
                          }
                          className={`${
                            clock.unlinked || clock.scheduled
                              ? "none"
                              : "noShiftSchedImg"
                          } ${clock.oneOfTwo === "two" ? "x49578274" : ""}`}
                          alt="No shift scheduled"
                        />
                        {clock.scheduled
                          ? `${
                              clock.createdByClock
                                ? "Originally clocked"
                                : "Scheduled"
                            }`
                          : `${
                              clock.unlinked || clock.scheduledRemoved
                                ? clock.unlinked
                                  ? "Unlinked shift"
                                  : "Scheduled shift removed"
                                : clock.oneOfTwo === "two"
                                ? "Clocked overtime"
                                : "No shift scheduled"
                            }`}{" "}
                        {clock.unlinked ? (
                          <p className="unlinkedExplainBtn">?</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <p
                        className={`${
                          clock.scheduled ? "clockSchedValue" : "none"
                        }`}
                      >
                        {dateStringer.dsToTimeStrip(clock.scheduledStart, true)}{" "}
                        - {dateStringer.dsToTimeStrip(clock.scheduledEnd, true)}{" "}
                        {/* <span className="clockBrkSpan">/ 60m </span> */}
                      </p>
                    </div>
                    <div
                      className={`clocksSchedSubRight ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      <p
                        className={`${
                          clock.approved ? "clockIsApproved" : "none"
                        } ${
                          memoVals.mob ? "font12px mobClockStatusBringLeft" : ""
                        }`}
                      >
                        Approved
                      </p>
                      <p
                        className={`${
                          clock.declined ? "clockIsDeclined" : "none"
                        } ${
                          memoVals.mob ? "font12px mobClockStatusBringLeft" : ""
                        }`}
                      >
                        Declined
                      </p>
                      <p
                        className={`openClock ${memoVals.mob ? "none" : ""}`}
                        onClick={() => {
                          memoVals.setClockOnID((x) => {
                            return {
                              clockOnID: clock.clockOnID,
                              isMgr: true,
                            };
                          });
                        }}
                      >
                        Open
                      </p>
                    </div>
                  </div>
                  {clock.oneOfTwo === "two" ? (
                    <div className="x485487597252"></div>
                  ) : (
                    ""
                  )}{" "}
                </div>
              );
            }
          }
        }
      }
    });
  }, [
    clockOns,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    memoVals.selectedShiftTeam,
    selectedNameUserID,
    selectedClock,
    reverse,
    memoVals.mob,
    memoVals.fullButNarrow,
  ]);

  let generateNameOptions = useMemo(() => {
    if (nameObjects) {
      return nameObjects.map((name) => {
        return (
          <option key={name.userID} value={name.userID}>
            {name.fName} {name.lName && name.lName[0]}
          </option>
        );
      });
    }
  }, [
    nameObjects,
    showUnlinked,
    showFilter,
    showOvertimes,
    showTils,
    showShifts,
    my,
    memoVals.selectedShiftTeam,
    selectedClock,
  ]);

  let generateTeamsSelectBar =
    memoVals.availableTeamsForShifts &&
    memoVals.availableTeamsForShifts.map((team) => {
      return (
        <option key={team.teamID} value={team.teamID}>
          {dateStringer.shorten(team.teamName, 22)}
        </option>
      );
    });

  let getTeamName = (teamID, teams) => {
    if (teamID) {
      if (teamID === "allStaff") {
        return "all staff you manage";
      } else {
        let teamFilt = teams.filter((item) => {
          return item.teamID === memoVals.selectedShiftTeam;
        });

        if (teamFilt[0]) {
          console.log("!!!", teamFilt[0]);
          return teamFilt[0].teamName;
        }
      }
    }
  };

  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      <ShiftsHeader my={my} setMy={setMy} />
      {/* <div className="adminCurverCropper bgColUpcoming absenceShoulder">
        <div className="adminCurve"></div>
      </div>{" "} */}
      <div className={`desktopMaster ${memoVals.mob ? "desktopPurpBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth mob100WitdthPlus2" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}  `}
        >
          <div
            className={`shiftsPageFilterBar ${
              memoVals.mob ? "userHoursShoulderBar" : ""
            } ${!memoVals.full ? "noBorder_" : ""} ${
              !memoVals.mob && "borderLeftGrey"
            } noBorderBottom ${
              !unlinkedExist && memoVals.mob
                ? "noUnlinkedMobClocksShoulderHeight"
                : ""
            }`}
          >
            {memoVals.mob ? (
              // "memoVals.mob clock cards shoulder"
              <div
                className={`staffClocksMobShoulder ${
                  !unlinkedExist ? "x109842082" : ""
                } `}
              >
                <div
                  className={`staffClocksMobShoulderRow ${
                    memoVals.mob ? "staffClocksMobShoulderRowUnlinked" : ""
                  }`}
                >
                  <select
                    className="shiftsTilTeamMonthDropdown"
                    value={my}
                    onChange={(e) => {
                      setLoading(true);
                      setMy(e.target.value);
                    }}
                  >
                    {generateMonthOptions}
                  </select>
                  <select
                    className={`shiftsHeaderTeamDropdown  ${
                      memoVals.selectedShiftTeam !== "allStaff"
                        ? `teamSelectedDrop ${
                            memoVals.mob ? "mobteamSelectedDrop" : ""
                          }`
                        : ""
                    }`}
                    value={memoVals.selectedShiftTeam}
                    onChange={(e) => {
                      // setLoadUser({});
                      memoVals.setSelectedShiftTeam((x) => e.target.value);
                      // memoVals.setSelectedShiftTeam(x =>{return{(e.target.value)});
                    }}
                  >
                    <option value="allStaff">-- All of your teams</option>
                    {generateTeamsSelectBar}
                  </select>
                </div>{" "}
                <div className="staffClocksMobShoulderRow2">
                  <select
                    className={`${
                      selectedNameUserID === "allNames"
                        ? "shiftNamesDropdown unselectedShiftOrTil2"
                        : "unselectedShiftOrTil2"
                    }`}
                    value={selectedNameUserID}
                    onChange={(e) => {
                      // setLoading(true);
                      // setUpdateShifts(!updateShifts);
                      setSelectedNameUserID(e.target.value);
                      // setSelectedChangeID("");
                    }}
                  >
                    <option key={Math.random()} value="allNames">
                      -- All of your staff
                    </option>
                    {generateNameOptions}
                  </select>

                  <div
                    className={`shiftsOrTilFilterTeam ${
                      showUnlinked ? "" : "unselectedShiftOrTil"
                    } unlinkedFiltBtnCorrection `}
                    onClick={() => {
                      if (!showUnlinked) {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Showing unlinked clock cards only",
                            colour: "orange",
                            duration: 4000,
                          };
                        });
                      } else {
                        memoVals.setIndicate((x) => {
                          return {
                            show: false,
                            message: "",
                            colour: "",
                            duration: 0,
                          };
                        });
                      }
                      setShowUnlinked(!showUnlinked);
                    }}
                  >
                    Unlinked{" "}
                    <span className="unlinkedQtySpan">{unlinkedExist}</span>
                    <img
                      src={showUnlinked ? checkbox2 : checkbox1}
                      alt="Select"
                      className="shiftTilSelectImg"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="desktopClockCardsShoulder">
                <div
                  className={`shiftsPageFilterBarLeft ${
                    memoVals.mob && showFilter ? "narrowerFilterLeft" : ""
                  }`}
                >
                  <select
                    className="shiftsTilMonthDropdown"
                    value={my}
                    onChange={(e) => {
                      setLoading(true);
                      if (showUnlinked) {
                        setShowUnlinked(false);
                      }
                      // setUpdateShifts(!updateShifts);
                      setMy(e.target.value);
                    }}
                  >
                    {generateMonthOptions}
                  </select>
                </div>{" "}
                <div className={`teamClockOnsFilterBarRight`}>
                  {showUnlinked || unlinkedExist > 0 ? (
                    <div
                      className={`shiftsOrTilFilterTeam ${
                        showUnlinked ? "" : "unselectedShiftOrTil"
                      } unlinkedFiltBtnCorrection`}
                      onClick={() => {
                        if (!showUnlinked) {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Showing unlinked clock cards only",
                              colour: "orange",
                              duration: 4000,
                            };
                          });
                        } else {
                          memoVals.setIndicate((x) => {
                            return {
                              show: false,
                              message: "",
                              colour: "",
                              duration: 0,
                            };
                          });
                        }
                        setShowUnlinked(!showUnlinked);
                      }}
                    >
                      Unlinked{" "}
                      <span className="unlinkedQtySpan">{unlinkedExist}</span>
                      <img
                        src={showUnlinked ? checkbox2 : checkbox1}
                        alt="Select"
                        className="shiftTilSelectImg"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {!memoVals.mob ? (
                    <div
                      className={`${
                        showFilter
                          ? "shiftTilFilterBtnActive"
                          : `${
                              selectedNameUserID !== "allNames"
                                ? "shiftTilFilterBtn marginRight0"
                                : "shiftTilFilterBtn marginRight0"
                            }`
                      } `}
                      onClick={() => {
                        if (showFilter) {
                          setSelectedNameUserID("allNames");
                          memoVals.setSelectedShiftTeam((x) => "allStaff");
                        }
                        setShowFilter(!showFilter);
                      }}
                    >
                      <img
                        src={filterWhite}
                        alt="Close filter"
                        className={`filtImg ${!showFilter ? "x19831" : ""}`}
                      />{" "}
                      <img
                        className={`${
                          showFilter ? "closeFilterImgTilShifts" : "none"
                        }`}
                        alt="close"
                        src={whitecross}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>

          {showFilter && !memoVals.mob ? (
            <div
              className={`nameAndDateDropdownsContainer paddingLeft5pc ${
                !memoVals.full ? "noBorder" : ""
              }`}
            >
              {" "}
              <select
                className={`shiftsHeaderTeamDropdown x10924014 ${
                  memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                } ${
                  memoVals.selectedShiftTeam !== "allStaff"
                    ? `teamSelectedDrop ${
                        memoVals.mob ? "mobteamSelectedDrop" : ""
                      }`
                    : ""
                }`}
                value={memoVals.selectedShiftTeam}
                onChange={(e) => {
                  // setLoadUser({});

                  memoVals.setSelectedShiftTeam((x) => e.target.value);
                }}
              >
                <option value="allStaff">-- All of your teams</option>
                {generateTeamsSelectBar}
              </select>
              <select
                className={`${
                  selectedNameUserID === "allNames"
                    ? "shiftNamesDropdown x10893011"
                    : "shiftNamesDropdown x10893011"
                } ${memoVals.fullButNarrow ? "x2038282" : ""}`}
                value={selectedNameUserID}
                onChange={(e) => {
                  // setLoading(true);
                  // setUpdateShifts(!updateShifts);
                  setSelectedNameUserID(e.target.value);
                  // setSelectedChangeID("");
                }}
              >
                <option key={Math.random()} value="allNames">
                  -- All staff
                </option>
                {generateNameOptions}
              </select>
            </div>
          ) : (
            ""
          )}

          <div
            className={`availabilityBodyContainer ${
              !memoVals.full ? "noBorder" : ""
            } ${!memoVals.mob ? "x19374872" : ""}`}
            onClick={() => {}}
          >
            {/* render here */}
            {!memoVals.mob ? (
              <div
                className={`filterSplitterContainer x8765433 ${
                  !memoVals.full || loading ? "noBorder" : "noBorder"
                }`}
              >
                <div className="filterSplitter"></div>
              </div>
            ) : (
              ""
            )}
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />

            <div
              ref={clocksScrollBox}
              className={`${
                !loading
                  ? `clockOnsContainer  ${
                      memoVals.mob && !unlinkedExist
                        ? "clockOnsContainerMobNoUnlinked"
                        : ""
                    } ${
                      memoVals.mob && unlinkedExist
                        ? "mobUnlinkedExistContainer"
                        : ""
                    } ${showFilter ? "clockOnsContainerWithFilt" : ""}`
                  : "none"
              } ${!memoVals.mob && !memoVals.full ? "noBorder_" : ""} ${
                memoVals.device.vhIssue ? "vhIssue" : ""
              } ${!memoVals.mob ? "x183989842" : ""}`}
            >
              {my === currMy ? (
                <p
                  className={`${
                    awaitingClocks.length > 0 && !showUnlinked
                      ? // &&
                        // filteredUserHasOutstanding
                        "clocksAwaitingTitles"
                      : "none"
                  } ${
                    selectedNameUserID !== "allNames" &&
                    !filteredUserHasOutstanding
                      ? "none"
                      : ""
                  }`}
                >
                  Outstanding
                </p>
              ) : (
                ""
              )}

              {showUnlinked && !loading ? (
                <p
                  className={`unlinkedClockCardsExplain ${
                    memoVals.mob ? "unlinkedClockCardsExplainMob" : ""
                  } ${!memoVals.mob && !memoVals.full ? "x983184" : ""}`}
                >
                  Unlinked clock cards are approved clock cards that are no
                  longer associated to a shift for which the employee is paid.
                  This could be because the shift that was created by the clock
                  card has since been moved or deleted.
                  <br />
                  <br />
                  You can re-link each clock card, which creates a new shift for
                  which the employee is paid.
                  <br />
                  <br />
                  Read more about this
                  <a
                    className="unlinkedSupportBtn"
                    href={`${websiteURL}/support/unlinked-clock-cards`}
                    target="_blank"
                  >
                    here <img src={popup} className="unlinkedPopup" />
                  </a>
                </p>
              ) : (
                ""
              )}
              {currMy === my ? generateClocksAwaiting : ""}

              <div
                className={`${
                  awaitingClocks.length > 0 && !showUnlinked && currMy === my
                    ? //  &&
                      // filteredUserHasOutstanding
                      "awaitingClockDivider"
                    : "none"
                } ${
                  selectedNameUserID !== "allNames" &&
                  !filteredUserHasOutstanding
                    ? "none"
                    : ""
                }`}
              ></div>

              {clockOns[0] || loading ? (
                generateClockOns
              ) : (
                <div
                  className={`noShiftsTxtContainer noUpcomingReqs ${
                    memoVals.mob ? "x1984971" : ""
                  } ${showUnlinked ? "none" : ""}`}
                >
                  <div className="noShiftsSideBar"></div>

                  <p
                    className={`noShiftsTxt x19873`}
                    onClick={() => {
                      console.log({ loading, clockOns });
                    }}
                  >
                    No clock cards in{" "}
                    {dateStringer.printMonthAndYearFromMy(my, true, true)}{" "}
                    amongst{" "}
                    {getTeamName(
                      memoVals.selectedShiftTeam,
                      memoVals.availableTeamsForShifts
                    )}{" "}
                  </p>

                  <div className="noShiftsSideBar"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            memoVals.mob || !memoVals.full ? "none" : "desktopMasterSecondary"
          }`}
        >
          <TeamHoursDataBox
            // sideBoxData={sideBoxData} my={my} setMy={setMy}

            setLoadUser={false}
            setShowRequests={false}
          />{" "}
        </div>
      </div>

      {showUnlinkedInfoModal ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setShowUnlinkedInfoModal(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              {" "}
              Unlinked clock cards are approved clock cards that are no longer
              associated to a shift for which the employee is paid. This could
              be because the shift that was created by the clock card has since
              been moved or deleted.
              <br />
              <br />
              You can re-link each clock card, which creates a new shift for
              which the employee is paid.
              <br />
              <br />
              Read more about this
              <a
                className="unlinkedPopUpLink"
                href={`${websiteURL}/support/unlinked-clock-cards`}
                target="_blank"
              >
                here <img src={popup} className="unlinkedPopup" />
              </a>
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowUnlinkedInfoModal(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default TeamClockOns;
