import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { DataContext } from "../../../../contexts/DataContext";
import { StyleContext } from "../../../../contexts/StyleContext";

import serverURL from "../../../../serverURL";

import close from "../../../../img/modals/close.svg";

import dateStringer from "../../../../tools/dateStringer.js";

const AddShiftModal2 = ({
  teamID,
  type,
  setShowAddShift,
  setShowAddTilModal,
  setShowAddLeaveOnTeam,
  loadFromReqsPage,
  absence,
}) => {
  console.log("AddShiftModal2 opened");
  const { setShowEditShiftModal, setShowAddLeaveModal } =
    useContext(DataContext);
  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowEditShiftModal,
      setShowAddLeaveModal,
      mob,
      mobModal,
    }),
    [
      setShowEditShiftModal, //
      setShowAddLeaveModal, //
      mob, //
      mobModal, //
    ]
  );

  let [userID, setUserID] = useState("");

  let [teamUsers, setTeamUsers] = useState([]);

  let [shiftTeam, setShiftTeam] = useState(teamID === "allStaff" ? "" : teamID);
  let [teamsUserIsPartOf, setTeamsUserIsPartOf] = useState([]);
  let [dateInput, setDateInput] = useState(
    loadFromReqsPage
      ? dateStringer.createDateInputFromDateString(loadFromReqsPage)
      : ""
  );
  let [dateValid, setDateValid] = useState(false);
  let [tooEarly, setTooEarly] = useState(false);
  let [selectedLeaveTypeID, setSelectedLeaveTypeID] = useState("annLeave");
  let [leaveTypes, setLeaveTypes] = useState([]);

  const inputDate = document.getElementById("addShiftDateInput");

  if (inputDate && !memoVals.mob) {
    inputDate.addEventListener("focus", function (evt) {
      if (this.getAttribute("type") === "date") {
        this.showPicker();
      }
    });
  }

  let [loading, setLoading] = useState(false);
  let getDayString = (di) => {
    let dateObj = new Date(
      dateStringer.createTimestampFromString(
        dateStringer.createStringFromDateInput(di)
      )
    );

    if (dateObj.getDay() === 0) {
      return "Sun";
    }

    if (dateObj.getDay() === 1) {
      return "Mon";
    }

    if (dateObj.getDay() === 2) {
      return "Tue";
    }

    if (dateObj.getDay() === 3) {
      return "Wed";
    }

    if (dateObj.getDay() === 4) {
      return "Thu";
    }

    if (dateObj.getDay() === 5) {
      return "Fri";
    }

    if (dateObj.getDay() === 6) {
      return "Sat";
    }
  };

  let getName = (userID) => {
    if (userID) {
      let filteredUser = teamUsers.filter((item) => item.userID === userID);
      if (filteredUser[0]) {
        return filteredUser[0].fName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  let scheduleNewShift = (type, ds, userID) => {
    let today = new Date();
    axios
      .post(
        `${serverURL}/schedule-new-shift`,
        {
          teamID: shiftTeam,
          ds: ds,
          userID: userID,
          type: type,
          nowDs: dateStringer.createStringFromTimestamp(today.getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (type === "shift") {
            setShowAddShift(false);
          } else {
            setShowAddTilModal(false);
          }
          memoVals.setShowEditShiftModal((x) => {
            return {
              itemID: response.data.shiftID,
              itemType: response.data.type,
              itemFName: response.data.fName,
              itemLName: response.data.lName,
              itemIsNew: true,
              itemUserID: userID,
            };
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    // clean not needed
    let ds = dateStringer.createStringFromDateInput(dateInput);
    if (
      ds &&
      ds[0] === "Y" &&
      ds[1] === "2" &&
      ds[2] === "0" &&
      !ds.includes("null")
    ) {
      setDateValid(true);
    } else {
      setDateValid(false);
    }
  }, [dateInput]);
  useEffect(() => {
    // get team users
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-team-users`,
        {
          teamID: teamID,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((resp) => {
        if (resp.data.message === "success") {
          setTeamUsers(resp.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    if (userID) {
      if (type === "leave") {
        axios
          .post(
            `${serverURL}/get-available-request-types`,
            { userID: userID, absencesToo: true },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource2.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              let leaves = response.data.custom;
              let absences = response.data.absences;
              let newArr = [
                { name: "- - - - Other leave types:", leaveTypeID: "null" },
                ...leaves,
                {
                  name: "- - - - Absences:",
                  absenceTypeID: "null",
                  isAbsence: true,
                },
                ...absences,
              ];
              setLeaveTypes(newArr);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        axios
          .post(
            `${serverURL}/get-teams-edit-user-is-part-of`,
            { userID: userID },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource2.token,
            }
          )
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.message === "success"
            ) {
              setTeamsUserIsPartOf(response.data.allTeams);
              // setPrimaryTeamIDDefault(response.data.allTeams[0].teamID);
              if (teamID === "allStaff") {
                response.data.allTeams.forEach((team) => {
                  if (team.primaryTeam) {
                    setShiftTeam(team.teamID);
                  }
                });
              } else {
                setShiftTeam(teamID);
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, [userID]);

  let generateUsersForAddShiftSelection = useMemo(() => {
    teamUsers.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });
    return teamUsers.map((usr) => {
      if ((type === "leave" && usr.managesPteam) || type !== "leave")
        return (
          <option key={usr.userID} value={usr.userID}>
            {usr.fName} {usr.lName}
          </option>
        );
    });
  }, [teamUsers]);

  let shortenTeamName = (text) => {
    if (text.length > 22) {
      return `${text.substr(0, 18)}...`;
    } else {
      return text;
    }
  };

  let generateTeamsAvailableForUser = useMemo(() => {
    return teamsUserIsPartOf.map((team) => {
      return (
        <option key={team.teamID} value={team.teamID}>
          {shortenTeamName(team.teamName)}
        </option>
      );
    });
  }, [teamsUserIsPartOf]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      if (type === "shift") {
        setShowAddShift(false);
      } else {
        if (type === "leave") {
          setShowAddLeaveOnTeam(false);
        } else {
          setShowAddTilModal(false);
        }
      }
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className="editShiftModalUnderlay"
      onClick={(e) => {
        e.stopPropagation();
        if (type === "shift") {
          setShowAddShift(false);
        } else {
          if (type === "leave") {
            setShowAddLeaveOnTeam(false);
          } else {
            setShowAddTilModal(false);
          }
        }
      }}
    >
      <div
        className={`addShiftModalBox ${
          type === "leave" ? `addShiftLeaveOnlyBox` : ""
        } ${memoVals.mobModal ? "mobModalShoulder slideUp" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperAddShiftModal2"></div>
        )}
        <div
          className="addShiftModalHeader"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={close}
            alt="close"
            className="closeAddShiftModalImg"
            onClick={() => {
              if (type === "shift") {
                setShowAddShift(false);
              } else {
                if (type === "leave") {
                  setShowAddLeaveOnTeam(false);
                } else {
                  setShowAddTilModal(false);
                }
              }
            }}
          />
          <p
            className="addShiftHeaderTitle addShiftTitle"
            onClick={() => {
              if (memoVals.mobModal) {
                if (type === "shift") {
                  setShowAddShift(false);
                } else {
                  if (type === "leave") {
                    setShowAddLeaveOnTeam(false);
                  } else {
                    setShowAddTilModal(false);
                  }
                }
              }
            }}
          >
            Add{" "}
            {type === "til"
              ? "time in lieu"
              : type === "leave"
              ? "leave / absence"
              : type}
          </p>
          <img
            src={close}
            alt="close"
            className="closeAddShiftModalImg invis"
          />
        </div>

        {/* page 0 */}
        <div
          className={`addShiftMidContainer alignMiddlePage0 ${
            type === "til" ? "addShiftMidContainerTil" : ""
          }`}
        >
          {type === "til" ? (
            <p className="addTilInfoTxt">
              This is a type of shift that the employee will not be paid for,
              but instead, can take it off as time off (time off in lieu) on any
              one of their scheduled shifts or overtime
            </p>
          ) : (
            ""
          )}
          <p className="addShiftInputLabel">Staff member</p>
          <select
            className="addShiftOnWhichUserDropdown"
            value={userID}
            onChange={(e) => {
              setUserID(e.target.value);
            }}
          >
            <option value="">Select employee</option>
            {generateUsersForAddShiftSelection}
          </select>
          {type === "leave" ? "" : <div className="addShiftDivider"></div>}
          <p
            className={`addShiftInputLabel ${userID ? "" : "opacity4"} ${
              type === "leave" ? "none" : ""
            }`}
          >
            {type === "leave" ? "Leave type" : "Team"}
          </p>
          {type === "leave" ? (
            // <select
            //   className={`addShiftOnWhichUserDropdown ${
            //     userID ? "" : "opacity4"
            //   } `}
            //   value={selectedLeaveTypeID}
            //   onChange={(e) => {
            //     if (e.target.value === "null") {
            //       setSelectedLeaveTypeID("annLeave");
            //     } else {
            //       setSelectedLeaveTypeID(e.target.value);
            //     }
            //   }}
            // >
            //   <option value="annLeave">Annual Leave</option>
            //   {generateLeaves}
            // </select>
            ""
          ) : (
            <select
              className={`addShiftOnWhichUserDropdown ${
                userID && (shiftTeam || teamID === "allStaff") ? "" : "opacity4"
              } `}
              value={shiftTeam}
              onChange={(e) => {
                setShiftTeam(e.target.value);
              }}
            >
              <option value="">Select team</option>
              {generateTeamsAvailableForUser}
            </select>
          )}{" "}
          <div className="addShiftDivider"></div>
          <p
            className={`addShiftInputLabel ${
              userID && (shiftTeam || selectedLeaveTypeID) ? "" : "opacity4"
            } `}
          >
            Date
          </p>
          <div className="addDateAndDayStringContainer">
            <input
              type="date"
              className={`addShiftDateInput  ${
                userID && (shiftTeam || selectedLeaveTypeID) ? "" : "opacity4"
              }`}
              id="addShiftDateInput"
              value={dateInput}
              onChange={(e) => {
                setTooEarly(false);
                setDateInput(e.target.value);
                console.log(e.target.value);
              }}
            ></input>{" "}
            <p className="addShiftDayString">
              {dateValid ? getDayString(dateInput) : ""}
            </p>
          </div>
        </div>
        {/* END OF PAGE 0 */}

        <div
          className={`addShiftFooter ${
            type === "leave" ? "addShiftLeaveOnlyFooter" : ""
          }`}
        >
          {/* <p
            className="cancelAddShiftTxt"
            onClick={() => {
              setShowAddShift(false);
            }}
          >
            Cancel
          </p>{" "} */}
          <p className={`addShiftTooLateEarlyTxt ${!tooEarly ? "none" : ""}`}>
            Cannot add {type === "leave" ? "leave" : "shift"} before{" "}
            {getName(userID)}
            {getName(userID)[getName(userID).length - 1] === "s"
              ? "'"
              : "'s"}{" "}
            start date
          </p>
          <p
            className={`submitAddShiftOnUser ${
              userID &&
              (shiftTeam || selectedLeaveTypeID) &&
              dateValid &&
              !tooEarly &&
              !loading
                ? ""
                : "invalidAddShiftBtn"
            } ${tooEarly ? "none" : ""}`}
            onClick={() => {
              setLoading(true);
              if (userID) {
                axios
                  .post(
                    `${serverURL}/get-max-date`,
                    {
                      userID: userID,
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((resp) => {
                    if (resp.data.message === "success") {
                      let ts = dateStringer.createTimestampFromString(
                        dateStringer.createStringFromDateInput(dateInput)
                      );

                      let minTs = dateStringer.createTimestampFromString(
                        resp.data.userStartDate
                      );

                      if (ts < minTs) {
                        setLoading(false);
                        setTooEarly(true);
                      } else {
                        if (type !== "leave") {
                          // schedule new shift here
                          setLoading(false);
                          scheduleNewShift(
                            type,
                            dateStringer.createStringFromDateInput(dateInput),
                            userID
                          );
                        } else {
                          // add new leave here

                          setShowAddLeaveOnTeam(false);
                          let isAbsence = absence;
                          // leaveTypes.forEach((lt) => {
                          //   if (lt.absenceTypeID === selectedLeaveTypeID) {
                          //     isAbsence = true;
                          //   }
                          // });
                          memoVals.setShowAddLeaveModal((x) => {
                            return {
                              show: true,
                              startDs:
                                dateStringer.createStringFromDateInput(
                                  dateInput
                                ),
                              userID: userID,
                              leaveTypeID: selectedLeaveTypeID,
                              fName: resp.data.fName,
                              reqID: "", // if editing existing one
                              editing: false,
                              editStartDs: "",
                              editEndDs: "",
                              ongoing: false,
                              toEndOngoing: false,
                              isAbsence,
                            };
                          });
                        }
                        // = (type, ds, userID)
                      }
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            Add
          </p>
          <p
            className={`closeEditShiftBtn`}
            onClick={() => {
              if (type === "shift") {
                setShowAddShift(false);
              } else {
                if (type === "leave") {
                  setShowAddLeaveOnTeam(false);
                } else {
                  setShowAddTilModal(false);
                }
              }
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddShiftModal2;
