import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import navyClose from "../img/general/navycross.svg";
import schedLimitMinus from "../img/general/schedLimitMinus.svg";
import schedLimitPlus from "../img/general/schedLimitPlus.svg";

import checkboxInstall1 from "../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../img/general/checkboxInstall2.svg";
import greyChecked from "../img/general/greyChecked.svg";

import editUserEdit1 from "../img/general/editUserEdit1.svg";
import { AdminContext } from "../contexts/AdminContext";
import dateStringer from "../tools/dateStringer";

const ViewLeaveTypeModal = () => {
  const { mobModal, mob } = useContext(StyleContext);
  const {
    showSpecialDayModal,

    showLeaveTypeModal,
    setShowLeaveTypeModal,
    setSureRemoveLeaveType,
    setIndicate,
  } = useContext(DataContext);
  const { setCustomLeaveTypes } = useContext(AdminContext);

  const memoVals = useMemo(
    () => ({
      showSpecialDayModal, //
      showLeaveTypeModal, //
      setShowLeaveTypeModal, //
      setSureRemoveLeaveType, //
      setIndicate, //
      setCustomLeaveTypes, //
      mobModal, //
      mob, //
    }),
    [
      showSpecialDayModal, //
      showLeaveTypeModal, //
      setShowLeaveTypeModal, //
      setSureRemoveLeaveType, //
      setIndicate, //
      setCustomLeaveTypes, //
      mobModal, //
      mob, //
    ]
  );

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowLeaveTypeModal((x) => {
        return { show: false };
      });
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  let [sureReinstate, setSureReinstate] = useState(false);

  let [toggleEditName, setToggleEditName] = useState(
    memoVals.showLeaveTypeModal.new ? true : false
  );

  let [newName, setNewName] = useState(
    memoVals.showLeaveTypeModal.new ? "" : ""
  );

  let [allowanceEdited, setAllowanceEdited] = useState(false);
  let [allowance, setAllowance] = useState(
    memoVals.showLeaveTypeModal.new ? 2 : memoVals.showLeaveTypeModal.allowance
  );
  let [daysOrHours, setDaysOrHours] = useState(
    memoVals.showLeaveTypeModal.new
      ? "days"
      : memoVals.showLeaveTypeModal.daysOrHours
  );
  let [rate, setRate] = useState(
    memoVals.showLeaveTypeModal.new ? 1 : memoVals.showLeaveTypeModal.rate
  );

  let [blockRequests, setBlockRequests] = useState(
    memoVals.showLeaveTypeModal.new
      ? false
      : memoVals.showLeaveTypeModal.blockRequests
  );

  let nameRef = useRef();
  useEffect(() => {
    if (memoVals.showLeaveTypeModal.new && !memoVals.mob) {
      if (nameRef && nameRef.current) {
        nameRef.current.focus();
      }
    }
  }, []);

  let closeModal = () => {
    memoVals.setShowLeaveTypeModal((x) => {
      return { show: false };
    });
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`dayCommentsModal ${
          memoVals.mobModal ? "mobModalShoulder" : "maxViewZoneModalWidth"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperViewLeaveType"></div>
        )}
        <div
          className="mySwapsHeader"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={memoVals.mobModal ? navyClose : navyClose}
            alt="Close"
            className="closeMySwapsModalImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p>Custom leave type</p>

          <p></p>
        </div>
        <div className="dayCommentsModalBody maxHeight480">
          <div className="gpsZoneRow noBorder">
            <p className="lonLatTitles">Leave type name</p>

            {toggleEditName ? (
              <div className={`lonLatInputDiv`}>
                {" "}
                <input
                  type="text"
                  defaultValue="Current location"
                  className={`latLonInput`}
                  ref={nameRef}
                  placeholder={
                    memoVals.showLeaveTypeModal.new ? "New leave type" : newName
                  }
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                ></input>
                {!memoVals.showLeaveTypeModal.new ? (
                  <p
                    className={`saveSettBtn ${
                      memoVals.showLeaveTypeModal.new ? "none" : ""
                    }`}
                    onClick={() => {
                      setToggleEditName(false);

                      axios
                        .post(
                          `${serverURL}/update-custom-leave-type`,
                          {
                            updateWhat: "name",
                            leaveTypeID:
                              memoVals.showLeaveTypeModal.leaveTypeID,
                            data: newName,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setShowLeaveTypeModal((x) => {
                              return {
                                show: true,
                                leaveTypeID:
                                  memoVals.showLeaveTypeModal.leaveTypeID,
                                daysOrHours:
                                  memoVals.showLeaveTypeModal.daysOrHours,
                                allowance:
                                  memoVals.showLeaveTypeModal
                                    .customLeaveDefaultDays,
                                rate: memoVals.showLeaveTypeModal.rate,
                                unlimited:
                                  memoVals.showLeaveTypeModal.unlimited,
                                name: newName,
                                blockRequests:
                                  memoVals.showLeaveTypeModal.blockRequests,
                              };
                            });
                            memoVals.setCustomLeaveTypes(
                              (x) => response.data.customLeaveTypes
                            );
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Save
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p
                className={`lonLatVal ${
                  memoVals.showSpecialDayModal.name > 24 ? "smallZoneName2" : ""
                }`}
              >
                {memoVals.showLeaveTypeModal.name}
                <img
                  src={editUserEdit1}
                  alt="Edit"
                  className="adminEditBtnImg"
                  onClick={() => {
                    setNewName(memoVals.showLeaveTypeModal.name);

                    setToggleEditName(true);
                  }}
                />
              </p>
            )}
          </div>{" "}
          <div className="gpsZoneRow">
            <p className="lonLatTitles">
              Allowance
              {memoVals.showLeaveTypeModal.new ? (
                <span className="x3838383"> - per year</span>
              ) : (
                ""
              )}
            </p>

            {memoVals.showLeaveTypeModal.new ? (
              <div className="customAllowFlex">
                {allowance === 366 ? <p className="unlim">Unlimited</p> : ""}
                <div className="customAllowWithDaysOrHours">
                  <div
                    className={`customAllowInputParent ${
                      allowance === 366 ? "none" : ""
                    }`}
                  >
                    <input
                      className={`customAllowInputter ${
                        allowanceEdited ? "x289389389" : ""
                      }`}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value > -1 && e.target.value < 367) {
                          setAllowance(e.target.value);
                          setAllowanceEdited(true);
                        }
                      }}
                      value={allowance}
                    ></input>{" "}
                    <img
                      src={schedLimitMinus}
                      alt="Deduct one"
                      className={`customAddDeductImg ${
                        allowance === 0 ? "x1118383" : ""
                      }`}
                      onClick={() => {
                        if (allowance > 0) {
                          setAllowance(parseInt(allowance) - 1);
                          setAllowanceEdited(true);
                        }
                      }}
                    />
                    <img
                      src={schedLimitPlus}
                      alt="Deduct one"
                      className={`customAddDeductImg ${
                        allowance === 366 ? "x1118383" : ""
                      }`}
                      onClick={() => {
                        if (allowance < 366) {
                          setAllowance(parseInt(allowance) + 1);
                          setAllowanceEdited(true);
                        }
                      }}
                    />
                  </div>

                  <div
                    className={`daysOrHoursDiv ${
                      allowance === 366 ? "none" : ""
                    }`}
                  >
                    <p
                      className={`daysOrHoursTxt ${
                        daysOrHours === "hours" ? "x298489243" : ""
                      }`}
                      onClick={() => {
                        setDaysOrHours("days");
                      }}
                    >
                      Days{" "}
                      <img
                        className="daysorHoursCheckbox"
                        src={
                          daysOrHours === "days"
                            ? greyChecked
                            : checkboxInstall1
                        }
                      />
                    </p>
                    <p
                      className={`daysOrHoursTxt x2389923 ${
                        daysOrHours === "days" ? "x298489243" : ""
                      }`}
                      onClick={() => {
                        setDaysOrHours("hours");
                      }}
                    >
                      Hours{" "}
                      <img
                        className="daysorHoursCheckbox"
                        src={
                          daysOrHours === "hours"
                            ? greyChecked
                            : checkboxInstall1
                        }
                      />
                    </p>
                  </div>
                </div>

                <p
                  className={`historySpecDaysBtn x2892989`}
                  onClick={() => {
                    if (allowance !== 366) {
                      setAllowance(366);
                    } else {
                      setAllowance(10);
                    }
                  }}
                >
                  Unlimited{" "}
                  <img
                    src={
                      allowance === 366 ? checkboxInstall2 : checkboxInstall1
                    }
                    alt="Check box install"
                    className="historicalCheckBoxInstallImg"
                  />
                </p>
              </div>
            ) : allowance === 366 ? (
              <p className="x2894982">Unlimited</p>
            ) : (
              <p className="x2894982">
                {allowance} {daysOrHours} per year
              </p>
            )}
          </div>
          <div className="gpsZoneRow">
            <p className="lonLatTitles">Paid leave</p>

            <p className={`lonLatVal x249898233`}>
              {memoVals.showLeaveTypeModal.new
                ? rate === "0"
                  ? "No"
                  : "Yes"
                : memoVals.showLeaveTypeModal.rate === 0 ||
                  memoVals.showLeaveTypeModal.rate === "0"
                ? "No"
                : "Yes"}

              {/*  KNOB BEGIN */}
              {memoVals.showLeaveTypeModal.new ? (
                <div
                  className="knobContainer specDayEditKnob"
                  onClick={() => {
                    let newData = rate !== "0" ? "0" : "1";
                    setRate(newData);
                  }}
                >
                  <div
                    className={`switchKnob ${
                      rate !== "0" && rate !== 0 ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              ) : (
                ""
              )}
              {/*  KNOB END */}
            </p>
          </div>
          {memoVals.showLeaveTypeModal.new && rate === "0" ? (
            ""
          ) : memoVals.showLeaveTypeModal.rate !== "0" &&
            memoVals.showLeaveTypeModal.rate !== 0 ? (
            <div className="gpsZoneRow flexGpsRow">
              <p className="lonLatTitles x248984234">Pay rate</p>

              <p
                className={`lonLatVal x240893823 ${
                  memoVals.mobModal && memoVals.showLeaveTypeModal.new
                    ? rate !== 0 && rate !== "0" && memoVals.mobModal
                      ? "x2383821232"
                      : "x298484"
                    : ""
                }`}
              >
                {memoVals.showLeaveTypeModal.new ? (
                  <select
                    className={`specialDaysRateDropDown x298492812 ${
                      memoVals.mobModal
                        ? rate !== "0" && rate !== 0
                          ? `x383838383121_ ${
                              blockRequests ? "x328923121" : ""
                            } delayShow`
                          : "x095985858 delayShow"
                        : ""
                    }`}
                    onClick={() => {
                      console.log({ rate });
                    }}
                    onChange={(e) => {
                      let newData = e.target.value;
                      setRate(newData);
                    }}
                    value={rate}
                  >
                    <option value="0">0x</option>
                    <option value="0.05">x0.05</option>

                    <option value="0.1">0.1x</option>
                    <option value="0.15">0.15x</option>

                    <option value="0.2">0.2x</option>
                    <option value="0.25">0.25x</option>

                    <option value="0.3">0.3x</option>
                    <option value="0.35">0.35x</option>

                    <option value="0.4">0.4x</option>
                    <option value="0.45">0.45x</option>

                    <option value="0.5">0.5x</option>
                    <option value="0.55">0.55x</option>

                    <option value="0.6">0.6x</option>
                    <option value="0.65">0.65x</option>

                    <option value="0.7">0.7x</option>
                    <option value="0.75">0.75x</option>

                    <option value="0.75">0.75x</option>
                    <option value="0.8">0.8x</option>
                    <option value="0.85">0.85x</option>

                    <option value="0.9">0.9x</option>
                    <option value="0.95">0.95x</option>

                    <option value="1">1x</option>

                    <option value="1.5">1.5x</option>
                    <option value="1.75">1.75x</option>
                    <option value="2">2x</option>
                    <option value="2.25">2.25x</option>
                    <option value="2.5">2.5x</option>
                    <option value="2.75">2.75x</option>
                    <option value="3">3x</option>
                    <option value="3.25">3.25x</option>
                    <option value="3.5">3.5x</option>
                    <option value="3.75">3.75x</option>
                    <option value="4">4x</option>
                  </select>
                ) : (
                  <p className="showLeaveTypeVal">{rate}x</p>
                )}
              </p>
            </div>
          ) : (
            ""
          )}
          {!memoVals.showLeaveTypeModal.removed ? (
            <div className="gpsZoneRow x8492892381">
              <p className="lonLatTitles">
                Allow employees to request this leave
              </p>

              <p
                className={`lonLatVal x249898233 x3838383243 ${
                  !blockRequests ? "x283829812" : ""
                }`}
              >
                {blockRequests
                  ? "This leave type will not be included in the list of leave types available for employees to request. Only an employee's manager can add this leave period."
                  : "Employees can request this leave type when making a leave request."}
                {/*  KNOB BEGIN */}
                <div
                  className={`knobContainer x9389183812  ${
                    !blockRequests
                      ? "x38383813121"
                      : memoVals.mobModal
                      ? "x283891389182"
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.showLeaveTypeModal.new) {
                      setBlockRequests(!blockRequests);
                    } else {
                      axios
                        .post(
                          `${serverURL}/update-custom-leave-type`,
                          {
                            updateWhat: "blockRequests",
                            leaveTypeID:
                              memoVals.showLeaveTypeModal.leaveTypeID,
                            data: !blockRequests,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setBlockRequests(!blockRequests);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !blockRequests ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </p>
            </div>
          ) : (
            ""
          )}
          {!memoVals.showLeaveTypeModal.new &&
          !memoVals.showLeaveTypeModal.removed ? (
            <div className="gpsZoneRow">
              <p className="lonLatTitles x248984234">
                You are unable to amend the rate or allowance. Instead, you
                should archive this leave type with the button below and create
                a new one.
                <br />
                <br />
                This is because employees may already have historic leave
                periods that rely on this leave type's settings. By archiving
                this leave type instead, employees' historic leave periods of
                this type will still be able to use the leave type's settings.
                The leave type is never truly deleted (unless you delete your
                FlowRota company account).
                <br />
                <br />
              </p>

              <p></p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="dayCommentsModalFooter">
          <div className="viewMapsAndSaveDiv">
            {memoVals.showLeaveTypeModal.new ? (
              <p
                className="saveGpsZoneBtn"
                onClick={() => {
                  setToggleEditName(false);
                  axios
                    .post(
                      `${serverURL}/create-new-custom-leave-type`,
                      {
                        name: newName,
                        daysOrHours,
                        allowance,
                        rate,
                        blockRequests,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setCustomLeaveTypes(
                          (x) => response.data.customLeaveTypes
                        );

                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Custom leave created",
                            colour: "blue",
                            duration: 4000,
                          };
                        });

                        memoVals.setShowLeaveTypeModal((x) => {
                          return {
                            show: true,
                            leaveTypeID: response.data.newObj.leaveTypeID,
                            daysOrHours: response.data.newObj.daysOrHours,
                            allowance:
                              response.data.newObj.customLeaveDefaultDays,
                            rate: response.data.newObj.rate,
                            name: response.data.newObj.name,
                            new: false,
                            blockRequests: response.data.newObj.blockRequests,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Save
              </p>
            ) : (
              ""
            )}
            <a
              className={`viewGpsZoneMapBtn ${
                memoVals.showLeaveTypeModal.new ? "none" : ""
              }`}
              onClick={() => {
                if (memoVals.showLeaveTypeModal.removed) {
                  setSureReinstate(true);
                } else {
                  memoVals.setShowLeaveTypeModal(
                    (x) => memoVals.showLeaveTypeModal.leaveTypeID
                  );
                }
              }}
            >
              {memoVals.showLeaveTypeModal.removed ? "Reinstate" : "Archive"}
            </a>
          </div>

          <p
            className="closeSeeUserIDBtn"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
      {sureReinstate ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setSureReinstate(false);
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to reinstate this custom leave?
              <br /> <br />
              Employees will be able to request this leave again.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureReinstate(false);
                  axios
                    .post(
                      `${serverURL}/remove-custom-leave-type`,
                      {
                        leaveTypeID: memoVals.showLeaveTypeModal.leaveTypeID,
                        undoRemove: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setCustomLeaveTypes(
                          (x) => response.data.customLeaveTypes
                        );

                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Custom leave type reinstated",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        memoVals.setShowLeaveTypeModal((x) => {
                          return {
                            show: true,
                            leaveTypeID:
                              memoVals.showLeaveTypeModal.leaveTypeID,
                            daysOrHours:
                              memoVals.showLeaveTypeModal.daysOrHours,
                            allowance:
                              memoVals.showLeaveTypeModal
                                .customLeaveDefaultDays,
                            rate: memoVals.showLeaveTypeModal.rate,
                            unlimited: memoVals.showLeaveTypeModal.unlimited,
                            name: memoVals.showLeaveTypeModal.name,
                            removed: false,
                            blockRequests:
                              memoVals.showLeaveTypeModal.blockRequests,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureReinstate(false);
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewLeaveTypeModal;
