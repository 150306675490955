import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import inReturnForArrow from "../img/general/inReturnForArrow.svg";
import navyClose from "../img/general/navycross.svg";
import unitTick from "../img/general/unitTick.svg";
import greyCross from "../img/general/greyCross.svg";
import publishTick1 from "../img/general/checkboxInstall1.svg";
import publishTick2 from "../img/general/checkboxInstall2.svg";
const TeamSwapsModal = () => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const {
    setIndicate,
    setShowMyShiftModal,
    showTeamSwapsModal,
    setShowTeamSwapsModal,
    teamSwapsQty,
    setTeamSwapsQty,
    setDynamicSwap,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      setShowMyShiftModal,
      showTeamSwapsModal,
      setShowTeamSwapsModal,
      teamSwapsQty,
      setTeamSwapsQty,
      setDynamicSwap,
      checkUserState,
      mobModal,
    }),
    [
      setIndicate, //
      setShowMyShiftModal, //
      showTeamSwapsModal, //
      setShowTeamSwapsModal, //
      teamSwapsQty, //
      setTeamSwapsQty, //
      setDynamicSwap, //
      checkUserState, //
      mobModal, //
    ]
  );

  let [showHistory, setShowHistory] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 6) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={inReturnForArrow}
        alt="In Return For Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={publishTick1}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={publishTick2}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCross}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowTeamSwapsModal((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowTeamSwapsModal((x) => false);
  };

  let [sureDeny, setSureDeny] = useState({ show: false });

  let [teams, setTeams] = useState([]);
  let [selectedTeamID, setSelectedTeamID] = useState(
    memoVals.showTeamSwapsModal || "allStaff"
  );

  let getTeamName = (teamID) => {
    if (teamID === "allStaff") {
      return "all your staff";
    }
    if (teamID && teams && teams[0]) {
      let filt = teams.filter((item) => {
        return item.teamID === teamID;
      });
      if (filt[0]) {
        return filt[0].teamName;
      }
    } else {
      return "Your staff";
    }
  };

  let [historyArr, setHistoryArr] = useState([]);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (showHistory) {
      axios
        .post(
          `${serverURL}/get-swap-history`,
          {
            teamID: selectedTeamID,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setHistoryArr(response.data.output);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [showHistory, selectedTeamID]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!teams[0]) {
      axios
        .post(
          `${serverURL}/get-teams-user-manages`,
          {
            someData: 1,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (response.data.array[0]) {
              setTeams(response.data.array);
              setSelectedTeamID("allStaff");
              //   setSelectedTeamID(response.data.array[0].teamID);
            } else {
              memoVals.setShowTeamSwapsModal((x) => false);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [swaps, setSwaps] = useState([]);
  let [loaded, setLoaded] = useState(false);
  let [allowTeamSwaps, setAllowTeamSwaps] = useState(false);
  let [autoApprove, setAutoApprove] = useState(false);

  let [showApprovalInfo, setShowApprovalInfo] = useState(false);
  useEffect(() => {
    // clean not needed
    if (selectedTeamID === "allStaff") {
      let atLeastOneSwapIsNotAutoApprovable = false;
      swaps.forEach((sw) => {
        if (!sw.autoApprovable) {
          atLeastOneSwapIsNotAutoApprovable = true;
        }
      });

      if (atLeastOneSwapIsNotAutoApprovable) {
        setShowApprovalInfo(true);
      }
    } else {
      if (!autoApprove) {
        setShowApprovalInfo(true);
      } else {
        setShowApprovalInfo(false);
      }
    }
  }, [selectedTeamID, swaps, autoApprove]);

  let sortSwaps = (theSwaps) => {
    let notYetApproved = [];
    let approved = [];

    theSwaps.forEach((s) => {
      if (s.mgrApproved) {
        approved.push(s);
      } else {
        notYetApproved.push(s);
      }
    });

    return [...notYetApproved, ...approved];
  };

  let deny = (s, allSwaps) => {
    axios
      .post(
        `${serverURL}/deny-swap`,
        {
          wantType: s.wantedType,
          shiftID: s.wantedTypeID,
          wantUserID: s.offeredUserID,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((resposne) => {
        if (resposne.data.message === "success") {
          let newArr = [];

          allSwaps.forEach((sw) => {
            if (sw.id !== s.id) {
              newArr.push(sw);
            }
          });

          setSwaps(sortSwaps(newArr));

          if (!s.mgrApproved) {
            let newTeamSwapsQty = memoVals.teamSwapsQty - 1;
            memoVals.setTeamSwapsQty((x) => newTeamSwapsQty);
          }

          memoVals.setIndicate((x) => {
            return {
              show: true,
              message: `Denied swap between ${s.wantedFName} and ${s.offeredFName}`,
              colour: "red",
              duration: 4000,
              ts: new Date().getTime(),
            };
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  let generateSwaps = swaps.map((swap, i) => {
    return (
      <div className="swapUnitParent">
        <div
          key={i}
          className={`swapUnit teamSwapUnit ${
            swap.mgrApproved ? "swapApprovedGreenLeftBorder" : ""
          }`}
          onClick={() => {
            console.log(swap);
          }}
        >
          <div className="swapUnitLeft">
            <div className="swapUnitTeamLeftUpperMinimum">
              <p className="swapUnitLeftTxt">
                <span className="swapFName">
                  {swap.offeredFName} {swap.offeredLName[0]}
                </span>{" "}
                wants {swap.wantedFName}{" "}
                {swap.wantedLName && swap.wantedLName[0]}
                's{" "}
                <span className="swapTypeSpan">
                  {swap.wantedType === "til" ? "time in lieu" : swap.wantedType}
                </span>
                :
              </p>
            </div>
            <div
              className="swapUnitWantItemDiv"
              onClick={() => {
                memoVals.setShowMyShiftModal((x) => {
                  return {
                    unitType: swap.wantedType,
                    unitID: swap.wantedTypeID,
                    otherUser:
                      swap.wantedUserID === memoVals.checkUserState.userID
                        ? false
                        : true,
                  };
                });
              }}
            >
              <p
                className={`swapUnitWantDs ${
                  memoVals.mobModal ? "font12" : ""
                }`}
              >
                {memoVals.mobModal
                  ? `${dateStringer.printedDateFromDs(
                      swap.wantedStartDs
                    )} '${swap.wantedStartDs.substr(3, 2)}`
                  : dateStringer.printedDateWithYrFromDs(swap.wantedStartDs)}
              </p>
              <p
                className={`swapUnitWantDs ${
                  memoVals.mobModal ? "font12" : ""
                }`}
              >
                {dateStringer.dsToTimeStrip(swap.wantedStartDs)} -{" "}
                {dateStringer.dsToTimeStrip(swap.wantedEndDs)}
              </p>
              <p
                className={`swapUnitWantDsSub noBorder ${
                  memoVals.mobModal ? "font12" : ""
                }`}
              >
                {dateStringer.formatMinsDurationToHours(swap.wantedDurMins)}
              </p>
            </div>
            {/* {swap.wantTags && swap.wantTags[0] ? (
              <div className="wantShiftTags">
                <p className="wantShiftTagsTitle">Tags:</p>
                <div className="wantShiftTagsList">{generateWantTags}</div>
              </div>
            ) : (
              ""
            )} */}
          </div>
          <div className="teamSwapUnitMiddle">
            <img
              src={inReturnForArrow}
              alt="In return for"
              className="inReturnForArrowImg"
            />
            <p
              className={`inReturnForTxt ${
                memoVals.mobModal ? "font12px" : ""
              }`}
            >
              in return for
            </p>
            <img
              src={inReturnForArrow}
              alt="In return for"
              className="inReturnForArrowImg flipH"
            />
          </div>

          <div
            className={`swapUnitRight ${
              swap.autoApprovable
                ? !memoVals.mobModal
                  ? "paddingBottom26px"
                  : "paddingBottom82px"
                : ""
            }`}
          >
            <div className="swapUnitTeamRightUpperMinimum">
              {swap.offerNothing || swap.deleted ? (
                <p
                  className={`offerSwapNothingTxt ${
                    swap.offerNothing ? "x9242323" : ""
                  }`}
                >
                  {swap.deleted
                    ? `Nothing. A ${
                        swap.offeredType === "til"
                          ? "time in lieu"
                          : swap.offeredType
                      } was offered, but it has since been changed by a manager`
                    : " ...no shift offered"}
                </p>
              ) : (
                <p className="swapUnitRightTxt swap500">
                  ...their{" "}
                  <span className="swapTypeSpan">
                    {swap.offeredType === "til"
                      ? "time in lieu"
                      : swap.offeredType}
                  </span>
                  :
                </p>
              )}
            </div>

            {swap.offerNothing || swap.deleted ? (
              <img
                src={greyCross}
                alt="Nothing offered"
                className="teamSwapOfferNothingCross"
              />
            ) : (
              <div
                className={`swapUnitWantItemDiv ${
                  memoVals.mobModal ? "rightMobSwapUnit" : ""
                }`}
                onClick={() => {
                  memoVals.setShowMyShiftModal((x) => {
                    return {
                      unitType: swap.offeredType,
                      unitID: swap.offeredTypeID,
                      otherUser:
                        swap.offeredUserID === memoVals.checkUserState
                          ? false
                          : true,
                    };
                  });
                }}
              >
                <p
                  className={`swapUnitWantDs txtAlignRight ${
                    memoVals.mobModal ? "font12" : ""
                  }`}
                >
                  {memoVals.mobModal
                    ? `${dateStringer.printedDateFromDs(
                        swap.offeredStartDs
                      )} '${swap.offeredStartDs.substr(3, 2)}`
                    : dateStringer.printedDateWithYrFromDs(swap.offeredStartDs)}
                </p>
                <p
                  className={`swapUnitWantDs txtAlignRight ${
                    memoVals.mobModal ? "font12" : ""
                  }`}
                >
                  {dateStringer.dsToTimeStrip(swap.offeredStartDs)} -{" "}
                  {dateStringer.dsToTimeStrip(swap.offeredEndDs)}
                </p>
                <p
                  className={`swapUnitWantDsSub txtAlignRight noBorder ${
                    memoVals.mobModal ? "font12" : ""
                  }`}
                >
                  {dateStringer.formatMinsDurationToHours(swap.offeredDurMins)}
                </p>
              </div>
            )}
            {/* {swap.offerTags && swap.offerTags[0] ? (
              <div className="wantShiftTags">
                <p className="wantShiftTagsTitle txtAlignRight">Tags:</p>
                <div className="wantShiftTagsList">{generateOfferTags}</div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div
          className={`swapUnitCtaBar teamSwapUnitCtaBar ${
            swap.selfMade ? "x92478922" : ""
          }`}
        >
          <p className="swapDsMade">
            Requested {dateStringer.dsToTimeStrip(swap.dsMade)}
            <br />
            {dateStringer.printedDateFromDs(swap.dsMade)}
          </p>
          <div
            className={`swapCtaBtns ${swap.autoApprovable ? "flexEnd" : ""}`}
          >
            {swap.mgrApproved ? (
              ""
            ) : (
              <p
                className="swapDecline"
                onClick={() => {
                  deny(swap, swaps);
                }}
              >
                Deny
              </p>
            )}
            {swap.mgrApproved ? (
              ""
            ) : (
              <p
                className={`teamSwapAccept ${
                  swap.autoApprovable ? "none" : ""
                }`}
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/approve-swap`,
                      {
                        wantType: swap.wantedType,
                        shiftID: swap.wantedTypeID,
                        wantUserID: swap.offeredUserID,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                        returnShiftData: window.location.href.includes("rota")
                          ? true
                          : false,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setTeamSwapsQty(
                          (x) => memoVals.teamSwapsQty - 1
                        );
                        if (response.data.mgrApproved) {
                          let newArr = [];
                          swaps.map((s) => {
                            // let sObj = s;
                            if (
                              s.wantedTypeID === swap.wantedTypeID &&
                              s.offeredUserID === swap.offeredUserID
                            ) {
                              s["mgrApproved"] = response.data.mgrApproved;
                            }
                            newArr.push(s);
                          });

                          setSwaps(sortSwaps(newArr));
                        } else {
                          if (response.data.swapInitiated) {
                            // remove it because the swap has been initiated
                            let newArr = [];
                            swaps.map((s) => {
                              // let sObj = s;
                              if (
                                s.wantedTypeID === swap.wantedTypeID &&
                                s.offeredUserID === swap.offeredUserID
                              ) {
                              } else {
                                newArr.push(s);
                              }
                            });

                            setSwaps(sortSwaps(newArr));

                            if (window.location.href.includes("rota")) {
                              memoVals.setDynamicSwap((x) => {
                                return {
                                  getRidOfTypeIDs:
                                    response.data.getRidOfTypeIDs,
                                  newItem1: response.data.newItem1,
                                  newItem2: response.data.newItem2,
                                };
                              });
                            }
                          }
                        }
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });

                  memoVals.setIndicate((x) => {
                    return {
                      show: true,
                      message: `Approved swap between ${swap.wantedFName} and ${swap.offeredFName}`,
                      colour: "blue",
                      duration: 4000,
                      ts: new Date().getTime(),
                    };
                  });
                }}
              >
                Approve
              </p>
            )}
            {swap.mgrApproved ? (
              <div className="swapApprovedAndDeclineDiv">
                <div
                  className={`swapMgrApprovedTxt ${
                    swap.selfMade ? "x2984212" : ""
                  }`}
                >
                  {swap.selfMade ? "Auto-approved" : "Approved"} by
                  {swap.selfMade ? <br /> : " "}
                  {swap.mgrApproved}
                  {/* <img
                    src={unitTick}
                    className="swapAcceptTickImg"
                    alt="Accepted"
                  /> */}
                </div>
                <div
                  className="declineSwapOnceApproved"
                  onClick={() => {
                    setSureDeny({
                      show: true,
                      fName1: swap.offeredFName,
                      fName2: swap.wantedFName,
                      item: swap,
                      items: swaps,
                    });
                  }}
                >
                  Deny
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {swap.wantedApproved ? (
          <div className="usrApprovedSwap">
            {swap.wantedFName} has accepted{" "}
            <img src={unitTick} className="swapAcceptTickImg" alt="Accepted" />
          </div>
        ) : (
          <div
            className={`usrApprovedSwap notYetAccept ${
              memoVals.mobModal ? "mobNotYetAccept" : ""
            } ${swap.selfMade ? "x2498293" : ""}`}
          >
            {swap.wantedFName} yet to accept{" "}
          </div>
        )}
        {swap.autoApprovable ? (
          <div
            className={`mgrSwapApprovalNotRequired ${
              !memoVals.mobModal ? "mgrSwapApprovalNotRequiredNonMob" : ""
            } ${swap.selfMade ? "x29845922" : ""}`}
          >
            Manager approval not required
          </div>
        ) : (
          ""
        )}
      </div>
    );
  });

  let generateTeams = teams
    .sort((a, b) => {
      return a.teamName.localeCompare(b.teamName);
    })
    .map((team) => {
      return (
        <option key={team.teamID} value={team.teamID}>
          {team.teamName}
        </option>
      );
    });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-team-swaps`,
        {
          teamID: selectedTeamID,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setAllowTeamSwaps(response.data.allowSwaps);
          setAutoApprove(response.data.autoApprove);
          if (selectedTeamID === "allStaff") {
            let qty = 0;
            response.data.output.forEach((sw) => {
              if (!sw.mgrApproved) {
                qty++;
              }
            });
            memoVals.setTeamSwapsQty((x) => qty);
          }

          response.data.output.sort(
            (a, b) =>
              dateStringer.createTimestampFromString(a.dsMade) -
              dateStringer.createTimestampFromString(b.dsMade)
          );

          setSwaps(sortSwaps(response.data.output));
          setDataLoaded(true);
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedTeamID]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`mySwapsModal ${
            memoVals.mobModal ? "mobModalShoulder" : ""
          } mgrModal`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperTeamSwaps"></div>
          )}
          <div
            className="mySwapsHeader"
            onClick={() => {}}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p>Your staff shift swap requests</p>

            <p></p>
          </div>

          <div
            className="teamSwapsContainer"
            onClick={() => {
              console.log(teams);
              // console.log(dateStringer.printMonthAndYearFromMy(my));
            }}
          >
            <div className="staffSwapTeamDropContainer">
              <p></p>

              <select
                onChange={(e) => {
                  setLoaded(false);
                  setSelectedTeamID(e.target.value);
                }}
                className="swapTeamDropdown"
              >
                <option value="allStaff">All your teams</option>
                {generateTeams}
              </select>
            </div>

            {selectedTeamID === "allStaff" ? (
              <p className="selectTeamToChangeSwapSettings">
                Select a team to edit each team's swap settings
              </p>
            ) : (
              <div className="staffSwapTeamSubContainer">
                {allowTeamSwaps ? (
                  <div
                    className={`allowTeamSwapsBtnHolder ${
                      memoVals.mobModal ? "requireMgrApp" : ""
                    } ${!autoApprove ? "allowSwapsMyRotaBtnActive" : ""}`}
                    onClick={() => {
                      if (selectedTeamID) {
                        setAutoApprove(!autoApprove);
                        axios
                          .post(
                            `${serverURL}/amend-team-swap-settings`,
                            {
                              teamID: selectedTeamID,
                              autoApprove: !autoApprove,
                              allowSwaps: allowTeamSwaps,
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              let newArr = [];

                              swaps.forEach((swap) => {
                                let newObj = swap;
                                newObj["autoApprovable"] =
                                  response.data.doc.autoApprove || false;
                                newArr.push(swap);
                              });

                              setSwaps(newArr);

                              setAutoApprove(response.data.doc.autoApprove);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Require approval from manager {/* KNOB BEGIN */}
                    <div
                      className={`editDayFxSchedKnob ${
                        memoVals.mobModal ? "allowSwapsTeamKnob" : ""
                      }`}
                    >
                      <div className="knobContainer allowSwapsKnobMyRota marginRight3 x10s00">
                        <div
                          className={`switchKnob ${
                            !autoApprove ? "knobOn allowSwapsKnobOn" : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg allowSwapsKnobBg"></div>
                      </div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`allowTeamSwapsBtnHolder ${
                    allowTeamSwaps ? "allowSwapsMyRotaBtnActive" : ""
                  }`}
                  onClick={() => {
                    if (selectedTeamID) {
                      setAllowTeamSwaps(!allowTeamSwaps);
                      axios
                        .post(
                          `${serverURL}/amend-team-swap-settings`,
                          {
                            teamID: selectedTeamID,
                            autoApprove: autoApprove,
                            allowSwaps: !allowTeamSwaps,
                          },
                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setAllowTeamSwaps(response.data.doc.allowSwaps);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Allow swaps {/* KNOB BEGIN */}
                  <div className="editDayFxSchedKnob">
                    <div className="knobContainer allowSwapsKnobMyRota marginRight3 x10s00">
                      <div
                        className={`switchKnob ${
                          allowTeamSwaps ? "knobOn allowSwapsKnobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg allowSwapsKnobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
              </div>
            )}
          </div>

          <div className="mySwapsContainer">
            {showHistory ? (
              <p className="showHistTxt">
                Showing swap history from the last 120 days
              </p>
            ) : (
              ""
            )}
            {showHistory ? (
              historyArr.map((hist, i) => {
                return (
                  <div
                    className={`histSwap ${
                      i === historyArr.length - 1 ? "noBorder" : ""
                    }`}
                  >
                    <p className="histSwapTxt">
                      <span className="makeBold">
                        {hist.buyerUserFName} {hist.buyerUserLName}
                      </span>{" "}
                      wanted{" "}
                      <span className="makeBold">
                        {dateStringer.possession(
                          `${hist.sellerFName} ${hist.sellerLName}`
                        )}{" "}
                        {hist.sellerType === "til"
                          ? "lieu shift"
                          : hist.sellerType}
                      </span>{" "}
                      scheduled for{" "}
                      <span className="makeBold">
                        {dateStringer.printedDateFromDs(
                          hist.sellerStartDs,
                          true
                        )}
                      </span>{" "}
                      starting{" "}
                      <span className="makeBold">
                        {dateStringer.dsToTimeStrip(hist.sellerStartDs)}
                      </span>{" "}
                      and ending{" "}
                      <span className="makeBold">
                        {dateStringer.dsToTimeStrip(hist.sellerEndDs)}
                      </span>{" "}
                      in return for{" "}
                      {hist.offerNothing ? (
                        <>
                          <span className="makeBold">nothing</span>
                        </>
                      ) : (
                        <>
                          <span className="makeBold">
                            {dateStringer.possession(hist.buyerUserFName)}{" "}
                            {hist.buyerType === "til"
                              ? "lieu shift"
                              : hist.buyerType}
                          </span>{" "}
                          scheduled for{" "}
                          <span className="makeBold">
                            {dateStringer.printedDateFromDs(
                              hist.buyerStartDs,
                              true
                            )}
                          </span>{" "}
                          starting{" "}
                          <span className="makeBold">
                            {dateStringer.dsToTimeStrip(hist.buyerStartDs)}
                          </span>{" "}
                          and ending{" "}
                          <span className="makeBold">
                            {dateStringer.dsToTimeStrip(hist.buyerEndDs)}
                          </span>
                        </>
                      )}
                      .
                    </p>
                    <p className="histTeamName">{hist.teamName}</p>
                    {hist.approved ? (
                      <p className="histSub">
                        This swap was{" "}
                        <span className="swapGreen">approved</span> and
                        initiated
                      </p>
                    ) : (
                      ""
                    )}
                    {hist.declined ? (
                      <p className="histSub">
                        This swap was <span className="swapRed">declined</span>{" "}
                        by {hist.actionedFName} {hist.actionedLName}
                      </p>
                    ) : (
                      ""
                    )}
                    {hist.cancelled ? (
                      <p className="histSub">
                        This swap was <span className="swapRed">cancelled</span>{" "}
                        by {hist.actionedFName} {hist.actionedLName}
                      </p>
                    ) : (
                      ""
                    )}
                    {hist.denied ? (
                      <p className="histSub">
                        This swap was <span className="swapRed">denied</span> by{" "}
                        {hist.actionedFName} {hist.actionedLName}
                      </p>
                    ) : (
                      ""
                    )}

                    <p className="swapHistoryDs">
                      {dateStringer.printedDateWithTimeFromDs(hist.dsMade)}
                    </p>
                  </div>
                );
              })
            ) : showApprovalInfo && swaps.length > 0 ? (
              <div className="staffSwapInfo">
                Approving your staff's swap requests won't initiate a swap until
                the staff member approves the swap as well.
              </div>
            ) : (
              ""
            )}
            {!loaded ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="loadingSwaps"
              />
            ) : (
              ""
            )}
            {!loaded ? (
              ""
            ) : swaps.length === 0 && !showHistory ? (
              <p className="noSwapsTxt">
                Shift swap requests made amongst {getTeamName(selectedTeamID)}{" "}
                will appear here. There are no swaps yet.
              </p>
            ) : showHistory ? (
              ""
            ) : (
              generateSwaps
            )}
          </div>
          <div className="mySwapsModalFooter">
            <p
              className="pubShiftBtn histSwapBtn"
              onClick={() => {
                setShowHistory(!showHistory);
              }}
            >
              Historical{" "}
              <img
                src={showHistory ? publishTick2 : publishTick1}
                className="histCheck"
                alt="Publish"
              />
            </p>

            <p
              className="closeSeeUserIDBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {sureDeny.show ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureDeny({ show: false });
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to deny this swap request between{" "}
              {sureDeny.fName1} and {sureDeny.fName2}?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  deny(sureDeny.item, sureDeny.items);

                  setSureDeny({ show: false });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeny({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default TeamSwapsModal;
