import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import serverURL from "../../serverURL";
import dateStringer from "../../tools/dateStringer";
import towerLogo from "../tower/img/towerLogo.png";
import { TowerContext } from "../../contexts/TowerContext";

const TowerSidebar = () => {
  const { fName, setFName, page, setPage } = useContext(TowerContext);

  //   useEffect(() => {
  //     console.log("Getting tower user info");
  //     axios
  //       .post(
  //         `${serverURL}/get-tower-data-init`,
  //         { someData: 1 },

  //         {
  //           withCredentials: true,
  //           credentials: "include",
  //         }
  //       )
  //       .then((response) => {
  //         if (response.data.message2 === "success") {
  //           setFName(response.data.fName);
  //         }
  //       });
  //   }, []);
  return (
    <div className="towerSidebar">
      <div className="towerMenuContainer">
        <div
          className="towerMenuRow"
          onClick={() => {
            setPage("dashboard");
          }}
        >
          <div
            className={`${
              page === "dashboard"
                ? "towerSelctedMenuAccent"
                : "towerSelctedMenuAccent towerInvisAccent"
            }`}
          >
            &nbsp;
          </div>
          <p
            className={`towerMenuTxt ${
              page === "dashboard" ? "whiteColTowerMenu" : ""
            }`}
          >
            Dashboard
          </p>
        </div>
        <div
          className="towerMenuRow"
          onClick={() => {
            setPage("tasks");
          }}
        >
          <div
            className={`${
              page === "tasks"
                ? "towerSelctedMenuAccent"
                : "towerSelctedMenuAccent towerInvisAccent"
            }`}
          >
            &nbsp;
          </div>
          <p
            className={`towerMenuTxt ${
              page === "tasks" ? "whiteColTowerMenu" : ""
            }`}
          >
            Tasks
          </p>
        </div>
        <div
          className="towerMenuRow"
          onClick={() => {
            setPage("clients");
          }}
        >
          <div
            className={`${
              page === "clients"
                ? "towerSelctedMenuAccent"
                : "towerSelctedMenuAccent towerInvisAccent"
            }`}
          >
            &nbsp;
          </div>
          <p
            className={`towerMenuTxt ${
              page === "clients" ? "whiteColTowerMenu" : ""
            }`}
          >
            Clients
          </p>
        </div>
        <div
          className="towerMenuRow"
          onClick={() => {
            setPage("billing");
          }}
        >
          <div
            className={`${
              page === "billing"
                ? "towerSelctedMenuAccent"
                : "towerSelctedMenuAccent towerInvisAccent"
            }`}
          >
            &nbsp;
          </div>
          <p
            className={`towerMenuTxt ${
              page === "billing" ? "whiteColTowerMenu" : ""
            }`}
          >
            Billing
          </p>
        </div>
        <div
          className="towerMenuRow"
          onClick={() => {
            setPage("notepad");
          }}
        >
          <div
            className={`${
              page === "notepad"
                ? "towerSelctedMenuAccent"
                : "towerSelctedMenuAccent towerInvisAccent"
            }`}
          >
            &nbsp;
          </div>
          <p
            className={`towerMenuTxt ${
              page === "notepad" ? "whiteColTowerMenu" : ""
            }`}
          >
            Notepad
          </p>
        </div>
        <div
          className="towerMenuRow"
          onClick={() => {
            setPage("settings");
          }}
        >
          <div
            className={`${
              page === "settings"
                ? "towerSelctedMenuAccent"
                : "towerSelctedMenuAccent towerInvisAccent"
            }`}
          >
            &nbsp;
          </div>
          <p
            className={`towerMenuTxt ${
              page === "settings" ? "whiteColTowerMenu" : ""
            }`}
          >
            Settings
          </p>
        </div>
        <div
          className="towerMenuRow"
          onClick={() => {
            setPage("promotions");
          }}
        >
          <div
            className={`${
              page === "promotions"
                ? "towerSelctedMenuAccent"
                : "towerSelctedMenuAccent towerInvisAccent"
            }`}
          >
            &nbsp;
          </div>
          <p
            className={`towerMenuTxt ${
              page === "promotions" ? "whiteColTowerMenu" : ""
            }`}
          >
            Promotions
          </p>
        </div>
      </div>
    </div>
  );
};

export default TowerSidebar;
