import React from 'react';
import { NavLink } from 'react-router-dom';

const Forgot = () => {
  return (
    <div>
      Forgotten your password? oh no...
      <NavLink to='/'>
        <button>Back</button>
      </NavLink>
    </div>
  );
};

export default Forgot;
 