import React, { useContext, useState, useEffect, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import dateStringer from "../../../tools/dateStringer";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { UserContext } from "../../../contexts/UserContext";
import { AdminContext } from "../../../contexts/AdminContext";

import { StyleContext } from "../../../contexts/StyleContext";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import serverURL from "../../../serverURL";

// import special days subcomponent
import SpecialDays from "./subcomponents/SpecialDays";

// Ignore public holidays
// if disabled, show: region, rate, business shut, pay even if shut
// aka Public Holidays tab in admin in react
const ClosedDays = ({ sureDelete, setSureDelete }) => {
  const { checkUserState, currencySymbol } = useContext(UserContext);
  const {
    enablePublicHols,
    setEnablePublicHols,
    closedPublicHols,
    setClosedPublicHols,
    payPublicHols,
    setPayPublicHols,
    publicHolExclusions,
    setPublicHolExclusions,
    setSpecialDaysData,
  } = useContext(AdminContext);

  const memoVals = useMemo(
    () => ({
      checkUserState,
      currencySymbol,
      enablePublicHols,
      setEnablePublicHols,
      closedPublicHols,
      setClosedPublicHols,
      payPublicHols,
      setPayPublicHols,
      publicHolExclusions,
      setPublicHolExclusions,
      setSpecialDaysData,
    }),
    [
      checkUserState,
      currencySymbol,
      enablePublicHols,
      setEnablePublicHols,
      closedPublicHols,
      setClosedPublicHols,
      payPublicHols,
      setPayPublicHols,
      publicHolExclusions,
      setPublicHolExclusions,
      setSpecialDaysData,
    ]
  );

  let [publicHolsRegion, setPublicHolsRegion] = useState("Englang & Wales");
  let [publicHolsRate, setPublicHolsRate] = useState(1);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded) {
      setLoading(false);
    }
  }, [dataLoaded]);

  const { setAccNav, mob } = useContext(StyleContext);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    setAccNav("admin");
  }, []);

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/closed-days";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-public-holiday-admin-data`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          memoVals.setEnablePublicHols((x) => response.data.enablePublicHols);
          memoVals.setClosedPublicHols(
            (x) => response.data.closedDuringPublicHols
          );
          memoVals.setPayPublicHols((x) => response.data.payPublicHols);
          setPublicHolsRegion(response.data.region);
          memoVals.setPublicHolExclusions(
            (x) => response.data.availablePublicHols
          );
          setPublicHolsRate(response.data.publicHolsRate);

          axios
            .get(`${serverURL}/get-special-days`, {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource2.token,
            })
            .then((response) => {
              if (!response.data.error) {
                let dataToAdd = [];
                response.data.specialDaysArray.forEach((item) => {
                  if (item.date) {
                    dataToAdd.push({
                      name: item.name,
                      _id: item._id,
                      date: item.date,
                      rate: item.rate,
                      companyClosed: item.companyClosed,
                      closedPay: item.closedPay,
                      tsForOrder: dateStringer.createTimestampFromString(
                        item.date
                      ),
                    });
                  }
                });
                memoVals.setSpecialDaysData((x) => dataToAdd);
                setDataLoaded(true);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, []);

  let generatePublicHolExclusions =
    memoVals.publicHolExclusions &&
    memoVals.publicHolExclusions.map((excl, i) => {
      return (
        <div
          key={i}
          className="exclusionUnit"
          key={excl.title}
          onClick={() => {
            axios
              .post(
                `${serverURL}/add-or-remove-public-hol-exclusion`,
                {
                  addOrRemove: excl.enabled ? "add" : "remove",
                  title: excl.title,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  let newArr = [];
                  memoVals.publicHolExclusions.forEach((ph) => {
                    if (ph.title === excl.title) {
                      newArr.push({
                        title: excl.title,
                        enabled: excl.enabled ? false : true,
                      });
                    } else {
                      newArr.push(ph);
                    }
                  });

                  memoVals.setPublicHolExclusions((x) => newArr);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          <p className="exclusionTitle">{excl.title}</p>
          <div className="excluKnobIgnoreContainer">
            {/*  KNOB BEGIN */}
            <div className="knobContainer exclusionKnob">
              <div
                className={`switchKnob ${excl.enabled ? "knobOn" : "knobOff"}`}
              ></div>
              <div className="knobBg"></div>
            </div>
            {/*  KNOB END */}
            <p
              className={`${
                excl.enabled ? "ignoreExcluActive" : "ignoreExcluInactive"
              }`}
            >
              {excl.enabled ? "Active" : "Inactive"}
            </p>
          </div>
        </div>
      );
    });

  return (
    <div
      className={`AdminSettingsContainer ${
        mob ? "AdminSettingsContainerMob" : "borderRightGrey"
      }`}
    >
      <div className="whiteGradientAdmin"></div>

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          <div
            className={`${
              memoVals.enablePublicHols || !memoVals.enablePublicHols
                ? `AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
                    mob ? "mobileAdminRow" : ""
                  }`
                : "none"
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-public-hols-company-closed`,
                  { data: !memoVals.closedPublicHols },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    axios
                      .post(
                        `${serverURL}/add-or-remove-public-hol-exc`,
                        { someData: 1 },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          memoVals.setEnablePublicHols(
                            (x) => response.data.enablePublicHols
                          );
                          memoVals.setClosedPublicHols(
                            (x) => response.data.closedDuringPublicHols
                          );
                          memoVals.setPayPublicHols(
                            (x) => response.data.payPublicHols
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">
                  Closed during{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"} holidays
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Is{" "}
                  {memoVals.checkUserState.companyName || "your organisation"}{" "}
                  closed on{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"}{" "}
                  holidays? If so, FlowRota won't schedule staff on these days.{" "}
                  <br />
                  <br />
                  <span className="makeBold">Paid days off</span> will be
                  created for employees who are contracted to work on a day that
                  falls on a closed{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"} holiday
                  (when generating weekly rotas).
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer  ">
                  <div
                    className={`switchKnob ${
                      memoVals.closedPublicHols ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>

          {/* END OF ROW ------------------------------------------------------------------------ */}

          <div
            className={`${
              memoVals.closedPublicHols
                ? `AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
                    mob ? "mobileAdminRow" : ""
                  }`
                : "none"
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-pay-public-hols`,
                  { data: !memoVals.payPublicHols },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    axios
                      .post(
                        `${serverURL}/get-public-holiday-admin-data`,
                        { someData: 1 },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          memoVals.setEnablePublicHols(
                            (x) => response.data.enablePublicHols
                          );
                          memoVals.setClosedPublicHols(
                            (x) => response.data.closedDuringPublicHols
                          );
                          memoVals.setPayPublicHols(
                            (x) => response.data.payPublicHols
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Public holidays are paid</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Are your employees (whose contracted days fall on{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"}{" "}
                  holidays) paid even if{" "}
                  {memoVals.checkUserState.companyName || "your organisation"}{" "}
                  is shut? For example, if an employee is contracted to work
                  Mondays, but{" "}
                  {memoVals.checkUserState.companyName || "your business"} is
                  shut due to it being a{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"} holiday,
                  will the employee still be paid?
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer  ">
                  <div
                    className={`switchKnob ${
                      memoVals.payPublicHols ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>

          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}

          <div className={`AdminRowContainer ${mob ? "mobileAdminRow" : ""}`}>
            <div className="settingsRow pHolRegionRow">
              <p className="pHolRegTitleTxt">
                {memoVals.currencySymbol === "£" ? "Bank" : "Public"} holidays
                region
              </p>
              <div>
                {/* <PublicHolsList
                publicHolsRegion={publicHolsRegion}
                setPublicHolsRegion={setPublicHolsRegion}
              /> */}
                <select
                  className="publicHolsDropDown"
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/select-public-hols-region`,
                        {
                          data: val,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setPublicHolsRegion(response.data.publicHolsRegion);

                          axios
                            .post(
                              `${serverURL}/get-available-public-hols`,
                              {
                                someData: 1,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setPublicHolExclusions(
                                  (x) => response.data.newArr
                                );
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  value={publicHolsRegion}
                  placeholder={publicHolsRegion}
                >
                  <option value="None">Please choose</option>
                  <option value="England &amp; Wales">
                    England &amp; Wales
                  </option>
                  <option value="Scotland">Scotland</option>
                  <option value="Northern Ireland">Northern Ireland</option>
                </select>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div className={`AdminRowContainer ${mob ? "mobileAdminRow" : ""}`}>
            <div className="settingsRow pHolRegionRow">
              <p className="pHolRegTitleTxt">
                {memoVals.currencySymbol === "£" ? "Bank" : "Public"} holiday
                rate
              </p>
              <div>
                <select
                  className="publicHolsDropDown rateDropDownShortened"
                  onChange={(e) => {
                    setPublicHolsRate(e.target.value);

                    axios
                      .post(
                        `${serverURL}/public-hol-rate`,
                        {
                          data: e.target.value,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.error) {
                          console.log("Could not update public hols rate");
                        } else {
                          console.log(
                            "updated the pHols rate region successfully"
                          );
                        }
                      });
                  }}
                  value={publicHolsRate}
                  placeholder={publicHolsRate}
                >
                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>
              </div>
            </div>
          </div>

          {/* ROW ------------------------------------------------------------------------ */}

          <div className={`AdminRowContainer ${mob ? "mobileAdminRow" : ""}`}>
            <div className="settingsRow">
              <p className="pubHolExclusionTitle">
                {memoVals.currencySymbol === "£" ? "Bank" : "Public"} holidays
              </p>
              <div className="holExclusionsContainer">
                {generatePublicHolExclusions}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          {/* ROW ------------------------------------------------------------------------ */}

          <div className={`AdminRowContainer ${mob ? "mobileAdminRow" : ""}`}>
            <div className="settingsRow">
              <p className="settingTitleWithQuery">Special days</p>
            </div>

            <div className="specialDaysHolder">
              <SpecialDays
                sureDelete={sureDelete}
                setSureDelete={setSureDelete}
              />
            </div>

            {/* <SpecialDays /> */}
          </div>

          {/* ROW ------------------------------------------------------------------------ */}
          <br />
        </div>
      )}
    </div>
  );
};

export default ClosedDays;
