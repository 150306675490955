import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import "../../../styles/settings.css";

import { UserContext } from "../../../contexts/UserContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";

import serverURL from "../../../serverURL";

const Admins = () => {
  const {
    fName,
    setFName,
    lName,
    jobTitle,
    companyName,
    email,
    permissions,
    toggleNotifications,
    setToggleNotifications,
  } = useContext(UserContext);

  const permissionsText = (p) => {
    if (p === 1) {
      return "Admin";
    } else if (p === 2) {
      return "Manager";
    } else {
      return "User";
    }
  };

  const { accNav, setAccNav } = useContext(StyleContext);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    setAccNav("admin");
  }, []);

  const {
    textInputTitle,
    setTextInputTitle,
    textInputEndpoint,
    setTextInputEndpoint,
    textInputPlaceholder,
    setTextInputPlaceholder,
    postInputPath,
    setPostInputPath,
    inputIsPassword,
    setInputIsPassword,
  } = useContext(DataContext);

  const formatEmail = (email) => {
    let emailStr = email.email || "email address";
    if (emailStr.length > 16) {
      return `${emailStr.substring(0, 20)}...`;
    } else {
      return emailStr;
    }
  };

  return <div></div>;
};

export default Admins;
