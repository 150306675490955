import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import "../../styles/leaveRequests.css";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";

import CheckUser from "../../tools/CheckUser";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import MyReqsDataBox from "./MyReqsDataBox";
import MgrReqsDataBox from "./MgrReqsDataBox";
import TopBar from "../../components/TopBar";

import Navbar from "../Navbar";
import serverURL from "../../serverURL";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import closeGrey from "../../img/general/closeGrey.svg";
import redTriangle from "../../img/general/redTriangle.svg";
import whiteBack2 from "../../img/general/whiteBack2.svg";
import plane from "../../img/general/plane.svg";
import whiteGo from "../../img/general/whiteGo.svg";
import plane2 from "../../img/general/plane2.svg";
import popupNavy from "../../img/general/popupNavy.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";

import whiteAddThin from "../../img/general/whiteAddThin.svg";

const LeaveReqsPage = ({ isTeam }) => {
  CheckUser();
  const {
    setSelectedNav,
    reqsPage,
    setReqsPage,
    mgrTeams,
    setMgrTeams,
    teamHasPendingReqs,
    setTeamHasPendings,
    teamHasDecidedReqs,
    setTeamHasDecidedReqs,
    selectedTeam,
    setSelectedTeam,
    updateRequests,
    updateMgrReqSingleReqID,
    setUpdateMgrReqSingleReqID,
    setPage,
    showReqStats,
    setShowReqStats,
    setShowUserReqFromNav,
    updatedReq,
    showReqMgrModal,
    setShowReqMgrModal,
    deletingReqID,
    device,
    setShowAddLeaveOnTeam,
    selectedYear,
    setSelectedYear,
    showReqModal,
    updateUserReqs,
  } = useContext(DataContext);

  const { mob, full, width, wide, fullButNarrow } = useContext(StyleContext);
  const { updatedItem } = useContext(CalendarContext);

  const memoVals = useMemo(
    () => ({
      setSelectedNav,
      reqsPage,
      setReqsPage,
      mgrTeams,
      setMgrTeams,
      teamHasPendingReqs,
      setTeamHasPendings,
      teamHasDecidedReqs,
      setTeamHasDecidedReqs,
      selectedTeam,
      setSelectedTeam,
      updateRequests,
      updateMgrReqSingleReqID,
      setUpdateMgrReqSingleReqID,
      setPage,
      showReqStats,
      setShowReqStats,
      setShowUserReqFromNav,
      updatedReq,
      showReqMgrModal,
      setShowReqMgrModal,
      deletingReqID,
      device,
      setShowAddLeaveOnTeam,
      selectedYear,
      setSelectedYear,
      showReqModal,
      mob,
      full,
      width,
      wide,
      fullButNarrow,
      updatedItem,
      updateUserReqs,
    }),
    [
      setSelectedNav,
      reqsPage,
      setReqsPage,
      mgrTeams,
      setMgrTeams,
      teamHasPendingReqs,
      setTeamHasPendings,
      teamHasDecidedReqs,
      setTeamHasDecidedReqs,
      selectedTeam,
      setSelectedTeam,
      updateRequests,
      updateMgrReqSingleReqID,
      setUpdateMgrReqSingleReqID,
      setPage,
      showReqStats,
      setShowReqStats,
      setShowUserReqFromNav,
      updatedReq,
      showReqMgrModal,
      setShowReqMgrModal,
      deletingReqID,
      device,
      setShowAddLeaveOnTeam,
      selectedYear,
      setSelectedYear,
      showReqModal,
      mob,
      full,
      width,
      wide,
      fullButNarrow,
      updatedItem,
      updateUserReqs,
    ]
  );

  let showReqs = useRef();
  // let upcomingRef = useRef();
  // let pastRef = useRef();
  // let declinedRef = useRef();

  const { checkUserState, currencySymbol } = useContext(UserContext);

  let [pendingRequests, setPendingRequests] = useState([]);
  let [upcomingRequests, setUpcomingRequests] = useState([]);
  let [pastRequests, setPastRequests] = useState([]);
  let [declinedRequests, setDeclinedRequests] = useState([]);
  let [mgrRequests, setMgrRequests] = useState([]);

  let [initial, setInitial] = useState(false);

  useEffect(() => {
    if (isTeam) {
      document.title = "FlowRota - Staff leave";
      memoVals.setReqsPage((x) => "team");
    } else {
      document.title = "FlowRota - My leave";

      memoVals.setPage((x) => "upcoming");
    }
  }, [isTeam]);

  let [teamDeclined, setTeamDeclined] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.reqID) {
      memoVals.setShowUserReqFromNav((x) => params.reqID);
      window.history.pushState({}, document.title, window.location.pathname);
    }

    if (params) {
      console.log(
        "mgrReqID is present ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ",
        params
      );
      memoVals.setShowReqMgrModal((x) => params.mgrReqID);
      window.history.pushState({}, document.title, window.location.pathname);
    }

    if (
      params &&
      params.userID &&
      params.fName &&
      params.lName &&
      !memoVals.full
    ) {
      memoVals.setReqsPage((x) => {
        return {
          userID: params.userID,
          fName: params.fName,
          lName: params.lName,
        };
      });
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let outstandingExists = (arr) => {
    let count = 0;
    arr.forEach((r) => {
      if (!r.approved) {
        // console.log("lll", r);

        count++;
      }
    });

    if (count > 0) {
      return true;
    }
  };

  // let [showAddLeaveOnTeam, setShowAddLeaveOnTeam] = useState(false);

  useEffect(() => {
    setShowFilter(false);
    memoVals.setSelectedTeam((x) => "allstaff");
  }, [memoVals.reqsPage]);

  let [showFilter, setShowFilter] = useState(false);

  let [yearsArr, setYearsArr] = useState([new Date().getFullYear()]);

  useEffect(() => {
    if (!memoVals.selectedYear) {
      memoVals.setSelectedYear(
        (x) => checkUserState.currClientYear || new Date().getFullYear()
      );
    }
  }),
    [];

  let [selectedType, setSelectedType] = useState("allTypes");
  let [types, setTypes] = useState([]);
  // modal to edit/delete request
  types &&
    types.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

  let [showDupeModal, setShowDupeModal] = useState(false);

  let [showMgrHistory, setShowMgrHistory] = useState(false);

  // filter states
  let [showReqFilter, setShowReqFilter] = useState(false);
  let [allNames, setAllNames] = useState([]); // array of objects w/ fName, lName and userID
  let [filterUserID, setFilterUserID] = useState("everyone");

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={profilePicturePlaceholder}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popupNavy}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={closeGrey}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={redTriangle}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteBack2}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plane}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plane2}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      {/* <img
        src={popup2}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      /> */}
      <img
        src={whiteAddThin}
        alt="8"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let shortenLeaveName = (string) => {
    if (window.innerWidth < 400) {
      if (string.length > 17) {
        return `${string.substr(0, 15)}...`;
      } else {
        return string;
      }
    }
    if (string.length > 21) {
      return `${string.substr(0, 19)}...`;
    } else {
      return string;
    }
    if (string) {
      if (memoVals.mob || memoVals.full) {
        if (string.length > 17) {
          return `${string.substr(0, 15)}...`;
        } else {
          return string;
        }
      } else {
        if (memoVals.width < 1160) {
          if (string.length > 22) {
            return `${string.substr(0, 19)}...`;
          } else {
            return string;
          }
        } else {
          return string;
        }
      }
    }
  };

  // let formatMinsDurationToHours = (mins) => {
  //   if (mins === 0) {
  //     return "None";
  //   }
  //   let hours = Math.floor(mins / 60);
  //   let m = mins % 60;

  //   let hString = "hr";
  //   let mString = "m";

  //   if (m === 0) {
  //     mString = "";
  //     m = "";
  //   } else if (m === 1) {
  //     mString = "m";
  //   }

  //   if (hours === 0) {
  //     hString = "";
  //     hours = "";
  //     mString = "mins";
  //   } else if (hours === 1) {
  //     hString = "hr";
  //   }

  //   if (!m && hours > 1) {
  //     hString = " hours";
  //   } else if (!m && hours === 1) {
  //     hString = " hour";
  //   }
  //   if (memoVals.mob) {
  //     if (!m) {
  //       return `${hours}h`;
  //     } else {
  //       return `${hours}h ${m}m`;
  //     }
  //   } else {
  //     return `${hours}${hString}${hString && mString ? " " : ""}${
  //       m === "" ? m : Math.round(m)
  //     }${!hString && mins < 60 ? " " : ""}${mString}`;
  //   }
  // };

  let tonight2359 = new Date();
  tonight2359.setHours(23, 59, 59, 0);
  let today0000 = new Date();
  today0000.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (!memoVals.reqsPage.userID && memoVals.reqsPage !== "team") {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-all-user-requests`,
          {
            someData: 1,
            status: memoVals.reqsPage,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: memoVals.selectedYear,
            getCosts: memoVals.mob ? false : true,
            // singleReqID: showUserReqModal
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setYearsArr(response.data.yearsArr);
            setTypes(response.data.types);
            console.log("SETTING TYPES:", new Date().getTime());

            let pendingArr = [];
            response.data.array.forEach((req) => {
              if (!req.declined && !req.approved) {
                pendingArr.push(req);
              }
            });
            pendingArr.sort((a, b) => {
              return (
                dateStringer.createTimestampFromString(a.reqStart) -
                dateStringer.createTimestampFromString(b.reqStart)
              );
            });
            setPendingRequests(pendingArr);

            let upcomingArr = [];
            response.data.array.forEach((req) => {
              if (
                dateStringer.createTimestampFromString(req.reqEnd) >=
                  today0000 &&
                !req.declined &&
                req.approved
              ) {
                upcomingArr.push(req);
              }
            });
            upcomingArr.sort((a, b) => {
              return (
                dateStringer.createTimestampFromString(a.reqStart) -
                dateStringer.createTimestampFromString(b.reqStart)
              );
            });
            setUpcomingRequests(upcomingArr);

            let pastArr = [];
            response.data.array.forEach((req) => {
              if (
                dateStringer.createTimestampFromString(req.reqEnd) <
                  today0000 &&
                !req.declined &&
                req.approved
              ) {
                pastArr.push(req);
              }
            });
            pastArr.sort((a, b) => {
              return (
                dateStringer.createTimestampFromString(b.reqStart) -
                dateStringer.createTimestampFromString(a.reqStart)
              );
            });
            setPastRequests(pastArr);

            let declinedArr = [];
            response.data.array.forEach((req) => {
              if (req.declined && !req.approved) {
                declinedArr.push(req);
              }
            });
            declinedArr.sort((a, b) => {
              return (
                dateStringer.createTimestampFromString(a.reqStart) -
                dateStringer.createTimestampFromString(b.reqStart)
              );
            });
            setDeclinedRequests(declinedArr);
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.reqsPage, memoVals.selectedYear, memoVals.updateUserReqs]);

  useEffect(() => {
    memoVals.setSelectedNav((x) => 3.1);

    memoVals.setUpdateMgrReqSingleReqID((x) => "");
  }, [memoVals.updateRequests, memoVals.reqsPage]);

  let [runUpdate, setRunUpdate] = useState(false);

  let [upcomingMgrApprovedCount, setUpcomingMgrApprovedCount] = useState(0);
  useEffect(() => {
    if (memoVals.reqsPage === "team" || memoVals.reqsPage.userID) {
      const cancelSource1 = axios.CancelToken.source();
      const cancelSource2 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-teams-user-manages`,
          { someData: 1 },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data.message === "success") {
            memoVals.setMgrTeams((x) => response.data.array);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      // get mgr's requests
      let status = "upcoming";
      if (showMgrHistory && !teamDeclined && !memoVals.reqsPage.userID) {
        status = "past";
      }
      if (showMgrHistory && teamDeclined && !memoVals.reqsPage.userID) {
        status = "declined";
      }

      axios
        .post(
          `${serverURL}/get-manager-requests-long`,
          {
            singleReqID: memoVals.updateMgrReqSingleReqID,
            year: memoVals.selectedYear,
            status,
            today0000: dateStringer.createStringFromTimestamp(
              today0000.getTime()
            ),
            userID: memoVals.reqsPage.userID || false,
            getCosts: memoVals.mob ? false : true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message === "no permissions") {
            window.location.href = "/requests";
          }
          if (response.data.message === "success") {
            setYearsArr(response.data.yearsArr);

            if (!initial) {
              memoVals.setSelectedYear((x) => response.data.yearUsed);
              setInitial(true);
            }

            let arr = [];
            let arr2 = [];
            let usersArr = [];

            let types = [];
            let typeIDs = [];
            let appr = 0;
            response.data.array.forEach((req) => {
              if (!typeIDs.includes(req.leaveTypeID)) {
                typeIDs.push(req.leaveTypeID);
                types.push({
                  name: req.leaveName || "-",
                  typeID: req.leaveTypeID,
                });
              }

              if (status === "upcoming") {
                if (req.approved && !req.declined) {
                  appr++;
                }
              }

              if (!req.approved && !req.declined) {
                arr.push(req.teamID);
                arr.push("allstaff");
                usersArr.push({
                  fName: req.fName,
                  lName: req.lName,
                  userID: req.userID,
                });
              } else if (req.approved || req.declined) {
                arr2.push(req.teamID);
                arr2.push("allstaff");
                usersArr.push({
                  fName: req.fName,
                  lName: req.lName,
                  userID: req.userID,
                  teamID: req.teamID,
                });
              }
            });
            if (dataLoaded) {
              setDataLoaded(false);
            }

            setDataLoaded(true);
            if (status === "upcoming") {
              setUpcomingMgrApprovedCount(appr);
            } else {
              setUpcomingMgrApprovedCount(0);
            }
            setTypes(types);
            setAllNames(response.data.allNames);

            // if (!memoVals.reqsPage.userID) {
            //   if (memoVals.updateMgrReqSingleReqID) {
            //     let uniqueArrayOfNames = usersArr.filter(
            //       (thing, index, self) =>
            //         index === self.findIndex((t) => t.userID === thing.userID)
            //     );
            //     setAllNames(uniqueArrayOfNames);
            //   } else {
            //     setAllNames(response.data.allNames);
            //   }
            // }
            memoVals.teamHasPendingReqs.forEach((r) => {
              if (r && r.reqID !== memoVals.updateMgrReqSingleReqID) {
                arr.push(r);
              }
            });
            memoVals.setTeamHasPendings((x) => arr);

            memoVals.teamHasDecidedReqs.forEach((r) => {
              if (r && r.reqID !== memoVals.updateMgrReqSingleReqID) {
                arr2.push(r);
              }
            });
            memoVals.setTeamHasDecidedReqs((x) => arr2);

            // let arr3 = response.data.array || [];

            if (memoVals.updateMgrReqSingleReqID) {
              mgrRequests.forEach((r) => {
                if (r && r.reqID !== memoVals.updateMgrReqSingleReqID) {
                  arr3.push(r);
                }
              });
            }

            // setMgrRequests(response.data.array || []);

            if (memoVals.updateMgrReqSingleReqID) {
              mgrRequests.forEach((req) => {});
              if (
                !response.data.array[0].approved &&
                !response.data.array[0].declined
              ) {
              }
            } else {
              response.data.array.forEach((req) => {
                if (!req.approved && !req.declined) {
                }
              });
            }

            setMgrRequests(response.data.array);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [
    memoVals.reqsPage,
    runUpdate,
    showMgrHistory,
    teamDeclined,
    memoVals.deletingReqID,
    memoVals.selectedYear,
  ]);

  useEffect(() => {
    if (
      memoVals.updatedReq.reqID ||
      (memoVals.updatedReq.updatedObj && memoVals.updatedReq.updatedObj.reqID)
    ) {
      if (memoVals.updatedReq.delete) {
        if (memoVals.updatedReq.pending) {
          let newArr = [];
          pendingRequests.forEach((r) => {
            if (r.reqID !== memoVals.updatedReq.reqID) {
              newArr.push(r);
            }
          });
          setPendingRequests(newArr);
        }

        if (memoVals.updatedReq.approved) {
          let newArr = [];
          upcomingRequests.forEach((r) => {
            if (r.reqID !== memoVals.updatedReq.reqID) {
              newArr.push(r);
            }
          });
          setUpcomingRequests(newArr);
        }

        if (memoVals.updatedReq.declined) {
          let newArr = [];
          declinedRequests.forEach((r) => {
            if (r.reqID !== memoVals.updatedReq.reqID) {
              newArr.push(r);
            }
          });
          setDeclinedRequests(newArr);
        }
      }

      if (
        memoVals.updatedReq.updatedObj &&
        memoVals.updatedReq.updatedObj.makePending
      ) {
        // remove from all user arrays, and ensure is added to pendings array
        let declinedArr = [];
        declinedRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            declinedArr.push(r);
          }
        });
        setDeclinedRequests(declinedArr);

        let pastArr = [];
        pastRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            pastArr.push(r);
          }
        });
        setPastRequests(declinedArr);

        let upcomingArr = [];
        upcomingRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            upcomingArr.push(r);
          }
        });
        setUpcomingRequests(upcomingArr);

        let pendingArr = [];
        pendingRequests.forEach((r) => {
          if (r.reqID !== memoVals.updatedReq.updatedObj.reqID) {
            pendingArr.push(r);
          } else {
          }
        });
        pendingArr.push(memoVals.updatedReq.updatedObj);
        setPendingRequests(pendingArr);
      }
    }
  }, [memoVals.updatedReq]);

  useEffect(() => {
    if (memoVals.updatedItem) {
      let arr3 = [];

      mgrRequests.forEach((req) => {
        if (req.reqID === memoVals.updatedItem.reqID) {
          if (!memoVals.updatedItem.delete) {
            let newItem = memoVals.updatedItem;
            let filt = mgrRequests.filter((x) => {
              return x.reqID === memoVals.updatedItem.reqID;
            });
            if (filt[0]) {
              newItem["picUrl"] = filt[0].picUrl;
            }
            arr3.push(memoVals.updatedItem);
          }
        } else {
          arr3.push(req);
        }
      });

      setMgrRequests(arr3);
      // setUpdatedItem({});

      arr3.forEach((req) => {
        if (!req.approved && !req.declined) {
        }
      });
    }
  }, [memoVals.updatedItem]);

  useEffect(() => {
    if (showReqs.current) {
      showReqs.current.scrollTo(0, 0);
    }
  }, [
    memoVals.reqsPage,
    memoVals.selectedTeam,
    memoVals.selectedYear,
    selectedType,
    teamDeclined,
    showMgrHistory,
    filterUserID,
  ]);

  let generateTeamSelectBar = React.useMemo(() => {
    let shortenTeamName = (val) => {
      if (val.length > 19) {
        return `${val.substr(0, 17)}...`;
      } else {
        return val;
      }
    };
    return memoVals.mgrTeams.map((item) => {
      return (
        <option key={item.teamID} value={item.teamID}>
          {memoVals.mob ? shortenTeamName(item.teamName) : item.teamName}
        </option>
      );
    });
  }, [memoVals.mgrTeams]);

  let createDate = (start, end) => {
    if (start && end) {
      let startDate = parseInt(start.substr(9, 2));
      let startMonth = parseInt(start.substr(6, 2));
      let endDate = parseInt(end.substr(9, 2));
      let endMonth = parseInt(end.substr(6, 2));
      let yr = `'${JSON.stringify(parseInt(end.substr(1, 4))).substr(2, 2)}`;
      let startMonthOutput = "";
      if (startMonth === 0) {
        startMonthOutput = "Jan";
      } else if (startMonth === 1) {
        startMonthOutput = "Feb";
      } else if (startMonth === 2) {
        startMonthOutput = "Mar";
      } else if (startMonth === 3) {
        startMonthOutput = "Apr";
      } else if (startMonth === 4) {
        startMonthOutput = "May";
      } else if (startMonth === 5) {
        startMonthOutput = "Jun";
      } else if (startMonth === 6) {
        startMonthOutput = "Jul";
      } else if (startMonth === 7) {
        startMonthOutput = "Aug";
      } else if (startMonth === 8) {
        startMonthOutput = "Sep";
      } else if (startMonth === 9) {
        startMonthOutput = "Oct";
      } else if (startMonth === 10) {
        startMonthOutput = "Nov";
      } else if (startMonth === 11) {
        startMonthOutput = "Dec";
      }

      let endMonthOutput = "";
      if (endMonth === 0) {
        endMonthOutput = "Jan";
      } else if (endMonth === 1) {
        endMonthOutput = "Feb";
      } else if (endMonth === 2) {
        endMonthOutput = "Mar";
      } else if (endMonth === 3) {
        endMonthOutput = "Apr";
      } else if (endMonth === 4) {
        endMonthOutput = "May";
      } else if (endMonth === 5) {
        endMonthOutput = "Jun";
      } else if (endMonth === 6) {
        endMonthOutput = "Jul";
      } else if (endMonth === 7) {
        endMonthOutput = "Aug";
      } else if (endMonth === 8) {
        endMonthOutput = "Sep";
      } else if (endMonth === 9) {
        endMonthOutput = "Oct";
      } else if (endMonth === 10) {
        endMonthOutput = "Nov";
      } else if (endMonth === 11) {
        endMonthOutput = "Dec";
      }

      if (memoVals.reqsPage === "team" && !showMgrHistory && !teamDeclined) {
        // ramadan
        if (startMonthOutput !== endMonthOutput) {
          return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput}`;
        } else if (
          startMonthOutput === endMonthOutput &&
          startDate === endDate
        ) {
          return `${startDate} ${endMonthOutput}`;
        } else {
          return `${startDate} - ${endDate} ${endMonthOutput}`;
        }
      } else {
        if (startMonthOutput !== endMonthOutput) {
          return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput} ${yr}`;
        } else if (
          startMonthOutput === endMonthOutput &&
          startDate === endDate
        ) {
          return `${startDate} ${endMonthOutput} ${yr}`;
        } else {
          return `${startDate} - ${endDate} ${endMonthOutput} ${yr}`;
        }
      }
    }
  };

  mgrRequests.sort((a, b) => {
    if (!memoVals.reqsPage.userID) {
      return (
        dateStringer.createTimestampFromString(a.reqStart) -
        dateStringer.createTimestampFromString(b.reqStart)
      );
    } else {
      return (
        dateStringer.createTimestampFromString(b.reqStart) -
        dateStringer.createTimestampFromString(a.reqStart)
      );
    }
  });

  let pendMgrQty = 0;

  if (!showMgrHistory && !teamDeclined && mgrRequests[0]) {
    mgrRequests.forEach((item) => {
      if (!item.approved && !item.declined) {
        pendMgrQty++;
      }
    });
  }

  let pendingMgrReqs = mgrRequests.filter(
    (item) => !item.approved && !item.declined
  );

  let generateMgrReqsPENDING = React.useMemo(() => {
    return pendingMgrReqs.map((item) => {
      let durationString = () => {
        let exc = 0;
        if (item.excessMins) {
          exc = item.excessMins;
        }
        let zMins = item.zMins ? item.zMins : 0;
        let val = Math.round(exc) + Math.round(zMins);
        if (val < 60) {
          return `${val}m`;
        }
        return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
      };

      if (
        memoVals.selectedTeam === item.teamID ||
        memoVals.selectedTeam === "allstaff"
      ) {
        if (filterUserID === item.userID || filterUserID === "everyone") {
          return (
            <div className="reqUnitParent">
              <div
                onClick={() => {
                  memoVals.setShowReqMgrModal((x) => item.reqID);
                }}
                className={`mgrReqItem pend ${
                  memoVals.mob ? "borderRadius10" : ""
                } leaveReqItem`}
                key={item.reqID}
              >
                {" "}
                <div className="unsubmittedTsBlob annLeaveBlob"></div>
                <div className="reqPlaneDiv">
                  <img
                    src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                    alt="Requested leave"
                    className={`reqPlaneIcon ${
                      memoVals.mob ? "reqPlaneIconMob" : ""
                    }`}
                  />
                </div>
                <div className="reqColBarDiv divPend"></div>
                <div className="mgrReqItemCol2">
                  <div className="mgrReqTopRow">
                    <div className="mgrReqName">
                      {createDate(item.reqStart, item.reqEnd)}{" "}
                      {/* {createDate(item.reqStart, item.reqEnd).length >= 15 ? (
                      <br />
                    ) : (
                      ""
                    )} */}
                      {/* {item.fName} {item.lName[0]} */}
                      {item.limitedExceeded ? (
                        <img
                          src={redTriangle}
                          alt="Understaffed warning"
                          className="requestsPageUnderstaffedWarnImg"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="mgrReqTypeName">
                      {shortenLeaveName(item.leaveName)}
                    </p>
                  </div>
                  <div className="mgrReqBottomRow">
                    <p
                      className={`mgrReqDateStrip ${
                        createDate(item.reqStart, item.reqEnd).length > 14
                          ? "mgrReqDateStripLong"
                          : ""
                      }`}
                    >
                      {/* {createDate(item.reqStart, item.reqEnd)}{" "}
                    {createDate(item.reqStart, item.reqEnd).length >= 15 ? (
                      <br />
                    ) : (
                      ""
                    )} */}
                      <span
                        className={`mgrReqDayLengthSpan upcomingMgrReqsSpan `}
                      >
                        {item.days} day
                        {item.days !== 1 ? "s" : ""}
                      </span>
                    </p>
                    <div className="mgrReqDurAndCostDiv">
                      {item.salaried && memoVals.mob && 2 + 2 === 5 ? (
                        ""
                      ) : (
                        <p
                          className={`mgrReqDurTxt ${
                            item.ongoing ? "ongoingTab" : ""
                          }`}
                          onClick={() => {
                            console.log(item);
                          }}
                        >
                          {item.ongoing ? "Ongoing" : durationString()}
                        </p>
                      )}
                      {item.unpaid ? (
                        <p className="mgrReqCostTxt unpaidCostColours">
                          Unpaid
                        </p>
                      ) : item.salaried && !item.ongoing ? (
                        <p
                          className={`mgrReqCostTxt  ${
                            memoVals.mob ? "none" : ""
                          } `}
                        >
                          {memoVals.mob ? "Sal" : "Salary"}{" "}
                          {item.cost > 0 ? (
                            <span className="reqSalPlusSpan">
                              + {currencySymbol}
                              {Math.round(item.cost)}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        <p
                          className={`mgrReqCostTxt  ${
                            memoVals.mob ? "none" : ""
                          }  ${item.ongoing ? "none" : ""}`}
                        >
                          {currencySymbol}
                          {Math.round(item.cost)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="itemTongue leaveTongue">
                  <p className="tongueTxt leaveTongueTxt">Leave</p>
                </div>
              </div>
              <div className={`mgrReqNameTab pendClockName`}>
                <img
                  src={item.picUrl || profilePicturePlaceholder}
                  alt={item.fName}
                  className="itemNameTabImg"
                />{" "}
                {item.fName} {item.lName}
              </div>
            </div>
          );
        }
      }
    });
  }, [pendingMgrReqs, memoVals.selectedTeam, filterUserID]);

  if (showMgrHistory) {
    mgrRequests.sort((a, b) => {
      return (
        dateStringer.createTimestampFromString(b.reqStart) -
        dateStringer.createTimestampFromString(a.reqStart)
      );
    });
  } else {
  }

  let mgrPrevQty = 0;
  let mgrDeclinedQty = 0;
  if (
    memoVals.reqsPage === "team" &&
    !memoVals.reqsPage.userID &&
    showMgrHistory &&
    !teamDeclined
  ) {
    let approvedFilt = mgrRequests.filter((obj) => obj.approved);
    approvedFilt.forEach((item) => {
      if (dateStringer.createTimestampFromString(item.reqEnd) < today0000) {
        mgrPrevQty++;
      }
    });
  }

  if (
    memoVals.reqsPage === "team" &&
    !memoVals.reqsPage.userID &&
    showMgrHistory &&
    teamDeclined
  ) {
    let declinedFilt = mgrRequests.filter((obj) => obj.declined);
    declinedFilt.forEach((item) => {
      mgrDeclinedQty++;
    });
  }

  let generateMgrReqs = React.useMemo(() => {
    return mgrRequests.map((item) => {
      if (
        (item.approved && showMgrHistory && !teamDeclined) ||
        (item.declined && showMgrHistory && teamDeclined) ||
        (!showMgrHistory && item.approved) ||
        memoVals.reqsPage.userID
      ) {
        let durationString = () => {
          let exc = 0;
          if (item.excessMins) {
            exc = item.excessMins;
          }
          let zMins = item.zMins ? item.zMins : 0;
          let val = Math.round(exc) + Math.round(zMins);
          if (val < 60) {
            return `${val}m`;
          }
          return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
        };

        let shortenLeaveName = (string) => {
          if (string) {
            if (memoVals.mob) {
              if (string.length > 20) {
                return `${string.substr(0, 17)}...`;
              } else {
                return string;
              }
            } else {
              if (string.length > 25) {
                return `${string.substr(0, 22)}...`;
              } else {
                return string;
              }
            }
          }
        };

        if (
          memoVals.selectedTeam === item.teamID ||
          memoVals.selectedTeam === "allstaff"
        ) {
          if (filterUserID === item.userID || filterUserID === "everyone") {
            return (
              <div className="reqUnitParent">
                <div
                  onClick={() => {
                    memoVals.setShowReqMgrModal((x) => item.reqID);
                  }}
                  className={`mgrReqItem ${
                    item.approved
                      ? "leftBorderGreen"
                      : `${
                          item.declined && !item.ongoing
                            ? "leftBorderRed"
                            : "pendMgr"
                        }`
                  } mgrReqItemNonPendHover  ${
                    memoVals.mob ? "borderRadius10" : ""
                  } leaveReqItem`}
                  key={item.reqID}
                >
                  {" "}
                  <div className="reqPlaneDiv">
                    <img
                      src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                      alt="Requested leave"
                      className={`reqPlaneIcon ${
                        memoVals.mob ? "reqPlaneIconMob" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`reqColBarDiv ${item.declined ? "divDec" : ""}`}
                  ></div>
                  <div className="mgrReqItemCol2">
                    <div className="mgrReqTopRow">
                      <div className="mgrReqName">
                        {createDate(item.reqStart, item.reqEnd)}

                        {/* {item.fName} {item.lName[0]} */}
                        {item.limitedExceeded ? (
                          <img
                            src={redTriangle}
                            alt="Understaffed warning"
                            className="requestsPageUnderstaffedWarnImg"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <p className="mgrReqTypeName">
                        {shortenLeaveName(item.leaveName)}
                      </p>
                    </div>
                    <div className="mgrReqBottomRow">
                      <p className={`mgrReqDateStrip `}>
                        {/* {createDate(item.reqStart, item.reqEnd)} */}
                        <span
                          className={`mgrReqDayLengthSpan ${
                            !showMgrHistory && !teamDeclined
                              ? "upcomingMgrReqsSpan"
                              : ""
                          }`}
                        >
                          {item.days} day
                          {item.days !== 1 ? "s" : ""}
                        </span>
                      </p>
                      <div className="mgrReqDurAndCostDiv">
                        {item.salaried && memoVals.mob && 2 + 2 === 5 ? (
                          ""
                        ) : (
                          <p
                            className={`mgrReqDurTxt ${
                              item.ongoing ? "ongoingTab" : ""
                            }`}
                            onClick={() => {
                              console.log(item);
                            }}
                          >
                            {item.ongoing ? "Ongoing" : durationString()}
                          </p>
                        )}
                        {item.unpaid ? (
                          <p className="mgrReqCostTxt unpaidCostColours">
                            Unpaid
                          </p>
                        ) : item.salaried && !item.ongoing ? (
                          <p
                            className={`mgrReqCostTxt  ${
                              memoVals.mob || item.unpaid ? "none" : ""
                            } `}
                          >
                            {memoVals.mob ? "Sal" : "Salary"}{" "}
                            {item.cost > 0 ? (
                              <span className="reqSalPlusSpan">
                                + {currencySymbol}
                                {Math.round(item.cost)}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        ) : (
                          <p
                            className={`mgrReqCostTxt  ${
                              memoVals.mob ? "none" : ""
                            }  ${item.ongoing ? "none" : ""}`}
                          >
                            {currencySymbol}
                            {Math.round(item.cost)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`itemTongue leaveTongue${
                      item.declined ? "Declined" : ""
                    }`}
                  >
                    <p className="tongueTxt leaveTongueTxt">Leave</p>
                  </div>
                </div>
                <div className={`mgrReqNameTab`}>
                  <img
                    src={item.picUrl || profilePicturePlaceholder}
                    alt={item.fName}
                    className="itemNameTabImg"
                  />{" "}
                  {item.fName} {item.lName}
                </div>
              </div>
            );
          }
        }
      }
    });
  }, [mgrRequests, memoVals.selectedTeam, filterUserID]);

  let generatePendingRequests = React.useMemo(() => {
    return pendingRequests.map((item) => {
      if (selectedType === "allTypes" || selectedType === item.leaveTypeID) {
        let durationString = () => {
          let exc = 0;
          if (item.excessMins) {
            exc = item.excessMins;
          }
          let zMins = item.zMins ? item.zMins : 0;
          let val = Math.round(exc) + Math.round(zMins);
          if (val < 60) {
            return `${val}m`;
          }
          return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
        };

        let countdown = () => {
          let startDateObj = new Date(
            dateStringer.createTimestampFromString(item.reqStart)
          );
          let todayObj = new Date();
          todayObj.setHours(0, 0, 0, 0);

          let dayDiff = Math.round(
            (startDateObj.getTime() - todayObj.getTime()) / 86400000
          );

          if (dayDiff === 1) {
            return "To start tomorrow";
          } else {
            if (dayDiff === 0) {
              return "To start today";
            } else {
              if (dayDiff < 0) {
                let val = dayDiff * -1;

                if (val === 1) {
                  return `To start yesterday`;
                } else {
                  return `To start ${val} days ago`;
                }
              } else {
                return `To start in ${dayDiff} days`;
              }
            }
          }
        };
        return (
          <div
            onClick={() => {
              memoVals.setShowUserReqFromNav((x) => item.reqID);
            }}
            className={`mgrReqItem pendClock  ${
              memoVals.mob ? "borderRadius10" : ""
            } leaveReqItem`}
            key={item.reqID}
          >
            {" "}
            <div className="reqPlaneDiv">
              <img
                src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                alt="Requested leave"
                className={`reqPlaneIcon ${
                  memoVals.mob ? "reqPlaneIconMob" : ""
                }`}
              />
            </div>{" "}
            <div className="reqColBarDiv divPend"></div>
            <div
              className={`mgrReqItemCol2 ${
                !item.approved && !item.declined ? "pendingReqBord" : ""
              }`}
            >
              <div className="mgrReqTopRow">
                {/* <p className="mgrReqName">{item.leaveTypeName}</p> */}

                <div className="mgrReqName">
                  {shortenLeaveName(item.leaveTypeName)}
                  {item.limitExceeded ? (
                    <img
                      src={redTriangle}
                      alt="Understaffed warning"
                      className="requestsPageUnderstaffedWarnImg"
                    />
                  ) : (
                    ""
                  )}
                </div>

                {/* {countdown().includes("Started") ||
                countdown().includes("ago") ? (
                  ""
                ) : (
                  <p
                    className={`mgrReqTypeName startsInTxt ${
                      !item.unpaid ? "x19844381" : ""
                    }`}
                  >
                    {countdown()}
                  </p>
                )} */}
                <p className="pendReqDash">Pending</p>
              </div>
              <div className="mgrReqBottomRow">
                <p
                  className={`mgrReqDateStrip ${
                    createDate(item.reqStart, item.reqEnd).length > 14
                      ? "mgrReqDateStripLong"
                      : ""
                  }`}
                >
                  {createDate(item.reqStart, item.reqEnd)}{" "}
                  {createDate(item.reqStart, item.reqEnd).length >= 15 ? (
                    <br />
                  ) : (
                    ""
                  )}
                  <span
                    className={`usrReqDayLengthSpan ${
                      createDate(item.reqStart, item.reqEnd).length >= 15
                        ? "mgrReqDayLengthSpanNewLine"
                        : ""
                    }`}
                  >
                    {item.days} day
                    {item.days !== 1 ? "s" : ""}
                  </span>
                </p>
                <div className="mgrReqDurAndCostDiv">
                  {item.salaried && memoVals.mob && 2 + 2 === 5 ? (
                    ""
                  ) : (
                    <p
                      className={`mgrReqDurTxt ${
                        item.ongoing ? "ongoingTab" : ""
                      }`}
                      onClick={() => {
                        console.log(item);
                      }}
                    >
                      {item.ongoing ? "Ongoing" : durationString()}
                    </p>
                  )}
                  {item.unpaid ? (
                    <p className="mgrReqCostTxt unpaidCostColours">Unpaid</p>
                  ) : !checkUserState.showCosts ? (
                    ""
                  ) : item.salaried && !item.ongoing ? (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      } `}
                    >
                      {memoVals.mob ? "Sal" : "Salary"}{" "}
                      {item.cost > 0 ? (
                        <span className="reqSalPlusSpan">
                          + {currencySymbol}
                          {Math.round(item.cost)}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      }  ${item.ongoing ? "none" : ""}`}
                    >
                      {currencySymbol}
                      {Math.round(item.cost)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="itemTongue leaveTongue">
              <p className="tongueTxt leaveTongueTxt">Leave</p>
            </div>
          </div>
          // <div
          //   className="requestUnitApproved"
          //   key={item.reqID}
          //   onClick={() => {
          //     setShowUserReqModal(item.reqID);
          //   }}
          // >
          //   <div className="reqContentContainerApproved">
          //     <div className="approvedLeft">
          //       <img src={unitTick} className="unitImgIcon" alt="tick" />
          //     </div>
          //     <div className="approvedMiddle">
          //       <p className="leaveTypeNameTxt">
          //         {" "}
          //         {shortenLeaveName(item.leaveTypeName)}
          //       </p>
          //       <p className="reqUnitDateRangeTxt">
          //         {createDate(item.reqStart, item.reqEnd)}
          //       </p>
          //     </div>
          //     <div className="pendingRight">
          //       <div className="contractedTotal">
          //         {durationStringEstimatedTotal()}
          //       </div>

          //       {item.cost ? (
          //         <p
          //           className={`${
          //             memoVals.reqsPage === "past" ? "userReqCostTxt" : "userReqCostTxtPast"
          //           } ${item.declined ? "none" : ""}`}
          //         >
          //           {currencySymbol}
          //           {Math.floor(item.cost)}
          //         </p>
          //       ) : (
          //         ""
          //       )}
          //     </div>
          //   </div>
          // </div>
        );
      }
    });
  }, [pendingRequests, selectedType]);

  let generateApprovedRequests = React.useMemo(() => {
    return upcomingRequests.map((item) => {
      if (selectedType === "allTypes" || selectedType === item.leaveTypeID) {
        let durationString = () => {
          let exc = 0;
          if (item.excessMins) {
            exc = item.excessMins;
          }
          let zMins = item.zMins ? item.zMins : 0;
          let val = Math.round(exc) + Math.round(zMins);
          if (val < 60) {
            return `${val}m`;
          }
          return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
        };
        let countdown = () => {
          let startDateObj = new Date(
            dateStringer.createTimestampFromString(item.reqStart)
          );
          let todayObj = new Date();
          todayObj.setHours(0, 0, 0, 0);

          let dayDiff = Math.round(
            (startDateObj.getTime() - todayObj.getTime()) / 86400000
          );

          if (dayDiff === 1) {
            return "Starts tomorrow";
          } else {
            if (dayDiff === 0) {
              return "Starts today";
            } else {
              if (dayDiff < 0) {
                let val = dayDiff * -1;

                if (val === 1) {
                  return `Started yesterday`;
                } else {
                  return `Started ${val} days ago`;
                }
              } else {
                return `Starts in ${dayDiff} days`;
              }
            }
          }
        };
        return (
          <div
            onClick={() => {
              memoVals.setShowUserReqFromNav((x) => item.reqID);
              console.log(
                "item.leaveTypeID: ",
                item.leaveTypeID,
                "selectedTypeID: ",
                selectedType
              );
            }}
            className={`mgrReqItem 
            ${
              item.approved
                ? "leftBo rderGreen"
                : `${
                    item.declined
                      ? "leftBor derRed"
                      : "mgrReqItemP endingBorder"
                  }`
            }
            ${item.ongoing ? "pend" : ""}  ${
              memoVals.mob ? "borderRadius10" : ""
            } leaveReqItem`}
            key={item.reqID}
          >
            <div className="reqPlaneDiv">
              <img
                src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                alt="Requested leave"
                className={`reqPlaneIcon ${
                  memoVals.mob ? "reqPlaneIconMob" : ""
                }`}
              />
            </div>
            <div className="reqColBarDiv"></div>

            <div className="mgrReqItemCol2">
              <div className="mgrReqTopRow">
                <div className="mgrReqName">
                  {shortenLeaveName(item.leaveTypeName)}
                  {item.limitExceeded ? (
                    <img
                      src={redTriangle}
                      alt="Understaffed warning"
                      className="requestsPageUnderstaffedWarnImg"
                    />
                  ) : (
                    ""
                  )}
                </div>{" "}
                {countdown().includes("Started") ||
                countdown().includes("ago") ? (
                  ""
                ) : (
                  <p
                    className={`mgrReqTypeName startsInTxt ${
                      !item.unpaid ? "x19844381" : ""
                    }`}
                  >
                    {countdown()}
                  </p>
                )}
              </div>
              <div className="mgrReqBottomRow">
                <p
                  className={`mgrReqDateStrip ${
                    createDate(item.reqStart, item.reqEnd).length > 14
                      ? "mgrReqDateStripLong"
                      : ""
                  }`}
                >
                  {createDate(item.reqStart, item.reqEnd)}{" "}
                  {createDate(item.reqStart, item.reqEnd).length >= 15 ? (
                    <br />
                  ) : (
                    ""
                  )}
                  <span
                    className={`usrReqDayLengthSpan ${
                      createDate(item.reqStart, item.reqEnd).length >= 15
                        ? "mgrReqDayLengthSpanNewLine"
                        : ""
                    }`}
                  >
                    {item.days} day
                    {item.days !== 1 ? "s" : ""}
                  </span>
                </p>
                <div className="mgrReqDurAndCostDiv">
                  {item.salaried && memoVals.mob && 2 + 2 === 5 ? (
                    ""
                  ) : (
                    <p
                      className={`mgrReqDurTxt ${
                        item.ongoing ? "ongoingTab" : ""
                      }`}
                      onClick={() => {
                        console.log(item.zMins);
                      }}
                    >
                      {item.ongoing ? "Ongoing" : durationString()}
                    </p>
                  )}
                  {item.unpaid ? (
                    <p className="mgrReqCostTxt unpaidCostColours">Unpaid</p>
                  ) : !checkUserState.showCosts ? (
                    ""
                  ) : item.salaried && !item.ongoing ? (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      } `}
                    >
                      {memoVals.mob ? "Sal" : "Salary"}{" "}
                      {item.cost > 0 ? (
                        <span className="reqSalPlusSpan">
                          + {currencySymbol}
                          {Math.round(item.cost)}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      }  ${item.ongoing ? "none" : ""}`}
                    >
                      {currencySymbol}
                      {Math.round(item.cost)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="itemTongue leaveTongue">
              <p className="tongueTxt leaveTongueTxt">Leave</p>
            </div>
          </div>
        );
      }
    });
  }, [upcomingRequests, selectedType, memoVals.selectedTeam]);

  let generateTypes = React.useMemo(() => {
    return types.map((t) => {
      return <option value={t.leaveTypeID}>{t.name}</option>;
    });
  }, [types]);

  declinedRequests.sort((a, b) => {
    return (
      dateStringer.createTimestampFromString(b.reqStart) -
      dateStringer.createTimestampFromString(a.reqStart)
    );
  });
  let generateDeclinedRequests = React.useMemo(() => {
    return declinedRequests.map((item) => {
      if (selectedType === "allTypes" || selectedType === item.leaveTypeID) {
        let durationString = () => {
          let exc = 0;
          if (item.excessMins) {
            exc = item.excessMins;
          }
          let zMins = item.zMins ? item.zMins : 0;
          let val = Math.round(exc) + Math.round(zMins);
          if (val < 60) {
            return `${val}m`;
          }
        };

        return (
          <div
            onClick={() => {
              console.log(item);
              memoVals.setShowUserReqFromNav((x) => item.reqID);
            }}
            className={`mgrReqItem ${
              item.approved
                ? "leftBor derGreen"
                : `${
                    item.declined
                      ? "leftB orderRed"
                      : "mgrReqItemPen dingBorder"
                  }`
            }  ${memoVals.mob ? "borderRadius10" : ""} leaveReqItem`}
            key={item.reqID}
          >
            {" "}
            <div className="reqPlaneDiv">
              <img
                src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                alt="Requested leave"
                className={`reqPlaneIcon ${
                  memoVals.mob ? "reqPlaneIconMob" : ""
                }`}
              />
            </div>
            <div className="reqColBarDiv divDec"></div>
            <div className="mgrReqItemCol2">
              <div className="mgrReqTopRow">
                <p className="mgrReqName">
                  {shortenLeaveName(item.leaveTypeName)}
                </p>
                <p
                  className={`mgrReqTypeName startsInTxt ${
                    !item.unpaid ? "x19844381" : ""
                  }`}
                ></p>
              </div>
              <div className="mgrReqBottomRow">
                <p
                  className={`mgrReqDateStrip ${
                    createDate(item.reqStart, item.reqEnd).length > 14
                      ? "mgrReqDateStripLong"
                      : ""
                  }`}
                >
                  {createDate(item.reqStart, item.reqEnd)}{" "}
                  {createDate(item.reqStart, item.reqEnd).length >= 15 ? (
                    <br />
                  ) : (
                    ""
                  )}
                  <span
                    className={`usrReqDayLengthSpan ${
                      createDate(item.reqStart, item.reqEnd).length >= 15
                        ? "mgrReqDayLengthSpanNewLine"
                        : ""
                    }`}
                  >
                    {item.days} day
                    {item.days !== 1 ? "s" : ""}
                  </span>
                </p>
                <div className="mgrReqDurAndCostDiv"></div>
              </div>
            </div>
            <div className="itemTongue leaveTongueDeclined">
              <p className="tongueTxt leaveTongueTxt">Leave</p>
            </div>
          </div>
        );
      }
    });
  }, [declinedRequests, selectedType]);

  pastRequests.sort((a, b) => {
    return (
      dateStringer.createTimestampFromString(b.reqStart) -
      dateStringer.createTimestampFromString(a.reqStart)
    );
  });
  let generatePastRequests = useMemo(() => {
    return pastRequests.map((item) => {
      if (selectedType === "allTypes" || selectedType === item.leaveTypeID) {
        let durationString = () => {
          let exc = 0;
          if (item.excessMins) {
            exc = item.excessMins;
          }
          let zMins = item.zMins ? item.zMins : 0;
          let val = Math.round(exc) + Math.round(zMins);
          if (val < 60) {
            return `${val}m`;
          }
          return `${dateStringer.formatMinsDurationToHours(val, false, true)}`;
        };

        let countdown = () => {
          let startDateObj = new Date(
            dateStringer.createTimestampFromString(item.reqStart)
          );
          let todayObj = new Date();
          todayObj.setHours(0, 0, 0, 0);

          let dayDiff = Math.round(
            (startDateObj.getTime() - todayObj.getTime()) / 86400000
          );

          if (dayDiff === 1) {
            return "Tomorrow";
          } else {
            if (dayDiff === 0) {
              return "Today";
            } else {
              if (dayDiff < 0) {
                let val = dayDiff * -1;

                if (val === 1) {
                  return `Yesterday`;
                } else {
                  return `${val} days ago`;
                }
              } else {
                return `${dayDiff} days`;
              }
            }
          }
        };
        return (
          <div
            onClick={() => {
              memoVals.setShowUserReqFromNav((x) => item.reqID);
            }}
            className={`mgrReqItem ${
              item.approved
                ? "leftBorder Green"
                : `${
                    item.declined
                      ? "leftBor derRed"
                      : "mgrReqItemPe ndingBorder"
                  }`
            }  ${memoVals.mob ? "borderRadius10" : ""} leaveReqItem`}
            key={item.reqID}
          >
            {" "}
            <div className="reqPlaneDiv">
              <img
                src={item.leaveTypeID === "annLeave" ? plane2 : plane}
                alt="Requested leave"
                className={`reqPlaneIcon ${
                  memoVals.mob ? "reqPlaneIconMob" : ""
                }`}
              />
            </div>
            <div className="reqColBarDiv"></div>
            <div className="mgrReqItemCol2">
              <div className="mgrReqTopRow">
                <p className="mgrReqName">
                  {shortenLeaveName(item.leaveTypeName)}
                </p>
                {countdown().includes("Started") ||
                countdown().includes("ago") ? (
                  ""
                ) : (
                  <p
                    className={`mgrReqTypeName startsInTxt ${
                      !item.unpaid ? "x19844381" : ""
                    }`}
                  >
                    {countdown()}
                  </p>
                )}
              </div>
              <div className="mgrReqBottomRow">
                <p
                  className={`mgrReqDateStrip ${
                    createDate(item.reqStart, item.reqEnd).length > 14
                      ? "mgrReqDateStripLong"
                      : ""
                  }`}
                >
                  {createDate(item.reqStart, item.reqEnd)}{" "}
                  {createDate(item.reqStart, item.reqEnd).length >= 15 ? (
                    <br />
                  ) : (
                    ""
                  )}
                  <span
                    className={`usrReqDayLengthSpan ${
                      createDate(item.reqStart, item.reqEnd).length >= 15
                        ? "mgrReqDayLengthSpanNewLine"
                        : ""
                    }`}
                  >
                    {item.days} day
                    {item.days !== 1 ? "s" : ""}
                  </span>
                </p>
                <div className="mgrReqDurAndCostDiv">
                  {item.salaried && memoVals.mob && 2 + 2 === 5 ? (
                    ""
                  ) : (
                    <p
                      className={`mgrReqDurTxt ${
                        item.ongoing ? "ongoingTab" : ""
                      }`}
                      onClick={() => {
                        console.log(item);
                      }}
                    >
                      {item.ongoing ? "Ongoing" : durationString()}
                    </p>
                  )}
                  {item.unpaid ? (
                    <p className="mgrReqCostTxt unpaidCostColours">Unpaid</p>
                  ) : !checkUserState.showCosts ? (
                    ""
                  ) : item.salaried && !item.ongoing ? (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      } `}
                    >
                      {memoVals.mob ? "Sal" : "Salary"}{" "}
                      {item.cost > 0 ? (
                        <span className="reqSalPlusSpan">
                          + {currencySymbol}
                          {Math.round(item.cost)}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <p
                      className={`mgrReqCostTxt  ${
                        memoVals.mob ? "none" : ""
                      }  ${item.ongoing ? "none" : ""}`}
                    >
                      {currencySymbol}
                      {Math.round(item.cost)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="itemTongue leaveTongue">
              <p className="tongueTxt leaveTongueTxt">Leave</p>
            </div>
          </div>
          // <div
          //   className="requestUnitApproved"
          //   key={item.reqID}
          //   onClick={() => {
          //     setShowUserReqModal(item.reqID);
          //   }}
          // >
          //   <div className="reqContentContainerApproved">
          //     <div className="approvedLeft">
          //       <img src={unitTick} className="unitImgIcon" alt="tick" />
          //     </div>
          //     <div className="approvedMiddle">
          //       <p className="leaveTypeNameTxt">
          //         {" "}
          //         {shortenLeaveName(item.leaveTypeName)}
          //       </p>
          //       <p className="reqUnitDateRangeTxt">
          //         {createDate(item.reqStart, item.reqEnd)}
          //       </p>
          //     </div>
          //     <div className="pendingRight">
          //       <div className="contractedTotal">
          //         {durationStringEstimatedTotal()}
          //       </div>

          //       {item.cost ? (
          //         <p
          //           className={`${
          //             memoVals.reqsPage === "past" ? "userReqCostTxt" : "userReqCostTxtPast"
          //           } ${item.declined ? "none" : ""}`}
          //         >
          //           {currencySymbol}
          //           {Math.floor(item.cost)}
          //         </p>
          //       ) : (
          //         ""
          //       )}
          //     </div>
          //   </div>
          // </div>
        );
      }
    });
  }, [pastRequests, selectedType]);

  allNames &&
    allNames.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });

  let generateAllNamesFilter = allNames.map((item) => {
    let isItInTheMgrReqsArray = mgrRequests.filter(
      (item2) =>
        item2.userID === item.userID &&
        (memoVals.selectedTeam === item.teamID ||
          memoVals.selectedTeam === "allstaff")
    );

    if (isItInTheMgrReqsArray[0]) {
      return (
        <option key={item.userID} value={item.userID}>{`${item.fName} ${
          item.lName ? item.lName[0] : ""
        }`}</option>
      );
    } else {
      return "";
    }
  });

  let todayDateObject = new Date();
  todayDateObject.setHours(0, 0, 0, 0);
  let todayDateString = dateStringer.createStringFromTimestamp(
    todayDateObject.getTime()
  );

  // RENDER USR MODAL
  // let renderUserLeaveReqModal = () => {
  //   if (showUserReqModal) {
  //     return (
  //       <UserLeaveReqModal
  //         unitReqID={showUserReqModal}
  //         reqIsPast={memoVals.reqsPage === "past" ? true : false}
  //       />
  //     );
  //   }
  // };
  // RENDER MGR MODAL
  // let renderLeaveReqModal = () => {
  //   if (memoVals.showReqModal) {
  //     return <LeaveReqModal unitReqID={memoVals.showReqModal} unitIsUserReq={false} />;
  //   }
  // };

  let generateYearsArr = useMemo(() => {
    yearsArr.sort();
    return (
      yearsArr &&
      yearsArr.map((year) => {
        // if (parseInt(year) <= new Date().getFullYear()) {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
        // }
      })
    );
  }, [yearsArr]);

  let typesPending = (type) => {
    let flag = false;

    pendingRequests.forEach((r) => {
      if (r.leaveTypeID === type) {
        flag = true;
      }
    });

    return flag;
  };

  let typesApproved = (type) => {
    let flag = false;

    upcomingRequests.forEach((r) => {
      if (r.leaveTypeID === type) {
        flag = true;
      }
    });

    return flag;
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
      console.log("BACK BUTTON PRESSED", memoVals.reqsPage);
      if (memoVals.reqsPage.userID) {
        console.log("Going back from selected staff");
        memoVals.setReqsPage((x) => "team");
        setShowMgrHistory(false);
        setTeamDeclined(false);
      }

      if (memoVals.reqsPage === "team") {
        memoVals.setReqsPage((x) => "upcoming");
      }
    });
  }, [memoVals.reqsPage]);
  // MASTER RETURN
  return (
    <div className="reqsPageHolder">
      {/* {renderLeaveReqModal()} {renderUserLeaveReqModal()} */}
      <div
        className={`${
          memoVals.reqsPage === "team" || memoVals.reqsPage.userID
            ? "reqsPageMgr"
            : "reqsPage"
        }`}
      >
        {/* this is for phone top corners -- i know it will work well with andriod, but unsure about iphone as the top bar won't be black */}
        <div className="blackCornerBg"></div>
        {/* end */}
        <div
          className={`${
            (memoVals.reqsPage === "team" || memoVals.reqsPage.userID) &&
            memoVals.mob
              ? "reqsPageUpperContainerTeam"
              : "reqsPageUpperContainer"
          } ${!memoVals.mob ? "pageLeft240pxDesktop" : ""} ${
            memoVals.mob ? "noBorderBottom" : ""
          }`}
        >
          {!memoVals.mob ? <TopBar /> : ""}
          {/* Reqs page header here */}
          <div className="pageHeaderMain none">
            <div className="pageHeaderRow1">
              <p className="pageHeaderRow1Title">Requests</p>
              <div className="pageHeaderRowMenuRow">
                <div className="pageHeaderRow1MenuItem">Upcoming</div>
              </div>
            </div>

            <div className="pageHeaderRow2">
              <div className="pageHeaderRow2TeamBtn">Staff leave</div>
              <div className="pageHeaderRow2StatstBn">Allowances</div>
            </div>
          </div>

          <div className="shiftsHeaderContentContainer">
            <div
              className={`shiftsHeaderLeft ${
                memoVals.reqsPage === "team" && memoVals.mob
                  ? "mobTeamReqsHeaderLeft"
                  : ""
              } ${memoVals.wide ? "wideTitle" : ""}`}
            >
              {/* {memoVals.selectedYear} */}
              <div
                className={`myRequestsHeaderLeftTitle ${
                  memoVals.reqsPage === "team" || memoVals.reqsPage.userID
                    ? `staffColour ${memoVals.mob ? "colorWhite" : ""}`
                    : ""
                } ${
                  memoVals.reqsPage === "team" && memoVals.mob
                    ? "staffReqsTitleDynamic"
                    : ""
                } ${!memoVals.mob ? "headerTitleSize myReqsTitle2" : ""} ${
                  memoVals.mob
                    ? `${
                        memoVals.reqsPage !== "team"
                          ? "myRequestsHeaderLeftTitleMob3"
                          : "myRequestsHeaderLeftTitleMob2"
                      }`
                    : ` ${
                        memoVals.reqsPage === "team" || memoVals.reqsPage.userID
                          ? "headerTitleColourMgr"
                          : ""
                      }`
                }`}
              >
                {(memoVals.reqsPage === "team" || memoVals.reqsPage.userID) &&
                !memoVals.mob ? (
                  <span className="staffTitleLeftBlob">.</span>
                ) : (
                  ""
                )}
                {memoVals.reqsPage.userID
                  ? `${memoVals.reqsPage.fName}'s leave`
                  : memoVals.reqsPage !== "team"
                  ? `${memoVals.mob ? `My leave` : "My leave"}`
                  : "Staff leave"}
              </div>
              {memoVals.reqsPage.userID ? (
                <div
                  className={`${
                    memoVals.mob ? "namedReqsBackBtnMob" : "namedReqsBackBtn"
                  } none`}
                  onClick={() => {
                    memoVals.setReqsPage((x) => "team");
                    setShowMgrHistory(false);
                    setTeamDeclined(false);
                  }}
                >
                  <img
                    src={whiteBack2}
                    className="namedReqsBackBtnImg"
                    alt="Back"
                  />{" "}
                  Staff leave
                </div>
              ) : (
                <div
                  className={`reqsHeaderMenuRow ${
                    memoVals.reqsPage === "team" ? "reqsHeaderMenuRowTeam" : ""
                  } ${memoVals.mob ? "reqsHeaderMenuRowMob" : ""}`}
                >
                  <div
                    className={`shiftHeaderMenuItem ${
                      memoVals.reqsPage === "upcoming"
                        ? `shiftHeaderMenuItemSelect`
                        : ""
                    } ${
                      memoVals.reqsPage === "team" && showMgrHistory
                        ? "reqsTeamHoverBtn"
                        : ""
                    } ${
                      memoVals.mob && memoVals.reqsPage === "team"
                        ? "noHover"
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      !showMgrHistory &&
                      !teamDeclined
                        ? `reqsMenuTeamBtnSel ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      if (memoVals.reqsPage === "team") {
                        if (showMgrHistory || teamDeclined) {
                          setLoading(true);
                          setShowMgrHistory(false);
                          setTeamDeclined(false);
                        }
                      } else {
                        if (memoVals.reqsPage !== "upcoming") {
                          setLoading(true);
                          memoVals.setReqsPage((x) => "upcoming");
                        }
                      }
                    }}
                  >
                    {/* Upcoming */}
                    <p className="headerMenuTopRow">Upcoming</p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "upcoming" ||
                        (memoVals.reqsPage === "team" &&
                          !showMgrHistory &&
                          !teamDeclined)
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "upcoming" ||
                        (memoVals.reqsPage === "team" &&
                          !showMgrHistory &&
                          !teamDeclined)
                          ? ""
                          : "none"
                      }  ${
                        memoVals.reqsPage === "team" ? "purpeMenuBottom" : ""
                      }`}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div
                    className={`${
                      memoVals.reqsPage === "team"
                        ? `shifHeaderMenuSplitterTeam ${
                            memoVals.mob ? "mobTeamHoursSplitterTeam" : ""
                          }`
                        : "shifHeaderMenuSplitter"
                    }`}
                  ></div>{" "}
                  <div
                    className={`shiftHeaderMenuItem ${
                      memoVals.reqsPage === "past"
                        ? `shiftHeaderMenuItemSelect`
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      (!showMgrHistory || teamDeclined)
                        ? "reqsTeamHoverBtn"
                        : ""
                    } ${
                      memoVals.mob && memoVals.reqsPage === "team"
                        ? "noHover"
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      showMgrHistory &&
                      !teamDeclined
                        ? `reqsMenuTeamBtnSel ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      if (memoVals.reqsPage === "team") {
                        if (teamDeclined || !showMgrHistory) {
                          setLoading(true);

                          setTeamDeclined(false);
                          setShowMgrHistory(true);
                        }
                      } else {
                        memoVals.setPage((x) => "past");
                        if (memoVals.reqsPage !== "past") {
                          setLoading(true);
                          memoVals.setReqsPage((x) => "past");
                        }
                      }
                    }}
                  >
                    <p className="headerMenuTopRow">Past</p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "past" ||
                        (memoVals.reqsPage === "team" &&
                          showMgrHistory &&
                          !teamDeclined)
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "past" ||
                        (memoVals.reqsPage === "team" &&
                          showMgrHistory &&
                          !teamDeclined)
                          ? ""
                          : "none"
                      }  ${
                        memoVals.reqsPage === "team" ? "purpeMenuBottom" : ""
                      }`}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div
                    className={`shiftHeaderMenuItem ${
                      memoVals.reqsPage === "declined"
                        ? `shiftHeaderMenuItemSelect`
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      (!showMgrHistory || !teamDeclined)
                        ? "reqsTeamHoverBtn"
                        : ""
                    } ${
                      memoVals.mob && memoVals.reqsPage === "team"
                        ? "noHover"
                        : ""
                    } ${
                      memoVals.reqsPage === "team" &&
                      showMgrHistory &&
                      teamDeclined
                        ? `reqsMenuTeamBtnSel ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      if (memoVals.reqsPage === "team") {
                        if (!teamDeclined) {
                          setLoading(true);
                          setTeamDeclined(true);
                          setShowMgrHistory(true);
                        }
                        // daisy
                      } else {
                        memoVals.setPage((x) => "declined");

                        if (memoVals.reqsPage !== "declined") {
                          setLoading(true);
                          memoVals.setReqsPage((x) => "declined");
                        }
                      }
                    }}
                  >
                    <p className="headerMenuTopRow">Declined</p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "declined" ||
                        (memoVals.reqsPage === "team" && teamDeclined)
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.reqsPage === "declined" ||
                        (memoVals.reqsPage === "team" && teamDeclined)
                          ? ""
                          : "none"
                      }  ${
                        memoVals.reqsPage === "team" && teamDeclined
                          ? "purpeMenuBottom"
                          : ""
                      }`}
                    ></p>
                  </div>
                </div>
              )}
            </div>

            {memoVals.reqsPage !== "team" &&
            (checkUserState.permissions < 3 || checkUserState.indiOnly) ? (
              <NavLink
                to="/team-leave"
                // to={`${!shiftsPage.includes("team") ? "/team-hours" : "/hours"}`}
                onClick={() => {
                  memoVals.setReqsPage((x) => "team");
                }}
              >
                <div
                  className={`staffHoursBtn staffReqsBtn minWidth164 ${
                    memoVals.mob && memoVals.reqsPage.userID ? "none" : ""
                  } `}
                >
                  <p className="teamHrsBtn">
                    {memoVals.full || !memoVals.full ? "Staff leave" : "Staff"}
                  </p>
                  <img src={whiteGo} alt="Team" className="whiteGoImg" />
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {memoVals.reqsPage === "team" ? (
              <NavLink
                to="/leave"
                // to={`${!shiftsPage.includes("team") ? "/team-hours" : "/hours"}`}
                onClick={() => {
                  // console.log("setting reqs page to team");
                  memoVals.setReqsPage((x) => "upcoming");
                }}
              >
                <div
                  className={`staffHoursBtn staffReqsBtn minWidth164 ${
                    memoVals.mob && memoVals.reqsPage.userID ? "none" : ""
                  } ${
                    memoVals.mob && memoVals.reqsPage === "team"
                      ? "staffHoursBtnDynamic"
                      : ""
                  } blueColours`}
                >
                  <p className="teamHrsBtn">My leave</p>
                  <img src={whiteGo} alt="Team" className="whiteGoImg" />
                </div>
              </NavLink>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className={`requestPageParent ${
            !memoVals.mob ? "pageLeft240pxDesktop borderLeftGrey" : ""
          } `}
        >
          {" "}
          <div
            className={`desktopMaster ${
              (memoVals.mob && memoVals.reqsPage === "team") ||
              memoVals.reqsPage.userID
                ? "desktopPurpBg"
                : "desktopWhiteBg"
            }`}
          >
            <div
              className={`desktopMasterMain  ${
                memoVals.mob ? "mob100Witdth" : ""
              } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
            >
              <div
                className={`requestsContainer ${
                  memoVals.mob ? "mobReqsContainerFullWidth" : ""
                }`}
              >
                {/* requests container starts */}

                {/* UPCOMING PAGE INCLUDING YET TO BE APPROVED */}
                {memoVals.reqsPage !== "team" && !memoVals.reqsPage.userID ? (
                  <img
                    src={horizontalBalls}
                    alt="loading"
                    className={`${loading ? "blueLoadingReqs" : "none"}`}
                  />
                ) : (
                  ""
                )}
                {memoVals.reqsPage !== "team" &&
                !memoVals.reqsPage.userID &&
                !loading ? (
                  <div
                    className={`reqsPageFilterHolder ${
                      !memoVals.mob ? "borderRightGrey" : "noBorderRight_"
                    } ${loading ? "noBorder" : ""}`}
                  >
                    <div
                      className={`reqPageFilterBarMy ${
                        memoVals.reqsPage === "upcoming" && !memoVals.full
                          ? "reqPageFilterBarMyUpcomingNonFull"
                          : ""
                      }`}
                    >
                      {memoVals.reqsPage === "upcoming" && !memoVals.full ? (
                        <div
                          className={`myAllowsWhiteBtn ${
                            checkUserState.permissions > 2
                              ? "nonMgrAvailBtn"
                              : ""
                          } ${memoVals.mob ? "myAllowsWhiteBtnMob" : ""}`}
                          onClick={() => {
                            memoVals.setShowReqStats((x) => "myStats");
                          }}
                        >
                          {memoVals.mob ? "Allowances" : "My allowances"}
                          <img
                            src={popupNavy}
                            alt="Staff rota"
                            className={`availPopupImg ${
                              window.innerWidth < 370 ? "invis" : ""
                            }`}
                          />
                        </div>
                      ) : (
                        // <div className="myAllowancesBtnInline">
                        //   My allowances
                        // </div>
                        ""
                      )}
                      <div
                        className={`myReqsFilterBar ${
                          (!memoVals.mob &&
                            !memoVals.full &&
                            memoVals.reqsPage === "past") ||
                          memoVals.reqsPage === "declined"
                            ? "x29482983298311"
                            : ""
                        } ${
                          memoVals.mob
                            ? `mobMyReqsFilterbarLeavePage ${
                                memoVals.reqsPage === "past" ||
                                memoVals.reqsPage === "declined"
                                  ? "nonUpcomingFiltBarMob"
                                  : ""
                              }`
                            : ""
                        } ${
                          memoVals.reqsPage === "upcoming" && !memoVals.full
                            ? "myReqsFilterBarUpcomingNonFull"
                            : ""
                        } ${
                          memoVals.reqsPage === "upcoming" && memoVals.mob
                            ? "x1038198"
                            : ""
                        } ${memoVals.full ? "myReqsFilterBarFull" : ""}`}
                      >
                        <select
                          className={`myReqsYearDropdown ${
                            memoVals.reqsPage === "upcoming" ? "none" : ""
                          }`}
                          value={memoVals.selectedYear}
                          onChange={(e) => {
                            if (e.target.value) {
                              setMgrRequests([]);
                              setLoading(true);
                              memoVals.setSelectedYear((x) => e.target.value);
                              // setRunUpdate(!runUpdate);
                            }
                          }}
                        >
                          {!yearsArr.includes(memoVals.selectedYear) ? (
                            <option value="">--</option>
                          ) : (
                            ""
                          )}
                          {generateYearsArr}
                        </select>

                        <select
                          className={`myReqsTypesDropdown`}
                          id="myReqsTypesDropdownId"
                          value={selectedType}
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                          }}
                        >
                          {" "}
                          <option value="allTypes">All types</option>
                          <option value="annLeave">Annual leave</option>
                          {generateTypes}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}

                {memoVals.reqsPage !== "team" && !memoVals.reqsPage.userID ? (
                  <div
                    className={`Container borderRightGrey  ${
                      loading || !memoVals.full ? "noBorder" : ""
                    }`}
                  >
                    {!loading ? <div className="reqFilterSplitter"></div> : ""}
                  </div>
                ) : (
                  ""
                )}
                <div
                  // ref={upcomingRef}
                  className={`${
                    memoVals.reqsPage === "upcoming" &&
                    !memoVals.reqsPage.userID &&
                    !loading
                      ? "showReqs myUpcomingReqsHeight"
                      : "none"
                  } ${pendingRequests.length === 0 ? "killTopPadding" : ""} ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? "minW101pc2"
                            : "minW101pc"
                        }`
                      : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
                >
                  <p
                    className={`${
                      pendingRequests.length > 0 &&
                      (typesPending(selectedType) ||
                        selectedType === "allTypes")
                        ? "pendingReqsUserTitle awaitingPullUp "
                        : "none"
                    }`}
                    onClick={() => {}}
                  >
                    Awaiting a decision
                  </p>
                  {generatePendingRequests}
                  <div
                    className={`${
                      !loading &&
                      upcomingRequests.length === 0 &&
                      pendingRequests.length === 0 &&
                      selectedType === "allTypes"
                        ? "noShiftsTxtContainer noUpcomingReqs"
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {!loading ? (
                      <p className="noShiftsTxt none">
                        No upcoming approved leave
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="noShiftsSideBar"></div>
                  </div>
                  <div
                    className={`${
                      pendingRequests.length > 0 &&
                      upcomingRequests.length > 0 &&
                      !memoVals.reqsPage.userID &&
                      !loading &&
                      (typesPending(selectedType) ||
                        selectedType === "allTypes")
                        ? "pendingSplitter"
                        : "none"
                    }`}
                  ></div>{" "}
                  <p
                    className={`${
                      upcomingRequests.length > 0 &&
                      (selectedType === "allTypes" ||
                        typesApproved(selectedType))
                        ? "pendingReqsUserTitle colour143051"
                        : "none"
                    } ${
                      pendingRequests.length === 0
                        ? "pushApprovedTitleDown"
                        : ""
                    }`}
                  >
                    Approved
                  </p>
                  {generateApprovedRequests}
                </div>
                {/* PAST PAGE */}
                <div
                  // ref={pastRef}
                  className={`${
                    memoVals.reqsPage === "past" &&
                    !memoVals.reqsPage.userID &&
                    !loading
                      ? "showReqs myUpcomingReqsHeight paddingTop20 myPreviousReqs"
                      : "none"
                  } ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? "minW101pc2"
                            : "minW101pc"
                        }`
                      : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
                >
                  {generatePastRequests}
                  <div
                    className={`${
                      !loading && pastRequests.length === 0
                        ? "noShiftsTxtContainer noPastReqs"
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {!loading ? (
                      <p className="noShiftsTxt">
                        No approved leave in {memoVals.selectedYear}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="noShiftsSideBar"></div>
                  </div>
                </div>
                {/* DECLINED PAGE */}
                <div
                  // ref={declinedRef}
                  className={`${
                    memoVals.reqsPage === "declined" &&
                    !memoVals.reqsPage.userID &&
                    !loading
                      ? "showReqs myUpcomingReqsHeight paddingTop20"
                      : "none"
                  } ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? "minW101pc2"
                            : "minW101pc"
                        }`
                      : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
                >
                  {generateDeclinedRequests}
                  <div
                    className={`${
                      !loading && declinedRequests.length === 0
                        ? "noShiftsTxtContainer noPastReqs"
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {!loading ? (
                      <p className="noShiftsTxt">No declined leave</p>
                    ) : (
                      ""
                    )}
                    <div className="noShiftsSideBar"></div>
                  </div>
                </div>
                {/*  TEAMS PAGE */}
                {/* - - - -  */}
                {/* {showMgrHistory ? ( */}
                {!loading ? (
                  <div
                    className={`reqsPageFilterHolder ${
                      !memoVals.mob ? "borderRightGrey" : ""
                    } ${loading ? "noBorder" : ""}`}
                  >
                    <div
                      className={`reqPageFilterBar  ${
                        !showMgrHistory && !teamDeclined
                          ? `teamReqsSelectorBar ${
                              !memoVals.mob && !memoVals.full ? "x1378491" : ""
                            }`
                          : "marginLef t17px"
                      }`}
                    >
                      <div
                        className={`reqsFiltBarLeft teamReqsFilterBar ${
                          !showMgrHistory && !memoVals.reqsPage.userID
                            ? "none"
                            : ""
                        }`}
                      >
                        <select
                          className={`myReqsYearDropdown  ${
                            memoVals.reqsPage === "upcoming" ? "none" : ""
                          }`}
                          value={memoVals.selectedYear}
                          onChange={(e) => {
                            if (e.target.value) {
                              console.log(e.target.value);
                              setMgrRequests([]);
                              setLoading(true);
                              memoVals.setSelectedYear((x) => e.target.value);
                              // setRunUpdate(!runUpdate);
                            }
                          }}
                        >
                          {!yearsArr.includes(memoVals.selectedYear) ? (
                            <option value="">--</option>
                          ) : (
                            ""
                          )}

                          {generateYearsArr}
                        </select>
                      </div>
                      {memoVals.reqsPage.userID && memoVals.reqsPage.fName ? (
                        <p
                          className="showingUsrReqsTxt"
                          onClick={() => {
                            memoVals.setReqsPage((x) => "team");
                          }}
                        >
                          {memoVals.reqsPage.fName}
                          {memoVals.reqsPage.fName[
                            memoVals.reqsPage.fName.length - 1
                          ] === "s"
                            ? "'"
                            : "'s"}{" "}
                          requests{" "}
                          <img
                            src={closeGrey}
                            alt="Show all requests"
                            className="closeShowNamedReqsImg"
                          />
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="mgrReqsShoulderTopRight">
                        {memoVals.reqsPage === "team" &&
                        !memoVals.full && // chicken
                        !showMgrHistory &&
                        !teamDeclined ? (
                          <div
                            className={`myAllowsWhiteBtn ${
                              checkUserState.permissions > 2
                                ? "nonMgrAvailBtn"
                                : ""
                            } ${
                              memoVals.mob
                                ? "myAllowsWhiteBtnMob"
                                : "minWidth140"
                            }`}
                            onClick={() => {
                              //   memoVals.setShowReqStats(x=>"myStats");
                              // }}

                              //   onClick={() => {
                              //     if (memoVals.reqsPage === "team") {
                              memoVals.setShowReqStats((x) => "mgr");
                              //     } else {
                              // memoVals.setShowReqStats(x=>"myStats");
                              //     }
                            }}
                          >
                            {memoVals.mob ? "Allowances" : "Staff allowances"}
                            <img
                              src={popupNavy}
                              alt="Staff rota"
                              className={`availPopupImg ${
                                window.innerWidth < 370 ? "invis" : ""
                              }`}
                            />
                          </div>
                        ) : (
                          // <div
                          //   className={`mobReqsAllowancesBtn`}
                          //   onClick={() => {
                          //     if (memoVals.reqsPage === "team") {
                          //       memoVals.setShowReqStats(x=>"mgr");
                          //     } else {
                          //       memoVals.setShowReqStats(x=>"myStats");
                          //     }
                          //   }}
                          // >
                          //   <p
                          //     className={`mobReqsAllowancesBtnTxt allowsColourTeamTxt_ `}
                          //   >
                          //     Allowances2
                          //   </p>
                          //   <img
                          //     src={
                          //       memoVals.reqsPage === "team"
                          //         ? memoVals.mob
                          //           ? popup2
                          //           : popup2
                          //         : stats
                          //     }
                          //     alt="Stats"
                          //     className="mobReqStatsImg"
                          //   />
                          // </div>
                          ""
                        )}{" "}
                        {!memoVals.reqsPage.userID &&
                        !showMgrHistory &&
                        !teamDeclined &&
                        memoVals.reqsPage === "team" &&
                        !memoVals.mob ? (
                          <p
                            className={`addStaffLeaveBtn ${
                              !memoVals.full ? "nonFullAddLeaveBtn" : ""
                            }`}
                            onClick={() => {
                              memoVals.setShowAddLeaveOnTeam((x) => true);
                              console.log("SHOW ADD LEAVE MODAL");
                            }}
                          >
                            Add leave{" "}
                            <img
                              src={whiteAddThin}
                              alt="Add form"
                              className="whiteAddFormImg"
                            />
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      {memoVals.reqsPage === "team" ? (
                        <div
                          className={`shiftsPageFilterBarRight ${
                            showMgrHistory ||
                            teamDeclined ||
                            memoVals.reqsPage.userID
                              ? "historyOrDeclinedFilterBar"
                              : ""
                          } ${
                            !showMgrHistory &&
                            !teamDeclined &&
                            !memoVals.reqsPage.userID
                              ? "upcomingFilterBarTeam"
                              : ""
                          } ${
                            !memoVals.reqsPage.userID &&
                            !showMgrHistory &&
                            !teamDeclined &&
                            memoVals.reqsPage === "team" &&
                            !memoVals.mob
                              ? "filterBarWithAddLeave"
                              : ""
                          }`}
                        >
                          {" "}
                          {!memoVals.reqsPage.userID ? (
                            <select
                              className={`${
                                filterUserID === "everyone"
                                  ? "shiftNamesDropdown x2082982"
                                  : "shiftNamesDropdown x2082982"
                              } typesDropdown ${
                                memoVals.mob
                                  ? `x108429811 ${
                                      showMgrHistory || teamDeclined
                                        ? "x29488845342"
                                        : ""
                                    } ${
                                      window.innerWidth < 370
                                        ? `x3388242 ${
                                            showMgrHistory ? "x33883121" : ""
                                          }`
                                        : ""
                                    }`
                                  : ""
                              } ${memoVals.fullButNarrow ? "x29382983" : ""}`}
                              value={filterUserID}
                              onChange={(e) => {
                                setFilterUserID(e.target.value);
                              }}
                            >
                              <option value="everyone">
                                All of your staff
                              </option>
                              {generateAllNamesFilter}
                            </select>
                          ) : (
                            <select
                              className={`${
                                selectedType === "allTypes"
                                  ? "shiftNamesDropdown x2082982"
                                  : "shiftNamesDropdown x2082982"
                              } typesDropdown`}
                              value={selectedType}
                              onChange={(e) => {
                                setSelectedType(e.target.value);
                              }}
                            >
                              <option value="allTypes">All types</option>
                              <option value="annLeave">Annual leave</option>

                              {types.map((t) => {
                                if (t.typeID !== "annLeave") {
                                  return (
                                    <option value={t.typeID}>{t.name}</option>
                                  );
                                }
                              })}
                            </select>
                          )}{" "}
                          {/* {memoVals.reqsPage === "team" && !memoVals.reqsPage.userID ? (
                            <select
                              value={memoVals.selectedTeam}
                              className={`teamReqTeamsDropdown ${
                                !memoVals.mob
                                  ? "fullTeamDrop"
                                  : `${
                                      memoVals.selectedTeam !== "allstaff"
                                        ? "mobteamSelectedDrop"
                                        : ""
                                    } shiftsHeaderTeamDropdownMob`
                              } ${
                                memoVals.selectedTeam !== "allstaff"
                                  ? "teamSelectedDropReqs"
                                  : ""
                              } reqsPageTeamsSelector`}
                              onChange={(e) => {
                                setShowReqFilter(false);
                                setShowMgrHistory(false);
                                setFilterUserID("everyone");
                                memoVals.setSelectedTeam( x =>e.target.value);
                              }}
                            >
                              <option value="allstaff">All teams</option>

                              {generateTeamSelectBar}
                            </select>
                          ) : (
                            ""
                          )} */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}
                {/* {showMgrHistory ? ( */}
                <div
                  className={`Container borderRightGrey  ${
                    loading ? "noBorder" : ""
                  }`}
                >
                  {!loading ? <div className="reqFilterSplitter"></div> : ""}
                </div>

                <div
                  ref={showReqs}
                  className={`${
                    memoVals.reqsPage === "team" ||
                    (memoVals.reqsPage.userID && !loading)
                      ? `showReqs ${showFilter ? "showFilterTeamShowReqs" : ""}`
                      : "none"
                  }  ${
                    memoVals.teamHasPendingReqs.includes(memoVals.selectedTeam)
                      ? ""
                      : "paddingTop0 marginTop0"
                  } ${
                    memoVals.mob
                      ? `noBorder ${
                          memoVals.reqsPage !== "team" &&
                          !memoVals.reqsPage.userID
                            ? "minW101pc2"
                            : "minW101pc"
                        }`
                      : ""
                  } ${
                    !showMgrHistory && !teamDeclined
                      ? "showReqsTeamUpcoming"
                      : ""
                  } mgrScrollColour ${
                    memoVals.device.vhIssue ? "vhIssue" : ""
                  } ${
                    (showMgrHistory || teamDeclined) && memoVals.mob
                      ? "mgrPrevScrollHeightMob"
                      : ""
                  }`}
                  // ref={showReqs}
                >
                  <div
                    className={`${
                      outstandingExists(pendingMgrReqs) && !loading
                        ? "filterReqsBarContainer outstandingPullUpABit"
                        : "none"
                    }`}
                  >
                    <p
                      className={`${
                        showMgrHistory || memoVals.reqsPage.userID || loading
                          ? "none"
                          : "clocksAwaitingTitles outstandingPullUp"
                      }`}
                    >
                      Outstanding
                    </p>

                    {memoVals.reqsPage.fName && memoVals.reqsPage.userID ? (
                      <p className="showingNameReqsOnlyTxt">
                        Showing {memoVals.reqsPage.fName}
                        {memoVals.reqsPage.fName[
                          memoVals.reqsPage.fName.length - 1
                        ] === "s"
                          ? "'"
                          : "'s"}{" "}
                        requests only
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  {!memoVals.reqsPage.userID && !loading
                    ? generateMgrReqsPENDING
                    : ""}
                  {mgrRequests.length === 0 ? (
                    <div
                      className={`${
                        (!loading && memoVals.reqsPage.userID) ||
                        (!loading &&
                          pendMgrQty === 0 &&
                          !showMgrHistory &&
                          !teamDeclined)
                          ? "noShiftsTxtContainer noUpcomingMgrReqs"
                          : "none"
                      }`}
                    >
                      <div className="noShiftsSideBar"></div>
                      {/* {!loading ? (
                        <p className="noShiftsTxt">
                          {memoVals.reqsPage.userID
                            ? `No requests from ${memoVals.reqsPage.fName} in ${memoVals.selectedYear}`
                            : "No requests from your staff"}
                        </p>
                      ) : (
                        ""
                      )} */}
                      <div className="noShiftsSideBar"></div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={`${
                      outstandingExists(pendingMgrReqs) &&
                      !showMgrHistory &&
                      !memoVals.reqsPage.userID &&
                      !loading
                        ? "pendingSplitter"
                        : "none"
                    }`}
                  ></div>
                  <div className={`${showReqFilter ? "filterBox" : "none"}`}>
                    <select
                      className="nameFilterDropdown"
                      value={filterUserID}
                      onChange={(e) => {
                        setFilterUserID(e.target.value);
                      }}
                    >
                      <option value="everyone">Everyone</option>
                      {generateAllNamesFilter}
                    </select>
                  </div>
                  <img
                    src={horizontalBalls}
                    alt="loading"
                    className={`${loading ? "blueLoadingReqs" : "none"}`}
                  />
                  <div className="filterReqsBarContainer">
                    <p
                      className={`${
                        showMgrHistory ||
                        (!loading &&
                          pendMgrQty === 0 &&
                          !showMgrHistory &&
                          !teamDeclined) ||
                        memoVals.reqsPage.userID ||
                        loading
                          ? "none"
                          : "approvedTeamReqsTitle"
                      }`}
                    >
                      Approved
                    </p>
                  </div>

                  {!loading &&
                  upcomingMgrApprovedCount === 0 &&
                  memoVals.reqsPage === "team" &&
                  !teamDeclined &&
                  !showMgrHistory ? (
                    <p className="noShiftsTxt x13141413 none">
                      No upcoming approved requests from staff you manage
                    </p>
                  ) : (
                    ""
                  )}
                  {!loading ? generateMgrReqs : ""}
                  <div
                    className={`${
                      !loading &&
                      ((mgrPrevQty === 0 &&
                        !teamDeclined &&
                        showMgrHistory &&
                        !memoVals.reqsPage.userID) ||
                        (mgrDeclinedQty === 0 &&
                          teamDeclined &&
                          showMgrHistory)) &&
                      !memoVals.reqsPage.userID
                        ? "noShiftsTxtContainer noUpcomingMgrReqs"
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    <p className="noShiftsTxt">
                      {teamDeclined
                        ? `No declined leave requests for your staff in ${memoVals.selectedYear}`
                        : `No approved leave requests for your staff in ${memoVals.selectedYear}`}
                    </p>
                    <div className="noShiftsSideBar"></div>
                  </div>
                </div>
              </div>
            </div>
            {memoVals.full ? (
              <div className="desktopMasterSecondary">
                {memoVals.reqsPage === "team" || memoVals.reqsPage.userID ? (
                  allNames[0] ? (
                    <MgrReqsDataBox modal={false} allNames={allNames} />
                  ) : (
                    ""
                  )
                ) : (
                  <MyReqsDataBox
                    modal={false}
                    profileSideBox={true}
                    selectedYear={memoVals.selectedYear}
                    setSelectedYearParent={setSelectedYear}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of requests */}

        {/* mgr Stats modal */}
        {memoVals.showReqStats === "mgr" ? (
          <div
            className="reqStatsUnderlay"
            onClick={(e) => {
              memoVals.setShowReqStats((x) => "");
            }}
          >
            <MgrReqsDataBox modal={true} allNames={allNames} />
          </div>
        ) : (
          ""
        )}

        {/* end of stats modals */}

        <div className={`${showDupeModal ? "reqInfoUnderlay" : "none"}`}>
          <div
            className="duplicateReqWarningModalBox navyBg"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="reqInfoDuplicateContainer">
              <div className="reqInfoTxtContainer">
                <p className="reqNotMadeTxt">Request not made</p>
                <p className="reqNotMadeinfoTxt">
                  The request you tried to make overlaps an existing one.{" "}
                </p>
              </div>
            </div>
            <p
              className="closeReqNotMadeNoteBtn"
              onClick={() => {
                setShowDupeModal(false);
              }}
            >
              OK
            </p>
          </div>
        </div>

        <Navbar />

        {/* {showAddLeaveOnTeam && memoVals.reqsPage === "team" ? (
          <AddShiftModal2
            teamID={"allStaff"}
            type="leave"
            setShowAddLeaveOnTeam={setShowAddLeaveOnTeam}
            loadFromReqsPage={dateStringer.createStringFromTimestamp(
              new Date().getTime()
            )}
          />
        ) : (
          ""
        )} */}
        {!memoVals.showReqMgrModal &&
        memoVals.mob &&
        memoVals.reqsPage === "team" &&
        !teamDeclined &&
        !showMgrHistory &&
        !memoVals.showReqStats &&
        !memoVals.showReqModal ? (
          <p
            className="addShiftBtnOnTeamShifts"
            onClick={() => {
              memoVals.setShowAddLeaveOnTeam((x) => {
                return {
                  new: true,
                };
              });
            }}
          >
            +
          </p>
        ) : (
          ""
        )}
      </div>
      {imgPreload}
    </div>
  );
};

export default LeaveReqsPage;
