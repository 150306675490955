import React, {useEffect, useState, useContext} from 'react'

import '../../../../styles/infoModal.css'

import { DataContext } from '../../../../contexts/DataContext';



const ErrorModal = () => {

    let {showErrorModal, setShowErrorModal, errorCode, setErrorCode} = useContext(DataContext);
    
    // support link with code
    let supportLink = `https://timelify.com/support/error/${errorCode}`

    return (
        <div className={`${showErrorModal ? "errorModalUnderlay" : "none"}`}>
            <div className="errorModalBox">Sorry, something went wrong.<br/><br/><b><span className="errorCodeSpan">Error code:</span></b> {errorCode}<br/><br/><span className="errorClickHereSpan" onClick={() => {
                window.open(supportLink)
            }}>Click <b>here</b> for support.</span><br/><br/><br/><span className="errorCloseBtn" onClick={() => {
             setShowErrorModal(false);
             setErrorCode();
             window.location.reload();
             return false;   }}>Close</span></div>
        </div>
    )
}

export default ErrorModal;