import React, { useContext, useEffect, useState } from "react";
// preloaded images 19 sep 23
// axios caught 21 sep 23

import "../../../styles/infoModal.css";

// import { DataContext } from '../../contexts/DataContext';
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";

import axios from "axios";
import serverURL from "../../../serverURL";

import ConvertStartYr from "../../tools/ConvertStartYr";

const StatsModal = () => {
  // const {}
  // = useContext(DataContext);

  const { showStatsModal, setShowStatsModal } = useContext(StyleContext);

  const {
    totUsrs,
    setTotUsrs,
    costPerMonth,
    setCostPerMonth,
    workforceAbsence,
    setWorkforceAbsence,
    yrReset,
    setYrReset,
  } = useContext(DataContext);

  let convertPtoPounds = (pence) => {
    let pounds = Math.floor(pence / 100);
    let pennies = pence % 100;

    let pennyOutcome = "";
    if (pennies > 0) {
      pennyOutcome = `.${pennies}`;
    }
    return `£${pounds}${pennyOutcome}`;
  };

  return (
    <div
      className={`${showStatsModal ? "infoModalBehind" : "none"}`}
      onClick={() => {
        setShowStatsModal(false);
      }}
    >
      <div
        className="statsModalBox"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="statsModalText">
          <p className="statsTitleTxt">Statistics</p>

          <div className="statsRow">
            <p className="statsRowTitle">Active users</p>
            <p className="statsRowValue">{totUsrs}</p>
          </div>
          <div className="statsRow">
            <p className="statsRowTitle">Cost per month</p>
            <p className="statsRowValue">{convertPtoPounds(costPerMonth)}</p>
          </div>
          <div className="statsRow">
            <p className="statsRowTitle">Workforce absence</p>
            <p className="statsRowValue">{workforceAbsence}%</p>
          </div>
          <div className="statsRow statsRowBottom">
            <p className="statsRowTitle">Year resets</p>
            <p className="statsRowValue">{ConvertStartYr(yrReset)}</p>
          </div>

          <p
            className="statsModalClose"
            onClick={() => {
              setShowStatsModal(false);
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatsModal;
