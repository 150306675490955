import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import serverURL from "../../serverURL";
import dateStringer from "../../tools/dateStringer";
import towerLogo from "../tower/img/towerLogo.png";
import editWhite from "../tower/img/editWhite.svg";
import blueSave from "../tower/img/blueSave.svg";
import horizontalBalls from "../tower/img/horizontalBalls.svg";

import { TowerContext } from "../../contexts/TowerContext";

const TowerClients = () => {
  const { fName, setFName, page, setPage } = useContext(TowerContext);

  let [clients, setClients] = useState([]);
  let [selectedClientID, setSelectedClientID] = useState("");
  let [users, setUsers] = useState([]);

  let [companyName, setCompanyName] = useState("");
  let [admins, setAdmins] = useState([]);
  let [archivedUsers, setArchivedUsers] = useState([]);

  let [editCompanyName, setEditCompanyName] = useState(false);
  let [companyNameNew, setCompanyNameNew] = useState("");

  let [dexterEmail, setDexterEmail] = useState("");
  let [dexterPassword, setDexterPassword] = useState("");

  let [notes, setNotes] = useState([]);

  let [yearStartDay, setYearStartDay] = useState("");
  let [yearStartMonth, setYearStartMonth] = useState("");
  let [yearStartDayNew, setYearStartDayNew] = useState("");
  let [yearStartMonthNew, setYearStartMonthNew] = useState("");
  let [editYearStart, setEditYearStart] = useState(false);
  let [refPeriod, setRefPeriod] = useState(0);
  let [weekStarts, setWeekStarts] = useState(0);
  let [beyondYearStart, setBeyondYearStart] = useState(false);
  let [accOwnerName, setAccOwnerName] = useState("");
  let [accOwnerEmail, setAccOwnerEmail] = useState("");

  let [joined, setJoined] = useState(0);
  let [clientStatus, setClientStatus] = useState("");
  let [trialLength, setTrialLength] = useState(0);
  let [editTrialLength, setEditTrialLength] = useState(false);
  let [newTrialLength, setNewTrialLength] = useState(0);

  let [editNoteID, setEditNoteID] = useState("");
  let [editNoteValue, setEditNoteValue] = useState("");
  let [sureRemoveClientID, setSureRemoveClientID] = useState("");
  let [refreshClients, setRefreshClients] = useState(false);
  let [removeClientLoading, setRemoveClientLoading] = useState(false);

  let [filterUserID, setFilterUserID] = useState("everyone");

  let howManyDaysAgo = (num) => {
    let now = new Date();
    now.setHours(23, 59, 0, 0);

    let then = new Date(num);
    let diffInDays = Math.round(
      (now.getTime() - then.getTime()) / (1000 * 60 * 60 * 24)
    );

    return diffInDays;
  };

  let calcTrialEnd = (joined, days) => {
    let joinedDate = new Date(joined);
    let endDate = new Date(joined);
    endDate.setDate(joinedDate.getDate() + days);
    return dateStringer.printedDateWithYrFromDs(
      dateStringer.createStringFromTimestamp(endDate.getTime())
    );
  };

  let [deleteUserID, setDeleteUserID] = useState("");

  let printPermissions = (num) => {
    if (num === 1) {
      return "Admin";
    }
    if (num === 2) {
      return "Manager";
    }
    if (num === 3) {
      return "User";
    }
  };
  let generateUsers = users.map((user) => {
    let overlapObjIDs = [];
    let startsAfterEnds = [];
    let overlaps = [];
    let overlapMpws = [];
    let startAfterEndsMpws = [];
    let overlapMpwIDs = [];
    user.hourlyRateTimeline.forEach((rate) => {
      let start = dateStringer.createTimestampFromString(rate.startDate);
      let end = dateStringer.createTimestampFromString(rate.endDate);

      if (start > end) {
        startsAfterEnds.push(rate.objID);
      }

      user.hourlyRateTimeline.forEach((rate2) => {
        if (rate2.objID !== rate.objID) {
          let start2 = dateStringer.createTimestampFromString(rate2.startDate);
          let end2 = dateStringer.createTimestampFromString(rate2.endDate);

          if (
            (start >= start2 && start <= end2) ||
            (end <= end2 && end >= start2)
          ) {
            if (!overlaps.includes(rate.objID)) {
              overlaps.push(rate.objID);
            }
          }
        }
      });
    });

    user.mpwTimeline.forEach((obj) => {
      let start = dateStringer.createTimestampFromString(obj.start);
      let end = dateStringer.createTimestampFromString(obj.end);

      if (start > end) {
        startAfterEndsMpws.push(obj.mpwID);
      }

      user.mpwTimeline.forEach((obj2) => {
        if (obj2.mpwID !== obj.mpwID) {
          let start2 = dateStringer.createTimestampFromString(obj2.start);
          let end2 = dateStringer.createTimestampFromString(obj2.end);
          if (
            (start >= start2 && start <= end2) ||
            (end <= end2 && end >= start2)
          ) {
            if (!overlapMpws.includes(obj.mpwID)) {
              overlapMpws.push(obj.mpwID);
            }
          }
        }
      });
    });

    let calcCurrMpw = (user) => {
      let mpw = 0;

      if (user.workingPattern === "fixedScheduled") {
        mpw += user.fixedSchedule[0].monDuration;
        mpw += user.fixedSchedule[0].tuesDuration;
        mpw += user.fixedSchedule[0].wedDuration;
        mpw += user.fixedSchedule[0].thursDuration;
        mpw += user.fixedSchedule[0].friDuration;
        mpw += user.fixedSchedule[0].satDuration;
        mpw += user.fixedSchedule[0].sunDuration;
      }
      if (user.workingPattern === "fixedVariable") {
        mpw += user.fixedVariable[0].hours * 60;
        mpw += user.fixedVariable[0].mins;
      }

      return mpw;
    };

    let generateMPWTimeline = user.mpwTimeline
      .sort((a, b) => {
        return b.endTs - a.endTs;
      })
      .map((mpw) => {
        return (
          <div
            key={mpw.mpwID}
            className={`towerHourlyRateObj ${
              overlapMpws.includes(mpw.mpwID) ||
              startAfterEndsMpws.includes(mpw.mpwID)
                ? "rateOverlapWarn"
                : ""
            }`}
            onClick={() => {
              console.log("objectiD:", mpw.mpwID);
            }}
          >
            <div className="hourlyRateTimelineLeftSection">
              <p className="tHrRateStart">
                {dateStringer.createShortStripFromDateString(mpw.end)}
              </p>
              <p className="tHrRateStart">
                {dateStringer.createShortStripFromDateString(mpw.start)}
              </p>
            </div>
            <p className="tHrRate">{mpw.mpw}</p>
          </div>
        );
      });

    let generateHourlyRateTimeline = user.hourlyRateTimeline
      .sort((a, b) => {
        return b.startTs - a.startTs;
      })
      .map((rate) => {
        return (
          <div
            key={rate.objID}
            className={`towerHourlyRateObj ${
              overlaps.includes(rate.objID) ||
              startsAfterEnds.includes(rate.objID)
                ? "rateOverlapWarn"
                : ""
            }`}
          >
            <div className="hourlyRateTimelineLeftSection">
              <p className="tHrRateStart">
                {dateStringer.createShortStripFromDateString(rate.endDate)}
              </p>
              <p className="tHrRateStart">
                {dateStringer.createShortStripFromDateString(rate.startDate)}
              </p>
            </div>
            <p className="tHrRate">{rate.hourlyRate}</p>
          </div>
        );
      });

    if (filterUserID === user.userID || filterUserID === "everyone")
      return (
        <div key={user.userID} className="towerUserUnitParent">
          <div className="towerUserUnit">
            <div className="towerUserUnitBox1">
              <p className="towerUserBox1NameTxt">
                {user.fName}
                <br />
                {user.lName}
              </p>
              <p className="permissionsBox">
                {printPermissions(user.permissions)}
              </p>
              <p className="userCreatedBox">
                Created
                <br />
                <span className="userCreatedSpan">
                  {dateStringer.printedDateWithYrFromDs(
                    dateStringer.createStringFromTimestamp(user.created)
                  )}
                </span>
              </p>
              <p
                className={`removeUserTower ${
                  deleteUserID === user.userID ? "sureRemoveUserTower" : ""
                }`}
                onClick={() => {
                  if (deleteUserID !== user.userID) {
                    setDeleteUserID(user.userID);
                  } else {
                    // fire req here
                    axios
                      .post(
                        `${serverURL}/delete-user`,
                        { userID: user.userID },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((resposne) => {
                        if (resposne.data.message === "success") {
                          let newUsers = [];
                          users.forEach((usr) => {
                            if (usr.userID !== user.userID) {
                              newUsers.push(usr);
                            }
                          });

                          setUsers(newUsers);
                        }
                      });
                  }
                }}
              >
                Remove{deleteUserID === user.userID ? "?" : ""}
              </p>
            </div>
            <div
              className="towerUserUnitBox1 hourlyRateBox"
              onClick={() => {
                console.log(user.hourlyRateTimeline);
              }}
            >
              <div className="towerUserHourlyRateDiv">
                <p className="towerUserUnitTitle">Rate</p>
                <p className="towerCurrRate">{user.hourlyRate}</p>
              </div>
              <div className="towerHourlyRateTimelineDiv">
                {generateHourlyRateTimeline}
              </div>
              {/* <p className='currentHourlyRateTxt'>
              Current{" "}
              <span className='currHourlySpan'>{user.hourlyRate || 0}</span>
            </p> */}
            </div>

            <div
              className="towerUserUnitBox1 mpwTimelineBox"
              onClick={() => {
                console.log(user.mpwTimeline);
              }}
            >
              <div className="towerUserHourlyRateDiv">
                <p className="towerUserUnitTitle">MPW</p>
                <p className="towerCurrRate">
                  {dateStringer.formatMinsDurationToHours(calcCurrMpw(user))}{" "}
                  <span className="towerWorkingPatternSpan">
                    {user.workingPattern === "fixedScheduled"
                      ? "FS"
                      : `${
                          user.workingPattern === "fixedVariable" ? "FV" : "Z"
                        }`}
                  </span>
                </p>
              </div>
              <div className="towerHourlyRateTimelineDiv">
                {generateMPWTimeline}
              </div>
              {/* <p className='currentHourlyRateTxt'>
              Current{" "}
              <span className='currHourlySpan'>{user.hourlyRate || 0}</span>
            </p> */}
            </div>
          </div>
          <div className="towerUserSplitter"></div>
        </div>
      );
  });

  let [clientLoading, setClientLoading] = useState(false);
  useEffect(() => {
    setClientLoading(true);
    setClients([]);
    setUsers([]);
    setNotes([]);
    setCompanyName("");
    setSelectedClientID("");
    console.log("Getting tower clients");
    axios
      .post(
        `${serverURL}/get-tower-clients`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.message === "success") {
          setClients(response.data.data);
          setClientLoading(false);
        }
      });
  }, [refreshClients]);

  useEffect(() => {
    if (selectedClientID) {
      console.log("Getting users on client: ", selectedClientID);
      setClientLoading(true);
      axios
        .post(
          `${serverURL}/get-tower-users-of-client`,
          { clientID: selectedClientID },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setUsers(response.data.userData);
            let archived = [];
            let admins1 = [];
            setDexterEmail(response.data.dexterEmail || "");
            setDexterPassword(response.data.dexterPassword || "");
            console.log(response.data.userData);
            setAccOwnerEmail("");
            setAccOwnerName("");

            response.data.userData.forEach((user1) => {
              if (user1.initialAccount) {
                setAccOwnerEmail(user1.email);
                setAccOwnerName(`${user1.fName} ${user1.lName}`);
              }
              if (user1.archived) {
                archived.push({
                  fName: user1.fName,
                  lName: user1.lName,
                  userID: user1.userID,
                });
              }
              if (user1.permissions === 1) {
                archived.push({
                  fName: user1.fName,
                  lName: user1.lName,
                  userID: user1.userID,
                });
              }
              setAdmins(admins1);

              setArchivedUsers(archived);
              setCompanyName(response.data.clientData.companyName || "");
              setCompanyNameNew(response.data.clientData.companyName || "");
              setBeyondYearStart(
                response.data.clientData.beyondYearStart || ""
              );

              let ys = response.data.clientData.yearStart;
              setYearStartDay(ys.substr(1, 2));
              setYearStartMonth(ys.substr(4, 2));
              setYearStartDayNew(ys.substr(1, 2));
              setYearStartMonthNew(ys.substr(4, 2));

              setRefPeriod(response.data.clientData.refPeriod || 0);
              setWeekStarts(response.data.clientData.weekStart || 0);
              setJoined(response.data.clientData.created);
              setClientStatus(response.data.clientData.clientStatus);
              setTrialLength(response.data.clientData.trialLength);
              setNewTrialLength(response.data.clientData.trialLength);

              setClientLoading(false);

              // clientData requires:
              // companyName: 1, beyondYearStart: 1, yearStart:1, refPeriod: 1, weekStart: 1, created: 1, clientStatus: 1, trialLength: 1,
            });
          }
        });
    }
  }, [selectedClientID]);

  clients.sort((a, b) => {
    return a.created - b.created;
  });

  let generateFilterNames = users.map((user) => {
    // users requires:
    // userID: 1, fName: 1, lName: 1,
    return (
      <option key={user.userID} value={user.userID}>
        {user.fName} {user.lName}
      </option>
    );
  });

  let generateClients = clients.map((cli) => {
    return (
      <div className="clientUnitParentUnit" key={cli.clientID}>
        <div
          className={`clientUnit ${
            selectedClientID === cli.clientID ? "selectedClient" : ""
          }`}
          onClick={() => {
            setUsers([]);
            setSureRemoveClientID("");
            if (selectedClientID === cli.clientID) {
              setSelectedClientID("");
              setCompanyName("");
            } else {
              setSelectedClientID(cli.clientID);
              setNotes(cli.clientNotes);
            }
          }}
        >
          <p className="clientCNameTxt">{cli.companyName}</p>
          <p className="clientID">
            <span
              className={`clientStatusSpan ${
                cli.clientStatus === "active"
                  ? "activeCli"
                  : `${
                      cli.clientStatus === "lapsed" ? "lapsedCli" : "trialCli"
                    }`
              }`}
            >
              {cli.clientStatus}
            </span>
            {cli.accNum}{" "}
            <span
              className={`${cli.todoQty > 0 ? "clientNotesToDoQty" : "none"}`}
            >
              {cli.todoQty} todo
            </span>
          </p>
          <p className="clientUsersQty">
            {cli.activeUsers.length} active users
          </p>
          <p className="clientArchivedUsersQty">
            {cli.archivedUsers.length} archived users
          </p>
          <p className="clientCreated">
            Signed up{" "}
            <span className="createdCliSpan">
              {dateStringer.printedDateWithYrFromDs(
                dateStringer.createStringFromTimestamp(cli.created)
              )}
            </span>
          </p>
        </div>
        <div
          className={`${
            selectedClientID === cli.clientID
              ? "selectedUnitClientBobble"
              : "none"
          }`}
        >
          rr
        </div>
      </div>
    );
  });

  let generateNotes = (todo) => {
    return notes.map((note) => {
      if ((todo && note.todo) || (!todo && !note.todo)) {
        return (
          <div className="clientNoteUnit">
            <p
              className={`${
                editNoteID !== note.noteID ? "clientNoteBox" : "none"
              }`}
              onClick={() => {
                // setEditNoteID(note.noteID);
                // setEditNoteValue(note.content);
              }}
            >
              {note.content}
            </p>
            <textarea
              className={`${
                editNoteID === note.noteID ? "clientNoteBoxInput" : "none"
              }`}
              value={editNoteValue}
              onChange={(e) => {
                setEditNoteValue(e.target.value);
              }}
            >
              {note.content}
            </textarea>

            <div className="clientNoteDone">
              <div className="noteBoxClientWrapper">
                <div
                  className={`${note.todo ? "noteTodoTodo" : "noteTodoDone"}`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/toggle-tower-note-todo`,
                        {
                          todo: !note.todo,
                          noteID: note.noteID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          let newNotes = [];
                          notes.forEach((n) => {
                            if (n.noteID === note.noteID) {
                              newNotes.push(response.data.data);
                            } else {
                              newNotes.push(n);
                            }
                          });
                          setNotes(newNotes);
                        }
                      });
                  }}
                ></div>
                <p
                  className={`${
                    note.todo ? "clientToDoIconTxt" : "clientToDoIconTxtDone"
                  }`}
                >
                  Todo
                </p>
              </div>

              <div className="editNoteContainerTower">
                <img
                  src={editNoteID === note.noteID ? blueSave : editWhite}
                  alt="Edit"
                  className="editClientUnitNoteBtnImg"
                  onClick={() => {
                    if (editNoteID === note.noteID) {
                      // fire request here
                      axios
                        .post(
                          `${serverURL}/edit-tower-note-content`,
                          {
                            content: editNoteValue,
                            noteID: note.noteID,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            console.log(notes);
                            let newNotes = [];
                            notes.forEach((n) => {
                              if (n.noteID === note.noteID) {
                                n.content = editNoteValue;
                                newNotes.push(n);
                              } else {
                                newNotes.push(note);
                              }
                            });
                            setEditNoteID("");
                            setEditNoteValue("");
                          }
                        });
                    } else {
                      setEditNoteID(note.noteID);
                      setEditNoteValue(note.content);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    });
  };
  return (
    <div className="towerBodyInnerContainer">
      <div className="towerClientsParent">
        <div className="towerClientClientsBar">
          <div className="clientCounterBar">
            <p className="totalClientsTxt">
              Total clients:{" "}
              <span className="totCliSpan">
                {clients.length === 0 ? "" : clients.length}
              </span>
            </p>
            <p
              className="refreshClients"
              onClick={() => {
                setSureRemoveClientID("");

                if (clients.length > 0) {
                  setClients([]);
                  setRefreshClients(!refreshClients);
                  console.log("refreshing");
                }
              }}
            >
              Refresh
            </p>
          </div>
          {generateClients}
        </div>
        <div className="towerClientUsersSection">
          {/* right */}
          <div
            className={`${
              clientLoading ? "none" : "towerClientClientDateContainer"
            }`}
          >
            <div
              className={`${companyName ? "clientActionButtonBox" : "none"}`}
            >
              <p
                className={`deleteClientButton ${
                  sureRemoveClientID === selectedClientID
                    ? `deleteClientButtonSelected ${
                        removeClientLoading ? "removeClientLoading" : ""
                      }`
                    : ""
                }`}
                onClick={() => {
                  if (!removeClientLoading) {
                    if (sureRemoveClientID !== selectedClientID) {
                      setSureRemoveClientID(selectedClientID);
                    } else {
                      setRemoveClientLoading(true);
                      //hello
                      axios
                        .post(
                          `${serverURL}/remove-client`,
                          { clientID: selectedClientID },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setRefreshClients(!refreshClients);
                            setCompanyName("");
                            setSureRemoveClientID("");
                            setRemoveClientLoading(false);
                          }
                        });
                    }
                  }
                }}
              >
                Remove{sureRemoveClientID === selectedClientID ? "?" : ""}
              </p>
            </div>
            <div className={`${companyName ? "clientBox1" : "none"}`}>
              <div className="clientBox1Item">
                <p
                  className={`${
                    editCompanyName ? "none" : "cliBox1CompanyName"
                  }`}
                >
                  {companyName}
                </p>
                <input
                  className={`${
                    editCompanyName ? "editCompanyNameInput" : "none"
                  }`}
                  value={companyNameNew}
                  onChange={(e) => {
                    setCompanyNameNew(e.target.value);
                  }}
                />

                <img
                  src={editCompanyName ? blueSave : editWhite}
                  alt="Edit"
                  className="editClientDataValue"
                  onClick={() => {
                    setEditCompanyName(!editCompanyName);
                    if (editCompanyName && companyNameNew) {
                      axios
                        .post(
                          `${serverURL}/tower-edit-company-name`,
                          { clientID: selectedClientID, data: companyNameNew },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setCompanyName(response.data.data);
                            setCompanyNameNew(response.data.data);
                          }
                        });
                    }
                  }}
                />
              </div>
              <div className="dexterContentBox">
                <p className="clientBoxTitle">Dexter email</p>
                <p className="clientBoxVal">{dexterEmail}</p>
              </div>
              <div className="dexterContentBox">
                <p className="clientBoxTitle">Dexter pass</p>
                <p className="clientBoxVal">{dexterPassword}</p>
              </div>
            </div>

            {/*  client header box 2 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            <div className={`${companyName ? "clientBox2" : "none"}`}>
              <p className="yearStartTitle">Joined</p>

              <div className="clientBox1Item">
                <p className="cliBox1CompanyName">
                  <span className="yrStartValSpan">
                    {dateStringer.printedDateWithYrFromDs(
                      dateStringer.createStringFromTimestamp(joined)
                    )}
                  </span>
                  &nbsp;&nbsp;&nbsp; {howManyDaysAgo(joined)} days ago
                </p>
              </div>

              <div className="refPeriodAndWeekStartContainer">
                <div className="refPeriodWeekStartUnit">
                  <p className="yearStartTitle">Status</p>
                  <div className="clientBox1Item refPeriodWidth">
                    <p className="cliBox1CompanyName">
                      <span className="yrStartValSpan">
                        {clientStatus === "active"
                          ? "Active"
                          : `${clientStatus === "trial" ? "Trial" : "Lapsed"}`}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="refPeriodWeekStartUnit">
                  <p className="yearStartTitle">Trial length</p>
                  <div className="clientBox1Item refPeriodWidth">
                    <p
                      className={`${
                        editTrialLength ? "none" : "cliBox1CompanyName"
                      }`}
                    >
                      <span className="yrStartValSpan">{trialLength} days</span>{" "}
                    </p>
                    <input
                      type="number"
                      value={newTrialLength}
                      className={`${
                        editTrialLength ? "trialLengthInput" : "none"
                      }`}
                      onChange={(e) => {
                        setNewTrialLength(e.target.value);
                      }}
                    />

                    <img
                      src={editTrialLength ? blueSave : editWhite}
                      alt="Edit"
                      className="editClientDataValue"
                      onClick={() => {
                        setEditTrialLength(!editTrialLength);
                        if (editTrialLength) {
                          axios
                            .post(
                              `${serverURL}/tower-edit-trial-length`,
                              {
                                clientID: selectedClientID,
                                data: newTrialLength,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setNewTrialLength(response.data.data);
                                setTrialLength(response.data.data);
                              }
                            });
                        }
                      }}
                    />
                  </div>{" "}
                  <p className="trialEndsTxt">
                    Ends {calcTrialEnd(joined, trialLength)}
                  </p>
                </div>
              </div>
            </div>

            {/*  client header box 2 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            <div className={`${companyName ? "clientBox2" : "none"}`}>
              <div className="yearStartTitleBox">
                <p className="yearStartTitle">Year start</p>
                <p
                  className={`${
                    beyondYearStart ? "beyondYearStartYes" : "beyondYearStartNo"
                  }`}
                >
                  {beyondYearStart
                    ? "Beyond year start"
                    : "Not yet beyond year start"}
                </p>
              </div>
              <div className="clientBox1Item">
                <p
                  className={`${editYearStart ? "none" : "cliBox1CompanyName"}`}
                >
                  Day: <span className="yrStartValSpan">{yearStartDay}</span>
                  &nbsp;&nbsp;&nbsp;Month:{" "}
                  <span className="yrStartValSpan">{yearStartMonth}</span>
                </p>

                <div
                  className={`${editYearStart ? "editYearStartDrops" : "none"}`}
                >
                  Day:
                  <select
                    className="towerYearStartDropdown"
                    value={yearStartDayNew}
                    onChange={(e) => {
                      setYearStartDayNew(e.target.value);
                    }}
                  >
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                  </select>
                  &nbsp;&nbsp;Month:{" "}
                  <select
                    className="towerYearStartDropdown"
                    value={yearStartMonthNew}
                    onChange={(e) => {
                      setYearStartMonthNew(e.target.value);
                    }}
                  >
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
                <img
                  src={editYearStart ? blueSave : editWhite}
                  alt="Edit"
                  className="editClientDataValue"
                  onClick={() => {
                    setEditYearStart(!editYearStart);
                    if (editYearStart) {
                      axios
                        .post(
                          `${serverURL}/tower-edit-client-year-start`,
                          {
                            clientID: selectedClientID,
                            day: yearStartDayNew,
                            month: yearStartMonthNew,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setYearStartDay(
                              response.data.yearStart.substr(1, 2)
                            );
                            setYearStartMonth(
                              response.data.yearStart.substr(4, 2)
                            );
                            setYearStartDayNew(
                              response.data.yearStart.substr(1, 2)
                            );
                            setYearStartMonthNew(
                              response.data.yearStart.substr(4, 2)
                            );
                            setBeyondYearStart(response.data.beyondYearStart);
                          }
                        });
                    }
                  }}
                />
              </div>

              <div className="refPeriodAndWeekStartContainer">
                <div className="refPeriodWeekStartUnit">
                  <p className="yearStartTitle">Ref period</p>
                  <div className="clientBox1Item refPeriodWidth">
                    <p className="cliBox1CompanyName">
                      <span className="yrStartValSpan">{refPeriod}</span> weeks
                    </p>

                    <img
                      src={blueSave}
                      alt="Edit"
                      className="editClientDataValue towerInvis"
                      onClick={() => {
                        // axios
                        //   .post(
                        //     `${serverURL}/tower-edit-client-year-start`,
                        //     {
                        //       clientID: selectedClientID,
                        //       day: yearStartDayNew,
                        //       month: yearStartMonthNew,
                        //     },
                        //     {
                        //       withCredentials: true,
                        //       credentials: "include",
                        //     }
                        //   )
                        //   .then((response) => {
                        //     if (response.data.message === "success") {
                        //     }
                        //   });
                      }}
                    />
                  </div>
                </div>
                <div className="refPeriodWeekStartUnit">
                  <p className="yearStartTitle">Week starts</p>
                  <div className="clientBox1Item refPeriodWidth">
                    <p className="cliBox1CompanyName">
                      <span className="yrStartValSpan">
                        {dateStringer.convertDayNumberToDayString(weekStarts)}
                      </span>{" "}
                    </p>

                    <img
                      src={blueSave}
                      alt="Edit"
                      className="editClientDataValue towerInvis"
                      onClick={() => {}}
                    />
                  </div>{" "}
                </div>
              </div>
            </div>

            {/*  client header box 3 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            <div className={`${companyName ? "clientBox2" : "none"}`}>
              <p className="yearStartTitle">Account owner</p>

              <div className="clientBox1Item">
                <p className="cliBox1CompanyName">{accOwnerName}</p>
              </div>

              <p className="yearStartTitle contactBoxTower">Contact </p>

              <div className="clientBox1Item accOwnerEmail">
                <p className="cliBox1CompanyName">{accOwnerEmail}</p>
              </div>
            </div>
          </div>
          <img
            src={horizontalBalls}
            className={`${clientLoading ? "clientLoadingSvg" : "none"}`}
            alt="Loading"
          />
          {/* users */}
          <div className="towerNotePadAndUsersContainer">
            <div
              className={`${
                clientLoading || !companyName
                  ? "none"
                  : "towerClientsNotepadPanelContainer"
              }`}
            >
              <div
                className={`${
                  clientLoading || !companyName ? "none" : "userNameFilterDiv"
                }`}
              >
                <select
                  className="userNameSelctFilter"
                  onChange={(e) => {
                    setFilterUserID(e.target.value);
                    setDeleteUserID("");
                  }}
                >
                  <option value="everyone">All</option>
                  {generateFilterNames}
                </select>
              </div>
              {generateNotes(true)} {generateNotes(false)}
              {/* {notes.length > 0
                ? `${generateNotes(true)} ${generateNotes(false)}`
                : "No notes"} */}
            </div>
            <div className="towerUsersContainer">{generateUsers}</div>
          </div>
          {/* end of users */}
        </div>
      </div>
    </div>
  );
};

export default TowerClients;
