import React, { createContext, useState } from "react";
import axios from "axios";

export const CalendarContext = createContext();

const CalendarContextProvider = (props) => {
  // ABSENCE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  let [calPage, setCalPage] = useState("schedule");
  let [absenceMgrTeams, setAbsenceMgrTeams] = useState([]);
  let [teamListData, setTeamListData] = useState([]);
  let [selectedAbsenceTeam, setSelectedAbsenceTeam] = useState("allStaff");
  let [showAddAbsenceModal, setShowAddAbsenceModal] = useState(false);

  let [userAbsenceArr, setUserAbsencesArr] = useState([]);
  let [mgrAbsenceArr, setMgrAbsenceArr] = useState([]);

  let [absencesLoading, setAbsencesLoading] = useState(true);
  // absence unit modal
  let [showAbsenceUnitModalOnUser, setShowAbsenceUnitModalOnUser] =
    useState(false);
  let [absTypeName, setAbsTypeName] = useState("Absence");
  let [absFirstShift, setAbsFirstShift] = useState("");
  let [absLastShift, setAbsLastShift] = useState("");
  let [absShiftsArr, setAbsShiftsArr] = useState([]);
  let [absRate, setAbsRate] = useState(1);
  let [absUnpaid, setAbsUnpaid] = useState(false);
  let [absDurationMins, setAbsDurationMins] = useState(0);
  let [absNote, setAbsNote] = useState("");
  let [absRtw, setAbsRtw] = useState(false);
  let [absRtwData, setAbsRtwData] = useState([]);
  let [absAbsenceID, setAbsAbsenceID] = useState("");
  let [absShiftCount, setAbsShiftCount] = useState(0);
  let [modalMgr, setModalMgr] = useState(false);

  // if modal is mgr:
  let [absFName, setAbsFName] = useState("");
  let [absLName, setAbsLName] = useState("");
  let [absUserID, setAbsUserID] = useState("");
  let [absTypeID, setAbsTypeID] = useState("");

  // from other compoents
  let [absenceIDLink, setAbsenceIDLink] = useState("");
  // END OF ABSENCE - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // SHIFTS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  let [shiftsPage, setShiftsPage] = useState("shifts"); //  should be shifts in real life
  let [showMyShiftModal, setShowMyShiftModal] = useState(false);
  let [showMgrShiftModal, setShowMgrShiftModal] = useState(false);
  let [showEditMyShiftModal, setShowEditMyShiftModal] = useState(false);
  let [updateChanges, setUpdateChanges] = useState(false);

  let [selectedShiftChange, setSelectedShiftChange] = useState("");
  let [openShiftChange, setOpenShiftChange] = useState(false);

  // let [reqMy, setReqMy] = useState("0000");

  let [showCompareShiftsModal, setShowCompareShiftsModal] = useState(false);
  let [showViewTilCalModal, setShowViewTilCalModal] = useState(false);
  let [showTilTrimModal, setShowTilTrimModal] = useState(false);

  let [updateTilData, setUpdateTilData] = useState(false);

  let [absenceTypes, setAbsenceTypes] = useState([]);

  // shifts, stats, availability, team-shifts, myAvail

  // order of team and user shifts (reverse arrow image)
  let [reverse, setReverse] = useState(false);

  // object to update the mgr tils/shifts with once shift has been edited and closed
  let [updatedItem, setUpdatedItem] = useState({});
  let [updateFlag, setUpdateFlag] = useState(false);
  // let [updateRun, setUpdateRun] = useState(false);
  // END OF SHIFTS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  let [userShiftsPage, setUserShiftsPage] = useState("shifts");

  // to update team schedule week
  let [updateInfo, setUpdateInfo] = useState(false);

  let [showOpenShiftModal, setShowOpenShiftModal] = useState({}); // {ds: ds, new: bool}

  let [copyWeek, setCopyWeek] = useState([]);

  let [bulkEditLimitObj, setBulkEditLimitObj] = useState({});
  // let [saveTemplateObj, setSaveTemplateObj] = useState({});
  // {teamID, monWeek}

  let [updateLimits, setUpdateLimits] = useState(false);

  // if updating schedule, update only a single day
  let [singleDay, setSingleDay] = useState(""); // "sun" "mon" etc.
  let [singleDay2, setSingleDay2] = useState(""); // "sun" "mon" etc.
  // singleDay2 is for use when duplicating or moving == two days need updating
  return (
    <CalendarContext.Provider
      value={{
        singleDay,
        setSingleDay,
        singleDay2,
        setSingleDay2,
        // saveTemplateObj,
        // setSaveTemplateObj,
        updateLimits,
        setUpdateLimits,
        bulkEditLimitObj,
        setBulkEditLimitObj,
        copyWeek,
        setCopyWeek,
        showOpenShiftModal,
        setShowOpenShiftModal,
        updateInfo,
        setUpdateInfo,

        userShiftsPage,
        setUserShiftsPage,
        // updateRun,
        // setUpdateRun,
        updateFlag,
        setUpdateFlag,
        updatedItem,
        setUpdatedItem,
        reverse,
        setReverse,
        updateTilData,
        setUpdateTilData,
        // reqMy,
        // setReqMy,
        showCompareShiftsModal,
        setShowCompareShiftsModal,
        absencesLoading,
        setAbsencesLoading,
        calPage,
        setCalPage,
        absenceMgrTeams,
        setAbsenceMgrTeams,
        teamListData,
        setTeamListData,
        selectedAbsenceTeam,
        setSelectedAbsenceTeam,
        showAddAbsenceModal,
        setShowAddAbsenceModal,
        userAbsenceArr,
        setUserAbsencesArr,
        showAbsenceUnitModalOnUser,
        setShowAbsenceUnitModalOnUser,
        absTypeName,
        setAbsTypeName,
        absFirstShift,
        setAbsFirstShift,
        absLastShift,
        setAbsLastShift,
        absShiftsArr,
        setAbsShiftsArr,
        absRate,
        setAbsRate,
        absUnpaid,
        setAbsUnpaid,
        absDurationMins,
        setAbsDurationMins,
        absNote,
        setAbsNote,
        absRtw,
        setAbsRtw,
        absRtwData,
        setAbsRtwData,
        absAbsenceID,
        setAbsAbsenceID,
        absShiftCount,
        setAbsShiftCount,
        modalMgr,
        setModalMgr,
        mgrAbsenceArr,
        setMgrAbsenceArr,
        absFName,
        setAbsFName,
        absLName,
        setAbsLName,
        absenceIDLink,
        setAbsenceIDLink,
        absUserID,
        setAbsUserID,
        absTypeID,
        setAbsTypeID,
        showMyShiftModal,
        setShowMyShiftModal,
        showMgrShiftModal,
        setShowMgrShiftModal,
        // - - - -  end of absence
        // shifts:
        shiftsPage,
        setShiftsPage,
        showEditMyShiftModal,
        setShowEditMyShiftModal,

        updateChanges,
        setUpdateChanges,
        selectedShiftChange,
        setSelectedShiftChange,
        openShiftChange,
        setOpenShiftChange,

        showTilTrimModal,
        setShowTilTrimModal,

        showViewTilCalModal,
        setShowViewTilCalModal,

        absenceTypes,
        setAbsenceTypes,
      }}
    >
      {props.children}
    </CalendarContext.Provider>
  );
};

export default CalendarContextProvider;
