import React, { useContext } from "react";

// cleaned wed 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import rotaLink404 from "../../img/general/rotaLink404.svg";
import logo from "../../img/general/logo.svg";
import logoTxt from "../../img/general/logoTxt.svg";

import "../../styles/calendar.css";

const InactiveRotaLink = () => {
  return (
    <div className="userShiftsPage">
      <img
        src={rotaLink404}
        alt="Inactive rota link"
        className={`inactiveRotaLinkHeroImg ${
          window.innerWidth < 820 ? "inactiveRotaLinkHeroImgMob" : ""
        }`}
      />
      <p className="rotaLinkBrokenTxt">This rota is not shared.</p>
      <img src={logo} alt="FlowRota logo" className="inactRotaLogo" />
      <br />
      <img
        src={logoTxt}
        alt="FlowRota logo text"
        className="inactRotaLogoTxt"
      />
    </div>
  );
};

export default InactiveRotaLink;
