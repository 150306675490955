import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";

import dateStringer from "../../tools/dateStringer";
import CheckUser from "../../tools/CheckUser";
import serverURL from "../../serverURL";

import "../../styles/calendar.css";

import CalendarSubBar from "./CalendarSubBar";
import AbsenceUser from "./AbsenceUser";
import AbsenceMgr from "./AbsenceMgr";

import Navbar from "../Navbar";

import { NavLink } from "react-router-dom";

const RTWForm = () => {
  const {
    calPage,
    setCalPage,
    absenceMgrTeams,
    setAbsenceMgrTeams,
    teamListData,
    setTeamListData,
    selectedAbsenceTeam,
    setSelectedAbsenceTeam,
    setUserAbsencesArr,
    absAbsenceID,
    absFirstShift,
    absLastShift,
    setAbsAbsenceID,
    setAbsLastShift,
    setAbsFirstShift,
    setAbsRtwData,
  } = useContext(CalendarContext);
  const { permissions, userID } = useContext(UserContext);
  const { setHideNavbar } = useContext(DataContext);

  let [questions, setQuestions] = useState([]);
  let [rtwPage, setRtwPage] = useState(1);
  let [rtwQuestionValue, setRtwQuestionValue] = useState("");
  let [rtwData, setRtwData] = useState([]);
  let [currQ, setCurrQ] = useState("");

  let [availableAbsences, setAvailableAbsences] = useState([]);

  let [hideAbsenceSelect, setHideAbsenceSelect] = useState(false);

  let generateRtwKey = questions.map((question, i) => {
    return (
      <div
        className={`rtwKey ${rtwPage === i + 1 ? "rtwKeySelected" : ""}`}
        key={i}
      ></div>
    );
  });

  let generateQuestions = questions.map((question, i) => {
    //   let getQuestion = "";

    return (
      <div
        key={i}
        className={`${
          rtwPage === i + 1
            ? `rtwQuestionContainer ${
                absAbsenceID ? "" : "disableRTWContainer"
              }`
            : "none"
        }`}
      >
        <p
          className={`${rtwPage === i + 1 ? "rtwQuestionNumber" : "none"}`}
          onClick={() => {
            console.log("the data", rtwData);
            console.log("currQ", currQ);
          }}
        >
          Question {i + 1} out of {questions.length}
        </p>

        <p className={`${rtwPage === i + 1 ? "rtwQuestion" : "none"}`}>
          {questions[i]}
        </p>
        <textarea
          className={`${rtwPage === i + 1 ? "rtwTextarea" : "none"}`}
          value={rtwQuestionValue}
          onChange={(e) => {
            setRtwQuestionValue(e.target.value);
          }}
        ></textarea>
      </div>
    );
  });

  rtwData.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

  let generateRtwReview = rtwData.map((rtw, i) => {
    return (
      <div className='rtwReviewUnit' key={i}>
        <p className='rtwReviewQ'>{rtw.question}</p>
        <p className='rtwReviewA'>{rtw.answer}</p>
      </div>
    );
  });

  useEffect(() => {
    if (!absAbsenceID) {
      axios
        .post(
          `${serverURL}/get-absences-for-user`,
          { userID: userID },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            response.data.arrToSend.sort((a, b) =>
              dateStringer.createTimestampFromString(b.firstShift) >
              dateStringer.createTimestampFromString(a.firstShift)
                ? 1
                : dateStringer.createTimestampFromString(a.firstShift) >
                  dateStringer.createTimestampFromString(b.firstShift)
                ? -1
                : 0
            );

            setAvailableAbsences(response.data.arrToSend);
          }
        });
    }

    axios
      .post(
        `${serverURL}/get-rtw-form-questions`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success" && response.data.rtwQuestions) {
          setQuestions(response.data.rtwQuestions);
          let arr = [];
          response.data.rtwQuestions.forEach((q, i) => {
            let obj = {
              question: q,
              answer: "",
              index: i,
            };
            arr.push(obj);
          });
          setRtwData(arr);
          setCurrQ(response.data.rtwQuestions[0]);
        }
      });
  }, []);

  let whichAbsenceComponent = () => {
    if (calPage === "team-absence") {
      return <AbsenceMgr />;
    }
    return <AbsenceUser />;
  };

  let generateAbsenceOptionsRTW = availableAbsences.map((abs) => {
    return (
      <option value={abs.absenceID}>
        {dateStringer.createDatePeriod(abs.firstShift, abs.lastShift)}
      </option>
    );
  });

  return (
    <div className='rtwContainer'>
      <div
        className={`${questions.length > 0 ? "rtwFormUpperContainer" : "none"}`}
      >
        <p
          className='absenceFormAbsenceTitleTxt'
          onClick={() => {
            console.log("available absences", availableAbsences);
            console.log("data to send:", rtwData);
          }}
        >
          Absence form
        </p>

        <p className={`${absAbsenceID ? "absenceFormAbsenceTxt" : "none"}`}>
          {dateStringer.createDatePeriod(absFirstShift, absLastShift)}
        </p>
        <select
          className={`rtwFormAbsDropdown ${absAbsenceID ? "none" : ""}`}
          onChange={(e) => {
            setAbsAbsenceID(e.target.value);
            setHideAbsenceSelect(true);
            availableAbsences.forEach((abs) => {
              if (abs.absenceID === e.target.value) {
                setAbsFirstShift(abs.firstShift);
                setAbsLastShift(abs.lastShift);
              }
            });
          }}
        >
          <option value=''>Select absence</option>

          {generateAbsenceOptionsRTW}
        </select>
      </div>
      <div className='rtwFormMidContainer'>
        {generateQuestions}
        <div className={`${rtwPage === 999 ? "rtwReviewContainer" : "none"}`}>
          {generateRtwReview}
        </div>
      </div>
      <div className='rtwFormLowerContainer'>
        <div className={`${questions.length > 0 ? "rtwBtnContainer" : "none"}`}>
          <div
            className='rtwBackBtn'
            onClick={() => {
              setRtwQuestionValue("");
              setRtwPage(1);
              axios
                .post(
                  `${serverURL}/get-rtw-form-questions`,
                  { someData: 1 },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (
                    response.data.message === "success" &&
                    response.data.rtwQuestions
                  ) {
                    setQuestions(response.data.rtwQuestions);
                    let arr = [];
                    response.data.rtwQuestions.forEach((q, i) => {
                      let obj = {
                        question: q,
                        answer: "",
                        index: i,
                      };
                      arr.push(obj);
                    });
                    setRtwData(arr);
                    setCurrQ(response.data.rtwQuestions[0]);
                  }
                });
            }}
          >
            {`${rtwPage !== 1 ? "Reset" : ""}`}
          </div>
          <div
            className={`${rtwPage === 999 ? "none" : "rtwQNextBtn"}`}
            onClick={() => {
              if (rtwPage === questions.length) {
                let obj = {
                  question: currQ,
                  answer: rtwQuestionValue,
                  index: questions.length - 1,
                };
                // remove existing obj
                let newArr = [];
                rtwData.forEach((q) => {
                  if (q.question === currQ) {
                  } else {
                    newArr.push({
                      question: q.question,
                      answer: q.answer,
                      index: q.index,
                    });
                  }
                });
                let newArrComplete = [...newArr, obj];
                setRtwData(newArrComplete);

                setCurrQ(questions[rtwPage]);

                // setRtwPage(rtwPage + 1);

                setRtwPage(999);
              } else {
                if (rtwPage !== 999) {
                  let obj = {
                    question: currQ,
                    answer: rtwQuestionValue,
                    index: rtwPage - 1,
                  };
                  // remove existing obj
                  let newArr = [];
                  rtwData.forEach((q) => {
                    if (q.question === currQ) {
                    } else {
                      newArr.push({
                        question: q.question,
                        answer: q.answer,
                        index: q.index,
                      });
                    }
                  });
                  let newArrComplete = [...newArr, obj];
                  setRtwData(newArrComplete);

                  setCurrQ(questions[rtwPage]);

                  setRtwPage(rtwPage + 1);
                  setRtwQuestionValue("");
                }
              }
            }}
          >
            {" "}
            {`${rtwPage === 999 ? "Submit" : "Next"}`}
          </div>
          <NavLink to='/absence'>
            <div
              className={`${rtwPage === 999 ? "rtwQNextBtnSubmit" : "none"}`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/submit-rtw-form`,
                    { absenceID: absAbsenceID, rtwData: rtwData },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setAbsRtwData(rtwData);
                    }
                  });
              }}
            >
              Submit
            </div>
          </NavLink>
          <NavLink to='/absence'>
            <div className='rtwBackBtn' onClick={() => {}}>
              Exit{" "}
            </div>
          </NavLink>{" "}
        </div>
        <div
          className={`${questions.length > 0 ? "rtwPageKeyContainer" : "none"}`}
        >
          {generateRtwKey}

          <div
            className={`rtwKey ${rtwPage === 999 ? "rtwKeyReady" : ""}`}
          ></div>
        </div>
      </div>
      {/* <Navbar /> */}
    </div>
  );
};

export default RTWForm;
