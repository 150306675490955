import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
  useMemo,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { NavLink } from "react-router-dom";
import axios from "axios";
import serverURL from "../serverURL";
import DemoUserSelect from "./DemoUserSelect";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import whitecross from "../img/general/whitecross.svg";
import downArrowWhite from "../img/general/downArrowWhite3.svg";
import checkbox2 from "../img/general/checkbox2team.svg";
import clockedOn from "../img/general/clockedOn.svg";
import gps1 from "../img/general/gps1.svg";
import gps2 from "../img/general/gps2.svg";
import gps3 from "../img/general/gps3.svg";
import threeLines from "../img/general/threeLines.svg";
import popup from "../img/general/popup.svg";
import stopwatch from "../img/general/stopwatch.svg";
import greenTick from "../img/general/greenTick.svg";
import edit from "../img/general/editGrey.svg";
import whiteTick from "../img/general/whiteTick.svg";
import plus from "../img/general/schedLimitPlus.svg";
import minus from "../img/general/schedLimitMinus.svg";
import onTimeTick from "../img/general/onTimeTick.svg";

import "../styles/navbar.css";
import dateStringer, { printMonthFromMy } from "../tools/dateStringer";
import OpenMgrShiftModal from "./calendar/shifts/OpenMgrShiftModal";

const ActiveClocksModal = () => {
  const {
    openManagerShiftModal,
    loadUnclocks,
    showEditUser,
    activeClocksQty,
    showMessages,
    showEditShiftModal,
    setUserIsClockedOn,
    setShowActiveClocks,
    setOpenManagerShiftModal,
    setLoadUnclocks,
    setActiveClocksQty,
    setShowMessages,
    setShowEditShiftModal,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      openManagerShiftModal, //
      loadUnclocks, //
      showEditUser, //
      activeClocksQty, //
      showMessages, //
      showEditShiftModal, //
      setUserIsClockedOn, //
      setShowActiveClocks, //
      setOpenManagerShiftModal, //
      setLoadUnclocks, //
      setActiveClocksQty, //
      setShowMessages, //
      setShowEditShiftModal, //
      mobModal, //
    }),
    [
      openManagerShiftModal,
      loadUnclocks,
      showEditUser,
      activeClocksQty,
      showMessages,
      showEditShiftModal,
      setUserIsClockedOn,
      setShowActiveClocks,
      setOpenManagerShiftModal,
      setLoadUnclocks,
      setActiveClocksQty,
      setShowMessages,
      setShowEditShiftModal,
      mobModal,
    ]
  );

  let [initialLoad, setInitialLoad] = useState(false);

  let [activeClocks, setActiveClocks] = useState([]);

  let shortenZone = (zone) => {
    if (zone) {
      if (zone.length > 18) {
        return `${zone.substr(0, 15)}...`;
      } else {
        return zone;
      }
    }
  };

  let isTimeInputValid = (val) => {
    if (val && val.length === 5 && val.substr(2, 1) === ":") {
      return true;
    } else {
      return false;
    }
  };

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 17) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={downArrowWhite}
        alt="Down Arrow White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2}
        alt="Checkbox 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={clockedOn}
        alt="Clocked On"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={gps1}
        alt="GPS 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={gps2}
        alt="GPS 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={gps3}
        alt="GPS 3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={threeLines}
        alt="Three Lines"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={stopwatch}
        alt="Stopwatch"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greenTick}
        alt="Green Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={edit}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="White Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plus}
        alt="Plus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={minus}
        alt="Minus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={onTimeTick}
        alt="On Time Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [multiSelect, setMultiSelect] = useState(false);
  let [selectedShiftIDs, setSelectedShiftIDs] = useState([]);
  let [selectedTilIDs, setSelectedTilIDs] = useState([]);
  let [selectedShiftID, setSelectedShiftID] = useState("");
  let [selectedTilID, setSelectedTilID] = useState("");
  let [userIDs, setUserIDs] = useState([]);
  let [showTopBorder, setShowTopBorder] = useState(false);
  // let [showActions, setShowActions] = useState(false);
  let [editClockOn, setEditClockOn] = useState(false);
  let [editClockOnVal, setEditClockOnVal] = useState("00:00");
  let [editB1start, setEditB1start] = useState(false);
  let [b1startValue, setB1StartValue] = useState("00:00");
  let [editB1end, setEditB1end] = useState(false);
  let [b1endValue, setB1EndValue] = useState("00:00");
  let [editB2start, setEditB2start] = useState(false);
  let [b2startValue, setB2StartValue] = useState("00:00");
  let [editB2end, setEditB2end] = useState(false);
  let [b2endValue, setB2EndValue] = useState("00:00");
  let [editB3start, setEditB3start] = useState(false);
  let [b3startValue, setB3StartValue] = useState("00:00");
  let [editB3end, setEditB3end] = useState(false);
  let [b3endValue, setB3EndValue] = useState("00:00");

  let [showCancelModal, setShowCancelModal] = useState(false);

  let [showAbsentOptions, setShowAbsentOptions] = useState(false);
  let [showClockOffModal, setShowClockOffModal] = useState({ show: false });

  let [reload, setReload] = useState(false);
  let [showGpsID, setShowGpsID] = useState("");
  let [upcoming, setUpcoming] = useState([]);

  let [clockOffIsBefore, setClockOffIsBefore] = useState("");
  let clockingOffCheck = (userID) => {
    // IF TRUE, SHOWS WARNING MODAL
    let ds = `${clockOffDs.substr(0, 11)}H${clockOffInput.substr(
      0,
      2
    )}M${clockOffInput.substr(3, 2)}`;
    let ts = dateStringer.createTimestampFromString(ds);
    let val = "";

    activeClocks.forEach((c) => {
      if (c.userID === userID) {
        if (ts < dateStringer.createTimestampFromString(c.break3endDs)) {
          val = "third break end";
        }

        if (ts < dateStringer.createTimestampFromString(c.break3startDs)) {
          val = "third break start";
        }

        if (ts < dateStringer.createTimestampFromString(c.break2endDs)) {
          val = "second break end";
        }

        if (ts < dateStringer.createTimestampFromString(c.break2startDs)) {
          val = "second break start";
        }

        if (ts < dateStringer.createTimestampFromString(c.break1endDs)) {
          val = "first break end";
        }

        if (ts < dateStringer.createTimestampFromString(c.break1startDs)) {
          val = "first break start";
        }

        if (ts < dateStringer.createTimestampFromString(c.startDs)) {
          val = "clocked on";
        }
      }
    });

    return val;
  };

  let clearEdits = () => {
    if (editClockOn) {
      setEditClockOn(false);
    }
    if (editB1start) {
      setEditB1start(false);
    }
    if (editB1end) {
      setEditB1end(false);
    }
    if (editB2start) {
      setEditB2start(false);
    }
    if (editB2end) {
      setEditB2end(false);
    }
    if (editB3start) {
      setEditB3start(false);
    }
    if (editB3end) {
      setEditB3end(false);
    }
  };

  let editClock = (code) => {
    let val = "";
    if (code === "clockOn") {
      val = editClockOnVal;
    }
    if (code === "b1start") {
      val = b1startValue;
    }
    if (code === "b1end") {
      val = b1endValue;
    }
    if (code === "b2start") {
      val = b2startValue;
    }
    if (code === "b2end") {
      val = b2endValue;
    }
    if (code === "b3start") {
      val = b3startValue;
    }
    if (code === "b3end") {
      val = b3endValue;
    }
  };

  let [namesOfInvalid, setNamesOfInvalid] = useState([]);

  let invalidTimes = (userID) => {
    let arr = [];
    activeClocks.forEach((c) => {
      if ((userID && c.userID === userID) || !userID) {
        let p1 =
          c.startDs && c.break1startDs
            ? (dateStringer.createTimestampFromString(c.break1startDs) -
                dateStringer.createTimestampFromString(c.startDs)) /
              1000 /
              60
            : 0;
        let p2 =
          c.break1endDs && c.break2startDs
            ? (dateStringer.createTimestampFromString(c.break2startDs) -
                dateStringer.createTimestampFromString(c.break1endDs)) /
              1000 /
              60
            : 0;
        let p3 =
          c.break2endDs && c.break3startDs
            ? (dateStringer.createTimestampFromString(c.break3startDs) -
                dateStringer.createTimestampFromString(c.break2endDs)) /
              1000 /
              60
            : 0;
        let b1 =
          c.break1startDs && c.break1endDs
            ? (dateStringer.createTimestampFromString(c.break1endDs) -
                dateStringer.createTimestampFromString(c.break1startDs)) /
              1000 /
              60
            : 0;
        let b2 =
          c.break2startDs && c.break2endDs
            ? (dateStringer.createTimestampFromString(c.break2endDs) -
                dateStringer.createTimestampFromString(c.break2startDs)) /
              1000 /
              60
            : 0;

        let b3 =
          c.break3startDs && c.break3endDs
            ? (dateStringer.createTimestampFromString(c.break3endDs) -
                dateStringer.createTimestampFromString(c.break3startDs)) /
              1000 /
              60
            : 0;

        let valid = true;
        let p1valid = true;
        let p2valid = true;
        let p3valid = true;
        let b1valid = true;
        let b2valid = true;
        let b3valid = true;
        if (p1 < 0) {
          valid = false;
          p1valid = false;
        }
        if (p2 < 0) {
          valid = false;
          p2valid = false;
        }
        if (p3 < 0) {
          valid = false;
          p3valid = false;
        }
        if (b1 < 0) {
          valid = false;
          b1valid = false;
        }
        if (b2 < 0) {
          valid = false;
          b2valid = false;
        }
        if (b3 < 0) {
          valid = false;
          b3valid = false;
        }
        if (!valid) {
          arr.push({
            fName: c.fName,
            lName: c.lName,
            p1valid,
            p2valid,
            p3valid,
            b1valid,
            b2valid,
            b3valid,
          });
        }
      }
    });

    if (arr[0]) {
      setNamesOfInvalid(arr);
    } else {
      setNamesOfInvalid(false);
    }

    return arr[0] ? true : false;
  };
  useEffect(() => {
    if (!memoVals.showEditShiftModal) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-clocked-active`,
          {
            // nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            someData: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            response.data.activeClocks.sort((a, b) => {
              return (
                dateStringer.createTimestampFromString(a.startDs) -
                dateStringer.createTimestampFromString(b.startDs)
              );
            });
            setActiveClocks(response.data.activeClocks);
            if (
              response.data.activeClocks.length === 0 &&
              !response.data.upcoming[0]
            ) {
              memoVals.setShowActiveClocks((x) => false);
            }
            memoVals.setActiveClocksQty(
              (x) => response.data.activeClocks.length
            );
            setUpcoming(response.data.upcoming || []);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.loadUnclocks, memoVals.showEditShiftModal]);

  let scrollBord = useRef();

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      closeModal();
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  let [clockOffInput, setClockOffInput] = useState(
    `${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(12, 2)}:${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(15, 2)}`
  );
  let [clockOffDs, setClockOffDs] = useState(
    dateStringer.createStringFromTimestamp(new Date().getTime())
  );

  let [clockOffDsFormatted, setClockOffDsFormatted] = useState("");
  useEffect(() => {
    if (clockOffDs && clockOffInput) {
      setClockOffDsFormatted(
        `${clockOffDs.substr(0, 11)}H${clockOffInput.substr(
          0,
          2
        )}M${clockOffInput.substr(3, 2)}`
      );
    } else {
      setClockOffDsFormatted("");
    }
  }, [clockOffDs, clockOffInput]);
  let actionFct = (action, clockOnID, clockOffTime) => {
    // action: makeWorked, delete, absentPaid, absentUnpaid
    // setShowActions(false);
    // setLoading(true);
    setInitialLoad(true);
    setLoading(true);

    axios
      .post(
        `${serverURL}/action-active-clock-item`,

        {
          action: action,
          clockOnID,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          clockOffDs: action === "clockOff" ? clockOffDsFormatted : "",
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setLoading(false);
          setSelectedShiftIDs([]);
          if (action === "delete") {
            setShowCancelModal(false);
          }

          if (response.data.cancelledOwnClockCard) {
            memoVals.setUserIsClockedOn((x) => false);
          }
          let newArr = [];
          activeClocks.forEach((ac) => {
            if (ac.clockOnID !== clockOnID) {
              newArr.push(ac);
            }
          });

          setActiveClocks(newArr);
          let qty = memoVals.activeClocksQty;
          qty--;
          memoVals.setActiveClocksQty((x) => qty);
          if (qty === 0) {
            memoVals.setShowActiveClocks((x) => false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let closeModal = () => {
    if (invalidTimes()) {
    } else {
      memoVals.setShowActiveClocks((x) => false);
    }
  };
  let generateActiveClocks = activeClocks.map((unc) => {
    let startDs = unc.startDs;
    let type = unc.type;

    let printShiftDurationSubTxt = () => {
      // let dur =
      //   (new Date().getTime() -
      //     dateStringer.createTimestampFromString(startDs)) /
      //     1000 /
      //     60 -
      //   ((unc.break1secs / 60 || 0) +
      //     (unc.break2secs / 60 || 0) +
      //     (unc.break3secs / 60 || 0));

      let totalMins =
        (new Date().getTime() -
          dateStringer.createTimestampFromString(startDs)) /
        1000 /
        60;

      // let brkMins = 0;
      if (unc.break1secs && typeof unc.break1secs === "number") {
        totalMins -= unc.break1secs / 60;
      }
      if (unc.break2secs && typeof unc.break2secs === "number") {
        totalMins -= unc.break2secs / 60;
      }
      if (unc.break1secs && typeof unc.break3secs === "number") {
        totalMins -= unc.break3secs / 60;
      }

      return `${dateStringer.formatMinsDurationToHours(totalMins)}`;
    };

    let shortenTeamName = (val) => {
      if (val && val.length > 15) {
        return `${val.substr(0, 13)}...`;
      } else {
        return val;
      }
    };

    let offsetMins = () => {
      if (unc.scheduledStartDs) {
        let diff =
          (dateStringer.createTimestampFromString(unc.startDs) -
            dateStringer.createTimestampFromString(unc.scheduledStartDs)) /
          1000 /
          60;

        let value = diff;

        if (diff < 0) {
          value *= -1;
        }
        return {
          value: value,
          early: diff < 0 ? true : false,
          late: diff > 0 ? true : false,
          onTime: diff === 0 ? true : false,
        };
      } else {
        return false;
      }
    };

    let brkStatus = () => {
      let onBreakNumber = 0;
      let brkStart = "";
      if (unc.break1startDs && !unc.break1endDs) {
        onBreakNumber = 1;
        brkStart = unc.break1startDs;
      }

      if (unc.break2startDs && !unc.break2endDs) {
        onBreakNumber = 2;
        brkStart = unc.break2startDs;
      }

      if (unc.break3startDs && !unc.break3endDs) {
        onBreakNumber = 3;
        brkStart = unc.break3startDs;
      }
      if (onBreakNumber) {
        return {
          brkNum: onBreakNumber,
          brkStart,
        };
      } else {
        return false;
      }
    };

    return (
      <div className="unclockParent">
        <div
          className={`clockedOnUnit ${!initialLoad ? "zoomIn_" : ""} ${
            selectedShiftID && selectedShiftID === unc.clockOnID
              ? "selectedActiveClockUnit"
              : "extendBlueLeftBorder clockedOnUnitX"
          }`}
          onClick={() => {
            // console.log({ invalidPeriodItem });
            setShowAbsentOptions(false);
            if (multiSelect) {
              if (unc.clockOnID) {
                if (selectedShiftIDs.includes(unc.clockOnID)) {
                  let newArr = [];
                  selectedShiftIDs.forEach((sh) => {
                    if (sh !== unc.clockOnID) {
                      newArr.push(sh);
                    }
                  });
                  setSelectedShiftIDs(newArr);
                } else {
                  let newArr = [...selectedShiftIDs, unc.clockOnID];
                  setSelectedShiftIDs(newArr);
                }
              }
            } else {
              if (selectedShiftID && selectedShiftID === unc.clockOnID) {
                setSelectedShiftID("");
                setShowGpsID("");
              } else {
                if (unc.clockOnID) {
                  setSelectedShiftID(unc.clockOnID);
                  setShowGpsID("");
                }
              }
            }
          }}
          key={unc.clockOnID}
        >
          <div className="unclockUnitNameAndTeamDiv">
            <div className="unclockNameAndIconDiv">
              <div className="unclockIconDiv">
                <img
                  src={clockedOn}
                  className="clockedOnIcon"
                  alt="Unclocked shift"
                />
              </div>
              <p className="unclockNameString">
                {unc.fName} {unc.lName ? unc.lName[0] : ""}
              </p>
            </div>
            <p className="unclockTeamName">{shortenTeamName(unc.teamName)}</p>
          </div>
          <div className="unclockUnitBody">
            <div className="tilShiftSection0">
              <div
                className={`${multiSelect ? "multiSelectUnlockRadio" : "none"}`}
                onClick={() => {}}
              >
                <img
                  src={checkbox2}
                  className={`${
                    selectedShiftIDs.includes(unc.clockOnID)
                      ? "unclockTickImg"
                      : "none"
                  }`}
                  alt="Select"
                />
                <div
                  className={`${
                    selectedShiftIDs.includes(unc.clockOnID)
                      ? "none"
                      : "unselectedUnclockRadio"
                  }`}
                ></div>
              </div>
            </div>
            <div
              className={`clockedOnSection1 ${
                multiSelect
                  ? ""
                  : `${
                      memoVals.mobModal
                        ? "unclockSection1"
                        : "unclockSection1Desktop"
                    }`
              }`}
            >
              <p className="activeClockOnTitle">Clocked on:</p>
              <div
                className={`unclockedDsString ${
                  printShiftDurationSubTxt() === "0m" ? "noBorder" : ""
                }`}
              >
                <div className="clockedOnAgoSpan">
                  {dateStringer.printedDateFromDs(startDs) ===
                  dateStringer.printedDateFromDs(
                    dateStringer.createStringFromTimestamp(new Date().getTime())
                  )
                    ? "Today"
                    : dateStringer.printedDateFromDs(startDs)}
                </div>{" "}
                {/* {dateStringer.dsToTimeStrip(startDs)} */}
                {startDs.substr(12, 2)}:{startDs.substr(15, 2)}{" "}
                {unc.scheduledStartDs ? (
                  !offsetMins().onTime ? (
                    <div className="scheduledActiveClockOffset">
                      {dateStringer.formatMinsDurationToHours(
                        offsetMins().value,
                        true
                      )}{" "}
                      {offsetMins().late
                        ? "late"
                        : `${offsetMins().onTime ? "On time" : "early"}`}
                    </div>
                  ) : (
                    <img
                      src={onTimeTick}
                      className="onTimeTickImg"
                      alt="On time"
                    />
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="clockActiveStartDiv">
                <p
                  className={`clockedOnStripHolder ${
                    selectedShiftID && selectedShiftID === unc.clockOnID
                      ? "x213982"
                      : ""
                  }`}
                >
                  <span className="activeClockAwaitingClockOffSpan">
                    {unc.scheduledStartDs
                      ? "Scheduled start:"
                      : "No shift scheduled"}
                  </span>
                  {unc.scheduledStartDs ? (
                    <span className="activeClockOnStartSpan">
                      {unc.scheduledStartDs.substr(12, 2)}:
                      {unc.scheduledStartDs.substr(15, 2)}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            <div className="tilShiftSection2 unclockSection2">
              <p className="activeClockedDurMins">Payable duration:</p>

              <p className="activeClockedDurString">
                {/* {unit.type === "shift"
              ? "Shift"
              : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`} */}
                {printShiftDurationSubTxt()}
              </p>
              <p
                className={`myShiftsUnitDurationSubTxt  ${
                  printShiftDurationSubTxt() === "0m" ? "none" : ""
                }`}
              >
                {dateStringer.formatMinsDurationToHours(
                  (unc.break1secs || 0) / 60 +
                    (unc.break2secs || 0) / 60 +
                    (unc.break3secs || 0) / 60
                )}{" "}
                break
              </p>
            </div>
          </div>
        </div>
        <div
          className={`${
            selectedShiftID && selectedShiftID === unc.clockOnID
              ? `${
                  unc.scheduledStartDs
                    ? `${
                        showAbsentOptions
                          ? "unclockItemMenuDiv2"
                          : "unclockItemMenuDivSched"
                      }`
                    : "unclockItemMenuDivNoSched"
                }`
              : "none"
          }`}
        >
          <div
            className={`unclockMenuL ${loading ? "disableUnclockActions" : ""}`}
          >
            {unc.gpsRequired ? (
              unc.gpsRequiredButNotRecorded ? (
                <div className="clockedOnGpsRow">Last clocked without GPS</div>
              ) : (
                <div className="clockedOnGpsRow">
                  Last clocked{" "}
                  <span className="colourWhite">
                    {dateStringer.convertMetresToKm(unc.lastClockedDistance)}
                  </span>{" "}
                  away from{" "}
                  <span className="colourWhite">
                    {unc.lastClockedZone || "Unknown"}
                  </span>
                </div>
              )
            ) : (
              ""
            )}
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                if (invalidTimes(unc.userID)) {
                } else {
                  // actionFct("clockOff", unc.clockOnID);
                  setClockOffInput(
                    `${dateStringer
                      .createStringFromTimestamp(new Date().getTime())
                      .substr(12, 2)}:${dateStringer
                      .createStringFromTimestamp(new Date().getTime())
                      .substr(15, 2)}`
                  );
                  setClockOffDs(
                    dateStringer.createStringFromTimestamp(new Date().getTime())
                  );

                  setShowClockOffModal({
                    show: true,
                    userID: unc.userID,
                    fName: unc.fName,
                    clockOnID: unc.clockOnID,
                  });
                }
              }}
            >
              Clock off
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                setShowCancelModal({
                  clockOnID: unc.clockOnID,
                  fName: unc.fName,
                });
              }}
            >
              Cancel clock card
            </p>
            {showCancelModal && showCancelModal.clockOnID ? (
              <div
                className="sureCloseAvailModalUnderlay"
                onClick={() => {
                  setShowCancelModal(false);
                }}
              >
                <div className="formCreatedModal">
                  <p className="overlapsRenTxt">
                    Cancelling this clock card will clock{" "}
                    {showCancelModal.fName} off and delete the clock card.
                    <br />
                    <br />
                    Are you sure you want to do this?
                  </p>
                  <div className="areYouModalBtnsDiv">
                    <p
                      className="areYouSureModalYesBtn"
                      onClick={() => {
                        actionFct("delete", unc.clockOnID);
                      }}
                    >
                      Yes
                    </p>
                    <p
                      className="areYouModalNoBtn"
                      onClick={() => {
                        setShowCancelModal(false);
                      }}
                    >
                      No
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {unc.scheduledStartDs ? (
              <div className="unclockBtnDivider"></div>
            ) : (
              ""
            )}
            {!showAbsentOptions &&
            unc.scheduledStartDs &&
            new Date().getTime() <
              dateStringer.createTimestampFromString(unc.scheduledEndDs) ? (
              <div
                className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                  !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
                } `}
                onClick={() => {
                  setShowAbsentOptions(true);
                }}
              >
                Clock off as absent
              </div>
            ) : (
              ""
            )}

            {showAbsentOptions && unc.scheduledStartDs ? (
              <div className="activeClockAbsentOptionsDiv">
                <div className="activeClockAbsentTitle">
                  Clock off as absent:
                </div>
                <div className="activeClockAbsentBtns">
                  <div
                    className="activeClockAbsentButton"
                    onClick={() => {
                      actionFct("absentPaid", unc.clockOnID);
                    }}
                  >
                    Paid
                  </div>
                  <div
                    className="activeClockAbsentButton"
                    onClick={() => {
                      actionFct("absentUnpaid", unc.clockOnID);
                    }}
                  >
                    Unpaid
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="unclockMenuR">
            {!unc.isSelf ? (
              <p
                className="chatActionItem"
                onClick={() => {
                  // open chat header
                  memoVals.setShowMessages((x) => unc.userID);
                }}
              >
                Chat
              </p>
            ) : (
              ""
            )}

            {unc.shiftID || unc.tilID ? (
              <p
                className="activeClockViewShift"
                onClick={() => {
                  // open shift
                  memoVals.setOpenManagerShiftModal((x) => {
                    return {
                      shiftID: unc.shiftID ? unc.shiftID : "",
                      tilID: unc.tilID ? unc.tilID : "",
                      fName: unc.fName,
                      lName: unc.lName,
                      openedFromShiftsPage: false,
                      openedFromUnclockedPage: true,
                      type: unc.shiftID ? "shift" : "til",
                    };
                  });
                }}
              >
                View
              </p>
            ) : (
              ""
            )}

            <div
              className="activeClockGPSBtn"
              onClick={() => {
                clearEdits();
                if (showGpsID === unc.clockOnID) {
                  setShowGpsID("");
                } else {
                  setShowGpsID(unc.clockOnID);
                }
              }}
            >
              <img src={threeLines} alt="GPS" className="clockedDataIconImg" />
              {/* <img src={edit} alt="GPS" className="clockedDataIconImg2" /> */}

              {/* Data */}
              {showGpsID === unc.clockOnID ? (
                <img
                  src={whitecross}
                  alt="closeGPSsub"
                  className="closeGPSsub"
                />
              ) : (
                <img
                  src={downArrowWhite}
                  alt="closeGPSsub"
                  className="closeGPSsub"
                />
              )}
            </div>
          </div>
        </div>{" "}
        {showGpsID === unc.clockOnID ? (
          <div className="activeClockGPSbox">
            {/* <p className="clockedGPStitle">Clock data</p> */}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {unc.break3endDs ? (
              <div className="clockedDataTimeRow brkLeftBordPurp">
                <p className="clockedDataTimeRowL">Break 3 ended</p>
                <p className="clockedDataTimeRowR">
                  {editB3end ? (
                    <input
                      value={b3endValue}
                      type="time"
                      className={`activeClockInputEdit`}
                      onChange={(e) => {
                        setB3EndValue(e.target.value);
                      }}
                    ></input>
                  ) : (
                    dateStringer.dsToTimeStrip(unc.break3endDs, true)
                  )}{" "}
                  {editB3end ? (
                    <p
                      className={`saveEditClockTimeBtn ${
                        isTimeInputValid(b3endValue)
                          ? ""
                          : "invalidClockTimeInput"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/edit-active-clock-record`,
                            {
                              clockOnID: unc.clockOnID,
                              code: "b3end",
                              value: b3endValue,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setLoadUnclocks(
                                (x) => !memoVals.loadUnclocks
                              );

                              let newArr = [];
                              activeClocks.forEach((c) => {
                                if (c.clockOnID === unc.clockOnID) {
                                  // update existing
                                  let newObj = c;
                                  // period1secs,
                                  // valueDs,
                                  // valueTs,
                                  // minus24Ticked,
                                  // newObj["period2secs"] =
                                  //   response.data.period2secs;
                                  newObj["break3secs"] =
                                    response.data.break3secs;
                                  newObj["break3endDs"] = response.data.valueDs;

                                  newArr.push(newObj);

                                  // if(response.data.minus24Ticked) {
                                  //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                  // }
                                } else {
                                  newArr.push(c);
                                }
                              });

                              setActiveClocks(newArr);
                              setEditB3end(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {/* Save{" "} */}
                      <img
                        src={whiteTick}
                        alt="Save"
                        className={`saveActiveClockEditPen`}
                      />
                    </p>
                  ) : (
                    <img
                      src={edit}
                      alt="Edit clocked time"
                      className={`editClockedImgWh ${
                        !memoVals.mobModal ? "editClockedImgWhDesktop" : ""
                      }`}
                      onClick={() => {
                        setB3EndValue(dateStringer.hhmmFromDs(unc.break3endDs));
                        setEditClockOn(false);
                        setEditB1start(false);
                        setEditB1end(false);
                        setEditB2start(false);
                        setEditB2end(false);
                        setEditB3start(false);
                        setEditB3end(true);
                      }}
                    />
                  )}
                  {!editB3end ? (
                    <p className="clockRowDs">
                      <img
                        src={minus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b3end",
                                value: dateStringer.hhmmFromDs(unc.break3endDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                minus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    // newObj["period2secs"] =
                                    //   response.data.period2secs;
                                    newObj["break3endDs"] =
                                      response.data.valueDs;
                                    newObj["break3secs"] =
                                      response.data.break3secs;
                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                      {dateStringer.printedDateFromDs(unc.break3endDs)}{" "}
                      <img
                        src={plus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b3end",
                                value: dateStringer.hhmmFromDs(unc.break3endDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                plus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    // newObj["period2secs"] =
                                    //   response.data.period2secs;
                                    newObj["break3endDs"] =
                                      response.data.valueDs;
                                    newObj["break3secs"] =
                                      response.data.break3secs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            {unc.break3endDs &&
            unc.gpsData &&
            typeof unc.gpsData.break3endLat === "number" ? (
              <div className="activeClockGPSrow">
                <div className="acitveClockGPScol2">
                  <div className="activeClockGPSUpper">
                    <div className="locRecordedDiv">
                      <img
                        src={gps2}
                        alt="GPS"
                        className="activeClockGPSrowGPSimg"
                      />
                      <p className="activeclockGPSUpperLeft">
                        Location recorded
                      </p>
                    </div>
                    <p className="activeclockGPSUpperRight gpsAccSmallTxt">
                      {Math.round(unc.gpsData.break3endAcc || 0)}m accuracy
                    </p>
                  </div>
                  <div className="activeClockGPSLower">
                    <div className="activeGpsLowerLeft">
                      <p className="activeclockGPSUpperLeft gpsClockedStatus">
                        {" "}
                        {shortenZone(unc.gpsData.break3endZone) ||
                          `${parseFloat(unc.gpsData.break3endLon).toFixed(
                            5
                          )}, ${parseFloat(unc.gpsData.break3endLat).toFixed(
                            5
                          )}`}
                      </p>
                      <p
                        className={`activeclockGPSUpperRight ${
                          !unc.gpsData.break3endZone || 0 ? "invis" : ""
                        }`}
                      >
                        {dateStringer.convertMetresToKm(
                          unc.gpsData.break3endDis || 0
                        )}{" "}
                        away
                      </p>
                    </div>
                    <a
                      className="activeClockGPSmapBtn"
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${unc.gpsData.break3endLat},${unc.gpsData.break3endLon}`}
                    >
                      Map{" "}
                      <img src={popup} alt="Maps" className="gpsMapPopUpIcon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : unc.break3endDs ? (
              <p className="activeClockNoGPSdiv">
                <img src={gps1} alt="GPS" className="activeClockGPSrowGPSimg" />{" "}
                No GPS recorded
              </p>
            ) : (
              ""
            )}
            {unc.break3endDs ? (
              <div
                className={`clockActiveDividerTxtBrk ${
                  unc.break3secs < 0 ? "warnRedMinus" : ""
                }`}
              >
                {" "}
                <span className="clockActDivPart">
                  Third break - unpaid:
                </span>{" "}
                {dateStringer.formatMinsDurationToHours(unc.break3secs / 60)}
              </div>
            ) : (
              ""
            )}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {unc.break3startDs ? (
              <div className="clockedDataTimeRow brkLeftBordPurp">
                <p className="clockedDataTimeRowL">Break 3 started</p>
                <p className="clockedDataTimeRowR">
                  {editB3start ? (
                    <input
                      value={b3startValue}
                      type="time"
                      className={`activeClockInputEdit`}
                      onChange={(e) => {
                        setB3StartValue(e.target.value);
                      }}
                    ></input>
                  ) : (
                    dateStringer.dsToTimeStrip(unc.break3startDs, true)
                  )}{" "}
                  {editB3start ? (
                    <p
                      className={`saveEditClockTimeBtn ${
                        isTimeInputValid(b3startValue)
                          ? ""
                          : "invalidClockTimeInput"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/edit-active-clock-record`,
                            {
                              clockOnID: unc.clockOnID,
                              code: "b3start",
                              value: b3startValue,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setLoadUnclocks(
                                (x) => !memoVals.loadUnclocks
                              );

                              let newArr = [];
                              activeClocks.forEach((c) => {
                                if (c.clockOnID === unc.clockOnID) {
                                  // update existing
                                  let newObj = c;
                                  // period1secs,
                                  // valueDs,
                                  // valueTs,
                                  // minus24Ticked,
                                  // newObj["period1secs"] =
                                  //   response.data.period1secs;
                                  newObj["break3secs"] =
                                    response.data.break3secs;
                                  newObj["break3startDs"] =
                                    response.data.valueDs;

                                  newArr.push(newObj);

                                  // if(response.data.minus24Ticked) {
                                  //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                  // }
                                } else {
                                  newArr.push(c);
                                }
                              });

                              setActiveClocks(newArr);
                              setEditB3start(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {/* Save{" "} */}
                      <img
                        src={whiteTick}
                        alt="Save"
                        className={`saveActiveClockEditPen`}
                      />
                    </p>
                  ) : (
                    <img
                      src={edit}
                      alt="Edit clocked time"
                      className={`editClockedImgWh ${
                        !memoVals.mobModal ? "editClockedImgWhDesktop" : ""
                      }`}
                      onClick={() => {
                        setB3StartValue(
                          dateStringer.hhmmFromDs(unc.break3startDs)
                        );
                        setEditClockOn(false);
                        setEditB1start(false);
                        setEditB1end(false);
                        setEditB2start(false);
                        setEditB2end(false);
                        setEditB3start(true);
                        setEditB3end(false);
                      }}
                    />
                  )}
                  {!editB3start ? (
                    <p className="clockRowDs">
                      <img
                        src={minus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b3start",
                                value: dateStringer.hhmmFromDs(
                                  unc.break3startDs
                                ),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                minus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    // newObj["period1secs"] =
                                    //   response.data.period1secs;
                                    newObj["break3startDs"] =
                                      response.data.valueDs;
                                    newObj["break3secs"] =
                                      response.data.break3secs;
                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                      {dateStringer.printedDateFromDs(unc.break3startDs)}{" "}
                      <img
                        src={plus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b3start",
                                value: dateStringer.hhmmFromDs(
                                  unc.break3startDs
                                ),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                plus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                // console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    // newObj["period1secs"] =
                                    //   response.data.period1secs;
                                    newObj["break3startDs"] =
                                      response.data.valueDs;
                                    newObj["break3secs"] =
                                      response.data.break3secs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            {unc.break3startDs &&
            unc.gpsData &&
            typeof unc.gpsData.break3startLat === "number" ? (
              <div className="activeClockGPSrow">
                <div className="acitveClockGPScol2">
                  <div className="activeClockGPSUpper">
                    <div className="locRecordedDiv">
                      <img
                        src={gps2}
                        alt="GPS"
                        className="activeClockGPSrowGPSimg"
                      />
                      <p className="activeclockGPSUpperLeft">
                        Location recorded
                      </p>
                    </div>
                    <p className="activeclockGPSUpperRight gpsAccSmallTxt">
                      {Math.round(unc.gpsData.break3startAcc || 0)}m accuracy
                    </p>
                  </div>
                  <div className="activeClockGPSLower">
                    <div className="activeGpsLowerLeft">
                      <p className="activeclockGPSUpperLeft gpsClockedStatus">
                        {" "}
                        {shortenZone(unc.gpsData.break3startZone) ||
                          `${parseFloat(unc.gpsData.break3startLon).toFixed(
                            5
                          )}, ${parseFloat(unc.gpsData.break3startLat).toFixed(
                            5
                          )}`}
                      </p>
                      <p
                        className={`activeclockGPSUpperRight ${
                          !unc.gpsData.break3startZone ? "invis" : ""
                        }`}
                      >
                        {dateStringer.convertMetresToKm(
                          unc.gpsData.break3startDis || 0
                        )}{" "}
                        away
                      </p>
                    </div>
                    <a
                      className="activeClockGPSmapBtn"
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${unc.gpsData.break3startLat},${unc.gpsData.break3startLon}`}
                    >
                      Map{" "}
                      <img src={popup} alt="Maps" className="gpsMapPopUpIcon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : unc.break3startDs ? (
              <p className="activeClockNoGPSdiv">
                <img src={gps1} alt="GPS" className="activeClockGPSrowGPSimg" />{" "}
                No GPS recorded
              </p>
            ) : (
              ""
            )}
            {unc.break3startDs ? (
              <div className="clockActiveDivider"></div>
            ) : (
              ""
            )}
            {unc.break3startDs ? (
              <div
                className={`clockActiveDividerTxt ${
                  dateStringer.calcMinsBetweenTwoDateStrings(
                    unc.break2endDs,

                    unc.break3startDs
                  ) < 0
                    ? "warnRedMinus"
                    : ""
                }`}
              >
                {" "}
                <span className="clockActDivPart">
                  Third period - paid:
                </span>{" "}
                {dateStringer.formatMinsDurationToHours(
                  dateStringer.calcMinsBetweenTwoDateStrings(
                    unc.break2endDs,

                    unc.break3startDs
                  )
                )}
              </div>
            ) : (
              ""
            )}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {unc.break2endDs ? (
              <div className="clockedDataTimeRow brkLeftBordPurp">
                <p className="clockedDataTimeRowL">Break 2 ended</p>
                <p className="clockedDataTimeRowR">
                  {editB2end ? (
                    <input
                      value={b2endValue}
                      type="time"
                      className={`activeClockInputEdit`}
                      onChange={(e) => {
                        setB2EndValue(e.target.value);
                      }}
                    ></input>
                  ) : (
                    dateStringer.dsToTimeStrip(unc.break2endDs, true)
                  )}{" "}
                  {editB2end ? (
                    <p
                      className={`saveEditClockTimeBtn ${
                        isTimeInputValid(b2endValue)
                          ? ""
                          : "invalidClockTimeInput"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/edit-active-clock-record`,
                            {
                              clockOnID: unc.clockOnID,
                              code: "b2end",
                              value: b2endValue,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setLoadUnclocks(
                                (x) => !memoVals.loadUnclocks
                              );

                              let newArr = [];
                              activeClocks.forEach((c) => {
                                if (c.clockOnID === unc.clockOnID) {
                                  // update existing
                                  let newObj = c;
                                  // period1secs,
                                  // valueDs,
                                  // valueTs,
                                  // minus24Ticked,
                                  // newObj["period2secs"] =
                                  //   response.data.period2secs;
                                  newObj["break2secs"] =
                                    response.data.break2secs;
                                  newObj["break2endDs"] = response.data.valueDs;

                                  newArr.push(newObj);

                                  // if(response.data.minus24Ticked) {
                                  //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                  // }
                                } else {
                                  newArr.push(c);
                                }
                              });

                              setActiveClocks(newArr);
                              setEditB2end(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {/* Save{" "} */}
                      <img
                        src={whiteTick}
                        alt="Save"
                        className={`saveActiveClockEditPen`}
                      />
                    </p>
                  ) : (
                    <img
                      src={edit}
                      alt="Edit clocked time"
                      className={`editClockedImgWh ${
                        !memoVals.mobModal ? "editClockedImgWhDesktop" : ""
                      }`}
                      onClick={() => {
                        setB2EndValue(dateStringer.hhmmFromDs(unc.break2endDs));
                        setEditClockOn(false);
                        setEditB1start(false);
                        setEditB1end(false);
                        setEditB2start(false);
                        setEditB2end(true);
                        setEditB3start(false);
                        setEditB3end(false);
                      }}
                    />
                  )}
                  {!editB2end ? (
                    <p className="clockRowDs">
                      <img
                        src={minus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b2end",
                                value: dateStringer.hhmmFromDs(unc.break2endDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                minus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    // newObj["period2secs"] =
                                    //   response.data.period2secs;
                                    newObj["break2endDs"] =
                                      response.data.valueDs;
                                    newObj["break2secs"] =
                                      response.data.break2secs;
                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                      {dateStringer.printedDateFromDs(unc.break2endDs)}{" "}
                      <img
                        src={plus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b2end",
                                value: dateStringer.hhmmFromDs(unc.break2endDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                plus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    // newObj["period2secs"] =
                                    //   response.data.period2secs;
                                    newObj["break2endDs"] =
                                      response.data.valueDs;
                                    newObj["break2secs"] =
                                      response.data.break2secs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            {unc.break2endDs &&
            unc.gpsData &&
            typeof unc.gpsData.break2endLat === "number" ? (
              <div className="activeClockGPSrow">
                <div className="acitveClockGPScol2">
                  <div className="activeClockGPSUpper">
                    <div className="locRecordedDiv">
                      <img
                        src={gps2}
                        alt="GPS"
                        className="activeClockGPSrowGPSimg"
                      />
                      <p className="activeclockGPSUpperLeft">
                        Location recorded
                      </p>
                    </div>
                    <p className="activeclockGPSUpperRight gpsAccSmallTxt">
                      {Math.round(unc.gpsData.break1endAcc || 0)}m accuracy
                    </p>
                  </div>
                  <div className="activeClockGPSLower">
                    <div className="activeGpsLowerLeft">
                      <p className="activeclockGPSUpperLeft gpsClockedStatus">
                        {" "}
                        {shortenZone(unc.gpsData.break1endZone) ||
                          `${parseFloat(unc.gpsData.break2endLon).toFixed(
                            5
                          )}, ${parseFloat(unc.gpsData.break2endLat).toFixed(
                            5
                          )}`}
                      </p>
                      <p
                        className={`activeclockGPSUpperRight ${
                          !unc.gpsData.break1endZone ? "invis" : ""
                        }`}
                      >
                        {dateStringer.convertMetresToKm(
                          unc.gpsData.break1endDis || 0
                        )}{" "}
                        away
                      </p>
                    </div>
                    <a
                      className="activeClockGPSmapBtn"
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${unc.gpsData.break2endLat},${unc.gpsData.break2endLon}`}
                    >
                      Map{" "}
                      <img src={popup} alt="Maps" className="gpsMapPopUpIcon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : unc.break2endDs ? (
              <p className="activeClockNoGPSdiv">
                <img src={gps1} alt="GPS" className="activeClockGPSrowGPSimg" />{" "}
                No GPS recorded
              </p>
            ) : (
              ""
            )}{" "}
            {unc.break2endDs ? (
              <div
                className={`clockActiveDividerTxtBrk ${
                  unc.break2secs < 0 ? "warnRedMinus" : ""
                }`}
              >
                <span className="clockActDivPart">Second break - unpaid:</span>{" "}
                {dateStringer.formatMinsDurationToHours(unc.break2secs / 60)}
              </div>
            ) : (
              ""
            )}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {unc.break2startDs ? (
              <div className="clockedDataTimeRow brkLeftBordPurp">
                <p className="clockedDataTimeRowL">Break 2 started</p>
                <p className="clockedDataTimeRowR">
                  {editB2start ? (
                    <input
                      value={b2startValue}
                      type="time"
                      className={`activeClockInputEdit`}
                      onChange={(e) => {
                        setB2StartValue(e.target.value);
                      }}
                    ></input>
                  ) : (
                    dateStringer.dsToTimeStrip(unc.break2startDs, true)
                  )}{" "}
                  {editB2start ? (
                    <p
                      className={`saveEditClockTimeBtn ${
                        isTimeInputValid(b2startValue)
                          ? ""
                          : "invalidClockTimeInput"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/edit-active-clock-record`,
                            {
                              clockOnID: unc.clockOnID,
                              code: "b2start",
                              value: b2startValue,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setLoadUnclocks(
                                (x) => !memoVals.loadUnclocks
                              );

                              let newArr = [];
                              activeClocks.forEach((c) => {
                                if (c.clockOnID === unc.clockOnID) {
                                  // update existing
                                  let newObj = c;
                                  // period1secs,
                                  // valueDs,
                                  // valueTs,
                                  // minus24Ticked,
                                  newObj["period2secs"] =
                                    response.data.period2secs;
                                  newObj["break2secs"] =
                                    response.data.break2secs;
                                  newObj["break2startDs"] =
                                    response.data.valueDs;

                                  newArr.push(newObj);

                                  // if(response.data.minus24Ticked) {
                                  //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                  // }
                                } else {
                                  newArr.push(c);
                                }
                              });

                              setActiveClocks(newArr);
                              setEditB2start(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {/* Save{" "} */}
                      <img
                        src={whiteTick}
                        alt="Save"
                        className={`saveActiveClockEditPen`}
                      />
                    </p>
                  ) : (
                    <img
                      src={edit}
                      alt="Edit clocked time"
                      className={`editClockedImgWh ${
                        !memoVals.mobModal ? "editClockedImgWhDesktop" : ""
                      }`}
                      onClick={() => {
                        setB2StartValue(
                          dateStringer.hhmmFromDs(unc.break2startDs)
                        );
                        setEditClockOn(false);
                        setEditB1start(false);
                        setEditB1end(false);
                        setEditB2start(true);
                        setEditB2end(false);
                        setEditB3start(false);
                        setEditB3end(false);
                      }}
                    />
                  )}
                  {!editB2start ? (
                    <p className="clockRowDs">
                      <img
                        src={minus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b2start",
                                value: dateStringer.hhmmFromDs(
                                  unc.break2startDs
                                ),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                minus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period2secs"] =
                                      response.data.period2secs;
                                    newObj["break2startDs"] =
                                      response.data.valueDs;
                                    newObj["break2secs"] =
                                      response.data.break2secs;
                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                      {dateStringer.printedDateFromDs(unc.break2startDs)}{" "}
                      <img
                        src={plus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b2start",
                                value: dateStringer.hhmmFromDs(
                                  unc.break2startDs
                                ),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                plus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period2secs"] =
                                      response.data.period2secs;
                                    newObj["break2startDs"] =
                                      response.data.valueDs;
                                    newObj["break2secs"] =
                                      response.data.break1secs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            {unc.break2startDs &&
            unc.gpsData &&
            typeof unc.gpsData.break2startLat === "number" ? (
              <div className="activeClockGPSrow">
                <div className="acitveClockGPScol2">
                  <div className="activeClockGPSUpper">
                    <div className="locRecordedDiv">
                      <img
                        src={gps2}
                        alt="GPS"
                        className="activeClockGPSrowGPSimg"
                      />
                      <p className="activeclockGPSUpperLeft">
                        Location recorded
                      </p>
                    </div>
                    <p className="activeclockGPSUpperRight gpsAccSmallTxt">
                      {Math.round(unc.gpsData.break2startAcc || 0)}m accuracy
                    </p>
                  </div>
                  <div className="activeClockGPSLower">
                    <div className="activeGpsLowerLeft">
                      <p className="activeclockGPSUpperLeft gpsClockedStatus">
                        {" "}
                        {shortenZone(unc.gpsData.break2startZone) ||
                          `${parseFloat(unc.gpsData.break2startLon).toFixed(
                            5
                          )}, ${parseFloat(unc.gpsData.break2startLat).toFixed(
                            5
                          )}`}
                      </p>
                      <p
                        className={`activeclockGPSUpperRight ${
                          !unc.gpsData.break2startZone ? "invis" : ""
                        }`}
                      >
                        {dateStringer.convertMetresToKm(
                          unc.gpsData.break2startDis || 0
                        )}{" "}
                        away
                      </p>
                    </div>
                    <a
                      className="activeClockGPSmapBtn"
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${unc.gpsData.break2startLat},${unc.gpsData.break2startLon}`}
                    >
                      Map{" "}
                      <img src={popup} alt="Maps" className="gpsMapPopUpIcon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : unc.break2startDs ? (
              <p className="activeClockNoGPSdiv">
                <img
                  src={gps1}
                  alt2="GPS"
                  className="activeClockGPSrowGPSimg"
                />{" "}
                No GPS recorded
              </p>
            ) : (
              ""
            )}
            {unc.break2startDs ? (
              <div className="clockActiveDivider"></div>
            ) : (
              ""
            )}
            {unc.break2startDs ? (
              <div
                className={`clockActiveDividerTxt ${
                  dateStringer.calcMinsBetweenTwoDateStrings(
                    unc.break1endDs,
                    unc.break2startDs
                  ) < 0
                    ? "warnRedMinus"
                    : ""
                }`}
              >
                {" "}
                <span className="clockActDivPart">
                  Second period - paid:
                </span>{" "}
                {dateStringer.formatMinsDurationToHours(
                  dateStringer.calcMinsBetweenTwoDateStrings(
                    unc.break1endDs,
                    unc.break2startDs
                  )
                )}
                {/* {dateStringer.secsToString(
                  unc.period2secs < 0 ? 0 : unc.period2secs
                )} */}
              </div>
            ) : (
              ""
            )}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {unc.break1endDs ? (
              <div className="clockedDataTimeRow brkLeftBordPurp">
                <p className="clockedDataTimeRowL">Break 1 ended</p>
                <p className="clockedDataTimeRowR">
                  {editB1end ? (
                    <input
                      value={b1endValue}
                      type="time"
                      className={`activeClockInputEdit`}
                      onChange={(e) => {
                        setB1EndValue(e.target.value);
                      }}
                    ></input>
                  ) : (
                    dateStringer.dsToTimeStrip(unc.break1endDs, true)
                  )}{" "}
                  {editB1end ? (
                    <p
                      className={`saveEditClockTimeBtn ${
                        isTimeInputValid(b1endValue)
                          ? ""
                          : "invalidClockTimeInput"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/edit-active-clock-record`,
                            {
                              clockOnID: unc.clockOnID,
                              code: "b1end",
                              value: b1endValue,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setLoadUnclocks(
                                (x) => !memoVals.loadUnclocks
                              );

                              let newArr = [];
                              activeClocks.forEach((c) => {
                                if (c.clockOnID === unc.clockOnID) {
                                  // update existing
                                  let newObj = c;
                                  // period1secs,
                                  // valueDs,
                                  // valueTs,
                                  // minus24Ticked,
                                  newObj["period2secs"] =
                                    response.data.period2secs;
                                  newObj["break1secs"] =
                                    response.data.break1secs;
                                  newObj["break1endDs"] = response.data.valueDs;

                                  newArr.push(newObj);

                                  // if(response.data.minus24Ticked) {
                                  //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                  // }
                                } else {
                                  newArr.push(c);
                                }
                              });

                              setActiveClocks(newArr);
                              setEditB1end(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {/* Save{" "} */}
                      <img
                        src={whiteTick}
                        alt="Save"
                        className={`saveActiveClockEditPen`}
                      />
                    </p>
                  ) : (
                    <img
                      src={edit}
                      alt="Edit clocked time"
                      className={`editClockedImgWh ${
                        !memoVals.mobModal ? "editClockedImgWhDesktop" : ""
                      }`}
                      onClick={() => {
                        setB1EndValue(dateStringer.hhmmFromDs(unc.break1endDs));
                        setEditClockOn(false);
                        setEditB1start(false);
                        setEditB1end(true);
                        setEditB2start(false);
                        setEditB2end(false);
                        setEditB3start(false);
                        setEditB3end(false);
                      }}
                    />
                  )}{" "}
                  {!editB1end ? (
                    <p className="clockRowDs">
                      <img
                        src={minus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b1end",
                                value: dateStringer.hhmmFromDs(unc.break1endDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                minus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period2secs"] =
                                      response.data.period2secs;
                                    newObj["break1endDs"] =
                                      response.data.valueDs;
                                    newObj["break1secs"] =
                                      response.data.break1secs;
                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                      {dateStringer.printedDateFromDs(unc.break1endDs)}{" "}
                      <img
                        src={plus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b1end",
                                value: dateStringer.hhmmFromDs(unc.break1endDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                plus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period2secs"] =
                                      response.data.period2secs;
                                    newObj["break1endDs"] =
                                      response.data.valueDs;
                                    newObj["break1secs"] =
                                      response.data.break1secs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            {unc.break1endDs &&
            unc.gpsData &&
            typeof unc.gpsData.break1endLat === "number" ? (
              <div className="activeClockGPSrow">
                <div className="acitveClockGPScol2">
                  <div className="activeClockGPSUpper">
                    <div className="locRecordedDiv">
                      <img
                        src={gps2}
                        alt="GPS"
                        className="activeClockGPSrowGPSimg"
                      />
                      <p className="activeclockGPSUpperLeft">
                        Location recorded
                      </p>
                    </div>
                    <p className="activeclockGPSUpperRight gpsAccSmallTxt">
                      {Math.round(unc.gpsData.break1endAcc || 0)}m accuracy
                    </p>
                  </div>
                  <div className="activeClockGPSLower">
                    <div className="activeGpsLowerLeft">
                      <p className="activeclockGPSUpperLeft gpsClockedStatus">
                        {" "}
                        {shortenZone(unc.gpsData.break1endZone) ||
                          `${parseFloat(unc.gpsData.break1endLon).toFixed(
                            5
                          )}, ${parseFloat(unc.gpsData.break1endLat).toFixed(
                            5
                          )}`}
                      </p>
                      <p
                        className={`activeclockGPSUpperRight ${
                          !unc.gpsData.break1endZone ? "invis" : ""
                        }`}
                      >
                        {dateStringer.convertMetresToKm(
                          unc.gpsData.break1endDis || 0
                        )}{" "}
                        away
                      </p>
                    </div>
                    <a
                      className="activeClockGPSmapBtn"
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${unc.gpsData.break1endLat},${unc.gpsData.break1endLon}`}
                    >
                      Map{" "}
                      <img src={popup} alt="Maps" className="gpsMapPopUpIcon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : unc.break1endDs ? (
              <p className="activeClockNoGPSdiv">
                <img src={gps1} alt="GPS" className="activeClockGPSrowGPSimg" />{" "}
                No GPS recorded
              </p>
            ) : (
              ""
            )}
            {unc.break1endDs ? (
              <div
                className={`clockActiveDividerTxtBrk ${
                  unc.break1secs < 0 ? "warnRedMinus" : ""
                }`}
              >
                {" "}
                <span className="clockActDivPart">
                  First break - unpaid:
                </span>{" "}
                {dateStringer.formatMinsDurationToHours(unc.break1secs / 60)}
              </div>
            ) : (
              ""
            )}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {unc.break1startDs ? (
              <div className="clockedDataTimeRow brkLeftBordPurp">
                <p className="clockedDataTimeRowL">Break 1 started</p>
                <p className="clockedDataTimeRowR">
                  {editB1start ? (
                    <input
                      value={b1startValue}
                      type="time"
                      className={`activeClockInputEdit`}
                      onChange={(e) => {
                        setB1StartValue(e.target.value);
                      }}
                    ></input>
                  ) : (
                    dateStringer.dsToTimeStrip(unc.break1startDs, true)
                  )}{" "}
                  {editB1start ? (
                    <p
                      className={`saveEditClockTimeBtn ${
                        isTimeInputValid(b1startValue)
                          ? ""
                          : "invalidClockTimeInput"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/edit-active-clock-record`,
                            {
                              clockOnID: unc.clockOnID,
                              code: "b1start",
                              value: b1startValue,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setLoadUnclocks(
                                (x) => !memoVals.loadUnclocks
                              );

                              let newArr = [];
                              activeClocks.forEach((c) => {
                                if (c.clockOnID === unc.clockOnID) {
                                  // update existing
                                  let newObj = c;
                                  // period1secs,
                                  // valueDs,
                                  // valueTs,
                                  // minus24Ticked,
                                  newObj["period1secs"] =
                                    response.data.period1secs;
                                  newObj["break1secs"] =
                                    response.data.break1secs;
                                  newObj["break1startDs"] =
                                    response.data.valueDs;

                                  newArr.push(newObj);

                                  // if(response.data.minus24Ticked) {
                                  //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                  // }
                                } else {
                                  newArr.push(c);
                                }
                              });

                              setActiveClocks(newArr);
                              setEditB1start(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {/* Save{" "} */}
                      <img
                        src={whiteTick}
                        alt="Save"
                        className={`saveActiveClockEditPen`}
                      />
                    </p>
                  ) : (
                    <img
                      src={edit}
                      alt="Edit clocked time"
                      className={`editClockedImgWh ${
                        !memoVals.mobModal ? "editClockedImgWhDesktop" : ""
                      }`}
                      onClick={() => {
                        setB1StartValue(
                          dateStringer.hhmmFromDs(unc.break1startDs)
                        );
                        setEditClockOn(false);
                        setEditB1start(true);
                        setEditB1end(false);
                        setEditB2start(false);
                        setEditB2end(false);
                        setEditB3start(false);
                        setEditB3end(false);
                      }}
                    />
                  )}
                  {!editB1start ? (
                    <p className="clockRowDs">
                      <img
                        src={minus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b1start",
                                value: dateStringer.hhmmFromDs(
                                  unc.break1startDs
                                ),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                minus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period1secs"] =
                                      response.data.period1secs;
                                    newObj["break1startDs"] =
                                      response.data.valueDs;
                                    newObj["break1secs"] =
                                      response.data.break1secs;
                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                      {dateStringer.printedDateFromDs(unc.break1startDs)}{" "}
                      <img
                        src={plus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "b1start",
                                value: dateStringer.hhmmFromDs(
                                  unc.break1startDs
                                ),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                plus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period1secs"] =
                                      response.data.period1secs;
                                    newObj["break1startDs"] =
                                      response.data.valueDs;
                                    newObj["break1secs"] =
                                      response.data.break1secs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            {unc.break1startDs &&
            unc.gpsData &&
            typeof unc.gpsData.break1startLat === "number" ? (
              <div className="activeClockGPSrow">
                <div className="acitveClockGPScol2">
                  <div className="activeClockGPSUpper">
                    <div className="locRecordedDiv">
                      <img
                        src={gps2}
                        alt="GPS"
                        className="activeClockGPSrowGPSimg"
                      />
                      <p className="activeclockGPSUpperLeft">
                        Location recorded
                      </p>
                    </div>
                    <p className="activeclockGPSUpperRight gpsAccSmallTxt">
                      {Math.round(unc.gpsData.break1startAcc || 0)}m accuracy
                    </p>
                  </div>
                  <div className="activeClockGPSLower">
                    <div className="activeGpsLowerLeft">
                      <p className="activeclockGPSUpperLeft gpsClockedStatus">
                        {" "}
                        {shortenZone(unc.gpsData.break1startZone) ||
                          `${parseFloat(unc.gpsData.break1startLat).toFixed(
                            5
                          )}, ${parseFloat(unc.gpsData.break1startLon).toFixed(
                            5
                          )}`}
                      </p>
                      <p
                        className={`activeclockGPSUpperRight ${
                          !unc.gpsData.break1startZone ? "invis" : ""
                        }`}
                      >
                        {dateStringer.convertMetresToKm(
                          unc.gpsData.break1startDis || 0
                        )}{" "}
                        away
                      </p>
                    </div>
                    <a
                      className="activeClockGPSmapBtn"
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${unc.gpsData.break1startLat},${unc.gpsData.break1startLon}`}
                    >
                      Map{" "}
                      <img src={popup} alt="Maps" className="gpsMapPopUpIcon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : unc.break1startDs ? (
              <p className="activeClockNoGPSdiv">
                <img src={gps1} alt="GPS" className="activeClockGPSrowGPSimg" />{" "}
                No GPS recorded
              </p>
            ) : (
              ""
            )}
            {unc.break1startDs ? (
              <div className="clockActiveDivider"></div>
            ) : (
              ""
            )}
            {unc.break1startDs ? (
              <div
                className={`clockActiveDividerTxt ${
                  dateStringer.calcMinsBetweenTwoDateStrings(
                    unc.startDs,
                    unc.break1startDs
                  ) < 0
                    ? "warnRedMinus"
                    : ""
                }`}
              >
                <span className="clockActDivPart">First period - paid:</span>{" "}
                {/* {dateStringer.secsToString(
                  unc.period1secs < 0 ? 0 : unc.period1secs
                )} */}
                {dateStringer.formatMinsDurationToHours(
                  dateStringer.calcMinsBetweenTwoDateStrings(
                    unc.startDs,
                    unc.break1startDs
                  )
                )}
              </div>
            ) : (
              ""
            )}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
            {unc.startDs ? (
              <div className="clockedDataTimeRow brkLeftBordGreen">
                <p className="clockedDataTimeRowL">Clocked on</p>
                <p className="clockedDataTimeRowR">
                  {editClockOn ? (
                    <input
                      value={editClockOnVal}
                      type="time"
                      className={`activeClockInputEdit`}
                      onChange={(e) => {
                        setEditClockOnVal(e.target.value);
                      }}
                    ></input>
                  ) : (
                    dateStringer.dsToTimeStrip(unc.startDs, true)
                  )}{" "}
                  {editClockOn ? (
                    <p
                      className={`saveEditClockTimeBtn ${
                        isTimeInputValid(editClockOnVal)
                          ? ""
                          : "invalidClockTimeInput"
                      }`}
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/edit-active-clock-record`,
                            {
                              clockOnID: unc.clockOnID,
                              code: "clockOn",
                              value: editClockOnVal,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setLoadUnclocks(
                                (x) => !memoVals.loadUnclocks
                              );

                              console.log({ response });
                              let newArr = [];
                              activeClocks.forEach((c) => {
                                if (c.clockOnID === unc.clockOnID) {
                                  // update existing
                                  let newObj = c;
                                  // period1secs,
                                  // valueDs,
                                  // valueTs,
                                  // minus24Ticked,
                                  newObj["period1secs"] =
                                    response.data.period1secs;
                                  newObj["startDs"] = response.data.valueDs;

                                  newArr.push(newObj);

                                  // if(response.data.minus24Ticked) {
                                  //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                  // }
                                } else {
                                  newArr.push(c);
                                }
                              });

                              setActiveClocks(newArr);
                              setEditClockOn(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      {/* Save{" "} */}
                      <img
                        src={whiteTick}
                        alt="Save"
                        className={`saveActiveClockEditPen`}
                      />
                    </p>
                  ) : (
                    <img
                      src={edit}
                      alt="Edit clocked time"
                      className={`editClockedImgWh ${
                        !memoVals.mobModal ? "editClockedImgWhDesktop" : ""
                      }`}
                      onClick={() => {
                        setEditClockOnVal(dateStringer.hhmmFromDs(unc.startDs));
                        setEditClockOn(true);
                        setEditB1start(false);
                        setEditB1end(false);
                        setEditB2start(false);
                        setEditB2end(false);
                        setEditB3start(false);
                        setEditB3end(false);
                      }}
                    />
                  )}
                  {!editClockOn ? (
                    <p className="clockRowDs">
                      <img
                        src={minus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "clockOn",
                                value: dateStringer.hhmmFromDs(unc.startDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                minus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );

                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period1secs"] =
                                      response.data.period1secs;
                                    newObj["startDs"] = response.data.valueDs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                      {dateStringer.printedDateFromDs(unc.startDs)}{" "}
                      <img
                        src={plus}
                        alt="Deduct one day"
                        className="clockDeductImg"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/edit-active-clock-record`,
                              {
                                clockOnID: unc.clockOnID,
                                code: "clockOn",
                                value: dateStringer.hhmmFromDs(unc.startDs),
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                plus24: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setLoadUnclocks(
                                  (x) => !memoVals.loadUnclocks
                                );
                                console.log({ response });
                                let newArr = [];
                                activeClocks.forEach((c) => {
                                  if (c.clockOnID === unc.clockOnID) {
                                    // update existing
                                    let newObj = c;
                                    // period1secs,
                                    // valueDs,
                                    // valueTs,
                                    // minus24Ticked,
                                    newObj["period1secs"] =
                                      response.data.period1secs;
                                    newObj["startDs"] = response.data.valueDs;

                                    newArr.push(newObj);

                                    // if(response.data.minus24Ticked) {
                                    //   setShowMinus24TickedModal({show: true, message1: `This new clock on start`})
                                    // }
                                  } else {
                                    newArr.push(c);
                                  }
                                });

                                setActiveClocks(newArr);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            {unc.startDs &&
            unc.gpsData &&
            typeof unc.gpsData.clockOnLat === "number" ? (
              <div className="activeClockGPSrow">
                <div className="acitveClockGPScol2">
                  <div className="activeClockGPSUpper">
                    <div className="locRecordedDiv">
                      <img
                        src={gps2}
                        alt="GPS"
                        className="activeClockGPSrowGPSimg"
                      />
                      <p className="activeclockGPSUpperLeft">
                        Location recorded
                      </p>
                    </div>
                    <p className="activeclockGPSUpperRight gpsAccSmallTxt">
                      {Math.round(unc.gpsData.clockOnAcc || 0)}m accuracy
                    </p>
                  </div>
                  <div className="activeClockGPSLower">
                    <div className="activeGpsLowerLeft">
                      <p className="activeclockGPSUpperLeft gpsClockedStatus">
                        {" "}
                        {shortenZone(unc.gpsData.clockOnZone) ||
                          `${parseFloat(unc.gpsData.clockOnLat).toFixed(
                            5
                          )}, ${parseFloat(unc.gpsData.clockOnLon).toFixed(5)}`}
                      </p>
                      <p
                        className={`activeclockGPSUpperRight ${
                          !unc.gpsData.clockOnZone ? "invis" : ""
                        }`}
                      >
                        {dateStringer.convertMetresToKm(
                          unc.gpsData.clockOnDis || 0
                        )}{" "}
                        away
                      </p>
                    </div>
                    <a
                      className="activeClockGPSmapBtn"
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${unc.gpsData.clockOnLat},${unc.gpsData.clockOnLon}`}
                    >
                      Map
                      <img src={popup} alt="Maps" className="gpsMapPopUpIcon" />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <p className="activeClockNoGPSdiv">
                <img src={gps1} alt="GPS" className="activeClockGPSrowGPSimg" />{" "}
                No GPS recorded
              </p>
            )}
            {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
          </div>
        ) : (
          ""
        )}
        {/* {brkStatus() && selectedShiftID !== unc.clockOnID ? ( */}
        <div
          className={`activeClockStatusDiv ${
            (brkStatus() && selectedShiftID !== unc.clockOnID) ||
            unc.showGpsFlag
              ? ""
              : "none"
          }`}
        >
          <div
            className={`activeClockBannerLeft ${
              brkStatus() && selectedShiftID !== unc.clockOnID ? "" : "invis"
            }`}
          >
            <p className="activeClockBrkTxt">
              On{" "}
              {brkStatus().brkNum === 1
                ? "first"
                : brkStatus().brkNum === 2
                ? "second"
                : "third"}{" "}
              break
            </p>
            <p className="activeClockBrkTxtAgo">
              since {dateStringer.dsToTimeStrip(brkStatus().brkStart, true)}
            </p>
          </div>

          {(selectedShiftID && selectedShiftID === unc.clockOnID) ||
          !unc.gpsRequired ? (
            ""
          ) : (
            <div className="activeClockBannerRight">
              <img
                src={
                  unc.gpsRequiredButNotRecorded
                    ? gps3
                    : unc.lastClockedDistance < 200
                    ? gps2
                    : gps3
                }
                alt="GPS"
                className="clockedGpsStatusImg"
              />
              <p className="lastClockedDistance">
                {unc.gpsRequiredButNotRecorded
                  ? "?"
                  : dateStringer.convertMetresToKm(unc.lastClockedDistance)}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  });

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`clockOnModalUnderlay ${
        memoVals.showEditUser ||
        memoVals.openManagerShiftModal.type ||
        memoVals.showEditShiftModal ||
        memoVals.showMessages
          ? "invis"
          : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn_"
            }`}
          />
        </div>
      ) : (
        <div
          className={`clockOnModalBox whiteBg ${
            memoVals.mobModal
              ? "mobUnclockModalBox slideUp mobModalShoulder"
              : "zoomIn_"
          } `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperActiveClocks"></div>
          )}
          <div
            className="clockOnModalHeader"
            onClick={() => {
              if (memoVals.mobModal) {
                closeModal();
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={handleMove}
            onTouchEnd={handleUp}
            onMouseDown={handleDown}
            onMouseMove={handleMove}
            onMouseUp={handleUp}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
            <p
              className="clockOnModalHeaderTitle"
              onClick={() => {
                // console.log({ memo: memoVals.mobModal, nonMemo: mobModal });
                // console.log(memoVals);
              }}
            >
              Today
              {/* Clocked on */}
            </p>
            <div className="clockOnModalHeaderSideUnit"></div>
            {/* <div className={`clockTicker ${durString ? "" : "clockTickerInvis"}`}>
            {durString}
          </div> */}
          </div>
          {/* {loading ? (
          ""
        ) : (
          <p className="currClockOnTitle">Employees currently clocked on</p>
        )} */}
          <div
            className={`${
              memoVals.mobModal ? "mobUnclocksModalBody" : ""
            } unclocksModalContentBox x10841085
          `}
          >
            <div
              ref={scrollBord}
              className={`activeClocksContainer ${
                showTopBorder ? "x389213" : ""
              }`}
              onScroll={() => {
                if (scrollBord.current && scrollBord.current.scrollTop === 0) {
                  setShowTopBorder(false);
                } else {
                  setShowTopBorder(true);
                }
              }}
            >
              {activeClocks[0] ? (
                <p className="currClockOnTitle">
                  Employees currently clocked on
                </p>
              ) : (
                ""
              )}
              {activeClocks[0] ? (
                <div className="activeClocksSection">
                  {" "}
                  {generateActiveClocks}
                </div>
              ) : (
                ""
              )}
              {upcoming[0] && activeClocks[0] ? (
                <div className="upcomingActiveClocksSplitter"></div>
              ) : (
                ""
              )}
              {upcoming[0] && activeClocks[0] ? (
                <div className="upcomingActiveClocksTitle">
                  Scheduled shifts
                </div>
              ) : (
                ""
              )}

              {upcoming[0] ? (
                <div className="activeClocksSection">
                  {upcoming.map((item, i) => {
                    let clockDueButNotClockedOn = false;
                    if (item.clockOnRequired && !item.clockOnID) {
                      if (new Date().getTime() >= item.startTs) {
                        clockDueButNotClockedOn = true;
                      }
                    }

                    let clockNotDueAndIsWorkingNow = false;
                    if (
                      !item.clockOnRequired &&
                      new Date().getTime() >= item.startTs &&
                      new Date().getTime() <=
                        dateStringer.createTimestampFromString(item.endDs)
                    ) {
                      clockNotDueAndIsWorkingNow = true;
                    }
                    return (
                      <Fragment>
                        <div
                          className={`upcomingActiveItem ${
                            i === 0 ? "" : "marginTop12"
                          } ${
                            clockDueButNotClockedOn
                              ? "highlightClockActiveItem"
                              : clockNotDueAndIsWorkingNow ||
                                item.clockedStartDs
                              ? `highlightClockActiveWorkingNow ${
                                  item.clockedEndDs
                                    ? "highlightClockActiveWorkingNow2"
                                    : ""
                                }`
                              : ""
                          }`}
                          onClick={() => {
                            memoVals.setShowEditShiftModal((x) => {
                              return {
                                itemID: item.shiftID || item.tilID,
                                itemType: item.shiftID ? "shift" : item.tilType,
                                itemFName: item.fName,
                                itemLName: item.lName,
                                itemIsNew: false,
                                itemUserID: item.userID,
                              };
                            });
                          }}
                        >
                          <div className="upcomingActiveItemLeft">
                            <p className="upcNameTxt">
                              {item.fName} {item.lName[0]}
                            </p>
                            <p className="upcStartStrip">
                              {clockNotDueAndIsWorkingNow
                                ? "Started"
                                : "Starts"}{" "}
                              today at{" "}
                              <span className="dueToStartSpan">
                                {dateStringer.dsToTimeStrip(item.startDs, true)}
                              </span>
                            </p>
                            <p className="upcStartStrip">
                              Finishes at{" "}
                              <span className="dueToStartSpan">
                                {dateStringer.dsToTimeStrip(item.endDs, true)}
                              </span>
                            </p>{" "}
                          </div>
                          <div className="upcomingActiveItemRight">
                            {item.clockedEndDs ? (
                              <p className={`clockRequiredStatus fontSize10`}>
                                Clocked on{" "}
                                {dateStringer.dsToTimeStrip(
                                  item.clockedStartDs,
                                  true
                                )}{" "}
                                <img
                                  src={greenTick}
                                  alt="Clock on required"
                                  className="clockOnReqStopwatchImg x2081371"
                                />
                              </p>
                            ) : (
                              ""
                            )}
                            <p
                              className={`clockRequiredStatus ${
                                item.clockOnRequired ? "" : "x9742152"
                              } ${
                                item.clockedEndDs ? "x08249 fontSize10" : ""
                              }`}
                            >
                              {item.clockedEndDs
                                ? `Clocked off ${dateStringer.dsToTimeStrip(
                                    item.clockedEndDs,
                                    true
                                  )}`
                                : item.clockOnRequired
                                ? "Clock-on required"
                                : "Clock-on not required"}{" "}
                              {item.clockOnRequired ? (
                                <img
                                  src={
                                    item.clockedEndDs ? greenTick : stopwatch
                                  }
                                  alt="Clock on required"
                                  className={`clockOnReqStopwatchImg ${
                                    item.clockedEndDs ? "invis" : ""
                                  }`}
                                />
                              ) : (
                                ""
                              )}
                            </p>
                            <p className={`clockRequiredStatus x081284`}>
                              {dateStringer.shorten(
                                item.teamName,
                                item.clockedEndDs ? 14 : 999
                              )}
                            </p>
                            {item.tags && item.tags[0] ? (
                              <div className="actClocksTags">
                                {item.tags.map((tag) => {
                                  return (
                                    <p className="actClockTagItem">{tag}</p>
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}

                            {/*  */}
                          </div>
                        </div>
                        {clockDueButNotClockedOn ? (
                          <div className="clockDueButNotActiveIndicate">
                            <p className="didNotClockTxt">
                              {item.fName} has not clocked on
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="blankModalFooter">
            &nbsp;
            <p
              className="closeEditShiftBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      <img src={edit} alt="-" className="invisImg" />

      {namesOfInvalid[0] ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setNamesOfInvalid([]);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              // console.log({ namesOfInvalid });
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              {namesOfInvalid.map((x) => {
                let arr = [];
                let invalidCount = 0;
                if (!x.p1valid) {
                  arr.push("first period");
                  invalidCount++;
                }
                if (!x.p2valid) {
                  arr.push("second period");
                  invalidCount++;
                }
                if (!x.p3valid) {
                  arr.push("third period");
                  invalidCount++;
                }
                if (!x.b1valid) {
                  arr.push("first break");
                  invalidCount++;
                }
                if (!x.b2valid) {
                  arr.push("second period");
                  invalidCount++;
                }
                if (!x.b3valid) {
                  arr.push("third period");
                  invalidCount++;
                }
                return (
                  <p className="invalidClockNameRow">
                    {x.fName} {dateStringer.possession(x.lName)}{" "}
                    {dateStringer.listify(arr, false, false)}{" "}
                    {invalidCount > 1 ? "are" : "is"} negative. Please fix.
                  </p>
                );
              })}
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setNamesOfInvalid([]);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {showClockOffModal.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            setShowClockOffModal({ show: false });
            e.stopPropagation();
          }}
        >
          <div
            className="overlapsRenewalModalBox x1289347"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              What time do you want to clock {showClockOffModal.fName} off from?
            </p>
            <input
              type="time"
              className="clockOffInputTime"
              value={clockOffInput}
              onChange={(e) => {
                setClockOffInput(e.target.value);
              }}
            ></input>
            <p className="clockRowDs x131212">
              <img
                src={minus}
                alt="Deduct one day"
                className="clockDeductImg x0910931"
                onClick={() => {
                  let dateObj = new Date(
                    dateStringer.createTimestampFromString(clockOffDs)
                  );
                  dateObj.setDate(dateObj.getDate() - 1);
                  setClockOffDs(
                    dateStringer.createStringFromTimestamp(dateObj.getTime())
                  );
                }}
              />
              <p className="x3893881">
                {clockOffDs.substr(0, 11) ===
                dateStringer
                  .createStringFromTimestamp(new Date().getTime())
                  .substr(0, 11)
                  ? "Today"
                  : dateStringer.printedDateFromDs(clockOffDs)}
              </p>
              <img
                src={plus}
                alt="Deduct one day"
                className="clockDeductImg x0910931"
                onClick={() => {
                  let dateObj = new Date(
                    dateStringer.createTimestampFromString(clockOffDs)
                  );
                  dateObj.setDate(dateObj.getDate() + 1);
                  setClockOffDs(
                    dateStringer.createStringFromTimestamp(dateObj.getTime())
                  );
                }}
              />
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className={`areYouSureModalYesBtn loadOtherTs ${
                  clockOffDs &&
                  clockOffDs.length === 17 &&
                  clockOffDs[0] === "Y" &&
                  clockOffInput.length === 5 &&
                  clockOffInput[2] === ":"
                    ? ""
                    : "invalidClockOffTime"
                }`}
                onClick={() => {
                  console.log(
                    "** ",
                    clockingOffCheck(showClockOffModal.userID),
                    "aauserID",
                    showClockOffModal.userID
                  );
                  if (clockingOffCheck(showClockOffModal.userID)) {
                    // shows clockOffIsBefore modal
                    setShowClockOffModal({ show: false });
                    setClockOffIsBefore(
                      clockingOffCheck(showClockOffModal.userID)
                    );
                  } else {
                    if (
                      clockOffInput.length === 5 &&
                      clockOffInput[2] === ":"
                    ) {
                      actionFct(
                        "clockOff",
                        showClockOffModal.clockOnID,
                        clockOffDs
                      );
                      setShowClockOffModal({ show: false });
                    } else {
                    }
                  }
                }}
              >
                Clock off
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowClockOffModal({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {clockOffIsBefore ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setClockOffIsBefore("");
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              // console.log({ namesOfInvalid });
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You cannot clock an employee off before their {clockOffIsBefore}{" "}
              time.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setClockOffIsBefore("");
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default ActiveClocksModal;
