import React, { useState, useContext, useEffect, useMemo } from "react";
// cleaned wef 24 july 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";

import dateStringer from "../../../tools/dateStringer";
import CheckUser from "../../../tools/CheckUser";
import serverURL from "../../../serverURL";

import "../../../styles/calendar.css";

import CalendarSubBar from "../CalendarSubBar";

import Navbar from "../../Navbar";

import UserShifts from "./UserShifts";
import TeamShifts from "./TeamShifts";
import Swaps from "./Swaps";
import TeamSwaps from "./TeamSwaps";
import ClockOns from "./ClockOns";
import TeamClockOns from "./TeamClockOns";
import TeamTimesheets from "./TeamTimesheets";
import Timesheets from "./Timesheets";

const Shifts = ({
  isTeam,
  isSwaps,
  isClockOns,
  isTimesheets,
  isTeamTimesheets,
}) => {
  CheckUser();
  const {
    page, //
    setPage, //
    setHoursDataLoading, //
    sideBoxData, //
    setSideBoxData, //
    setAvailableMonths, //
  } = useContext(DataContext);
  const { full, mob } = useContext(StyleContext);
  const { setCalPage, shiftsPage, setShiftsPage, setUserShiftsPage } =
    useContext(CalendarContext);

  const memoVals = useMemo(
    () => ({
      page, //
      setPage, //
      setHoursDataLoading, //
      sideBoxData, //
      setSideBoxData, //
      setAvailableMonths, //
      full, //
      setCalPage, //
      shiftsPage, //
      setShiftsPage, //
      setUserShiftsPage, //
      mob, //
    }),
    [
      page, //
      setPage, //
      setHoursDataLoading, //
      sideBoxData, //
      setSideBoxData, //
      setAvailableMonths, //
      full, //
      setCalPage, //
      shiftsPage, //
      setShiftsPage, //
      setUserShiftsPage, //
      mob, //
    ]
  );

  let [my, setMy] = useState(
    `${
      new Date().getMonth() < 10 ? "0" : ""
    }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
      2,
      2
    )}`
  );

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!isTeam && memoVals.full) {
      memoVals.setHoursDataLoading((x) => true);
      axios
        .post(
          `${serverURL}/get-my-hours-data`,
          { my: my, ytd: false },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            console.log(response.data.data);
            memoVals.setSideBoxData((x) => response.data.data);
            memoVals.setHoursDataLoading((x) => false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [my, memoVals.full]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-month-year-dropdown-options`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (!response.data.arr.includes(my)) {
            memoVals.setAvailableMonths((x) => [my, ...response.data.arr]);
          } else {
            memoVals.setAvailableMonths((x) => response.data.arr);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    // clean not needed
    if (isTeamTimesheets) {
      memoVals.setCalPage((x) => "team-shifts");
      // memoVals.setShiftsPage(x=>"team-shifts");
      // memoVals.setPage(x => "team-shifts");
      // memoVals.setUserShiftsPage(x=>"team-shifts");

      memoVals.setUserShiftsPage((x) => "team-submissions");
      memoVals.setShiftsPage((x) => "team-submissions");
      memoVals.setPage((x) => "team-submissions");
    } else {
      if (isTeam && !isClockOns && !isTimesheets) {
        memoVals.setCalPage((x) => "team-shifts");
        memoVals.setShiftsPage((x) => "team-shifts");
        memoVals.setPage((x) => "team-shifts");
        memoVals.setUserShiftsPage((x) => "team-shifts");
      } else if (isClockOns && !isTimesheets) {
        if (isTeam) {
          memoVals.setPage((x) => "team-clock-ons");

          memoVals.setShiftsPage((x) => "team-clock-ons");
          memoVals.setUserShiftsPage((x) => "team-clock-ons");
        } else {
          memoVals.setUserShiftsPage((x) => "clock-ons");

          memoVals.setPage((x) => "clock-ons");
          memoVals.setShiftsPage((x) => "clock-ons");
        }
      } else if (isTimesheets) {
        memoVals.setShiftsPage((x) => "submissions");
        memoVals.setUserShiftsPage((x) => "submissions");
        memoVals.setPage((x) => "submissions");
      } else {
        memoVals.setCalPage((x) => "shifts");
        memoVals.setPage((x) => "shifts");
      }
    }
  }, []);
  // set calendarSubBar:
  // useEffect(() => {
  //   const cancelSource1 = axios.CancelToken.source();

  //   let now = new Date();
  //   let localDs = dateStringer.createStringFromTimestamp(now.getTime());

  //   axios
  //     .post(
  //       `${serverURL}/checkuser`,
  //       { localDs: localDs },

  //       {
  //         withCredentials: true,
  //         credentials: "include",
  //         cancelToken: cancelSource1.token,
  //       }
  //     )
  //     .then((response) => {
  //       if (response.data.message === "success") {
  //         if (response.data.permissions < 3 && memoVals.page.includes("team")) {
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });

  //   return () => {
  //     cancelSource1.cancel("Component unmounted");
  //   };
  // }, []);
  // memoVals.shiftsPage, setShiftsPage:
  // shifts, stats, availability, team-shifts

  let whichShiftsComponenet = () => {
    if (memoVals.shiftsPage === "shifts") {
      return (
        <UserShifts sideBoxData={memoVals.sideBoxData} my={my} setMy={setMy} />
      );
    }
    if (memoVals.shiftsPage === "clock-ons") {
      return (
        <ClockOns sideBoxData={memoVals.sideBoxData} my={my} setMy={setMy} />
      );
    }

    if (memoVals.shiftsPage === "team-shifts") {
      return <TeamShifts my={my} setMy={setMy} />;
    }
    if (memoVals.shiftsPage === "swaps") {
      return <Swaps my={my} setMy={setMy} />;
    }
    if (memoVals.shiftsPage === "team-swaps") {
      return <TeamSwaps my={my} setMy={setMy} />;
    }
    if (memoVals.shiftsPage === "submissions") {
      return <Timesheets my={my} setMy={setMy} />;
    }
    if (memoVals.shiftsPage === "team-submissions") {
      return <TeamTimesheets my={my} setMy={setMy} />;
    }
    if (memoVals.shiftsPage === "team-clock-ons") {
      return <TeamClockOns my={my} setMy={setMy} />;
    }
  };

  return (
    <div>
      {" "}
      {whichShiftsComponenet()}
      <Navbar />
      {memoVals.mob && <CalendarSubBar />}
    </div>
  );
};

export default Shifts;
