// CSS for this modal is in calendar.css + editUser.css

// INSTRUCTIONS TO LOAD MODAL
// use props
// show modal with showEditShiftModal stored in DataContext

import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
  useMemo,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import TilTrimModal from "./TilTrimModal";

import { DataContext } from "../../../contexts/DataContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import close from "../../../img/modals/close.svg";
import editGrey from "../../../img/general/editGrey.svg";
import greyAdd from "../../../img/general/greyAdd.svg";
import edit from "../../../img/general/edit.svg";
import greyTag from "../../../img/general/greyTag.svg";
import whiteBack3 from "../../../img/general/whiteBack3.svg";
import tagAdd from "../../../img/general/tagAdd.svg";
import closeGrey from "../../../img/general/closeGrey.svg";
import sub from "../../../img/general/sub.svg";
import greenTick from "../../../img/general/greenTick.svg";
import navyClose from "../../../img/general/navyClose.svg";
import editReq from "../../../img/general/editReq.svg";
import removeTagImg from "../../../img/general/removeTagImg.svg";
import whitecross from "../../../img/general/whitecross.svg";
import bin1 from "../../../img/general/bin1.svg";
import shiftInfo from "../../../img/general/shiftInfo.svg";
import greyClose from "../../../img/general/greyClose.svg";
import greyCancel from "../../../img/general/greyCancel.svg";
import greyRemove from "../../../img/general/greyRemove.svg";
import checkbox1 from "../../../img/general/checkboxInstall1.svg";
import checkbox2b from "../../../img/general/checkboxInstall2.svg";
import checkbox2 from "../../../img/general/checkboxInstall2pub.svg";
import checkbox2purp from "../../../img/general/checkboxInstall2purp.svg";
import noteImg from "../../../img/general/note.svg";
import liked2 from "../../../img/general/liked2.svg";
import liked from "../../../img/general/liked.svg";
import like from "../../../img/general/like.svg";
import acknowledgeArr from "../../../img/general/acknowledgeArr.svg";
import arrGrey from "../../../img/general/arrGrey.svg";
import popup from "../../../img/general/popup.svg";
import whitebin from "../../../img/general/whitebin.svg";
import shiftChangeArrow from "../../../img/general/shiftChangeArrow.svg";
import doubleTick from "../../../img/general/doubleTick.svg";
import rotaClock2 from "../../../img/general/rotaClock2.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import allowArr from "../../../img/general/allowArr.svg";

import dateStringer, { dsToTimeStrip } from "../../../tools/dateStringer";

// type = til, overtime, shift
const ManagerShiftModal = ({
  openedFromShiftsPage,
  unitType,
  unitUserID,
  unitAddedBy,
  unitAddedByUserID,
  // unitFName,
  // unitLName,
  unitShowGhostOption,
  getFromServerID,
  unitIsNew,
  setShowEditShiftModalParent,
  makeAbsent,
}) => {
  const {
    setShowEditShiftModal,
    setOpenManagerShiftModal,
    setShowUserAbsenceModal,
    setDontAddNewItemToRota,
    setClockOnID,
    clockOnID,
    reloadManagerShift,
    setChangedMidnightTimestamp,
    setShowEditUser,
    showEditUser,
    setShiftIDNoLongerExists,
    setOpenShiftConvert,
    setAddtagToAllUserShifts,
    setIndicate,
    reloadTs,
    setReloadTs,
    updateUnclocked,
    setUpdateUnclocked,
    showUnclocked,
    showViewTimesheet,
    setShowViewTimesheet,
    showMgrAvailModal,
    setShowMgrAvailModal,
    showNotifications,
    setShowNotifications,
    updateTabData,
    setUpdateTabData,
    setSeeUserID,
    seeUserID,
    noPermissions,
    setNoPermissions,
  } = useContext(DataContext);
  const { currencySymbol, checkUserState } = useContext(UserContext);
  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowEditShiftModal,
      setOpenManagerShiftModal,
      setShowUserAbsenceModal,
      setDontAddNewItemToRota,
      setClockOnID,
      clockOnID,
      reloadManagerShift,
      setChangedMidnightTimestamp,
      setShowEditUser,
      showEditUser,
      setShiftIDNoLongerExists,
      setOpenShiftConvert,
      setAddtagToAllUserShifts,
      setIndicate,
      reloadTs,
      setReloadTs,
      updateUnclocked,
      setUpdateUnclocked,
      showUnclocked,
      showViewTimesheet,
      setShowViewTimesheet,
      showMgrAvailModal,
      setShowMgrAvailModal,
      showNotifications,
      setShowNotifications,
      updateTabData,
      setUpdateTabData,
      setSeeUserID,
      seeUserID,
      noPermissions,
      setNoPermissions,
      currencySymbol,
      checkUserState,
      mob,
      mobModal,
    }),
    [
      setShowEditShiftModal, //
      setOpenManagerShiftModal, //
      setShowUserAbsenceModal, //
      setDontAddNewItemToRota, //
      setClockOnID, //
      clockOnID, //
      reloadManagerShift, //
      setChangedMidnightTimestamp, //
      setShowEditUser, //
      showEditUser, //
      setShiftIDNoLongerExists, //
      setOpenShiftConvert, //
      setAddtagToAllUserShifts, //
      setIndicate, //
      reloadTs, //
      setReloadTs, //
      updateUnclocked, //
      setUpdateUnclocked, //
      showUnclocked, //
      showViewTimesheet, //
      setShowViewTimesheet, //
      showMgrAvailModal, //
      setShowMgrAvailModal, //
      showNotifications, //
      setShowNotifications, //
      updateTabData, //
      setUpdateTabData, //
      setSeeUserID, //
      seeUserID, //
      noPermissions, //
      setNoPermissions, //
      currencySymbol, //
      checkUserState, //
      mob, //
      mobModal, //
    ]
  );

  let [itemBankData, setItemBankData] = useState({});

  let [hoveredLikeCommentID, setHoveredLikeCommentID] = useState("");
  let [showLikedCommentIDs, setShowCommentLikedIDs] = useState([]);
  let [payClockedNotApprovedYet, setPayClockedNotApprovedYet] = useState(false);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [loading, setLoading] = useState(true);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let [teamCost, setTeamCost] = useState(0);
  let [salaryCost, setSalaryCost] = useState(0);
  let [hourlyCost, setHourlyCost] = useState(0);
  let [showTeamCost, setShowTeamCost] = useState(false);

  let [showPartialBlockModal, setShowPartialBlockModal] = useState(false);
  // this is a modal that shows if partially absent and user tries to change break/times.

  useEffect(() => {
    // console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 35) {
      console.log(
        "setting loading to false as all images loaded and data is loaded too"
      );

      setLoading(false);

      console.log({ loading }, "***");
    }
  }, [dataLoaded, imagesLoaded]);

  let [sureMakeOpen, setSureMakeOpen] = useState(false);

  let [sureCloseInitial, setSureCloseInitial] = useState(false);

  let calcDur = (startDs, endDs, brkMins) => {
    let start = new Date(dateStringer.createTimestampFromString(startDs));
    let end = new Date(dateStringer.createTimestampFromString(endDs));

    let mins = (end.getTime() - start.getTime()) / 1000 / 60;
    mins -= brkMins || 0;
    return mins;
  };

  let [showNotify, setShowNotify] = useState(false);

  let [otherUsersArr, setOtherUsersArr] = useState([]);

  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      shiftLoaded: false, // done
      profPicUrl: "",
      requireClockOn: false,
      clockCardValid: false,
      clockOnAmended: false,
      createdByClock: false,
      itemType: "",
      dayN: 0,
      editShiftStart: 0,
      editShiftEnd: 0,
      editShiftBrk: 0,
      editShiftRate: 1,
      editShiftSwappable: false,
      editShiftAbsent: false,
      editDurationMins: 0,
      durationMins2: 0,
      specialName: "",
      itemFName: "",
      itemLName: "",
      originalTeamID: "",
      editShiftShiftID: "",
      itemUserID: "",
      editShiftWorked: false,
      showShiftModalTeamName: "",
      editPublicNoteValue: [],
      shiftTeamID: "",
      tags: [],
      dontAccrue: false,
      shiftCost: 0,
      shiftIsBank: false,
      itemGhost: false,
      inLieu: false,
      unpaidAbsent: false,
      partialAbsent: false,
      editPartialStart: "",
      editPartialEnd: "",
      editPartialStartLocal: "",
      editPartialEndLocal: "",
      hideClockOnRequired: false,
      partialAbsenceMins: 0,
      assocAbsenceID: "",
      assocAbsenceName: "",
      initial: false,
      beyond24: false,
      minPartialStart: 0,
      minPartialEnd: 0,
      maxPartialStart: 0,
      maxPartialEnd: 0,
      payClocked: false,
      clockOn: "",
      clockOff: "",
      clockedDurMins: 0,
      clockOnApproved: false,
      clockOnDeclined: false,
      clockBrkMins: 0,
      clockOnIDLocal: "",
      tilRequested: false,
      tilApproved: false,
      tilDeclined: false,
      tilStart: "",
      tilEnd: "",
      newTilStart: "",
      newTilEnd: "",
      tilMins: 0,
      tilDecidedBy: "",
      tilTsMade: 0,
      takeTilID: "",
      fName: "",
      lName: "",
      published: true,
      itemIsNew: unitIsNew,
      changed: false,
      salaried: false,
      unpaidSalary: 0,
      swapRequests: [],
      payRoll: {},
      lateStartDs: "",
      lateDurMins: 0,
      unpaidLate: false,
      timesheetID: "",
      selfLoggedAbsence: "",
      selfLoggedAbsenceReason: "",
      requireAccept: false,
      accepted: false,
      acceptedByMgrName: "",
      allowSelfApprove: false,
      isOwn: false,
    }
  );

  let tonightMidnight = new Date();
  tonightMidnight.setHours(0, 0, 0, 0);
  tonightMidnight.setDate(tonightMidnight.getDate() + 1);
  let [showLate, setShowLate] = useState(false);

  useEffect(() => {
    if (state.editShiftStart <= tonightMidnight) {
      setShowLate(true);
    } else {
      setShowLate(false);
    }
  }, [state]);

  let [addTagsToAll, setAddTagsToAll] = useState(false);
  let [hoursData, setHoursData] = useState({});
  let [tilError, setTilError] = useState(false);
  let [changeApproveOrDeclineLoading, setChangeApproveOrDeclineLoading] =
    useState(false);
  let [toggleEditLate, setToggleEditLate] = useState(false);
  let [lateMinsValue, setLateMinsValue] = useState(0);
  let [lateDsValue, setLateDsValue] = useState("");
  let [lateTimeValue, setLateTimeValue] = useState("");
  let [unpaidLateValue, setUnpaidLateValue] = useState(false);
  let [mgrAppliedTil, setMgrAppliedTil] = useState("");
  let [showTilTrimModal, setShowTilTrimModal] = useState(false);

  let [trueCost, setTrueCost] = useState(0);
  let [timePickerDrops, setTimePickerDrops] = useState(false);

  let [avail, setAvail] = useState({ active: false });

  const startTimeInputt = document.getElementById("newTimeStartInput1");
  const endTimeInput = document.getElementById("newTimeEndInput1");
  const addShiftDateInput = document.getElementById("addShiftDateInput");

  if (addShiftDateInput && !memoVals.mob) {
    addShiftDateInput.addEventListener("focus", function (evt) {
      // if (this.getAttribute("type") === "date") {
      if (this.showPicker) {
        this.showPicker();
      }
      // }
    });
  }
  if (startTimeInputt && !memoVals.mob) {
    startTimeInputt.addEventListener("focus", function (evt) {
      // if (this.getAttribute("type") === "time") {
      // console.log("CLICKED");
      if (this.showPicker) {
        this.showPicker();
      }
      // }
    });
  }

  if (endTimeInput && !memoVals.mob) {
    endTimeInput.addEventListener("focus", function (evt) {
      // if (this.getAttribute("type") === "time") {
      if (this.showPicker) {
        this.showPicker();
      }
      // }
    });
  }

  let timeOptions = [];

  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute <= 45; minute += 15) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      timeOptions.push(time);
    }
  }
  let generateTimeOptions = timeOptions.map((opt) => {
    return <option value={opt}>{opt}</option>;
  });

  let renderTilTrimModal = () => {
    if (showTilTrimModal) {
      let startDs = dateStringer.createStringFromTimestamp(
        state.editShiftStart
      );
      let endDs = dateStringer.createStringFromTimestamp(state.editShiftEnd);
      // Y2022M02D02 H 0 0 M 0 0 0
      // 01234567891011121314151617

      let startHHMM = `${startDs.substr(12, 2)}:${startDs.substr(15, 2)}`;
      let endHHMM = `${endDs.substr(12, 2)}:${endDs.substr(15, 2)}`;

      return (
        <TilTrimModal
          unitType={state.itemType}
          unitID={state.editShiftShiftID}
          unitDate={dateStringer.createStringFromTimestamp(
            state.editShiftStart
          )}
          unitShiftMins={state.editDurationMins}
          unitStart={startHHMM}
          unitEnd={endHHMM}
          unitTeamID={state.shiftTeamID}
          unitBrkMins={state.editShiftBrk}
          isMgr={true}
          setShowTilTrimModalParent={setShowTilTrimModal}
          userID={state.itemUserID}
          fName={state.fName}
          reload={reload}
          setReload={setReload}
          editing={state.tilMins}
          editStart={state.tilStart ? state.tilStart : ""}
          editEnd={state.tilEnd ? state.tilEnd : ""}
          setSureBinTil={setSureBinTil}
          setChangesMade={setChangesMade}
        />
      );
    }
  };

  useEffect(() => {
    // clean not needed
    if (lateTimeValue.length === 5) {
      let startTs = state.editShiftStart;

      let dateObj = new Date(startTs);
      let hours = parseInt(lateTimeValue.substr(0, 2));
      let mins = parseInt(lateTimeValue.substr(3, 2));

      dateObj.setHours(hours);
      dateObj.setMinutes(mins);

      let endObj = new Date(dateObj.getTime());

      let totalMs = endObj.getTime() - startTs;
      setLateDsValue(dateStringer.createStringFromTimestamp(endObj.getTime()));
      setLateMinsValue(totalMs / 1000 / 60);
    }
  }, [lateTimeValue]);
  //  ------------------------------------------------------------------------------------------

  //  ------------------------------------------------------------------------------------------
  //  ------------------------------------------------------------------------------------------
  //  ------------------------------------------------------------------------------------------
  //  ------------------------------------------------------------------------------------------

  let [itemStart, setItemStart] = useState("");
  let [itemEnd, setItemEnd] = useState("");

  let [availableTags, setAvailableTags] = useState([]);

  let [showNotes, setShowNotes] = useState(false);
  let [toggleAddNote, setToggleAddNote] = useState(false);
  let [shiftAddNoteValue, setShiftAddNoteValue] = useState("");
  let [editPartialTimes1, setEditPartialTimes1] = useState(false);
  let [editPartialTimes2, setEditPartialTimes2] = useState(false);

  let [availableTeams, setAvailableTeams] = useState([]);

  let [editTagSuggestions, setEditTagSuggestions] = useState(false);
  // non unit states
  let [showDatepicker, setShowDatepicker] = useState(false);
  let [editedShiftDate, setEditedShiftDate] = useState("");

  let [dateIsBeforeUserStarted, setDateIsBeforeUserStarted] = useState(false);
  let [userHasHalfDayOff, setUserHasHalfDayOff] = useState(false);
  let [userHasFullDayOff, setUserHasFullDayOff] = useState(false);
  let [showEditTimes, setShowEditTimes] = useState(false);
  let [startPurgatory, setStartPurgatory] = useState("12:00");
  let [endPurgatory, setEndPurgatory] = useState("18:00");
  let [startPurgatorySubmit, setStartPurgatorySubmit] = useState("12:00");
  let [endPurgatorySubmit, setEndPurgatorySubmit] = useState("18:00");
  let [showEditBrk, setShowEditBrk] = useState(false);
  let [breakPurgatory, setBreakPurgatory] = useState(0);
  let [addingNewTag, setAddingNewTag] = useState(false);
  let [newTagValue, setNewTagValue] = useState("");
  let [editPublicNote, setEditPublicNote] = useState(false);
  let [editUserStartDate, setEditUserStartDate] =
    useState("Y19700M00D01H00M00");

  let [expandShiftInfo, setExpandShiftInfo] = useState(false);
  let [sureDeleteShift, setSureDeleteShift] = useState(false);
  let [showAvailableTagsModal, setShowAvailableTagsModal] = useState(false);
  let [showAddShift, setShowAddShift] = useState(false);

  let [showName, setShowName] = useState(false);

  let [publishImmediately, setPublishImmediately] = useState(true);

  let [startContradictsTil, setStartContradictsTil] = useState(false);
  let [endContradictsTil, setEndContradictsTil] = useState(false);

  let [editTilTimes, setEditTilTimes] = useState(false);

  let [newTilStartError, setNewTilStartError] = useState(false);
  let [newTilEndError, setNewTilEndError] = useState(false);

  let [showEditUnitType, setShowEditUnitType] = useState(false);

  let [jamPayAcc, setJamPayAcc] = useState(false);
  let [jamAbs, setJamAbs] = useState(false);

  let [itemIsNew, setItemIsNew] = useState(false);
  let [itemWasNew, setItemWasNew] = useState(false);

  let [sureBinTil, setSureBinTil] = useState(false);

  let [initialDone, setInitialDone] = useState(false);

  let [suggestLate, setSuggestLate] = useState(0);

  useEffect(() => {
    // clean not needed

    setShowEditTimes(unitIsNew);
    setState({ itemIsNew: unitIsNew });
    if (unitIsNew) {
      setTimePickerDrops(true);
    }
    setItemWasNew(unitIsNew);
  }, [unitIsNew]);
  useEffect(() => {
    // clean not needed

    memoVals.setDontAddNewItemToRota((x) => false);
  }, []);

  let [reload, setReload] = useState(false);
  let [changesMade, setChangesMade] = useState(false);

  const {
    setUpdatedItem,
    // updateRun,
    // setUpdateRun,

    setSingleDay,
    setSingleDay2,
  } = useContext(CalendarContext);

  let [changeType, setChangeType] = useState(unitType);

  let [showChangeTeam, setShowChangeTeam] = useState(false);
  let [showChangeReqs, setShowChangeReqs] = useState(false);
  let shortenTeamName = (val) => {
    if (val) {
      if (val.length > 18) {
        return `${val.substr(0, 15)}...`;
      } else {
        return val;
      }
    }
  };

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}
      <img
        src={shiftChangeArrow}
        alt="Close Modal"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="Close Modal"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={doubleTick}
        alt="Close Modal"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="Close Modal"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editGrey}
        alt="Edit Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyAdd}
        alt="Grey Add"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whitebin}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={edit}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyTag}
        alt="Grey Tag"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteBack3}
        alt="White Back 3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tagAdd}
        alt="Tag Add"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={closeGrey}
        alt="Close Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={sub}
        alt="Subtract"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greenTick}
        alt="Green Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editReq}
        alt="Edit Request"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={removeTagImg}
        alt="Remove Tag"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bin1}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftInfo}
        alt="Shift Info"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyClose}
        alt="Grey Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCancel}
        alt="Grey Cancel"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyRemove}
        alt="Grey Remove"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2b}
        alt="Checkbox Install 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2}
        alt="Checkbox Install 2 Pub"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2purp}
        alt="Checkbox Install 2 Purple"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noteImg}
        alt="Note"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked2}
        alt="Liked 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked}
        alt="Liked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaClock2}
        alt="Like"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={like}
        alt="Like"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={acknowledgeArr}
        alt="Acknowledge Arr"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={arrGrey}
        alt="Arrow Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let closeModal = (deleting, doesNotExist, failedID) => {
    console.log({ deleting, doesNotExist, failedID });
    if (memoVals.showNotifications) {
      memoVals.setShowNotifications((x) => false);
    }
    memoVals.setOpenManagerShiftModal((x) => {
      return {};
    });
    if (!state.itemIsNew && (changesMade || deleting)) {
      memoVals.setUpdateTabData(!memoVals.updateTabData);
      let data = {
        absent: state.editShiftAbsent,
        partialAbsent: state.partialAbsent,
        partialAbsentDuration: state.partialAbsenceMins * 60 * 1000,
        unpaidAbsent: state.unpaidAbsent,
        bankCost: itemBankData ? itemBankData.bankCost : 0,
        start:
          state.clockOnApproved && state.payClocked && state.clockOn
            ? state.clockOn
            : dateStringer.createStringFromTimestamp(state.editShiftStart),
        end:
          state.clockOnApproved && state.payClocked && state.clockOff
            ? state.clockOff
            : dateStringer.createStringFromTimestamp(state.editShiftEnd),
        type: state.itemType,
        duration:
          state.clockOnApproved && state.payClocked && state.clockOn
            ? state.clockedDurMins * 1000 * 60
            : state.editDurationMins * 1000 * 60,
        durMins:
          state.clockOnApproved && state.payClocked && state.clockOn
            ? state.clockedDurMins
            : state.editDurationMins,
        tags: state.tags,
        addedBy: unitAddedBy,
        addedByUserID: unitAddedByUserID,
        ds: dateStringer.createStringFromTimestamp(state.editShiftStart),
        teamName: state.showShiftModalTeamName,
        break: state.editShiftBrk,
        published: state.published,
        assocAbsence: state.assocAbsenceID,
        partialAbsentDuration: state.partialAbsenceMins * 60 * 1000 || 0,
        partialAbsentStart: state.editPartialStart,
        partialAbsentEnd: state.editPartialEnd,
        shiftID: state.editShiftShiftID,
        tilID: state.editShiftShiftID,
        userID: unitUserID,
        ghost: state.itemGhost,
        rate: state.editShiftRate,
        cost: state.shiftCost,
        teamName: state.showShiftModalTeamName,
        lateDurMins: state.lateDurMins,
      };

      let midTs = new Date(state.editShiftStart);
      midTs.setHours(0, 0, 0, 0);

      let changeObj = {
        teamChanged: state.originalTeamID === state.shiftTeamID ? false : true,
        deleted: deleting,
        dayN: state.dayN,
        cost: state.shiftCost,
        key: state.editShiftShiftID,
        midnightTimestamp: midTs.getTime(),
        fName: state.fName,
        lName: state.lName,
        teamID: state.shiftTeamID,
        data: data,
        tags: state.tags,
        published: state.published,
        type: state.itemType, //goaty
        tilRequested: state.tilRequested,
        tilStart: state.tilStart,
        tilEnd: state.tilEnd,
        tilMins: state.tilMins,
        tilApproved: state.tilApproved,
        tilDeclined: state.tilDeclined,
        tilDecidedBy: state.tilDecidedBy,
        tilTsMade: state.tilTsMade,
        takeTilID: state.takeTilID,
        bank: state.shiftIsBank,
        durMins:
          state.itemType === "shift"
            ? state.editDurationMins * 60 * 1000
            : state.editDurationMins,
        startTs: state.editShiftStart,
        teamName: state.showShiftModalTeamName,
        showGhostOption: unitShowGhostOption,
        doesNotExist: doesNotExist || false,
        failedID,
        salaried: state.salaried,
        userID: state.itemUserID,
        userHasSwapped:
          state.swapRequests && state.swapRequests[0] ? true : false,
        comments:
          state.editPublicNoteValue && state.editPublicNoteValue[0]
            ? true
            : false,
        payRoll: state.payRoll,
        trueCost: state.payRoll.totCost,
        teamName: state.showShiftModalTeamName,
        selfLoggedAbsence: state.selfLoggedAbsence,
        accepted: state.accepted,
        requireAccept: state.requireAccept,
      };

      console.log({ changeObj });
      memoVals.setReloadTs(!memoVals.reloadTs);

      if (memoVals.showUnclocked) {
        memoVals.setUpdateUnclocked((x) => !memoVals.updateUnclocked);
      }
      setUpdatedItem(changeObj);

      // setUpdateRun(!updateRun);
    }
    memoVals.setShowEditShiftModal((x) => false);
    if (setShowEditShiftModalParent) {
      setShowEditShiftModalParent(false);
    }

    // setShowEditShiftModalParent(false);
  };

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      console.log("BACK BUTTON PRESS * * *");
      window.history.pushState(null, document.title, window.location.href);
      if (state.itemIsNew) {
        setSureCloseInitial(true);
      } else {
        closeModal();
      }
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [suggestLateOriginal, setSuggestLateOriginal] = useState(0);
  let [shiftDoesNotExist, setShiftDoesNotExist] = useState(false);

  // console.log({ dataLoaded });
  useEffect(() => {
    // clean not needed
    if (state.shiftLoaded) {
      setChangesMade(true);
    }
  }, [memoVals.reloadManagerShift]);

  let [amends, setAmends] = useState([]);
  let [changeRequests, setChangeRequests] = useState([]);
  let [outstandingChangeReqs, setOutstandingChangeReqs] = useState(0);

  let getSingleShiftData = (id, updatedType, cancelToken) => {
    console.log({ id });
    // return;
    // if (itemType === "shift") {

    let type = state.itemType || unitType;

    if (updatedType) {
      type = updatedType;
    }

    console.log({ type, id });

    if (dataLoaded) {
      // setDataLoaded(false);
    }
    console.log("Getting shift", { type, id });
    axios
      .post(
        `${serverURL}/get-single-${
          type === "shift" ? "shift" : `${type === "open" ? "open" : "til"}`
        }-data`,
        {
          shiftID: id ? id : state.editShiftShiftID,
          tilID: id ? id : state.editShiftShiftID,
          openID: id ? id : state.editShiftShiftID,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelToken ? cancelToken : "",
        }
      )
      .then((response) => {
        if (response.data.message === "you do not manage this team") {
          memoVals.setNoPermissions((x) => {
            return {
              show: true,
              teamName: response.data.teamName,
              type:
                type === "shift"
                  ? "shift"
                  : type === "til"
                  ? "shift in lieu"
                  : "overtime",
            };
          });
          memoVals.setShowEditShiftModal((x) => false);
        } else {
          setDataLoaded(true);
          setTeamCost(response.data.teamCost);
          setSalaryCost(response.data.salaryCost);
          setHourlyCost(response.data.hourlyCost);

          console.log("setting data loaded to true :)");
          if (response.data.message.includes("no shift available")) {
            memoVals.setShiftIDNoLongerExists((x) => {
              return {
                userID: state.itemUserID,
                itemID: id ? id : state.editShiftShiftID,
              };
            });
          }
          if (response.data.message.includes("not exist")) {
            setShiftDoesNotExist(true);
          }
          if (response.data.message === "success") {
            let typePurg = "shift";
            let dayNPurg = 0;
            let editShiftStartPurg = 0;
            let editShiftEndPurg = 0;

            let beyond24Purg = false;
            let minPartialStartPurg = 0;
            let maxPartialStartPurg = 0;
            let minPartialEndPurg = 0;
            let maxPartialEndPurg = 0;

            let startMinObj = new Date();
            startMinObj.setHours(
              response.data.start.substr(12, 2),
              response.data.start.substr(15, 2),
              0,
              0
            );

            let startMaxObj = new Date();
            startMaxObj.setHours(
              response.data.end.substr(12, 2),
              response.data.end.substr(15, 2),
              0,
              0
            );

            let endMinObj = new Date();
            endMinObj.setHours(
              response.data.start.substr(12, 2),
              response.data.start.substr(15, 2),
              0,
              0
            );

            let endMaxObj = new Date();
            endMaxObj.setHours(
              response.data.end.substr(12, 2),
              response.data.end.substr(15, 2),
              0,
              0
            );

            minPartialStartPurg = startMinObj.getTime();
            if (startMaxObj.getTime() <= startMinObj.getTime()) {
              startMaxObj.setDate(startMaxObj.getDate() + 1);
              beyond24Purg = true;
            }
            maxPartialStartPurg = startMaxObj.getTime() - 1000 * 60;
            minPartialEndPurg = endMinObj.getTime() + 1000 * 60;
            maxPartialEndPurg = endMaxObj.getTime();

            if (response.data.tilID && response.data.tilType === "til") {
              typePurg = "til";
            }
            if (response.data.tilID && response.data.tilType === "overtime") {
              typePurg = "overtime";
            }
            if (type === "open" || response.data.shiftID) {
              typePurg = "shift";
            }

            let startDateObj = new Date(
              dateStringer.createTimestampFromString(response.data.start)
            );
            dayNPurg = startDateObj.getDay();

            let startD = new Date(
              dateStringer.createTimestampFromString(response.data.start)
            );
            let endD = new Date(
              dateStringer.createTimestampFromString(response.data.end)
            );
            if (endD.getTime() < startD.getTime()) {
              endD.setDate(endD.getDate() + 1);
            }
            editShiftStartPurg = startD.getTime();
            editShiftEndPurg = endD.getTime();

            setItemStart(
              `${response.data.start.substr(
                12,
                2
              )}:${response.data.start.substr(15, 2)}`
            );
            setItemEnd(
              `${response.data.end.substr(12, 2)}:${response.data.end.substr(
                15,
                2
              )}`
            );

            let tilRequestedPurg = false;
            let tilApprovedPurg = false;
            let tilDeclinedPurg = false;
            let tilStartPurg = "";
            let tilEndPurg = "";
            let newTilStartPurg = "";
            let newTilEndPurg = "";
            let tilMinsPurg = 0;
            let tilDecidedByPurg = "";
            let tilTsMadePurg = 0;
            let takeTilIDPurg = "";

            if (
              (response.data.takeTilRequestMade ||
                response.data.mgrAppliedTil) &&
              response.data.tilData
            ) {
              if (response.data.mgrAppliedTil) {
                setMgrAppliedTil(response.data.mgrAppliedTil);
              } else {
                setMgrAppliedTil(false);
              }
              tilRequestedPurg = response.data.tilData.requested || false;
              tilApprovedPurg = response.data.tilData.approved || false;
              tilDeclinedPurg = response.data.tilData.declined || false;
              tilStartPurg = response.data.tilData.tilStart;
              tilEndPurg = response.data.tilData.tilEnd;
              newTilStartPurg = response.data.tilData.tilStart;
              newTilEndPurg = response.data.tilData.tilEnd;
              tilMinsPurg = response.data.tilData.tilMins;
              tilDecidedByPurg = response.data.tilData.decidedBy;
              tilTsMadePurg = response.data.tilData.tsMade;
              takeTilIDPurg = response.data.tilData.takeTilID;
            }

            if (!response.data.tilData) {
              tilRequestedPurg = false;
              tilApprovedPurg = false;
              tilDeclinedPurg = false;
            }
            setOtherUsersArr(response.data.otherUsers);
            setItemBankData(response.data.bankData);
            // if (response.data.publicNote && response.data.publicNote[0]) {
            //   response.data.publicNote.sort(function (a, b) {
            //     return b.ts - a.ts;
            //   });
            // }

            let awaitingChangeReqs = 0;
            response.data.changesArr.forEach((ch) => {
              if (!ch.approved && !ch.declined) {
                awaitingChangeReqs++;
              }
            });

            setOutstandingChangeReqs(awaitingChangeReqs);
            setChangeRequests(response.data.changesArr);
            // setChangesRequests(response.data.changesArr);
            // setAmends(response.data.alerts || []);

            if (response.data.initial) {
              console.log(
                "START:",
                dateStringer.hhmmFromDs(
                  dateStringer.createStringFromTimestamp(editShiftStartPurg)
                )
              );

              console.log(
                "END:",
                dateStringer.hhmmFromDs(
                  dateStringer.createStringFromTimestamp(editShiftEndPurg)
                )
              );
              setStartPurgatory(
                dateStringer.hhmmFromDs(
                  dateStringer.createStringFromTimestamp(editShiftStartPurg)
                )
              );
              setEndPurgatory(
                dateStringer.hhmmFromDs(
                  dateStringer.createStringFromTimestamp(editShiftEndPurg)
                )
              );

              setStartPurgatorySubmit(
                dateStringer.hhmmFromDs(
                  dateStringer.createStringFromTimestamp(editShiftStartPurg)
                )
              );
              setEndPurgatorySubmit(
                dateStringer.hhmmFromDs(
                  dateStringer.createStringFromTimestamp(editShiftEndPurg)
                )
              );
            }

            if (response.data.avail1start) {
              setAvail({
                active: true,
                avail1start: response.data.avail1start,
                avail1end: response.data.avail1end,
                avail2start: response.data.avail2start,
                avail2end: response.data.avail2end,
              });
            }
            if (response.data.notAvailable) {
              setAvail({
                active: true,
                notAvailable: true,
              });
            }
            setState({
              shiftLoaded: true,
              profPicUrl: response.data.profPicUrl || "",
              requireClockOn: response.data.requireClockOn,
              clockCardValid: response.data.clockCardValid,
              clockOnAmended: response.data.clockOnAmended,
              createdByClock: response.data.createdByClock,
              itemType: typePurg,
              dayN: dayNPurg,
              editShiftStart: editShiftStartPurg,
              editShiftEnd: editShiftEndPurg,
              editShiftBrk: response.data.break,
              editShiftRate: response.data.rate,
              editShiftSwappable: response.data.swappable,
              editShiftAbsent: response.data.absent,
              editDurationMins: response.data.durationMins,
              specialName: response.data.specialName,
              itemFName: response.data.fName,
              itemLName: response.data.lName,
              originalTeamID: !initialDone
                ? response.data.teamID
                : state.originalTeamID,
              editShiftShiftID:
                type === "shift" || type === "open"
                  ? response.data.shiftID
                  : response.data.tilID,
              itemUserID: response.data.userID,
              editShiftWorked: response.data.worked,
              showShiftModalTeamName: response.data.teamName,
              editPublicNoteValue:
                type === "shift"
                  ? response.data.publicNote
                  : response.data.note,
              // sandra
              // type === "shift" ? response.data.publicNote : response.data.note,
              shiftTeamID: response.data.teamID,
              tags: response.data.tags,
              dontAccrue: response.data.dontAccrue,
              shiftCost: response.data.cost || 0,
              shiftIsBank: response.data.bank,
              itemGhost: response.data.ghost,
              unpaidAbsent: response.data.unpaidAbsent,
              partialAbsent: response.data.partialAbsent,
              editPartialStart: response.data.partialAbsentStart,
              editPartialEnd: response.data.partialAbsentEnd,
              editPartialStartLocal: response.data.partialAbsent
                ? response.data.partialAbsentStart
                : state.editPartialStartLocal,
              editPartialEndLocal: response.data.partialAbsent
                ? response.data.partialAbsentEnd
                : state.editPartialEndLocal,
              hideClockOnRequired: response.data.ghost
                ? true
                : response.data.hideClockOnRequired,
              partialAbsenceMins:
                response.data.partialAbsentDuration / 1000 / 60,
              assocAbsenceID: response.data.assocAbsence,
              assocAbsenceName: response.data.assocAbsenceName,
              shiftIsBank: response.data.bank ? true : false,
              initial: response.data.initial,
              beyond24: beyond24Purg,
              minPartialStart: minPartialStartPurg,
              maxPartialStart: maxPartialStartPurg,
              minPartialEnd: minPartialEndPurg,
              maxPartialEnd: maxPartialEndPurg,
              payClocked: response.data.payClocked,
              clockOn: response.data.clockOn,
              clockOff: response.data.clockOff,
              clockedDurMins: response.data.clockedDurMins,
              clockOnApproved: response.data.clockOnApproved,
              clockOnDeclined: response.data.clockOnDeclined,
              clockBrkMins: response.data.clockBrkMins,
              clockOnIDLocal: response.data.clockOnID,
              tilRequested: tilRequestedPurg,
              tilApproved: tilApprovedPurg,
              tilDeclined: tilDeclinedPurg,
              tilStart: tilStartPurg,
              tilEnd: tilEndPurg,
              newTilStart: newTilStartPurg,
              newTilEnd: newTilEndPurg,
              tilMins: tilMinsPurg,
              tilDecidedBy: tilDecidedByPurg,
              tilTsMade: tilTsMadePurg,
              takeTilID: takeTilIDPurg,
              fName: response.data.fName,
              lName: response.data.lName,
              published: response.data.published,
              itemIsNew: response.data.initial,
              changed: true,
              salaried: response.data.salaried,
              unpaidSalary: response.data.unpaidSalary,
              swapRequests: response.data.swapRequests,
              payRoll: response.data.payRoll,

              lateStartDs: response.data.lateStartDs,
              lateDurMins: response.data.lateDurMins,
              unpaidLate: response.data.unpaidLate,
              inLieu: response.data.inLieu,
              timesheetID: response.data.timesheetID,
              tsNoLongerAvailable: response.data.tsNoLongerAvailable,
              selfLoggedAbsence: response.data.selfLoggedAbsence,
              selfLoggedAbsenceReason: response.data.selfLoggedAbsenceReason,
              midnightTimestamp: response.data.midnightTimestamp,
              requireAccept: response.data.requireAccept,
              accepted: response.data.accepted,
              forceAccceptMgrName: response.data.forceAccceptMgrName,
              isOwn: response.data.isOwn,
              allowSelfApprove: response.data.allowSelfApprove,
            });
            setDataLoaded(true);
            setSuggestLate(response.data.suggestLate || 0);
            setSuggestLateOriginal(response.data.suggestLate || 0);
          } else {
            if (
              response.data.message === "there is no shift available" ||
              response.data.message === "til does not exist"
            ) {
              closeModal(true, true, id ? id : state.editShiftShiftID);
            } else {
              closeModal();
            }
          }
        }

        // setDataLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });

    setInitialDone(true);
  };

  useEffect(() => {
    // cleaned
    const cancelSource1 = axios.CancelToken.source();

    if (getFromServerID) {
      getSingleShiftData(getFromServerID, "", cancelSource1.token);
      console.log({ getFromServerID });
      // setState({
      //   itemType: unitType,
      //   itemFName: unitFName,
      //   itemLName: unitLName,
      // });
      // setItemType(unitType);

      setChangeType(unitType);
      // setItemFName(unitFName);
      // setItemLName(unitLName);

      setShowName(true);

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.reloadManagerShift, reload]);

  let generateMonthDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    if (m !== 0) {
      return `${h} hours, ${m} mins`;
    } else if (h === 0 && m === 0) {
      return ``;
    } else {
      return `${h} hours`;
    }
  };

  let enterFctNote = (e) => {
    if (e.key === "Enter" || e === "add") {
      setToggleAddNote(false);

      if (shiftAddNoteValue) {
        let ds = dateStringer.createStringFromTimestamp(new Date().getTime());
        axios
          .post(
            `${serverURL}/add-note-to-shift`,
            {
              type: state.itemType,
              shiftID: state.editShiftShiftID,
              ds,
              note: shiftAddNoteValue,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setToggleAddNote(false);
              setShiftAddNoteValue("");
              setChangesMade(true);
              setShowNotify(true);

              // response.data.publicNote.sort(function (a, b) {
              //   return b.ts - a.ts;
              // });
              setState({
                editPublicNoteValue: [
                  ...state.editPublicNoteValue,
                  {
                    ts: response.data.ts,
                    note: shiftAddNoteValue,
                    ds,
                    noteID: response.data.noteID,
                    fName: response.data.fName,
                    lName: response.data.lName,
                    userID: memoVals.checkUserState.userID,
                    profPicUrl: response.data.profPicUrl,
                  },
                ],
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  let generateShiftTags = state.tags
    ? state.tags.map((tag, i) => {
        return (
          <div
            key={`${tag}${tag[i]}`}
            className={`tagUnit ${showNotes ? "notesTagUnit" : ""}`}
          >
            <p className="tagTxt">{tag}</p>
            {!showNotes ? (
              <img
                className="pullTagImg"
                src={closeGrey}
                alt="Remove tag"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/remove-shift-tag`,
                      {
                        shiftID: state.editShiftShiftID,
                        data: tag,
                        type: `${state.itemType === "shift" ? "shift" : "til"}`,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setChangesMade(true);
                        // setAmends(response.data.alerts || []);
                        setShowNotify(true);

                        setState({ tags: response.data.tags });

                        // add back to available tags
                        // let preAvaTags = availableTags
                        if (!availableTags.includes(tag)) {
                          setAvailableTags([...availableTags, tag]);
                        }
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      })
    : "";

  let validatePartialTimes = (startInput, endInput) => {
    // startInput comes in as 00:00
    if (startInput) {
      return false;
    }
  };

  // let formatEditPartials = (startHHMM, endHHMM) => {
  //   if (startHHMM) {
  //     return startHHMM;
  //   }
  // };

  let generateNotes =
    state.editPublicNoteValue && state.editPublicNoteValue[0]
      ? state.editPublicNoteValue
          .sort((a, b) => {
            return (
              (b.ts || dateStringer.createTimestampFromString(b.ds)) -
              (a.ts || dateStringer.createTimestampFromString(b.ds))
            );
          })
          .map((note) => {
            let nameVal = `${note.fName} ${note.lName}`;
            let usrLiked = false;
            if (note.likedUserObjects && note.likedUserObjects[0]) {
              note.likedUserObjects.forEach((obj) => {
                if (obj.userID === memoVals.checkUserState.userID) {
                  usrLiked = true;
                }
              });
            }

            return (
              <div className="shiftNotesObj">
                <div className="noteObjNameAndDs">
                  <p className="noteObjName">
                    {note.profPicUrl ? (
                      <img
                        src={note.profPicUrl}
                        alt={note.fName}
                        className="dayCommentComProfPic"
                      />
                    ) : (
                      <p className="dayCommInits">
                        {note.fName[0]}
                        {note.lName[0]}
                      </p>
                    )}{" "}
                    {nameVal && nameVal.length > 14
                      ? `${note.fName} ${
                          note.lName && note.lName[0] ? note.lName[0] : ""
                        }`
                      : nameVal}
                  </p>

                  <p className="noteObjFsDs">
                    {dateStringer.printedDateWithTimeFromDs(note.ds)}
                  </p>
                </div>
                <p className="noteObjNote">{note.note}</p>
                {/* 1 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}

                <div className="deleteNoteRow">
                  {note.likedUserObjects &&
                  note.likedUserObjects[0] &&
                  note.likedUserObjects.length > 0 ? (
                    // &&
                    // memoVals.checkUserState.userID === state.itemUserID
                    <div className="likedUsersSection">
                      {note.likedUserObjects[0] ? (
                        <p className="ackTitle">Acknowledged:</p>
                      ) : (
                        ""
                      )}
                      {note.likedUserObjects[0] ? (
                        <div
                          className="likedArrMapDiv"
                          onClick={() => {
                            if (showLikedCommentIDs.includes(note.noteID)) {
                              setShowCommentLikedIDs(
                                showLikedCommentIDs.filter((x) => {
                                  return x !== note.noteID;
                                })
                              );
                            } else {
                              setShowCommentLikedIDs([
                                ...showLikedCommentIDs,
                                note.noteID,
                              ]);
                            }
                          }}
                        >
                          {note.likedUserObjects.map((liked, i) => {
                            if (i < 8) {
                              return liked.profPicUrl ? (
                                <img
                                  src={liked.profPicUrl}
                                  alt={liked.fName}
                                  className="ackUsrIcon"
                                />
                              ) : (
                                <p className="ackUsrInitials">
                                  {liked.fName[0]}
                                  {liked.lName[0]}
                                </p>
                              );
                            }
                          })}{" "}
                          {note.likedUserObjects.length > 7 ? (
                            <p className="ackUsrExcess">
                              +{note.likedUserObjects.length - 8}
                            </p>
                          ) : (
                            ""
                          )}
                          {showLikedCommentIDs.includes(note.noteID) ? (
                            ""
                          ) : (
                            <img
                              src={acknowledgeArr}
                              alt="Show acknowledged"
                              className="showAckExpandIcon"
                              onClick={() => {
                                setShowCommentLikedIDs([
                                  ...showLikedCommentIDs,
                                  note.noteID,
                                ]);
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {showLikedCommentIDs.includes(note.noteID) ? (
                        <div className="showLikedNamesCom">
                          <img
                            onClick={() => {
                              setShowCommentLikedIDs(
                                showLikedCommentIDs.filter((x) => {
                                  return x !== note.noteID;
                                })
                              );
                            }}
                            src={arrGrey}
                            alt="Acknowledged"
                            className="ackArrClose"
                          />
                          <div className="ackNamesList">
                            {note.likedUserObjects.map((x) => {
                              if (x.userID === memoVals.checkUserState.userID) {
                                return <p className="ackNameItem">Me</p>;
                              } else {
                                return (
                                  <p className="ackNameItem">
                                    {x.fName} {x.lName}
                                  </p>
                                );
                              }
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <p></p>
                  )}
                  {/* <div className="clockNoteAckArr">
            {note.likedUserIDs &&
              note.likedUserIDs[0] &&
              note.likedUserIDs.map((liker) => {
                return liker.substr(0, 3);
              })}
          </div> */}

                  {note.userID === memoVals.checkUserState.userID ? (
                    <img
                      src={bin1}
                      alt="Remove note"
                      className="binNoteImg"
                      onClick={() => {
                        setState({
                          editPublicNoteValue: state.editPublicNoteValue.filter(
                            (x) => {
                              return x.noteID !== note.noteID;
                            }
                          ),
                        });
                        axios
                          .post(
                            `${serverURL}/add-note-to-shift`,
                            {
                              type: state.itemType,
                              shiftID: state.editShiftShiftID,
                              // ds: dateStringer.createStringFromTimestamp(
                              //   new Date().getTime()
                              // ),
                              // note: shiftAddNoteValue,
                              noteIDifRemoving: note.noteID,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              // setToggleAddNote(false);
                              setShiftAddNoteValue("");

                              // response.data.publicNote.sort(
                              //   (a, b) => b.ts - a.ts
                              // );

                              // setState({
                              //   editPublicNoteValue:
                              //     state.editPublicNoteValue.filter((x) => {
                              //       return x.noteID !== note.noteID;
                              //     }),
                              // });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  ) : (
                    <img
                      src={
                        hoveredLikeCommentID === note.noteID && usrLiked
                          ? liked2
                          : usrLiked
                          ? liked
                          : like
                      }
                      alt="Acknowledge note"
                      className={`binNoteImg ${usrLiked ? "x18429814581" : ""}`}
                      onMouseEnter={() => {
                        setHoveredLikeCommentID(note.noteID);
                      }}
                      onMouseLeave={() => {
                        setHoveredLikeCommentID("");
                      }}
                      onClick={() => {
                        setShowCommentLikedIDs(
                          (showLikedCommentIDs || []).filter((x) => {
                            return x !== note.noteID;
                          })
                        );

                        axios
                          .post(
                            `${serverURL}/acknowledge-shift-or-til-comment`,
                            {
                              type: state.itemType,
                              noteID: note.noteID,
                              shiftID: state.editShiftShiftID,
                              undo: usrLiked ? true : false,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              if (!usrLiked) {
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: `Acknowledged ${dateStringer.possession(
                                      note.fName
                                    )} note`,
                                    colour: "green",
                                    duration: 2000,
                                  };
                                });
                              }
                              // update here
                              // setUpdate(!update);
                              let newArr = [];
                              state.editPublicNoteValue.forEach((n) => {
                                if (n.noteID !== note.noteID) {
                                  newArr.push(n);
                                } else {
                                  // amend
                                  if (usrLiked) {
                                    // is undo
                                    // REMOVE IT
                                    let filteredOut = n.likedUserObjects.filter(
                                      (x) => {
                                        return (
                                          x.userID !==
                                          memoVals.checkUserState.userID
                                        );
                                      }
                                    );
                                    newArr.push({
                                      noteID: n.noteID,
                                      note: n.note,
                                      fName: n.fName,
                                      lName: n.lName,
                                      ds: n.ds,
                                      ts: n.ts,
                                      likedUserObjects: filteredOut,
                                      userID: n.noteID,
                                      profPicUrl: n.profPicUrl,
                                    });
                                  } else {
                                    newArr.push({
                                      noteID: n.noteID,
                                      note: n.note,
                                      fName: n.fName,
                                      lName: n.lName,
                                      ds: n.ds,
                                      ts: n.ts,
                                      profPicUrl: n.profPicUrl,
                                      likedUserObjects:
                                        n.likedUserObjects &&
                                        n.likedUserObjects[0]
                                          ? [
                                              {
                                                fName: response.data.fName,
                                                lName: response.data.lName,
                                                userID:
                                                  memoVals.checkUserState
                                                    .userID,
                                                profPicUrl:
                                                  response.data.profPicUrl,
                                              },
                                              ...n.likedUserObjects,
                                            ]
                                          : [
                                              {
                                                fName: response.data.fName,
                                                lName: response.data.lName,
                                                userID:
                                                  memoVals.checkUserState
                                                    .userID,
                                                profPicUrl:
                                                  response.data.profPicUrl,
                                              },
                                            ],
                                      userID: n.noteID,
                                    });

                                    // newObj.likedUserObjects.filter((x) => {
                                    //   return x.userID !== memoVals.checkUserState.userID;
                                    // });
                                  }
                                }
                              });

                              console.log({ newArr });
                              setState({ editPublicNoteValue: newArr });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    />
                  )}
                </div>
                {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              </div>
            );
          })
      : "";

  let getStartEndString = (start, end, getDaysAheadOnly) => {
    let startDate = new Date(start);
    let startH = startDate.getHours();
    let startM = startDate.getMinutes();

    let endDate = new Date(end);
    let endH = endDate.getHours();
    let endM = endDate.getMinutes();

    let start1 = new Date(start);
    let end1 = new Date(end);
    start1.setHours(0, 0, 0, 0);
    end1.setHours(0, 0, 0, 0);
    let daysBetween = Math.round(
      (end1.getTime() - start1.getTime()) / (1000 * 60 * 60 * 24)
    );

    let prepend0 = (val) => {
      if (JSON.stringify(val).length === 1) {
        return `0${val}`;
      } else {
        return val;
      }
    };
    if (!getDaysAheadOnly) {
      return `${prepend0(startH)}:${prepend0(startM)} - ${prepend0(
        endH
      )}:${prepend0(endM)}`;
    } else {
      return daysBetween ? ` +${daysBetween}d` : "";
    }
  };

  let getFullDate = (start) => {
    let dateObj = new Date(start);
    let day = dateObj.getDay();
    let date = dateObj.getDate();
    let m = dateObj.getMonth();
    let y = dateObj.getFullYear();
    let ySub = JSON.stringify(y).substr(2, 2);

    let convertDay = (num) => {
      if (num === 0) {
        return "Sun";
      } else if (num === 1) {
        return "Mon";
      } else if (num === 2) {
        return "Tue";
      } else if (num === 3) {
        return "Wed";
      } else if (num === 4) {
        return "Thu";
      } else if (num === 5) {
        return "Fri";
      } else if (num === 6) {
        return "Sat";
      }
    };

    let convertMonth = (num) => {
      if (num === 0) {
        return "Jan";
      } else if (num === 1) {
        return "Feb";
      } else if (num === 2) {
        return "Mar";
      } else if (num === 3) {
        return "Apr";
      } else if (num === 4) {
        return "May";
      } else if (num === 5) {
        return "Jun";
      } else if (num === 6) {
        return "Jul";
      } else if (num === 7) {
        return "Aug";
      } else if (num === 8) {
        return "Sep";
      } else if (num === 9) {
        return "Oct";
      } else if (num === 10) {
        return "Nov";
      } else if (num === 11) {
        return "Dec";
      }
    };

    return `${convertDay(day)} ${date} ${convertMonth(m)} '${ySub}`;
  };

  let addTagInputRef = useRef();
  let addNoteTextArea = useRef();
  let notesBox = useRef();
  let amendsRef = useRef();
  let mainRef = useRef();
  let absRef = useRef();

  useEffect(() => {
    if (makeAbsent) {
      console.log("1111");
      setTimeout(() => {
        if (mainRef.current && absRef.current) {
          // Calculate the top offset of absRef relative to amendsRef
          const offsetTop =
            absRef.current.offsetTop - mainRef.current.offsetTop - 150;
          console.log("222");

          // Scroll the parent element (amendsRef) to bring absRef into view
          mainRef.current.scrollTo({
            top: offsetTop,
            behavior: "smooth", // Smooth scroll behavior
          });
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    // clean not needed

    if (notesBox.current) {
      notesBox.current.scrollTop = 0;
    }
  }, [showNotes]);
  useEffect(() => {
    // clean not needed

    if (addNoteTextArea.current) {
      addNoteTextArea.current.focus();
    }
  }, [toggleAddNote]);

  let checkNewTilIsWhole = (
    itemBrk,
    newTilStart,
    newTilEnd,
    originalStart,
    originalEnd
  ) => {
    if (newTilStart && newTilEnd && originalStart && originalEnd) {
      let startObj = new Date();
      startObj.setHours(parseInt(newTilStart.substr(0, 2)));
      startObj.setMinutes(parseInt(newTilStart.substr(3, 2)));

      let endObj = new Date();
      endObj.setHours(parseInt(newTilEnd.substr(0, 2)));
      endObj.setMinutes(parseInt(newTilEnd.substr(3, 2)));

      let minsDiff = (endObj.getTime() - startObj.getTime()) / 1000 / 60;

      let originalDurExclBreak = (originalEnd - originalStart) / 1000 / 60;
      originalDurExclBreak -= itemBrk;

      if (minsDiff >= originalDurExclBreak) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    // clean not needed

    if (addTagInputRef && addTagInputRef.current) {
      addTagInputRef.current.focus();
    }
  }, [addingNewTag]);

  let enterFct = (e) => {
    if (e.key === "Enter") {
      if (newTagValue.length > 0) {
        setChangesMade(true);

        axios
          .post(
            `${serverURL}/add-new-shift-tag`,
            {
              shiftID: state.editShiftShiftID,
              data: newTagValue,
              addTagsToAll,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setState({ tags: response.data.tags });
              // setAmends(response.data.alerts || []);
              setAddingNewTag(false);
              setNewTagValue("");
              setShowNotify(true);

              if (addTagsToAll) {
                memoVals.setAddtagToAllUserShifts((x) => {
                  return {
                    userID: state.itemUserID,
                    tag: newTagValue,
                  };
                });
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };
  let generateMonthDurationForModal = (mins) => {
    let h = Math.floor(mins / 60);
    let m = Math.round(mins % 60);
    if (h === 0 && m === 1) {
      return "1 min";
    }
    if (mins < 60) {
      return `${Math.round(m)} mins`;
    }
    if (m !== 0) {
      return `${h}h, ${Math.round(m)}m`;
    } else if (h === 0 && m === 0) {
      return ``;
    } else {
      if (h === 1) {
        return `${h} hour`;
      } else {
        return `${h} hours`;
      }
    }
  };

  // let shortenTeamName = (name) => {
  //   if (name.length > 23) {
  //     return `${name.substr(0, 22)}...`;
  //   } else {
  //     return name;
  //   }
  // };

  let generateAvailableTeams = useMemo(() => {
    if (availableTeams) {
      return availableTeams.map((team) => {
        return (
          <option key={team.teamID} value={team.teamID}>
            {shortenTeamName(team.teamName)}
          </option>
        );
      });
    }
  }, [availableTeams]);

  let clockedTimesSameAsScheduled = () => {
    // nathan
    let startDs = dateStringer.createStringFromTimestamp(state.editShiftStart);
    let endDs = dateStringer.createStringFromTimestamp(state.editShiftEnd);

    if (
      (state.clockOn === startDs && endDs === state.clockOff) ||
      state.clockOnAmended
    ) {
      return true;
    }
  };
  let generateAvailableTags = availableTags.map((tag, i) => {
    return (
      <div
        className={`availableTagTxt ${
          editTagSuggestions ? "editTagDivFlex" : ""
        } ${i === availableTags.length - 1 ? "lastAvailTag" : ""}`}
        key={`${tag}${tag[i]}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!editTagSuggestions) {
            if (showAddShift) {
              setAddShiftTags([...addShiftTags, tag]);
              setShowAvailableTagsModal(false);
              let newArr = [];
              availableTags.forEach((t) => {
                if (t === tag) {
                } else {
                  newArr.push(t);
                }
              });
              setAvailableTags(newArr);
            } else {
              // setChangesMade(true);

              axios
                .post(
                  `${serverURL}/add-new-shift-tag`,
                  {
                    shiftID: state.editShiftShiftID,
                    data: tag,
                    open: false,
                    addTagsToAll,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setState({ tags: response.data.tags });
                    setAddingNewTag(false);
                    setShowNotify(true);

                    setNewTagValue("");
                    setShowAvailableTagsModal(false);
                    // setAmends(response.data.alerts || []);

                    let arr = [];
                    availableTags.forEach((aTag) => {
                      if (tag === aTag) {
                      } else {
                        arr.push(aTag);
                      }
                    });
                    setAvailableTags(arr);
                    if (addTagsToAll) {
                      memoVals.setAddtagToAllUserShifts((x) => {
                        return {
                          userID: state.itemUserID,
                          tag: tag,
                        };
                      });
                    }
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }
        }}
      >
        {tag}
        <img
          src={removeTagImg}
          className={`${editTagSuggestions ? "removeTagImg" : "none"}`}
          alt="Remove"
          onClick={(e) => {
            e.stopPropagation();
            axios
              .post(
                `${serverURL}/exclude-tag-from-bank`,
                {
                  teamID: state.shiftTeamID,
                  tag: tag,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  axios
                    .post(
                      `${serverURL}/get-available-tags`,
                      {
                        teamID: state.shiftTeamID,
                        // userID: state.itemUserID,
                        // nowDs: dateStringer.createStringFromTimestamp(
                        //   new Date().getTime()
                        // ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((resp) => {
                      if (resp.data.message === "success") {
                        let arr = [];
                        resp.data.tags.forEach((tag) => {
                          if (state.tags.includes(tag)) {
                          } else {
                            arr.push(tag);
                          }
                        });
                        setAvailableTags(arr);
                        setShowAvailableTagsModal(true);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              });
          }}
        />
      </div>
    );
  });
  // }
  // }, [availableTags, editTagSuggestions, addTagsToAll]);

  let [showShiftTemplateModal, setShowShiftTemplateModal] = useState(false);
  let [shiftTemplates, setShiftTemplates] = useState([]);
  let generateShiftTemplates = shiftTemplates.map((template) => {
    return (
      <p
        className="shiftTemplateUnitTxt"
        onClick={() => {
          // setDefaultStart(template.start);
          // setDefaultEnd(template.end);
          // setDefaultBrk(template.brk);

          setStartPurgatory(template.start);
          setStartPurgatorySubmit(JSON.stringify(template.start));

          setEndPurgatory(template.end);
          setEndPurgatorySubmit(JSON.stringify(template.end));

          setBreakPurgatory(parseInt(template.brk));
          setState({ editShiftBrk: parseInt(template.brk) });
          setShowShiftTemplateModal(false);
          setTimePickerDrops(false);
          // stella
        }}
        key={Math.random() * Math.random()}
      >
        {template.start} <span className="shiftTemplateSpan">-</span>{" "}
        {template.end} <span className="shiftTemplateSpan">&nbsp;/&nbsp;</span>{" "}
        {template.brk}m <span className="shiftTemplateSpan">break</span>
      </p>
    );
  });

  const tagsModalRef = useRef(null);

  const [isDraggingTags, setIsDraggingTags] = useState(false);
  const [dragStartTags, setDragStartTags] = useState({ x: 0, y: 0 });
  const [modalPositionTags, setModalPositionTags] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPositionTags, setDragPositionTags] = useState(0);

  const handleDownTags = (e) => {
    setIsDraggingTags(true);
    setDragStartTags({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveTags = (e) => {
    if (isDraggingTags) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartTags.y;

      if (deltaY > 0) {
        setDragPositionTags(deltaY);
        setModalPositionTags({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };
  useEffect(() => {
    if (showAvailableTagsModal) {
      setModalPositionTags({
        bottom: `0px`,
        // left: `calc(0% - ${deltaX}px)`,
      });
    }
  }, [showAvailableTagsModal]);

  const handleUpTags = () => {
    setIsDraggingTags(false);

    if (dragPositionTags > 140) {
      if (loading) {
      } else {
        setShowAvailableTagsModal(false);
      }
    } else {
      setModalPositionTags({ bottom: "0%", left: "0%" });
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      if (loading) {
      } else {
        if (state.itemIsNew) {
          memoVals.setDontAddNewItemToRota((x) => true);
          setSureCloseInitial(true);
        } else {
          closeModal();
        }
      }
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [isAtTop, setIsAtTop] = useState(true);
  let [okToDrag, setOkToDrag] = useState(true);
  // MASTER RETURN
  return (
    <div
      className={`myShiftModalUnderlay ${
        memoVals.clockOnID.clockOnID ||
        memoVals.showMgrAvailModal.ds ||
        memoVals.seeUserID.userID ||
        memoVals.noPermissions
          ? "invis"
          : ""
      } ${memoVals.showEditUser ? "invis_" : ""} `}
      onClick={() => {
        if (state.itemIsNew) {
          memoVals.setDontAddNewItemToRota((x) => true);
          setSureCloseInitial(true);
        } else {
          closeModal();
        }
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
          onClick={() => {
            console.log({ dataLoaded });
          }}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
            onClick={() => {
              console.log({ loading, dataLoaded });
            }}
          />
        </div>
      ) : (
        <div
          className={`${
            state.editShiftEnd && state.editShiftStart && state.editShiftShiftID
              ? "editShiftModalBox mgrShiftModalBoxBorder"
              : "none"
          }
        
        ${
          memoVals.mobModal
            ? "mobManagerShiftModalBox slideUp mobModalShoulder"
            : "zoomIn"
        } mgrModal
        `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperManagerShiftModal"></div>
          )}
          {/* header of add admin user - inherited from Add user to team modal in EditTeam.js */}
          <div
            className="addUserToTeamHeaderContainer"
            onClick={() => {
              if (memoVals.mobModal) {
                if (loading) {
                } else {
                  if (state.itemIsNew) {
                    memoVals.setDontAddNewItemToRota((x) => true);
                    setSureCloseInitial(true);
                  } else {
                    closeModal();
                  }
                }
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div
              className={`shiftCloseHolder ${loading ? "disableCloseBtn" : ""}`}
            >
              <img
                src={close}
                // src={memoVals.mobModal ? downModal : close}
                alt="Close"
                className="closeManageShiftModalBtnImg"
                onClick={() => {
                  if (loading) {
                  } else {
                    if (state.itemIsNew) {
                      memoVals.setDontAddNewItemToRota((x) => true);
                      setSureCloseInitial(true);
                    } else {
                      closeModal();
                    }
                  }
                }}
              />
            </div>
            <p
              className="addUserToTeamTitle x28349829211"
              onClick={() => {
                console.log({ state });
              }}
            >
              {state.itemGhost
                ? "Paid day off"
                : state.itemType === "shift"
                ? `${state.itemIsNew ? "Add shift" : "Shift"}`
                : `${
                    state.itemType === "overtime"
                      ? `${state.itemIsNew ? "Add overtime" : "Overtime"}`
                      : `${
                          state.itemIsNew
                            ? "Add shift in lieu"
                            : "Shift in lieu"
                        }`
                  }`}{" "}
            </p>
            <div className="shiftDurationTopRight">
              <p
                className={`${
                  state.editShiftAbsent
                    ? "editShiftDurationTopRightTxt txtRed"
                    : "editShiftDurationTopRightTxt"
                } ${
                  state.editShiftWorked && !state.editShiftAbsent
                    ? "txtGreen"
                    : ""
                } ${state.itemIsNew ? "none" : ""}`}
              >
                {generateMonthDurationForModal(
                  state.tilApproved && !state.tilDeclined
                    ? state.editDurationMins
                    : // - state.tilMins
                      state.editDurationMins
                )}
              </p>
              {showChangeTeam ? (
                ""
              ) : (
                <p className="payableShiftDur">
                  {state.itemType === "til" ? "earned" : "payable"}
                </p>
              )}
            </div>
          </div>
          {/* end of header */}
          {/* subheader bubble bar */}
          <div className="shiftBubblesBarManager">
            <div
              className={`shiftTeamSubBubble shiftTeamSubBubbleMgrBg cursorPointer ${
                showChangeTeam ? "none" : ""
              } ${
                state.payRoll &&
                state.payRoll.salaried &&
                state.payRoll.payRoll < 0
                  ? "font12px"
                  : ""
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/get-teams-user-manages`,
                    { userIDThatUserIsPartOfAndJwtManages: state.itemUserID },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setAvailableTeams(response.data.array);
                      setShowChangeTeam(true);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {shortenTeamName(state.showShiftModalTeamName)}
              <img
                src={editGrey}
                className="purpleEditChangeTeamIcon"
                alt="Change team"
              />
            </div>
            <select
              className={`${showChangeTeam ? "editShiftTeamDropdown" : "none"}`}
              value={state.shiftTeamID}
              onChange={(e) => {
                axios
                  .post(
                    `${serverURL}/edit-shift-team`,
                    {
                      shiftID: state.editShiftShiftID,
                      type: state.itemType,
                      teamID: e.target.value,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setChangesMade(true);
                      setState({
                        showShiftModalTeamName: response.data.teamName,
                        shiftTeamID: response.data.teamID,
                      });
                      setShowChangeTeam(false);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              {generateAvailableTeams}
            </select>
            <p
              className={`${
                // shiftCost > 0 &&
                state.itemType !== "til" &&
                !state.initial &&
                !state.itemIsNew &&
                !showChangeTeam
                  ? "shiftCostBubble mgrModalBgShiftCost"
                  : "none"
              } ${state.salaried ? "salariedCost" : ""}`}
            >
              {state.salaried
                ? state.payRoll && state.payRoll.salaried
                  ? state.payRoll.payRoll < 0
                    ? `${memoVals.currencySymbol}${
                        Math.round(
                          (state.payRoll.payRoll -
                            state.payRoll.payRoll -
                            state.payRoll.payRoll +
                            Number.EPSILON) *
                            100
                        ) / 100
                          ? JSON.stringify(
                              (
                                Math.round(
                                  (state.payRoll.payRoll -
                                    state.payRoll.payRoll -
                                    state.payRoll.payRoll +
                                    Number.EPSILON) *
                                    100
                                ) / 100
                              ).toFixed(2)
                            ).includes(".00")
                            ? Math.round(
                                (state.payRoll.payRoll -
                                  state.payRoll.payRoll -
                                  state.payRoll.payRoll +
                                  Number.EPSILON) *
                                  100
                              ) / 100
                            : (
                                Math.round(
                                  (state.payRoll.payRoll -
                                    state.payRoll.payRoll -
                                    state.payRoll.payRoll +
                                    Number.EPSILON) *
                                    100
                                ) / 100
                              ).toFixed(2)
                          : 0
                      } deduction`
                    : state.payRoll.payRoll === 0
                    ? `Salaried (${memoVals.currencySymbol}${
                        state.shiftCost
                          ? JSON.stringify(state.shiftCost.toFixed(2)).includes(
                              "00"
                            )
                            ? state.shiftCost
                            : state.shiftCost.toFixed(2)
                          : ""
                      })`
                    : `${memoVals.currencySymbol}${
                        Math.round(
                          (state.payRoll.payRoll + Number.EPSILON) * 100
                        ) / 100
                      } added to salary`
                  : ""
                : `${memoVals.currencySymbol}${
                    state.shiftCost
                      ? JSON.stringify(state.shiftCost.toFixed(2)).includes(
                          "00"
                        )
                        ? state.shiftCost
                        : state.shiftCost.toFixed(2)
                      : ""
                  }`}
            </p>

            <p
              className={`${
                itemBankData.bank &&
                itemBankData.bankCost > 0 &&
                state.itemType !== "til" &&
                !showChangeTeam
                  ? "shiftCostBubble mgrModalBgShiftCost bankCostBubble"
                  : "none"
              }`}
            >
              +{memoVals.currencySymbol}
              {itemBankData.bankCost && itemBankData.bankCost.toFixed(2)}{" "}
            </p>
          </div>
          {/* end of subheader bubble bar */}
          {/* modal body */}{" "}
          <p
            className={`shiftPublishedStatus ${
              state.itemIsNew && publishImmediately
                ? ""
                : `${
                    (!state.itemIsNew && !state.published) ||
                    (state.itemIsNew && !state.publishImmediately)
                      ? "unpubBg"
                      : ""
                  }`
            }`}
          >
            {state.published
              ? `${
                  state.itemIsNew
                    ? `${
                        publishImmediately
                          ? "To be published now"
                          : "Not to be published yet"
                      }`
                    : "Published"
                }`
              : `${
                  state.itemIsNew && !publishImmediately
                    ? "Not to be published yet"
                    : `${
                        state.itemIsNew && publishImmediately
                          ? "To be published now"
                          : "Unpublished"
                      }`
                }`}
          </p>
          {showChangeReqs ? (
            <div
              ref={amendsRef}
              className={`editShiftModalBody   ${
                memoVals.mobModal ? "mobManagerShiftModalBody" : ""
              }`}
            >
              <div className="myShiftRow changesArrRow">
                <p
                  className="showChBackBtn"
                  onClick={() => {
                    setShowChangeReqs(false);
                  }}
                >
                  <img src={whiteBack3} alt="Back" className="backChangesImg" />{" "}
                  Back
                </p>
                <p className="shiftHistoryTitle reqsToChangeTitle">
                  {state.itemFName} has requested the following change to this{" "}
                  {state.itemType === "til" ? "shift in lieu" : state.itemType}
                </p>
                {changeRequests && changeRequests[0] && (
                  <div className="shiftChangeFlex">
                    <div className="changeRowLeft x11221">
                      <p className="oldShiftDate textAlignLeft x0912844">
                        {dateStringer.printedDateWithYrFromDs(
                          changeRequests[0].oldDs
                        )}
                      </p>
                      <p className="oldShiftTimes textAlignLeft x4264723">
                        {changeRequests[0].oldStart.length > 10
                          ? `${changeRequests[0].oldStart.substr(
                              12,
                              2
                            )}:${changeRequests[0].oldStart.substr(15, 2)}`
                          : changeRequests[0].oldStart}{" "}
                        -{" "}
                        {changeRequests[0].oldEnd.length > 10
                          ? `${changeRequests[0].oldEnd.substr(
                              12,
                              2
                            )}:${changeRequests[0].oldEnd.substr(15, 2)}`
                          : changeRequests[0].oldEnd}
                      </p>
                      <p className="oldShiftBrk textAlignLeft x874722">
                        {dateStringer.formatMinsDurationToHours(
                          changeRequests[0].oldBrk
                        )}{" "}
                        break
                      </p>
                    </div>
                    <div className="changeRowMiddle">
                      <img
                        src={shiftChangeArrow}
                        alt="arrow"
                        className="changeShiftArrowImg"
                      />
                    </div>
                    <div className="changeRowRight x11221">
                      <p
                        className={`oldShiftDate ${
                          changeRequests[0].newDs.substr(0, 11) !==
                          changeRequests[0].oldDs.substr(0, 11)
                            ? "highlightedChangeElement"
                            : ""
                        } x0912844`}
                      >
                        {dateStringer.printedDateWithYrFromDs(
                          changeRequests[0].newDs
                        )}
                      </p>
                      <p
                        className="oldShiftTimes x4264723"
                        // {`oldShiftTimes
                        // ${
                        //   change.oldStart !== change.newStart ||
                        //   change.oldEnd !== change.newEnd
                        //     ? "highlightedChangeElement"
                        //     : ""
                        // }
                        // `}
                      >
                        <span
                          className={`${
                            changeRequests[0].newStart !==
                            changeRequests[0].oldStart
                              ? "highlightedChangeElement"
                              : ""
                          }`}
                        >
                          {changeRequests[0].newStart.length > 10
                            ? `${changeRequests[0].newStart.substr(
                                12,
                                2
                              )}:${changeRequests[0].newStart.substr(15, 2)}`
                            : changeRequests[0].newStart}{" "}
                        </span>{" "}
                        -{" "}
                        <span
                          className={`${
                            changeRequests[0].newEnd !==
                            changeRequests[0].oldEnd
                              ? "highlightedChangeElement"
                              : ""
                          }`}
                        >
                          {changeRequests[0].newEnd.length > 10
                            ? `${changeRequests[0].newEnd.substr(
                                12,
                                2
                              )}:${changeRequests[0].newEnd.substr(15, 2)}`
                            : changeRequests[0].newEnd}
                        </span>
                      </p>
                      <p
                        className={`${
                          changeRequests[0].newBrk !== changeRequests[0].oldBrk
                            ? "highlightedChangeElement"
                            : ""
                        } oldShiftBrk x874722`}
                      >
                        {dateStringer.formatMinsDurationToHours(
                          changeRequests[0].newBrk
                        )}{" "}
                        break
                      </p>
                    </div>
                  </div>
                )}

                <div className="chMgrActions">
                  <p className="chMgrTsMade">
                    Requested{" "}
                    {dateStringer.printedDateFromDs(
                      changeRequests[0].dsMade,
                      true,
                      true,
                      true
                    )}
                  </p>
                  {changeRequests[0].note ? (
                    <p className="chMgrTsMade x323425">
                      <span className="makeBold x12144">
                        Note from {state.itemFName}:
                      </span>{" "}
                      {changeRequests[0].note}
                    </p>
                  ) : (
                    ""
                  )}

                  <p className="newOldDurTxt">
                    Old duration:{" "}
                    <span className="newOldDurValueSpan">
                      {dateStringer.formatMinsDurationToHours(
                        state.editDurationMins
                      )}
                    </span>
                  </p>
                  <p className="newOldDurTxt">
                    New duration:{" "}
                    <span className="newOldDurValueSpan">
                      {dateStringer.formatMinsDurationToHours(
                        calcDur(
                          changeRequests[0].newStart,
                          changeRequests[0].newEnd,
                          changeRequests[0].newBrk
                        )
                      )}
                    </span>
                  </p>
                  <div className="chMgrDivCtaDiv">
                    <p
                      className="approveShCh"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/approve-or-decline-shift-edit`,
                            {
                              editID: changeRequests[0].editID,
                              userID: state.itemUserID,
                              decision: "approve",
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              getSingleShiftData(state.editShiftShiftID);
                              setShowChangeReqs(false);
                            }
                          });
                      }}
                    >
                      Approve
                    </p>
                    <p
                      className="declineShCh"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/approve-or-decline-shift-edit`,
                            {
                              editID: changeRequests[0].editID,
                              userID: state.itemUserID,
                              decision: "decline",
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              getSingleShiftData(state.editShiftShiftID);
                              setShowChangeReqs(false);
                            }
                          });
                      }}
                    >
                      Decline
                    </p>
                  </div>
                </div>
                {/* romania */}

                {/* {amends
                    .sort((a, b) => {
                      return b.ts - a.ts;
                    })
                    .map((amend) => {
                      let change = "";
                      if (amend.type === "break") {
                        change = (
                          <>
                            The break was amended from
                            <br />
                            <span className="amendBoldSpan">
                              {dateStringer.formatMinsDurationToHours(
                                amend.from
                              )}
                            </span>{" "}
                            to{" "}
                            <span className="amendBoldSpan">
                              {dateStringer.formatMinsDurationToHours(amend.to)}
                            </span>
                          </>
                        );
                      }
                      if (amend.type === "times") {
                        change = (
                          <>
                            The times were amended from
                            <br />
                            <span className="amendBoldSpan">
                              {amend.from}
                            </span>{" "}
                            to <span className="amendBoldSpan">{amend.to}</span>
                          </>
                        );
                      }
                      if (amend.type === "date") {
                        change = (
                          <>
                            The date was amended from
                            <br />
                            <span className="amendBoldSpan">
                              {dateStringer.printedDateFromDs(amend.from)}
                            </span>{" "}
                            to{" "}
                            <span className="amendBoldSpan">
                              {dateStringer.printedDateFromDs(amend.to)}
                            </span>
                          </>
                        );
                      }
                      if (amend.type === "type") {
                        change = (
                          <>
                            The type was amended from
                            <br />
                            <span className="amendBoldSpan">
                              {amend.from}
                            </span>{" "}
                            to <span className="amendBoldSpan">{amend.to}</span>
                          </>
                        );
                      }
                      if (amend.type === "adding") {
                        change = (
                          <>
                            This{" "}
                            <span className="amendBoldSpan">
                              {state.itemType === "til"
                                ? "shift in lieu"
                                : state.itemType}
                            </span>{" "}
                            was added
                          </>
                        );
                      }
                      if (amend.type === "tag") {
                        if (amend.to) {
                          change = (
                            <>
                              The tag{" "}
                              <span className="amendBoldSpan">{amend.to}</span>{" "}
                              was added
                            </>
                          );
                        }
                        if (amend.from) {
                          change = (
                            <>
                              The tag{" "}
                              <span className="amendBoldSpan">
                                {amend.from}
                              </span>{" "}
                              was removed
                            </>
                          );
                        }
                      }
                      return (
                        <div className="shiftAmendUnit">
                          <p className="shiftAmendType">{change}</p>
                          <p className="shiftAmendBy">by {amend.mgr}</p>
                          <p className="shiftAmendWhen">
                            on {dateStringer.printedDateFromDs(amend.ds)}
                          </p>
                          {amend.acknowledged ? (
                            <p
                              className={`shiftAmendAck ${
                                memoVals.checkUserState.userID === state.itemUserID
                                  ? "none"
                                  : ""
                              }`}
                            >
                              <img
                                src={greenTick}
                                alt="Acknowledged"
                                className="acknowledgedAmendTickImg"
                              />
                              Acknowledged on{" "}
                              {dateStringer.printedDateFromDs(
                                amend.acknowledged
                              )}
                            </p>
                          ) : (
                            <p
                              className={`shiftAmendAck ${
                                memoVals.checkUserState.userID === state.itemUserID
                                  ? "none"
                                  : ""
                              }`}
                            >
                              Not yet acknowledged by {state.itemFName}
                            </p>
                          )}
                        </div>
                      );
                    })} */}
              </div>
            </div>
          ) : showNotes ? (
            <div
              ref={notesBox}
              className={`editShiftModalBody   ${
                memoVals.mobModal ? "mobManagerShiftModalBody" : ""
              }`}
            >
              <div className="shiftNoteHeaderBox">
                <p className="shiftNoteHeaderTxt">
                  {state.fName}'
                  {state.fName[state.fName.length - 1] === "s" ? "" : "s"}{" "}
                  {state.itemType === "shift"
                    ? "shift"
                    : state.itemType === "overtime"
                    ? "overtime"
                    : "shift in lieu"}{" "}
                  on{" "}
                  {dateStringer.printedDateFromDs(
                    dateStringer.createStringFromTimestamp(state.editShiftStart)
                  )}
                </p>
                <p className="shiftNoteHeaderTxt2">
                  From{" "}
                  {dateStringer.dsToTimeStrip(
                    dateStringer.createStringFromTimestamp(state.editShiftStart)
                  )}{" "}
                  until{" "}
                  {dateStringer.dsToTimeStrip(
                    dateStringer.createStringFromTimestamp(state.editShiftEnd)
                  )}
                </p>
                <div className="noteShiftTagsDiv">{generateShiftTags}</div>
              </div>
              <div className="shiftNotesTitleAndAdd">
                <p className="shiftNotesTitleTxt">Notes</p>
                <div
                  className={`addClockNoteBtn ${
                    toggleAddNote ? "addClockNoteBtnActive_" : ""
                  }`}
                  onClick={() => {
                    setToggleAddNote(!toggleAddNote);
                    setShiftAddNoteValue("");
                  }}
                >
                  Add note{" "}
                  <img
                    src={toggleAddNote ? greyClose : noteImg}
                    alt="Add note"
                    className={`addNoteInlineImg ${
                      toggleAddNote ? "x19387459" : ""
                    }`}
                  />
                </div>
                {/* {!toggleAddNote ? (
                <div
                  className="shiftNotesAddNoteBtn"
                  onClick={() => {
                    setToggleAddNote(true);
                  }}
                >
                  <span className="addNotePlusSign">+</span> Add note
                </div>
              ) : (
                ""
              )} */}
              </div>
              {toggleAddNote ? (
                <div className="shiftAddNoteDiv">
                  <textarea
                    ref={addNoteTextArea}
                    className="shiftAddNoteTextArea"
                    value={shiftAddNoteValue}
                    onKeyPress={(e) => {
                      enterFctNote(e);
                    }}
                    onChange={(e) => {
                      setShiftAddNoteValue(e.target.value);
                    }}
                  ></textarea>
                  <div className="shiftAddNoteRightBtns">
                    <p
                      className={`shiftAddNoteSaveBtn ${
                        !shiftAddNoteValue ? "disable" : ""
                      }`}
                      onClick={() => {
                        enterFctNote("add");
                      }}
                    >
                      {/* shiftID, type, ds, noteIDifRemoving, note */}
                      Add
                    </p>
                    {/* <p
                    className="shiftAddNoteSaveBtnCanx"
                    onClick={() => {
                      setToggleAddNote(false);
                    }}
                  >
                    Cancel
                  </p> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {memoVals.mobModal && toggleAddNote ? ( */}
              {/* // "" // ) : ( */}
              <div className="notesHolder">
                {state.editPublicNoteValue && state.editPublicNoteValue[0] ? (
                  generateNotes
                ) : (
                  <p className="noShiftNotes">No notes yet</p>
                )}
              </div>
              {/* )} */}
            </div>
          ) : (
            <div
              ref={mainRef}
              // onScroll={(e) => {
              //   if (e.target.scrollTop === 0) {
              //     setIsAtTop(true);
              //     setOkToDrag(false);
              //   } else {
              //     setIsAtTop(false);
              //   }
              // }}
              // onTouchStart={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleDown : null
              // }
              // onTouchMove={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleMove : null
              // }
              // onTouchEnd={() => {
              //   memoVals.mobModal && okToDrag && isAtTop ? handleUp : null;
              //   setOkToDrag(true);
              // }}
              // onMouseDown={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleDown : null
              // }
              // onMouseMove={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleMove : null
              // }
              // onMouseUp={
              //   memoVals.mobModal && okToDrag && isAtTop ? handleUp : null
              // }
              className={`${
                state.editShiftStart && state.editShiftEnd
                  ? "editShiftModalBody"
                  : "none"
              }     
          ${memoVals.mobModal ? "mobManagerShiftModalBody" : ""}
          `}
            >
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  showName ? "editShiftRowEmployeeNameAndPic" : "none"
                }`}
              >
                {state.profPicUrl ? (
                  <img
                    src={state.profPicUrl}
                    alt={state.itemFName}
                    className="managerShiftProPicImg"
                    onClick={() => {
                      if (memoVals.showEditUser) {
                        closeModal();
                      } else {
                        memoVals.setShowEditUser((x) => state.itemUserID);
                        closeModal();
                      }
                    }}
                  />
                ) : (
                  <div
                    className="managerShiftInitialsPlaceholder"
                    onClick={() => {
                      if (memoVals.showEditUser) {
                        closeModal();
                      } else {
                        memoVals.setShowEditUser((x) => state.itemUserID);
                        closeModal();
                      }
                    }}
                  >
                    {state.itemFName && state.itemFName[0]}
                    {state.itemLName && state.itemLName[0]}
                  </div>
                )}

                <div className="shiftEditTitle mgrModalTitleColour">
                  <div className="shiftEditTitleTxt x292093091">Employee</div>
                  <div className="shiftEditValue">
                    {state.itemFName} {state.itemLName}
                  </div>
                  {state.requireAccept && state.accepted ? (
                    <div className="mgrShModalAcceptRow">
                      <img
                        src={doubleTick}
                        alt="Accepted"
                        className="acceptMgrShRowImg"
                      />{" "}
                      Accepted{" "}
                      {dateStringer.printedDateWithTimeFromDs(state.accepted)}
                    </div>
                  ) : state.requireAccept && !state.itemIsNew ? (
                    <div className="notYetAccWithForceRow">
                      <div className="mgrShModalAcceptRow x23892j3">
                        <div className="notYetAccBlob"></div>
                        Not yet accepted
                      </div>
                      <p
                        className="forceAcceptBtn"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/accept-shift`,
                              {
                                shiftID: state.editShiftShiftID,
                                type: `${
                                  state.itemType === "shift" ? "shift" : "til"
                                }`,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                force: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setState({
                                  accepted: response.data.accepted,
                                  forceAccceptMgrName:
                                    response.data.acceptedByMgrName,
                                });
                                setChangesMade(true);
                                // setAcceptedShiftID(state.editShiftShiftID);
                              }
                            });
                        }}
                      >
                        Force accept
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {state.requireAccept &&
                  state.accepted &&
                  state.forceAccceptMgrName ? (
                    <div className="mgrShModalAcceptRow xu2321">
                      Accepted on {dateStringer.possession(state.itemFName)}{" "}
                      behalf by {state.forceAccceptMgrName}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="shiftUserInfoTriangle"
                  onClick={() => {
                    setExpandShiftInfo(!expandShiftInfo);
                    if (!expandShiftInfo) {
                      if (!hoursData.teamName || changesMade) {
                        axios
                          .post(
                            `${serverURL}/get-user-hours-surrounding-shift`,
                            {
                              shiftID:
                                state.itemType === "shift"
                                  ? state.editShiftShiftID
                                  : "",
                              tilID:
                                state.itemType !== "shift"
                                  ? state.editShiftShiftID
                                  : "",
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setHoursData(response.data);
                              console.log(response.data);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }
                  }}
                >
                  {/* <img
                src={whitecross}
                alt="close"
                className="closeShiftHoursDataWhiteCrossImg"
              /> */}
                  {/* <p className="shiftHoursDataTxt">Hours</p> */}

                  <img
                    src={expandShiftInfo ? greyClose : shiftInfo}
                    alt="Expand info"
                    className={`shiftExpandInfoImg ${
                      expandShiftInfo ? "expandGreyCloseShiftInfo" : ""
                    }`}
                  />
                </div>
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}
              {/* employee data row */}
              {expandShiftInfo ? (
                <div className="shiftManagerDataDiv">
                  <div className="shiftDataTableHolder">
                    <div className="shiftDataTable">
                      <div className="shiftDataTableRow">
                        <p className="shiftDataTableTitle">&nbsp;</p>
                        <p className="shiftDataTableValue monthHoursDataTitle">
                          {state.fName}
                          {state.fName[state.fName - 1] === "s"
                            ? "'"
                            : "'s"}{" "}
                          hours in{" "}
                          {dateStringer.getMonthThreeLettersFromDs(
                            dateStringer.createStringFromTimestamp(
                              state.editShiftStart
                            ),
                            true
                          )}
                        </p>
                      </div>
                      <div className="shiftDataTableRow shiftDataBorderTopThick">
                        <p className="shiftDataTableTitle">
                          Hours for{" "}
                          {hoursData.teamName
                            ? shortenTeamName(hoursData.teamName)
                            : "-"}
                        </p>
                        <p className="shiftDataTableValue">
                          {hoursData.teamName
                            ? dateStringer.formatMinsDurationToHours(
                                hoursData.currentTeamShiftMins +
                                  hoursData.currentTeamOvertimeMins
                              )
                            : "-"}
                        </p>
                      </div>

                      <div className="shiftDataTableRow shiftDataRowIndent shiftDataTableRowTop1pxBorder">
                        <div className="shiftDataTableTitle">
                          <img
                            src={sub}
                            alt="sub"
                            className="shiftDataSubIcon"
                          />
                          Regular
                        </div>
                        <p className="shiftDataTableValue">
                          {hoursData.teamName
                            ? dateStringer.formatMinsDurationToHours(
                                hoursData.currentTeamShiftMins
                              )
                            : "-"}
                        </p>
                      </div>

                      <div className="shiftDataTableRow shiftDataRowIndent shiftDataTableRowTop1pxBorder">
                        <div className="shiftDataTableTitle">
                          {" "}
                          <img
                            src={sub}
                            alt="sub"
                            className="shiftDataSubIcon"
                          />
                          Overtime
                        </div>
                        <p className="shiftDataTableValue">
                          {" "}
                          {hoursData.teamName
                            ? dateStringer.formatMinsDurationToHours(
                                hoursData.currentTeamOvertimeMins
                              )
                            : "-"}
                        </p>
                      </div>

                      <div className="shiftDataTableRow shiftDataBorderTopThick">
                        <p className="shiftDataTableTitle">
                          Hours for other teams{" "}
                        </p>
                        <p className="shiftDataTableValue">
                          {" "}
                          {hoursData.teamName
                            ? dateStringer.formatMinsDurationToHours(
                                hoursData.monthOtherTeamMins
                              )
                            : "-"}
                        </p>
                      </div>

                      {hoursData.enableTil && hoursData.teamName ? (
                        <div className="shiftDataTableRow shiftDataBorderTopThick">
                          <p className="shiftDataTableTitle">
                            TOIL earned in{" "}
                            {dateStringer.getMonthThreeLettersFromDs(
                              dateStringer.createStringFromTimestamp(
                                state.editShiftStart
                              ),
                              false
                            )}
                          </p>
                          <p className="shiftDataTableValue">
                            {dateStringer.formatMinsDurationToHours(
                              hoursData.tilEarnedAcrossYear
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {hoursData.enableTil && hoursData.teamName ? (
                        <div className="shiftDataTableRow shiftDataTableRowTop1pxBorder">
                          <p className="shiftDataTableTitle">
                            TOIL taken in{" "}
                            {dateStringer.getMonthThreeLettersFromDs(
                              dateStringer.createStringFromTimestamp(
                                state.editShiftStart
                              ),
                              false
                            )}
                          </p>
                          <p className="shiftDataTableValue">
                            {dateStringer.formatMinsDurationToHours(
                              hoursData.tilUsedAcrossYear
                            )}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* end of employee data row */}
              {/* new row - - - - - - - - - - - - - - - - */}
              {!state.clockOnApproved &&
              !state.clockOnDeclined &&
              !state.clockOff ? (
                ""
              ) : state.clockOn ? (
                <div className={`editShiftRow `} onClick={() => {}}>
                  <div className="shiftTypeLeftBox x7187372">
                    <div className={`shiftEditTitleTxt x238312`}>
                      This{" "}
                      {state.itemType === "shift"
                        ? "shift"
                        : state.itemType === "til"
                        ? "worked TOIL"
                        : "overtime"}{" "}
                      is linked to a clock card
                    </div>
                    <p
                      className="viewApprovedTsItem mgrCtaColours"
                      onClick={(e) => {
                        e.stopPropagation();
                        memoVals.setClockOnID((x) => {
                          return {
                            clockOnID: state.clockOnIDLocal,
                            isMgr: true,
                          };
                        });
                      }}
                    >
                      View clock card
                      <img
                        src={popup}
                        alt="View item"
                        className="viewTsItemPopUp"
                      />
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {state.timesheetID && !state.clockOn ? (
                <div className={`editShiftRow `} onClick={() => {}}>
                  <div className="shiftTypeLeftBox x7187372">
                    <div className={`shiftEditTitleTxt x238312`}>
                      This{" "}
                      {state.itemType === "shift"
                        ? "shift"
                        : state.itemType === "til"
                        ? "shift in lieu"
                        : "overtime"}{" "}
                      was created by an approved timesheet
                    </div>
                    {state.tsNoLongerAvailable ? (
                      <p className="tsNoLongerAvailInShift">
                        Timesheet is no longer available
                      </p>
                    ) : (
                      <p
                        className="viewApprovedTsItem mgrCtaColours"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            memoVals.showViewTimesheet.timesheetID ===
                              state.timesheetID &&
                            memoVals.showViewTimesheet.show
                          ) {
                            closeModal();
                          } else {
                            closeModal();

                            memoVals.setShowViewTimesheet((x) => {
                              return {
                                timesheetID: state.timesheetID,
                                show: true,
                                isMgr: true,
                              };
                            });
                          }
                        }}
                      >
                        View timesheet
                        <img
                          src={popup}
                          alt="View item"
                          className="viewTsItemPopUp"
                        />
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* new row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`editShiftRow ${
                  !showEditUnitType && !state.itemIsNew ? "" : ""
                } `}
                onClick={() => {
                  // if (!showEditUnitType && !itemIsNew) {
                  //   setShowEditUnitType(true);
                  // }
                }}
              >
                <div className="shiftTypeLeftBox">
                  <div className="shiftEditTitleTxt">Type</div>

                  {state.itemType === "til" ? (
                    <div className="tilLeftX00">
                      <div className="shiftEditTitle move5pxR">
                        Shift in lieu
                      </div>
                      <p className="shiftInLieuExplain marginBottom20px">
                        This shift is unpaid, but the duration is added to{" "}
                        {dateStringer.possession(state.itemFName)} time off in
                        lieu (TOIL) balance. The TOIL balance allows{" "}
                        {state.itemFName} to claim time off from other scheduled
                        shifts.
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`${
                        showEditUnitType ? "none" : "shiftEditValue"
                      }`}
                    >
                      {state.itemType === "shift"
                        ? "Shift"
                        : `${
                            state.itemType === "til"
                              ? "shift in lieu"
                              : "Overtime"
                          }`}
                    </div>
                  )}
                  <select
                    className={`${
                      showEditUnitType ? "unitTypeDropdown" : "none"
                    }`}
                    value={changeType}
                    onChange={(e) => {
                      setChangeType(e.target.value);
                    }}
                  >
                    <option value="shift">Shift</option>
                    <option value="overtime">Overtime</option>
                    <option value="til">Shift in lieu</option>
                  </select>
                </div>

                <img
                  src={editGrey}
                  className={`${
                    showEditUnitType ? "none" : "editShiftPencil"
                  } ${state.itemIsNew ? "voidRowIfNew" : ""}`}
                  onClick={() => {
                    if (!showEditUnitType && !state.itemIsNew) {
                      setShowEditUnitType(true);
                    }
                  }}
                />
                <p
                  className={`${
                    showEditUnitType ? "saveBtnTxt saveBtnPushedDown" : "none"
                  }`}
                  onClick={() => {
                    if (!state.itemIsNew) {
                      // fire request here
                      setChangesMade(true);

                      axios
                        .post(
                          `${serverURL}/convert-shift-or-til`,
                          {
                            shiftID: state.editShiftShiftID,
                            oldType: state.itemType,
                            newType: changeType,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setState({ itemType: changeType });
                            setShowNotify(true);

                            getSingleShiftData(
                              state.editShiftShiftID,
                              changeType,
                              false
                            );
                            setShowEditUnitType(!showEditUnitType);
                          } else {
                            setShowEditUnitType(!showEditUnitType);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Save
                </p>
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}
              <div className={`${state.specialName ? "editShiftRow" : "none"}`}>
                <div className="shiftEditTitle specialNameTitle move5pxR">
                  {state.specialName}
                </div>{" "}
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost || unitShowGhostOption || state.specialName
                    ? `editShiftRow ${
                        !memoVals.mobModal ? "clickShiftRow" : ""
                      }`
                    : "none"
                }`}
                onClick={() => {
                  // setGhost(!ghost);
                  setChangesMade(true);

                  axios
                    .post(
                      `${serverURL}/toggle-shift-ghost`,
                      {
                        shiftID: state.editShiftShiftID,
                        data: !state.itemGhost,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setReload(!reload);
                        // setState({
                        //   itemGhost: response.data.ghost,
                        //   hideClockOnRequired: response.data.ghost,
                        //   requireClockOn: response.data.clockonRequired,
                        //   inLieu: response.data.inLieu,
                        // });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <div className="shiftEditTitle move5pxR">Paid day off</div>

                {/*  KNOB BEGIN */}
                <div className="editUserReqClockOutKnobContainer">
                  <div className="knobContainer knobContainerEditUser knobEditShift">
                    <div
                      className={`switchKnob ${
                        state.itemGhost ? "knobOn knobOnMgrColour" : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}
              </div>
              <div
                className={`${
                  state.itemGhost || unitShowGhostOption || state.specialName
                    ? `editShiftRow ${
                        !memoVals.mobModal ? "clickShiftRow" : ""
                      }`
                    : "none"
                }`}
                onClick={() => {
                  // setGhost(!ghost);
                  setChangesMade(true);

                  axios
                    .post(
                      `${serverURL}/make-shift-in-lieu`,
                      {
                        shiftID: state.editShiftShiftID,
                        data: !state.inLieu,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setState({
                          inLieu: response.data.data ? true : false,
                          itemGhost: response.data.ghost,
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <div className="shiftInLieuLeft">
                  <div className="shiftEditTitle move5pxR">Shift in lieu</div>
                  <p className="shiftInLieuExplain">
                    Add the payable duration of this shift to{" "}
                    {dateStringer.possession(state.itemFName)} TOIL balance
                  </p>
                </div>

                {/*  KNOB BEGIN */}
                <div className="editUserReqClockOutKnobContainer">
                  <div className="knobContainer knobContainerEditUser knobEditShift">
                    <div
                      className={`switchKnob ${
                        state.inLieu ? "knobOn knobOnMgrColour" : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}
              {/* new row...time row- - - - - - - - - - - - - - - - */}
              <div
                className={`editShiftRow ${
                  state.itemIsNew ? "timesItemIsNewBg displayBlock" : ""
                }
             ${
               !state.clockOnAmended &&
               state.clockOnIDLocal &&
               state.createdByClock
                 ? "none"
                 : ""
             }
            `}
              >
                <div className="x203912312">
                  <div
                    className={`${
                      showEditTimes || startContradictsTil || endContradictsTil
                        ? "none"
                        : "shiftEditTilTimeContainer"
                    }`}
                  >
                    <p
                      className={`${
                        state.tilApproved ||
                        (state.tilRequested && !state.tilDeclined)
                          ? "originalShiftTimesTilBar"
                          : "none"
                      }`}
                    >
                      Scheduled times{" "}
                      <span
                        className="takesAccTilSpan"
                        onClick={() => {
                          console.log(
                            { startContradictsTil, endContradictsTil },
                            { showEditTimes },
                            { startContradictsTil },
                            { endContradictsTil },
                            state.itemGhost,
                            { loading },

                            state.createdByClock
                          );
                        }}
                      >
                        (before TOIL applied)
                      </span>
                    </p>
                    <p
                      className={`${
                        state.tilApproved ||
                        (state.tilRequested && !state.tilDeclined)
                          ? "none"
                          : "originalShiftTimesTilBar"
                      } `}
                    >
                      {state.clockOn
                        ? `${
                            state.createdByClock
                              ? "Recorded times"
                              : "Scheduled times"
                          }`
                        : "Scheduled times"}
                    </p>
                    <p
                      className={`${
                        newTilStartError || newTilEndError
                          ? "tilStartClash"
                          : "margin5pxLeft"
                      } ${state.initial ? "doNotShowTimeStrip" : ""}`}
                      onClick={() => {
                        console.log(
                          dateStringer.createStringFromTimestamp(
                            state.editShiftStart
                          ),
                          dateStringer.createStringFromTimestamp(
                            state.editShiftEnd
                          )
                        );
                      }}
                    >
                      {getStartEndString(
                        state.editShiftStart,
                        state.editShiftEnd,
                        false
                      )}
                      {getStartEndString(
                        state.editShiftStart,
                        state.editShiftEnd,
                        true
                      ) ? (
                        <span className="plusDxx">
                          {getStartEndString(
                            state.editShiftStart,
                            state.editShiftEnd,
                            true
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div
                    className={`${
                      showEditTimes || startContradictsTil || endContradictsTil
                        ? "shiftEditTitle"
                        : "none"
                    }`}
                  >
                    <p
                      className={`${
                        state.tilApproved ||
                        (state.tilRequested && !state.tilDeclined)
                          ? "originalShiftTimesTilBar"
                          : "none"
                      }`}
                    >
                      Scheduled times{" "}
                      <span className="takesAccTilSpan">(without TOIL)</span>
                    </p>
                    <p
                      className={`${
                        state.tilApproved ||
                        (state.tilRequested && !state.tilDeclined)
                          ? "originalShiftTimesTilBar gapBetweenOrigAndEditTimes"
                          : "none"
                      }`}
                    >
                      &nbsp;
                    </p>
                    <p
                      className={`${
                        state.tilApproved ||
                        (state.tilRequested && !state.tilDeclined)
                          ? "none"
                          : "originalShiftTimesTilBar"
                      } ${state.itemIsNew ? "x12134" : ""}`}
                    >
                      Time (24hr format)
                    </p>
                    <div
                      className={`${
                        showEditTimes ||
                        startContradictsTil ||
                        endContradictsTil
                          ? `startEndShiftContainer ${
                              timePickerDrops ? "timePickerDropsInput" : ""
                            }`
                          : "none"
                      }`}
                    >
                      {timePickerDrops ? (
                        <select
                          className="timePickerDropper"
                          value={startPurgatory}
                          onChange={(e) => {
                            setStartPurgatory(e.target.value);
                            setStartPurgatorySubmit(
                              JSON.stringify(e.target.value)
                            );
                          }}
                        >
                          {generateTimeOptions}
                        </select>
                      ) : (
                        <input
                          className={`shiftTimeInput ${
                            startContradictsTil ? "contradictsTil" : ""
                          }`}
                          id="newTimeStartInput1"
                          value={startPurgatory}
                          type="time"
                          onChange={(e) => {
                            setStartPurgatory(e.target.value);
                            setStartPurgatorySubmit(
                              JSON.stringify(e.target.value)
                            );
                          }}
                        ></input>
                      )}
                      <p
                        className={`editShiftTimesUntilTxt ${
                          state.itemIsNew ? "x12134" : ""
                        }`}
                      >
                        until
                      </p>
                      {timePickerDrops ? (
                        <select
                          className="timePickerDropper"
                          value={endPurgatory}
                          onChange={(e) => {
                            setEndPurgatory(e.target.value);
                            setEndPurgatorySubmit(
                              JSON.stringify(e.target.value)
                            );
                          }}
                        >
                          {generateTimeOptions}
                        </select>
                      ) : (
                        <input
                          className={`shiftTimeInput ${
                            endContradictsTil ? "contradictsTil" : ""
                          } input2sh`}
                          id="newTimeEndInput1"
                          value={endPurgatory}
                          type="time"
                          onChange={(e) => {
                            setEndPurgatory(e.target.value);
                            setEndPurgatorySubmit(
                              JSON.stringify(e.target.value)
                            );
                          }}
                        ></input>
                      )}
                    </div>
                  </div>
                  {/* ogrady */}
                  <img
                    src={editGrey}
                    className={`${
                      showEditTimes ||
                      startContradictsTil ||
                      endContradictsTil ||
                      state.itemGhost ||
                      loading ||
                      state.createdByClock
                        ? "none"
                        : "editShiftPencil"
                    } ${state.partialAbsent ? "fadeEditSh" : ""}`}
                    onClick={() => {
                      if (state.partialAbsent) {
                        setShowPartialBlockModal(true);
                      } else {
                        if (!state.createdByClock) {
                          let startPre = JSON.stringify(
                            getStartEndString(
                              state.editShiftStart,
                              state.editShiftEnd
                            )
                          ).substr(1, 5);
                          let endPre = JSON.stringify(
                            getStartEndString(
                              state.editShiftStart,
                              state.editShiftEnd
                            )
                          ).substr(9, 5);
                          setStartPurgatory(startPre);
                          setStartPurgatorySubmit(startPre);
                          setEndPurgatory(endPre);
                          setEndPurgatorySubmit(endPre);
                          setEditTilTimes(false);
                          setShowEditBrk(false);
                          setShowDatepicker(false);

                          setShowEditTimes(!showEditTimes);
                        }
                      }
                    }}
                  />
                  {state.itemIsNew ? (
                    <div className="saveTimesLoadTemplateBtnAndSaveContainer">
                      <p
                        className="loadTimesTemplateBtn mgrCtaColours"
                        onClick={() => {
                          setShowShiftTemplateModal(true);

                          axios
                            .post(
                              `${serverURL}/get-shift-templates`,
                              { teamID: state.shiftTeamID },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (
                                response.status === 200 &&
                                response.data.message === "success"
                              ) {
                                response.data.array.sort(function (a, b) {
                                  return (
                                    parseInt(a.start.substr(0, 2)) -
                                    parseInt(b.start.substr(0, 2))
                                  );
                                });
                                setShiftTemplates(response.data.array);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Suggestions
                      </p>

                      <p
                        className={`${
                          (showEditTimes ||
                            startContradictsTil ||
                            endContradictsTil) &&
                          startPurgatory &&
                          endPurgatory
                            ? `saveNewShiftBtn ${
                                state.tilApproved ||
                                (state.tilRequested && !state.tilDeclined)
                                  ? "tilPushTickDown"
                                  : ""
                              }`
                            : "none"
                        } saveTimesNewItemBtn`}
                        onClick={() => {
                          let isNew = false;
                          if (state.itemIsNew) {
                            isNew = true;
                            setState({ published: true });
                          }
                          let contradictsTilFlag = false;
                          if (startPurgatory && endPurgatory) {
                            let startSub = startPurgatorySubmit;
                            let endSub = endPurgatorySubmit;

                            if (startPurgatorySubmit.length === 7) {
                              startSub = `${startPurgatorySubmit.substr(1, 5)}`;
                            }

                            if (endPurgatorySubmit.length === 7) {
                              endSub = `${endPurgatorySubmit.substr(1, 5)}`;
                            }
                            setChangesMade(true);

                            axios
                              .post(
                                `${serverURL}/edit-shift-times`,
                                {
                                  shiftID: state.editShiftShiftID,
                                  endTime: endSub,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                  startTime: startSub,
                                  type: `${
                                    state.itemType === "shift" ? "shift" : "til"
                                  }`,
                                  publishImmediately:
                                    state.itemIsNew && publishImmediately
                                      ? true
                                      : false,
                                  templateBrk: state.itemIsNew
                                    ? state.editShiftBrk
                                    : false,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                // setShiftCost(response.data.newCost);
                                if (response.data.contradictsTil) {
                                  memoVals.setIndicate((x) => {
                                    return {
                                      show: true,
                                      message: "Amend or remove TOIL first",
                                      colour: "red",
                                      duration: 4000,
                                    };
                                  });
                                  setLoading(false);
                                }

                                if (response.data.message === "success") {
                                  if (!isNew) {
                                    setShowNotify(true);
                                  }
                                  setChangesMade(true);

                                  setEndContradictsTil(false);
                                  setStartPurgatory("");
                                  setEndPurgatory("");
                                  setChangesMade(true);

                                  // let startD = new Date(
                                  //   dateStringer.createTimestampFromString(
                                  //     response.data.start
                                  //   )
                                  // );
                                  // let endD = new Date(
                                  //   dateStringer.createTimestampFromString(
                                  //     response.data.end
                                  //   )
                                  // );
                                  // if (endD.getTime() < startD.getTime()) {
                                  //   endD.setDate(endD.getDate() + 1);
                                  // }

                                  // setState({
                                  //   initial: response.data.initial,
                                  //   editShiftStart: startD.getTime(),
                                  //   editShiftEnd: endD.getTime(),
                                  //   shiftCost: response.data.newCost,
                                  //   partialAbsent: response.data.partialAbsent,
                                  //   editPartialStartLocal:
                                  //     response.data.partialStart,
                                  //   editPartialEndLocal: response.data.partialEnd,
                                  //   editDurationMins: response.data.newDurMins,
                                  //   partialAbsenceMins:
                                  //     response.data.partialAbsenceMins,
                                  // });

                                  getSingleShiftData(state.editShiftShiftID);
                                  // setLoading(false);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }

                          setTimeout(() => {
                            // setState({ itemIsNew: false });
                            setShowEditTimes(!showEditTimes);
                          }, 250);
                        }}
                      >
                        Save
                      </p>
                    </div>
                  ) : (
                    <p
                      className={`${
                        (showEditTimes ||
                          startContradictsTil ||
                          endContradictsTil) &&
                        startPurgatory &&
                        endPurgatory
                          ? `saveBtnTxt ${
                              state.tilApproved ||
                              (state.tilRequested && !state.tilDeclined)
                                ? "tilPushTickDown"
                                : ""
                            }`
                          : "none"
                      } pushSaveTimesBtnDown`}
                      onClick={() => {
                        let isNew = false;
                        if (state.itemIsNew) {
                          isNew = true;
                          setState({ published: true });
                        }
                        let contradictsTilFlag = false;
                        if (startPurgatory && endPurgatory) {
                          let startSub = startPurgatorySubmit;
                          let endSub = endPurgatorySubmit;

                          if (startPurgatorySubmit.length === 7) {
                            startSub = `${startPurgatorySubmit.substr(1, 5)}`;
                          }

                          if (endPurgatorySubmit.length === 7) {
                            endSub = `${endPurgatorySubmit.substr(1, 5)}`;
                          }
                          setChangesMade(true);

                          axios
                            .post(
                              `${serverURL}/edit-shift-times`,
                              {
                                shiftID: state.editShiftShiftID,
                                endTime: endSub,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),

                                startTime: startSub,
                                type: `${
                                  state.itemType === "shift" ? "shift" : "til"
                                }`,
                                publishImmediately:
                                  state.itemIsNew && publishImmediately
                                    ? true
                                    : false,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              // setShiftCost(response.data.newCost);
                              if (response.data.contradictsTil) {
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: "Amend or remove TOIL first",
                                    colour: "red",
                                    duration: 4000,
                                  };
                                });
                                setLoading(false);
                              }
                              if (response.data.message === "success") {
                                setChangesMade(true);
                                if (!isNew) {
                                  setShowNotify(true);
                                }
                                setEndContradictsTil(false);
                                setStartPurgatory("");
                                setEndPurgatory("");
                                setChangesMade(true);

                                // let startD = new Date(
                                //   dateStringer.createTimestampFromString(
                                //     response.data.start
                                //   )
                                // );
                                // let endD = new Date(
                                //   dateStringer.createTimestampFromString(
                                //     response.data.end
                                //   )
                                // );
                                // if (endD.getTime() < startD.getTime()) {
                                //   endD.setDate(endD.getDate() + 1);
                                // }

                                // setState({
                                //   initial: response.data.initial,
                                //   editShiftStart: startD.getTime(),
                                //   editShiftEnd: endD.getTime(),
                                //   shiftCost: response.data.newCost,
                                //   partialAbsent: response.data.partialAbsent,
                                //   editPartialStartLocal:
                                //     response.data.partialStart,
                                //   editPartialEndLocal: response.data.partialEnd,
                                //   editDurationMins: response.data.newDurMins,
                                //   partialAbsenceMins:
                                //     response.data.partialAbsenceMins,
                                // });

                                getSingleShiftData(state.editShiftShiftID);
                                // setLoading(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }

                        setTimeout(() => {
                          setState({ itemIsNew: false });
                          setShowEditTimes(!showEditTimes);
                        }, 250);
                      }}
                    >
                      Save
                    </p>
                  )}
                </div>
                {timePickerDrops ? (
                  <p
                    className="editTimeSpecificBtn"
                    onClick={() => {
                      setTimePickerDrops(false);
                    }}
                  >
                    Specific times{" "}
                    <img
                      src={editReq}
                      alt="Specific times mode"
                      className="specTimesModeImg"
                    />
                  </p>
                ) : (
                  ""
                )}{" "}
                {avail.active ? (
                  <div
                    className={`shActiveDiv ${
                      !timePickerDrops ? "x8283242312111" : ""
                    } ${avail.notAvailable ? "noBorder z882132" : ""}`}
                    onClick={() => {
                      memoVals.setShowMgrAvailModal((x) => {
                        return {
                          team: state.shiftTeamID,
                          ds: dateStringer.getMonWeekOfDs(
                            dateStringer.createStringFromTimestamp(
                              state.midnightTimestamp
                            )
                          ),
                          userID: state.itemUserID,
                        };
                      });
                    }}
                  >
                    {avail.notAvailable ? (
                      <p className="shActiveDivTxt makeBold usrUnavail">
                        {state.itemFName} is not available
                      </p>
                    ) : (
                      <p className="shActiveDivTxt makeBold">
                        {state.itemFName} is available:
                      </p>
                    )}

                    {!avail.notAvailable ? (
                      <p className="shActiveDivTxt z728731">
                        {avail.avail1start} - {avail.avail1end}
                      </p>
                    ) : (
                      ""
                    )}
                    {avail.avail2start && !avail.notAvailable ? (
                      <p className="shActiveDivTxt z728731">
                        & {avail.avail2start} - {avail.avail2end}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* end of row - - - - - - - - - - - - - - - - */}
              {/* new row...time row- - - - - - - - - - - - - - - - */}
              {state.selfLoggedAbsence ? (
                <div
                  className={`editShiftRow timesItemIsNewBg displayBlock x24859382`}
                >
                  <div className="x203912312">
                    <div className="shiftEditTitle">
                      <p className="originalShiftTimesTilBar makeBold x09897534">
                        {state.fName} reported absent for this{" "}
                        {state.itemType === "til"
                          ? "shift in lieu"
                          : state.itemType}
                      </p>
                      <p className="reportedAbs1">
                        {dateStringer.printedDateWithTimeFromDs(
                          state.selfLoggedAbsence
                        )}
                      </p>
                      {state.selfLoggedAbsenceReason ? (
                        <p className="reportedAbs2">
                          {state.selfLoggedAbsenceReason}
                        </p>
                      ) : (
                        ""
                      )}

                      {state.isOwn && !state.allowSelfApprove ? (
                        <p className="cantSelfAppAbs">
                          Not yet approved. Only another manager of{" "}
                          {state.showShiftModalTeamName} or a FlowRota admin can
                          accept this absence.
                        </p>
                      ) : (
                        <div className="selfLogAbsOptionsDiv">
                          <p
                            className="selfLogAbsOption"
                            onClick={() => {
                              axios
                                .post(
                                  `${serverURL}/respond-self-log-absence`,
                                  {
                                    shiftID: state.editShiftShiftID,
                                    paid: true,
                                    type:
                                      state.itemType === "shift"
                                        ? "shift"
                                        : "overtime",
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    getSingleShiftData(state.editShiftShiftID);
                                    setChangesMade(true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Mark{" "}
                            {state.itemType === "til"
                              ? "shift in lieu"
                              : state.itemType}{" "}
                            absent -{" "}
                            {state.itemType === "til"
                              ? "TOIL still accrued"
                              : "paid"}
                          </p>
                          <p
                            className="selfLogAbsOption"
                            onClick={() => {
                              axios
                                .post(
                                  `${serverURL}/respond-self-log-absence`,
                                  {
                                    shiftID: state.editShiftShiftID,
                                    unpaid: true,
                                    type:
                                      state.itemType === "shift"
                                        ? "shift"
                                        : "overtime",
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    getSingleShiftData(state.editShiftShiftID);
                                    setChangesMade(true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Mark{" "}
                            {state.itemType === "til"
                              ? "shift in lieu"
                              : state.itemType}{" "}
                            absent -{" "}
                            {state.itemType === "til"
                              ? "TOIL not accrued"
                              : "unpaid"}
                          </p>
                          <p
                            className="selfLogAbsOption x1998423"
                            onClick={() => {
                              axios
                                .post(
                                  `${serverURL}/respond-self-log-absence`,
                                  {
                                    shiftID: state.editShiftShiftID,
                                    disregard: true,
                                    type:
                                      state.itemType === "shift"
                                        ? "shift"
                                        : "overtime",
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setState({
                                      selfLoggedAbsence: "",
                                      selfLoggedAbsenceReason: "",
                                    });
                                    setChangesMade(true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Disregard
                          </p>
                        </div>
                      )}
                    </div>
                    {/* <img
                      src={editGrey}
                      className={`${
                        showEditTimes ||
                        startContradictsTil ||
                        endContradictsTil ||
                        state.itemGhost ||
                        loading ||
                        state.createdByClock
                          ? "none"
                          : "editShiftPencil"
                      }`}
                      onClick={() => {
                        if (!state.createdByClock) {
                          let startPre = JSON.stringify(
                            getStartEndString(
                              state.editShiftStart,
                              state.editShiftEnd
                            )
                          ).substr(1, 5);
                          let endPre = JSON.stringify(
                            getStartEndString(
                              state.editShiftStart,
                              state.editShiftEnd
                            )
                          ).substr(9, 5);
                          setStartPurgatory(startPre);
                          setStartPurgatorySubmit(startPre);
                          setEndPurgatory(endPre);
                          setEndPurgatorySubmit(endPre);
                          setEditTilTimes(false);
                          setShowEditBrk(false);
                          setShowDatepicker(false);

                          setShowEditTimes(!showEditTimes);
                        }
                      }}
                    /> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* end of row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  !changeRequests[0] || !outstandingChangeReqs
                    ? "none"
                    : `editShiftRow ${
                        outstandingChangeReqs
                          ? `lateActive ${
                              state.selfLoggedAbsence ? "x18381812" : ""
                            }`
                          : ""
                      }`
                }`}
              >
                <div className="makeOpenTxtDiv">
                  <p className="makeOpenTitle move5pxR">Change requests</p>
                  {/* <p className="makeOpenExplainTxt move5pxR">
                  {" "}
                
                </p> */}
                </div>
                <p
                  className={`viewChBtn ${
                    outstandingChangeReqs ? "pViewChBtn" : ""
                  }`}
                  onClick={() => {
                    // add TOIL here
                    setShowChangeReqs(true);
                  }}
                >
                  View
                  {outstandingChangeReqs ? (
                    <span className="outstandingChReqsSpan">
                      {outstandingChangeReqs}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              {/* end of row - - - - - - - - - - - - - - - - */}
              {/*  new row */}
              <div className="editShiftRow">
                <div className="shiftTypeLeftBox">
                  <div className="shiftEditTitleTxt">Date</div>

                  <div
                    className={`${
                      showDatepicker ? "none" : "shiftEditTitle shiftDateValue"
                    }`}
                    onClick={() => {
                      console.log(state.editShiftStart);
                    }}
                  >
                    {getFullDate(state.editShiftStart)}
                  </div>
                  <div
                    className={`${
                      showDatepicker ? "shiftDateEditDiv" : "none"
                    }`}
                  >
                    {/* <input className={`${showDatepicker ? "shiftBrkInput" : "none"}`} id="shiftBrkInput" defaultValue={editShiftBrk} type="number" onChange={(e) => {setBreakPurgatory(e.target.value)}}></input> mins break */}

                    <input
                      type="date"
                      className="addShiftDateInput editShiftDateInputBox"
                      id="addShiftDateInput"
                      defaultValue={editedShiftDate}
                      onChange={(e) => {
                        let ds = dateStringer.createStringFromDateInput(
                          e.target.value
                        );

                        if (e.target.value[0] === "2") {
                          if (
                            dateStringer.createTimestampFromString(ds) <
                            editUserStartDate
                          ) {
                            setDateIsBeforeUserStarted(true);
                          } else {
                            setDateIsBeforeUserStarted(false);
                            setEditedShiftDate(e.target.value);
                          }
                        }

                        if (
                          (e.target.value[0] === "1" &&
                            e.target.value[1] === "9") ||
                          dateStringer.createTimestampFromString(ds) <
                            editUserStartDate
                        ) {
                          setDateIsBeforeUserStarted(true);
                        } else {
                          setDateIsBeforeUserStarted(false);
                        }
                      }}
                    ></input>
                    <p
                      className={`${
                        dateIsBeforeUserStarted ||
                        userHasHalfDayOff ||
                        userHasFullDayOff
                          ? "editShiftInfoWarning"
                          : "none"
                      }`}
                    >
                      {`${
                        dateIsBeforeUserStarted
                          ? `Before ${state.fName} joined`
                          : `${
                              userHasHalfDayOff
                                ? `${state.fName} has half of this day booked off`
                                : `${state.fName} has this day booked off`
                            }`
                      }`}
                    </p>
                  </div>
                  {/* // title and  value here */}
                </div>

                <img
                  src={editGrey}
                  className={`${
                    showDatepicker || state.itemGhost
                      ? "none"
                      : "editShiftPencil"
                  } ${state.itemIsNew ? "voidRowIfNew" : ""}`}
                  onClick={() => {
                    if (!state.itemIsNew) {
                      setEditedShiftDate(
                        dateStringer.createDateInputFromDateString(
                          dateStringer.createStringFromTimestamp(
                            state.editShiftStart
                          )
                        )
                      );
                      setShowDatepicker(!showDatepicker);
                    }
                  }}
                />
                <p
                  // src={saveDayHours}
                  className={`${
                    showDatepicker &&
                    !dateIsBeforeUserStarted &&
                    editedShiftDate.length === 10
                      ? "saveBtnTxt saveBtnPushedDown"
                      : "none"
                  }`}
                  onClick={() => {
                    // fire request here
                    let d = editedShiftDate.substr(8, 2);
                    let m = parseInt(editedShiftDate.substr(5, 2)) - 1;
                    let y = editedShiftDate.substr(0, 4);
                    let dateObj = new Date(y, m, d, 0, 0, 0, 0);
                    let timestamp = dateObj.getTime();

                    // convert the editedShiftDate to a timestamp, then dateStringer to string it for axios
                    if (
                      dateStringer.createStringFromTimestamp(timestamp)
                        .length === 17
                    ) {
                      setChangesMade(true);

                      let newDateObj = new Date(timestamp);
                      setSingleDay2(newDateObj.getDay());

                      axios
                        .post(
                          `${serverURL}/edit-shift-midnight-timestamp`,
                          {
                            shiftID: state.editShiftShiftID,
                            data: dateStringer.createStringFromTimestamp(
                              timestamp
                            ),
                            type: `${
                              state.itemType === "shift" ? "shift" : "til"
                            }`,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.status === 200) {
                            // setChanged(true);
                            setShowNotify(true);

                            setChangedMidnightTimestamp((x) => {
                              return {
                                typeID: state.editShiftShiftID,
                                oldDayN: state.dayN,
                                newDayN: new Date(timestamp).getDay(),
                              };
                            });
                            console.log("changedMidnightTimestamp: ", {
                              typeID: state.editShiftShiftID,
                              oldDayN: state.dayN,
                              newDayN: new Date(timestamp).getDay(),
                            });

                            if (response.data.message === "already exists") {
                              setEditMidnightTsAlreadyExists(true);
                              setEditedShiftDate("");
                            } else {
                              // HELLO

                              let startD = new Date(
                                dateStringer.createTimestampFromString(
                                  response.data.start
                                )
                              );
                              let endD = new Date(
                                dateStringer.createTimestampFromString(
                                  response.data.end
                                )
                              );
                              if (endD.getTime() < startD.getTime()) {
                                endD.setDate(endD.getDate() + 1);
                              }
                              setState({
                                editShiftStart: startD.getTime(),
                                editShiftEnd: endD.getTime(),
                                shiftCost: response.data.newCost,
                                editShiftRate: response.data.rate,
                                payRoll: response.data.payRoll,
                              });

                              setShowDatepicker(!showDatepicker);
                              setEditedShiftDate("");
                              // setLoading(false);
                            }
                            getSingleShiftData(state.editShiftShiftID);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Save
                </p>
              </div>
              {/* new row...new time row only if til && !tilDeclined- - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.tilApproved || state.tilRequested
                    ? "editShiftRow"
                    : "none"
                }`}
              >
                <div className="shiftEditTilTimeContainer">
                  <p
                    className={`${
                      state.tilApproved ||
                      (state.tilRequested && !state.tilDeclined)
                        ? "originalShiftTimesTilBar"
                        : "none"
                    }`}
                  >
                    Work times{" "}
                    {state.tilRequested &&
                    !state.tilApproved &&
                    !state.tilDeclined ? (
                      <span className="takesAccTilSpan">
                        (if TOIL gets approved)
                      </span>
                    ) : (
                      <span className="takesAccTilSpan">
                        (takes into account TOIL)
                      </span>
                    )}
                  </p>
                  {/* goaty */}
                  <div
                    className={`${
                      state.tilMins >= state.editDurationMins
                        ? `tilSplitShiftContainer ${
                            dateStringer.generateTilShiftString(
                              state.tilStart,
                              state.tilEnd,
                              dateStringer.tsToHHMM(state.editShiftStart),
                              dateStringer.tsToHHMM(state.editShiftEnd),
                              false
                            ).length > 18 &&
                            !state.tilApproved &&
                            !state.tilDeclined
                              ? "x1938197411"
                              : ""
                          }`
                        : "none"
                    } x29482974231`}
                  >
                    All{" "}
                    {dateStringer.formatMinsDurationToHours(
                      state.editDurationMins
                    )}{" "}
                    {state.tilApproved ? "taken" : "requested"} as TOIL
                  </div>
                  <div
                    className={`${
                      state.tilMins < state.editDurationMins
                        ? "tilSplitShiftContainer"
                        : "none"
                    }`}
                  >
                    <div
                      className={`${
                        (state.tilApproved ||
                          state.tilRequested ||
                          state.tilDeclined) &&
                        dateStringer.generateTilShiftString(
                          state.tilStart,
                          state.tilEnd,
                          itemStart,
                          itemEnd,
                          false
                        ).length > 20
                          ? "tilABShiftBox"
                          : "none"
                      }`}
                      onClick={() => {
                        console.log(
                          dateStringer.generateTilShiftString(
                            state.tilStart,
                            state.tilEnd,
                            itemStart,
                            itemEnd,
                            false
                          ),
                          state.tilStart || 1,
                          state.tilEnd || 2,
                          itemStart || 3,
                          itemEnd || 4
                        );
                      }}
                    >
                      <p
                        className={`tilArow ${
                          state.tilApproved ? "approved12TilRow" : ""
                        }`}
                      >
                        1
                      </p>
                      <p
                        className={`tilBrow ${
                          state.tilApproved ? "approved12TilRow" : ""
                        }`}
                      >
                        2
                      </p>
                    </div>
                    <p
                      className={`myShiftTimesRow margin5pxLeft x191 ${
                        dateStringer.generateTilShiftString(
                          state.tilStart,
                          state.tilEnd,
                          dateStringer.tsToHHMM(state.editShiftStart),
                          dateStringer.tsToHHMM(state.editShiftEnd),
                          false
                        ).length > 18 && state.tilApproved
                          ? "x19198314"
                          : ""
                      } ${
                        state.tilRequested &&
                        !state.tilApproved &&
                        !state.tilDeclined
                          ? "fadeTilTimes"
                          : ""
                      }`}
                    >
                      {`${
                        state.tilRequested ||
                        state.tilApproved ||
                        state.tilDeclined
                          ? `${dateStringer.generateTilShiftString(
                              state.tilStart,
                              state.tilEnd,
                              dateStringer.tsToHHMM(state.editShiftStart),
                              dateStringer.tsToHHMM(state.editShiftEnd),
                              false
                            )}`
                          : `${itemStart} - ${itemEnd}`
                      }`}
                    </p>
                    <p
                      className={`${
                        state.tilApproved || state.tilDeclined
                          ? "blankTilSpaceRight"
                          : "none"
                      }`}
                    >
                      &nbsp;{" "}
                    </p>
                  </div>

                  {/* <div className='tilTimesMgr1and2Container'>
                <p className='tilTimesOnNewTimesStrip'>
                  {dateStringer.generateTilShiftString(
                    tilStart,
                    tilEnd,
                    dateStringer.tsToHHMM(editShiftStart),
                    dateStringer.tsToHHMM(editShiftEnd),
                    false,
                    "  "
                  )}
                </p>
              </div> */}
                </div>
              </div>
              {/* end of new times */}
              {/* new row...til (if til) row- - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.tilApproved || state.tilDeclined || state.tilRequested
                    ? "editShiftRow"
                    : "none"
                }`}
              >
                <div className="shiftEditTilTimeContainer">
                  <div className="tilTitleBar">
                    <p
                      className={`${
                        state.tilApproved ||
                        state.tilRequested ||
                        state.tilDeclined
                          ? "originalShiftTimesTilBar removeMarginB"
                          : "none"
                      }`}
                    >
                      TOIL{" "}
                      {state.tilRequested
                        ? mgrAppliedTil
                          ? ""
                          : "requested"
                        : "taken"}
                    </p>

                    <p
                      className={`${
                        state.tilApproved
                          ? "tilStatusTitleApproved manageTilStatusTitleApproved"
                          : "none"
                      }`}
                    >
                      {mgrAppliedTil
                        ? `Applied by ${dateStringer.initial(mgrAppliedTil)}`
                        : "Approved"}
                    </p>
                    <p
                      className={`${
                        state.tilDeclined ? "tilStatusTitleDeclined" : "none"
                      }`}
                    >
                      Declined
                    </p>
                    <p
                      className={`${
                        state.tilRequested && !mgrAppliedTil
                          ? "tilStatusTitlePending"
                          : "none"
                      }`}
                    >
                      Pending
                    </p>
                  </div>
                  <p className={`${editTilTimes ? "none" : "tilReqTimesRow"}`}>
                    <span
                      className={`${
                        startContradictsTil ? "tilStartClash" : ""
                      }`}
                    >
                      {state.tilStart}
                    </span>{" "}
                    -{" "}
                    <span
                      className={`${endContradictsTil ? "tilStartClash" : ""}`}
                    >
                      {state.tilEnd}
                    </span>{" "}
                    <span className="editShiftTilMins">
                      {dateStringer.formatMinsDurationToHours(state.tilMins)}
                    </span>
                  </p>
                  <div
                    className={`${
                      editTilTimes ? "editTilInputsContainer" : "none"
                    }`}
                  >
                    <input
                      className={`shiftTimeInput ${
                        newTilStartError ? "contradictsTil" : ""
                      }`}
                      id="startTimeInputy"
                      value={state.newTilStart}
                      type="time"
                      onChange={(e) => {
                        setState({ newTilStart: e.target.value });
                      }}
                    ></input>{" "}
                    <span className="editTilUntilSpan">until</span>{" "}
                    <input
                      className={`shiftTimeInput ${
                        newTilEndError ? "contradictsTil" : ""
                      }`}
                      id="endTimeInput"
                      value={state.newTilEnd}
                      type="time"
                      onChange={(e) => {
                        setState({ newTilEnd: e.target.value });
                      }}
                    ></input>{" "}
                  </div>
                </div>
                <div className="tilBtnsOnShiftDiv">
                  <img
                    src={editGrey}
                    className={`${
                      editTilTimes ? "none" : "editShiftPencil tilTakenEditImg"
                    }`}
                    onClick={() => {
                      setShowEditTimes(false);
                      setShowEditBrk(false);
                      setShowTilTrimModal(true);

                      // setEditTilTimes(!editTilTimes);
                    }}
                  />

                  <p
                    // src={saveDayHours}
                    className={`${
                      editTilTimes ? "saveBtnTxt tilPushTickDown" : "none"
                    }`}
                    onClick={() => {
                      setChangesMade(true);
                      setSureBinTil(false);

                      // save new til times
                      axios
                        .post(
                          `${serverURL}/change-til-times`,
                          {
                            shiftID: state.editShiftShiftID,
                            tilStart: state.newTilStart,
                            tilEnd: state.newTilEnd,
                            type:
                              state.itemType === "shift" ? "shift" : "overtime",
                            whole: checkNewTilIsWhole(
                              state.editShiftBrk,
                              state.newTilStart,
                              state.newTilEnd,
                              state.editShiftStart,
                              state.editShiftEnd
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (
                            response.data.message === "success" ||
                            response.data.message === "error"
                          ) {
                            if (
                              response.data.startError ||
                              response.data.endError
                            ) {
                              if (response.data.startError) {
                                setNewTilStartError(true);
                              } else {
                                setNewTilStartError(false);
                              }
                              if (response.data.endError) {
                                setNewTilEndError(true);
                              } else {
                                setNewTilEndError(false);
                              }
                              setTilError(true);
                            }
                            if (response.data.endBeforeStartError) {
                              setNewTilStartError(false);
                              setNewTilEndError(false);
                            }
                            if (
                              !response.data.endBeforeStartError &&
                              !response.data.startError &&
                              !response.data.endError
                            ) {
                              setEditTilTimes(false);

                              setItemStart(response.data.itemStart);
                              setItemEnd(response.data.itemEnd);

                              setState({
                                tilStart: response.data.tilStart,
                                tilEnd: response.data.tilEnd,
                                tilMins: response.data.tilMins,
                              });

                              setChangesMade(!changesMade);

                              setNewTilStartError(false);
                              setNewTilEndError(false);
                              getSingleShiftData(state.editShiftShiftID);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Save
                  </p>
                  {startContradictsTil || endContradictsTil ? (
                    <div className="addAbsenceModalUnderlay">
                      <div className="formCreatedModal">
                        <p className="overlapsRenTxt">
                          {/* {JANET} */}
                          Please ensure that
                          {startContradictsTil
                            ? " the scheduled start time is on or before the TOIL start time"
                            : ""}
                          {startContradictsTil && endContradictsTil
                            ? ", and that"
                            : ""}
                          {endContradictsTil
                            ? " the scheduled end time is on or after the TOIL end time"
                            : ""}
                          .
                          <br />
                          <br />
                          You may wish to amend or remove the TOIL first.
                        </p>
                        <div className="areYouModalBtnsDiv">
                          <p
                            className="closeSwapsNoLongerBtn"
                            onClick={() => {
                              setStartContradictsTil(false);
                              setEndContradictsTil(false);
                              setShowEditTimes(false);
                              setLoading(false);
                            }}
                          >
                            Understood
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {tilError ? (
                    <div className="addAbsenceModalUnderlay">
                      <div className="formCreatedModal">
                        <p className="overlapsRenTxt">
                          {/* {JANET} */}
                          Please ensure the TOIL{" "}
                          {newTilStartError ? "start" : ""}{" "}
                          {newTilStartError && newTilEndError ? "and" : ""}{" "}
                          {newTilEndError ? "end" : ""} time
                          {newTilStartError && newTilEndError
                            ? "s are"
                            : " is"}{" "}
                          within the scheduled start and end times.
                        </p>
                        <div className="areYouModalBtnsDiv">
                          <p
                            className="closeSwapsNoLongerBtn"
                            onClick={() => {
                              setTilError(false);
                            }}
                          >
                            Understood
                          </p>
                          {/* <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setNotEnoughModal(false);
                    }}
                  >
                    Cancel
                  </p> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* CHICKEN */}
                  {sureBinTil ? (
                    <div
                      className="areYouSureModalUnderlay"
                      onClick={() => {
                        setSureBinTil(false);
                      }}
                    >
                      <div
                        className="formCreatedModal"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <p className="overlapsRenTxt">
                          Are you sure you want to cancel the TOIL used on this{" "}
                          {state.itemType === "shift"
                            ? "shift"
                            : state.itemType === "til"
                            ? "shift in lieu"
                            : "overtime"}
                          ?
                        </p>
                        <div className="areYouModalBtnsDiv">
                          <p
                            className="areYouSureModalYesBtn"
                            onClick={() => {
                              setSureBinTil(false);

                              axios
                                .post(
                                  `${serverURL}/remove-til-request`,
                                  {
                                    shiftID: state.editShiftShiftID,
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                    type:
                                      state.itemType === "shift"
                                        ? "shift"
                                        : "overtime",
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setShowTilTrimModal(false);
                                    // setTimeout(() => {
                                    getSingleShiftData(state.editShiftShiftID);

                                    // }, 250);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Yes
                          </p>
                          <p
                            className="areYouModalNoBtn"
                            onClick={() => {
                              setSureBinTil(false);
                            }}
                          >
                            No
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* end of til row */}

              {/* new row - - - - - - - - - - - - - - - - */}
              {!state.createdByClock ? (
                <div className="editShiftRow">
                  <div className="shiftTypeLeftBox">
                    <div className="shiftEditTitleTxt">
                      {state.itemType === "til"
                        ? "Break (excluded from TOIL)"
                        : "Unpaid break"}
                    </div>
                    <div
                      className={`${
                        showEditBrk
                          ? "shiftEditTitle shiftEditBrkInputMove"
                          : "none"
                      }`}
                    >
                      <input
                        className={`${showEditBrk ? "shiftBrkInput" : "none"}`}
                        id="shiftBrkInput"
                        value={breakPurgatory}
                        type="number"
                        onChange={(e) => {
                          setBreakPurgatory(e.target.value);
                        }}
                      ></input>{" "}
                      mins
                    </div>

                    <div
                      className={`${
                        showEditBrk ? "none" : "shiftEditTitle editBreakMove"
                      }`}
                    >
                      {state.editShiftBrk} mins
                    </div>
                  </div>

                  <img
                    src={editGrey}
                    className={`${
                      showEditBrk || state.itemGhost
                        ? "none"
                        : "editShiftPencil"
                    } ${state.itemIsNew ? "voidRowIfNew" : ""} ${
                      state.partialAbsent ? "fadeEditSh" : ""
                    }`}
                    onClick={() => {
                      if (state.partialAbsent) {
                        setShowPartialBlockModal(true);
                      } else {
                        if (!state.itemIsNew) {
                          setBreakPurgatory(state.editShiftBrk);
                          setShowEditBrk(!showEditBrk);

                          setShowDatepicker(false);
                          setShowEditTimes(false);
                          setEditTilTimes(false);
                        }
                      }
                    }}
                  />
                  <p
                    // src={saveDayHours}
                    className={`${
                      showEditBrk ? "saveBtnTxt pushSaveBrkBtnDown" : "none"
                    }`}
                    onClick={() => {
                      if (
                        // editDurationMins > breakPurgatory &&
                        breakPurgatory >= 0
                      ) {
                        setChangesMade(true);

                        axios
                          .post(
                            `${serverURL}/edit-shift-break`,
                            {
                              shiftID: state.editShiftShiftID,
                              data: breakPurgatory,
                              type:
                                state.itemType === "shift" ? "shift" : "til",
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.status === 200) {
                              setShowNotify(true);
                              setState({
                                shiftCost: response.data.newCost,
                                editShiftBrk: response.data.newBrkMins,
                                editDurationMins: response.data.newDurMins,
                                tilMins: response.data.newTilMins,
                                unpaidSalary: response.data.unpaidSalary,
                                payRoll: response.data.payRoll,
                              });
                              getSingleShiftData(state.editShiftShiftID);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                      setShowEditBrk(!showEditBrk);
                    }}
                  >
                    Save
                  </p>
                </div>
              ) : (
                ""
              )}
              {/* new row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemIsNew ||
                  state.itemType === "til" ||
                  state.tilMins ||
                  state.itemGhost ||
                  state.clockOnApproved ||
                  state.clockOff
                    ? "none"
                    : "editShiftRow"
                }`}
              >
                <div className="makeOpenTxtDiv">
                  <p className="makeOpenTitle move5pxR">TOIL</p>
                  <p className="makeOpenExplainTxt move5pxR">
                    {" "}
                    Shorten this {state.itemType} with {state.itemFName}
                    {state.itemFName[state.itemFName.length - 1] === "s"
                      ? "'"
                      : "'s"}{" "}
                    accrued TOIL balance
                  </p>
                </div>
                <p
                  className="makeOpenBtn"
                  onClick={() => {
                    // add TOIL here
                    setShowTilTrimModal(true);
                  }}
                >
                  Add TOIL
                </p>
              </div>
              {renderTilTrimModal()}
              {/* end of row - - - - - - - - - - - - - - - - */}
              {/* manchester */}
              {!state.itemIsNew && !state.itemGhost && showLate ? (
                <div className="editShiftRow lateActive">
                  <div className="shiftTypeLeftBox">
                    <div
                      className={`
                     ${
                       state.lateStartDs || toggleEditLate
                         ? `shiftEditTitleTxt ${
                             state.lateStartDs ? "lateActiveTitleRed" : ""
                           }`
                         : "lateTitleWhenNotEnabled"
                     } ${
                        !state.lateStartDs && suggestLate
                          ? "clockOnLateTxt"
                          : ""
                      }`}
                    >
                      {!state.lateStartDs
                        ? suggestLate
                          ? `Clocked on ${dateStringer.formatMinsDurationToHours(
                              suggestLate
                            )} late`
                          : "Add lateness"
                        : "Late"}
                    </div>
                    <div
                      className={`${
                        toggleEditLate
                          ? "shiftEditTitle shiftEditBrkInputMove"
                          : "none"
                      }`}
                    >
                      <input
                        className={`${toggleEditLate ? "lateInput" : "none"}`}
                        id="shiftBrkInput"
                        value={lateTimeValue}
                        type="time"
                        onChange={(e) => {
                          setLateTimeValue(e.target.value);
                        }}
                      ></input>{" "}
                      <span className="lateMinsSpan">
                        {dateStringer.formatMinsDurationToHours(lateMinsValue)}{" "}
                        late
                      </span>
                    </div>

                    <div
                      className={`${
                        !state.lateStartDs || toggleEditLate
                          ? "none"
                          : "shiftEditTitle editBreakMove"
                      }`}
                    >
                      {dateStringer.formatMinsDurationToHours(
                        state.lateDurMins
                      )}{" "}
                      late{" "}
                      {!state.payClocked ? (
                        state.itemType === "til" ? (
                          <span className="paidLateSpan">
                            ({state.unpaidLate ? "excluded" : "included"})
                          </span>
                        ) : (
                          <span className="paidLateSpan">
                            ({state.unpaidLate ? "unpaid" : "paid"})
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {suggestLate ? (
                    <p
                      className="applyLateBtn"
                      onClick={() => {
                        setToggleEditLate(true);
                        let initialDateObj = new Date(state.editShiftStart);
                        initialDateObj.setMinutes(
                          initialDateObj.getMinutes() + parseInt(suggestLate)
                        );
                        // Y 2 0 2 2 M 0 0 D 0 1   H   0   0   M   0   0
                        // 0 1 2 3 4 5 6 7 8 9 10 11   12  13  14  15  16
                        // citra
                        setLateTimeValue(
                          dateStringer.tsToHHMM(initialDateObj.getTime())
                        );
                        setSuggestLate(0);
                      }}
                    >
                      Record
                    </p>
                  ) : !toggleEditLate && !suggestLate ? (
                    <img
                      src={!state.lateStartDs ? greyAdd : editGrey}
                      className={`editShiftPencil ${
                        !state.lateStartDs ? "addLateIconPlus" : ""
                      }`}
                      onClick={() => {
                        if (!state.lateStartDs) {
                          let newDate = new Date(state.editShiftStart);

                          setLateTimeValue(
                            dateStringer.tsToHHMM(newDate.getTime())
                          );
                        } else {
                          console.log(
                            "start late: ",
                            state.lateStartDs,

                            dateStringer.tsToHHMM(
                              dateStringer.createTimestampFromString(
                                state.lateStartDs
                              )
                            )
                          );
                          setUnpaidLateValue(state.unpaidLate);
                          setLateTimeValue(
                            dateStringer.tsToHHMM(
                              dateStringer.createTimestampFromString(
                                state.lateStartDs
                              )
                            )
                          );
                        }
                        setToggleEditLate(true);
                      }}
                    />
                  ) : (
                    <div className={`saveLateAndUnpaidDiv `}>
                      <div className="unpaidSaveCancelDiv">
                        <p
                          className={`${
                            toggleEditLate ? "saveBtnTxtLate" : "none"
                          } ${lateMinsValue < 1 ? "invis" : ""}`}
                          onClick={() => {
                            setToggleEditLate(false);

                            axios
                              .post(
                                `${serverURL}/make-late`,
                                {
                                  shiftID: state.editShiftShiftID,
                                  type: `${
                                    state.itemType === "shift" ? "shift" : "til"
                                  }`,
                                  lateStartDs: lateDsValue,
                                  lateDurMins: lateMinsValue,
                                  unpaid: unpaidLateValue,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setChangesMade(true);
                                  setShowNotify(true);

                                  setState({
                                    lateDurMins: response.data.lateDurMins,
                                    lateStartDs: response.data.lateStartDs,
                                    unpaidLate: response.data.unpaidLate,
                                    shiftCost: response.data.newCost,
                                    editDurationMins: response.data.newDurMins,
                                    unpaidSalary: response.data.unpaidSalary,
                                    payRoll: response.data.payRoll,
                                    payClocked: response.data.payClocked,
                                    editShiftAbsent: false,
                                    partialAbsent: false,
                                    unpaidAbsent: false,
                                  });
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Save
                        </p>{" "}
                        <img
                          src={!state.lateStartDs ? greyRemove : greyCancel}
                          className={`canxLateBtnImg ${
                            !state.lateStartDs ? "removeLateImg" : ""
                          }`}
                          alt="Edit"
                          onClick={() => {
                            setSuggestLate(suggestLateOriginal);

                            if (state.lateStartDs) {
                              // delete the lateness
                              setToggleEditLate(false);

                              axios
                                .post(
                                  `${serverURL}/make-late`,
                                  {
                                    shiftID: state.editShiftShiftID,
                                    type: `${
                                      state.itemType === "shift"
                                        ? "shift"
                                        : "til"
                                    }`,
                                    lateStartDs: lateDsValue,
                                    lateDurMins: lateMinsValue,
                                    unpaid: unpaidLateValue,
                                    removeLate: true,
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setChangesMade(true);
                                    setShowNotify(true);

                                    setState({
                                      lateDurMins: response.data.lateDurMins,
                                      lateStartDs: response.data.lateStartDs,
                                      unpaidLate: response.data.unpaidLate,
                                      shiftCost: response.data.newCost,
                                      editDurationMins:
                                        response.data.newDurMins,
                                      unpaidSalary: response.data.unpaidSalary,
                                      payRoll: response.data.payRoll,
                                      payClocked: response.data.payClocked,
                                      editShiftAbsent: false,
                                      partialAbsent: false,
                                      unpaidAbsent: false,
                                    });
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            } else {
                              setToggleEditLate(false);
                            }
                          }}
                        />
                      </div>

                      <div
                        className={`unpaidlateBtnDiv ${
                          unpaidLateValue ? "" : "unpaidLateOpacity"
                        }`}
                        onClick={() => {
                          setUnpaidLateValue(!unpaidLateValue);
                        }}
                      >
                        {" "}
                        <img
                          src={unpaidLateValue ? checkbox2purp : checkbox1}
                          className="unpaidLateCheckBoxImg"
                          alt="Publish"
                        />
                        {state.itemType === "til" ? "Exclude" : "Unpaid"}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {/* new row...time row- - - - - - - - - - - - - - - - */}
              <div className={`editShiftRow ${!state.clockOn ? "none" : ""}`}>
                <div className="shiftEditTilTimeContainer">
                  <p className="originalShiftTimesTilBar">Clocked times</p>
                  <span className="margin5pxLeft">
                    {dateStringer.dsToTimeStrip(state.clockOn, true)} -{" "}
                    {dateStringer.dsToTimeStrip(state.clockOff, true)}
                  </span>

                  {!state.clockOnApproved &&
                  !state.clockOnDeclined &&
                  !state.clockOff ? (
                    ""
                  ) : (
                    <span className="managerShiftClockBreakSpan">
                      {state.clockBrkMins}m clocked unpaid break
                    </span>
                  )}

                  {!state.clockOnApproved &&
                  !state.clockOnDeclined &&
                  !state.clockOff ? (
                    ""
                  ) : (
                    <span className="managerShiftClockBreakSpan clDurSpan">
                      {dateStringer.formatMinsDurationToHours(
                        state.clockedDurMins
                      )}{" "}
                      clocked duration
                    </span>
                  )}
                </div>

                <div className="clockCardBtnAndApproveStatus">
                  <p
                    className={`${
                      !state.clockOnApproved && !state.clockOnDeclined
                        ? "clockAppStatusNotYetApproved"
                        : "none"
                    }`}
                  >
                    {`${
                      state.clockOff ? "Not yet approved" : "Clocked on now"
                    }`}
                  </p>
                  <p
                    className={`${
                      state.clockOnApproved && !state.clockOnDeclined
                        ? "clockAppStatusApproved"
                        : "none"
                    }`}
                  >
                    Approved
                  </p>
                  <p
                    className={`${
                      !state.clockOnApproved && state.clockOnDeclined
                        ? "clockAppStatusDeclined"
                        : "none"
                    }`}
                  >
                    Declined
                  </p>
                  {!state.clockCardValid && (
                    <p className="invalidClockCardBtn">
                      Clock card no longer exists
                    </p>
                  )}
                  {state.clockCardValid && state.clockOff && (
                    <p
                      className="viewClockItemBtn"
                      onClick={() => {
                        if (state.clockCardValid) {
                          console.log("open manager clock card");
                          memoVals.setClockOnID((x) => {
                            return {
                              clockOnID: state.clockOnIDLocal,
                              isMgr: true,
                              fromShiftManager: true,
                            };
                          });
                        } else {
                        }
                      }}
                    >
                      Clock card
                    </p>
                  )}
                </div>
              </div>

              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost ||
                  state.hideClockOnRequired ||
                  state.createdByClock
                    ? "none"
                    : `editShiftRow ${
                        !memoVals.mobModal ? "clickShiftRow" : ""
                      }`
                }`}
                onClick={() => {
                  setState({ requireClockOn: !state.requireClockOn });
                  axios
                    .post(
                      `${serverURL}/toggle-clock-on-required-on-shift-or-til`,
                      {
                        shiftID:
                          state.itemType === "shift"
                            ? state.editShiftShiftID
                            : "",
                        tilID:
                          state.itemType !== "shift"
                            ? state.editShiftShiftID
                            : "",

                        data: !state.requireClockOn,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setState({ requireClockOn: response.data.data });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                  // }
                }}
              >
                <p className="shiftEditTitle move5pxR">Clock on required</p>
                <div
                  className={`${
                    state.itemGhost
                      ? "none"
                      : "editUserReqClockOutKnobContainer"
                  }`}
                >
                  <div className="knobContainer knobContainerEditUser knobEditShift">
                    <div
                      className={`switchKnob ${
                        state.requireClockOn
                          ? "knobOn knobOnMgrColour"
                          : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}{" "}
              </div>
              {/* end of row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost ||
                  !state.clockCardValid ||
                  state.createdByClock
                    ? // ||
                      // clockedTimesSameAsScheduled()
                      "none"
                    : "editShiftRow clickShiftRow"
                }`}
                onClick={() => {
                  setState({ payClocked: !state.payClocked });
                  axios
                    .post(
                      `${serverURL}/toggle-pay-clocked-on-shift`,
                      {
                        shiftID:
                          state.itemType === "shift"
                            ? state.editShiftShiftID
                            : "",
                        tilID:
                          state.itemType !== "shift"
                            ? state.editShiftShiftID
                            : "",
                        clockOnID: state.clockOnIDLocal,
                        data: !state.payClocked,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setState({ payClocked: response.data.payClocked });
                        getSingleShiftData(state.editShiftShiftID);
                        setPayClockedNotApprovedYet(
                          response.data.payClockedNotApprovedYet
                        );

                        setChangesMade(true);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                  // }
                }}
              >
                <p className="shiftEditTitle move5pxR">Pay clocked time only</p>
                <div
                  className={`${
                    state.itemGhost
                      ? "none"
                      : "editUserReqClockOutKnobContainer"
                  }`}
                >
                  <div className="knobContainer knobContainerEditUser knobEditShift">
                    <div
                      className={`switchKnob ${
                        state.payClocked ? "knobOn knobOnMgrColour" : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}{" "}
              </div>
              {/* end of row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost || state.itemType === "til"
                    ? "none"
                    : `${
                        state.partialAbsent
                          ? "editShiftRow clickShiftRow cursorPointer  partialBottomBorderAdjust"
                          : "editShiftRow clickShiftRow cursorPointer"
                      }`
                } ${jamAbs ? "jamAbs" : ""}  ${state.itemIsNew ? "none" : ""} ${
                  state.editShiftAbsent ? "absentRowActive" : ""
                } ${makeAbsent ? "makeAbsRowFlash" : ""}`}
                ref={absRef}
                onClick={() => {
                  setEditPartialTimes1(false);
                  setEditPartialTimes2(false);
                  setChangesMade(true);
                  setState({ editShiftAbsent: !state.editShiftAbsent });
                  axios
                    .post(
                      `${serverURL}/edit-shift-absent`,
                      {
                        shiftID: state.editShiftShiftID,
                        data: !state.editShiftAbsent,
                        type: `${state.itemType === "shift" ? "shift" : "til"}`,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setShowNotify(true);

                        setState({
                          editDurationMins: response.data.durMins,
                          editShiftAbsent: !state.editShiftAbsent,
                          shiftCost: response.data.cost,
                          unpaidAbsent: response.data.unpaidAbsent,
                          partialAbsent: response.data.partialAbsent,
                          unpaidSalary: response.data.unpaidSalary,
                          payRoll: response.data.payRoll,
                          lateDurMins: 0,
                          lateStartDs: "",
                          unpaidLate: false,
                        });
                        getSingleShiftData(state.editShiftShiftID);

                        // setLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <p
                  className={`shiftEditTitle move5pxR ${
                    state.editShiftAbsent ? "colourWhite" : ""
                  }`}
                >
                  Absent
                </p>
                <div className="absenceValueContainerIncPartialBtn">
                  {state.itemType !== "til" ? (
                    <div
                      onClick={(e) => {
                        // setEditShiftAbsent(!editShiftAbsent);
                        e.stopPropagation();

                        if (state.editShiftAbsent) {
                          let oneHour = 1000 * 60 * 60;

                          setState({
                            partialAbsent: false,
                            editPartialStartLocal: dateStringer.tsToHHMM(
                              state.editShiftStart
                            ),
                            editPartialEndLocal: dateStringer.tsToHHMM(
                              state.editShiftStart + oneHour
                            ),
                          });

                          // request to make partial absent
                          setChangesMade(true);
                          setJamAbs(true);
                          axios
                            .post(
                              `${serverURL}/toggle-shift-partial-absent`,
                              {
                                shiftID: state.editShiftShiftID,
                                data: !state.partialAbsent,
                                type: `${
                                  state.itemType === "shift" ? "shift" : "til"
                                }`,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.status === 200) {
                                setSingleDay(state.dayN);
                                setShowNotify(true);
                                setReload(!reload);
                                // setTimeout(() => {
                                //   setUpdate2(!update2);
                                // }, 300);
                                // setPartialAbsent(response.data.partialAbsent);
                                // setEditPartialStartLocal(
                                //   response.data.partialStart
                                // );
                                // setEditPartialEndLocal(response.data.partialEnd);
                                // setShiftCost(response.data.cost);
                                // setEditDurationMins(response.data.durMins);
                                // setPartialAbsenceMins(
                                //   response.data.partialAbsenceMins
                                // );
                                // setState({
                                //   partialAbsent: response.data.partialAbsent,
                                //   shiftCost: response.data.cost,
                                //   editDurationMins: response.data.durMins,
                                //   partialAbsenceMins:
                                //     response.data.partialAbsenceMins,
                                //   unpaidSalary: response.data.unpaidSalary,
                                //   payRoll: response.data.payRoll,
                                // });
                                setLoading(false);
                                setJamAbs(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        } else {
                          setChangesMade(true);
                          setJamAbs(true);
                          axios
                            .post(
                              `${serverURL}/edit-shift-absent`,
                              {
                                shiftID: state.editShiftShiftID,
                                data: !state.editShiftAbsent,
                                type: `${
                                  state.itemType === "shift" ? "shift" : "til"
                                }`,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setState({
                                  editDurationMins: response.data.durMins,
                                  editShiftAbsent: !state.editShiftAbsent,
                                  shiftCost: response.data.cost,
                                  unpaidAbsent: response.data.unpaidAbsent,
                                  partialAbsent: response.data.partialAbsent,
                                  payRoll: response.data.payRoll,
                                  lateDurMins: 0,
                                  lateStartDs: "",
                                  unpaidLate: false,
                                });
                                getSingleShiftData(state.editShiftShiftID);
                                setShowNotify(true);

                                setLoading(false);
                                setJamAbs(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                      className={`${
                        state.editShiftAbsent
                          ? `${
                              state.partialAbsent
                                ? "partialAbsenceBtnContainerActive partialHover"
                                : "partialAbsenceBtnContainer partialHover"
                            }`
                          : "partialAbsenceBtnContainer partialFaded"
                      } ${loading ? "partialFaded" : ""}`}
                    >
                      <p className="partialAbsenceBtnTxt">Partial absence</p>
                      <p
                        onClick={() => {
                          setState({ partialAbsent: !state.partialAbsent });
                          if (state.partialAbsent) {
                            setState({
                              editPartialTimes1: false,
                              editPartialTimes2: false,
                            });

                            setChangesMade(true);

                            axios
                              .post(
                                `${serverURL}/toggle-shift-partial-absent`,
                                {
                                  shiftID: state.editShiftShiftID,
                                  data: !state.partialAbsent,
                                  type: `${
                                    state.itemType === "shift" ? "shift" : "til"
                                  }`,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setSingleDay(state.dayN);
                                  setShowNotify(true);
                                  setReload(!reload);

                                  // setState({
                                  //   partialAbsent: response.data.partialAbsent,
                                  //   shiftCost: response.data.cost,
                                  //   editDurationMins: response.data.durMins,
                                  //   partialAbsenceMins:
                                  //     response.data.partialAbsenceMins,
                                  //   unpaidSalary: response.data.unpaidSalary,
                                  //   payRoll: response.data.payRoll,
                                  // });

                                  setLoading(false);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                        className={`${
                          state.partialAbsent
                            ? "partialAbsencePlusBtnActive"
                            : "partialAbsencePlusBtn"
                        }`}
                      >
                        +
                      </p>
                    </div>
                  ) : (
                    <p></p>
                  )}

                  {/*  KNOB BEGIN */}
                  <div
                    className={`${
                      state.itemGhost
                        ? "none"
                        : "editUserReqClockOutKnobContainer"
                    }`}
                  >
                    <div className="knobContainer knobContainerEditUser knobEditShift">
                      <div
                        className={`switchKnob ${
                          state.editShiftAbsent
                            ? "knobOn knobOnMgrColour knobRed"
                            : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}

              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost ||
                  !state.partialAbsent ||
                  !state.editShiftAbsent ||
                  state.itemType === "til"
                    ? "none"
                    : "editShiftRow absenceRowContainer"
                } ${state.itemIsNew ? "none" : ""}`}
                onClick={() => {}}
              >
                <div className="partialAbsenceRow">
                  <div className="absenceRowUnit1">
                    <p className="shiftEditTitle partialTitle">Absence start</p>
                  </div>
                  <div
                    className={`units2and3 ${
                      editPartialTimes1 ? "" : "units2and3Hover"
                    }`}
                    onClick={() => {
                      setEditPartialTimes1(true);
                    }}
                  >
                    <div className="absenceRowUnit2">
                      <input
                        className={`${
                          editPartialTimes1
                            ? `partialTimeEditMode  ${
                                dateStringer.HHMMToMsToday(
                                  state.editPartialStartLocal
                                ) >= state.minPartialStart &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialStartLocal
                                ) <= state.maxPartialStart &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialStartLocal
                                ) <
                                  dateStringer.HHMMToMsToday(
                                    state.editPartialEndLocal
                                  )
                                  ? // validatePartialTimes(editPartialStartLocal, "")
                                    ""
                                  : `${
                                      state.beyond24
                                        ? ""
                                        : "invalidPartialInput"
                                    }`
                              }`
                            : "none"
                        }`}
                        type="time"
                        value={state.editPartialStartLocal}
                        onChange={(e) => {
                          setState({ editPartialStartLocal: e.target.value });
                          // setEditPartialStartLocal(
                          //   e.target.value
                          //   // formatEditPartials(e.target.value, "")
                          // );
                        }}
                      />
                      <p
                        className={`${
                          editPartialTimes1 ? "none" : "partialTimeValue"
                        }`}
                      >
                        {state.editPartialStartLocal || "--:--"}
                      </p>
                    </div>
                    <div className="absenceRowUnit3">
                      <img
                        src={editGrey}
                        className={`${
                          editPartialTimes1 ? "none" : "editPartialTimePencil"
                        }`}
                        alt="Edit"
                      />
                      <p
                        // src={saveDayHours}
                        onClick={() => {
                          if (state.editPartialStartLocal.length === 5) {
                            setJamAbs(true);
                            axios
                              .post(
                                `${serverURL}/edit-shift-partial-absent-start-end-time`,
                                {
                                  shiftID: state.editShiftShiftID,
                                  start: state.editPartialStartLocal,
                                  end: state.editPartialEndLocal,

                                  type: `${
                                    state.itemType === "shift" ? "shift" : "til"
                                  }`,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  // setTimeout(() => {
                                  //   setUpdateShifts(!updateShifts);
                                  // }, 650);
                                  setShowNotify(true);
                                  setReload(!reload);

                                  // setState({
                                  //   partialAbsent: response.data.partialAbsent,
                                  //   editPartialStartLocal:
                                  //     response.data.partialStart,
                                  //   editPartialEndLocal:
                                  //     response.data.partialEnd,
                                  //   shiftCost: response.data.newCost,
                                  //   editDurationMins: response.data.durMins,
                                  //   partialAbsenceMins:
                                  //     response.data.partialAbsenceMins,
                                  //   unpaidSalary: response.data.unpaidSalary,
                                  //   payRoll: response.data.payRoll,
                                  // });
                                  // setPartialAbsent(response.data.partialAbsent);
                                  // setEditPartialStartLocal(
                                  //   response.data.partialStart
                                  // );
                                  // setEditPartialEndLocal(response.data.partialEnd);
                                  // setShiftCost(response.data.newCost);
                                  // setEditDurationMins(response.data.durMins);
                                  // setPartialAbsenceMins(
                                  //   response.data.partialAbsenceMins
                                  // );
                                  setChangesMade(true);
                                  setEditPartialTimes1(false);
                                  // setLoading(false);
                                  setJamAbs(false);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                        className={`${
                          editPartialTimes1
                            ? `saveBtnTxt savePartialBtn ${
                                dateStringer.HHMMToMsToday(
                                  state.editPartialStartLocal
                                ) >= state.minPartialStart &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialStartLocal
                                ) <= state.maxPartialStart &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialStartLocal
                                ) <
                                  dateStringer.HHMMToMsToday(
                                    state.editPartialEndLocal
                                  )
                                  ? ""
                                  : `${
                                      state.beyond24
                                        ? ""
                                        : "disablePartialSaveBtn"
                                    }`
                              }`
                            : "none"
                        }`}
                        alt="Save"
                      >
                        Save
                      </p>
                    </div>
                  </div>
                </div>
                <div className="partialAbsenceRow">
                  <div className="absenceRowUnit1">
                    <p className="shiftEditTitle partialTitle">Absence end</p>
                  </div>
                  <div
                    className={`units2and3 ${
                      editPartialTimes2 ? "" : "units2and3Hover"
                    }`}
                    onClick={() => {
                      setEditPartialTimes2(true);
                    }}
                  >
                    <div className="absenceRowUnit2">
                      <input
                        className={`${
                          editPartialTimes2
                            ? `partialTimeEditMode ${
                                dateStringer.HHMMToMsToday(
                                  state.editPartialEndLocal
                                ) >= state.minPartialEnd &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialEndLocal
                                ) <= state.maxPartialEnd &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialEndLocal
                                ) >
                                  dateStringer.HHMMToMsToday(
                                    state.editPartialStartLocal
                                  )
                                  ? ""
                                  : `${
                                      state.beyond24
                                        ? ""
                                        : "invalidPartialInput"
                                    }`
                              }`
                            : "none"
                        }`}
                        onChange={(e) => {
                          setState({ editPartialEndLocal: e.target.value });
                        }}
                        type="time"
                        value={state.editPartialEndLocal}
                      />
                      <p
                        className={`${
                          editPartialTimes2 ? "none" : "partialTimeValue"
                        }`}
                      >
                        {state.editPartialEndLocal || "--:--"}
                      </p>
                    </div>
                    <div className="absenceRowUnit3">
                      <img
                        src={editGrey}
                        className={`${
                          editPartialTimes2 ? "none" : "editPartialTimePencil"
                        }`}
                        alt="Edit"
                      />
                      <p
                        // src={saveDayHours}
                        onClick={() => {
                          if (state.editPartialEndLocal.length === 5) {
                            setJamAbs(true);
                            axios
                              .post(
                                `${serverURL}/edit-shift-partial-absent-start-end-time`,
                                {
                                  shiftID: state.editShiftShiftID,
                                  start: state.editPartialStartLocal,

                                  end: state.editPartialEndLocal,
                                  type: `${
                                    state.itemType === "shift" ? "shift" : "til"
                                  }`,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  // setTimeout(() => {
                                  //   setUpdateShifts(!updateShifts);
                                  // }, 650);
                                  setShowNotify(true);
                                  setReload(!reload);

                                  // setState({
                                  //   partialAbsent: response.data.partialAbsent,
                                  //   editPartialStartLocal:
                                  //     response.data.partialStart,
                                  //   editPartialEndLocal:
                                  //     response.data.partialEnd,
                                  //   shiftCost: response.data.newCost,
                                  //   editDurationMins: response.data.durMins,
                                  //   partialAbsenceMins:
                                  //     response.data.partialAbsenceMins,
                                  //   unpaidSalary: response.data.unpaidSalary,
                                  //   payRoll: response.data.payRoll,
                                  // });
                                  // setPartialAbsent(response.data.partialAbsent);
                                  // setEditPartialStartLocal(
                                  //   response.data.partialStart
                                  // );
                                  // setEditPartialEndLocal(response.data.partialEnd);
                                  // setShiftCost(response.data.newCost);
                                  // setEditDurationMins(response.data.durMins);
                                  // setPartialAbsenceMins(
                                  //   response.data.partialAbsenceMins
                                  // );
                                  setChangesMade(true);
                                  setEditPartialTimes2(false);
                                  // setLoading(false);
                                  setJamAbs(false);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                        className={`${
                          editPartialTimes2
                            ? `saveBtnTxt savePartialBtn ${
                                dateStringer.HHMMToMsToday(
                                  state.editPartialEndLocal
                                ) >= state.minPartialEnd &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialEndLocal
                                ) <= state.maxPartialEnd &&
                                dateStringer.HHMMToMsToday(
                                  state.editPartialEndLocal
                                ) >
                                  dateStringer.HHMMToMsToday(
                                    state.editPartialStartLocal
                                  )
                                  ? ""
                                  : `${
                                      state.beyond24
                                        ? ""
                                        : "disablePartialSaveBtn"
                                    }`
                              }`
                            : "none"
                        }`}
                        alt="Save"
                      >
                        Save
                      </p>
                    </div>
                  </div>
                </div>
                <div className="partialAbsenceDurationSplitter"></div>
                <div className="partialAbsenceRow absentDurationRow">
                  <div className="absenceRowUnit1">
                    <p className="shiftEditTitle partialTitle">Absent for</p>
                  </div>
                  <div className="absenceRowUnit2 partialAbsenceDurationBox">
                    {state.partialAbsenceMins > 0
                      ? `${generateMonthDurationForModal(
                          state.partialAbsenceMins
                        )}`
                      : "1 hour"}
                  </div>
                  <div className="absenceRowUnit3"></div>
                </div>{" "}
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost ||
                  !state.editShiftAbsent ||
                  state.itemIsNew ||
                  state.itemType === "til"
                    ? "none"
                    : "editShiftRow clickShiftRow"
                }`}
                onClick={() => {
                  setChangesMade(true);

                  setState({ unpaidAbsent: !state.unpaidAbsent });
                  axios
                    .post(
                      `${serverURL}/make-shift-absent-unpaid`,
                      {
                        shiftID: state.editShiftShiftID,
                        data: !state.unpaidAbsent,
                        type: `${state.itemType === "shift" ? "shift" : "til"}`,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        setShowNotify(true);

                        setState({
                          unpaidAbsent: response.data.newData,
                          shiftCost: response.data.cost,
                          editShiftRate: response.data.rate,
                          editDurationMins:
                            state.itemType === "shift"
                              ? response.data.duration / 1000 / 60
                              : response.data.duration,
                          unpaidSalary: response.data.unpaidSalary,
                          payRoll: response.data.payRoll,
                          // harry
                        });
                        getSingleShiftData(state.editShiftShiftID);

                        // setLoading(false);
                        setChangesMade(true);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <p className="shiftEditTitle move5pxR">
                  {state.itemType === "til"
                    ? "Accrue TOIL anyway"
                    : "Paid absence"}
                </p>

                {/*  KNOB BEGIN */}
                <div
                  className={`${
                    state.itemGhost
                      ? "none"
                      : "editUserReqClockOutKnobContainer"
                  }`}
                >
                  <div className="knobContainer knobContainerEditUser knobEditShift">
                    <div
                      className={`switchKnob ${
                        !state.unpaidAbsent
                          ? "knobOn knobOnMgrColour knobRed"
                          : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost ||
                  !state.editShiftAbsent ||
                  !state.assocAbsenceName ||
                  state.itemIsNew
                    ? "none"
                    : "editShiftRow"
                }`}
                onClick={() => {
                  // onclicker
                }}
              >
                <p className="shiftEditTitle">Absence</p>
                <p
                  className="absenceUnitNameTxt"
                  onClick={() => {
                    closeModal();
                    memoVals.setShowUserAbsenceModal((x) => {
                      return {
                        absenceID: state.assocAbsenceID, // curry
                        isMgr: true,
                      };
                    });
                  }}
                >
                  {state.assocAbsenceName}
                </p>
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}

              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemType !== "til" && !state.itemIsNew
                    ? "editShiftRow"
                    : "none"
                } ${
                  !changeRequests[0] &&
                  (state.itemIsNew ||
                    openedFromShiftsPage ||
                    (state.clockOn && state.clockOnApproved))
                    ? "noBorderBottom_"
                    : ""
                }`}
              >
                <p className="shiftEditTitle move5pxR">Rate</p>

                <select
                  className="shiftRateDropdown"
                  value={state.editShiftRate}
                  onChange={(e) => {
                    setChangesMade(true);

                    setState({ editShiftRate: e.target.value });

                    axios
                      .post(
                        `${serverURL}/edit-shift-rate`,
                        {
                          shiftID: state.editShiftShiftID,
                          data: e.target.value,
                          type: `${
                            state.itemType === "shift" ? "shift" : "til"
                          }`,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          // setShiftCost(response.data.newCost);
                          // here we goThe am
                          setShowNotify(true);

                          getSingleShiftData(
                            state.editShiftShiftID,
                            response.data.type
                          );

                          // setShiftCost(response.data.newCost);
                          // setItemBankData(response.data.bankData);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>
                  <option value="0.3">0.3x</option>
                  <option value="0.5">0.5x</option>
                  <option value="0.6">0.6x</option>
                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>
              </div>
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemIsNew ||
                  openedFromShiftsPage ||
                  (state.clockOn && state.clockOnApproved)
                    ? "none"
                    : "editShiftRow"
                }`}
              >
                <div className="makeOpenTxtDiv">
                  <p className="makeOpenTitle move5pxR">
                    Open{" "}
                    {state.itemType === "til"
                      ? "shift in lieu"
                      : `${
                          state.itemType === "shift" ? "shift" : "overtime"
                        }`}{" "}
                    {/* open */}
                  </p>
                  <p className="makeOpenExplainTxt move5pxR">
                    {" "}
                    Remove{" "}
                    {state.itemType === "til"
                      ? "shift in lieu"
                      : `${
                          state.itemType === "shift" ? "shift" : "overtime"
                        }`}{" "}
                    from {state.itemFName} and allow other staff in{" "}
                    {state.showShiftModalTeamName} to claim it
                  </p>
                </div>
                <p
                  className="makeOpenBtn"
                  onClick={() => {
                    setSureMakeOpen(true);
                  }}
                >
                  Make open
                </p>
              </div>
              {/* end of row - - - - - - - - - - - - - - - - */}
              {/* new row - - - - - - - - - - - - - - - - */}
              <div
                className={`${
                  state.itemGhost || state.itemIsNew ? "none" : "editShiftRow"
                }`}
              >
                <div className="shiftTypeLeftBox">
                  <div className="shiftEditTitleTxt">Tags</div>
                  <div
                    className={`${
                      state.tags && state.tags.length === 0
                        ? "shiftEditValue noTagsTxt"
                        : "none"
                    }`}
                  >
                    No tags
                  </div>

                  <div
                    className={`${
                      !addingNewTag && state.tags && state.tags.length === 0
                        ? "none"
                        : "shiftTagHolder"
                    }`}
                  >
                    {generateShiftTags}
                    <div
                      className={`${addingNewTag ? "addTagUnit" : "none"}`}
                      onClick={() => {
                        setNewTagValue("");
                      }}
                    >
                      <input
                        className="tagInput"
                        placeholder="New tag"
                        ref={addTagInputRef}
                        onKeyPress={(e) => {
                          enterFct(e);
                        }}
                        type="text"
                        // defaultValue={newTagValue}
                        value={newTagValue}
                        onChange={(e) => {
                          // if (newTagValue.length < 18) {
                          setNewTagValue(e.target.value);
                          // }
                        }}
                      ></input>
                      <img
                        className={`${state.itemGhost ? "none" : "addTagIcon"}`}
                        src={tagAdd}
                        alt="Add tag"
                        onClick={() => {
                          if (newTagValue.length > 0) {
                            setChangesMade(true);
                            setAddingNewTag(false);

                            axios
                              .post(
                                `${serverURL}/add-new-shift-tag`,
                                {
                                  shiftID: state.editShiftShiftID,
                                  data: newTagValue,
                                  addTagsToAll,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setShowNotify(true);

                                  setState({ tags: response.data.tags });
                                  // setAmends(response.data.alerts || []);
                                  setNewTagValue("");
                                  if (addTagsToAll) {
                                    memoVals.setAddtagToAllUserShifts((x) => {
                                      return {
                                        userID: state.itemUserID,
                                        tag: newTagValue,
                                      };
                                    });
                                  }
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <p
                  className={`${
                    !state.itemGhost && !addingNewTag ? "addTagTxtBtn" : "none"
                  }`}
                  onClick={() => {
                    setChangesMade(true);
                    if (addTagsToAll) {
                      setAddTagsToAll(false);
                    }
                    axios
                      .post(
                        `${serverURL}/get-available-tags`,
                        {
                          teamID: state.shiftTeamID,
                          // userID: state.itemUserID,
                          // nowDs: dateStringer.createStringFromTimestamp(
                          //   new Date().getTime()
                          // ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((resp) => {
                        if (resp.data.message === "success") {
                          let arr = [];
                          resp.data.tags.forEach((tag) => {
                            if (state.tags.includes(tag)) {
                            } else {
                              arr.push(tag);
                            }
                          });
                          setAvailableTags(arr);
                          if (arr[0]) {
                            setShowAvailableTagsModal(true);
                          } else {
                            setNewTagValue("");
                            setAddingNewTag(true);
                            setShowAvailableTagsModal(false);
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Add tag{" "}
                  <img src={greyTag} alt="Add tag" className="addTagInlineSh" />
                </p>
              </div>
              {/* ---------------------------- */}
              {/* new row */}
              {state.itemType !== "til" && (
                <div className="myShiftRow">
                  <div className="myShiftRowLeft">
                    <p className="collea">
                      Wage spend on this day in {state.showShiftModalTeamName}
                    </p>
                    <p className="teamCostinclSpan">
                      (excluding leave & including this shift)
                    </p>

                    <p
                      className={`teamCostInSh ${
                        !teamCost || !salaryCost || !hourlyCost
                          ? "x8382818133"
                          : ""
                      }`}
                      onClick={() => {
                        if (salaryCost && hourlyCost) {
                          setShowTeamCost(!showTeamCost);
                        }
                      }}
                    >
                      {memoVals.currencySymbol}
                      {JSON.stringify(
                        teamCost ? teamCost.toFixed(2) : ""
                      ).includes(".00")
                        ? teamCost
                        : teamCost
                        ? teamCost.toFixed(2)
                        : 0}{" "}
                      {!teamCost || !salaryCost || !hourlyCost ? (
                        ""
                      ) : (
                        <img
                          src={allowArr}
                          alt="Costs"
                          className={`shTeamCostArr ${
                            showTeamCost ? "shTeamCostArrActive" : ""
                          }`}
                        />
                      )}
                    </p>
                    {showTeamCost && (
                      <div className="shCostArr">
                        <div className="shCostArrRow">
                          <p className="shCostArrTitle">
                            Cost from salaried shifts
                          </p>
                          <p className="shCostArrValue">
                            {memoVals.currencySymbol}
                            {JSON.stringify(salaryCost.toFixed(2)).includes(
                              ".00"
                            )
                              ? salaryCost
                              : salaryCost.toFixed(2)}
                          </p>
                        </div>

                        <div className="shCostArrRow noBorder">
                          <p className="shCostArrTitle">
                            Cost from hourly-paid shifts
                          </p>
                          <p className="shCostArrValue">
                            {memoVals.currencySymbol}
                            {JSON.stringify(hourlyCost.toFixed(2)).includes(
                              ".00"
                            )
                              ? hourlyCost
                              : hourlyCost.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>
              )}
              {/* end of row */}
              {/* new row */}
              <div className="myShiftRow">
                <div className="myShiftRowLeft">
                  <p className="collea">
                    Colleagues working the same day within{" "}
                    {state.showShiftModalTeamName}
                  </p>

                  {!otherUsersArr[0] ? (
                    <p className="x991ddz">
                      Nobody else is scheduled to work this day in{" "}
                      {state.showShiftModalTeamName}
                    </p>
                  ) : (
                    <div className="myShiftOtherUsersBlock">
                      {otherUsersArr.map((user) => {
                        if (user.userID !== state.itemUserID) {
                          let showUser = user.items.filter((x) => {
                            return !x.ghost;
                          });
                          if (showUser[0]) {
                            return (
                              <div className="myShOtherUserRow">
                                <img
                                  onClick={() => {
                                    memoVals.setSeeUserID((x) => {
                                      return {
                                        userID: user.userID,
                                        openedFromUserRota: false,
                                      };
                                    });
                                  }}
                                  src={user.pic || profilePicturePlaceholder}
                                  className="otherUsrShImg"
                                  alt={user.fName}
                                />
                                <div className="otherUsrShName">
                                  <p
                                    className="otherUsrShNameTop"
                                    onClick={() => {
                                      memoVals.setSeeUserID((x) => {
                                        return {
                                          userID: user.userID,
                                          openedFromUserRota: false,
                                        };
                                      });
                                    }}
                                  >
                                    {user.userID ===
                                    memoVals.checkUserState.userID
                                      ? "You"
                                      : user.fName}{" "}
                                    {user.userID ===
                                    memoVals.checkUserState.userID
                                      ? ""
                                      : user.lName}
                                  </p>
                                  <div className="otherUsrShNameLow">
                                    {user.items.map((x) => {
                                      return (
                                        <p className="otherUserShItem">
                                          <img
                                            src={rotaClock2}
                                            alt="times"
                                            className="otherUsrClock"
                                          />
                                          {dateStringer.dsToTimeStrip(
                                            x.startDs
                                          )}{" "}
                                          -{" "}
                                          {dateStringer.dsToTimeStrip(x.endDs)}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  )}
                </div>
                <div className="myShiftRowRight"></div>
              </div>
              {/* end of row */}
              {/* 
    
              {/* end of row - - - - - - - - - - - - - - - - */}
            </div>
          )}
          {/* end of modal body */}
          {/* modal footer */}
          <div className="editShiftModalFooter">
            <div
              className={`backAndDeleteShiftContainer x2913081 ${
                state.loading ? "none" : ""
              }`}
            >
              <div
                className={`pubShiftBtn ${
                  state.itemIsNew
                    ? `${publishImmediately ? "pubImmedBg" : ""}`
                    : "none"
                } `}
                onClick={() => {
                  setSureDeleteShift("");
                  setPublishImmediately(!publishImmediately);
                }}
              >
                <img
                  src={publishImmediately ? checkbox2 : checkbox1}
                  className="publishTickImg"
                  alt="Publish"
                />
                Publish
              </div>

              {state.published &&
              showNotify &&
              memoVals.checkUserState.userID !== state.itemUserID ? (
                <p
                  className="notifyShChangeBtn"
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/notify-user-shift-has-changed`,
                        {
                          shiftID: state.editShiftShiftID,
                          type: `${state.itemType}`,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setShowNotify(false);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `Notified ${
                                state.itemFName
                              } that their ${
                                state.itemType === "til"
                                  ? "shift in lieu"
                                  : state.itemType
                              } has changed`,
                              colour: "blue",
                              duration: 4000,
                            };
                          });

                          setState({
                            accepted: false,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Notify
                </p>
              ) : (
                <div
                  className={`pubShiftBtn ${
                    !state.published ? "needToPubColour" : ""
                  } ${state.itemIsNew ? "none" : ""} `}
                  onClick={() => {
                    setSureDeleteShift("");
                    // curryhouse
                    setState({ published: !state.published });
                    setChangesMade(true);

                    axios
                      .post(
                        `${serverURL}/publish-single-shift-or-til`,
                        {
                          shiftID: state.editShiftShiftID,
                          type: state.itemType,
                          data: !state.published,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        setState({ published: response.data.newData });
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  {state.published ? "Unpublish" : "Publish"}
                </div>
              )}

              <div
                onMouseEnter={() => {
                  console.log(state.editPublicNoteValue.length);
                }}
                className={`notSureDeleteShiftBtn ${
                  state.editPublicNoteValue &&
                  state.editPublicNoteValue.length >= 9
                    ? "notesBtnShift2"
                    : "notesBtnShift"
                } ${showNotes ? "noteBtnSelected" : ""} ${
                  state.editPublicNoteValue &&
                  state.editPublicNoteValue.length > 0 &&
                  !showNotes
                    ? "activeNoteBtnColours"
                    : ""
                } ${
                  state.editPublicNoteValue &&
                  state.editPublicNoteValue.length > 0 &&
                  !showNotes
                    ? "notesActive"
                    : ""
                }`}
                onClick={() => {
                  setShowNotes(!showNotes);
                }}
              >
                {showNotes ? (
                  <img
                    src={whitecross}
                    alt="Close"
                    className="closeShiftNotesWhiteCrossImg"
                  />
                ) : (
                  ""
                )}
                Notes{" "}
                {showNotes
                  ? ""
                  : `(${
                      state.editPublicNoteValue
                        ? state.editPublicNoteValue.length > 9
                          ? "9+"
                          : state.editPublicNoteValue.length
                        : 0
                    })`}
              </div>
              <p
                className={`deleteShiftBtn pubShiftBtn ${
                  showNotes ? "x9098" : ""
                } ${state.itemIsNew ? "none" : ""}`}
                onClick={() => {
                  // if (!state.itemIsNew) {
                  if (!sureDeleteShift) {
                    setSureDeleteShift(true);
                  } else {
                  }
                  // } else {
                  memoVals.setDontAddNewItemToRota((x) => true);
                  // }
                }}
              >
                <img
                  src={whitebin}
                  alt="Delete shift"
                  className="deleteShiftBin"
                />
              </p>
              {/* daisy */}
              {sureDeleteShift ? (
                <div
                  className="addAbsenceModalUnderlay"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSureDeleteShift(false);
                  }}
                >
                  {" "}
                  <div
                    className="formCreatedModal"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <p className="overlapsRenTxt">
                      Are you sure you want to
                      <br />
                      delete {state.itemFName}
                      {state.itemFName[state.itemFName.length - 1] === "s"
                        ? "'"
                        : "'s"}{" "}
                      {state.itemType === "shift"
                        ? "shift"
                        : state.itemType === "til"
                        ? "shift in lieu"
                        : "overtime"}
                      ?
                    </p>
                    <div className="areYouModalBtnsDiv">
                      <p
                        className="areYouSureModalYesBtn"
                        onClick={() => {
                          setChangesMade(true);
                          setSureDeleteShift(false);
                          axios
                            .post(
                              `${serverURL}/delete-shift`,
                              {
                                shiftID: state.editShiftShiftID,
                                type: `${
                                  state.itemType === "shift" ? "shift" : "til"
                                }`,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.status === 200) {
                                memoVals.setShiftIDNoLongerExists((x) => {
                                  return {
                                    userID: state.itemUserID,
                                    itemID: state.editShiftShiftID,
                                  };
                                });
                                // closeModal(true);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                          closeModal(true);
                        }}
                      >
                        Delete
                      </p>
                      <p
                        className="areYouModalNoBtn"
                        onClick={() => {
                          setSureDeleteShift(false);
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <p
              className={`closeEditShiftBtn ${
                loading ? "disableCloseBtn" : ""
              }`}
              onClick={() => {
                if (loading) {
                } else {
                  if (state.itemIsNew) {
                    setSureCloseInitial(true);
                  } else {
                    closeModal();
                  }
                }
              }}
            >
              Close
            </p>
          </div>
          {/* end of modal footer */}
        </div>
      )}
      {/*end of modalBox  */}
      {/* templates modal */}
      <div
        className={`${
          showShiftTemplateModal ? "shiftTemplateUnderlay" : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setShowShiftTemplateModal(false);
        }}
      >
        <div
          className={`shiftTemplateModalBox ${
            memoVals.mobModal ? "mobModalShoulder" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="shiftTemplateModalHeader">
            <img
              src={close}
              className="closeShiftTemplateModalImage"
              onClick={() => {
                setShowShiftTemplateModal(false);
              }}
            />
            <p className="shiftTemplateHeaderTitleTxt">Suggestions</p>
            <div className="emptyShiftTemplateShiftModalHeaderSpaceRight"></div>
          </div>

          <div className="shiftTemplateModalContentContainer">
            {generateShiftTemplates}
          </div>
        </div>
      </div>
      {/* end of templates modal */} {/* choose available tags modal */}
      <div
        className={`${
          showAvailableTagsModal ? "availableTagsUnderlay" : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setShowAvailableTagsModal(false);
        }}
      >
        <div
          className={`availableTagsModal ${
            memoVals.mobModal ? "mobModalShoulder" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPositionTags}
          ref={tagsModalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperAddTags"></div>
          )}
          <div
            className="addTagModalHeader"
            onTouchStart={handleDownTags}
            onTouchMove={memoVals.mobModal ? handleMoveTags : null}
            onTouchEnd={memoVals.mobModal ? handleUpTags : null}
            onMouseDown={memoVals.mobModal ? handleDownTags : null}
            onMouseMove={memoVals.mobModal ? handleMoveTags : null}
            onMouseUp={memoVals.mobModal ? handleUpTags : null}
          >
            <img
              className="closeAddTagModalImg"
              src={navyClose}
              alt="Close"
              onClick={() => {
                setShowAvailableTagsModal(false);
              }}
            />
            <p className="addTagModalHeaderTitle">Add tags</p>
            <p className="phantomModalHeaderUnit">&nbsp;</p>
          </div>

          <div className="generateAvailTagsScrollBox">
            {generateAvailableTags}{" "}
            <div
              className={`${editTagSuggestions ? "none" : "addTagRowItem"}`}
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(true);
                setShowAvailableTagsModal(false);
              }}
            >
              Add new tag
            </div>
          </div>
          <div className="tagMgrDiv">
            <div className="tagMgrLeft">
              <p
                className={`editTagSugBtn ${
                  !editTagSuggestions ? "editTagBtn" : ""
                }`}
                onClick={() => {
                  setEditTagSuggestions(!editTagSuggestions);
                }}
              >
                {editTagSuggestions ? (
                  "Done"
                ) : (
                  <img
                    src={edit}
                    alt="Edit tag suggestions"
                    className="editTagSugImg"
                  />
                )}
              </p>{" "}
              <p
                className={`addTagToMulti ${
                  addTagsToAll ? "addTagsToAllActive" : ""
                } ${editTagSuggestions ? "none" : ""}`}
                onClick={() => {
                  setAddTagsToAll(!addTagsToAll);
                }}
              >
                <img
                  src={addTagsToAll ? checkbox2b : checkbox1}
                  alt="Add tag to all items in week"
                  className="addTagToAllImg"
                />
                Apply to all
                <br />
                shifts in week
              </p>
            </div>
            <p
              className="closeMgrAvailReqBtn"
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(false);
                setShowAvailableTagsModal(false);
              }}
            >
              Close
            </p>

            {/* <p className="phantomModalHeaderUnit">&nbsp;</p> */}
            {/* <p
              className="addNewTagBtn"
              onClick={() => {
                setNewTagValue("");
                setAddingNewTag(true);
                setShowAvailableTagsModal(false);
              }}
            >
              Add new tag
            </p> */}
          </div>
        </div>
      </div>
      {sureMakeOpen ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setSureMakeOpen(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to remove this{" "}
              {state.itemType === "til" ? "shift in lieu" : state.itemType} from{" "}
              {state.itemFName} and make it open, available for someone else in{" "}
              {state.showShiftModalTeamName} to claim?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/change-user-shift-or-til-to-open`,
                      {
                        shiftID: state.editShiftShiftID,
                        type: state.itemType,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        // setSingleDay(state.dayN);
                        memoVals.setOpenShiftConvert((x) => {
                          return {
                            direction: "toOpen",
                            openID: response.data.openID,
                            ds: response.data.ds,
                            startDs: response.data.startDs,
                            endDs: response.data.endDs,
                            teamID: response.data.teamID,
                            tags: response.data.tags,
                          };
                        });

                        closeModal(true, false, false);
                        // setUpdate2(!update2);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes - open
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureMakeOpen(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureCloseInitial ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();

            setSureCloseInitial(false);
            // closeModal();
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You haven't saved this new{" "}
              {state.itemType === "shift"
                ? "shift"
                : state.itemType === "til"
                ? "shift in lieu"
                : "overtime"}{" "}
              for {state.itemFName}.
              <br />
              <br />
              Are you sure you want to close?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-shift`,
                      {
                        shiftID: state.editShiftShiftID,
                        type: `${state.itemType === "shift" ? "shift" : "til"}`,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                  closeModal(true);
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureCloseInitial(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {shiftDoesNotExist ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            closeModal(false, true, true);
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            This shift does not exist.
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  closeModal(false, true, true);
                }}
              >
                Close
              </p>
              {/* <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setNotEnoughModal(false);
                    }}
                  >
                    Cancel
                  </p> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {payClockedNotApprovedYet ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!state.clockOnApproved && !state.clockOnDeclined ? (
              <p className="overlapsRenTxt">
                The clock card for this{" "}
                {state.itemType === "til" ? "shift in lieu" : state.itemType}{" "}
                has not yet been approved.
                <br />
                <br />
                When {dateStringer.possession(state.itemFName)} clock card has
                been approved, the amount payable for this{" "}
                {state.itemType === "til" ? "shift in lieu" : state.itemType}{" "}
                will reflect the clocked duration only.
              </p>
            ) : (
              <p className="overlapsRenTxt">
                The clock card for this{" "}
                {state.itemType === "til" ? "shift in lieu" : state.itemType}{" "}
                has been declined.
                <br />
                <br />
                The amount payable for this clock card will not reflect the
                clocked duration unless the clock card is approved.
              </p>
            )}
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setPayClockedNotApprovedYet(false);
                }}
              >
                Understood
              </p>
              {/* <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setNotEnoughModal(false);
                    }}
                  >
                    Cancel
                  </p> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showPartialBlockModal ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You cannot amend this{" "}
              {state.itemType === "til" ? "shift in lieu" : state.itemType}{" "}
              whilst it is marked partially absent. <br />
              <br />
              Remove the parital absence and then make your changes. You can
              then re-add the partial absence again.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowPartialBlockModal(false);
                }}
              >
                Understood
              </p>
              {/* <p
                    className="areYouModalNoBtn"
                    onClick={() => {
                      setNotEnoughModal(false);
                    }}
                  >
                    Cancel
                  </p> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
      {/* end of choose available tags modal */}
    </div>

    // end of underlay
  );
};

export default ManagerShiftModal;
