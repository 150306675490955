import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { UserContext } from "../../../contexts/UserContext";
import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";

import serverURL from "../../../serverURL";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import navyBin from "../../../img/general/navyBin.svg";
import whiteAddThin from "../../../img/general/whiteAddThin.svg";

const Admins = () => {
  const { setSelectedNav } = useContext(DataContext);

  const { adminsArray, setAdminsArray } = useContext(DataContext);
  const { mob, setAccNav } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setSelectedNav,
      adminsArray,
      setAdminsArray,
      mob,
      setAccNav,
    }),
    [setSelectedNav, adminsArray, setAdminsArray, mob, setAccNav]
  );

  let [allUsers, setAllUsers] = useState([]);
  let [showAddAdmin, setShowAddAdmin] = useState(false);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
    memoVals.setSelectedNav((x) => 5.1);
  }, []);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 2) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyBin}
        alt="Navy Tri"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />

      <img
        src={whiteAddThin}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/administrators";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-admins`,
        { getAllUsers: false, someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        memoVals.setAdminsArray((x) => response.data.adminsArray);
        setLoading(false);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let adminsToBeSorted = [];
  memoVals.adminsArray.forEach((item) => {
    adminsToBeSorted.push({
      fName: item.fName,
      lName: item.lName,
      userID: item.userID,
      order: item.self ? 0 : 1,
      self: item.self,
    });
  });

  let sortedAdmins = adminsToBeSorted.sort(function (a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  let generateAdminsData = sortedAdmins.map((item) => {
    return (
      <div
        className={`${
          item.self ? "adminUserRow youColourAdmin" : "adminUserRow"
        }`}
        key={item.userID}
        onClick={() => {
          console.log(item);
        }}
      >
        <p
          className={`${item.self ? "none" : "adminUserName"}`}
        >{`${item.fName} ${item.lName}`}</p>
        <p className={`${item.self ? "adminUserName" : "none"}`}>You</p>

        <img
          src={navyBin}
          alt="Remove Admin"
          className={`${item.self ? "none" : "removeAdminBinImg"}`}
          onClick={() => {
            axios
              .post(
                `${serverURL}/demote-admin`,
                { userID: item.userID },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  memoVals.setAdminsArray((x) =>
                    memoVals.adminsArray.filter((x) => {
                      return x.userID !== item.userID;
                    })
                  );
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        />
      </div>
    );
  });

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob ? "AdminSettingsContainerMob" : "borderRightGrey"
      }`}
    >
      {/* <div className='whiteGradientAdmin'></div> */}
      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`gpsAdminRow ${memoVals.mob ? "gpsAdminRowMob" : ""}`}
          >
            <div className={`settingsRow`}>
              <div className="rowWithSubTextGPS">
                <p className="absenceRowMainText">System administrators</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Whilst managers may only manage their own team members,
                  administrators are able to view and manage all teams and staff
                  across the whole company in FlowRota. This includes approving
                  clock cards, leave requests and marking staff as absent.
                </p>
              </div>{" "}
            </div>
            <div className="gpsList paddingBottom0">
              <div className="specialDayUnitsContainer">
                {generateAdminsData}
              </div>
            </div>{" "}
            <div className={`addZoneParent`}>
              {showAddAdmin ? (
                <select
                  className="dailyPayRateDropdown x21345678542"
                  onChange={(e) => {
                    if (e.target.value !== "--") {
                      let val = e.target.value;
                      axios
                        .post(
                          `${serverURL}/make-user-admin`,
                          { userID: e.target.value },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            memoVals.setAdminsArray((x) => [
                              ...memoVals.adminsArray,
                              {
                                fName: response.data.fName,
                                lName: response.data.lName,
                                userID: val,
                                order: 1,
                                self: false,
                              },
                            ]);

                            setShowAddAdmin(false);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  <option key="1" value="--">
                    -- Select employee
                  </option>
                  {allUsers.map((x) => {
                    let adminFilt = memoVals.adminsArray.filter((xx) => {
                      return xx.userID === x.userID;
                    });

                    if (!adminFilt[0]) {
                      return (
                        <option key={x.userID} value={x.userID}>
                          {x.fName} {x.lName}
                        </option>
                      );
                    }
                  })}
                </select>
              ) : (
                <p
                  className="addGPSBtn addCustomBtn x2312221"
                  onClick={() => {
                    setShowAddAdmin(!showAddAdmin);
                    if (!allUsers[0]) {
                      axios
                        .post(
                          `${serverURL}/get-admins`,
                          { getAllUsers: true },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          setAllUsers(response.data.allUsers);
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  Add administrator{" "}
                  <img
                    src={whiteAddThin}
                    alt="Add zone"
                    className="addZonePlus addTypePlusImg"
                  />
                </p>
              )}
            </div>
          </div>

          <br />
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default Admins;
