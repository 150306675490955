import React, { useEffect, useState, useContext, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import DatePicker from "react-datepicker";

import serverURL from "../../../../serverURL";

import "../../../../styles/newUser.css";

import checkbox1 from "../../../../img/general/checkbox1.svg";
import checkbox2 from "../../../../img/general/checkbox2.svg";
import bluePlus from "../../../../img/general/bluePlus.svg";

import dateStringer from "../../../../tools/dateStringer";

import { DataContext } from "../../../../contexts/DataContext";
import { StyleContext } from "../../../../contexts/StyleContext";
import { UserContext } from "../../../../contexts/UserContext";

import cancel from "../../../../img/general/cancel.svg";

import "../../../../styles/datepicker.css";
import "../../../../styles/subcomponents/specialDays.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/settings.css";

const AddUserSeries = ({ refPeriod }) => {
  // let showAddUser modal
  let {
    setShowAddUserModal,
    addUserPage,
    setAddUserPage,
    addUserModalCalOpen,
    setAddUserModalCalOpen,
  } = useContext(StyleContext);
  let { companyName } = useContext(UserContext);
  let {
    allTeams,
    setAllTeams,
    setAdminUsersArray,

    currYrStartTs,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      allTeams,
      setAllTeams,
      setAdminUsersArray,
      currYrStartTs,
      companyName,
      setShowAddUserModal,
      addUserPage,
      setAddUserPage,
      addUserModalCalOpen,
      setAddUserModalCalOpen,
    }),
    [
      allTeams,
      setAllTeams,
      setAdminUsersArray,
      currYrStartTs,
      companyName,
      setShowAddUserModal,
      addUserPage,
      setAddUserPage,
      addUserModalCalOpen,
      setAddUserModalCalOpen,
    ]
  );

  let [fadeSched, setFadeSched] = useState(false);

  let [proRatedDue, setProRatedDue] = useState(false);
  let [startsInFuture, setStartsInFuture] = useState(false);

  // prorate isnt due if user joins on renewal date +/- 1 day, or if user joins today.

  // get array of all client teams from server
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .get(`${serverURL}/get-all-teams`, {
        withCredentials: true,
        credentials: "include",
        cancelToken: cancelSource1.token,
      })
      .then((response) => {
        if (response.data.teamsArray) {
          memoVals.setAllTeams((x) => response.data.teamsArray);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  // set the default startDate to today's date:
  let defaultStartDate = () => {
    let todayInstance = new Date();
    let todayAtMidnight = todayInstance.setHours(0, 0, 0, 0);
    return todayAtMidnight;
  };

  let todayTimestamp = defaultStartDate();

  // store datepicker value
  let today = new Date();
  let todayAt00 = today.setHours(0, 0, 0, 0);
  let [datepickerStartDate, setDatepickerStartDate] = useState(todayAt00);

  // States for submission
  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [email, setEmail] = useState("");
  let [primaryTeam, setPrimaryTeam] = useState("");
  let [startDate, setStartDate] = useState();
  let [reqClockOut, setReqClockOut] = useState(false);
  let [workingPattern, setWorkingPattern] = useState("zero");
  let [fixedVar, setFixedVar] = useState(false);
  let [fixedVarHrs, setFixedVarHrs] = useState(0);
  let [fixedVarMins, setFixedVarMins] = useState(0);
  // fixedSchedOdd is true if scheduled days are inequal - declared below with function
  // minsTotal stores the total fixed mins each week by the user - unable to set into a state due to re-render limit

  let [mBrk, setMBrk] = useState("");
  let [tBrk, setTBrk] = useState("");
  let [WBrk, setWBrk] = useState("");
  let [thBrk, setThBrk] = useState("");
  let [fBrk, setFBrk] = useState("");
  let [sBrk, setSBrk] = useState("");
  let [suBrk, setSuBrk] = useState("");

  let [monTimeStart, setMonTimeStart] = useState("");
  let [monTimeEnd, setMonTimeEnd] = useState("");

  let [tueTimeStart, setTueTimeStart] = useState("");
  let [tueTimeEnd, setTueTimeEnd] = useState("");

  let [wedTimeStart, setWedTimeStart] = useState("");
  let [wedTimeEnd, setWedTimeEnd] = useState("");

  let [thuTimeStart, setThuTimeStart] = useState("");
  let [thuTimeEnd, setThuTimeEnd] = useState("");

  let [friTimeStart, setFriTimeStart] = useState("");
  let [friTimeEnd, setFriTimeEnd] = useState("");

  let [satTimeStart, setSatTimeStart] = useState("");
  let [satTimeEnd, setSatTimeEnd] = useState("");

  let [sunTimeStart, setSunTimeStart] = useState("");
  let [sunTimeEnd, setSunTimeEnd] = useState("");

  let [proHpwHours, setProHpwHours] = useState(0);
  let [proHpwMins, setProHpwMins] = useState(0);

  useEffect(() => {
    setMonTimeStart("");
    setMonTimeEnd("");
    setMBrk("");
    setTueTimeStart("");
    setTueTimeEnd("");
    setTBrk("");
    setWedTimeStart("");
    setWedTimeEnd("");
    setWBrk("");
    setThuTimeStart("");
    setThuTimeEnd("");
    setThBrk("");
    setFriTimeStart("");
    setFriTimeEnd("");
    setFBrk("");
    setSatTimeStart("");
    setSatTimeEnd("");
    setSBrk("");
    setSunTimeStart("");
    setSunTimeEnd("");
    setSuBrk("");
  }, [memoVals.addUserModalCalOpen]);

  // fct to clear the states
  let clearState = () => {
    setFName("");
    setLName("");
    setEmail("");
    setPrimaryTeam("");
    setStartDate();
    setReqClockOut(false);
    setWorkingPattern("zero");
    setFixedVar(false);
    setFixedVarHrs(0);
    setFixedVarMins(0);
    setProHpwHours(0);
    setProHpwMins(0);
    setFadeSched(false);
  };

  let todayTs = new Date();
  todayTs.setHours(0, 0, 0, 0);

  let todayYear = today.getFullYear();
  let todayM = today.getMonth() + 1;
  let todayD = today.getDate();

  let prepend0today = (val, dm) => {
    if (val < 10) {
      return `0${val}`;
    } else {
      return val;
    }
  };

  let dsForToday = `${todayYear}-${prepend0today(todayM)}-${prepend0today(
    todayD
  )}`;

  let [userJoinDate, setUserJoinDate] = useState(dsForToday);

  let convertDsToTs = (ds) => {
    let yr = ds.substr(0, 4);
    let m = ds.substr(5, 2);
    let d = ds.substr(8, 2);

    let dateObj = new Date(yr, m - 1, d, 0, 0, 0, 0);

    return dateObj.getTime();
  };

  let calcVarMins = () => {
    let totalHrMins = parseInt(fixedVarHrs) * 60;
    let finalResult = parseInt(fixedVarMins) + totalHrMins;
    return finalResult;
  };

  let varMinsTotal = calcVarMins();

  let calcTotalHrs = () => {
    //monOutput = total mins of shift

    let mEndH = parseInt(monTimeEnd.substr(0, 2));
    let mStaH = parseInt(monTimeStart.substr(0, 2));
    let mEndM = parseInt(monTimeEnd.substr(3, 2));
    let mStaM = parseInt(monTimeStart.substr(3, 2));

    let monHrs = (mEndH - mStaH) * 60;
    let monMins = mEndM - mStaM;
    let monOutput = 0;
    let monHrsPreMidnight = 0;
    let monMinsPreMidnight = 0;
    let monHrsPostMidnight = 0;
    let monMinsPostMidnight = 0;
    let monTotalNightMins = 0;
    // if the shift crosses midnight
    if (mEndH < mStaH) {
      // min pre midnight
      monHrsPreMidnight = (24 - mStaH) * 60;
      monMinsPreMidnight = monHrsPreMidnight - mStaM;
      // min post midnight
      monHrsPostMidnight = mEndH * 60; // hours in minutes after midnight
      monMinsPostMidnight = mEndM + monHrsPostMidnight;
      monOutput = monMinsPreMidnight + monMinsPostMidnight - mBrk;
    } else {
      monOutput = monHrs + monMins - mBrk;
    }

    //tueOutput = total mins of shift

    let tEndH = parseInt(tueTimeEnd.substr(0, 2));
    let tStaH = parseInt(tueTimeStart.substr(0, 2));
    let tEndM = parseInt(tueTimeEnd.substr(3, 2));
    let tStaM = parseInt(tueTimeStart.substr(3, 2));

    let tueHrs = (tEndH - tStaH) * 60;
    let tueMins = tEndM - tStaM;
    let tueOutput = 0;
    let tueHrsPreMidnight = 0;
    let tueMinsPreMidnight = 0;
    let tueHrsPostMidnight = 0;
    let tueMinsPostMidnight = 0;
    // if the shift crosses midnight
    if (tEndH < tStaH) {
      // min pre midnight
      tueHrsPreMidnight = (24 - tStaH) * 60;
      tueMinsPreMidnight = tueHrsPreMidnight - tStaM;
      // min post midnight
      tueHrsPostMidnight = tEndH * 60; // hours in minutes after midnight
      tueMinsPostMidnight = parseInt(tEndM) + tueHrsPostMidnight;
      tueOutput = tueMinsPreMidnight + tueMinsPostMidnight - tBrk;
    } else {
      tueOutput = tueHrs + tueMins - tBrk;
    }

    //wedOutput = total mins of shift
    let WEndH = parseInt(wedTimeEnd.substr(0, 2));
    let WStaH = parseInt(wedTimeStart.substr(0, 2));
    let WEndM = parseInt(wedTimeEnd.substr(3, 2));
    let WStaM = parseInt(wedTimeStart.substr(3, 2));

    let wedHrs = (WEndH - WStaH) * 60;
    let wedMins = WEndM - WStaM;
    let wedOutput = 0;
    let wedHrsPreMidnight = 0;
    let wedMinsPreMidnight = 0;
    let wedHrsPostMidnight = 0;
    let wedMinsPostMidnight = 0;
    let wedTotalNightMins = 0;
    // if the shift crosses midnight
    if (WEndH < WStaH) {
      // min pre midnight
      wedHrsPreMidnight = (24 - WStaH) * 60;
      wedMinsPreMidnight = wedHrsPreMidnight - WStaM;
      // min post midnight
      wedHrsPostMidnight = WEndH * 60; // hours in minutes after midnight
      wedMinsPostMidnight = parseInt(WEndM) + wedHrsPostMidnight;
      wedOutput = wedMinsPreMidnight + wedMinsPostMidnight - WBrk;
    } else {
      wedOutput = wedHrs + wedMins - WBrk;
    }

    //thuOutput = total mins of shift
    let thEndH = parseInt(thuTimeEnd.substr(0, 2));
    let thStaH = parseInt(thuTimeStart.substr(0, 2));
    let thEndM = parseInt(thuTimeEnd.substr(3, 2));
    let thStaM = parseInt(thuTimeStart.substr(3, 2));

    let thuHrs = (thEndH - thStaH) * 60;
    let thuMins = thEndM - thStaM;
    let thuOutput = 0;
    let thuHrsPreMidnight = 0;
    let thuMinsPreMidnight = 0;
    let thuHrsPostMidnight = 0;
    let thuMinsPostMidnight = 0;
    // if the shift crosses midnight
    if (thEndH < thStaH) {
      // min pre midnight
      thuHrsPreMidnight = (24 - thStaH) * 60;
      thuMinsPreMidnight = thuHrsPreMidnight - thStaM;
      // min post midnight
      thuHrsPostMidnight = thEndH * 60; // hours in minutes after midnight
      thuMinsPostMidnight = parseInt(thEndM) + thuHrsPostMidnight;
      thuOutput = thuMinsPreMidnight + thuMinsPostMidnight - thBrk;
    } else {
      thuOutput = thuHrs + thuMins - thBrk;
    }

    //friOutput = total mins of shift
    let fEndH = parseInt(friTimeEnd.substr(0, 2));
    let fStaH = parseInt(friTimeStart.substr(0, 2));
    let fEndM = parseInt(friTimeEnd.substr(3, 2));
    let fStaM = parseInt(friTimeStart.substr(3, 2));

    let friHrs = (fEndH - fStaH) * 60;
    let friMins = fEndM - fStaM;
    let friOutput = 0;
    let friHrsPreMidnight = 0;
    let friMinsPreMidnight = 0;
    let friHrsPostMidnight = 0;
    let friMinsPostMidnight = 0;
    // if the shift crosses midnight
    if (fEndH < fStaH) {
      // min pre midnight
      friHrsPreMidnight = (24 - fStaH) * 60;
      friMinsPreMidnight = friHrsPreMidnight - fStaM;
      // min post midnight
      friHrsPostMidnight = fEndH * 60; // hours in minutes after midnight
      friMinsPostMidnight = parseInt(fEndM) + friHrsPostMidnight;
      friOutput = friMinsPreMidnight + friMinsPostMidnight - fBrk;
    } else {
      friOutput = friHrs + friMins - fBrk;
    }

    //satOutput = total mins of shift
    let sEndH = parseInt(satTimeEnd.substr(0, 2));
    let sStaH = parseInt(satTimeStart.substr(0, 2));
    let sEndM = parseInt(satTimeEnd.substr(3, 2));
    let sStaM = parseInt(satTimeStart.substr(3, 2));

    let satHrs = (sEndH - sStaH) * 60;
    let satMins = sEndM - sStaM;
    let satOutput = 0;
    let satHrsPreMidnight = 0;
    let satMinsPreMidnight = 0;
    let satHrsPostMidnight = 0;
    let satMinsPostMidnight = 0;
    // if the shift crosses midnight
    if (sEndH < sStaH) {
      // min pre midnight
      satHrsPreMidnight = (24 - sStaH) * 60;
      satMinsPreMidnight = satHrsPreMidnight - sStaM;
      // min post midnight
      satHrsPostMidnight = sEndH * 60; // hours in minutes after midnight
      satMinsPostMidnight = parseInt(sEndM) + satHrsPostMidnight;
      satOutput = satMinsPreMidnight + satMinsPostMidnight - sBrk;
    } else {
      satOutput = satHrs + satMins - sBrk;
    }

    //sunOutput = total mins of shift
    let suEndH = parseInt(sunTimeEnd.substr(0, 2));
    let suStaH = parseInt(sunTimeStart.substr(0, 2));
    let suEndM = parseInt(sunTimeEnd.substr(3, 2));
    let suStaM = parseInt(sunTimeStart.substr(3, 2));

    let sunHrs = (suEndH - suStaH) * 60;
    let sunMins = suEndM - suStaM;
    let sunOutput = 0;
    let sunHrsPreMidnight = 0;
    let sunMinsPreMidnight = 0;
    let sunHrsPostMidnight = 0;
    let sunMinsPostMidnight = 0;
    // if the shift crosses midnight
    if (suEndH < suStaH) {
      // min pre midnight
      sunHrsPreMidnight = (24 - suStaH) * 60;
      sunMinsPreMidnight = sunHrsPreMidnight - suStaM;
      // min post midnight
      sunHrsPostMidnight = suEndH * 60; // hours in minutes after midnight
      sunMinsPostMidnight = parseInt(suEndM) + sunHrsPostMidnight;
      sunOutput = sunMinsPreMidnight + sunMinsPostMidnight - suBrk;
    } else {
      sunOutput = sunHrs + sunMins - suBrk;
    }

    let totMinutes = 0;
    if (monTimeStart && monTimeEnd) {
      totMinutes += monOutput;
    }
    if (tueTimeStart && tueTimeEnd) {
      totMinutes += tueOutput;
    }
    if (wedTimeStart && wedTimeEnd) {
      totMinutes += wedOutput;
    }
    if (thuTimeStart && thuTimeEnd) {
      totMinutes += thuOutput;
    }
    if (friTimeStart && friTimeEnd) {
      totMinutes += friOutput;
    }
    if (satTimeStart && satTimeEnd) {
      totMinutes += satOutput;
    }
    if (sunTimeStart && sunTimeEnd) {
      totMinutes += sunOutput;
    }
    // let combinedMinutes = (parseInt(monOutput) + parseInt(tueOutput) + parseInt(wedOutput) + parseInt(thuOutput) + parseInt(friOutput) + parseInt(satOutput) + parseInt(sunOutput));

    let weeklyHrs = Math.floor(totMinutes / 60);
    let weeklyMins = totMinutes % 60;

    return `${weeklyHrs}hr ${weeklyMins}m`;
  };

  let calcTotalMinsForRequest = () => {
    //monOutput = total mins of shift
    let mEndH = parseInt(monTimeEnd.substr(0, 2));
    let mStaH = parseInt(monTimeStart.substr(0, 2));
    let mEndM = parseInt(monTimeEnd.substr(3, 2));
    let mStaM = parseInt(monTimeStart.substr(3, 2));

    let monHrs = (mEndH - mStaH) * 60;
    let monMins = mEndM - mStaM;
    let monOutput = 0;
    let monHrsPreMidnight = 0;
    let monMinsPreMidnight = 0;
    let monHrsPostMidnight = 0;
    let monMinsPostMidnight = 0;
    let monTotalNightMins = 0;
    // if the shift crosses midnight
    if (mEndH < mStaH) {
      // min pre midnight
      monHrsPreMidnight = (24 - mStaH) * 60;
      monMinsPreMidnight = monHrsPreMidnight - mStaM;
      // min post midnight
      monHrsPostMidnight = mEndH * 60; // hours in minutes after midnight
      monMinsPostMidnight = parseInt(mEndM) + monHrsPostMidnight;
      monOutput = monMinsPreMidnight + monMinsPostMidnight - mBrk;
    } else {
      monOutput = monHrs + monMins - mBrk;
    }

    //tueOutput = total mins of shift
    let tEndH = parseInt(tueTimeEnd.substr(0, 2));
    let tStaH = parseInt(tueTimeStart.substr(0, 2));
    let tEndM = parseInt(tueTimeEnd.substr(3, 2));
    let tStaM = parseInt(tueTimeStart.substr(3, 2));

    let tueHrs = (tEndH - tStaH) * 60;
    let tueMins = tEndM - tStaM;
    let tueOutput = 0;
    let tueHrsPreMidnight = 0;
    let tueMinsPreMidnight = 0;
    let tueHrsPostMidnight = 0;
    let tueMinsPostMidnight = 0;
    let tueTotalNightMins = 0;
    // if the shift crosses midnight
    if (tEndH < tStaH) {
      // min pre midnight
      tueHrsPreMidnight = (24 - tStaH) * 60;
      tueMinsPreMidnight = tueHrsPreMidnight - tStaM;
      // min post midnight
      tueHrsPostMidnight = tEndH * 60; // hours in minutes after midnight
      tueMinsPostMidnight = parseInt(tEndM) + tueHrsPostMidnight;
      tueOutput = tueMinsPreMidnight + tueMinsPostMidnight - tBrk;
    } else {
      tueOutput = tueHrs + tueMins - tBrk;
    }

    //wedOutput = total mins of shift
    let WEndH = parseInt(wedTimeEnd.substr(0, 2));
    let WStaH = parseInt(wedTimeStart.substr(0, 2));
    let WEndM = parseInt(wedTimeEnd.substr(3, 2));
    let WStaM = parseInt(wedTimeStart.substr(3, 2));

    let wedHrs = (WEndH - WStaH) * 60;
    let wedMins = WEndM - WStaM;
    let wedOutput = 0;
    let wedHrsPreMidnight = 0;
    let wedMinsPreMidnight = 0;
    let wedHrsPostMidnight = 0;
    let wedMinsPostMidnight = 0;
    let wedTotalNightMins = 0;
    // if the shift crosses midnight
    if (WEndH < WStaH) {
      // min pre midnight
      wedHrsPreMidnight = (24 - WStaH) * 60;
      wedMinsPreMidnight = wedHrsPreMidnight - WStaM;
      // min post midnight
      wedHrsPostMidnight = WEndH * 60; // hours in minutes after midnight
      wedMinsPostMidnight = parseInt(WEndM) + wedHrsPostMidnight;
      wedOutput = wedMinsPreMidnight + wedMinsPostMidnight - WBrk;
    } else {
      wedOutput = wedHrs + wedMins - WBrk;
    }

    //thuOutput = total mins of shift
    let thEndH = parseInt(thuTimeEnd.substr(0, 2));
    let thStaH = parseInt(thuTimeStart.substr(0, 2));
    let thEndM = parseInt(thuTimeEnd.substr(3, 2));
    let thStaM = parseInt(thuTimeStart.substr(3, 2));

    let thuHrs = (thEndH - thStaH) * 60;
    let thuMins = thEndM - thStaM;
    let thuOutput = 0;
    let thuHrsPreMidnight = 0;
    let thuMinsPreMidnight = 0;
    let thuHrsPostMidnight = 0;
    let thuMinsPostMidnight = 0;
    let thuTotalNightMins = 0;
    // if the shift crosses midnight
    if (thEndH < thStaH) {
      // min pre midnight
      thuHrsPreMidnight = (24 - thStaH) * 60;
      thuMinsPreMidnight = thuHrsPreMidnight - thStaM;
      // min post midnight
      thuHrsPostMidnight = thEndH * 60; // hours in minutes after midnight
      thuMinsPostMidnight = parseInt(thEndM) + thuHrsPostMidnight;
      thuOutput = thuMinsPreMidnight + thuMinsPostMidnight - thBrk;
    } else {
      thuOutput = thuHrs + thuMins - thBrk;
    }

    //friOutput = total mins of shift
    let fEndH = parseInt(friTimeEnd.substr(0, 2));
    let fStaH = parseInt(friTimeStart.substr(0, 2));
    let fEndM = parseInt(friTimeEnd.substr(3, 2));
    let fStaM = parseInt(friTimeStart.substr(3, 2));

    let friHrs = (fEndH - fStaH) * 60;
    let friMins = fEndM - fStaM;
    let friOutput = 0;
    let friHrsPreMidnight = 0;
    let friMinsPreMidnight = 0;
    let friHrsPostMidnight = 0;
    let friMinsPostMidnight = 0;
    let friTotalNightMins = 0;
    // if the shift crosses midnight
    if (fEndH < fStaH) {
      // min pre midnight
      friHrsPreMidnight = (24 - fStaH) * 60;
      friMinsPreMidnight = friHrsPreMidnight - fStaM;
      // min post midnight
      friHrsPostMidnight = fEndH * 60; // hours in minutes after midnight
      friMinsPostMidnight = parseInt(fEndM) + friHrsPostMidnight;
      friOutput = friMinsPreMidnight + friMinsPostMidnight - fBrk;
    } else {
      friOutput = friHrs + friMins - fBrk;
    }

    //satOutput = total mins of shift
    let sEndH = parseInt(satTimeEnd.substr(0, 2));
    let sStaH = parseInt(satTimeStart.substr(0, 2));
    let sEndM = parseInt(satTimeEnd.substr(3, 2));
    let sStaM = parseInt(satTimeStart.substr(3, 2));

    let satHrs = (sEndH - sStaH) * 60;
    let satMins = sEndM - sStaM;
    let satOutput = 0;
    let satHrsPreMidnight = 0;
    let satMinsPreMidnight = 0;
    let satHrsPostMidnight = 0;
    let satMinsPostMidnight = 0;
    let satTotalNightMins = 0;
    // if the shift crosses midnight
    if (sEndH < sStaH) {
      // min pre midnight
      satHrsPreMidnight = (24 - sStaH) * 60;
      satMinsPreMidnight = satHrsPreMidnight - sStaM;
      // min post midnight
      satHrsPostMidnight = sEndH * 60; // hours in minutes after midnight
      satMinsPostMidnight = parseInt(sEndM) + satHrsPostMidnight;
      satOutput = satMinsPreMidnight + satMinsPostMidnight - sBrk;
    } else {
      satOutput = satHrs + satMins - sBrk;
    }

    //sunOutput = total mins of shift
    let suEndH = parseInt(sunTimeEnd.substr(0, 2));
    let suStaH = parseInt(sunTimeStart.substr(0, 2));
    let suEndM = parseInt(sunTimeEnd.substr(3, 2));
    let suStaM = parseInt(sunTimeStart.substr(3, 2));

    let sunHrs = (suEndH - suStaH) * 60;
    let sunMins = suEndM - suStaM;
    let sunOutput = 0;
    let sunHrsPreMidnight = 0;
    let sunMinsPreMidnight = 0;
    let sunHrsPostMidnight = 0;
    let sunMinsPostMidnight = 0;
    let sunTotalNightMins = 0;
    // if the shift crosses midnight
    if (suEndH < suStaH) {
      // min pre midnight
      sunHrsPreMidnight = (24 - suStaH) * 60;
      sunMinsPreMidnight = sunHrsPreMidnight - suStaM;
      // min post midnight
      sunHrsPostMidnight = suEndH * 60; // hours in minutes after midnight
      sunMinsPostMidnight = parseInt(suEndM) + sunHrsPostMidnight;
      sunOutput = sunMinsPreMidnight + sunMinsPostMidnight - suBrk;
    } else {
      sunOutput = sunHrs + sunMins - suBrk;
    }

    let totMinutes = 0;
    if (monTimeStart && monTimeEnd) {
      totMinutes += monOutput;
    }
    if (tueTimeStart && tueTimeEnd) {
      totMinutes += tueOutput;
    }
    if (wedTimeStart && wedTimeEnd) {
      totMinutes += wedOutput;
    }
    if (thuTimeStart && thuTimeEnd) {
      totMinutes += thuOutput;
    }
    if (friTimeStart && friTimeEnd) {
      totMinutes += friOutput;
    }
    if (satTimeStart && satTimeEnd) {
      totMinutes += satOutput;
    }
    if (sunTimeStart && sunTimeEnd) {
      totMinutes += sunOutput;
    }
    // let combinedMinutes = (parseInt(monOutput) + parseInt(tueOutput) + parseInt(wedOutput) + parseInt(thuOutput) + parseInt(friOutput) + parseInt(satOutput) + parseInt(sunOutput));

    return totMinutes;
  };

  const minsTotal = calcTotalMinsForRequest();

  let schedOdd = () => {
    let mEndH = parseInt(monTimeEnd.substr(0, 2));
    let mStaH = parseInt(monTimeStart.substr(0, 2));
    let mEndM = parseInt(monTimeEnd.substr(3, 2));
    let mStaM = parseInt(monTimeStart.substr(3, 2));
    let tEndH = parseInt(tueTimeEnd.substr(0, 2));

    let tStaH = parseInt(tueTimeStart.substr(0, 2));
    let tEndM = parseInt(tueTimeEnd.substr(3, 2));
    let tStaM = parseInt(tueTimeStart.substr(3, 2));

    let WEndH = parseInt(wedTimeEnd.substr(0, 2));
    let WStaH = parseInt(wedTimeStart.substr(0, 2));
    let WEndM = parseInt(wedTimeEnd.substr(3, 2));
    let WStaM = parseInt(wedTimeStart.substr(3, 2));

    let thEndH = parseInt(thuTimeEnd.substr(0, 2));
    let thStaH = parseInt(thuTimeStart.substr(0, 2));
    let thEndM = parseInt(thuTimeEnd.substr(3, 2));
    let thStaM = parseInt(thuTimeStart.substr(3, 2));

    let fEndH = parseInt(friTimeEnd.substr(0, 2));
    let fStaH = parseInt(friTimeStart.substr(0, 2));
    let fEndM = parseInt(friTimeEnd.substr(3, 2));
    let fStaM = parseInt(friTimeStart.substr(3, 2));

    let sEndH = parseInt(satTimeEnd.substr(0, 2));
    let sStaH = parseInt(satTimeStart.substr(0, 2));
    let sEndM = parseInt(satTimeEnd.substr(3, 2));
    let sStaM = parseInt(satTimeStart.substr(3, 2));

    let suEndH = parseInt(sunTimeEnd.substr(0, 2));
    let suStaH = parseInt(sunTimeStart.substr(0, 2));
    let suEndM = parseInt(sunTimeEnd.substr(3, 2));
    let suStaM = parseInt(sunTimeStart.substr(3, 2));

    let array = [];
    if (monTimeStart && monTimeEnd) {
      let monHrs = (mEndH - mStaH) * 60;
      let monMins = mEndM - mStaM;
      let monOutput = 0;
      let monHrsPreMidnight = 0;
      let monMinsPreMidnight = 0;
      let monHrsPostMidnight = 0;
      let monMinsPostMidnight = 0;
      let monTotalNightMins = 0;
      // if the shift crosses midnight
      if (mEndH < mStaH) {
        // min pre midnight
        monHrsPreMidnight = (24 - mStaH) * 60;
        monMinsPreMidnight = monHrsPreMidnight - mStaM;
        // min post midnight
        monHrsPostMidnight = mEndH * 60; // hours in minutes after midnight
        monMinsPostMidnight = mEndM + monHrsPostMidnight;
        monOutput = monMinsPreMidnight + monMinsPostMidnight - mBrk;
      } else {
        monOutput = monHrs + monMins - mBrk;
      }
      array.push(monOutput);
    }
    if (tueTimeStart && tueTimeEnd) {
      let tueHrs = (tEndH - tStaH) * 60;
      let tueMins = tEndM - tStaM;
      let tueOutput = 0;
      let tueHrsPreMidnight = 0;
      let tueMinsPreMidnight = 0;
      let tueHrsPostMidnight = 0;
      let tueMinsPostMidnight = 0;
      let tueTotalNightMins = 0;
      // if the shift crosses midnight
      if (tEndH < tStaH) {
        // min pre midnight
        tueHrsPreMidnight = (24 - tStaH) * 60;
        tueMinsPreMidnight = tueHrsPreMidnight - tStaM;
        // min post midnight
        tueHrsPostMidnight = tEndH * 60; // hours in minutes after midnight
        tueMinsPostMidnight = parseInt(tEndM) + tueHrsPostMidnight;
        tueOutput = tueMinsPreMidnight + tueMinsPostMidnight - tBrk;
      } else {
        tueOutput = tueHrs + tueMins - tBrk;
      }
      array.push(tueOutput);
    }
    if (wedTimeStart && wedTimeEnd) {
      let wedHrs = (WEndH - WStaH) * 60;
      let wedMins = WEndM - WStaM;
      let wedOutput = 0;
      let wedHrsPreMidnight = 0;
      let wedMinsPreMidnight = 0;
      let wedHrsPostMidnight = 0;
      let wedMinsPostMidnight = 0;
      let wedTotalNightMins = 0;
      // if the shift crosses midnight
      if (WEndH < WStaH) {
        // min pre midnight
        wedHrsPreMidnight = (24 - WStaH) * 60;
        wedMinsPreMidnight = wedHrsPreMidnight - WStaM;
        // min post midnight
        wedHrsPostMidnight = WEndH * 60; // hours in minutes after midnight
        wedMinsPostMidnight = parseInt(WEndM) + wedHrsPostMidnight;
        wedOutput = wedMinsPreMidnight + wedMinsPostMidnight - WBrk;
      } else {
        wedOutput = wedHrs + wedMins - WBrk;
      }
      array.push(wedOutput);
    }
    if (thuTimeStart && thuTimeEnd) {
      let thuHrs = (thEndH - thStaH) * 60;
      let thuMins = thEndM - thStaM;
      let thuOutput = 0;
      let thuHrsPreMidnight = 0;
      let thuMinsPreMidnight = 0;
      let thuHrsPostMidnight = 0;
      let thuMinsPostMidnight = 0;
      let thuTotalNightMins = 0;
      // if the shift crosses midnight
      if (thEndH < thStaH) {
        // min pre midnight
        thuHrsPreMidnight = (24 - thStaH) * 60;
        thuMinsPreMidnight = thuHrsPreMidnight - thStaM;
        // min post midnight
        thuHrsPostMidnight = thEndH * 60; // hours in minutes after midnight
        thuMinsPostMidnight = parseInt(thEndM) + thuHrsPostMidnight;
        thuOutput = thuMinsPreMidnight + thuMinsPostMidnight - thBrk;
      } else {
        thuOutput = thuHrs + thuMins - thBrk;
      }
      array.push(thuOutput);
    }
    if (friTimeStart && friTimeEnd) {
      let friHrs = (fEndH - fStaH) * 60;
      let friMins = fEndM - fStaM;
      let friOutput = 0;
      let friHrsPreMidnight = 0;
      let friMinsPreMidnight = 0;
      let friHrsPostMidnight = 0;
      let friMinsPostMidnight = 0;
      let friTotalNightMins = 0;
      // if the shift crosses midnight
      if (fEndH < fStaH) {
        // min pre midnight
        friHrsPreMidnight = (24 - fStaH) * 60;
        friMinsPreMidnight = friHrsPreMidnight - fStaM;
        // min post midnight
        friHrsPostMidnight = fEndH * 60; // hours in minutes after midnight
        friMinsPostMidnight = parseInt(fEndM) + friHrsPostMidnight;
        friOutput = friMinsPreMidnight + friMinsPostMidnight - fBrk;
      } else {
        friOutput = friHrs + friMins - fBrk;
      }
      array.push(friOutput);
    }
    if (satTimeStart && satTimeEnd) {
      let satHrs = (sEndH - sStaH) * 60;
      let satMins = sEndM - sStaM;
      let satOutput = 0;
      let satHrsPreMidnight = 0;
      let satMinsPreMidnight = 0;
      let satHrsPostMidnight = 0;
      let satMinsPostMidnight = 0;
      let satTotalNightMins = 0;
      // if the shift crosses midnight
      if (sEndH < sStaH) {
        // min pre midnight
        satHrsPreMidnight = (24 - sStaH) * 60;
        satMinsPreMidnight = satHrsPreMidnight - sStaM;
        // min post midnight
        satHrsPostMidnight = sEndH * 60; // hours in minutes after midnight
        satMinsPostMidnight = parseInt(sEndM) + satHrsPostMidnight;
        satOutput = satMinsPreMidnight + satMinsPostMidnight - sBrk;
      } else {
        satOutput = satHrs + satMins - sBrk;
      }
      array.push(satOutput);
    }
    if (sunTimeStart && sunTimeEnd) {
      let sunHrs = (suEndH - suStaH) * 60;
      let sunMins = suEndM - suStaM;
      let sunOutput = 0;
      let sunHrsPreMidnight = 0;
      let sunMinsPreMidnight = 0;
      let sunHrsPostMidnight = 0;
      let sunMinsPostMidnight = 0;
      let sunTotalNightMins = 0;
      // if the shift crosses midnight
      if (suEndH < suStaH) {
        // min pre midnight
        sunHrsPreMidnight = (24 - suStaH) * 60;
        sunMinsPreMidnight = sunHrsPreMidnight - suStaM;
        // min post midnight
        sunHrsPostMidnight = suEndH * 60; // hours in minutes after midnight
        sunMinsPostMidnight = parseInt(suEndM) + sunHrsPostMidnight;
        sunOutput = sunMinsPreMidnight + sunMinsPostMidnight - suBrk;
      } else {
        sunOutput = sunHrs + sunMins - suBrk;
      }

      array.push(sunOutput);
    }

    function allEqual(arr) {
      return new Set(arr).size == 1;
    }

    return !allEqual(array);
  };

  let fixedSchedOdd = schedOdd();

  let shortenTeamName = (val) => {
    if (val.length > 19) {
      return `${val.substr(0, 18)}...`;
    } else {
      return val;
    }
  };

  // dropdown for the teams series page
  let teamsDropdown = () => {
    return (
      <div className="pTeamDropdownContainer">
        <select
          id="addUserTeamSelectDropdown"
          className="addUserPrimaryTeamDropdown"
          onChange={(e) => {
            setPrimaryTeam(e.target.value);
          }}
        >
          {memoVals.allTeams.map((item) => {
            let convertTeamless = (team) => {
              if (team === "Teamless") {
                return "Select team";
              } else {
                return team;
              }
            };
            return (
              <option key={item._id} value={item.teamID}>
                {shortenTeamName(convertTeamless(item.teamName))}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  let dateObjForStartDate = new Date(datepickerStartDate);

  let inputtedMonthRaw = dateObjForStartDate.getMonth();
  let inputtedMonth = "";
  switch (inputtedMonthRaw) {
    case 0:
      inputtedMonth = "January";
      break;
    case 1:
      inputtedMonth = "February";
      break;
    case 2:
      inputtedMonth = "March";
      break;
    case 3:
      inputtedMonth = "April";
      break;
    case 4:
      inputtedMonth = "May";
      break;
    case 5:
      inputtedMonth = "June";
      break;
    case 6:
      inputtedMonth = "July";
      break;
    case 7:
      inputtedMonth = "August";
      break;
    case 8:
      inputtedMonth = "September";
      break;
    case 9:
      inputtedMonth = "October";
      break;
    case 10:
      inputtedMonth = "November";
      break;
    case 11:
      inputtedMonth = "December";
      break;
  }
  let inputtedYr = dateObjForStartDate.getFullYear();

  // unique email warning
  let [emailTaken, setEmailTaken] = useState(false);

  useEffect(() => {
    clearState();
  }, []);

  let prorateStartDate = (startDate, dataOnly) => {
    let formatDate = (val) => {
      let dateObj = new Date(val);
      let day = dateObj.getDate();
      let year = `'${JSON.stringify(dateObj.getFullYear()).substr(2, 2)}`;
      let m = "";
      let mPre = dateObj.getMonth();
      if (mPre === 0) {
        m = "Jan";
      } else if (mPre === 1) {
        m = "Feb";
      } else if (mPre === 2) {
        m = "Mar";
      } else if (mPre === 3) {
        m = "Apr";
      } else if (mPre === 4) {
        m = "May";
      } else if (mPre === 5) {
        m = "Jun";
      } else if (mPre === 6) {
        m = "Jul";
      } else if (mPre === 7) {
        m = "Aug";
      } else if (mPre === 8) {
        m = "Sep";
      } else if (mPre === 9) {
        m = "Oct";
      } else if (mPre === 10) {
        m = "Nov";
      } else if (mPre === 11) {
        m = "Dec";
      }
      return `${day} ${m} ${year}`;
    };

    if (startDate > memoVals.currYrStartTs) {
      return dataOnly ? startDate : formatDate(startDate);
    } else {
      let refPeriodDateObj = new Date();
      refPeriodDateObj.setHours(0, 0, 0, 0);
      refPeriodDateObj.setDate(refPeriodDateObj.getDate() - refPeriod * 7);
      if (startDate <= refPeriodDateObj.getTime()) {
        return dataOnly
          ? refPeriodDateObj.getTime()
          : formatDate(refPeriodDateObj.getTime());
      } else {
        return dataOnly ? startDate : formatDate(startDate);
      }
    }
  };

  return (
    <div>
      {/* INITIAL */}
      <div
        className={`${
          memoVals.addUserPage === "initial" ? "addUserInputFrame" : "none"
        }`}
      >
        <p className="addNewUserTitle">Add new user</p>
        <img
          src={bluePlus}
          className="addPlusUser"
          onClick={() => {
            clearState();
            memoVals.setAddUserPage((x) => "fName");
          }}
        />

        <img
          className="addUserCancelImg"
          src={cancel}
          alt="Cancel"
          onClick={() => {
            memoVals.setShowAddUserModal((x) => false);
            memoVals.setAddUserPage((x) => "initial");
          }}
        />
      </div>

      {/* FNAME */}
      <div
        className={`${
          memoVals.addUserPage === "fName" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">Their first name</p>
          <input
            id="addUserfName"
            className="addUserInput"
            onChange={(e) => {
              setFName(e.target.value);
            }}
          ></input>
          <div className="backNextContainer">
            <p className="addUserBackInvisible">Back</p>
            <p
              className={`${
                fName.length > 1
                  ? "addUserNextBtn nextBtnReady"
                  : "addUserNextBtn"
              }`}
              onClick={() => {
                memoVals.setAddUserPage((x) => "lName");
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* LNAME */}
      <div
        className={`${
          memoVals.addUserPage === "lName" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">
            {fName}
            {`${fName.charAt(fName.length - 1) === "s" ? "'" : "'s"}`} last name
          </p>
          <input
            id="addUserlName"
            className="addUserInput"
            onChange={(e) => {
              setLName(e.target.value);
            }}
          ></input>
          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "fName");
              }}
            >
              Back
            </p>
            <p
              className={`${
                lName.length > 1
                  ? "addUserNextBtn nextBtnReady"
                  : "addUserNextBtn"
              }`}
              onClick={() => {
                memoVals.setAddUserPage((x) => "email");
              }}
            >
              Next
            </p>
            <p className="addUserBack addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* EMAIL */}
      <div
        className={`${
          memoVals.addUserPage === "email" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">
            {fName}
            {`${fName.charAt(fName.length - 1) === "s" ? "'" : "'s"}`} email
          </p>
          <input
            id="addUserEmail"
            className="addUserInput"
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
          <div className="backNextContainer">
            <p className={`${emailTaken ? "addUserEmailExists" : "none"}`}>
              Email already exists
            </p>
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "lName");
              }}
            >
              Back
            </p>
            <p
              className={`${
                email.length > 1
                  ? "addUserNextBtn nextBtnReady"
                  : "addUserNextBtn"
              }`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/check-email-unique`,
                    { email: email },
                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "unique") {
                      memoVals.setAddUserPage((x) => "primaryTeam");
                      axios
                        .get(`${serverURL}/get-teamless-teamid`, {
                          withCredentials: true,
                          credentials: "include",
                        })
                        .then((response) =>
                          setPrimaryTeam(response.data.teamlessTeamId)
                        )
                        .catch((err) => {
                          console.error(err);
                        });
                    } else if (response.data.message === "not unique") {
                      setEmailTaken(true);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* PRIMARYTEAM */}
      <div
        className={`${
          memoVals.addUserPage === "primaryTeam" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">
            {fName}
            {`${fName.charAt(fName.length - 1) === "s" ? "'" : "'s"}`} primary
            team
          </p>
          {teamsDropdown()}
          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "email");
              }}
            >
              Back
            </p>
            <p
              className="addUserNextBtn nextBtnReady"
              onClick={() => {
                memoVals.setAddUserPage((x) => "startDate");
              }}
            >
              Next
            </p>
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "startDate");
              }}
            >
              Skip
            </p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* STARTDATE */}
      <div
        className={`${
          memoVals.addUserPage === "startDate" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle pullUpABitAddUserTxt">
            {fName}
            {`${fName.charAt(fName.length - 1) === "s" ? "'" : "'s"}`} start
            date
          </p>
          <p className="importantStartDateStrap">
            Enter the day {fName} joined {memoVals.companyName}.
          </p>

          <div className="dateInputterForStartDate">
            <input
              type="date"
              className="addShiftDateInput userStartDateAddUserInput"
              id="addShiftDateInput"
              defaultValue={userJoinDate}
              onChange={(e) => {
                setUserJoinDate(e.target.value);
              }}
            ></input>
          </div>

          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "primaryTeam");
                memoVals.setAddUserModalCalOpen((x) => false);
              }}
            >
              Back
            </p>
            <p
              className="addUserNextBtn nextBtnReady"
              onClick={() => {
                memoVals.setAddUserPage((x) => "reqClockOut");
                memoVals.setAddUserModalCalOpen((x) => false);

                let joinDateTs = convertDsToTs(userJoinDate);
                let currYrBegin = memoVals.currYrStartTs;
                let daysSinceRenewal = (todayTs - currYrBegin) / 86400000;
                let daysBetweenJoinAndYearStart =
                  (joinDateTs - currYrBegin) / 86400000;

                if (joinDateTs < currYrBegin) {
                  daysBetweenJoinAndYearStart =
                    (currYrBegin - joinDateTs) / 86400000;
                }

                let daysBetweenTodayAndJoinDate = 0;
                if (joinDateTs < todayTs) {
                  daysBetweenTodayAndJoinDate =
                    (todayTs - joinDateTs) / 86400000;
                  if (daysBetweenTodayAndJoinDate < 7) {
                    setStartsInFuture(true);
                  } else {
                    setStartsInFuture(false);
                  }
                } else {
                  daysBetweenTodayAndJoinDate =
                    (joinDateTs - todayTs) / 86400000;
                  setStartsInFuture(true);
                }

                setStartDate(joinDateTs);

                // if start date is 7 days after last yearStart, pro-rated is due
                if (daysBetweenJoinAndYearStart >= 7) {
                  setProRatedDue(true);
                } else {
                  // setProRatedDue(false)
                }

                // if today is not within 7 days of renewal && days between join date and today is >7, pro-rate is not due
                if (daysSinceRenewal > 7 && daysBetweenTodayAndJoinDate >= 7) {
                  setProRatedDue(true);
                } else {
                }
              }}
            >
              Next
            </p>
            <p
              className="addUserBackInvisible"
              onClick={() => {
                memoVals.setAddUserModalCalOpen((x) => false);
              }}
            >
              Skip
            </p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* REQCLOCKOUT */}
      <div
        className={`${
          memoVals.addUserPage === "reqClockOut" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">Should {fName} clock in/out?</p>

          {/*  KNOB BEGIN */}
          <div className="reqClockOutKnobContainer">
            <p
              className={`${
                reqClockOut
                  ? "reqClockOutN"
                  : "reqClockOutN reqClockOutNSelected"
              }`}
              onClick={() => {
                setReqClockOut(false);
              }}
            >
              No
            </p>
            <div
              className="knobContainer clockOutKnob"
              onClick={() => {
                setReqClockOut(!reqClockOut);
              }}
            >
              <div
                className={`switchKnob ${reqClockOut ? "knobOn" : "knobOff"}`}
              ></div>
              <div className="knobBg"></div>
            </div>
            <p
              className={`${
                !reqClockOut
                  ? "reqClockOutY"
                  : "reqClockOutY reqClockOutYSelected"
              }`}
              onClick={() => {
                setReqClockOut(true);
              }}
            >
              Yes
            </p>
          </div>
          {/*  KNOB END */}

          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "startDate");
              }}
            >
              Back
            </p>
            <p
              className="addUserNextBtn nextBtnReady"
              onClick={() => {
                if (startsInFuture) {
                  memoVals.setAddUserPage((x) => "workingPattern");
                } else {
                  memoVals.setAddUserPage((x) => "prorated");
                }
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* PRORATED HPW  */}
      <div
        className={`${
          memoVals.addUserPage === "prorated" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          {/* <p className="addUserInputTitle">Hours per week up to now</p> */}
          <p className="importantStartDateStrap proratedhpwinstructions">
            How many hours per week, on average, has {fName} worked since{" "}
            <span className="dateBolded">{prorateStartDate(startDate)}</span>?
          </p>

          <div className="proHPWContainer">
            <div className="proHPWUnit">
              <input
                className="variedNumberInput"
                onChange={(e) => {
                  setProHpwHours(e.target.value);
                }}
                value={proHpwHours}
                type="number"
              ></input>
              <p className="proHPWTxt"> hours</p>
            </div>
            <div className="proHPWUnit">
              <input
                className={`${
                  proHpwMins > 59
                    ? "variedNumberInput over59input"
                    : "variedNumberInput"
                }`}
                onChange={(e) => {
                  setProHpwMins(e.target.value);
                }}
                value={proHpwMins}
                type="number"
              ></input>
              <p className="proHPWTxt"> mins</p>
            </div>
          </div>

          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "reqClockOut");
              }}
            >
              Back
            </p>
            <p
              className="addUserNextBtn nextBtnReady"
              onClick={() => {
                memoVals.setAddUserPage((x) => "workingPattern");
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* WORKINGPATTERN */}
      <div
        className={`${
          memoVals.addUserPage === "workingPattern"
            ? "addUserInputFrame"
            : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">On what basis does {fName} work?</p>

          {/* Casual or Fixed */}
          <div className="casFixContainer">
            <div className="casFixBase">
              <p
                className={`${
                  workingPattern === "zero"
                    ? "casFixBtn casFixSel"
                    : "casFixBtn casFixUnsel"
                }`}
                onClick={() => {
                  setWorkingPattern("zero");
                }}
              >
                Casual
              </p>
              <p
                className={`${
                  workingPattern === "fixed"
                    ? "casFixBtn casFixSel"
                    : "casFixBtn casFixUnsel"
                }`}
                onClick={() => {
                  setWorkingPattern("fixed");
                }}
              >
                &nbsp;Fixed hours&nbsp;
              </p>
            </div>
          </div>
          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "reqClockOut");
              }}
            >
              Back
            </p>
            <p
              className="addUserNextBtn nextBtnReady"
              onClick={() => {
                if (workingPattern === "fixed") {
                  memoVals.setAddUserPage((x) => "fixedHours");
                  memoVals.setAddUserModalCalOpen((x) => true);
                } else {
                  axios
                    .post(
                      `${serverURL}/add-new-user`,
                      {
                        fName: fName,
                        lName: lName,
                        email: email,
                        reqClockOn: reqClockOut,
                        primaryTeam: primaryTeam,
                        startDate:
                          prorateStartDate(startDate, true) || todayTimestamp,
                        workingPattern: "zero",
                        schedMinsTotal: 0,
                        prorated: proRatedDue,
                        proHpwHours: proHpwHours,
                        proHpwMins: proHpwMins,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data) {
                        axios
                          .post(
                            `${serverURL}/clean-teamless-data`,
                            { someData: 1 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    })
                    .then(() => {
                      // then re-load the admin users list
                      axios
                        .post(`${serverURL}/get-all-users`, {
                          withCredentials: true,
                          credentials: "include",
                        })
                        .then((response) =>
                          memoVals.setAdminUsersArray(
                            (x) => response.data.usersArray
                          )
                        )
                        .then(() => {
                          memoVals.setShowAddUserModal((x) => false);
                          memoVals.setAddUserModalCalOpen((x) => false);
                          memoVals.setAddUserPage((x) => "initial");
                          clearState();
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/*  IF FIXED */}

      {/* SAME HOURS EACH WEEK? */}
      <div
        className={`${
          memoVals.addUserPage === "fixedHours" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">
            {fName}
            {`${fName.charAt(fName.length - 1) === "s" ? "'" : "'s"}`} schedule
          </p>

          {/* Casual or Fixed */}
          <div className="casFixContainer">
            <div className="upperSchedContainer">
              <div className="checkboxSide">
                <img
                  src={`${fadeSched ? checkbox2 : checkbox1}`}
                  className="fadeToggleButton"
                  onClick={() => {
                    setFadeSched(!fadeSched);
                    setFixedVar(!fixedVar);
                  }}
                ></img>
                <p
                  className="fixedVariedSelect"
                  onClick={() => {
                    setFadeSched(!fadeSched);
                    setFixedVar(!fixedVar);
                  }}
                >
                  {fName}
                  {`${
                    fName.charAt(fName.length - 1) === "s" ? "'" : "'s"
                  }`}{" "}
                  schedule varies each week
                </p>
              </div>
              <div className="hoursCalcSide">
                <div
                  className={`${
                    fadeSched
                      ? "totalHrsAddUser schedInputFaded"
                      : "totalHrsAddUser"
                  }`}
                  onClick={() => {}}
                >
                  {calcTotalHrs()}
                </div>
              </div>
            </div>
          </div>

          {/* Daily hours inputter */}

          <div
            className={`${
              fadeSched
                ? "schedHrsInputContainer schedInputFaded"
                : "schedHrsInputContainer"
            }`}
          >
            <div
              className={`${
                monTimeStart && monTimeEnd ? "dayPopulated" : "dayRow"
              }`}
            >
              <div className="schedHrsRow">
                <p className="schedWkDay">Mon</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">START</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={monTimeStart}
                    type="time"
                    onChange={(e) => {
                      setMonTimeStart(e.target.value);
                    }}
                  ></input>
                </div>
                <p className="startFinDivider">&nbsp;</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">END</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={monTimeEnd}
                    type="time"
                    onChange={(e) => {
                      setMonTimeEnd(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="brkRow">
                <p className="breakTitleTxt">Break</p>
                <input
                  className="brkInputMins"
                  placeholder="0"
                  onChange={(e) => {
                    setMBrk(e.target.value);
                  }}
                ></input>
                <p className="minsSuffix">&nbsp;mins</p>
              </div>
            </div>

            <div
              className={`${
                tueTimeStart && tueTimeEnd ? "dayPopulated" : "dayRow"
              }`}
            >
              <div className="schedHrsRow">
                <p className="schedWkDay">Tue</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">START</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={tueTimeStart}
                    type="time"
                    onChange={(e) => {
                      setTueTimeStart(e.target.value);
                    }}
                  ></input>
                </div>
                <p className="startFinDivider">&nbsp;</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">END</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={tueTimeEnd}
                    type="time"
                    onChange={(e) => {
                      setTueTimeEnd(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="brkRow">
                <p className="breakTitleTxt">Break</p>
                <input
                  className="brkInputMins"
                  placeholder="0"
                  onChange={(e) => {
                    setTBrk(e.target.value);
                  }}
                ></input>
                <p className="minsSuffix">&nbsp;mins</p>
              </div>
            </div>

            <div
              className={`${
                wedTimeStart && wedTimeEnd ? "dayPopulated" : "dayRow"
              }`}
            >
              <div className="schedHrsRow">
                <p className="schedWkDay">Wed</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">START</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={wedTimeStart}
                    type="time"
                    onChange={(e) => {
                      setWedTimeStart(e.target.value);
                    }}
                  ></input>
                </div>
                <p className="startFinDivider">&nbsp;</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">END</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={wedTimeEnd}
                    type="time"
                    onChange={(e) => {
                      setWedTimeEnd(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="brkRow">
                <p className="breakTitleTxt">Break</p>
                <input
                  className="brkInputMins"
                  placeholder="0"
                  onChange={(e) => {
                    setWBrk(e.target.value);
                  }}
                ></input>
                <p className="minsSuffix">&nbsp;mins</p>
              </div>
            </div>

            <div
              className={`${
                thuTimeStart && thuTimeEnd ? "dayPopulated" : "dayRow"
              }`}
            >
              <div className="schedHrsRow">
                <p className="schedWkDay">Thurs</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">START</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={thuTimeStart}
                    type="time"
                    onChange={(e) => {
                      setThuTimeStart(e.target.value);
                    }}
                  ></input>
                </div>
                <p className="startFinDivider">&nbsp;</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">END</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={thuTimeEnd}
                    type="time"
                    onChange={(e) => {
                      setThuTimeEnd(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="brkRow">
                <p className="breakTitleTxt">Break</p>
                <input
                  className="brkInputMins"
                  placeholder="0"
                  onChange={(e) => {
                    setThBrk(e.target.value);
                  }}
                ></input>
                <p className="minsSuffix">&nbsp;mins</p>
              </div>
            </div>

            <div
              className={`${
                friTimeStart && friTimeEnd ? "dayPopulated" : "dayRow"
              }`}
            >
              <div className="schedHrsRow">
                <p className="schedWkDay">Fri</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">START</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={friTimeStart}
                    type="time"
                    onChange={(e) => {
                      setFriTimeStart(e.target.value);
                    }}
                  ></input>
                </div>
                <p className="startFinDivider">&nbsp;</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">END</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={friTimeEnd}
                    type="time"
                    onChange={(e) => {
                      setFriTimeEnd(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="brkRow">
                <p className="breakTitleTxt">Break</p>
                <input
                  className="brkInputMins"
                  placeholder="0"
                  onChange={(e) => {
                    setFBrk(e.target.value);
                  }}
                ></input>
                <p className="minsSuffix">&nbsp;mins</p>
              </div>
            </div>

            <div
              className={`${
                satTimeStart && satTimeEnd ? "dayPopulated" : "dayRow"
              }`}
            >
              <div className="schedHrsRow">
                <p className="schedWkDay">Sat</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">START</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={satTimeStart}
                    type="time"
                    onChange={(e) => {
                      setSatTimeStart(e.target.value);
                    }}
                  ></input>
                </div>
                <p className="startFinDivider">&nbsp;</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">END</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={satTimeEnd}
                    type="time"
                    onChange={(e) => {
                      setSatTimeEnd(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="brkRow">
                <p className="breakTitleTxt">Break</p>
                <input
                  className="brkInputMins"
                  placeholder="0"
                  onChange={(e) => {
                    setSBrk(e.target.value);
                  }}
                ></input>
                <p className="minsSuffix">&nbsp;mins</p>
              </div>
            </div>

            <div
              className={`${
                sunTimeStart && sunTimeEnd ? "dayPopulated" : "dayRow"
              }`}
            >
              <div className="schedHrsRow">
                <p className="schedWkDay">Sun</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">START</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={sunTimeStart}
                    type="time"
                    onChange={(e) => {
                      setSunTimeStart(e.target.value);
                    }}
                  ></input>
                </div>
                <p className="startFinDivider">&nbsp;</p>
                <div className="timeInputDiv">
                  <p className="timeInputTxt">END</p>
                  <input
                    className="shiftTimeInput"
                    id="shiftBrkInput"
                    value={sunTimeEnd}
                    type="time"
                    onChange={(e) => {
                      setSunTimeEnd(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="brkRow">
                <p className="breakTitleTxt">Break</p>
                <input
                  className="brkInputMins"
                  placeholder="0"
                  onChange={(e) => {
                    setSuBrk(e.target.value);
                  }}
                ></input>
                <p className="minsSuffix">&nbsp;mins</p>
              </div>
            </div>
          </div>

          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "workingPattern");
                memoVals.setAddUserModalCalOpen((x) => false);
              }}
            >
              Back
            </p>
            <p
              className="addUserNextBtn nextBtnReady"
              onClick={() => {
                if (fixedVar) {
                  memoVals.setAddUserPage((x) => "varied");
                  memoVals.setAddUserModalCalOpen((x) => false);
                } else {
                  // request for fixedScheduled
                  axios
                    .post(
                      `${serverURL}/add-new-user`,
                      {
                        fName: fName,
                        lName: lName,
                        startDate:
                          prorateStartDate(startDate, true) || todayTimestamp,
                        email: email,
                        reqClockOn: reqClockOut,
                        primaryTeam: primaryTeam,
                        workingPattern: "fixedScheduled",
                        fixSchMonStart: `${monTimeStart.substr(
                          0,
                          2
                        )}${monTimeStart.substr(3, 2)}`,
                        fixSchMonEnd: `${monTimeEnd.substr(
                          0,
                          2
                        )}${monTimeEnd.substr(3, 2)}`,
                        fixSchMonBrk: mBrk,
                        fixSchTueStart: `${tueTimeStart.substr(
                          0,
                          2
                        )}${tueTimeStart.substr(3, 2)}`,
                        fixSchTueEnd: `${tueTimeEnd.substr(
                          0,
                          2
                        )}${tueTimeEnd.substr(3, 2)}`,
                        fixSchTueBrk: tBrk,
                        fixSchWedStart: `${wedTimeStart.substr(
                          0,
                          2
                        )}${wedTimeStart.substr(3, 2)}`,
                        fixSchWedEnd: `${wedTimeEnd.substr(
                          0,
                          2
                        )}${wedTimeEnd.substr(3, 2)}`,
                        fixSchWedBrk: WBrk,
                        fixSchThuStart: `${thuTimeStart.substr(
                          0,
                          2
                        )}${thuTimeStart.substr(3, 2)}`,
                        fixSchThuEnd: `${thuTimeEnd.substr(
                          0,
                          2
                        )}${thuTimeEnd.substr(3, 2)}`,
                        fixSchThuBrk: thBrk,
                        fixSchFriStart: `${friTimeStart.substr(
                          0,
                          2
                        )}${friTimeStart.substr(3, 2)}`,
                        fixSchFriEnd: `${friTimeEnd.substr(
                          0,
                          2
                        )}${friTimeEnd.substr(3, 2)}`,
                        fixSchFriBrk: fBrk,
                        fixSchSatStart: `${satTimeStart.substr(
                          0,
                          2
                        )}${satTimeStart.substr(3, 2)}`,
                        fixSchSatEnd: `${satTimeEnd.substr(
                          0,
                          2
                        )}${satTimeEnd.substr(3, 2)}`,
                        fixSchSatBrk: sBrk,
                        fixSchSunStart: `${sunTimeStart.substr(
                          0,
                          2
                        )}${sunTimeStart.substr(3, 2)}`,
                        fixSchSunEnd: `${sunTimeEnd.substr(
                          0,
                          2
                        )}${sunTimeEnd.substr(3, 2)}`,
                        fixSchSunBrk: suBrk,
                        fixedSchedOdd: fixedSchedOdd,
                        schedMinsTotal: minsTotal,
                        prorated: proRatedDue,
                        proHpwHours: proHpwHours,
                        proHpwMins: proHpwMins,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data) {
                      }
                    })
                    .then(() => {
                      // then re-load the admin users list
                      axios
                        .post(`${serverURL}/get-all-users`, {
                          withCredentials: true,
                          credentials: "include",
                        })
                        .then((response) =>
                          memoVals.setAdminUsersArray(
                            (x) => response.data.usersArray
                          )
                        )
                        .then(() => {
                          memoVals.setShowAddUserModal((x) => false);
                          memoVals.setAddUserModalCalOpen((x) => false);
                          clearState();
                          memoVals.setAddUserPage((x) => "initial");
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* VARIED */}
      <div
        className={`${
          memoVals.addUserPage === "varied" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">
            {fName}
            {`${fName.charAt(fName.length - 1) === "s" ? "'" : "'s"}`}{" "}
            contracted hours per week
          </p>

          <div className="proHPWContainer">
            <div className="proHPWUnit">
              <input
                className="variedNumberInput"
                onChange={(e) => {
                  setFixedVarHrs(e.target.value);
                }}
                value={fixedVarHrs}
                type="number"
              ></input>
              <p className="proHPWTxt"> hours</p>
            </div>
            <div className="proHPWUnit">
              <input
                className={`${
                  fixedVarMins > 59
                    ? "variedNumberInput over59input"
                    : "variedNumberInput"
                }`}
                onChange={(e) => {
                  setFixedVarMins(e.target.value);
                }}
                value={fixedVarMins}
                type="number"
              ></input>
              <p className="proHPWTxt"> mins</p>
            </div>
          </div>

          <div className="backNextContainer">
            <p
              className="addUserBack"
              onClick={() => {
                memoVals.setAddUserPage((x) => "fixedHours");
                memoVals.setAddUserModalCalOpen((x) => true);
              }}
            >
              Back
            </p>
            <p
              className="addUserNextBtn nextBtnReady"
              onClick={() => {
                // request for fixedVar
                axios
                  .post(
                    `${serverURL}/add-new-user`,
                    {
                      fName: fName,
                      lName: lName,
                      startDate: startDate || todayTimestamp,
                      email: email,
                      reqClockOn: reqClockOut,
                      primaryTeam: primaryTeam,
                      workingPattern: "fixedVariable",
                      fixVarHrs: fixedVarHrs,
                      fixVarMins: fixedVarMins,
                      varMinsTotal: varMinsTotal,
                      schedMinsTotal: varMinsTotal,
                      prorated: proRatedDue,
                      proHpwHours: proHpwHours,
                      proHpwMins: proHpwMins,
                    },
                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data) {
                    }
                  })
                  .then(() => {
                    // then re-load the admin users list
                    axios
                      .post(`${serverURL}/get-all-users`, {
                        withCredentials: true,
                        credentials: "include",
                      })
                      .then((response) =>
                        memoVals.setAdminUsersArray(
                          (x) => response.data.usersArray
                        )
                      )
                      .then(() => {
                        memoVals.setShowAddUserModal((x) => false);
                        memoVals.setAddUserPage((x) => "initial");
                        memoVals.setAddUserModalCalOpen((x) => false);
                        clearState();
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}

      {/* FNAME */}
      <div
        className={`${
          memoVals.addUserPage === "prorate" ? "addUserInputFrame" : "none"
        }`}
      >
        <div className="addUserInputContainer">
          <p className="addUserInputTitle">Prorated</p>
          <input
            id="addUserfName"
            className="addUserInput"
            onChange={(e) => {
              setFName(e.target.value);
            }}
          ></input>
          <div className="backNextContainer">
            <p className="addUserBackInvisible">Back</p>
            <p
              className={`${
                fName.length > 1
                  ? "addUserNextBtn nextBtnReady"
                  : "addUserNextBtn"
              }`}
              onClick={() => {
                memoVals.setAddUserPage((x) => "lName");
              }}
            >
              Next
            </p>
            <p className="addUserBackInvisible">Skip</p>
          </div>
        </div>
      </div>
      {/* end */}
    </div>
  );
};

export default AddUserSeries;
