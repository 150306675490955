import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import serverURL from "../../serverURL";
import dateStringer from "../../tools/dateStringer";
import towerLogo from "../tower/img/towerLogo.png";
import { TowerContext } from "../../contexts/TowerContext"; 

const TowerTasks = () => {
  const { fName, setFName, page, setPage } = useContext(TowerContext);

  //   useEffect(() => {
  //     console.log("Getting tower user info");
  //     axios
  //       .post(
  //         `${serverURL}/get-tower-data-init`,
  //         { someData: 1 },

  //         {
  //           withCredentials: true,
  //           credentials: "include",
  //         }
  //       )
  //       .then((response) => {
  //         if (response.data.message2 === "success") {
  //           setFName(response.data.fName);
  //         }
  //       });
  //   }, []);
  return <div className='towerBodyInnerContainer'>Tasks</div>;
};

export default TowerTasks;
