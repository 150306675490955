import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useReducer,
} from "react";
// cleaned wef 24 july 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";

import dateStringer from "../../../tools/dateStringer";
import serverURL from "../../../serverURL";
import whitecross from "../../../img/general/whitecross.svg";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import "../../../styles/calendar.css";

const TeamHoursDataBox = ({
  modal,
  setLoadUser,
  setShowRequests,
  setShowTeamHoursStatsModal,
}) => {
  const { setShowEditUser, selectedShiftTeam } = useContext(DataContext);
  const { currencySymbol } = useContext(UserContext);
  const { mob, full } = useContext(StyleContext);

  let [teamHoursSideBoxData, setTeamHoursSideBoxData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      months: [],
      staff: [],
      teamName: "",
      loading: true,
      my: `${
        new Date().getMonth() < 10 ? "0" : ""
      }${new Date().getMonth()}${JSON.stringify(
        new Date().getFullYear()
      ).substr(2, 2)}`,
    }
  );

  const memoVals = useMemo(
    () => ({
      setShowEditUser,
      selectedShiftTeam,
      currencySymbol,
      mob,
      full,
    }),
    [
      setShowEditUser, //
      selectedShiftTeam, //
      currencySymbol, //
      mob, //
      full, //
    ]
  );

  //   - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  let [selectedUserID, setSelectedUserID] = useState("all");

  useEffect(() => {
    // initial run
    // if (!teamHoursSideBoxData.months[0]) {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-team-hours-data`,
        {
          getMy:
            teamHoursSideBoxData.months && teamHoursSideBoxData.months[0]
              ? false
              : true,
          my: teamHoursSideBoxData.my,
          teamID: memoVals.selectedShiftTeam || "allStaff",
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("archived")) {
          setTeamHoursSideBoxData({ loading: false });
        } else {
          if (response.data.message === "success") {
            if (teamHoursSideBoxData.months && teamHoursSideBoxData.months[0]) {
            } else {
              if (
                !response.data.monthOptions.includes(teamHoursSideBoxData.my)
              ) {
                setTeamHoursSideBoxData({
                  months: [
                    teamHoursSideBoxData.my,
                    ...response.data.monthOptions,
                  ],
                });
              } else {
                setTeamHoursSideBoxData({ months: response.data.monthOptions });
              }
            }
            setTeamHoursSideBoxData({
              teamName: response.data.teamName || "All of your staff",
              staff: response.data.usersData || [],
              loading: false,
            });
            // setTeamName(response.data.teamName || "All of your staff");
            // setStaff(response.data.usersData);
            // setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
    // }
  }, [teamHoursSideBoxData.my]);

  // //   - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      if (setShowTeamHoursStatsModal) {
        setShowTeamHoursStatsModal(false);
      }
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [showTopWhiteBorder, setShowTopWhiteBorder] = useState(false);

  let generateAllStaffData = useMemo(() => {
    return teamHoursSideBoxData.staff[0]
      ? teamHoursSideBoxData.staff.map((item, i) => {
          let dontShowBottomBorder = false;
          teamHoursSideBoxData.staff.forEach((s, ind) => {
            if (s.userID === selectedUserID) {
              if (i === ind - 1) {
                dontShowBottomBorder = true;
              }
            }
          });
          return (
            <div
              key={item.userID}
              className={`payableHoursUserItemContainer ${
                i === teamHoursSideBoxData.staff.length - 1 ||
                selectedUserID === item.userID
                  ? "noBo rder"
                  : ""
              } ${dontShowBottomBorder ? "noBorderBottom" : ""}`}
              onClick={(e) => {
                e.stopPropagation();

                if (selectedUserID === item.userID) {
                  setSelectedUserID("");
                } else {
                  setSelectedUserID(item.userID);
                }
              }}
            >
              <div
                className={`mgrTeamHoursDataItem ${
                  item.userID === selectedUserID
                    ? "noHoverExpandable dataItemBorderRight"
                    : ""
                }
           
                `}
              >
                <p
                  className={`mgrReqFullName lineHeight18 ${
                    memoVals.mob ? "payableHoursMobTitle" : "fontSize12"
                  }`}
                >
                  {item.fName} {item.lName}
                </p>

                {selectedUserID === item.userID ? (
                  <div
                    className="payableUserBtns"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {modal ? (
                      ""
                    ) : (
                      <p
                        className="payableUserHours"
                        onClick={() => {
                          if (!modal) {
                            if (setShowRequests) {
                              setShowRequests(false);
                            }
                            if (setLoadUser) {
                              setLoadUser({
                                my: teamHoursSideBoxData.my,
                                userID: item.userID,
                              });
                            }
                          }
                        }}
                      >
                        Hours
                      </p>
                    )}
                    <p
                      className="payableUserHours"
                      onClick={() => {
                        memoVals.setShowEditUser((x) => item.userID);
                        if (!memoVals.full && setShowTeamHoursStatsModal) {
                          setShowTeamHoursStatsModal(false);
                        }
                      }}
                    >
                      Profile
                    </p>
                  </div>
                ) : (
                  <p
                    className={`allStaffReqsDataValue ${
                      memoVals.mob ? "payableHoursMobTitle" : ""
                    }`}
                  >
                    {teamHoursSideBoxData.loading
                      ? "-"
                      : dateStringer.formatMinsDurationToHours(
                          item.totalPayableMins
                        )}
                  </p>
                )}
              </div>
              {selectedUserID === item.userID ? (
                <div
                  className="expandedNamePayableHoursDiv"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="payableExpandedRow payablePurpleBg">
                    <p className="payableExpandedTitle">Payable hours</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.totalPayableMins
                      )}
                    </p>
                  </div>
                  <div className="payableExpandedRow">
                    <p className="payableExpandedTitle2">Regular hours</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(item.regularMins)}
                    </p>
                  </div>

                  <div className="payableExpandedRow">
                    <p className="payableExpandedTitle2">Overtime hours</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.overtimeMins
                      )}
                    </p>
                  </div>
                  <div className="payableDataBoxSplitter"></div>
                  <div className="payableExpandedRow">
                    <p className="payableExpandedTitle2">TOIL earned</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.tilMinsEarned
                      )}
                    </p>
                  </div>
                  <div className="payableDataBoxSplitter"></div>

                  <div className="payableExpandedRow">
                    <p className="payableExpandedTitle2">Paid leave</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.paidLeaveMins
                      )}
                    </p>
                  </div>

                  <div className="payableDataBoxSplitter"></div>

                  <div className="payableExpandedRow">
                    <p className="payableExpandedTitle2">Unpaid leave</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.unpaidLeaveMins
                      )}
                    </p>
                  </div>
                  <div className="payableDataBoxSplitter"></div>

                  <div className="payableExpandedRow">
                    <p className="payableExpandedTitle2">Paid absence</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.paidAbsentMins
                      )}
                    </p>
                  </div>

                  <div className="payableExpandedRow">
                    <p className="payableExpandedTitle2">Unpaid absence</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.unpaidAbsentMins
                      )}
                    </p>
                  </div>

                  <div className="payableExpandedRow payablePurpleBg">
                    <p className="payableExpandedTitle">
                      {dateStringer.printMonthFromMy(
                        teamHoursSideBoxData.my.substr(0, 2)
                      )}{" "}
                      pay*
                    </p>
                    <p className="payableExpandedValue">
                      {memoVals.currencySymbol}
                      {item.totalCost}
                    </p>
                  </div>
                  <div className="usrPayAsterixSalaryInfo colourPink">
                    *Includes salary, overtime, unpaid deductions & paid leave.
                    Check reports for a detailed breakdown.
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })
      : "";
  }, [teamHoursSideBoxData.staff, selectedUserID]);

  let generateAvailableYears = useMemo(() => {
    return (
      teamHoursSideBoxData.months &&
      teamHoursSideBoxData.months.map((item) => (
        <option value={item} key={item}>
          {dateStringer.printMonthFromMy(item.substr(0, 2))} {item.substr(2, 2)}
        </option>
      ))
    );
  }, [teamHoursSideBoxData.months]);

  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      if (setShowTeamHoursStatsModal) {
        setShowTeamHoursStatsModal(false);
      }
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`desktopDataBox noBottomPadding ${
        modal ? "modalTeamHoursDataBox" : "teamHoursDataBoxDesktopScroll"
      } mgrReqsDataBoxColours`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {/* <div className={`${modal ? "showMyToggler" : "none"}`}>
        <img
          src={whitecross}
          alt="Close"
          className="closeStatsModalCrossImg"
          onClick={() => {
            setShowReqStats(false);
          }}
        />
      </div> */}
      {window.innerWidth <= 550 && (
        <div className="modalSwiper modalSwiperMyAllows"></div>
      )}
      <div
        className="desktopTeamHoursDataBoxHeader"
        onTouchStart={handleDown}
        onTouchMove={window.innerWidth <= 550 ? handleMove : null}
        onTouchEnd={window.innerWidth <= 550 ? handleUp : null}
        onMouseDown={window.innerWidth <= 550 ? handleDown : null}
        onMouseMove={window.innerWidth <= 550 ? handleMove : null}
        onMouseUp={window.innerWidth <= 550 ? handleUp : null}
      >
        <div className="staffHoursDataBoxHeaderLeft">
          {modal ? (
            <img
              src={whitecross}
              alt="Close"
              className="closeStaffDataBoxImg"
              onClick={() => {
                if (setShowTeamHoursStatsModal) {
                  setShowTeamHoursStatsModal(false);
                }
              }}
            />
          ) : (
            ""
          )}
          <div className="payableTitleAndTeamNameDiv">
            <p
              className={`dataBoxHeading colourNavy_ ${
                modal ? "font18px" : ""
              } mgrHoursDataBoxHeading`}
              // onClick={() => {
              //   console.log({ teamName });
              // }}
            >
              Payable hours{" "}
            </p>
            <p className="payableDataBoxTeamName">
              {teamHoursSideBoxData.teamName}
            </p>
          </div>
        </div>

        <select
          className={`teamHoursMyDropdown reqDataBoxPurpleBg ${
            memoVals.mob ? "txtFontSize15px" : ""
          }`}
          value={teamHoursSideBoxData.my}
          onChange={(e) => {
            setTeamHoursSideBoxData({ my: e.target.value, loading: true });
            // setMy(e.target.value);
          }}
        >
          {generateAvailableYears}
        </select>
      </div>

      <div
        onScroll={(e) => {
          // if (
          //   allStaffAnnLeaveDiv.current &&
          //   allStaffAnnLeaveDiv.current.scrollTop === 0
          // ) {
          setShowTopWhiteBorder(true);
          // }
        }}
        className={`allStaffAnnLeaveDiv ${
          modal ? "allStaffAnnLeaveDivModal" : ""
        } ${
          !memoVals.mob && !modal ? "allStaffTeamHoursDivModalDesktop" : ""
        } ${showTopWhiteBorder ? "topWhiteBorder" : ""} ${
          modal ? "paddingTop20Px x249213572" : ""
        }`}
      >
        {teamHoursSideBoxData.loading ? (
          <img
            src={horizontalBalls}
            alt="Loading"
            className="loadingStaffHoursData"
          />
        ) : (
          generateAllStaffData
        )}
      </div>
      {modal ? (
        <div
          className={`mgrAllowsFooter ${
            selectedUserID !== "all" ? "noBorder" : ""
          }`}
        >
          <p></p>{" "}
          <p
            className={`closeEditShiftBtn closeMgrPurpleBtn`}
            onClick={() => {
              if (setShowTeamHoursStatsModal) {
                setShowTeamHoursStatsModal(false);
              }
            }}
          >
            Close
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TeamHoursDataBox;
