import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext";

import axios from "axios";

import logoicon from "../../img/signIn/smalllogo.png";
import download from "../../img/signIn/download.svg";
import eye from "../../img/signIn/eye.svg";
import logo from "../../img/general/logo.svg";

import "../../styles/signup.css";

import { UserContext } from "../../contexts/UserContext";

import serverURL from "../../serverURL";

import CheckUser from "../../tools/CheckUser";
import dateStringer from "../../tools/dateStringer";

const Signin = () => {
  const { setCheckUserState } = useContext(UserContext);

  // is the form filled?
  const [formFilled, setFormFilled] = useState(false);

  // value of email input field
  const [emailInput, setEmailInput] = useState("");

  // value of password input field
  const [passInput, setPassInput] = useState("");

  // check all fields are filled
  const checkFormFields = () => {
    if (emailInput && passInput) {
      setFormFilled(true);
    } else {
      setFormFilled(false);
    }
  };

  // state for the show password button to change the password input type
  const [showPassword, setShowPassword] = useState(false);

  // state to show invalid signin texts
  const [wrongEmail, setWrongEmail] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);

  const [ipAddress, setIpAddress] = useState("");
  let [location, setLocation] = useState("Unknown location");

  useEffect(() => {
    // Function to fetch IP address
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);

        const locationApi = await fetch(`https://ip-api.com/json/${data.ip}`);
        const data2 = await locationApi.json();

        setLocation(data2.city);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []);

  let enterFct = (e, btn) => {
    if (e.key === "Enter" || btn) {
      function getDeviceName() {
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
          return "Android Device";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
          return "iOS Device";
        } else if (/Windows Phone/i.test(userAgent)) {
          return "Windows Phone";
        } else if (/Windows/i.test(userAgent)) {
          return "Windows PC";
        } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
          return "Macintosh";
        } else if (/Linux|X11/i.test(userAgent)) {
          return "Linux";
        } else {
          return "Unknown Device";
        }
      }

      axios
        .post(
          `${serverURL}/signin`,
          {
            email: emailInput,
            password: passInput,
            client: "React",
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            deviceName: getDeviceName(),
            ip: ipAddress,
            location: location,
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          // console.log(response.data.email);
          if (response.data.error === "incorrect email") {
            console.log("email not found");
            setWrongEmail(true);
            setWrongPass(false);
          } else {
          }
          if (response.data.error === "incorrect password") {
            console.log("wrong password");
            setWrongEmail(false);
            setWrongPass(true);
          } else if (response.data.loggedIn) {
            setCheckUserState({ validUser: true });
            if (window.location.href.includes("contact")) {
              window.location.href = "/whatsapp-messenger";
            } else {
              if (response.data.user.permissions === 1) {
                // window.location.href = "/team-rota";
                window.location.href = "/calendar";
              } else {
                window.location.href = "/calendar";
              }
            }
          }
        });
    }
  };

  CheckUser(false, true);

  // render
  return (
    <div className="signUpBackground">
      {/* <div className="downloadBar">
        <div className="downloadBarContainer">
          <img src={download} alt="download pwa" id="mobIcon" />
          <p className="downloadBarText">Install FlowRota on your smartphone</p>
        </div>
      </div> */}

      <div className="signUpContainer">
        <div className="signinUpper">
          <p className="signInTitle">
            Sign in to FlowRota{" "}
            <img src={logo} alt="FlowRota" className="signInLogoImg" />
          </p>
          {/* <img className="signUpLogo" alt="FlowRota logo" src={logoicon} /> */}
          <form className="signUpForm">
            <p className="signInInputTitle">Email</p>
            <input
              type="email"
              placeholder="email"
              id="email"
              name="email"
              className="signinInput signUpEmailInput"
              onKeyPress={(e) => {
                enterFct(e, false);
              }}
              onChange={(e) => {
                setEmailInput(e.target.value);
                checkFormFields();
              }}
            />
            <br />
            <p className={`${wrongPass ? "wrongSignInPass" : "none"}`}>
              Wrong password
            </p>
            <p className={`${wrongEmail ? "wrongSignInPass" : "none"}`}>
              Hmm, that email isn't registered...
            </p>
            <p className="signInInputTitle x20483085">Password</p>
            <input
              type={`${showPassword ? "text" : "password"}`}
              id="password"
              placeholder="password"
              name="password"
              onKeyPress={(e) => {
                enterFct(e, false);
              }}
              onChange={(e) => {
                setPassInput(e.target.value);
                checkFormFields();
              }}
            ></input>
            <img
              className="showPassword"
              src={eye}
              alt="show password"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            />
            <div className="forgotContainer">
              <NavLink to="/reset-password">
                <p className="forgotPass">Forgotten password&nbsp;&nbsp;</p>
              </NavLink>
            </div>
            <br /> <br />
            {/* <NavLink to='/account-admin-general'> */}{" "}
            <p
              className="signinBtn2"
              // {`signinBtn2 ${!formFilled ? 'hide' : ''}`}
              onClick={(event) => {
                enterFct("", true);
              }}
            >
              Sign in
            </p>
            {/* </NavLink> */}
          </form>
        </div>
        <div className="signinLower">
          <div className="signinDivider"></div>
          <div className="signinFooter">
            <NavLink to="/signup">
              <p className="signInPrivacy">Sign up</p>
            </NavLink>
            <a
              href="https://flowrota.com/privacy"
              className="signInPrivacy"
              target="_blank"
            >
              Privacy & Terms
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
