import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { DataContext } from "../../../../contexts/DataContext";
import { AdminContext } from "../../../../contexts/AdminContext";
import { UserContext } from "../../../../contexts/UserContext";
import { StyleContext } from "../../../../contexts/StyleContext";

import "react-datepicker/dist/react-datepicker.css";

import "../../../../styles/subcomponents/specialDays.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/settings.css";
import horizontalBalls from "../../../../img/loaders/horizontalBalls.svg";

import navyBin from "../../../../img/general/navyBin.svg";

import checkboxInstall1 from "../../../../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../../../../img/general/checkboxInstall2.svg";
import whiteAdd from "../../../../img/general/whiteAdd.svg";

import dateStringer from "../../../../tools/dateStringer";

const SpecialDays = () => {
  const {
    specialDayDate,
    setShowSpecialDayModal,
    updatedSpecDay,
    setSureDeleteSpecialDay,
  } = useContext(DataContext);

  const {
    specialDaysData,
    setSpecialDaysData,
    showPrevSpecDays,
    setShowPrevSpecDays,
    showAddSpecialDayModal,
    setShowAddSpecialDayModal,
  } = useContext(AdminContext);
  const { mob } = useContext(StyleContext);

  const { companyName } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      specialDayDate,
      setShowSpecialDayModal,
      updatedSpecDay,
      setSureDeleteSpecialDay,
      specialDaysData,
      setSpecialDaysData,
      showPrevSpecDays,
      setShowPrevSpecDays,
      showAddSpecialDayModal,
      setShowAddSpecialDayModal,
      mob,
      companyName,
    }),
    [
      specialDayDate,
      setShowSpecialDayModal,
      updatedSpecDay,
      setSureDeleteSpecialDay,
      specialDaysData,
      setSpecialDaysData,
      showPrevSpecDays,
      setShowPrevSpecDays,
      showAddSpecialDayModal,
      setShowAddSpecialDayModal,
      mob,
      companyName,
    ]
  );

  const inputRef = useRef();

  let sortedSpecialDaysArray = memoVals.specialDaysData.sort((a, b) => {
    return a.tsForOrder - b.tsForOrder;
  });

  // new array excluding previous special days

  let goingFwdOnlyArray = memoVals.specialDaysData.filter((item) => {
    let todayDate = new Date();
    let todayMidnight = todayDate.setHours(0, 0, 0, 0);
    return dateStringer.createTimestampFromString(item.date) >= todayMidnight;
  });

  let convertTimestampToDate = (ds) => {
    if (!ds) {
      return "";
    }
    if (typeof ds === "string" && ds[0] !== "Y") {
      return "";
    }
    let yrShortened1 = ds.charAt(3);
    let yrShortened2 = ds.charAt(4);
    let yrString = `'${yrShortened1}${yrShortened2}`;
    let rawMonth = parseInt(ds.substr(6, 2));
    let month = "";
    switch (rawMonth) {
      case 0:
        month = "Jan";
        break;
      case 1:
        month = "Feb";
        break;
      case 2:
        month = "Mar";
        break;
      case 3:
        month = "Apr";
        break;
      case 4:
        month = "May";
        break;
      case 5:
        month = "Jun";
        break;
      case 6:
        month = "Jul";
        break;
      case 7:
        month = "Aug";
        break;
      case 8:
        month = "Sep";
        break;
      case 9:
        month = "Oct";
        break;
      case 10:
        month = "Nov";
        break;
      case 11:
        month = "Dec";
        break;
    }

    let day = ds.substr(9, 2);
    let prependSingleDigitDay = (day) => {
      if (day < 10) {
        return `0${day}`;
      } else {
        return day;
      }
    };

    let convertedToTs = dateStringer.createTimestampFromString(ds);
    let convertedDateObj = new Date(convertedToTs);

    let rawWeekday = convertedDateObj.getDay();
    let weekDay = "";
    switch (rawWeekday) {
      case 0:
        weekDay = "Sun";
        break;
      case 1:
        weekDay = "Mon";
        break;
      case 2:
        weekDay = "Tue";
        break;
      case 3:
        weekDay = "Wed";
        break;
      case 4:
        weekDay = "Thu";
        break;
      case 5:
        weekDay = "Fri";
        break;
      case 6:
        weekDay = "Sat";
        break;
    }
    return `${weekDay} ${prependSingleDigitDay(
      parseInt(day)
    )} ${month} ${yrString}`;
  };

  // the pay rate fct
  let payRate = (value, companyClosed) => {
    if (!companyClosed) {
      return `${value}x`;
    } else return <span className="shut">CLOSED</span>;
  };

  let today = new Date();
  let todayAt00 = today.setHours(0, 0, 0, 0);

  useEffect(() => {
    if (memoVals.updatedSpecDay.data) {
      let newArr = [];
      memoVals.specialDaysData.forEach((x) => {
        if (x._id === memoVals.updatedSpecDay.id) {
          console.log({
            name: memoVals.updatedSpecDay.name,
            companyClosed: memoVals.updatedSpecDay.companyClosed,
            closedPay: memoVals.updatedSpecDay.closedPay,
            rate: memoVals.updatedSpecDay.rate,
            _id: memoVals.updatedSpecDay.id,
            date: memoVals.updatedSpecDay.date,
            ts: memoVals.updatedSpecDay.ts,
          });
          newArr.push({
            name: memoVals.updatedSpecDay.name,
            companyClosed: memoVals.updatedSpecDay.companyClosed,
            closedPay: memoVals.updatedSpecDay.closedPay,
            rate: memoVals.updatedSpecDay.rate,
            _id: memoVals.updatedSpecDay.id,
            date: memoVals.updatedSpecDay.date,
            ts: memoVals.updatedSpecDay.ts,
          });
        } else {
          newArr.push(x);
        }
      });
      memoVals.setSpecialDaysData((x) => newArr);
    }
  }, [memoVals.updatedSpecDay]);

  // data returner
  let populateSpecialDays = memoVals.showPrevSpecDays
    ? sortedSpecialDaysArray.map((item) => {
        if (memoVals.specialDaysData) {
          return (
            <div
              key={item._id}
              onClick={() => {
                memoVals.setShowSpecialDayModal((x) => {
                  return {
                    show: true,
                    name: item.name,
                    companyClosed: item.companyClosed,
                    closedPay: item.closedPay,
                    rate: item.rate,
                    id: item._id,
                    date: item.date,
                    ts: item.ts,
                  };
                });
              }}
            >
              <div
                className={`${
                  dateStringer.createTimestampFromString(item.date) < todayAt00
                    ? "lighterBgSpecialDay"
                    : "specialDayUnit whiteBg"
                } height60`}
              >
                <span>
                  <p className="specialDayDate">
                    {convertTimestampToDate(item.date)}
                  </p>
                  <p className="specialDayRate">
                    {!item.companyClosed ? "Paid: " : ""}
                    {payRate(item.rate, item.companyClosed)}
                  </p>
                </span>
                <img
                  className={`${
                    dateStringer.createTimestampFromString(item.date) <
                    todayAt00
                      ? "trashInvis_ specialDaysTrash"
                      : "specialDaysTrash"
                  }`}
                  src={navyBin}
                  alt="Delete"
                  onClick={(e) => {
                    // deleteSpecialDay(item._id);
                    e.stopPropagation();
                    memoVals.setSureDeleteSpecialDay((x) => item._id);
                  }}
                />
              </div>
            </div>
          );
        }
      })
    : goingFwdOnlyArray.map((item) => {
        if (memoVals.specialDaysData) {
          return (
            <div key={item._id}>
              <div
                className="specialDayUnit whiteBg height60"
                onClick={() => {
                  console.log({ item });
                  memoVals.setShowSpecialDayModal((x) => {
                    return {
                      show: true,
                      name: item.name,
                      companyClosed: item.companyClosed,
                      closedPay: item.closedPay,
                      rate: item.rate,
                      id: item._id,
                      date: item.date,
                      ts: item.ts,
                    };
                  });
                }}
              >
                <span>
                  <p className="specialDayDate">
                    {convertTimestampToDate(item.date)}
                  </p>
                  <p className="specialDayRate">
                    {!item.companyClosed ? "Paid: " : ""}
                    {payRate(item.rate, item.companyClosed)}
                  </p>
                </span>
                <img
                  className="specialDaysTrash"
                  src={navyBin}
                  alt="Delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    memoVals.setSureDeleteSpecialDay((x) => item._id);
                  }}
                />
              </div>
            </div>
          );
        }
      });

  let dateObjForSpecialDay = new Date(memoVals.specialDayDate);

  let inputtedMonthRaw = dateObjForSpecialDay.getMonth();
  let inputtedMonth = "";
  switch (inputtedMonthRaw) {
    case 0:
      inputtedMonth = "January";
      break;
    case 1:
      inputtedMonth = "February";
      break;
    case 2:
      inputtedMonth = "March";
      break;
    case 3:
      inputtedMonth = "April";
      break;
    case 4:
      inputtedMonth = "May";
      break;
    case 5:
      inputtedMonth = "June";
      break;
    case 6:
      inputtedMonth = "July";
      break;
    case 7:
      inputtedMonth = "August";
      break;
    case 8:
      inputtedMonth = "September";
      break;
    case 9:
      inputtedMonth = "October";
      break;
    case 10:
      inputtedMonth = "November";
      break;
    case 11:
      inputtedMonth = "December";
      break;
  }

  useEffect(() => {
    if (!memoVals.mob && inputRef.current) {
      inputRef.current.focus();
    }
  }, [memoVals.showAddSpecialDayModal]);

  return (
    <div className="specialDaysContainer">
      <div className="specialDaysUpper"></div>
      <p className="specialDayExplain">
        Set rules for specific days including whether or not the{" "}
        {memoVals.companyName || "your organisation"} is closed, whether staff
        should be paid, and how much they should be paid.
        <br />
        <br />
        An example would be if your workplace is closed for refurbishment, but
        you wish to pay your staff anyway.
      </p>
      <p
        className={`historySpecDaysBtn ${
          !memoVals.specialDaysData[0] ? "none" : ""
        } ${memoVals.mob ? "" : "historySpecDaysBtnDesktop"}`}
        onClick={() => {
          memoVals.setShowPrevSpecDays((x) => !memoVals.showPrevSpecDays);
        }}
      >
        Historical{" "}
        <img
          src={memoVals.showPrevSpecDays ? checkboxInstall2 : checkboxInstall1}
          alt="Check box install"
          className="historicalCheckBoxInstallImg"
        />
      </p>
      <div className="specialDaysLower">
        <div className="specialDayUnitsContainer">{populateSpecialDays}</div>
      </div>
      <p
        className="specialDaysAddBtn"
        onClick={() => {
          memoVals.setShowAddSpecialDayModal(
            (x) => !memoVals.showAddSpecialDayModal
          );
        }}
      >
        Add special day{" "}
        <img
          src={whiteAdd}
          alt="Add new employee"
          className="addNewEmpIcon jukl"
        />
      </p>
    </div>
  );
};

export default SpecialDays;
