import React from 'react'

const ConvertStartYr = (value) => {

    let dayOutput = '';
    let monthOutput = '';

    let d = `${value.charAt(1)}${value.charAt(2)}`

    let m = `${value.charAt(4)}${value.charAt(5)}`


    if (d.charAt(0) === '0') {
        dayOutput = value.charAt(2);
    } else {
        dayOutput = `${value.charAt(1)}${value.charAt(2)}`
    }

    if (m === '01') {
        monthOutput = 'Jan'
    } else if (m === '02') {
        monthOutput = 'Feb'
    } else if (m === '03') {
        monthOutput = 'Mar'
    } else if (m === '04') {
        monthOutput = 'Apr'
    } else if (m === '05') {
        monthOutput = 'May'
    } else if (m === '06') {
        monthOutput = 'Jun'
    } else if (m === '07') {
        monthOutput = 'Jul'
    } else if (m === '08') {
        monthOutput = 'Aug'
    } else if (m === '09') {
        monthOutput = 'Sep'
    } else if (m === '10') {
        monthOutput = 'Oct'
    } else if (m === '11') {
        monthOutput = 'Nov'
    } else if (m === '12') {
        monthOutput = 'Dec'
    }

    return `${dayOutput} ${monthOutput}`;
}

export default ConvertStartYr;