import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  Fragment,
} from "react";
// cleaned wed 24 jul 23
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";
import close from "../img/modals/close.svg";

import installCloud from "../img/general/resolutionCloud.svg";
import threeGreyDots from "../img/general/threeGreyDots.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const ResolutionModal = ({ setShowInstallModal, device, popUp }) => {
  const { mobModal } = useContext(StyleContext);
  const { setCheckUserState, checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      mobModal,
      setCheckUserState,
      checkUserState, //
    }),
    [mobModal, setCheckUserState, checkUserState]
  );

  let [deviceToggler, setDeviceToggler] = useState("ios"); // or "chrome"
  useEffect(() => {
    // cleaned - not needed
    if (device.browser.toLowerCase() === "chrome") {
      setDeviceToggler("chrome");
    }
  }, [device]);

  // useEffect(() => {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener("popstate", function (event) {
  //     window.history.pushState(null, document.title, window.location.href);
  //     setShowInstallModal(false);
  //   });
  // }, []);

  let [hide, setHide] = useState(false);

  let closeModal = () => {
    if (popUp) {
      let ds = dateStringer.createStringFromTimestamp(new Date().getTime());
      axios
        .post(
          `${serverURL}/close-install`,
          {
            ds: ds,
            hide: hide,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            memoVals.setCheckUserState((x) => {
              return {
                hideInstall: hide ? ds : memoVals.checkUserState.hideInstall,
                lastClosedInstall: ds,
              };
            });
          }
        });
      setShowInstallModal(false);
    } else {
      setShowInstallModal(false);
    }
  };

  return (
    <div
      className="clockOnModalUnderlay underlayInstall"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`clockOnModalBox ${
          deviceToggler === "chrome" ? "chromeInstallModalBox" : "x3841941"
        } ${
          memoVals.mobModal
            ? "mobInstallModal mobModalShoulder slideUp"
            : "zoomIn"
        } x3841941`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="clockOnModalHeader none"
          onClick={() => {
            if (memoVals.mobModal) {
              closeModal();
            }
          }}
        >
          <div className="clockOnModalHeaderSideUnit">
            <img
              src={close}
              alt="Close"
              className="closeClockOnModalImg"
              onClick={() => {
                closeModal();
              }}
            />
          </div>
          <p className="clockOnModalHeaderTitle">
            {/* Install the FlowRota app */}
          </p>
          {/* <div className="clockOnModalHeaderSideUnit"></div> */}
        </div>

        <div className="installModalContentBox">
          <img
            src={installCloud}
            alt="Install FlowRota"
            className="installCloudImg x32892984221"
          />
          <p
            className="resWarnInfo"
            onClick={() => {
              console.log({ device });
            }}
          >
            FlowRota may not be able to display the content correctly unless you
            zoom out. Follow the instructions below.
          </p>
          {/* <div className="dockInstructDiv"> */}
          {(device.browser.toLowerCase() === "chrome" ||
            device.browser.toLowerCase() === "safari") &&
          device.os === "Mac OS" &&
          device.device === "desktop" ? (
            <Fragment>
              <p className="resInsturctTxt displayFlex">
                {" "}
                Press <span className="resolIcon">⌘</span> and{" "}
                <span className="resolIcon">-</span>
              </p>
              <p className="resInsturctTxt">until this popup closes</p>
            </Fragment>
          ) : (
            ""
          )}
          {/* {device.os} {device.device} */}
          {device.os === "Android" && device.device === "mobile" ? (
            <Fragment>
              {device.browser.toLowerCase() === "chrome" ? (
                <p className="resInsturctTxt">
                  {" "}
                  Open Chrome on your Android device and go to{" "}
                  <span className="makeBold">app.flowrota.com</span>.
                  <br />
                  <br />
                  Tap the{" "}
                  <img
                    src={threeGreyDots}
                    className="greyThreeDotsResImg"
                    alt="Menu"
                  />{" "}
                  button on the top right and tap{" "}
                  <span className="makeBold">Zoom</span>.
                  <br />
                  <br />
                  Zoom out until this popup disappears and then switch back to
                  your installed FlowRota app.
                </p>
              ) : (
                <p className="resInsturctTxt">
                  {" "}
                  Open your main web browser on your Android phone (Chrome,
                  Samsung Internet etc.) and go to app.flowrota.com.
                  <br />
                  <br />
                  Go to settings and select zoom.
                  <br />
                  <br />
                  Zoom out until this popup disappears.
                </p>
              )}
            </Fragment>
          ) : (
            ""
          )}
        </div>

        <div className="installModalFooter">
          <p
            className="closeClockOnDiv x9183812"
            onClick={() => {
              sessionStorage.setItem("hideResolutionModal", true);
              closeModal();
            }}
          >
            Disregard
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResolutionModal;
