import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "core-js";

// fonts

import "./fonts/timelify-bold.otf";
import "./fonts/timelify-extrabold.otf";
import "./fonts/timelify-extralight.otf";
import "./fonts/timelify-light.otf";
import "./fonts/timelify-medium.otf";
import "./fonts/timelify-normal.otf";
// import "core-js/fn/number/is-nan";
// import "core-js/es7/";
// import "core-js/es6/";
// import "raf/polyfill";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
