// This is a functional component (does not render anything)
// that checks user logged in based on jwt (verfies it too)
// and will update the userContext state

// Use this function on every component that needs general data to be fetches/updated

import { useContext, useEffect, useMemo } from "react";
// cleaned wef 24 jul 23
// axios caught 21 sep 23

import { UserContext } from "../contexts/UserContext";

import axios from "axios";

import serverURL from "../serverURL";
import dateStringer from "../tools/dateStringer";

const CheckUser = async (whatsapp, signin) => {
  const { setCheckUserState } = useContext(UserContext);

  // Memoizing this does not work - no need to do it.

  // checkUser currently only runs on NavBar.js

  const cancelSourceCheckUser = axios.CancelToken.source();

  const loadHandler = (userID, fName, lName, permissions, email) => {
    console.log("LOADING ", userID, " TO PROGREESIER");
    progressier.add({
      userID: userID,
      name: `${fName} ${lName}`,
    });
    // Copy the below lines under window.fcWidget.init inside fw_chat_widget function in the above snippet

    // // To set unique user id in your system when it is available
    // window.fcWidget.setExternalId(userID);
    // // To set user name
    // window.fcWidget.user.setFirstName(fName);
    // window.fcWidget.user.setLastName(lName);
    // window.fcWidget.user.setPermissions(permissions);
    // window.fcWidget.user.setEmail(email);

    // // To set user email

    // // To set user properties
    // // Note that any other fields passed as properties which are not in the CRM Contact properties already, they will be ignored.
    // window.fcWidget.user.setProperties({
    //   cf_plan: "Pro", // meta property 1
    //   cf_status: "Active", // meta property 2
    // });
  };

  useEffect(() => {
    const fetchData = async () => {
      const now = new Date();
      const localDs = dateStringer.createStringFromTimestamp(now.getTime());

      try {
        const response = await axios.post(
          `${serverURL}/checkuser`,
          { localDs: localDs },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSourceCheckUser.token,
          }
        );

        if (response.status === 200) {
          if (response.data.message === "archived") {
            window.location.href = "/signin";
          }

          if (response.data.validUser === false && !signin) {
            window.location.href = "/signin";
          }

          if (response.data.validUser === true && signin) {
            window.location.href = "/account";
          }

          if (response.data.message === "success") {
            let fName = response.data.fName;
            let lName = response.data.lName;
            let userID = response.data.userID;
            setCheckUserState({
              validUser: response.data.validUser,
              fName: response.data.fName,
              lName: response.data.lName,
              permissions: response.data.permissions,
              tilEnabled: response.data.tilEnabled,
              clockOnAllowed: response.data.clockOnAllowed,
              userID: response.data.userID,
              tilEnabled: response.data.tilEnabled,
              currClientYear: response.data.currClientYear,
              showCosts: response.data.usersCanSeeCost,
              todayCheckUserRequests: response.data.todayCheckUserRequests, // this is to be removed
            });

            window.addEventListener(
              "load",
              loadHandler(
                userID,
                fName,
                lName,
                response.data.permissions,
                response.data.email
              )
            );

            console.log("checkUser fct run");
            console.log("validuser", response.data.validUser);
            if (response.data.validUser === "false" && !signin) {
              window.location.href = "/signin";
            }

            // Return the event listener cleanup function
            return () => {
              window.removeEventListener(
                "load",
                loadHandler(userID, fName, lName)
              );
              cancelSourceCheckUser.cancel("Component unmounted");
            };
          } else {
            console.log("Failed to get data");
          }
        } else {
          console.log("invalid user");
        }
      } catch (error) {
        console.error(error);
      } finally {
        cancelSourceCheckUser.cancel("Component unmounted");
      }
    };

    fetchData();

    return () => {
      // Cleanup function
      cancelSourceCheckUser.cancel("Component unmounted");
      window.removeEventListener("load", loadHandler);
    };
  }, []);
};

export default CheckUser;
