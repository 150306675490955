import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useRef,
  useMemo,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
import serverURL from "../serverURL";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import checkbox1 from "../img/general/checkboxInstall1.svg";
import checkbox2 from "../img/general/checkboxInstall2.svg";
import swapArrow from "../img/general/swapArrow.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import { off } from "process";

const SwapModal = ({ shiftID, type, setSwapParent }) => {
  const { mobModal } = useContext(StyleContext);
  const {
    setIndicate,
    setIWant,
    setOutboundSwapsQty,
    outboundSwapsQty,
    setDynanicSwapOrNote,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      setIWant,
      setOutboundSwapsQty,
      outboundSwapsQty,
      setDynanicSwapOrNote,
      mobModal,
    }),
    [
      setIndicate, //
      setIWant, //
      setOutboundSwapsQty, //
      outboundSwapsQty, //
      setDynanicSwapOrNote, //
      mobModal, //
    ]
  );

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 4) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox1}
        alt="Checkbox 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox2}
        alt="Checkbox 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={swapArrow}
        alt="Swap Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [offerNothing, setOfferNothing] = useState(false);

  let [selectedShiftObject, setSelectedShiftObject] = useState({
    ds: "",
    tags: [],
    brk: 0,
    durMins: 0,
    startDs: "",
    endDs: "",
    type: "shift",
    typeID: "",
  });

  let [select, setSelect] = useState(false);

  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fName: "",
      lName: "",
      availableItems: [],
      monWeek: "",
      originalItem: { start: "", end: "", durMins: 0, brk: 0 },
      wantedType: "",
      itemID: "",
    }
  );

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      setSwapParent(false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let renderShifts = state.availableItems.map((item) => {
    let generateTags = item.tags.map((tag) => {
      return <p className="selectShiftTag">{tag}</p>;
    });
    return (
      <div
        className={`selectShiftItem ${memoVals.mobModal ? "fullOpac" : ""}`}
        onClick={() => {
          console.log({
            ds: item.ds,
            tags: item.tags,
            brk: item.brk,
            durMins: item.durMins,
            startDs: item.start,
            endDs: item.end,
            type: item.type,
            itemID: item.itemID,
          });
          setSelectedShiftObject({
            ds: item.ds,
            tags: item.tags,
            brk: item.brk,
            durMins: item.durMins,
            startDs: item.start,
            endDs: item.end,
            type: item.type,
            itemID: item.itemID,
          });
          setSelect(false);
        }}
      >
        <p className="selectShiftItemDs">
          {item.type === "shift"
            ? "Shift"
            : item.type === "til"
            ? "Time in Lieu"
            : "Overtime"}
        </p>
        <p className="selectShiftTimesRow">
          {dateStringer.printedDateFromDs(item.ds)}
        </p>

        <p className="selectShiftBrkRow">
          {dateStringer.dsToTimeStrip(item.start)} -{" "}
          {dateStringer.dsToTimeStrip(item.end)}
        </p>

        <p className="selectShiftBrkRow">
          {dateStringer.formatMinsDurationToHours(item.brk)} break
        </p>

        <p className="selectShiftDurMinsRow">
          {dateStringer.formatMinsDurationToHours(item.durMins)}
        </p>

        {item.tags[0] ? (
          <p className="selectShiftTagsRow">{generateTags}</p>
        ) : (
          ""
        )}
      </div>
    );
  });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (shiftID && type) {
      axios
        .post(
          `${serverURL}/get-my-shifts-for-swap-offer`,
          {
            shiftID,
            type,
            nowDs: `${dateStringer.createStringFromTimestamp(
              new Date().getTime()
            )}`,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            let availItems = [
              ...response.data.offerShifts,
              ...response.data.offerTils,
            ];
            availItems.sort(
              (a, b) => a.midnightTimestamp - b.midnightTimestamp
            );
            setDataLoaded(true);
            setState({
              fName: response.data.fName,
              lName: response.data.lName,
              availableItems: availItems,
              monWeek: response.data.monWeek,
              originalItem: response.data.getItem,
              wantedType: response.data.wantedType,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let closeModal = () => {
    setSwapParent(false);
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  useEffect(() => {
    setModalPosition({ bottom: "0px", left: "0px" });
  }, [select]);

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className="swapModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          style={modalPosition}
          ref={modalRef}
          className={`swapModalHeader ${
            memoVals.mobModal
              ? `slideUp ${
                  select
                    ? "mobSwapModal"
                    : selectedShiftObject.ds
                    ? "selectedShiftMobSwapModal"
                    : "nonSelectMobSwapModal"
                } mobModalShoulder slideUp`
              : "zoomIn"
          }`}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperSwapModal"></div>
          )}
          <div
            className={`clockOnModalHeader  ${
              memoVals.mobModal ? "x1393198197311" : ""
            }`}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
            onClick={(e) => {
              e.stopPropagation();

              if (memoVals.mobModal) {
                closeModal();
              }
            }}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
            <p
              className="clockOnModalHeaderTitle"
              onClick={() => {
                console.log(state);
              }}
            >
              Swap with {state.fName}
            </p>
            <div className="clockOnModalHeaderSideUnit"></div>
          </div>

          <div
            className={`swapModalMiddle ${
              memoVals.mobModal ? "swapModalMiddleMob" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className={`swapModalMiddleLeft ${!state.monWeek ? "invis" : ""}`}
            >
              <p className="swapModalMiddleLeftTitle">
                I want {state.fName}
                {state.fName[state.fName.length - 1] === "s" ? "'" : "'s"}{" "}
              </p>
              <div className="wantShiftDiv">
                <p className="wantShiftType">
                  {state.wantedType === "shift"
                    ? "Shift"
                    : state.wantedType === "til"
                    ? "Time in Lieu"
                    : "Overtime"}
                </p>
                <p className="wantShiftDs">
                  {dateStringer.printedDateFromDs(state.originalItem.start)}
                </p>
                <p className="wantTimeStrip">
                  {dateStringer.dsToTimeStrip(state.originalItem.start)} -{" "}
                  {dateStringer.dsToTimeStrip(state.originalItem.end)}
                </p>
                <p className="wantTimeStrip noBorder">
                  {dateStringer.formatMinsDurationToHours(
                    state.originalItem.brk
                  )}{" "}
                  break
                </p>
              </div>
              <div className="wantShiftDurationDiv">
                {dateStringer.formatMinsDurationToHours(
                  state.originalItem.durMins || 0
                )}
              </div>
            </div>
            <img
              src={swapArrow}
              alt="Swap"
              className={`swapModalSwapMiddleMiddleArrow ${
                select
                  ? "selectMiddleArrow"
                  : selectedShiftObject.ds
                  ? "selectMiddleArrow2"
                  : ""
              }`}
            />
            <div className="swapModalMiddleRight">
              <p className="swapModalMiddleLeftRight">in return for my</p>
              {select ? (
                <div className="selectShiftScrollBox">{renderShifts}</div>
              ) : selectedShiftObject.ds ? (
                <div className="selectedShiftFlexEnd">
                  <div className="offeredShiftDiv">
                    <div className="offeredShiftDivCloseAndTypeDiv">
                      <img
                        src={close}
                        className="closeOfferedShiftImg"
                        onClick={() => {
                          setSelectedShiftObject({
                            ds: "",
                            tags: [],
                            brk: 0,
                            durMins: 0,
                            startDs: "",
                            endDs: "",
                            type: "shift",
                            itemID: "",
                          });
                        }}
                      />
                      <p className="wantShiftType">
                        {selectedShiftObject.type === "shift"
                          ? "Shift"
                          : selectedShiftObject.type === "til"
                          ? "Time in Lieu"
                          : "Overtime"}
                      </p>
                    </div>
                    <p className="wantShiftDs">
                      {dateStringer.printedDateFromDs(
                        selectedShiftObject.startDs
                      )}
                    </p>
                    <p className="wantTimeStrip">
                      {dateStringer.dsToTimeStrip(selectedShiftObject.startDs)}{" "}
                      - {dateStringer.dsToTimeStrip(selectedShiftObject.endDs)}
                    </p>
                    <p className="wantTimeStrip noBorder">
                      {dateStringer.formatMinsDurationToHours(
                        selectedShiftObject.brk
                      )}{" "}
                      break
                    </p>
                  </div>
                  <div className="offeredShiftDurationDiv">
                    {dateStringer.formatMinsDurationToHours(
                      selectedShiftObject.durMins
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`selectShiftBox ${
                    offerNothing ? "offerNothingShiftBox" : ""
                  }`}
                  onClick={() => {
                    if (offerNothing) {
                      setOfferNothing(false);
                    }
                    setSelect(true);
                  }}
                >
                  Select a shift
                </div>
              )}
              <div
                className="offerNoShiftCheckBoxDiv"
                onClick={() => {
                  setOfferNothing(!offerNothing);
                  setSelect(false);
                  setSelectedShiftObject({
                    ds: "",
                    tags: [],
                    brk: 0,
                    durMins: 0,
                    startDs: "",
                    endDs: "",
                    type: "shift",
                    itemID: "",
                  });
                }}
              >
                <img
                  src={offerNothing ? checkbox2 : checkbox1}
                  alt="Offer shift"
                  className="offerNothingCheckBoxImg"
                />
                No shift
              </div>
            </div>
          </div>
          <div
            className="swapModalFooter"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className="closeClockOnDiv"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </p>

            <p
              className={`requestSwapBtnSubmit ${
                !selectedShiftObject.ds && !offerNothing
                  ? "disableReqSwapBtn"
                  : ""
              }`}
              onClick={() => {
                console.log({
                  wantShiftID: state.originalItem.itemID,
                  wantType: state.originalItem.itemType,
                  offerShiftID: selectedShiftObject.itemID,
                  offerType: selectedShiftObject.type,
                  offerNothing: offerNothing,
                  dsMade: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),
                });
                if (selectedShiftObject.itemID || offerNothing) {
                  axios
                    .post(
                      `${serverURL}/request-swap`,
                      {
                        wantShiftID: state.originalItem.itemID,
                        wantType: state.originalItem.itemType,
                        offerShiftID: selectedShiftObject.itemID,
                        offerType: selectedShiftObject.type,
                        offerNothing: offerNothing,
                        dsMade: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        closeModal();
                        memoVals.setIWant((x) => response.data.iWant);
                        memoVals.setOutboundSwapsQty(
                          (x) => memoVals.outboundSwapsQty + 1
                        );
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Requested swap",
                            colour: "limegreen",
                            duration: 4000,
                          };
                        });

                        setDynanicSwapOrNote((x) => {
                          return {
                            typeID: state.originalItem.itemID,
                            swapOrNote: "swap",
                            note: "",
                            swap: true,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Request swap
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default SwapModal;
