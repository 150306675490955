import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// proleaded images 19 sep 23
// axios caught 21 sep 23

import { NavLink } from "react-router-dom";
import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import acc from "../img/mFooter/acc.svg";
import accMgr from "../img/mFooter/accMgr.svg";

import accHome from "../img/mFooter/accHome.svg";
import accHomeMgr from "../img/mFooter/accHomeMgr.svg";

import accMselected from "../img/mFooter/accMselected.svg";
import accSelected from "../img/mFooter/accSelected.svg";
import accSelectedMgr from "../img/mFooter/accSelectedMgr.svg";

import sched from "../img/mFooter/sched.svg";

import dateStringer from "../tools/dateStringer";

import cal from "../img/mFooter/cal.svg";
import calHome from "../img/mFooter/calHome.svg";
import logo from "../img/general/logo.svg";
import logoTxt from "../img/general/logoTxt.svg";

import bell1 from "../img/general/bell1.svg";
import bell2 from "../img/general/bell2.svg";
import plus from "../img/general/plus.svg";
// import shoulder from "../img/general/shoulder.svg";

import cal2 from "../img/general/cal.svg";
import desktopBurger from "../img/general/desktopBurger.svg";

import cal3 from "../img/general/CAL2.svg";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import icon1 from "../img/general/navbar/1.svg";
import icon2 from "../img/general/navbar/2.svg";
import icon3 from "../img/general/navbar/3.svg";
import icon4 from "../img/general/navbar/4.svg";
import icon5 from "../img/general/navbar/5.svg";
import icon6 from "../img/general/navbar/6.svg";

import icon7 from "../img/general/navbar/7.svg";
import icon8 from "../img/general/navbar/8.svg";
import icon9 from "../img/general/navbar/9.svg";
import icon10 from "../img/general/navbar/10.svg";
import icon11 from "../img/general/navbar/11.svg";
import icon12 from "../img/general/navbar/12.svg";
import icon13 from "../img/general/navbar/13.svg";

import icon1b from "../img/general/navbar/1b.svg";
import icon2b from "../img/general/navbar/2b.svg";
import icon3b from "../img/general/navbar/3b.svg";
import icon4b from "../img/general/navbar/4b.svg";
import icon5b from "../img/general/navbar/5b.svg";
import icon6b from "../img/general/navbar/6b.svg";
import close from "../img/general/navyClose.svg";

import icon7b from "../img/general/navbar/7b.svg";
import icon8b from "../img/general/navbar/8b.svg";
import icon9b from "../img/general/navbar/9b.svg";
import icon10b from "../img/general/navbar/10b.svg";
import icon11b from "../img/general/navbar/11b.svg";
import icon12b from "../img/general/navbar/12b.svg";
import icon13b from "../img/general/navbar/13b.svg";

import icon14 from "../img/general/navbar/14.svg";
import icon14b from "../img/general/navbar/14b.svg";

import whatsapp1 from "../img/general/contact/whatsapp1.svg";
import whatsapp2 from "../img/general/contact/whatsapp2.svg";
import messenger1 from "../img/general/contact/messenger1.svg";
import messenger2 from "../img/general/contact/messenger2.svg";
import email1 from "../img/general/contact/email1.svg";
import email2 from "../img/general/contact/email2.svg";

import calMselected from "../img/mFooter/calMselected.svg";
import calSelected from "../img/mFooter/calSelected.svg";
import requests from "../img/mFooter/requests.svg";
import requestsHome from "../img/mFooter/requestsHome.svg";

import requestsMselected from "../img/mFooter/requestsMselected.svg";
import requestsSelected from "../img/mFooter/requestsSelected.svg";
import burger from "../img/mFooter/burger.svg";
import burgerHome from "../img/mFooter/burgerHome.svg";
import whiteCog from "../img/general/whiteCog.svg";

import mobBurger from "../img/general/mobBurger.svg";
import mobMsg from "../img/general/mobMsg.svg";
import mobMsgActive from "../img/general/mobMsgActive.svg";
import mobBell from "../img/general/mobBell.svg";
import mobBellActive from "../img/general/mobBellActive.svg";
import externalLink from "../img/general/externalLink.svg";

import mobHelp from "../img/general/mobHelp.svg";

import whitecross from "../img/general/whitecross.svg";

import { DataContext } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";
import { RequestContext } from "../contexts/RequestContext";
import { CalendarContext } from "../contexts/CalendarContext";
import { StyleContext } from "../contexts/StyleContext";

import MakeLeaveReq from "../components/MakeLeaveReq";
import AddAvailModal from "../components/AddAvailModal";
import ManagerShiftModal from "./calendar/shifts/ManagerShiftModal";
import UserLeaveReqModal from "../components/requests/UserLeaveReqModal";
import Indicate from "../components/Indicate";
import AbsenceUnitModal from "../components/calendar/AbsenceUnitModal";
import MyShiftModal from "./calendar/shifts/MyShiftModal";
import UserOpenShiftModal from "./calendar/shifts/UserOpenShiftModal";

import LeaveReqModal from "../components/requests/LeaveReqModal";
import EditUser from "../components/account/admin/subcomponents/EditUser";

import Notifications from "./Notifications";
import Upcoming from "./Upcoming";
import ClockOnModal from "./ClockOnModal";

import ToilConversionModal from "./calendar/shifts/ToilConversionModal";
import ClockItemModal from "./calendar/shifts/ClockItemModal";
import UnclocksModal from "../components/UnclocksModal.js";
import BulkEditLimitsModal from "../components/calendar/BulkEditLimitsModal.js";

import ActiveClocksModal from "../components/ActiveClocksModal.js";
import MessagesModal from "../components/MessagesModal.js";
import SwapModal from "../components/SwapModal.js";
import AddLeaveModal from "../components/AddLeaveModal.js";
import OpenMgrShiftModal from "../components/calendar/shifts/OpenMgrShiftModal.js";
import MgrAvailModal from "../components/MgrAvailModal.js";
import InstallModal from "../components/InstallModal.js";
import ResolutionModal from "../components/ResolutionModal.js";

import UserSchedulePopUp from "../components/UserSchedulePopUp.js";
import MySwapsModal from "./MySwapsModal.js";
import MyReqsDataBox from "./requests/MyReqsDataBox";
import TeamSwapsModal from "./TeamSwapsModal.js";
import DayCommentModal from "./DayCommentModal.js";
import CreateFormModal from "./CreateFormModal";
import FormModal from "./FormModal";
import ViewTeamDayModal from "./ViewTeamDayModal";
import ClosedDaysTimelineModal from "./account/admin/ClosedDaysTimelineModal";
import ViewZoneModal from "./ViewZoneModal";
import AddUserModal from "./AddUserModal";
import AddShiftModal2 from "./account/admin/subcomponents/AddShiftModal2";
import AddAbsenceModal from "../components/AddAbsenceModal";

import ViewTeamModal from "./ViewTeamModal";
import CreateTimesheetModal from "./CreateTimesheetModal";
import ViewTimesheetModal from "./ViewTimesheetModal";
import ViewTeamUserModal from "./ViewTeamUserModal";
import ViewSpecialDayModal from "./ViewSpecialDayModal";
import ViewLeaveTypeModal from "./ViewLeaveTypeModal";
import ViewAbsenceTypeModal from "./ViewAbsenceTypeModal";
import MgrAcceptanceModal from "./MgrAcceptanceModal.js";
import MyDocsModal from "../components/MyDocsModal.js";

const Navbar = () => {
  const {
    selectedNav,
    changeNavTo,
    hideNavbar,
    reqsPage,
    setReqsPage,
    showAddLeaveOnTeam,
    setShowAddLeaveOnTeam,
    setSelectedNav,
    showAddAvailModal,
    setShowAddAvailModal,
    setOpenManagerShiftModal,
    openManagerShiftModal,
    showUserReqFromNav,
    showUserAbsenceModal,
    showMyShiftModal,
    showEditUser,
    showClockOnModal,
    setShowClockOnModal,
    page,
    setPage,
    setSettingsAlowances,
    clockOnID,
    showUnclocked,
    userIsClockedOn,
    setUserIsClockedOn,
    setUnclockedQty,
    setActiveClocksQty,
    updateHeaderClocks,
    showActiveClocks,
    setShowMessages,
    showMessages,
    msgQty,
    setMsgQty,
    showReqMgrModal,
    showEditShiftModal,
    showMgrAvailModal,
    showInstallModal,
    setShowInstallModal,
    device,
    setDevice,
    seeUserID,
    swap,
    setSwap,
    showSwapsModal,
    setShowSwapsModal,
    inboundSwapsQty,
    setInboundSwapsQty,
    setOutboundSwapsQty,
    showTeamSwapsModal,
    setTeamSwapsQty,
    showDayCommentModal,
    showUserOpenShiftModal,
    outstandingReqsQty,
    setOutstandingReqsQty,
    staffHoursQty,
    setStaffHoursQty,
    showAddLeaveModal,
    setShowAddLeaveModal,
    absencePage,
    setAbsencePage,
    showCreateFormModal,
    showForm,
    setShowChanges,
    showContact,
    setShowContact,
    showViewTeamDayModal,
    setShowViewTeamDayModal,
    setCalendarPage,
    showClosedDaysHistory,
    absentQty,
    setAbsentQty,
    viewZoneModal,
    showAddUserModal,
    setShowAddUserModal,
    showViewTeamModal,
    showAddTeamModal,
    setShowAddTeamModal,
    setShowViewTeamModal,
    setUpdateTeam,
    userIsOnClockedBreak,
    setUserIsOnClockedBreak,
    showTimesheet,
    setShowTimesheet,
    selectedDesktop,
    setSelectedDesktop,
    showViewTimesheet,
    showViewUserTeamModal,
    setUpcomingRequiringClocks,
    goToLimits,
    setGoToLimits,
    setShowReqStats,
    showReqStats,
    selectedYear,
    setSelectedYear,
    showSpecialDayModal,
    showLeaveTypeModal,
    showAbsenceTypeModal,
    unreadNotifs,
    setUnreadNotifs,
    showNotifications,
    setShowNotifications,
    itemType,
    itemID,
    standalone,
    setMgrHasAcceptTeams,
    setAcceptDecline,
    showMgrAcceptanceModal,
    showMobMenu,
    setShowMobMenu,
    showUpdatedReqsModal,
    setShowUpdatedReqsModal,
    setShowReqMgrModal,
    noPermissions,
    setNoPermissions,
    showConvertToilModal,
    showAddAbsenceModal,
    setShowAddAbsenceModal,
    setShowMgrAvailModal,
    showMyDocsModal,
    setShowMyDocsModal,
    indicate,
  } = useContext(DataContext);

  const { showReqModal, setShowReqModal, setShowAdd } =
    useContext(RequestContext);

  const { checkUserState, currencySymbol, setCheckUserState } =
    useContext(UserContext);

  const {
    calPage,
    setUserShiftsPage,
    showOpenShiftModal,
    setShiftsPage,
    bulkEditLimitObj,
  } = useContext(CalendarContext);

  const {
    mob,
    setMob,
    setMobModal,
    mobModal,
    setWidth,
    full,
    setFull,
    width,
    setFullButNarrow,
    fullButNarrow,
  } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      selectedNav,
      changeNavTo,
      hideNavbar,
      reqsPage,
      setReqsPage,
      showAddLeaveOnTeam,
      setShowAddLeaveOnTeam,
      setSelectedNav,
      showAddAvailModal,
      setShowAddAvailModal,
      setOpenManagerShiftModal,
      openManagerShiftModal,
      showUserReqFromNav,
      showUserAbsenceModal,
      showMyShiftModal,
      showEditUser,
      showClockOnModal,
      setShowClockOnModal,
      page,
      setPage,
      setSettingsAlowances,
      clockOnID,
      showUnclocked,
      userIsClockedOn,
      setUserIsClockedOn, //
      setUnclockedQty,
      setActiveClocksQty,
      updateHeaderClocks,
      showActiveClocks,
      setShowMessages,
      showMessages,
      msgQty,
      setMsgQty,
      showReqMgrModal,
      showEditShiftModal,
      showMgrAvailModal,
      showInstallModal,
      setShowInstallModal,
      device,
      setDevice, //
      seeUserID,
      swap,
      setSwap,
      showSwapsModal,
      setShowSwapsModal,
      inboundSwapsQty,
      setInboundSwapsQty,
      setOutboundSwapsQty,
      showTeamSwapsModal,
      setTeamSwapsQty,
      showDayCommentModal,
      showUserOpenShiftModal,
      outstandingReqsQty,
      setOutstandingReqsQty,
      staffHoursQty,
      setStaffHoursQty,
      showAddLeaveModal,
      absencePage,
      setAbsencePage,
      showCreateFormModal,
      showForm,
      setShowChanges,
      showContact,
      setShowContact,
      showViewTeamDayModal,
      setShowViewTeamDayModal,
      setCalendarPage,
      showClosedDaysHistory,
      absentQty,
      setAbsentQty,
      viewZoneModal,
      showAddUserModal,
      setShowAddUserModal,
      showViewTeamModal,
      showAddTeamModal,
      setShowAddTeamModal,
      setShowViewTeamModal,
      setUpdateTeam,
      userIsOnClockedBreak,
      setUserIsOnClockedBreak,
      showTimesheet,
      setShowTimesheet,
      selectedDesktop,
      setSelectedDesktop,
      showViewTimesheet,
      showViewUserTeamModal,
      setUpcomingRequiringClocks,
      goToLimits,
      setGoToLimits,
      setShowReqStats,
      showReqStats,
      selectedYear,
      setSelectedYear,
      showSpecialDayModal,
      showLeaveTypeModal,
      showAbsenceTypeModal,
      unreadNotifs,
      setUnreadNotifs,
      showNotifications,
      setShowNotifications,
      itemType,
      itemID,
      standalone,
      setMgrHasAcceptTeams,
      setAcceptDecline,
      showMgrAcceptanceModal,
      showMobMenu,
      setShowMobMenu,
      showUpdatedReqsModal,
      setShowUpdatedReqsModal,
      setShowReqMgrModal,
      noPermissions,
      setNoPermissions,
      showReqModal,
      setShowReqModal,
      setShowAdd,
      checkUserState,
      currencySymbol,
      calPage,
      setUserShiftsPage,
      showOpenShiftModal,
      setShiftsPage,
      bulkEditLimitObj,
      mob,
      setMob,
      setMobModal,
      mobModal,
      setWidth,
      full,
      setFull,
      width,
      setFullButNarrow,
      fullButNarrow,
      showConvertToilModal,
      showAddAbsenceModal,
      setShowAddAbsenceModal,
      setShowAddLeaveModal,
      setShowMgrAvailModal,
      showMyDocsModal,
      setShowMyDocsModal,
      indicate,
    }),
    [
      selectedNav,
      changeNavTo,
      hideNavbar,
      reqsPage,
      setReqsPage,
      showAddLeaveOnTeam,
      setShowAddLeaveOnTeam,
      setSelectedNav,
      showAddAvailModal,
      setShowAddAvailModal,
      setOpenManagerShiftModal,
      openManagerShiftModal,
      showUserReqFromNav,
      showUserAbsenceModal,
      showMyShiftModal,
      showEditUser,
      showClockOnModal,
      setShowClockOnModal,
      page,
      setPage,
      setSettingsAlowances,
      clockOnID,
      showUnclocked,
      userIsClockedOn,
      setUserIsClockedOn, //
      setUnclockedQty,
      setActiveClocksQty,
      updateHeaderClocks,
      showActiveClocks,
      setShowMessages,
      showMessages,
      msgQty,
      setMsgQty,
      showReqMgrModal,
      showEditShiftModal,
      showMgrAvailModal,
      showInstallModal,
      setShowInstallModal,
      device,
      setDevice, //
      seeUserID,
      swap,
      setSwap,
      showSwapsModal,
      setShowSwapsModal,
      inboundSwapsQty,
      setInboundSwapsQty,
      setOutboundSwapsQty,
      showTeamSwapsModal,
      setTeamSwapsQty,
      showDayCommentModal,
      showUserOpenShiftModal,
      outstandingReqsQty,
      setOutstandingReqsQty,
      staffHoursQty,
      setStaffHoursQty,
      showAddLeaveModal,
      absencePage,
      setAbsencePage,
      showCreateFormModal,
      showForm,
      setShowChanges,
      showContact,
      setShowContact,
      showViewTeamDayModal,
      setShowViewTeamDayModal,
      setCalendarPage,
      showClosedDaysHistory,
      absentQty,
      setAbsentQty,
      viewZoneModal,
      showAddUserModal,
      setShowAddUserModal,
      showViewTeamModal,
      showAddTeamModal,
      setShowAddTeamModal,
      setShowViewTeamModal,
      setUpdateTeam,
      userIsOnClockedBreak,
      setUserIsOnClockedBreak,
      showTimesheet,
      setShowTimesheet,
      selectedDesktop,
      setSelectedDesktop,
      showViewTimesheet,
      showViewUserTeamModal,
      setUpcomingRequiringClocks,
      goToLimits,
      setGoToLimits,
      setShowReqStats,
      showReqStats,
      selectedYear,
      setSelectedYear,
      showSpecialDayModal,
      showLeaveTypeModal,
      showAbsenceTypeModal,
      unreadNotifs,
      setUnreadNotifs,
      showNotifications,
      setShowNotifications,
      itemType,
      itemID,
      standalone,
      setMgrHasAcceptTeams,
      setAcceptDecline,
      showMgrAcceptanceModal,
      showMobMenu,
      setShowMobMenu,
      showUpdatedReqsModal,
      setShowUpdatedReqsModal,
      setShowReqMgrModal,
      noPermissions,
      setNoPermissions,
      showReqModal,
      setShowReqModal,
      setShowAdd,
      checkUserState,
      currencySymbol,
      calPage,
      setUserShiftsPage,
      showOpenShiftModal,
      setShiftsPage,
      bulkEditLimitObj,
      mob,
      setMob,
      setMobModal,
      mobModal,
      setWidth,
      full,
      setFull,
      width,
      setFullButNarrow,
      fullButNarrow,
      showConvertToilModal,
      showAddAbsenceModal,
      setShowAddAbsenceModal,
      setShowAddLeaveModal,
      setShowMgrAvailModal,
      showMyDocsModal,
      setShowMyDocsModal,
      indicate,
    ]
  );

  const element = document.querySelector(".multi-widget-ui");
  if (element) {
    element.style.display = "none !important";
  }
  let [addTeamNameVal, setAddTeamNameVal] = useState("");

  let [showUpcoming, setShowUpcoming] = useState(false);
  let [showAddDesktop, setShowAddDesktop] = useState(false);
  let [initial, setInitial] = useState(true);
  let [showDesktopMenu, setShowDesktopMenu] = useState(false);
  let [promptAcioned, setPromptActioned] = useState(false);

  let [showResolutionModal, setShowResolutionModal] = useState(false);

  let [showAdminActions, setShowAdminActions] = useState(false);

  useEffect(() => {
    memoVals.setUnreadNotifs((x) => 0);
  }, [memoVals.showNotifications]);

  useEffect(() => {
    if (memoVals.width < 360 && memoVals.device.os === "Android") {
      if (
        !sessionStorage.getItem("hideResolutionModal") ||
        sessionStorage.getItem("hideResolutionModal") === null
      ) {
        console.log("Showing res modal");
        setShowResolutionModal(true);
      }
    } else {
      setShowResolutionModal(false);
    }
  }, [memoVals.width]);

  if ("onbeforeinstallprompt" in window) {
    // Add an event listener to catch the 'beforeinstallprompt' event
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the default behavior, which is to prompt the user to install the PWA
      event.preventDefault();

      // Store the 'beforeinstallprompt' event for later use
      const deferredPrompt = event;

      // Get the reference to the installButton element
      const installButton = document.getElementById("installButton");

      // Show the installButton element when the 'beforeinstallprompt' event is triggered
      installButton.style.display = "block";

      // Add an onclick event handler to trigger the PWA installation
      installButton.onclick = () => {
        // Show the native browser install prompt when the custom button is clicked
        deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          setPromptActioned(true);
          // Handle the user's choice if needed
          // The 'prompt' method returns a Promise, but it's not necessary to handle it here
        });
      };
    });
  }

  useEffect(() => {
    // clean not needed
    var browserName = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "MS Edge";
        case agent.indexOf("edg/") > -1:
          return "Edge ( chromium based)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "chrome";
        case agent.indexOf("trident") > -1:
          return "MS IE";
        case agent.indexOf("firefox") > -1:
          return "Mozilla Firefox";
        case agent.indexOf("safari") > -1:
          return "Safari";
        default:
          return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    let device = "desktop";

    if (navigator.userAgent.match(/Android/i)) {
      device = "android";
    }
    if (navigator.userAgent.match(/iPhone/i)) {
      device = "iphone";
    }

    // progressier-widget-logo
    // Get all elements with the class 'my-image-class'
    var imgElements = document.getElementsByClassName(
      "progressier-widget-logo"
    );

    // Check if there are any elements with the specified class
    // if (imgElements.length > 0) {
    // Change the src attribute of the first element with the class 'my-image-class'
    // imgElements[0].src = installBtn;
    // }

    function getOperatingSystem() {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;

      if (/Win/i.test(platform)) {
        return "Windows";
      } else if (/Mac/i.test(platform)) {
        return "Mac OS";
      } else if (/Android/i.test(userAgent)) {
        return "Android";
      } else if (/Linux/i.test(platform)) {
        return "Linux";
      } else if (/iOS|iPhone|iPad|iPod/i.test(userAgent)) {
        return "iOS";
      } else {
        return "Unknown";
      }
    }

    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );

    memoVals.setDevice((x) => {
      return {
        os: getOperatingSystem(),
        browser: browserName,
        device: window.matchMedia("(pointer:coarse)").matches
          ? "mobile"
          : "desktop",
        fullScreen: window.matchMedia("(display-mode: standalone)").matches
          ? true
          : false,
        vhIssue:
          window.matchMedia("(pointer:coarse)").matches &&
          !window.matchMedia("(display-mode: standalone)").matches,
        tablet: isTablet,
      };
    });

    if (
      window.matchMedia("(pointer:coarse)").matches &&
      !window.matchMedia("(display-mode: standalone)").matches &&
      !sessionStorage.getItem("blockInstall")
    ) {
      memoVals.setShowInstallModal((x) => true);
    }
  }, [memoVals.mob, memoVals.full]);

  let [mobileWidthBreak, setMobileWidthBreak] = useState(820);
  let [mobModalWidthBreak, setMobModalWidthBreak] = useState(500);
  let [fullWidthBreak, setFullWidthBreak] = useState(1000);

  useEffect(() => {
    // clean not needed

    if (!memoVals.showTimesheet) {
      setLaunchTimesheet(false);
    }
  }, [memoVals.showTimesheet]);

  function handleWindowSizeChange() {
    memoVals.setWidth((x) => window.innerWidth);

    let widthBrk = mobileWidthBreak;

    if (window.innerWidth < widthBrk) {
      memoVals.setMob((x) => true);
    } else {
      memoVals.setMob((x) => false);
    }

    if (window.innerWidth < mobModalWidthBreak) {
      if (
        window.matchMedia("(pointer:coarse)").matches &&
        !window.matchMedia("(display-mode: standalone)").matches
      ) {
        memoVals.setMobModal((x) => false);
      } else {
        memoVals.setMobModal((x) => true);
      }
      // memoVals.setMobModal( x=>true);
    } else {
      memoVals.setMobModal((x) => false);
    }

    if (window.innerWidth >= fullWidthBreak) {
      memoVals.setFull((x) => true);
    } else {
      memoVals.setFull((x) => false);
    }

    memoVals.setShowNotifications((x) => false);
    setShowUpcoming(false);
    setShowAddDesktop(false);

    if (window.innerWidth >= 1000 && window.innerWidth < 1080) {
      memoVals.setFullButNarrow((x) => true);
    } else {
      memoVals.setFullButNarrow((x) => false);
    }
  }

  let [rota, setRota] = useState(false);
  // setMobModal(true);
  useEffect(() => {
    // clean not needed
    let widthBrk = mobileWidthBreak;
    if (
      window.location.href.includes("/rota") ||
      window.location.href.includes("/team-rota") ||
      window.location.href.includes("staff-calendar")
    ) {
      setRota(true);
    }
    if (window.innerWidth < widthBrk) {
      memoVals.setMob((x) => true);
    } else {
      memoVals.setMob((x) => false);
    }

    if (window.innerWidth < mobModalWidthBreak) {
      if (
        window.matchMedia("(pointer:coarse)").matches &&
        !window.matchMedia("(display-mode: standalone)").matches
      ) {
        memoVals.setMobModal((x) => false);
      } else {
        memoVals.setMobModal((x) => true);
      }
      // memoVals.setMobModal( x=>true);
    } else {
      memoVals.setMobModal((x) => false);
    }
    if (window.innerWidth >= fullWidthBreak) {
      memoVals.setFull((x) => true);
    } else {
      memoVals.setFull((x) => false);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [memoVals.calPage]);

  useEffect(() => {
    // clean not needed
    if (memoVals.checkUserState.permissions < 3 && !initial) {
      memoVals.setReqsPage((x) => "team");
      setInitial(true);
    } else {
      memoVals.setReqsPage((x) => "upcoming");
    }
  }, []);

  let renderBulkLimitModal = () => {
    if (memoVals.bulkEditLimitObj.teamID) {
      return <BulkEditLimitsModal />;
    }
  };
  let renderClockOnModal = () => {
    if (memoVals.showClockOnModal) {
      return <ClockOnModal />;
    }
  };

  let renderAddUserModal = () => {
    if (memoVals.showAddUserModal) {
      return <AddUserModal />;
    }
  };

  let renderViewZoneModal = () => {
    if (memoVals.viewZoneModal && typeof memoVals.viewZoneModal === "string") {
      return <ViewZoneModal />;
    }
  };
  let renderViewTeamDayModal = () => {
    if (memoVals.showViewTeamDayModal) {
      return (
        <ViewTeamDayModal
          ds={memoVals.showViewTeamDayModal.ds}
          teamID={memoVals.showViewTeamDayModal.teamID}
          closeModal={memoVals.setShowViewTeamDayModal}
          isMgr={memoVals.showViewTeamDayModal.isMgr}
        />
      );
    }
  };

  let renderForm = () => {
    if (memoVals.showForm.formID) {
      return <FormModal />;
    }
  };

  let renderCreateFormModal = () => {
    if (memoVals.showCreateFormModal) {
      return <CreateFormModal />;
    }
  };

  let renderDayCommentModal = () => {
    if (memoVals.showDayCommentModal.show) {
      return <DayCommentModal />;
    }
  };

  let renderSwapsModal = () => {
    if (memoVals.showSwapsModal) {
      return <MySwapsModal />;
    }
  };

  let renderTeamSwapsModal = () => {
    if (memoVals.showTeamSwapsModal) {
      return <TeamSwapsModal />;
    }
  };

  let renderMgrAcceptanceModal = () => {
    if (memoVals.showMgrAcceptanceModal) {
      return <MgrAcceptanceModal />;
    }
  };

  let renderSwapModal = () => {
    if (memoVals.swap && memoVals.swap.shiftID) {
      return (
        <SwapModal
          shiftID={memoVals.swap.shiftID}
          type={memoVals.swap.type}
          setSwapParent={memoVals.setSwap}
        />
      );
    }
  };

  let renderAddLeaveModal = () => {
    if (memoVals.showAddLeaveModal && memoVals.showAddLeaveModal.show) {
      return <AddLeaveModal />;
    }
  };

  // let renderShowShiftModal = () => {
  //   console.log("shoftShift: ", showShift);

  //   if (showShift && showShift.shiftID) {
  //     return (
  //       <ShowShift
  //         type={showShift.type}
  //         shiftID={showShift.shiftID}
  //         setShowShiftUnit={setShowShift}
  //       />
  //     );
  //   }
  // };
  let [hover, setHover] = useState("");

  let [showPopUpInstall, setShowPopUpInstall] = useState(false);

  useEffect(() => {
    if (memoVals.goToLimits) {
      memoVals.setGoToLimits((x) => false);
    }
  }, [memoVals.calPage, memoVals.page]);
  useEffect(() => {
    // clean not needed
    let showPopUp = false;

    if (memoVals.checkUserState.validUser) {
      let lastClosedTs = dateStringer.createTimestampFromString(
        memoVals.checkUserState.lastClosedInstall || "Y2020M00D01H00M00"
      );
      let lastSetDontShowTs = dateStringer.createTimestampFromString(
        memoVals.checkUserState.hideInstall || "Y2020M00D01H00M00"
      );

      let now = new Date().getTime();

      let msSinceLastSetDontShowTs = now - lastSetDontShowTs;
      let msSinceLastClosedTs = now - lastClosedTs;
      let hours24Ms = 24 * 60 * 60 * 1000;

      if (msSinceLastSetDontShowTs > hours24Ms * 90) {
        // last set dont show was set over 30 days ago
        // check is the modal was closed within the last 24 hours -- if so, don't open, if not.. open
        if (msSinceLastClosedTs > hours24Ms) {
          showPopUp = true;
        } else {
          // don't open
        }
      } else {
        // don't open
      }

      // if (!memoVals.checkUserState.hideInstall) {
      //   showPopUp = true;
      // } else {
      //   // hide exists

      //   // if hidden over 30 days ago, need to show it
      //   let daysDiff = Math.round(
      //     (new Date().getTime() -
      //       dateStringer.createTimestampFromString(
      //         memoVals.checkUserState.hideInstall
      //       )) /
      //       86400000
      //   );

      //   if (daysDiff > 30) {
      //     console.log(
      //       "- showing install modal because it's been >30 days since Dont Show Again was pressed"
      //     );
      //     showPopUp = true;
      //   } else {
      //     if (memoVals.checkUserState.lastClosedInstall) {
      //       let lastClosedTs = dateStringer.createTimestampFromString(
      //         memoVals.checkUserState.lastClosedInstall
      //       );
      //       if (new Date().getTime() - lastClosedTs > 1000 * 60 * 60 * 24) {
      //         console.log(
      //           "- showing install modal because it's been >1 day since modal was closed, and Dont Show Again was not selected pressed"
      //         );

      //         showPopUp = true;
      //       }
      //     }
      //   }

      //   console.log("QQQQQQ ----", daysDiff, showPopUp);
      // }

      if (memoVals.device.vhIssue && !memoVals.device.fullScreen) {
        setShowPopUpInstall(showPopUp);
      }
    }
  }, [
    memoVals.checkUserState.hideInstall,
    memoVals.checkUserState.lastClosedInstall,
  ]);

  let renderInstallModal = () => {
    if (memoVals.showInstallModal) {
      return (
        <InstallModal
          setShowInstallModal={
            showPopUpInstall
              ? setShowPopUpInstall
              : memoVals.setShowInstallModal
          }
          device={memoVals.device}
          popUp={showPopUpInstall}
        />
      );
    }
  };

  let renderMgrAvailModal = () => {
    if (memoVals.showMgrAvailModal) {
      return (
        <MgrAvailModal selectedUserID={memoVals.showMgrAvailModal.userID} />
      );
    }
  };

  let renderHistoryModal = () => {
    if (memoVals.showClosedDaysHistory) {
      console.log("SHOW CLOSED DAYS HISTORY MODAL");
      return (
        <ClosedDaysTimelineModal
          userID={
            typeof memoVals.showClosedDaysHistory === "string"
              ? memoVals.showClosedDaysHistory
              : ""
          }
        />
      );
    }
  };

  let renderClockItemModal = () => {
    if (memoVals.clockOnID && !memoVals.clockOnID.isMgr) {
      return <ClockItemModal id={memoVals.clockOnID.clockOnID} isMgr={false} />;
    } else if (memoVals.clockOnID && memoVals.clockOnID.isMgr) {
      return <ClockItemModal id={memoVals.clockOnID.clockOnID} isMgr={true} />;
    } else {
      return "";
    }
  };

  let navRef = useRef();

  let [launchTimesheet, setLaunchTimesheet] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (launchTimesheet === "new2") {
      memoVals.setShowTimesheet((x) => "new");
    } else {
      if (launchTimesheet) {
        axios
          .post(
            `${serverURL}/launch-timesheet`,
            {
              someData: 1,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              console.log(response.data);
              if (response.data.openTimesheets[0]) {
                memoVals.setShowTimesheet((x) => {
                  return {
                    optionsArr: [...response.data.openTimesheets],
                    ableToLaunchNew: response.data.ableToLaunchNew,
                    options: true,
                  };
                });
              } else {
                setShowTimesheet((x) => "new");
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [launchTimesheet]);

  let [mobHomeQty, setMobHomeQty] = useState(0);
  // this is acceptDecline.accept + acceptDecline.decline + activeClocksQty + unclockedQty

  useEffect(() => {
    if (!window.location.href.includes("view-rota")) {
      const cancelSource1 = axios.CancelToken.source();
      const cancelSource2 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-header-clocks`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            previewOnly: true,
            calledFromMobileNavOnly: true,
            getShiftEditsForMgr: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setMobHomeQty(
              (response.data.qty || 0) +
                (response.data.activeQty || 0) +
                (response.data.accept || 0) +
                (response.data.declinedAccept || 0)
            );
            let val = JSON.stringify(
              (response.data.qty || 0) +
                (response.data.activeQty || 0) +
                (response.data.accept || 0) +
                (response.data.declinedAccept || 0)
            );

            localStorage.setItem("mobHomeQty", val);

            memoVals.setUnclockedQty((x) => response.data.unclocksQty);
            memoVals.setActiveClocksQty((x) => response.data.activeQty);
            memoVals.setUpcomingRequiringClocks(
              (x) => response.data.upcomingRequiringClocks
            );
            memoVals.setMsgQty((x) => response.data.msgQty);

            memoVals.setUserIsClockedOn((x) => response.data.userIsClockedOn);
            memoVals.setUserIsOnClockedBreak(
              (x) => response.data.userIsOnClockedBreak
            );
            memoVals.setOutboundSwapsQty((x) => response.data.outboundSwapsQty);
            memoVals.setInboundSwapsQty((x) => response.data.inboundSwapsQty);
            memoVals.setTeamSwapsQty((x) => response.data.teamSwapsQty);
            memoVals.setAbsentQty((x) => response.data.absentQty);
            // setCountMyChanges(response.data.countMyChanges);
            memoVals.setOutstandingReqsQty((x) => response.data.outReqIDs);
            memoVals.setUnreadNotifs((x) => response.data.unreadNotifs);
            memoVals.setMgrHasAcceptTeams(
              (x) => response.data.mgrHasAcceptTeams
            );
            memoVals.setStaffHoursQty((x) => response.data.shiftEditsQty || 0);

            setAcceptDecline((x) => {
              return {
                accepted: response.data.accept,
                declined: response.data.declinedAccept,
              };
            });

            // if (response.data.isMgr) {
            //   axios
            //     .post(
            //       `${serverURL}/get-shift-edits-for-mgr`,
            //       {
            //         teamID: "allStaff",
            //         countOnly: true,
            //       },

            //       {
            //         withCredentials: true,
            //         credentials: "include",
            //         cancelToken: cancelSource2.token,
            //       }
            //     )
            //     .then((response) => {
            //       if (response.data.message === "success") {
            //         memoVals.setStaffHoursQty((x) => response.data.qty || 0);
            //       }
            //     })
            //     .catch((err) => {
            //       console.error(err);
            //     });
            // }

            // memoVals.setOutboundSwapsQty(x =>0);
            // memoVals.setInboundSwapsQty(x =>0);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [memoVals.mob, memoVals.updateHeaderClocks, memoVals.page]);

  let [showAddOptions, setShowAddOptions] = useState(false);

  let f1 = burger;
  let f2 = cal;
  let f3 = requests;
  let f4 = sched;
  let f5 = memoVals.checkUserState.permissions < 3 ? accMgr : acc;

  if (
    window.location.href.includes("account") ||
    window.location.href.includes("dash")
  ) {
    memoVals.setSelectedNav((x) => 5.1);
  }

  // conditionals for f1
  if (memoVals.selectedNav === 1.1) {
    f1 = burgerHome;
    f2 = calHome;
    f3 = requestsHome;
    f5 = memoVals.checkUserState.permissions < 3 ? accHomeMgr : accHome;
  } else if (memoVals.selectedNav === 1) {
    f1 = burgerHome;
    f2 = calHome;
    f3 = requestsHome;
    f5 = memoVals.checkUserState.permissions < 3 ? accHomeMgr : accHome;
  }
  // conditionals for f2
  if (
    window.location.href.includes("/rota") ||
    window.location.href.includes("/team-rota") ||
    window.location.href.includes("schedule") ||
    window.location.href.includes("calendar") ||
    window.location.href.includes("absence") ||
    window.location.href.includes("hours")
  ) {
    f2 = calSelected;

    if (window.location.href.includes("team")) {
      f2 = calMselected;
    }
  }

  // conditionals for f3
  if (memoVals.selectedNav === 3.1) {
    f3 = requestsSelected;
  } else if (memoVals.selectedNav === 3) {
    f3 = requests;
  } else if (memoVals.selectedNav === 3.2) {
    f3 = requestsMselected;
  }

  // conditionals for f4
  if (memoVals.selectedNav === 4.1) {
    f4 = sched;
  } else if (memoVals.selectedNav === 4) {
    f4 = sched;
  } else if (memoVals.selectedNav === 4.2) {
    f4 = sched;
  }

  // conditionals for f5
  if (
    memoVals.selectedNav === 5.1 ||
    window.location.href.includes("account")
  ) {
    f5 = memoVals.checkUserState.permissions < 3 ? accSelectedMgr : accSelected;
  } else if (memoVals.selectedNav === 5) {
    f5 = memoVals.checkUserState.permissions < 3 ? accMgr : acc;
  } else if (memoVals.selectedNav === 5.2) {
    f5 = accMselected;
  }

  let renderReq = () => {
    if (memoVals.showReqModal) {
      return (
        <MakeLeaveReq
          // reqID={
          //   memoVals.showReqModal === "string"
          //     ? ""
          //     : memoVals.showReqModal.reqID
          // }
          // leaveTypeID={
          //   memoVals.showReqModal === "string"
          //     ? ""
          //     : memoVals.showReqModal.leaveTypeID
          // }
          ds={
            typeof memoVals.showReqModal === "string"
              ? memoVals.showReqModal
              : ""
          }
        />
      );
    } else {
      return "";
    }
  };

  let renderUnclocks = () => {
    if (memoVals.showUnclocked) {
      return <UnclocksModal />;
    } else {
      return "";
    }
  };

  let renderMessages = () => {
    if (memoVals.showMessages) {
      return <MessagesModal />;
    } else {
      return "";
    }
  };

  let renderActiveClocks = () => {
    if (memoVals.showActiveClocks) {
      return <ActiveClocksModal />;
    } else {
      return "";
    }
  };

  let renderAbsenceModal = () => {
    if (memoVals.showUserAbsenceModal.absenceID) {
      return (
        <AbsenceUnitModal
          absenceID={memoVals.showUserAbsenceModal.absenceID}
          isMgr={memoVals.showUserAbsenceModal.isMgr}
        />
      );
    }
  };

  let renderLeaveReqModal = () => {
    if (memoVals.showReqMgrModal && memoVals.showReqMgrModal.length > 4) {
      return (
        <LeaveReqModal
          unitReqID={memoVals.showReqMgrModal}
          unitIsUserReq={false}
        />
      );
    }
  };

  let renderUserReq = () => {
    if (memoVals.showUserReqFromNav) {
      // if memoVals.showUserReqFromNav has * at the start, it is an absence. If there is an # in it, it is in the past
      let isAbsence = false;
      if (
        memoVals.showUserReqFromNav &&
        memoVals.showUserReqFromNav.includes("*")
      ) {
        isAbsence = true;
      }

      let reqID = memoVals.showUserReqFromNav.replace("*", "");

      return (
        <UserLeaveReqModal
          unitReqID={reqID}
          reqIsPast={
            isAbsence ? false : memoVals.reqsPage === "past" ? true : false
          }
        />
      );
    } else {
      return "";
    }
  };

  let renderAddAvailModal = () => {
    if (memoVals.showAddAvailModal) {
      if (typeof memoVals.showAddAvailModal === "object") {
        return (
          <AddAvailModal
            monWeek={memoVals.showAddAvailModal.monWeek}
            loadTeamID={memoVals.showAddAvailModal.loadTeamID}
          />
        );
      } else {
        return <AddAvailModal monWeek={dateStringer.getCurrMonWeek()} />;
      }
    } else {
      return "";
    }
  };

  let renderOpenShiftModal = () => {
    if (memoVals.showOpenShiftModal && memoVals.showOpenShiftModal.ds) {
      return (
        <OpenMgrShiftModal
          unitDs={memoVals.showOpenShiftModal.ds}
          unitTeamID={memoVals.showOpenShiftModal.teamID}
          unitItemIsNew={memoVals.showOpenShiftModal.new}
          unitOpenID={memoVals.showOpenShiftModal.openID}
        />
      );
    }
  };
  let renderManagerShiftModal = () => {
    if (memoVals.showEditShiftModal && memoVals.showEditShiftModal.itemID) {
      return (
        <ManagerShiftModal
          getFromServerID={memoVals.showEditShiftModal.itemID}
          unitType={memoVals.showEditShiftModal.itemType}
          unitFName={memoVals.showEditShiftModal.itemFName}
          unitLName={memoVals.showEditShiftModal.itemLName}
          unitIsNew={memoVals.showEditShiftModal.itemIsNew}
          unitUserID={memoVals.showEditShiftModal.itemUserID}
          makeAbsent={memoVals.showEditShiftModal.makeAbsent}
        />
      );
    }

    if (
      memoVals.openManagerShiftModal &&
      memoVals.openManagerShiftModal.shiftID
    ) {
      return (
        <ManagerShiftModal
          getFromServerID={memoVals.openManagerShiftModal.shiftID}
          unitType="shift"
          unitFName={memoVals.openManagerShiftModal.fName}
          unitLName={memoVals.openManagerShiftModal.lName}
          openedFromShiftsPage={
            memoVals.openManagerShiftModal.openedFromShiftsPage
          }
          setShowEditShiftModal={memoVals.setOpenManagerShiftModal}
        />
      );
    }

    if (
      memoVals.openManagerShiftModal &&
      memoVals.openManagerShiftModal.tilID
    ) {
      return (
        <ManagerShiftModal
          getFromServerID={memoVals.openManagerShiftModal.tilID}
          unitType={memoVals.openManagerShiftModal.type}
          unitFName={memoVals.openManagerShiftModal.fName}
          unitLName={memoVals.openManagerShiftModal.lName}
          openedFromShiftsPage={
            memoVals.openManagerShiftModal.openedFromShiftsPage
          }
          setShowEditShiftModalParent={memoVals.setOpenManagerShiftModal}
        />
      );
    }
  };

  let renderMyShiftModal = () => {
    if (memoVals.showMyShiftModal.unitID) {
      console.log("rendering my shift: ", memoVals.showMyShiftModal);
      return (
        <MyShiftModal
          unitType={memoVals.showMyShiftModal.unitType}
          unitID={memoVals.showMyShiftModal.unitID}
          otherUser={memoVals.showMyShiftModal.otherUser || false}
        />
      );
    }
  };

  let renderUserOpenShiftModal = () => {
    if (memoVals.showUserOpenShiftModal) {
      console.log("rendering my shift: ", memoVals.showMyShiftModal);
      return <UserOpenShiftModal />;
    }
  };
  let renderEditUser = () => {
    if (memoVals.showEditUser && typeof memoVals.showEditUser === "string") {
      return (
        <EditUser
          userID={memoVals.showEditUser}
          straightToAllowances={
            memoVals.selectedDesktop === "team-requests" ? true : false
          }
        />
      );
    }
    if (memoVals.showEditUser && typeof memoVals.showEditUser === "object") {
      return (
        <EditUser
          userID={memoVals.showEditUser.userID}
          straightToTeams={memoVals.showEditUser.teams}
          straightToAllowances={memoVals.showEditUser.allows}
          straightToData={memoVals.showEditUser.tab}
          monthArr={memoVals.showEditUser.monthArr}
        />
      );
    }
  };

  let renderUserSchedulePopUpModal = () => {
    if (memoVals.seeUserID && memoVals.seeUserID.userID) {
      return (
        <UserSchedulePopUp
          openedFromUserRota={memoVals.seeUserID.openedFromUserRota}
          openedFromMyAAccount={memoVals.seeUserID.openedFromMyAAccount}
        />
      );
    }
  };

  useEffect(() => {
    // clean not needed
    let url = window.location.href;
    if (!url.includes("/")) {
      memoVals.setSelectedDesktop((x) => "calendar");
    }
    if (url.includes("rota")) {
      if (url.includes("team")) {
        memoVals.setSelectedDesktop((x) => "team-rota");
      } else {
        memoVals.setSelectedDesktop((x) => "rota");
      }
    }
    if (url.includes("calendar")) {
      memoVals.setSelectedDesktop((x) => "calendar");
    }

    if (url.includes("staff-calendar")) {
      memoVals.setSelectedDesktop((x) => "staff-calendar");
    }

    if (url.includes("absence")) {
      if (url.includes("staff")) {
        memoVals.setSelectedDesktop((x) => "team-absence");
      } else {
        memoVals.setSelectedDesktop((x) => "absence");
      }
    }

    if (url.includes("hours")) {
      if (url.includes("team")) {
        memoVals.setSelectedDesktop((x) => "team-shifts");
        // setCalPage("team-shifts");
      } else {
        memoVals.setSelectedDesktop((x) => "shifts");
        // setCalPage("shifts");
        memoVals.setUserShiftsPage((x) => "shifts");
        memoVals.setShiftsPage((x) => "shifts");
      }
    }

    if (url.includes("staff-dashboard")) {
      memoVals.setSelectedDesktop((x) => "dashboard");
      memoVals.setPage((x) => "dashboard");
    }

    if (url.includes("swaps")) {
      if (url.includes("team")) {
        memoVals.setSelectedDesktop((x) => "team-shifts");
        // setCalPage("team-shifts");
        memoVals.setPage((x) => "team-swaps");
      } else {
        memoVals.setSelectedDesktop((x) => "shifts");
        // setCalPage("shifts");
        memoVals.setUserShiftsPage((x) => "shifts");
        memoVals.setShiftsPage((x) => "shifts");
        memoVals.setPage((x) => "swaps");
      }
    }

    if (url.includes("account")) {
      memoVals.setSelectedDesktop((x) => "account");
    }
    if (url.includes("leave")) {
      if (url.includes("team")) {
        memoVals.setSelectedDesktop((x) => "team-requests");
      } else {
        memoVals.setSelectedDesktop((x) => "requests");
      }
    }
    if (url.includes("reports")) {
      memoVals.setSelectedDesktop((x) => "reports");
    }
    if (url.includes("admin")) {
      memoVals.setSelectedDesktop((x) => "admin");
    }
    if (url.includes("dashboard")) {
      memoVals.setSelectedDesktop((x) => "dashboard");
    }

    if (url.includes("clock-ons")) {
      memoVals.setSelectedDesktop((x) => "shifts");
      memoVals.setPage((x) => "clock-ons");
      if (url.includes("team")) {
        memoVals.setSelectedDesktop((x) => "team-shifts");
        memoVals.setPage((x) => "team-clock-ons");
      }
    }
  }, [memoVals.calPage, memoVals.reqsPage]);

  let renderEditShiftModal = () => {
    if (memoVals.showEditShiftModal) {
      if (memoVals.itemType !== "shift") {
      }

      return (
        <ManagerShiftModal
          getFromServerID={memoVals.itemID}
          unitType={memoVals.itemType}
          // setShowEditShiftModalParent={setShowEditShiftModal}

          // unitFName={itemFName}
          // unitLName={itemLName}
        />
      );
    }
  };

  // ------------------------------------------------------------------------
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 140) {
      memoVals.setShowNotifications((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (memoVals.showNotifications) {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  }, [memoVals.showNotifications]);
  return (
    <div>
      {!memoVals.standalone && (
        <div
          className={`${
            memoVals.mob
              ? "none"
              : `desktopHeaderHolder ${rota ? "rotaDesktopHeaderHolder" : ""}`
          } ${
            window.location.href.includes("/rota") ||
            window.location.href.includes("/team-rota") ||
            window.location.href.includes("staff-calendar")
              ? "fixedLeft0"
              : ""
          }`}
        >
          <div className="desktopHeaderTopBarLogoAndClockOnDiv">
            <img
              src={logo}
              alt="FlowRota logo"
              className={`headerLogoTimelify ${
                rota ? "headerLogoTimelifyRota" : ""
              }`}
            />
            <img
              src={logoTxt}
              alt="FlowRota logo"
              className={`headerLogoTimelifyTxt ${
                rota ? "headerLogoTimelifyRotaTxt" : ""
              }`}
              onClick={() => {
                if (window.fcWidget) {
                  // Check if Freshdesk chat widget is available
                  window.fcWidget.open(); // Open Freshdesk live chat
                } else {
                  console.error("Freshdesk chat widget not initialized.");
                }
              }}
            />
            {/* <div
            className="deviceInfo"
            onClick={() => {
              console.log({ memoVals.device });
            }}
          >
            {memoVals.device}
          </div> */}
          </div>

          <div
            className={`desktopHeaderPanel ${
              rota ? "desktopHeaderPanelRota" : ""
            }`}
          >
            <div
              className={`desktopNotifbtn ${
                memoVals.unreadNotifs > 0 && !memoVals.showNotifications
                  ? "unreadNotifBtn"
                  : ""
              } ${memoVals.showNotifications ? "desktopNotifBtnBg" : ""}`}
              onClick={() => {
                memoVals.setShowNotifications(
                  (x) => !memoVals.showNotifications
                );
                setShowUpcoming(false);
              }}
            >
              <img
                src={
                  memoVals.showNotifications || memoVals.unreadNotifs > 0
                    ? bell2
                    : bell1
                }
                alt="Notification"
                className="desktopHeaderNotifImg"
              />
              {memoVals.unreadNotifs && !memoVals.showNotifications ? (
                <p className="unreadNotifQty">
                  {memoVals.unreadNotifs > 99 ? "99+" : memoVals.unreadNotifs}
                </p>
              ) : (
                ""
              )}
            </div>
            <div
              className={`desktopNotifbtn ${
                showUpcoming ? "desktopNotifBtnBg" : ""
              }`}
              onClick={() => {
                memoVals.setShowNotifications((x) => false);
                setShowUpcoming(!showUpcoming);
              }}
            >
              <img
                src={showUpcoming ? cal2 : cal3}
                alt="Notification"
                className="desktopHeaderCalImg"
              />
            </div>
            <div
              className={`desktopAddbtn ${
                showAddDesktop ? "desktopAddbtnSelected" : ""
              } ${rota ? "desktopAddBtnRota" : ""}`}
              onClick={() => {
                setShowAddDesktop(!showAddDesktop);
                memoVals.setShowNotifications((x) => false);
                setShowUpcoming(false);
              }}
            >
              {" "}
              <img
                src={showAddDesktop ? plus : plus}
                alt="Add"
                className="desktopHeaderAddImg"
              />
              <p className={`desktopPlusTxt ${rota ? "none" : ""}`}>Add</p>
            </div>
          </div>

          {memoVals.checkUserState.clockOnAllowed ? (
            <div
              className={`desktopClockOnDiv ${
                rota ? "rotaDesktopClockOnDiv" : ""
              }`}
              onClick={() => {
                console.log({ showConvertToilModal });
              }}
            >
              <img
                src={desktopBurger}
                alt="Menu"
                className={`desktopBurgerIcon ${
                  rota ? "desktopBurgerIconRota" : ""
                } ${showDesktopMenu ? "invis" : ""}`}
                onClick={() => {
                  setShowDesktopMenu(!showDesktopMenu);
                }}
              />
              {showDesktopMenu ? (
                <div
                  className="showDesktopMenuUnderlay"
                  onClick={() => {
                    setShowDesktopMenu(false);
                  }}
                ></div>
              ) : (
                ""
              )}

              {showDesktopMenu ? (
                <div
                  className={`desktopMenuFloater ${
                    rota ? "desktopMenuFloaterRota" : ""
                  }`}
                  onClick={() => {
                    setShowDesktopMenu(false);
                  }}
                >
                  <div
                    className="desktopBurgerMenu"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <p className="desktopBurgerTitle">As an employee</p>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setShowReqModal((x) => true);
                      }}
                    >
                      <p className="desktopBurgerItemBlob"></p>Make a leave
                      request
                    </div>
                    <NavLink to="/rota?today=true">
                      <div
                        className="desktopBurgerItem"
                        onClick={() => {
                          setShowDesktopMenu(false);
                        }}
                      >
                        <p className="desktopBurgerItemBlob"></p>View today's
                        rota
                      </div>
                    </NavLink>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setShowAddAvailModal((x) => true);
                      }}
                    >
                      <p className="desktopBurgerItemBlob"></p>Provide my
                      availability
                    </div>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setShowSwapsModal((x) => true);
                      }}
                    >
                      <p className="desktopBurgerItemBlob"></p>View my shift
                      swaps
                    </div>
                    <NavLink
                      to="/calendar"
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setGoToLimits((x) => true);
                      }}
                    >
                      {/* <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setGoToLimits(x =>true);
                      }}
                    > */}
                      <p className="desktopBurgerItemBlob"></p>Check a period of
                      time for leave limits
                      {/* </div> */}
                    </NavLink>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        setLaunchTimesheet(true);
                      }}
                    >
                      <p className="desktopBurgerItemBlob"></p>Log hours in a
                      timesheet
                    </div>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setSelectedYear(
                          (x) =>
                            memoVals.checkUserState.currClientYear ||
                            new Date().getFullYear()
                        );
                        memoVals.setShowReqStats((x) => "myStats");
                      }}
                    >
                      <p className="desktopBurgerItemBlob"></p>View my
                      allowances
                    </div>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        axios
                          .post(
                            `${serverURL}/logout`,
                            { someData: 1 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              window.location.href = "/signin";
                            } else {
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <p className="desktopBurgerItemBlob"></p>Sign out
                    </div>
                    <br />
                    <p className="desktopBurgerTitle asMgr">As a manager</p>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        memoVals.setShowAddUserModal((x) => true);
                        setShowDesktopMenu(false);
                      }}
                    >
                      <p className="desktopBurgerItemBlob x239814"></p>
                      Add an employee
                    </div>
                    <NavLink to="/staff-dashboard">
                      <div className="desktopBurgerItem">
                        <p className="desktopBurgerItemBlob x239814"></p>Find an
                        employee
                      </div>
                    </NavLink>
                    {/* <NavLink to="/team-rota?absence=true"> */}
                    <div
                      // href="/team-rota?abs=true"
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setShowAddAbsenceModal((x) => true);
                        // setRota(true);
                        // memoVals.setSelectedDesktop((x) => "team-rota");
                      }}
                    >
                      <p className="desktopBurgerItemBlob x239814"></p>Record an
                      employee as absent
                      {/* Opens a modal asking which employee, if has shifts, ask to mark shifts as absent, or create a period of absent leave instead */}
                    </div>{" "}
                    {/* </NavLink> */}
                    {/* <NavLink to="/team-rota?absence=true"> */}
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setShowAddLeaveOnTeam((x) => true);
                      }}
                    >
                      <p className="desktopBurgerItemBlob x239814"></p>Record an
                      employee as on leave
                    </div>
                    {/* </NavLink> */}
                    <NavLink
                      to="/staff-dashboard/allowances"
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                      }}
                    >
                      <p className="desktopBurgerItemBlob x239814"></p>View
                      staff allowances
                    </NavLink>
                    <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        memoVals.setShowMgrAvailModal((x) => {
                          return {
                            team: "-",
                            ds: dateStringer.getMonWeekOfDs(
                              dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              )
                            ),
                            userID: "",
                            isMgr: true,
                          };
                        });
                        setShowDesktopMenu(false);
                      }}
                    >
                      <p className="desktopBurgerItemBlob x239814"></p>View
                      staff availability
                    </div>
                  </div>
                  <img
                    src={close}
                    alt="Close menu"
                    onClick={() => {
                      setShowDesktopMenu(!showDesktopMenu);
                    }}
                    className="closeDesktopBurer"
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className={`desktopHeaderClockOnBtn ${
                  rota ? "desktopHeaderClockOnBtnRota" : ""
                } ${!memoVals.userIsClockedOn ? "x248921" : ""} ${
                  !rota && !memoVals.userIsClockedOn ? "x1308428411" : ""
                } ${!rota && memoVals.userIsClockedOn ? "x1308428e411" : ""}`}
                onClick={() => {
                  memoVals.setShowClockOnModal((x) => true);
                  console.log("SHOW CLOCK ON MODAL");
                }}
              >
                <span
                  className={
                    memoVals.userIsClockedOn
                      ? `clockedOnLightSpan ${
                          rota && memoVals.userIsOnClockedBreak ? "x81811" : ""
                        }`
                      : "none"
                  }
                ></span>
                {memoVals.userIsOnClockedBreak
                  ? `On  break ${
                      memoVals.userIsOnClockedBreak === 1
                        ? "1"
                        : memoVals.userIsOnClockedBreak === 2
                        ? "2"
                        : "3"
                    }`
                  : memoVals.userIsClockedOn
                  ? "Clocked on"
                  : "Clock on"}
              </div>
              {/* <div className="submitDesktopBtn">Submit</div> */}
            </div>
          ) : (
            ""
          )}
          <div
            className={`desktopHeaderMenuDiv ${
              memoVals.checkUserState.clockOnAllowed
                ? ""
                : "headerMenuIfClockOnNotAllowed"
            } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
            ref={navRef}
            onScroll={(e) => {
              localStorage.setItem("navRef", navRef.current.scrollTop);
            }}
          >
            <p
              className={`desktopNavBarHeading ${
                rota ? "rotaNavBarHeading" : ""
              }`}
            >
              My hours
            </p>
            <NavLink
              to="/calendar"
              onClick={() => {
                memoVals.setSelectedDesktop((x) => "calendar");
                if (
                  memoVals.checkUserState.permissions < 3 &&
                  memoVals.calPage === "calendar" &&
                  memoVals.selectedNav === 2.1
                ) {
                  // setCalPage("staff-calendar");
                } else {
                  // setCalPage("calendar");
                }
                memoVals.changeNavTo((x) => 2.1);
                memoVals.setReqsPage((x) => "");
                setShowAddOptions(false);
              }}
            >
              <div
                className={`desktopNavItem ${
                  memoVals.selectedDesktop === "calendar"
                    ? "desktopItemSelected"
                    : ""
                } ${rota ? "rotaNavItem" : ""}`}
              >
                <img
                  src={memoVals.selectedDesktop === "calendar" ? icon1b : icon1}
                  alt="Calendar"
                  className={`desktopNavIcon ${
                    rota ? "desktopNavIconRota" : ""
                  }`}
                />
                <p
                  className={`${
                    memoVals.selectedDesktop === "calendar"
                      ? "desktopHeaderMenuBtn navBtnBlue"
                      : "desktopHeaderMenuBtn"
                  } ${rota ? "rotaSizeFont" : ""}`}
                >
                  My calendar
                </p>
              </div>
            </NavLink>
            <div
              className={`${
                memoVals.selectedDesktop === "calendar"
                  ? "desktopHeaderSubDiv desktopHeaderDivFourItems"
                  : "none"
              }`}
            >
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "calendar"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "calendar");
                  memoVals.setCalendarPage((x) => "myCal");
                }}
              >
                My calendar
              </p>{" "}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "view-colleague"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "view-colleague");
                  memoVals.setCalendarPage((x) => "teamCal");
                }}
              >
                Leave charts
              </p>
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "team-calendar"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "team-calendar");
                  memoVals.setCalendarPage((x) => "team");
                }}
              >
                Team calendar
              </p>
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "limit-checker"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "limit-checker");
                  memoVals.setCalendarPage((x) => "check");
                }}
              >
                Limit checker
              </p>
            </div>
            <NavLink
              to="/rota"
              onClick={() => {
                memoVals.setSelectedDesktop((x) => "rota");
              }}
            >
              <div
                className={`desktopNavItem ${
                  memoVals.selectedDesktop === "rota"
                    ? "desktopItemSelected borderRadius5"
                    : ""
                } staffRotaNavBtn ${
                  memoVals.selectedDesktop === "calendar"
                    ? "borderTopWhite"
                    : ""
                } ${
                  memoVals.selectedDesktop === "absence"
                    ? "borderBottomWhite"
                    : ""
                } ${rota ? "rotaNavItem" : ""}`}
              >
                <img
                  src={memoVals.selectedDesktop === "rota" ? icon2b : icon2}
                  alt="Rota"
                  className={`desktopNavIcon ${
                    rota ? "desktopNavIconRota" : ""
                  }`}
                />
                <p
                  className={`${
                    memoVals.selectedDesktop === "rota"
                      ? "desktopHeaderMenuBtn navBtnBlue"
                      : "desktopHeaderMenuBtn"
                  } ${rota ? "rotaSizeFont" : ""}`}
                >
                  My rota
                </p>
                {memoVals.inboundSwapsQty > 0 ? (
                  <p className="navBarQty blueNavBarQty">
                    {memoVals.inboundSwapsQty > 9
                      ? "9+"
                      : memoVals.inboundSwapsQty}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </NavLink>
            <NavLink
              to="/absence"
              onClick={() => {
                memoVals.setSelectedDesktop((x) => "absence");
                memoVals.setAbsencePage((x) => "shifts");
              }}
            >
              <div
                className={`desktopNavItem ${
                  memoVals.selectedDesktop === "absence"
                    ? "desktopItemSelected"
                    : ""
                } ${rota ? "rotaNavItem" : ""}`}
              >
                <img
                  src={memoVals.selectedDesktop === "absence" ? icon3b : icon3}
                  alt="Absence"
                  className={`desktopNavIcon ${
                    rota ? "desktopNavIconRota" : ""
                  }`}
                />
                <p
                  className={`${
                    memoVals.selectedDesktop === "absence"
                      ? "desktopHeaderMenuBtn navBtnBlue"
                      : "desktopHeaderMenuBtn"
                  } ${rota ? "rotaSizeFont" : ""}`}
                >
                  My absence
                </p>
              </div>
            </NavLink>
            <div
              className={`${
                memoVals.selectedDesktop === "absence"
                  ? "desktopHeaderSubDiv desktopHeaderDivFiveItems"
                  : "none"
              }`}
            >
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.absencePage === "periods" &&
                  memoVals.selectedDesktop === "absence"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setAbsencePage((x) => "periods");
                }}
              >
                Periods
              </p>{" "}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.absencePage === "shifts" &&
                  memoVals.selectedDesktop === "absence"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setAbsencePage((x) => "shifts");
                }}
              >
                Absent shifts
              </p>
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.absencePage === "late" &&
                  memoVals.selectedDesktop === "absence"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setAbsencePage((x) => "late");
                }}
              >
                Lateness
              </p>
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.absencePage === "forms" &&
                  memoVals.selectedDesktop === "absence"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setAbsencePage((x) => "forms");
                }}
              >
                Absence forms
              </p>
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.absencePage === "rank" &&
                  memoVals.selectedDesktop === "absence"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setAbsencePage((x) => "rank");
                }}
              >
                My rank
              </p>
            </div>

            <NavLink
              to="/hours"
              onClick={() => {
                memoVals.setSelectedDesktop((x) => "shifts");
                memoVals.setShowChanges((x) => false);

                // setCalPage("shifts");
              }}
            >
              <div
                className={`desktopNavItem ${
                  memoVals.selectedDesktop === "shifts"
                    ? "desktopItemSelected"
                    : ""
                } ${rota ? "rotaNavItem" : ""}`}
              >
                <img
                  src={memoVals.selectedDesktop === "shifts" ? icon4b : icon4}
                  alt="Hours"
                  className={`desktopNavIcon ${
                    rota ? "desktopNavIconRota" : ""
                  }`}
                />
                <p
                  className={`${
                    memoVals.selectedDesktop === "shifts"
                      ? "desktopHeaderMenuBtn navBtnBlue"
                      : "desktopHeaderMenuBtn"
                  } ${rota ? "rotaSizeFont" : ""}`}
                >
                  My hours
                </p>
                {/* {countMyChanges > 0 ? (
                <p className="navBarQty blueNavBarQty">
                  {countMyChanges > 9 ? "9+" : countMyChanges}
                </p>
              ) : (
                ""
              )} */}
              </div>
            </NavLink>

            <div
              className={`${
                memoVals.selectedDesktop === "shifts"
                  ? "desktopHeaderSubDiv desktopHeaderDivFourItems"
                  : "none"
              }`}
            >
              {/* <NavLink to="/hours"> */}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "shifts"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setUserShiftsPage((x) => "shifts");
                  memoVals.setShiftsPage((x) => "shifts");
                  memoVals.setPage((x) => "shifts");
                  memoVals.setShowChanges((x) => false);
                }}
              >
                Hours
              </p>
              {/* </NavLink> */}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "reqs" ? "desktopHeaderSubBtnSelected" : ""
                }`}
                onClick={() => {
                  memoVals.setUserShiftsPage((x) => "shifts");
                  memoVals.setShiftsPage((x) => "shifts");
                  memoVals.setPage((x) => "reqs");
                  memoVals.setShowChanges((x) => {
                    fromNav: true;
                  });
                }}
              >
                Requests
              </p>
              {/* <NavLink to="/clock-ons"> */}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "clock-ons"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setUserShiftsPage((x) => "clock-ons");
                  memoVals.setShiftsPage((x) => "clock-ons");
                  memoVals.setPage((x) => "clock-ons");
                }}
              >
                Clock ons
              </p>
              {/* </NavLink> */}
              {/* <NavLink to="/timesheets"> */}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "submissions"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setUserShiftsPage((x) => "submissions");
                  memoVals.setShiftsPage((x) => "submissions");

                  memoVals.setPage((x) => "submissions");
                }}
              >
                Timesheets
              </p>
              {/* </NavLink> */}
            </div>
            <NavLink
              to="/leave"
              onClick={() => {
                memoVals.setSelectedDesktop((x) => "requests");
              }}
            >
              <div
                className={`desktopNavItem ${
                  memoVals.selectedDesktop === "requests"
                    ? "desktopItemSelected"
                    : ""
                } ${rota ? "rotaNavItem" : ""}`}
              >
                <img
                  src={memoVals.selectedDesktop === "requests" ? icon5b : icon5}
                  alt="Hours"
                  className={`desktopNavIcon ${
                    rota ? "desktopNavIconRota" : ""
                  }`}
                />
                <p
                  className={`${
                    memoVals.selectedDesktop === "requests"
                      ? "desktopHeaderMenuBtn navBtnBlue"
                      : "desktopHeaderMenuBtn"
                  } ${rota ? "rotaSizeFont" : ""}`}
                >
                  My leave
                </p>
              </div>
            </NavLink>
            <div
              className={`${
                memoVals.selectedDesktop === "requests"
                  ? "desktopHeaderSubDiv"
                  : "none"
              }`}
            >
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "upcoming"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "upcoming");
                  memoVals.setReqsPage((x) => "upcoming");
                }}
              >
                Upcoming
              </p>{" "}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "past" ? "desktopHeaderSubBtnSelected" : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "past");
                  memoVals.setReqsPage((x) => "past");
                }}
              >
                Past
              </p>
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "declined"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "declined");
                  memoVals.setReqsPage((x) => "declined");
                }}
              >
                Declined
              </p>
            </div>
            <NavLink
              to="/account"
              onClick={() => {
                memoVals.setSelectedDesktop((x) => "account");
                memoVals.setPage((x) => "profile");
                memoVals.setSettingsAlowances((x) => "profile");
              }}
            >
              <div
                className={`desktopNavItem ${
                  memoVals.selectedDesktop === "account"
                    ? "desktopItemSelected"
                    : ""
                } ${rota ? "rotaNavItem" : ""}`}
              >
                <img
                  src={memoVals.selectedDesktop === "account" ? icon6b : icon6}
                  alt="Hours"
                  className={`desktopNavIcon ${
                    rota ? "desktopNavIconRota" : ""
                  }`}
                />
                <p
                  className={`${
                    memoVals.selectedDesktop === "account"
                      ? "desktopHeaderMenuBtn navBtnBlue"
                      : "desktopHeaderMenuBtn"
                  } ${rota ? "rotaSizeFont" : ""}`}
                >
                  My account
                </p>
              </div>
            </NavLink>
            <div
              className={`${
                memoVals.selectedDesktop === "account"
                  ? "desktopHeaderSubDiv desktopHeaderDivThreeItems"
                  : "none"
              }`}
            >
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "profile"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "profile");
                  memoVals.setSettingsAlowances((x) => "profile");
                }}
              >
                My profile
              </p>{" "}
              <p
                className={`desktopHeaderSubBtn  ${
                  memoVals.page === "settings"
                    ? "desktopHeaderSubBtnSelected"
                    : ""
                }`}
                onClick={() => {
                  memoVals.setPage((x) => "settings");
                  memoVals.setSettingsAlowances((x) => "settings");
                }}
              >
                Settings
              </p>
              <a
                href="https://flowrota.com/support"
                target="_blank"
                className={`desktopHeaderSubBtn x2983028948111`}
                onClick={() => {
                  // memoVals.setShowContact(x =>true);
                }}
              >
                Support{" "}
                <img
                  src={externalLink}
                  alt="External support"
                  className="extSuppImg"
                />
              </a>
            </div>
            <br />

            {memoVals.checkUserState.permissions < 3 ||
            memoVals.checkUserState.indiOnly ? (
              <div>
                <p
                  className={`desktopNavBarHeading ${
                    rota ? "rotaNavBarHeading" : ""
                  }`}
                >
                  Manage
                </p>

                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions < 3 ||
                    memoVals.checkUserState.indiOnly
                      ? "/staff-dashboard"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      memoVals.checkUserState.permissions < 3 ||
                      memoVals.checkUserState.indiOnly
                    ) {
                      memoVals.setSelectedDesktop((x) => "dashboard");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem ${
                      memoVals.selectedDesktop === "dashboard"
                        ? "desktopItemSelected desktopItemSelectedMgrBorder"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "dashboard"
                          ? icon7b
                          : icon7
                      }
                      alt="Hours"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions < 3 ||
                        memoVals.checkUserState.indiOnly
                          ? `${
                              memoVals.selectedDesktop === "dashboard"
                                ? "desktopHeaderMenuBtn navBtnPink"
                                : "desktopHeaderMenuBtn navBtnPurple"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      {/* {memoVals.checkUserState.permissions === 1
                        ? "All staff"
                        : "My staff"} */}
                      Staff dashboard
                      {/* {memoVals.checkUserState.permissions === 1
                        ? "Staff dashboard"
                        : "My staff"} */}
                    </p>{" "}
                  </div>
                </NavLink>
                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions < 3 ||
                    memoVals.checkUserState.indiOnly
                      ? "/staff-calendar"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      memoVals.checkUserState.permissions < 3 ||
                      memoVals.checkUserState.indiOnly
                    ) {
                      memoVals.setSelectedDesktop((x) => "staff-calendar");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem ${
                      memoVals.selectedDesktop === "staff-calendar"
                        ? "desktopItemSelected desktopItemSelectedMgrBorder"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "staff-calendar"
                          ? icon14b
                          : icon14
                      }
                      alt="Hours"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions < 3 ||
                        memoVals.checkUserState.indiOnly
                          ? `${
                              memoVals.selectedDesktop === "staff-calendar"
                                ? "desktopHeaderMenuBtn navBtnPink"
                                : "desktopHeaderMenuBtn navBtnPurple"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      Staff calendar
                    </p>{" "}
                  </div>
                </NavLink>
                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions < 3 ||
                    memoVals.checkUserState.indiOnly
                      ? "/team-rota"
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.checkUserState.permissions < 3) {
                      memoVals.setSelectedDesktop((x) => "team-rota");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem ${
                      memoVals.selectedDesktop === "team-rota"
                        ? "desktopItemSelected desktopItemSelectedMgrBorder borderRadius5"
                        : ""
                    } ${
                      memoVals.checkUserState.permissions === 3 &&
                      memoVals.checkUserState.indiOnly
                        ? "fadeStaffRotaLink"
                        : ""
                    } staffRotaNavBtn ${
                      memoVals.selectedDesktop === "staff-calendar"
                        ? "borderTopWhite"
                        : ""
                    } ${
                      memoVals.selectedDesktop === "team-absence"
                        ? "borderBottomWhite"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "team-rota"
                          ? icon8b
                          : icon8
                      }
                      alt="Hours"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions < 3 ||
                        memoVals.checkUserState.indiOnly
                          ? `${
                              memoVals.selectedDesktop === "team-rota"
                                ? "desktopHeaderMenuBtn navBtnPink"
                                : "desktopHeaderMenuBtn navBtnPurple"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      Staff rota
                    </p>{" "}
                    {/* {teamSwapsQty > 0 ? (
                    <p className="navBarQty">
                      {teamSwapsQty > 9 ? "9+" : teamSwapsQty}
                    </p>
                  ) : (
                    " "
                  )}{" "} */}
                  </div>
                </NavLink>
                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions < 3 ||
                    memoVals.checkUserState.indiOnly
                      ? "/staff-absence"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      memoVals.checkUserState.permissions < 3 ||
                      memoVals.checkUserState.indiOnly
                    ) {
                      memoVals.setSelectedDesktop((x) => "team-absence");
                      memoVals.setAbsencePage((x) => "shifts");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem ${
                      memoVals.selectedDesktop === "team-absence"
                        ? "desktopItemSelected desktopItemSelectedMgrBorder"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "team-absence"
                          ? icon9b
                          : icon9
                      }
                      alt="Absence"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions < 3 ||
                        memoVals.checkUserState.indiOnly
                          ? `${
                              memoVals.selectedDesktop === "team-absence"
                                ? "desktopHeaderMenuBtn navBtnPink"
                                : "desktopHeaderMenuBtn navBtnPurple"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      Staff absence
                    </p>{" "}
                    {memoVals.absentQty > 0 ? (
                      <p className="navBarQty">
                        {memoVals.absentQty > 9 ? "9+" : memoVals.absentQty}
                      </p>
                    ) : (
                      " "
                    )}
                  </div>
                </NavLink>
                <div
                  className={`${
                    memoVals.selectedDesktop === "team-absence"
                      ? "desktopHeaderSubDiv desktopHeaderDivFiveItems teamDesktopHeaderSubBg"
                      : "none"
                  }`}
                >
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.selectedDesktop === "team-absence" &&
                      memoVals.absencePage === "shifts"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setAbsencePage((x) => "shifts");
                    }}
                  >
                    Absent shifts
                  </p>{" "}
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.selectedDesktop === "team-absence" &&
                      memoVals.absencePage === "periods"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setAbsencePage((x) => "periods");
                    }}
                  >
                    Periods
                  </p>{" "}
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.selectedDesktop === "team-absence" &&
                      memoVals.absencePage === "late"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setAbsencePage((x) => "late");
                    }}
                  >
                    Lateness
                  </p>
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.selectedDesktop === "team-absence" &&
                      memoVals.absencePage === "forms"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setAbsencePage((x) => "forms");
                    }}
                  >
                    Forms
                  </p>
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.selectedDesktop === "team-absence" &&
                      memoVals.absencePage === "rank"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setAbsencePage((x) => "rank");
                    }}
                  >
                    Staff ranks
                  </p>
                </div>
                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions < 3 ||
                    memoVals.checkUserState.indiOnly
                      ? "/team-hours"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      memoVals.checkUserState.permissions < 3 ||
                      memoVals.checkUserState.indiOnly
                    ) {
                      memoVals.setSelectedDesktop((x) => "team-shifts");
                      memoVals.setUserShiftsPage((x) => "team-shifts");
                      memoVals.setShiftsPage((x) => "team-shifts");
                      // setCalPage("team-shifts");
                      memoVals.setPage((x) => "team-shifts");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem ${
                      memoVals.selectedDesktop === "team-shifts"
                        ? "desktopItemSelected desktopItemSelectedMgrBorder"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "team-shifts"
                          ? icon10b
                          : icon10
                      }
                      alt="Team hours"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions < 3 ||
                        memoVals.checkUserState.indiOnly
                          ? `${
                              memoVals.selectedDesktop === "team-shifts"
                                ? "desktopHeaderMenuBtn navBtnPink"
                                : "desktopHeaderMenuBtn navBtnPurple"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      Staff hours
                    </p>{" "}
                    {memoVals.staffHoursQty > 0 ? (
                      <p className="navBarQty">
                        {memoVals.staffHoursQty > 9
                          ? "9+"
                          : memoVals.staffHoursQty}
                      </p>
                    ) : (
                      " "
                    )}{" "}
                  </div>
                </NavLink>
                <div
                  className={`${
                    memoVals.selectedDesktop === "team-shifts"
                      ? "desktopHeaderSubDiv desktopHeaderDivFourItems_ teamDesktopHeaderSubBg"
                      : "none"
                  }`}
                >
                  {/* <NavLink to="/team-hours"> */}
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.page === "team-shifts"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setUserShiftsPage((x) => "team-shifts");
                      memoVals.setShiftsPage((x) => "team-shifts");
                      memoVals.setPage((x) => "team-shifts");
                    }}
                  >
                    Hours
                  </p>
                  {/* </NavLink> */}
                  {/* <p
                  className={`desktopHeaderSubBtn mgrSubBtnHover ${
                    memoVals.page === "team-swaps"
                      ? "desktopHeaderSubBtnSelectedMob"
                      : ""
                  }`}
                  onClick={() => {
                    memoVals.setUserShiftsPage( x=>"team-swaps");
                    memoVals.setShiftsPage( x=>"team-swaps");
                    memoVals.setPage( x=>"team-swaps");
                  }}
                >
                  Shifts swaps{" "}
                </p> */}
                  {/* <NavLink to="/team-clock-ons"> */}
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.page === "team-clock-ons"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setUserShiftsPage((x) => "team-clock-ons");
                      memoVals.setShiftsPage((x) => "team-clock-ons");
                      memoVals.setPage((x) => "team-clock-ons");
                    }}
                  >
                    Clock ons
                  </p>
                  {/* </NavLink> */}
                  {/* <NavLink to="/team-timesheets"> */}
                  <p
                    className={`desktopHeaderSubBtn mgrSubBtnHover ${
                      memoVals.page === "team-submissions"
                        ? "desktopHeaderSubBtnSelectedMob"
                        : ""
                    }`}
                    onClick={() => {
                      memoVals.setUserShiftsPage((x) => "team-submissions");
                      memoVals.setShiftsPage((x) => "team-submissions");
                      memoVals.setPage((x) => "team-submissions");
                    }}
                  >
                    Timesheets{" "}
                  </p>
                  {/* </NavLink> */}
                </div>
                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions < 3 ||
                    memoVals.checkUserState.indiOnly
                      ? "/team-leave"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      memoVals.checkUserState.permissions < 3 ||
                      memoVals.checkUserState.indiOnly
                    ) {
                      memoVals.setSelectedDesktop((x) => "team-requests");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem ${
                      memoVals.selectedDesktop === "team-requests"
                        ? "desktopItemSelected desktopItemSelectedMgrBorder"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "team-requests"
                          ? icon11b
                          : icon11
                      }
                      alt="Team requests"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions < 3 ||
                        memoVals.checkUserState.indiOnly
                          ? `${
                              memoVals.selectedDesktop === "team-requests"
                                ? "desktopHeaderMenuBtn navBtnPink"
                                : "desktopHeaderMenuBtn navBtnPurple"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      Staff leave
                    </p>{" "}
                    {memoVals.outstandingReqsQty > 0 ? (
                      <p className="navBarQty">
                        {memoVals.outstandingReqsQty > 9
                          ? "9+"
                          : memoVals.outstandingReqsQty}
                      </p>
                    ) : (
                      " "
                    )}
                  </div>
                </NavLink>
                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions < 3 ||
                    memoVals.checkUserState.indiOnly
                      ? "/reports"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      memoVals.checkUserState.permissions < 3 ||
                      memoVals.checkUserState.indiOnly
                    ) {
                      memoVals.setSelectedDesktop((x) => "reports");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem ${
                      memoVals.selectedDesktop === "reports"
                        ? "desktopItemSelected desktopItemSelectedMgrBorder"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "reports"
                          ? icon12b
                          : icon12
                      }
                      alt="Reports"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions < 3 ||
                        memoVals.checkUserState.indiOnly
                          ? `${
                              memoVals.selectedDesktop === "reports"
                                ? "desktopHeaderMenuBtn navBtnPink"
                                : "desktopHeaderMenuBtn navBtnPurple"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      Reports
                    </p>{" "}
                  </div>
                </NavLink>
              </div>
            ) : (
              ""
            )}
            <br />
            {memoVals.checkUserState.permissions === 1 ? (
              <div>
                <p
                  className={`desktopNavBarHeading ${
                    rota ? "rotaNavBarHeading" : ""
                  }`}
                >
                  Admins only
                </p>

                <NavLink
                  to={`${
                    memoVals.checkUserState.permissions === 1 ? "/admin" : ""
                  }`}
                  onClick={() => {
                    if (memoVals.checkUserState.permissions === 1) {
                      memoVals.setSelectedDesktop((x) => "admin");
                    }
                  }}
                >
                  <div
                    className={`desktopNavItem desktopNavAdmin ${
                      memoVals.selectedDesktop === "admin"
                        ? "desktopItemSelected desktopItemSelectedAdminBorder"
                        : ""
                    } ${rota ? "rotaNavItem" : ""}`}
                  >
                    <img
                      src={
                        memoVals.selectedDesktop === "admin" ? icon13b : icon13
                      }
                      alt="Reports"
                      className={`desktopNavIcon ${
                        rota ? "desktopNavIconRota" : ""
                      }`}
                    />
                    <p
                      className={`${
                        memoVals.checkUserState.permissions === 1
                          ? `${
                              memoVals.selectedDesktop === "admin"
                                ? "desktopHeaderMenuBtn navBtnDarkGrey"
                                : "desktopHeaderMenuBtn navBtnLightGrey"
                            }`
                          : "none"
                      } ${rota ? "rotaSizeFont" : ""}`}
                    >
                      Admin panel
                    </p>{" "}
                  </div>
                </NavLink>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      <div className={`${memoVals.mob ? "none" : `desktopBottomBar`}`}>
        {/* <p
          className="memoVals.device"
          onClick={() => {
            console.log({ memoVals.device });
          }}
        > */}
        {/* {" "} */}
        {/* {memoVals.device.vhIssue
            ? `vh prob ${memoVals.device.browser} ${memoVals.device.memoVals.device} ${
                memoVals.device.fullScreen ? "memoVals.full" : "non-memoVals.full"
              }`
            : ""}{" "} */}
        {/* </p> */}

        {!memoVals.standalone && (
          <p
            className="getSupportFooterBtn"
            onClick={() => {
              memoVals.setShowContact((x) => true);
            }}
          >
            Support{" "}
            {/* <img
            src={whiteQuestionMark}
            alt="Support"
            className="getSuppFooterImg"
          /> */}
          </p>
        )}
      </div>

      {!memoVals.standalone && (
        <div
          className={`${memoVals.showMobMenu ? "mobMenuUnderlay" : "none"}`}
          onClick={() => {
            memoVals.setShowMobMenu((x) => !memoVals.showMobMenu);
          }}
        >
          <div className={`${memoVals.showMobMenu ? "mobMenuDiv" : "none"}`}>
            <div
              className="mobBurgerMenu"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="desktopBurgerTitle">As an employee</p>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setShowReqModal((x) => true);
                }}
              >
                <p className="desktopBurgerItemBlob"></p>Make a leave request
              </div>
              <NavLink to="/rota?today=true">
                <div
                  className="desktopBurgerItem"
                  onClick={() => {
                    setShowDesktopMenu(false);
                  }}
                >
                  <p className="desktopBurgerItemBlob"></p>View today's rota
                </div>
              </NavLink>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setShowAddAvailModal((x) => true);
                }}
              >
                <p className="desktopBurgerItemBlob"></p>Provide my availability
              </div>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setShowSwapsModal((x) => true);
                }}
              >
                <p className="desktopBurgerItemBlob"></p>View my shift swaps
              </div>
              <NavLink
                to="/calendar"
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setGoToLimits((x) => true);
                }}
              >
                {/* <div
                      className="desktopBurgerItem"
                      onClick={() => {
                        setShowDesktopMenu(false);
                        memoVals.setGoToLimits(x =>true);
                      }}
                    > */}
                <p className="desktopBurgerItemBlob"></p>Check a period of time
                for leave limits
                {/* </div> */}
              </NavLink>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  setLaunchTimesheet(true);
                }}
              >
                <p className="desktopBurgerItemBlob"></p>Log hours in a
                timesheet
              </div>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setSelectedYear(
                    (x) =>
                      memoVals.checkUserState.currClientYear ||
                      new Date().getFullYear()
                  );
                  memoVals.setShowReqStats((x) => "myStats");
                }}
              >
                <p className="desktopBurgerItemBlob"></p>View my allowances
              </div>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/logout`,
                      { someData: 1 },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        window.location.href = "/signin";
                      } else {
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <p className="desktopBurgerItemBlob"></p>Sign out
              </div>

              <br />
              <p className="desktopBurgerTitle">As a manager</p>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  memoVals.setShowAddUserModal((x) => true);
                  setShowDesktopMenu(false);
                }}
              >
                <p className="desktopBurgerItemBlob x239814"></p>
                Add an employee
              </div>
              <NavLink to="/staff-dashboard" className="desktopBurgerItem">
                <p className="desktopBurgerItemBlob x239814"></p>Find an
                employee
              </NavLink>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setShowAddAbsenceModal((x) => true);
                }}
              >
                <p className="desktopBurgerItemBlob x239814"></p>Record an
                employee as absent
                {/* Opens a modal asking which employee, if has shifts, ask to mark shifts as absent, or create a period of absent leave instead */}
              </div>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setShowReqModal((x) => true);
                }}
              >
                <p className="desktopBurgerItemBlob x239814"></p>Record an
                employee as on leave
              </div>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setShowReqModal((x) => true);
                }}
              >
                <p className="desktopBurgerItemBlob x239814"></p>View staff
                allowances
              </div>
              <div
                className="desktopBurgerItem"
                onClick={() => {
                  setShowDesktopMenu(false);
                  memoVals.setShowReqModal((x) => true);
                }}
              >
                <p className="desktopBurgerItemBlob x239814"></p>View staff
                availability
              </div>
            </div>
          </div>
        </div>
      )}
      {!memoVals.standalone && (
        <div className="mobNavTopLeftDiv">
          <img
            src={memoVals.showMobMenu ? whitecross : mobBurger}
            className={`${
              memoVals.showMobMenu ? "whiteCrossMobMenu" : "mobNavMsgIcon"
            } ${!memoVals.mob && "none"}`}
            alt="Menu"
            onClick={() => {
              memoVals.setShowMobMenu((x) => !memoVals.showMobMenu);
            }}
          />
          {!memoVals.showMobMenu ? (
            <img
              src={memoVals.msgQty > 0 ? mobMsgActive : mobMsg}
              alt="Messages"
              className="mobNavMsgIcon"
              onClick={() => {
                memoVals.setShowMessages((x) => true);
              }}
            />
          ) : (
            ""
          )}
          {!memoVals.showMobMenu && memoVals.msgQty > 0 ? (
            <div
              className="mobMsgQtyBlob"
              onClick={() => {
                memoVals.setShowMessages((x) => true);
              }}
            >
              {memoVals.msgQty > 9 ? `${memoVals.msgQty}+` : memoVals.msgQty}
            </div>
          ) : (
            ""
          )}

          {!memoVals.showMobMenu ? (
            <div
              className="mobNotifHolder"
              onClick={() => {
                memoVals.setShowNotifications((x) => true);
              }}
            >
              <img
                src={memoVals.unreadNotifs ? mobBellActive : mobBell}
                alt="Notifications"
                className={`mobNavMsgIcon mobNotifIconSep `}
              />

              <p
                className={`mobUnreadNotifIcon ${
                  !memoVals.unreadNotifs ? "invis" : ""
                } ${memoVals.unreadNotifs > 99 ? "xi28381" : ""}`}
              >
                {memoVals.unreadNotifs > 99 ? "99+" : memoVals.unreadNotifs}
              </p>
            </div>
          ) : (
            ""
          )}

          {/* {!memoVals.showMobMenu ? (
          <img src={mobHelp} alt="Notifications" className="mobHelpIcon" />
        ) : (
          ""
        )} */}
        </div>
      )}
      {!memoVals.standalone && (
        <div className={`${memoVals.mob ? "footerHolder" : "none"}`}>
          <div className="deviceInfo" onClick={() => {}}>
            {memoVals.device.vhIssue
              ? `vh prob ${memoVals.device.browser} ${memoVals.device.device} ${
                  memoVals.device.fullScreen ? "full" : "non-full"
                }`
              : ""}
          </div>

          <div
            className={`${showAddOptions ? "addOptionsUnderlay" : "none"}`}
            onClick={() => {
              setShowAddOptions(false);
            }}
          >
            <div className={`showOptionsBtnsContainer `}>
              <p
                className="addLeaveOption"
                onClick={() => {
                  setShowAddOptions(false);

                  setLaunchTimesheet(true);
                }}
              >
                Timesheet
              </p>

              <p
                className="addLeaveOption"
                onClick={() => {
                  memoVals.setShowAddAvailModal((x) => true);
                }}
              >
                Availability
              </p>
              <p
                className="mobNavClockOnBtn"
                onClick={() => {
                  console.log("SHOW CLOCK ON MODAL");
                  memoVals.setShowClockOnModal((x) => true);
                }}
              >
                Clock on / off
              </p>
              <p
                className="addLeaveOption leaveBubbleSolo "
                onClick={() => {
                  memoVals.setShowReqModal((x) => true);
                }}
              >
                New leave request
              </p>
            </div>{" "}
          </div>
          <div
            className={`${
              memoVals.hideNavbar
                ? "footer-menu-hidden"
                : `footer-menu ${
                    memoVals.selectedNav === 1 || memoVals.selectedNav === 1.1
                      ? "homeFooterMobile"
                      : ""
                  }`
            }`}
          >
            <NavLink
              to="/menu"
              className="footerBtnToucher"
              onClick={() => {
                memoVals.changeNavTo((x) => 1.1);
                memoVals.setReqsPage((x) => "");
                setShowAddOptions(false);
                // setCalPage("calendar");
              }}
            >
              <div className="footerBtn">
                {/* {parseInt(localStorage.getItem("mobHomeQty"))} */}
                <img className="f1" alt="Menu" src={f1}></img>
                {parseInt(localStorage.getItem("mobHomeQty")) &&
                memoVals.checkUserState.permissions < 3 ? (
                  <div
                    className={`mobHomeBtnQty ${
                      parseInt(localStorage.getItem("mobHomeQty")) > 99
                        ? "mobHomeBtnQtyFontSmall"
                        : ""
                    }`}
                  >
                    {parseInt(localStorage.getItem("mobHomeQty")) > 99
                      ? "99+"
                      : parseInt(localStorage.getItem("mobHomeQty"))}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {memoVals.selectedNav === 1.1 ? (
                <div className="footerTouchHolder homeToucher">
                  <div className="footerToucher"></div>
                </div>
              ) : (
                ""
              )}
            </NavLink>
            <NavLink
              to="/calendar"
              className="footerBtnToucher"
              onClick={() => {
                if (
                  memoVals.checkUserState.permissions < 3 &&
                  memoVals.calPage === "calendar" &&
                  memoVals.selectedNav === 2.1
                ) {
                  // setCalPage("staff-calendar");
                } else {
                  // setCalPage("calendar");
                }
                memoVals.changeNavTo((x) => 2.1);
                memoVals.setReqsPage((x) => "");
                setShowAddOptions(false);
              }}
            >
              <div className="footerBtn">
                <img className="f2" alt="Calendar" src={f2}></img>
              </div>

              {memoVals.selectedNav === 2.1 ? (
                <div className="footerTouchHolder calToucher">
                  <div className="footerToucher"></div>
                </div>
              ) : (
                ""
              )}
            </NavLink>

            <div
              className="footerBtn"
              onClick={() => {
                if (!showAddOptions) {
                  memoVals.setShowReqModal((x) => false);
                  memoVals.setShowAdd((x) => false);
                }
                setShowAddOptions(!showAddOptions);
              }}
            >
              <div
                className={`f4Container ${
                  showAddOptions
                    ? "f4ContainerActive"
                    : `${
                        memoVals.userIsClockedOn ? "clockedOnF4Pulsate" : ""
                      } ${
                        memoVals.userIsOnClockedBreak
                          ? "clockedOnBrkF4Pulsate"
                          : ""
                      }`
                }`}
              >
                <img
                  className={`${showAddOptions ? "f4 rotate45degs" : "f4"}`}
                  alt="Schedule"
                  src={whitecross}
                ></img>
              </div>
            </div>
            <NavLink
              className="footerBtnToucher"
              to="/leave"
              onClick={() => {
                memoVals.changeNavTo((x) => 3.1);
                setShowAddOptions(false);
                // setCalPage("calendar");
                if (
                  memoVals.selectedNav === 3.1 &&
                  // memoVals.calPage === "calendar" &&
                  memoVals.checkUserState.permissions < 3 &&
                  memoVals.reqsPage === "upcoming"
                ) {
                  memoVals.setReqsPage((x) => "team");
                } else {
                  memoVals.setReqsPage((x) => "upcoming");
                }
              }}
            >
              <div className="footerBtn">
                <img
                  className="f3"
                  alt="Requests"
                  src={`${
                    memoVals.reqsPage === "team" && memoVals.selectedNav === 3.1
                      ? requestsMselected
                      : f3
                  }`}
                ></img>
              </div>
              {memoVals.selectedNav === 3.1 ? (
                <div className="footerTouchHolder">
                  <div className="footerToucher"></div>
                </div>
              ) : (
                ""
              )}
            </NavLink>

            <NavLink
              to="/account"
              className="footerBtnToucher"
              onClick={() => {
                memoVals.changeNavTo((x) => 5.1);
                memoVals.setReqsPage((x) => "");
                setShowAddOptions(false);
                // setCalPage("calendar");
              }}
            >
              <div className="footerBtn">
                <img className="f5" alt="Account" src={f5}></img>
              </div>

              {memoVals.selectedNav === 5.1 ? (
                <div className="footerTouchHolder accountToucher">
                  <div className="footerToucher"></div>
                </div>
              ) : (
                ""
              )}
            </NavLink>
          </div>
        </div>
      )}
      {!memoVals.standalone && (
        <div
          className={`${
            memoVals.showReqModal ? "reqModalOnTheNavBar" : "none"
          }`}
          onClick={() => {
            memoVals.setShowReqModal((x) => false);
          }}
        >
          {renderReq()}
        </div>
      )}

      {/* {memoVals.mob ? (
        <img src={getShoulder()} className="shoulder" onClick={() => {}}></img>
      ) : (
        ""
      )} */}
      {/* 
      {memoVals.mob ? (
        <div className="shoulder">
          <div className="shoulderIn"></div>
        </div>
      ) : (
        ""
      )}
      {memoVals.mob ? (
        <div className="shoulder rightShoulder">
          <div className="shoulderIn"></div>
        </div>
      ) : (
        ""
      )} */}

      {/* {memoVals.mob ? <div className="shoulderCenter"></div> : ""} */}
      {!memoVals.standalone && renderEditShiftModal()}
      {!memoVals.standalone && renderForm()}
      {!memoVals.standalone && renderAddUserModal()}
      {!memoVals.standalone && renderAbsenceModal()}
      {!memoVals.standalone && renderLeaveReqModal()}
      {!memoVals.standalone && renderManagerShiftModal()}
      {!memoVals.standalone && renderAddAvailModal()}
      {!memoVals.standalone && renderMyShiftModal()}
      {!memoVals.standalone && renderUserOpenShiftModal()}
      {!memoVals.standalone && renderEditUser()}
      {!memoVals.standalone && renderClockOnModal()}
      {!memoVals.standalone && renderClockItemModal()}
      {!memoVals.standalone && renderUnclocks()}
      {!memoVals.standalone && renderActiveClocks()}
      {!memoVals.standalone && renderMessages()}
      {!memoVals.standalone && renderMgrAvailModal()}
      {!memoVals.standalone && renderInstallModal()}
      {!memoVals.standalone && renderUserSchedulePopUpModal()}
      {!memoVals.standalone && renderSwapModal()}
      {!memoVals.standalone && renderSwapsModal()}
      {!memoVals.standalone && renderMgrAcceptanceModal()}
      {!memoVals.standalone && renderTeamSwapsModal()}
      {!memoVals.standalone && renderDayCommentModal()}
      {!memoVals.standalone && renderAddLeaveModal()}
      {!memoVals.standalone && renderCreateFormModal()}
      {!memoVals.standalone && renderUserReq()}
      {!memoVals.standalone && renderViewTeamDayModal()}
      {!memoVals.standalone && renderHistoryModal()}
      {!memoVals.standalone && renderViewZoneModal()}
      {!memoVals.standalone && renderOpenShiftModal()}
      {!memoVals.standalone && memoVals.showConvertToilModal && (
        // <div className="x8"></div>
        <ToilConversionModal userID={memoVals.showConvertToilModal} />
      )}
      {!memoVals.standalone && showResolutionModal && (
        <ResolutionModal
          setShowInstallModal={setShowResolutionModal}
          device={memoVals.device}
          popUp={showPopUpInstall}
        />
      )}
      {!memoVals.standalone &&
      (memoVals.showTimesheet.options || memoVals.showTimesheet) ? (
        <CreateTimesheetModal
          teamsheetID={launchTimesheet}
          setLaunchTimesheet={setLaunchTimesheet}
        />
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showViewTimesheet.show ? (
        <ViewTimesheetModal isMgr={memoVals.showViewTimesheet.isMgr} />
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showViewTeamModal.teamID ? (
        <ViewTeamModal
          teamID={memoVals.showViewTeamModal.teamID}
          limitEditsToMgrLevel={
            memoVals.showViewTeamModal.loadedFromMyAcc &&
            memoVals.checkUserState.permissions !== 1
          }
          // if limitEditsToMgrLevel is true, this is loaded from the team viewer in my account memoVals.page. Unless the user is an admin, when amending the team, the user is limited to adding or removing users only, they can't promote/demote or set self-approve
        />
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showViewUserTeamModal ? (
        <ViewTeamUserModal teamID={memoVals.showViewUserTeamModal} />
      ) : (
        ""
      )}

      {!memoVals.standalone && memoVals.showContact ? (
        <div
          className="reqStatsUnderlay"
          onClick={(e) => {
            memoVals.setShowContact((x) => false);
          }}
        >
          <div
            className="whatsappModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="whatsappBoxHeader">
              <img
                src={close}
                alt="Close chat to us"
                className="closeWhatsappModalImg"
                onClick={() => {
                  memoVals.setShowContact((x) => false);
                }}
              />
              <p className="chatToUsHeader">Get help</p>
              <p></p>
            </div>
            <div className="whatsappIconDiv">
              <img
                src={hover === "w" ? whatsapp2 : whatsapp1}
                alt="WhatsApp"
                className="whatsappIconImg"
                onMouseEnter={() => {
                  setHover("w");
                }}
                onMouseLeave={() => {
                  setHover("");
                }}
                onClick={(e) => {
                  window.open(
                    "https://api.whatsapp.com/send?phone=447388625579",
                    "_blank",
                    "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                  );
                }}
              />

              <img
                src={hover === "m" ? messenger2 : messenger1}
                alt="WhatsApp"
                className="whatsappIconImg"
                onMouseEnter={() => {
                  setHover("m");
                }}
                onMouseLeave={() => {
                  setHover("");
                }}
                onClick={(e) => {
                  window.open(
                    "https://m.me/timelifyapp",
                    "_blank",
                    "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                  );
                }}
              />

              <img
                src={hover === "e" ? email2 : email1}
                alt="WhatsApp"
                className="whatsappIconImg"
                onMouseEnter={() => {
                  setHover("e");
                }}
                onMouseLeave={() => {
                  setHover("");
                }}
                onClick={() => {
                  window.location.href = "/contact";
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {!memoVals.standalone && memoVals.showSpecialDayModal.show ? (
        <ViewSpecialDayModal />
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showLeaveTypeModal.show ? (
        <ViewLeaveTypeModal />
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showAbsenceTypeModal.show ? (
        <ViewAbsenceTypeModal />
      ) : (
        ""
      )}

      {!memoVals.standalone &&
      memoVals.full &&
      !window.location.href.includes("/rota") &&
      !window.location.href.includes("/team-rota") &&
      !window.location.href.includes("staff-calendar") ? (
        <div className="desktopLeftShield">
          <div
            className={`desktopLeftGreyBar ${
              !memoVals.checkUserState.clockOnAllowed
                ? "desktopLeftGreyForNonClockOn"
                : ""
            }`}
          ></div>
        </div>
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.full ? (
        <div
          className={`desktopRightShield ${
            window.location.href.includes("admin")
              ? "desktopRightShieldAdmin"
              : ""
          }`}
        ></div>
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showNotifications && !memoVals.mob ? (
        <div
          className={`desktopNotifUnderlay ${
            window.innerWidth > 1140 ? "shadowUnderlay" : ""
          }`}
          onClick={() => {
            memoVals.setShowNotifications((x) => false);
            memoVals.setUnreadNotifs((x) => 0);
          }}
        >
          <div
            className={`desktopNotifBox ${
              window.location.href.includes("/rota") ||
              window.location.href.includes("/team-rota") ||
              window.location.href.includes("staff-calendar")
                ? "rotaNotifsBoxPosition"
                : ""
            } `}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className={`x98248423 ${
                window.location.href.includes("/rota") ||
                window.location.href.includes("/team-rota") ||
                window.location.href.includes("staff-calendar")
                  ? "x09482834"
                  : ""
              } ${window.innerWidth > 1140 ? "none" : ""}`}
              onClick={() => {
                memoVals.setShowNotifications((x) => false);
                setShowUpcoming(true);
              }}
            ></div>
            <div
              className={`x49849357843 ${
                window.location.href.includes("/rota") ||
                window.location.href.includes("/team-rota") ||
                window.location.href.includes("staff-calendar")
                  ? "x84746265233"
                  : ""
              } ${window.innerWidth > 1140 ? "none" : ""}`}
              onClick={() => {
                setShowAddDesktop(true);
                memoVals.setShowNotifications((x) => false);
                setShowUpcoming(false);
              }}
            ></div>

            <Notifications />
            {/* <div
            className="notifGhost"
            onClick={() => {
              setShowUpcoming(true);
              memoVals.setShowNotifications(x =>false);
            }}
          ></div>
          <div
            className="addGhost"
            onClick={() => {
              setShowUpcoming(false);
              memoVals.setShowNotifications(x =>false);
              setShowAddDesktop(true);
            }}
          ></div> */}
          </div>
        </div>
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showNotifications && memoVals.mob ? (
        <div
          className={`desktopNotifUnderlay x39248231  ${
            window.innerWidth > 1140 ? "shadowUnderlay" : ""
          }`}
          onClick={() => {
            memoVals.setShowNotifications((x) => false);
            memoVals.setUnreadNotifs((x) => 0);
          }}
        >
          <div
            className={`mobNotifBox slideUp ${
              memoVals.device.vhIssue ? "vhIssueNotifBox" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPosition}
            ref={modalRef}
          >
            <Notifications
              handleDown={handleDown}
              handleMove={handleMove}
              handleUp={handleUp}
              l
            />

            {/* <div
            className="notifGhost"
            onClick={() => {
              setShowUpcoming(true);
              memoVals.setShowNotifications(x =>false);
            }}
          ></div>
          <div
            className="addGhost"
            onClick={() => {
              setShowUpcoming(false);
              memoVals.setShowNotifications(x =>false);
              setShowAddDesktop(true);
            }}
          ></div> */}
          </div>
        </div>
      ) : (
        ""
      )}
      {!memoVals.standalone && (
        <div
          className={`${showUpcoming ? "desktopNotifUnderlay" : "none"}  ${
            window.innerWidth > 1140 ? "shadowUnderlay" : ""
          }`}
          onClick={() => {
            setShowUpcoming(false);
          }}
        >
          <div
            className={`x239829833 ${
              window.location.href.includes("/rota") ||
              window.location.href.includes("/team-rota") ||
              window.location.href.includes("staff-calendar")
                ? "x848887423"
                : ""
            } ${window.innerWidth > 1140 ? "none" : ""}`}
            onClick={() => {
              memoVals.setShowNotifications((x) => true);
              setShowUpcoming(false);
            }}
          ></div>
          <div
            className={`x49849357843 ${
              window.location.href.includes("/rota") ||
              window.location.href.includes("/team-rota") ||
              window.location.href.includes("staff-calendar")
                ? "x84746265233"
                : ""
            } ${window.innerWidth > 1140 ? "none" : ""}`}
            onClick={() => {
              setShowAddDesktop(true);
              memoVals.setShowNotifications((x) => false);
              setShowUpcoming(false);
            }}
          ></div>
          <div
            className={`desktopUpcomingBox ${
              window.location.href.includes("/rota") ||
              window.location.href.includes("/team-rota") ||
              window.location.href.includes("staff-calendar")
                ? "rotaUpcomingBox"
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="desktopUpcomingTitle">
              <img
                src={whitecross}
                alt="Close"
                className="closeUpcomingImg"
                onClick={() => setShowUpcoming(false)}
              />{" "}
              Upcoming ahead for {memoVals.checkUserState.fName}
            </p>
            <Upcoming
              desktop={true}
              desktopShow={showUpcoming}
              setShowUpcoming={showUpcoming ? setShowUpcoming : false}
            />
            {/* <div
            className="upcomingGhost"
            onClick={() => {
              setShowUpcoming(false);
              memoVals.setShowNotifications(x =>true);
            }}
          ></div>

          <div
            className="addGhost"
            onClick={() => {
              setShowUpcoming(false);
              memoVals.setShowNotifications(x =>false);
              setShowAddDesktop(true);
            }}
          ></div> */}
          </div>
        </div>
      )}

      {!memoVals.standalone && (
        <div
          className={`${showAddDesktop ? "desktopNotifUnderlay" : "none"} ${
            window.innerWidth > 1140 ? "shadowUnderlay" : ""
          }`}
          onClick={() => {
            memoVals.setShowNotifications((x) => false);
            setShowUpcoming(false);
            setShowAddDesktop(false);
          }}
        >
          <div
            className={`x8478268642323 ${
              window.innderWidth > 1140 ? "none" : ""
            } ${
              window.location.href.includes("/rota") ||
              window.location.href.includes("/team-rota") ||
              window.location.href.includes("staff-calendar")
                ? "x848887423"
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              console.log("EXECUTE");
              memoVals.setShowNotifications((x) => true);
              setShowUpcoming(false);
              setShowAddDesktop(false);
            }}
          ></div>
          <div
            className={`x32982874423  ${
              Window.innderWidth > 1140 ? "none" : ""
            }  ${
              window.location.href.includes("/rota") ||
              window.location.href.includes("/team-rota") ||
              window.location.href.includes("staff-calendar")
                ? "x94829873233"
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();

              setShowAddDesktop(false);
              memoVals.setShowNotifications((x) => false);
              setShowUpcoming(true);
            }}
          ></div>
          <div
            className={`${
              showAddDesktop
                ? `addBtnShield ${
                    window.location.href.includes("/rota") ||
                    window.location.href.includes("/team-rota") ||
                    window.location.href.includes("staff-calendar")
                      ? "teamRotaAddBtnShield"
                      : ""
                  }`
                : "none"
            }`}
            onClick={() => {
              setShowAddDesktop(!showAddDesktop);
              memoVals.setShowNotifications((x) => false);
              setShowUpcoming(false);
            }}
          >
            {" "}
            <img
              src={showAddDesktop ? plus : plus}
              alt="Add"
              className="desktopHeaderAddImg"
            />
            <p className="desktopPlusTxt">Add</p>
          </div>{" "}
          <div
            className={`addDesktopBox ${
              window.location.href.includes("/rota") ||
              window.location.href.includes("/team-rota") ||
              window.location.href.includes("staff-calendar")
                ? "teamRotaAddDesktopBox"
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p
              className="desktopLeaveReqBtn"
              onClick={() => {
                memoVals.setShowReqModal((x) => true);
                setShowAddDesktop(false);
              }}
            >
              Leave request
            </p>
            <p
              className="desktopAddBtns"
              onClick={() => {
                memoVals.setShowAddAvailModal((x) => true);
                setShowAddDesktop(false);
              }}
            >
              Availability
            </p>
            {/* <p
            className="desktopAddBtns"
            onClick={() => {
              setShowAddDesktop(false);
            }}
          >
            Overtime
          </p> */}
            <p
              className="desktopAddBtns"
              onClick={() => {
                setShowAddDesktop(false);
                setLaunchTimesheet(true);
              }}
            >
              Timesheet
            </p>
          </div>
        </div>
      )}

      {/* {!memoVals.showInstallModal && !memoVals.device.fullScreen && memoVals.mob ? (
        <img
          id="installButton"
          src={installBtn}
          alt="Install app"
          className="installAppImg"
          onClick={(e) => {
            // memoVals.setShowInstallModal(x =>true);
            if (promptAcioned) {
              memoVals.setShowInstallModal(x =>true);
            }
          }}
        />
      ) : (
        // <div
        //   className="installMobBtn"
        //   onClick={() => {
        //     memoVals.setShowInstallModal(x =>true);
        //   }}
        // >

        //   <img src={installMob} alt="Install" className="mobInstallBtnImg" />
        //   <div className="tapToInstallParent">
        //     <p className="tapToInstall">Tap to</p>
        //     <p className="tapToInstall2">install app</p>
        //   </div>
        // </div>
        ""
      )} */}

      {!memoVals.standalone && memoVals.showAddAbsenceModal ? (
        <AddAbsenceModal />
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showAddLeaveOnTeam ? (
        <AddShiftModal2
          teamID={"allStaff"}
          type="leave"
          absence={memoVals.showAddLeaveOnTeam === "abs" ? true : false}
          setShowAddLeaveOnTeam={memoVals.setShowAddLeaveOnTeam}
          loadFromReqsPage={dateStringer.createStringFromTimestamp(
            new Date().getTime()
          )}
        />
      ) : (
        ""
      )}

      {!memoVals.standalone && memoVals.showAddTeamModal ? (
        <div
          className="messagesModalUnderlay"
          onClick={() => {
            memoVals.setShowAddTeamModal((x) => false);
          }}
        >
          <div
            className={`addTeamModalBox ${
              memoVals.mobModal ? "mobModalShoulder" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="addTagModalHeader">
              <img
                className="closeAddTagModalImg"
                src={close}
                alt="Close"
                onClick={() => {
                  memoVals.setShowAddTeamModal((x) => false);
                  setAddTeamNameVal("");
                }}
              />
              <p className="addTagModalHeaderTitle">Add team</p>
              <p className="phantomModalHeaderUnit">&nbsp;</p>
            </div>
            <p className="shiftNotesTitleTxt">Team name</p>

            <input
              className="adminTeamNameInput addAdminTeamNameInput"
              value={addTeamNameVal}
              onChange={(e) => {
                setAddTeamNameVal(e.target.value);
              }}
            ></input>

            <div className="addTeamModalFooter">
              <div className={`backAndDeleteShiftContainer `}>
                <div
                  className={`createTeamBtn ${
                    !addTeamNameVal ? "disable" : ""
                  }`}
                  onClick={() => {
                    // fire add team request here
                    memoVals.setShowAddTeamModal((x) => false);
                    setAddTeamNameVal("");
                    axios
                      .post(
                        `${serverURL}/add-new-team`,
                        { teamName: addTeamNameVal },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setShowViewTeamModal((x) => {
                            return {
                              teamID: response.data.teamID,
                            };
                          });
                          memoVals.setUpdateTeam((x) => {
                            return {
                              new: true,
                              teamID: response.data.teamID,
                              mgrs: [],
                              users: [],
                              teamName: addTeamNameVal,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Add team
                </div>
              </div>
              <p
                className={`closeEditShiftBtn `}
                onClick={() => {
                  memoVals.setShowAddTeamModal((x) => false);
                  setAddTeamNameVal("");
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {!memoVals.standalone && memoVals.showReqStats === "myStats" ? (
        <div
          className="reqStatsUnderlay"
          onClick={(e) => {
            memoVals.setShowReqStats((x) => "");
          }}
        >
          <MyReqsDataBox
            modal={true}
            setShowAllows={memoVals.setShowReqStats}
            selectedYear={memoVals.selectedYear}
            setSelectedYearParent={memoVals.setSelectedYear}
          />
        </div>
      ) : (
        ""
      )}
      {!memoVals.standalone && memoVals.showMyDocsModal ? (
        <div
          className="reqStatsUnderlay"
          onClick={(e) => {
            memoVals.setShowMyDocsModal((x) => false);
          }}
        >
          <MyDocsModal />
        </div>
      ) : (
        ""
      )}

      {memoVals.showUpdatedReqsModal[0] ? (
        <div
          className={`addAbsenceModalUnderlay ${
            memoVals.showReqMgrModal ? "invis" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              The following leave requests and absence periods have been
              updated:
            </p>
            <div className="updatedReqsBox">
              {memoVals.showUpdatedReqsModal.map((x) => {
                return (
                  <div
                    className="updatedReqItem"
                    key={x.reqID}
                    onClick={() => {
                      memoVals.setShowReqMgrModal((x) => x.reqID);
                    }}
                  >
                    <p className="updatedReqNameStrip textAlignLeft">
                      {x.fName} {x.lName} <br />
                      {dateStringer.formatMinsDurationToHours(x.durMins)}{" "}
                      {!x.zeroHours ? "from contracted hours" : ""}
                      {!x.zeroHours ? <br /> : ""} {!x.zeroHours ? "+" : ""}{" "}
                      {x.zeroHours
                        ? ""
                        : x.payExcess
                        ? dateStringer.formatMinsDurationToHours(x.excessMins)
                        : "nil"}{" "}
                      {!x.zeroHours ? "from overtime" : ""}
                    </p>
                    <p className="updatedReqNameStrip textAlignRight">
                      {dateStringer.printedDateFromDs(x.startDs, true)}{" "}
                      {x.startDs !== x.endDs
                        ? `- ${dateStringer.printedDateFromDs(x.endDs, true)}`
                        : ""}
                      <br />
                      {x.typeName}
                      <br />
                      {x.isAbsence ? "Absence" : "Leave"}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  memoVals.setShowUpdatedReqsModal((x) => []);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <img src={horizontalBalls} alt="1" className="imagesHidden" />

      {!memoVals.standalone && renderBulkLimitModal()}
      {!memoVals.standalone &&
        memoVals.checkUserState.permissions === 1 &&
        window.innerWidth > 978 && (
          <p
            className={`adminTopRight ${
              showAdminActions ? "adminTopRightActive" : ""
            }`}
            onMouseEnter={() => {
              setShowAdminActions(!showAdminActions);
            }}
            onMouseLeave={() => {
              setShowAdminActions(!showAdminActions);
            }}
          >
            Admin actions{" "}
            <img
              src={whiteCog}
              alt="Admin actions"
              className={`adminActionsCog `}
            />
          </p>
        )}
      {!memoVals.standalone &&
      memoVals.noPermissions &&
      memoVals.noPermissions.show &&
      memoVals.noPermissions.type ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            {memoVals.noPermissions.type === "period" ? (
              <p className="overlapsRenTxt">
                You do not manage{" "}
                {dateStringer.possession(memoVals.noPermissions.fName)} primary
                team, {memoVals.noPermissions.teamName} - so you are unable to
                manage this {memoVals.noPermissions.type}.
              </p>
            ) : (
              <p className="overlapsRenTxt">
                You do not manage {memoVals.noPermissions.teamName} so you are
                unable to manage this {memoVals.noPermissions.type}.
              </p>
            )}
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  memoVals.setNoPermissions((x) => false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {!memoVals.standalone &&
        memoVals.checkUserState.permissions === 1 &&
        window.innerWidth > 978 &&
        showAdminActions && (
          <div
            className="adminActionsMenu"
            onMouseEnter={() => {
              setShowAdminActions(true);
            }}
            onMouseLeave={() => {
              if (showAdminActions) {
                setShowAdminActions(false);
              }
            }}
          >
            <p
              to="/admin/employees"
              className="adminActionsRow"
              onClick={() => {
                memoVals.setShowAddUserModal((x) => true);
                setShowAdminActions(false);
              }}
            >
              Add an employee
            </p>
            <NavLink to="/admin/teams" className="adminActionsRow">
              Add / remove a team
            </NavLink>
            <NavLink to="/admin" className="adminActionsRow">
              General settings
            </NavLink>
            <NavLink to="/admin/leave-settings" className="adminActionsRow">
              Leave settings
            </NavLink>
            <NavLink to="/admin/closed-days" className="adminActionsRow">
              {memoVals.currencySymbol === "£"
                ? "Bank holidays"
                : "Public holidays"}
            </NavLink>
            <NavLink to="/admin/absence" className="adminActionsRow">
              Absence settings
            </NavLink>
            <NavLink to="/admin/overtime" className="adminActionsRow">
              Overtime settings
            </NavLink>
            <NavLink to="/admin/billing" className="adminActionsRow">
              Billing
            </NavLink>
            <NavLink to="/admin/administrators" className="adminActionsRow">
              Administrators
            </NavLink>
          </div>
        )}
      {/* {window.innerWidth < 1200 && window.location.href.includes("192") && ( */}
      {!window.location.href.includes("app") && (
        <div className="x090910923091">
          {window.innerWidth}
          {memoVals.fullButNarrow ? "-FBN" : ""}-
          {memoVals.checkUserState.todayCheckUserRequests}
        </div>
      )}
      {!memoVals.standalone && memoVals.indicate.show ? <Indicate /> : ""}

      {/* )} */}
      {/* <div className="x12345">{window.innerHeight}</div>
      <div className="x54321">{window.outerHeight}</div> */}
    </div>
  );
};

export default Navbar;
