import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { DataContext } from "../../../../contexts/DataContext";
import { CalendarContext } from "../../../../contexts/CalendarContext";
import { UserContext } from "../../../../contexts/UserContext";

import serverURL from "../../../../serverURL";
import { StyleContext } from "../../../../contexts/StyleContext";

const ClockDetails = ({
  isAdmin,
  isSelf,
  unitUserID,
  availableZones,
  zones,
  managesOwnTeams,
  managesOwnTeam,
  editUserState,
  setEditUserState,
}) => {
  const { mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      mob,
    }),
    [mob]
  );
  let generateManagesOwnTeams = managesOwnTeams.map((t) => {
    return (
      <p className="managesOwnTeamRow" key={t.teamID}>
        - {t.teamName}
      </p>
    );
  });

  let detailsContainerRefClock = useRef();
  useEffect(() => {
    if (detailsContainerRefClock.current) {
      detailsContainerRefClock.current.scrollTop = 0;
    }
  }, []);

  return (
    <div className="editUserMainBodyContainer">
      <div className="EditUserTeamsContainer" ref={detailsContainerRefClock}>
        {/* new row ---------------- */}
        <div
          className={`editUserRow ${!mob ? "hoverRowColour" : ""} ${
            !isAdmin && isSelf ? "notAdminIsSelf" : ""
          }`}
          onClick={() => {
            if (!isAdmin && isSelf) {
              setShowIsSelfNotAdminModal(true);
            } else {
              setEditUserState({
                clockOnaAllowed: !editUserState.clockOnAllowed,
              });

              axios
                .post(
                  `${serverURL}/toggle-user-allow-clock-on`,
                  {
                    userID: unitUserID,
                    newData: !editUserState.clockOnAllowed,
                    nowTs: new Date().getTime(),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  setEditUserState({ clockOnAllowed: response.data.data });
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }}
        >
          <p className="editUserEditTitle clockTitleSize">
            Allow clock on / off
          </p>
          <div className="reqClockinValContainer">
            <div className="editUserReqClockOutKnobContainer clockInKnob">
              <div className="knobContainer knobContainerEditUser">
                <div
                  className={`switchKnob ${
                    editUserState.clockOnAllowed ? "knobOn" : "knobOff"
                  }`}
                ></div>
                <div className="knobBg"></div>
              </div>
            </div>
          </div>
        </div>
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow ${!mob ? "hoverRowColour" : ""} ${
              !isAdmin && isSelf ? "notAdminIsSelf" : ""
            }`}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                // console.log(";;", editUserState.editReqClockout,);
                setEditUserState({
                  editReqClockout: !editUserState.editReqClockOut,
                });
                let nowTs = new Date();

                axios
                  .post(
                    `${serverURL}/edit-user-clock-on-bool`,
                    {
                      userID: unitUserID,
                      newData: !editUserState.editReqClockout,
                      nowTs: nowTs.getTime(),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({
                      editReqClockout: response.data.reqClockOn,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <p className="editUserEditTitle clockTitleSize">
              Require clock on / off
            </p>
            <div className="reqClockinValContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.editReqClockout ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow ${!mob ? "hoverRowColour" : ""} ${
              !isAdmin && isSelf ? "notAdminIsSelf" : ""
            }`}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                // console.log(";;", editUserState.editReqClockout,);
                setEditUserState({
                  enableGPS: !editUserState.enableGPS,
                });
                let nowTs = new Date();

                axios
                  .post(
                    `${serverURL}/toggle-user-enable-gps`,
                    {
                      userID: unitUserID,
                      data: !editUserState.enableGPS,
                      nowTs: nowTs.getTime(),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({
                      enableGPS: response.data.enableGPS,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <p className="editUserEditTitle clockTitleSize">
              Request GPS when clocking on / off
            </p>
            <div className="reqClockinValContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.enableGPS ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}

        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow approveClockEditUserRow ${
              !mob ? "hoverRowColour" : ""
            } ${!isAdmin && isSelf ? "notAdminIsSelf" : ""}`}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                setEditUserState({
                  autoApproveClock: !editUserState.autoApproveClock,
                });

                axios
                  .post(
                    `${serverURL}/toggle-auto-approve-clock`,
                    {
                      userID: unitUserID,
                      data: !editUserState.autoApproveClock,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({ autoApproveClock: response.data.data });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <div
              className={`autoApproveClockDiv ${
                !memoVals.mob ? "desktopAutoApproveClockDiv" : ""
              }`}
            >
              <p className="editUserEditTitle clockTitleSize">
                Auto-approve clock on / off
              </p>
              <p className="autoApproveExplain">
                If selected, {editUserState.editFName}
                {editUserState.editFName &&
                editUserState.editFName[editUserState.editFName.length - 1] ===
                  "s"
                  ? ""
                  : "'s"}{" "}
                clock cards will be automatically approved when{" "}
                {editUserState.editFName} clocks off, creating a shift that{" "}
                {editUserState.editFName} will be paid for based on the clocked
                times.
              </p>
            </div>
            <div className="reqClockinValContainer autoApproveClockKnobContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.autoApproveClock ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed && managesOwnTeam ? (
          <div
            className={`editUserRow approveClockEditUserRow ${
              !mob ? "hoverRowColour" : ""
            } ${
              !isAdmin && isSelf ? "notAdminIsSelf" : ""
            } approveOwnClocksRow`}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                setEditUserState({
                  allowSelfClockApprove: !editUserState.allowSelfClockApprove,
                });

                axios
                  .post(
                    `${serverURL}/toggle-allow-self-clock-approve`,
                    {
                      userID: unitUserID,
                      data: !editUserState.allowSelfClockApprove,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({
                      allowSelfClockApprove: response.data.data,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <div
              className={`autoApproveClockDiv ${
                !memoVals.mob ? "desktopAutoApproveClockDiv" : ""
              }`}
            >
              <p className="editUserEditTitle">
                Allow {editUserState.editFName} to approve and amend their own
                requests and submissions
                {/* (devnote: only admins can see this row) */}
              </p>{" "}
              <div className="amendOwnClocksRow">
                {editUserState.editFName} is both a member and manager of:{" "}
                {generateManagesOwnTeams}
                <br />
                If unselected, {editUserState.editFName}
                {editUserState.editFName &&
                editUserState.editFName[editUserState.editFName.length - 1] ===
                  "s"
                  ? ""
                  : "'s"}{" "}
                leave requests, overtime submissions and clock cards will need
                to be approved by other managers of the team.
              </div>
            </div>
            <div className="reqClockinValContainer autoApproveClockKnobContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.allowSelfClockApprove ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow approveClockEditUserRow ${
              !mob ? "hoverRowColour" : ""
            } ${
              !memoVals.mob
                ? "approveClockEditUserRowDesktop"
                : "payClockedRowMob"
            } ${!isAdmin && isSelf ? "notAdminIsSelf" : ""} `}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                setEditUserState({ payClocked: !editUserState.payClocked });

                axios
                  .post(
                    `${serverURL}/toggle-user-pay-clocked`,
                    {
                      userID: unitUserID,
                      newData: !editUserState.payClocked,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({
                      payClocked: response.data.data,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <div
              className={`autoApproveClockDiv ${
                !memoVals.mob ? "desktopAutoApproveClockDiv" : ""
              } payClockTimeOnlyDiv`}
            >
              <p className="editUserEditTitle payClockTimeOnlyTitle clockTitleSize">
                Pay clocked time only
              </p>
              <p className="autoApproveExplain">
                If selected, {editUserState.editFName} will be paid for the time
                that they have clocked. Otherwise, {editUserState.editFName}{" "}
                will be paid for the shift's scheduled times, regardless of
                clock on or off times. If {editUserState.editFName} has clocked
                on without being scheduled for a shift, they will be paid for
                the clocked time by default.
              </p>
            </div>
            <div className="reqClockinValContainer autoApproveClockKnobContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.payClocked ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
      </div>
    </div>
  );
};

export default ClockDetails;
