import React, { useState, useContext, useEffect } from "react";

import { DataContext } from "../../contexts/DataContext";

import "../../styles/signup.css";

import smallClose from "../../img/general/smallClose.svg";
import signUpAddTeamButton from "../../img/general/signUpAddTeamButton.svg";

const AddTeams = () => {
  // state for the team list
  const [teams, setTeams] = useState([]);

  // get signUpTeams from datacontext
  const { signUpTeams, setSignUpTeams } = useContext(DataContext);

  // randomise number for id
  const getRandomNumber = () => {
    return (
      (Math.random() * 9183917391) / (Math.random() + Math.random()) +
      Math.random()
    );
  };

  //   remove team function
  const removeTeam = (itemId) => {
    // array that excludes team with itemKey
    let filteredArray = teams.filter((item) => {
      return itemId !== item.id;
    });
    setTeams(filteredArray);
    setSignUpTeams(filteredArray);
  };

  const teamsOutput = teams.map((item) => {
    return (
      <div key={getRandomNumber()} className='signUpTeamsCollection'>
        <div className='signUpTeamUnit'>
          <p className='listTeamNameText'>{item.text}</p>
          <img
            className='signUpRemoveTeamImg'
            alt='close'
            src={smallClose}
            onClick={() => removeTeam(item.id)}
          />
        </div>
      </div>
    );
  });

  // team to be added:
  const [team, setTeam] = useState("");

  // update team to be added every time input changes
  const handleChange = (e) => {
    setTeam(e.target.value);
  };

  // when add button pressed, send the team to the addTeam fct
  const handleSubmit = (e) => {
    // check if team has content first, don't submit otherwise

    if (team) {
      addTeam(team);
    } else if (!team) {
    }
    // reset the input field to blank
    setTeam("");
    e.preventDefault();
  };

  // function to add the team to the teams array
  const addTeam = (team) => {
    setTeams([{ text: team, id: getRandomNumber() }, ...teams]);
  };
  let [action, setAction] = useState(false);

  // have to set the context state outside at the bottom - last thing to run
  useEffect(() => {
    setSignUpTeams(teams);
  }, [action]);

  // signup page: count how many teams. We only want 3 teams to be able to be added (due to scroll issue)
  const [supTeamQty, setSupTeamQty] = useState(0);

  // quantity of teams, can only have 3 max due to mobile scroll issue
  useEffect(() => {
    let qty = signUpTeams.length;
    setSupTeamQty(qty);
  });

  // team quantity checker - can only have 3 teams on signup due to scroll issue
  let supTeamsFull = false;
  if (supTeamQty > 2) {
    supTeamsFull = true;
  } else {
    supTeamsFull = false;
  }

  return (
    <div>
      <input
        className={`${supTeamsFull ? "xx" : ""}`}
        name='signUpTeamName'
        id='signUpTeamName'
        type='text'
        value={team}
        onChange={(e) => handleChange(e)}
        value={team}
      ></input>
      <img
        src={signUpAddTeamButton}
        className={`${supTeamsFull ? "xx" : "addTeamBtnSignup"}`}
        onClick={(e) => {
          handleSubmit(e);
          setAction(!action);
        }}
      />
      <div className='signUpTeamsContainer'>{teamsOutput}</div>
    </div>
  );
};

export default AddTeams;
