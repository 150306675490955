import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  Fragment,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
import MgrAccountNavbar from "./MgrAccountNavbar";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import logo from "../../img/general/logo.svg";
import activate from "../../img/general/activate.png";

import close from "../../img/modals/close.svg";
import whiteDown from "../../img/general/whiteDown.svg";
import popup3 from "../../img/general/popup3.svg";
import popup2 from "../../img/general/popup2.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import editUserEdit1 from "../../img/general/editUserEdit1.svg";
import userIcon from "../../img/general/userIcon.svg";
import teamIcon from "../../img/general/teamIcon.svg";
import teamIcon2 from "../../img/general/teamIcon2.svg";
import editGrey from "../../img/general/editGrey.svg";
import signout from "../../img/general/signout.svg";
import install from "../../img/general/install.svg";
import adminBurgerMob from "../../img/general/adminBurgerMob.svg";

// content components

import TopBar from "../../components/TopBar";
import NotifSettings from "./NotifSettings";

import dateStringer from "../../tools/dateStringer";

import MyReqsDataBox from "../requests/MyReqsDataBox";

import HoursDataBox from "../calendar/shifts/HoursDataBox";
import appURL from "../../appURL";

const Welcome = ({}) => {
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  let [success, setSuccess] = useState(false);
  let [loading, setLoading] = useState(true);
  let [fName, setFName] = useState("");
  let [companyName, setCompanyName] = useState("");
  let [password, setPassword] = useState(""); //  should be at least 6 characters
  let [passInvalid, setPassInvalid] = useState(false);
  let [userID, setUserID] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  let [location, setLocation] = useState("Unknown location");

  useEffect(() => {
    // Function to fetch IP address
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);

        const locationApi = await fetch(`https://ip-api.com/json/${data.ip}`);
        const data2 = await locationApi.json();

        setLocation(data2.city);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.uid && params.cid) {
      setUserID(params.uid);
      axios
        .post(
          `${serverURL}/check-welcome-link`,
          { userID: params.uid, clientID: params.cid },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setSuccess(true);
            setLoading(false);
            setFName(response.data.fName);
            setCompanyName(response.data.companyName);
          } else {
            setSuccess(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      let currentUrl = new URL(window.location.href);

      // Remove query parameters by setting search to an empty string
      currentUrl.search = "";

      // Update the URL without the query parameters
      window.history.replaceState({}, document.title, currentUrl.href);
    } else {
      setSuccess(false);
      setLoading(false);
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let savePass = () => {
    // activate axios here
    if (userID && password.length >= 6) {
      function getDeviceName() {
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
          return "Android Device";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
          return "iOS Device";
        } else if (/Windows Phone/i.test(userAgent)) {
          return "Windows Phone";
        } else if (/Windows/i.test(userAgent)) {
          return "Windows PC";
        } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
          return "Macintosh";
        } else if (/Linux|X11/i.test(userAgent)) {
          return "Linux";
        } else {
          return "Unknown Device";
        }
      }
      axios
        .post(
          `${serverURL}/activate-account`,
          {
            userID,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            password,
            ip: ipAddress,
            location,
            deviceName: getDeviceName(),
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.loggedIn && response.data.user.userID === userID) {
            window.location.href = "/calendar";
          } else {
            console.log("Failed to activate user", response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  let enterFct = (e, go) => {
    if (e.key === "Enter" || go)
      if (password.length < 6) {
        setPassInvalid(true);
      } else {
        savePass();
      }
  };

  return (
    <div
      className={`resetPasswordPage ${
        window.innerWidth > 820 ? "resetPassPageDesktop" : ""
      }`}
    >
      {window.innerWidth > 820 && (
        <div className="activateSideBox">
          <img src={activate} alt="Activate" className="activateImg" />
        </div>
      )}
      <div
        className={`resetpassMain ${
          window.innerWidth > 820 ? "paddingRight10pc" : "mobPassMain"
        }`}
      >
        <img src={logo} alt="FlowRota logo" className="resetPassLogo" />
        {!loading && !success && 2 + 2 === 5 ? (
          <p className="resetPassTitle">
            Unable to activate this account.
            <br />
            <br />
            The account has either been deleted or already activated.
          </p>
        ) : (
          <Fragment>
            {" "}
            <p className="resetPassTitle">
              Welcome to FlowRota, {fName}!<br />
              <br />
              To get started, set a password below.
            </p>
            <input
              type="password"
              className="resetPassInput"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (passInvalid) {
                  setPassInvalid(false);
                }
              }}
              onKeyPress={(e) => {
                enterFct(e);
              }}
            />
            <p
              className={`requestNewPassBtn ${
                password.length < 6 ? "disabledActivate" : ""
              }`}
              onClick={() => {
                savePass("", true);
              }}
            >
              Activate
            </p>
            {passInvalid ? (
              <p className="emailDoesNotExistTxt">
                Your password should be at least 6 characters long
              </p>
            ) : (
              ""
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Welcome;
