import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import serverURL from "../../serverURL";

import "../../styles/tower.css"; 

import TowerHeader from "./TowerHeader";
import TowerSidebar from "./TowerSidebar"; 
import TowerDashboard from "./TowerDashboard";
import TowerTasks from "./TowerTasks";
import TowerClients from "./TowerClients";
import TowerBilling from "./TowerBilling";
import TowerNotepad from "./TowerNotepad";
import TowerSettings from "./TowerSettings";
import TowerPromotions from "./TowerPromotions";

import dateStringer from "../../tools/dateStringer";

import CheckUser from "../../tools/CheckUser";

import { TowerContext } from "../../contexts/TowerContext";

import cog from "../../img/general/cog.svg";

const TowerIndex = () => {
  CheckUser();

  let [valid, setValid] = useState(false);

  const { page } = useContext(TowerContext);

  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-tower-data-init`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        console.log(response.data.message);
        if (response.data.message === "user is tower controller") {
          setValid(true);
        } else {
          console.log("user is not a controller");
          setValid(false);
          window.location.href = "/menu";
        }
      });
  }, []);

  let renderContent = () => {
    if (page === "dashboard") {
      return <TowerDashboard />;
    }
    if (page === "tasks") {
      return <TowerTasks />;
    }
    if (page === "clients") {
      return <TowerClients />;
    }
    if (page === "billing") {
      return <TowerBilling />;
    }
    if (page === "notepad") {
      return <TowerNotepad />;
    }
    if (page === "settings") {
      return <TowerSettings />;
    }
    if (page === "promotions") {
      return <TowerPromotions />;
    }
  };

  // MASTER RETURN
  if (valid) {
    return (
      <div className='towerContainer'>
        <TowerHeader />
        <div className='towerBody'>
          <TowerSidebar />
          <div className='towerContent'>{renderContent()}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='noPermissionsForTower'>You do not have permissions</div>
    );
  }
};

export default TowerIndex;
