// Console logs removed: 28 Sep 22
// useMemo: 28 sep 22 -- do not use, as it affects the useRefs, plus it's not really needed
// useReducer: not needed, 28 sep 22
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { usePageVisibility } from "react-page-visibility";

// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import serverURL from "../serverURL";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import proPicPlaceholder from "../img/general/proPicPlaceholder.svg";
import magni from "../img/general/magni.svg";
import whiteGoBackTransparent from "../img/general/whiteGoBackTransparent.svg";
import send from "../img/general/send.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import notifBell from "../img/general/notifBell.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const MessagesModal = () => {
  const { showMessages, setShowMessages, setMsgQty } = useContext(DataContext);
  const { mobModal, mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      showMessages,
      setShowMessages,
      setMsgQty,
      mobModal,
      mob,
    }),
    [
      showMessages, //
      setShowMessages, //
      setMsgQty, //
      mobModal, //
      mob, //
    ]
  );

  let [loadAllLoaded, setLoadAllLoaded] = useState(false);

  let [lastActiveDs, setLastActiveDs] = useState("");

  let [dataLoaded, setDataLoaded] = useState(false);
  let [loading, setLoading] = useState(true);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [showPushSettings, setShowPushSettings] = useState(false);
  let [push, setPush] = useState(false);
  let [pushWide, setPushWide] = useState(false); // if user push settings generally enabled?

  let [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 7) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={notifBell}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={proPicPlaceholder}
        alt="Profile Picture Placeholder"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={magni}
        alt="Magni"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGoBackTransparent}
        alt="White Go Back Transparent"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={send}
        alt="Send"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="Profile Picture Placeholder"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [chatHeads, setChatHeads] = useState([]);
  let [selectedChatUserID, setSelectedChatUserID] = useState("");
  let [selectedIndex, setSelectedIndex] = useState(-1);
  let [selectedFName, setSelectedFName] = useState("");
  let [selectedLName, setSelectedLName] = useState("");

  let [selectedProfPic, setSelectedProfPic] = useState("");
  let [msg, setMsg] = useState("");
  let [thread, setThread] = useState([]);
  let chatInput = useRef();
  let chatBox = useRef();
  let mobChatHeads = useRef();
  let messagesLeft = useRef();

  let chatHeadScroller = useRef();
  let [hideLoadAll, setHideLoadAll] = useState(false);
  let [search, setSearch] = useState("");
  let [unreadUserIDs, setUnreadUserIDs] = useState([]);
  let [msgSent, setMsgSent] = useState(false);
  let [alreadyLoaded, setAlreadyLoaded] = useState(false);

  let countUnread = () => {
    axios
      .post(
        `${serverURL}/count-unread-messages`,
        {
          someData: 1,
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          memoVals.setMsgQty((x) => response.data.unreadQty);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    // clean not needed
    if (
      !selectedChatUserID &&
      chatHeadScroller &&
      chatHeadScroller.current &&
      memoVals.mob
    ) {
      chatHeadScroller.current.scrollTop = 0;

      // var objDiv = document.getElementsByClassName("mobChatThread");
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  }, [selectedChatUserID]);
  useEffect(() => {
    if (
      selectedChatUserID &&
      messagesLeft &&
      messagesLeft.current &&
      !memoVals.mob
    ) {
      messagesLeft.current.scrollTop = 0;
    }
  }, [msgSent]);

  let selectedUserRef = useRef();
  useEffect(() => {
    // Check if selectedUserRef exists and selectedChatUserID is not null
    setTimeout(() => {
      if (selectedUserRef.current && selectedChatUserID && !mobModal) {
        // Scroll the selectedUserRef element into view
        selectedUserRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }, 500);
  }, [selectedChatUserID]);

  useEffect(() => {
    // clean not needed

    if (selectedChatUserID && !memoVals.mob && chatInput.current) {
      chatInput.current.focus();
    }
  }, [selectedChatUserID]);

  let [scrollToMobChatHead, setScrollToMobChatHead] = useState(false);
  // let targetHead = useRef();
  useEffect(() => {
    if (mobChatHeads.current) {
      const targetElement = document.getElementById("x111");

      if (targetElement) {
        mobChatHeads.current.scrollLeft = targetElement.scrollLeft;
      }
    }
  }, [scrollToMobChatHead]);

  useEffect(() => {
    if (mobChatHeads.current) {
      if (selectedChatUserID && mobChatHeads && mobChatHeads.current) {
        mobChatHeads.current.scrollLeft = 0;
      }
    }
    if (messagesLeft.current) {
      if (selectedChatUserID && messagesLeft && messagesLeft.current) {
        messagesLeft.current.scrollTop = 0;
      }
    }
  }, [selectedChatUserID]);

  useEffect(() => {
    // clean not needed

    if (mobChatHeads.current) {
      //       var objDiv = document.getElementsByClassName("mobChatThread");
      // objDiv.scrollTop = objDiv.scrollHeight;
      mobChatHeads.current.scrollLeft = 0;
    }
  }, [msgSent]);

  let scrollToBottom = () => {
    if (chatBox.current) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;

      var objDiv = document.getElementsByClassName("mobChatThread");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  useEffect(() => {
    // clean not needed

    if (
      !selectedChatUserID &&
      chatHeads[0] &&
      !alreadyLoaded &&
      !memoVals.mob
    ) {
      setSelectedChatUserID(chatHeads[0].userID);
      memoVals.setShowMessages((x) => chatHeads[0].userID);

      setSelectedFName(chatHeads[0].fName);
      setSelectedLName(chatHeads[0].lName);
      setSelectedProfPic(chatHeads[0].profPicUrl);
      setSelectedIndex(0);
    }
  }, [chatHeads]);

  const isVisible = usePageVisibility();

  let getMsgs = (canxSourceToken) => {
    // console.log({ dontLoadUser });
    axios
      .post(
        `${serverURL}/get-users-for-messages`,
        {
          someData: 1,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: canxSourceToken,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setPush(response.data.push);
          setPushWide(response.data.pushWide);
          if (
            typeof memoVals.showMessages === "string" &&
            !selectedChatUserID
          ) {
            setSelectedChatUserID(memoVals.showMessages);
            memoVals.setShowMessages((x) => true);
            let arr = [];
            let unreadIDs = [];

            response.data.clientUsers.forEach((usr, i) => {
              if (usr.userID === memoVals.showMessages) {
                // if (!dontLoadUser) {
                setSelectedIndex(i);
                setSelectedFName(usr.fName);
                setSelectedLName(usr.lName);

                setSelectedProfPic(usr.profPicUrl);
                setAlreadyLoaded(true);
                // }
                usr.ts = 999999999999900;

                arr.push(usr);
              } else {
                arr.push(usr);
              }

              if (usr.unread) {
                unreadIDs.push(usr.userID);
              }
            });

            setUnreadUserIDs(unreadIDs);

            arr.sort((a, b) => {
              return b.ts - a.ts;
            });

            setChatHeads(arr);
          } else {
            setChatHeads(response.data.clientUsers);
            let unreadIDs = [];

            response.data.clientUsers.forEach((usr) => {
              if (usr.unread) {
                unreadIDs.push(usr.userID);
              }
            });
            setAlreadyLoaded(true);
            setUnreadUserIDs(unreadIDs);

            // if (!memoVals.mob ) {
            //   setSelectedChatUserID(response.data.clientUsers[0].userID);
            //   setSelectedFName(response.data.clientUsers[0].fName);
            //   setSelectedLName(response.data.clientUsers[0].lName);

            //   setSelectedProfPic(response.data.clientUsers[0].profPicUrl);
            //   setSelectedIndex(0);
            // }
          }
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let getThread = (userID, canxSourceToken) => {
    if (userID) {
      axios
        .post(
          `${serverURL}/get-message-thread`,
          {
            otherUserID: userID,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: canxSourceToken,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setLastActiveDs(response.data.lastActive);

            setThread(response.data.messages);

            if (response.data.messages[0]) {
              let bankItem = {
                userID: selectedChatUserID,
                thread: response.data.messages,
              };

              let newArr = [];
              newArr.push(bankItem);
              chatBank.forEach((obj) => {
                if (selectedChatUserID.userID === obj.userID) {
                } else {
                  newArr.push(obj);
                }
              });

              setChatBank(newArr);
            } else {
            }

            // let newArr = [];
            // let valToCompare =
            //   typeof memoVals.showMessages === "string"
            //     ? memoVals.showMessages
            //     : selectedChatUserID;

            // unreadUserIDs.forEach((uid) => {
            //   if (uid === valToCompare) {
            //   } else {
            //     newArr.push(uid);
            //   }
            // });

            // setUnreadUserIDs(newArr);

            setLoadingThread(false);

            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    setModalPosition({
      bottom: "0%",
      left: "0%",
    });
    // cleaned
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    getMsgs(cancelSource1.token);
    if (selectedChatUserID) {
      getThread(selectedChatUserID, cancelSource2.token, true);
    }
    let intervaller = setInterval(() => {
      if (memoVals.showMessages && !loadAllLoaded) {
        getMsgs(cancelSource1.token);
        if (selectedChatUserID) {
          getThread(selectedChatUserID, cancelSource2.token);
        }
      }
    }, 6000);

    if (!memoVals.showMessages) {
      clearInterval(intervaller);
    }

    return () => {
      clearInterval(intervaller);
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.showMessages, selectedChatUserID, loadAllLoaded]);

  useEffect(() => {
    if (!isVisible) {
      memoVals.setShowMessages((x) => false);
    }
  }, [isVisible]);

  let shortenPreview = (val) => {
    if (val && val.length > 30) {
      return `${val.substr(0, 26)}...`;
    } else return val;
  };

  let [chatBank, setChatBank] = useState([]);

  let [loadingThread, setLoadingThread] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (selectedChatUserID || typeof memoVals.showMessages === "string") {
      if (!loading) {
        setHideLoadAll(false);
        axios
          .post(
            `${serverURL}/get-message-thread`,
            {
              otherUserID:
                typeof memoVals.showMessages === "string"
                  ? memoVals.showMessages
                  : selectedChatUserID,
              messagesBeforeTs: false,
              usingShowMessages:
                typeof memoVals.showMessages === "string" ? true : false,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setLastActiveDs(response.data.lastActive);

              setThread(response.data.messages);

              if (response.data.messages[0]) {
                let bankItem = {
                  userID: selectedChatUserID,
                  thread: response.data.messages,
                };

                let newArr = [];
                newArr.push(bankItem);
                chatBank.forEach((obj) => {
                  if (selectedChatUserID.userID === obj.userID) {
                  } else {
                    newArr.push(obj);
                  }
                });

                setChatBank(newArr);
              } else {
              }

              let newArr = [];
              let valToCompare =
                typeof memoVals.showMessages === "string"
                  ? memoVals.showMessages
                  : selectedChatUserID;

              unreadUserIDs.forEach((uid) => {
                if (uid === valToCompare) {
                } else {
                  newArr.push(uid);
                }
              });

              setUnreadUserIDs(newArr);

              setLoadingThread(false);

              setLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedChatUserID]);

  let generateChatHeads = chatHeads
    .sort((a, b) => {
      // Check if either a or b has userID '123'
      if (a.userID === memoVals.showMessages) return -1;
      if (b.userID === memoVals.showMessages) return 1;

      // If neither has userID '123', sort by timestamp
      return b.ts - a.ts; // Sort newest at the top
    })
    .map((item, i) => {
      let nameString = `${item.fName} ${item.lName}`;
      if (
        search === "" ||
        nameString.toLowerCase().includes(search.toLocaleLowerCase())
      ) {
        return (
          <div
            ref={item.userID === selectedChatUserID ? selectedUserRef : null}
            className={`chatHeadUnit ${
              selectedIndex - 1 === i ? "messageNoBorder" : ""
            } ${selectedChatUserID === item.userID ? "selectedChatHead" : ""}`}
            onClick={() => {
              memoVals.setShowMessages((x) => item.userID);
              setSelectedChatUserID(item.userID);
              setSelectedIndex(i);
              setSelectedFName(item.fName);
              setSelectedLName(item.lName);

              setSelectedProfPic(item.profPicUrl);
              // scrolloBottom();
            }}
          >
            <div className="chatHeadL">
              <img
                src={item.profPicUrl || proPicPlaceholder}
                alt={item.fName}
                className="chatHeadProfPicImg"
              />
            </div>
            <div className="chatHeadR">
              <p
                className={`chatHeadName ${
                  selectedChatUserID === item.userID ? "colourWhite" : ""
                }`}
              >
                {item.fName} {item.lName}
              </p>
              {item.preview ? (
                <p
                  className={`chatHeadMsgPreview ${
                    unreadUserIDs.includes(item.userID) ? "unreadColour" : ""
                  } ${selectedChatUserID === item.userID ? "colourWhite" : ""}`}
                >
                  {shortenPreview(item.preview)}
                </p>
              ) : (
                ""
              )}
            </div>
            <div
              className={`${
                unreadUserIDs.includes(item.userID)
                  ? "unreadDiv"
                  : "unreadDiv invis"
              }`}
            >
              &nbsp;
            </div>
          </div>
        );
      }
    });

  let generateThread = thread.map((chat) => {
    let timestrip = "";
    let todayDs = dateStringer.createStringFromTimestamp(new Date().getTime());
    if (chat.ds.substr(0, 11) === todayDs.substr(0, 11)) {
      timestrip = dateStringer.dsToTimeStrip(chat.ds);
    } else {
      timestrip = dateStringer.printedDateFromDs(chat.ds);
    }
    return (
      <div className="chatItemParentDesktop">
        <div
          className={`chatItemDesktop ${chat.in ? "flexLeft" : "flexRight"}`}
          key={chat.messageID}
        >
          {chat.in ? (
            selectedProfPic ? (
              <img
                src={selectedProfPic}
                className="threadProPic"
                alt={selectedFName}
              />
            ) : (
              <div className="msgsNoPicInitialDiv">
                {selectedFName[0]}
                {selectedLName[0]}
              </div>
            )
          ) : (
            ""
          )}
          <p className={`${chat.in ? "chatTxtIn" : "chatTxtOut"}`}>
            {chat.message}
          </p>
        </div>
        {chat.in ? <p className="chatDs">{timestrip}</p> : ""}
        {chat.out ? <p className="chatDsRight">{timestrip}</p> : ""}
      </div>
    );
  });

  let enterFct = (e) => {
    if ((e.key === "Enter" || e === "send") && msg) {
      if (memoVals.mob) {
        let inputElement = document.getElementById("msgInputField");
        if (inputElement) {
          inputElement.blur();
        }
      }

      if (msg && msg.length < 1000) {
        axios
          .post(
            `${serverURL}/send-message`,
            {
              message: msg,
              toUserID: selectedChatUserID,
              ds: dateStringer.createStringFromTimestamp(new Date().getTime()),
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setMsgSent(!msgSent);
              let newArr = thread;
              newArr.push({
                ts: response.data.ts,
                ds: response.data.ds,
                message: msg,
                out: true,
                in: false,
              });
              setMsg("");
              setThread(newArr);
              // scrollToBottom();
              let newChatHeads = [];
              chatHeads.forEach((ch) => {
                if (ch.userID === selectedChatUserID) {
                  let tsOfFirstChatHead = chatHeads[0].ts;
                  tsOfFirstChatHead++;
                  ch.ts = tsOfFirstChatHead;
                  ch.preview = msg;
                }
                newChatHeads.push(ch);
              });

              newChatHeads.sort((a, b) => {
                return b.ts - a.ts;
              });

              if (typeof memoVals.showMessages === "string") {
                let arr = [];
                newChatHeads.forEach((c) => {
                  if (c.userID === memoVals.showMessages) {
                    c.ts = 999999999999900;
                  }

                  arr.push(c);
                });
                setChatHeads(arr);
              } else {
                setChatHeads(newChatHeads);
              }

              scrollToBottom();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  let shortenMobPreview = (val) => {
    if (val.length > 32) {
      return `${val.substr(0, 28)}...`;
    } else {
      return val;
    }
  };

  useEffect(() => {
    // clean not needed
    setLoadingThread(true);
    setLoadingThread(false);
  }, [thread]);
  let generateMobChatPreviews = chatHeads.map((ch) => {
    // if (ch.preview) {
    return (
      <div
        className={`mobChatPreviewUnit ${
          unreadUserIDs.includes(ch.userID) ? "unreadMobChatUnit" : ""
        }`}
        id={selectedChatUserID === ch.userID ? "x111" : ""}
        // ref={selectedChatUserID === ch.userID ? targetHead : null}
        onClick={() => {
          setThread([]);
          if (selectedChatUserID === ch.userID) {
            setSelectedChatUserID("");
            setSelectedFName("");
            setSelectedLName("");

            setSelectedProfPic("");
          } else {
            setSelectedChatUserID(ch.userID);
            memoVals.setShowMessages((x) => ch.userID);

            setSelectedFName(ch.fName);
            setSelectedLName(ch.lName);

            setSelectedProfPic(ch.profPicUrl);
          }
          setScrollToMobChatHead(!scrollToMobChatHead);
        }}
      >
        <div className="mobChatPreviewUnitLeft">
          <img
            src={ch.profPicUrl || profilePicturePlaceholder}
            className="mobChatPreviewProfilePicImg"
            alt={ch.fName}
          />
        </div>
        <div className="mobChatPreviewUnitRight">
          <p
            className={`mobChatPreviewUnitRightName ${
              unreadUserIDs.includes(ch.userID) ? "unreadMobChatNameStrip" : ""
            }`}
          >
            {ch.fName} {ch.lName}
          </p>
          {/* {ch.preview ? ( */}
          <p
            className={`mobChatPreviewUnitRightPreview ${
              !ch.preview ? "noMsgsYet" : ""
            }`}
          >
            {shortenMobPreview(ch.preview || "No messages yet")}
          </p>
          {/* ) : (
            ""
          )} */}
        </div>
      </div>
    );
    // } else {
    //   return "";
    // }
  });

  window.addEventListener("resize", () => {
    if (chatBox.current) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
    }
  });

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      if (selectedChatUserID) {
        setSelectedChatUserID("");
      } else {
        countUnread();
        memoVals.setShowMessages((x) => false);
      }
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [memoVals.showMessages, selectedChatUserID]);

  let lastActive = (ds) => {
    let today = dateStringer.createStringFromTimestamp(new Date().getTime());
    let todayAtMidnightDs = `${today.substr(0, 11)}H00M00`;
    let dayCount = Math.round(
      (dateStringer.createTimestampFromString(todayAtMidnightDs) -
        dateStringer.createTimestampFromString(ds)) /
        86400000
    );
    if (dayCount > 365) {
      return "+1 year ago";
    } else if (
      ds &&
      dateStringer.createTimestampFromString(ds) <
        dateStringer.createTimestampFromString(todayAtMidnightDs)
    ) {
      return `${dateStringer.printedDateFromDs(ds)}`;
    } else {
      return dateStringer.dsToTimeStrip(ds);
    }
  };

  let generateMobThread = thread.map((chat) => {
    let timestrip = "";
    let todayDs = dateStringer.createStringFromTimestamp(new Date().getTime());
    if (chat.ds.substr(0, 11) === todayDs.substr(0, 11)) {
      timestrip = dateStringer.dsToTimeStrip(chat.ds);
    } else {
      timestrip = dateStringer.printedDateFromDs(chat.ds);
    }
    return (
      <div className={`chatItemParent ${loadingThread ? "invis" : ""}`}>
        <div
          className={`chatItem ${chat.in ? "flexLeft" : "flexRight"}`}
          key={chat.messageID}
          onClick={() => {}}
        >
          {chat.in ? (
            <img
              src={selectedProfPic}
              className="threadProPic"
              alt={selectedFName}
            />
          ) : (
            ""
          )}
          <p className={`${chat.in ? "chatTxtIn" : "chatTxtOut"}`}>
            {chat.message}
          </p>
        </div>
        {chat.in ? <p className="chatDs">{timestrip}</p> : ""}
        {chat.out ? <p className="chatDsRight">{timestrip}</p> : ""}
      </div>
    );
  });

  useEffect(() => {
    if (loadAllLoaded) {
      setLoadAllLoaded(false);
    }
  }, [selectedChatUserID]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 140) {
      countUnread();
      memoVals.setShowMessages((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  let [dontScrollToBottom, setDontScrollToBottom] = useState(false);
  useEffect(() => {
    if (!dontScrollToBottom) {
      scrollToBottom();
    }
  }, [thread]);

  useEffect(() => {
    setDontScrollToBottom(false);
  }, [selectedChatUserID]);

  // useEffect(() => {
  //   setDontScrollToBottom(false);
  // }, [selectedChatUserID]);

  // master return
  if (memoVals.mob) {
    return (
      <div
        className="mobMessagesUnderlay"
        onClick={() => {
          countUnread();
          memoVals.setShowMessages((x) => false);
        }}
      >
        {loading ? (
          <div
            className={`shiftLoadingBox ${
              memoVals.mobModal
                ? "shiftLoadingBoxMob"
                : "shiftLoadingBoxDesktop"
            }`}
          >
            <img
              src={horizontalBalls}
              alt="Loading"
              className={`shiftLoadingBallsImg ${
                memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
              }`}
            />
          </div>
        ) : (
          <div
            className={`mobMessagesModalBox ${
              memoVals.mobModal || memoVals.mob ? "mobModalShoulder" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              // countUnread();
            }}
            style={modalPosition}
            ref={modalRef}
          >
            {memoVals.mobModal && (
              <div className="modalSwiper modalSwiperMessages"></div>
            )}
            <div
              className="mobMessagesHeader"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
              onClick={() => {
                if (memoVals.mobModal) {
                  countUnread();
                  memoVals.setShowMessages((x) => false);
                }
              }}
            >
              <img
                src={close}
                alt="Close"
                className="downMsgModalImg"
                onClick={() => {
                  countUnread();
                  memoVals.setShowMessages((x) => false);
                }}
              />
              <p
                className="mobMessagesTitle"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Messages{" "}
                <img
                  src={notifBell}
                  alt="Notification settings"
                  className="msgNotifPushBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPushSettings(true);
                    console.log("showing push settings");
                  }}
                />
              </p>
            </div>

            {/* <div className="mobChatHeadsDiv" ref={mobChatHeads}>
              {generateMobileChatHeads}
            </div> */}
            <div className="mobChatBox">
              {selectedChatUserID ? (
                <div className="mobChatThreadHeader">
                  <div className="mobChatHeaderThreadLeft">
                    <div
                      className="msgBackBtnDiv"
                      onClick={() => {
                        setSelectedChatUserID("");
                        memoVals.setShowMessages((x) => true);
                      }}
                    >
                      <img
                        src={whiteGoBackTransparent}
                        className="whiteGoBackMsgsImg"
                      />
                      Back
                    </div>
                    <p
                      className="mobChatThreadNameTitle"
                      onClick={() => {
                        setSelectedChatUserID("");
                      }}
                    >
                      {selectedFName} {selectedLName ? selectedLName[0] : ""}
                    </p>
                  </div>
                  <div className="mobChatHeaderThreadRight">
                    {lastActiveDs ? (
                      <p className="lastActiveTxtMob">
                        Last active
                        <br />
                        {lastActive(lastActiveDs)}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {selectedChatUserID ? (
                <div
                  className="mobChatThread"
                  ref={chatBox}
                  onScroll={() => {
                    setDontScrollToBottom(true);
                  }}
                >
                  {thread.length === 100 && !hideLoadAll ? (
                    <div
                      className="loadAllChatBtn"
                      onClick={() => {
                        // scrollToBottom();
                        setLoadAllLoaded(true);
                        axios
                          .post(
                            `${serverURL}/get-message-thread`,
                            {
                              otherUserID: selectedChatUserID,
                              messagesBeforeTs: false,
                              loadAll: true,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setLastActiveDs(response.data.lastActive);

                              setHideLoadAll(true);

                              setThread(response.data.messages);

                              let newChatHeads = [];
                              chatHeads.forEach((ch) => {
                                if (ch.userID === selectedChatUserID) {
                                  ch.unread = false;
                                }
                                newChatHeads.push(ch);
                              });

                              if (typeof memoVals.showMessages === "string") {
                                let arr = [];
                                newChatHeads.forEach((c) => {
                                  if (c.userID === memoVals.showMessages) {
                                    c.ts = 999999999999900;
                                  }

                                  arr.push(c);
                                });
                                setChatHeads(arr);
                                console.log("x1", { arr });
                              } else {
                                setChatHeads(newChatHeads);
                              }

                              setLoadingThread(false);
                              setLoading(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      Load all messages
                    </div>
                  ) : (
                    ""
                  )}
                  {generateMobThread}
                </div>
              ) : (
                <div
                  className={`mobChatPreviews ${
                    scrolled ? "msgBorderTopScrolled" : ""
                  }`}
                  ref={chatHeadScroller}
                  onScroll={() => {
                    if (chatHeadScroller.current.scrollTop === 0) {
                      setScrolled(false);
                    } else {
                      setScrolled(true);
                    }
                  }}
                >
                  {generateMobChatPreviews}
                </div>
              )}
              {selectedChatUserID ? (
                ""
              ) : (
                // <div className="mobChatMsgFooter">footer</div>
                <div
                  className={`messagesModalFooterDesktop ${
                    memoVals.mob ? "mobMsgFooter" : ""
                  }`}
                >
                  <p></p>
                  <p
                    className="clockMessagesModalBtn"
                    onClick={() => {
                      countUnread();

                      memoVals.setShowMessages((x) => false);
                    }}
                  >
                    Close
                  </p>
                </div>
              )}
              {selectedChatUserID ? (
                <div className="mobChatInputBox">
                  <input
                    type="text"
                    onChange={(e) => {
                      setMsg(e.target.value);
                    }}
                    value={msg}
                    className="mobChatTxtInput"
                    placeholder={`Say something to ${selectedFName}`}
                    id="msgInputField"
                    onKeyPress={(e) => {
                      enterFct(e);
                    }}
                  ></input>

                  <p
                    className="mobSendChatBtn"
                    onClick={() => {
                      enterFct("send");
                    }}
                  >
                    {/* Send */}
                    <img src={send} alt="Send" className="sendMsgArrImg" />
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {imgPreload}
        {showPushSettings ? (
          <div
            className="clockOnModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setShowPushSettings(false);
            }}
          >
            <div
              className={`formCreatedModal paddingBottom0`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Do you want to receive alerts (push notifications) to your
                mobile device when you receive a new message?
                {!pushWide ? <br /> : ""}
                {!pushWide ? <br /> : ""}{" "}
                {!pushWide ? (
                  <span className="pushNoteSpan">
                    Push notifications in the app are currently disabled.
                    Message alerts require push notifications to be enabled in{" "}
                    <NavLink
                      to="/notifications"
                      onClick={() => {
                        memoVals.setShowMessages((x) => false);
                      }}
                    >
                      Settings
                    </NavLink>
                    .
                  </span>
                ) : (
                  ""
                )}
              </p>

              <div className="areYouModalBtnsDiv">
                <div
                  className={`myRotaAllowSwapsBtn sendPushNotifBtnMgs ${
                    push ? "allowSwapsMyRotaBtnActive" : ""
                  } allowSwapsBtnTxtModal ${!pushWide ? "none" : ""}`}
                  onClick={() => {
                    // setPush(!push);
                    axios
                      .post(
                        `${serverURL}/edit-notifications`,
                        {
                          notifName: "messages",
                          enable: !push,
                          pushOrEmail: "push",
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setPush(!push);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  {" "}
                  Send push notifications
                  {/* KNOB BEGIN */}
                  <div className="editDayFxSchedKnob">
                    <div className="knobContainer allowSwapsKnobMyRota">
                      <div
                        className={`switchKnob ${
                          push ? "knobOn allowSwapsKnobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg allowSwapsKnobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
              </div>
              <div className={`messagesModalFooterDesktop xb192381`}>
                <p></p>
                <p
                  className="clockMessagesModalBtn"
                  onClick={() => {
                    setShowPushSettings(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } else
    return (
      <div
        className="messagesModalUnderlay"
        onClick={() => {
          countUnread();
          memoVals.setShowMessages((x) => false);
        }}
      >
        {loading ? (
          <div
            className={`shiftLoadingBox ${
              memoVals.mobModal
                ? "shiftLoadingBoxMob"
                : "shiftLoadingBoxDesktop"
            }`}
          >
            <img
              src={horizontalBalls}
              alt="Loading"
              className={`shiftLoadingBallsImg ${
                memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
              }`}
            />
          </div>
        ) : (
          <div
            className={`messagesModalBox whiteBg ${
              memoVals.mobModal ? "mobMessagesModalBox slideUp" : "zoomIn"
            } `}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="clockOnModalHeader">
              <div className="clockOnModalHeaderSideUnit">
                <img
                  src={close}
                  alt="Close"
                  className="closeClockOnModalImg"
                  onClick={() => {
                    countUnread();

                    memoVals.setShowMessages((x) => false);
                  }}
                />
              </div>
              <p
                className="clockOnModalHeaderTitle"
                onClick={() => {
                  console.log({ chatHeads });
                }}
              >
                Messages
              </p>
              <div className="clockOnModalHeaderSideUnit"></div>
            </div>
            <div className="messagesModalBodyDesktop">
              <div className="messagesLeft" ref={messagesLeft}>
                <div className="searchUserMessages">
                  <img src={magni} alt="Search" className="messagesMagniImg" />
                  <input
                    type="text"
                    className="messagesSearchInput"
                    placeholder="Search staff"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  ></input>
                </div>
                {generateChatHeads}
              </div>
              <div className="messagesRight">
                <div
                  className="chatBoxScrollBox"
                  ref={chatBox}
                  onScroll={() => {
                    setDontScrollToBottom(true);
                  }}
                >
                  <div className="chatBox">
                    {thread.length === 100 && !hideLoadAll ? (
                      <div
                        className="loadAllChatBtn"
                        onClick={() => {
                          // scrollToBottom();

                          axios
                            .post(
                              `${serverURL}/get-message-thread`,
                              {
                                otherUserID: selectedChatUserID,
                                messagesBeforeTs: false,
                                loadAll: true,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setLastActiveDs(response.data.lastActive);

                                setHideLoadAll(true);

                                setThread(
                                  response.data.messages.sort((a, b) => {
                                    return a.ts - b.ts;
                                  })
                                );

                                let newChatHeads = [];
                                chatHeads.forEach((ch) => {
                                  if (ch.userID === selectedChatUserID) {
                                    ch.unread = false;
                                  }
                                  newChatHeads.push(ch);
                                });

                                if (typeof memoVals.showMessages === "string") {
                                  let arr = [];
                                  newChatHeads.forEach((c) => {
                                    if (c.userID === memoVals.showMessages) {
                                      c.ts = 999999999999900;
                                    }

                                    arr.push(c);
                                  });
                                  setChatHeads(arr);
                                  console.log("x2", { arr });
                                } else {
                                  setChatHeads(newChatHeads);
                                }
                                setLoadingThread(false);
                                setLoading(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Load all messages
                      </div>
                    ) : (
                      ""
                    )}
                    {generateThread}
                  </div>
                </div>
                {selectedChatUserID ? (
                  <div className="chatInputBar">
                    <input
                      type="text"
                      className="chatInput"
                      placeholder={`Say something to ${selectedFName}`}
                      ref={chatInput}
                      value={msg}
                      onChange={(e) => {
                        setMsg(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        enterFct(e);
                      }}
                    ></input>
                    <p
                      className={`chatSend ${!msg ? "disableSendBtn" : ""}`}
                      onClick={() => {
                        enterFct("send");
                      }}
                    >
                      <img src={send} alt="Send" className="sendMsgArrImg" />
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {lastActiveDs ? (
                  <p className="lastActiveTxtDesktop">
                    Last active {lastActive(lastActiveDs)}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`messagesModalFooterDesktop ${
                memoVals.mob ? "mobMsgFooter" : ""
              }`}
            >
              <p></p>
              <p
                className="clockMessagesModalBtn"
                onClick={() => {
                  countUnread();

                  memoVals.setShowMessages((x) => false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        )}
        <img
          src={whiteGoBackTransparent}
          className="whiteGoBackMsgsImg invisImg"
        />

        {imgPreload}
      </div>
    );
};

export default MessagesModal;
