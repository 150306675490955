const checkDayExists = (day, month) => {
  if (month == 2 && day == 29) {
    return false;
  } else if (month == 2 && day == 30) {
    return false;
  } else if (month == 2 && day == 31) {
    return false;
  } else if (month == 4 && day == 31) {
    return false;
  } else if (month == 4 && day == 31) {
    return false;
  } else if (month == 6 && day == 31) {
    return false;
  } else if (month == 9 && day == 31) {
    return false;
  } else if (month == 11 && day == 31) {
    return false;
  } else {
    return true;
  }
};

export default checkDayExists;
