import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import serverURL from "../../serverURL";
import dateStringer from "../../tools/dateStringer";
import towerLogo from "../tower/img/towerLogo.png";
import { TowerContext } from "../../contexts/TowerContext";

import powerImg from "../../img/general/power2.svg";
import { get } from "jquery";

const TowerSettings = () => {
  const { fName, setFName, page, setPage } = useContext(TowerContext);

  let [selectedBatch, setSelectedBatch] = useState("none");
  let [midnightBatchActive, setMidnightBatchActive] = useState(false);
  let [midnightBatchLastStart, setMidnightBatchLastStart] = useState(1);
  let [midnightBatchLastFinished, setMidnightBatchLastFinished] = useState(1);

  let [fiveMinsBatchActive, setFiveminsBatchActive] = useState(false);
  let [fiveMinsBatchLastRan, setFiveMinsBatchLastRan] = useState(1);

  let [jam, setJam] = useState(false);
  let [lastJamTs, setLastJamTs] = useState(1);
  let [lastUnjamTs, setLastUnjamTs] = useState(1);

  let [throttling, setThrottling] = useState(false);

  let [monitor, setMonitor] = useState(false);
  let [midnightRunning, setMidnightRunning] = useState(false);
  useEffect(() => {
    console.log("Getting tower user info");
    console.log(monitor, "monitor2");

    if (monitor) {
      let getData = setInterval(() => {
        console.log(monitor, "monitor");
        axios
          .post(
            `${serverURL}/get-batch-data-live`,
            { someData: 1 },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setJam(response.data.jam);
              setMidnightBatchActive(response.data.midnightBatchActive);
              setMidnightBatchLastStart(
                response.data.midnightBatchLastStart || 1
              );
              setMidnightBatchLastFinished(
                response.data.midnightBatchLastFinished || 1
              );

              if (
                response.data.midnightBatchLastFinished >
                response.data.midnightBatchLastStart
              ) {
                setMidnightRunning(false);
              } else {
                setMidnightRunning(true);
              }
              setLastJamTs(response.data.lastJamTs);
              setLastUnjamTs(response.data.lastUnjamTs);
            }
          });

        axios
          .post(
            `${serverURL}/check-if-mongo-is-throttling`,
            { someData: 1 },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((resp) => {
            if (resp.data.message === "throttling") {
              setThrottling(true);
            } else {
              setThrottling(false);
            }
          });
      }, 1000);
      return () => {
        clearInterval(getData);
      };
    }
  }, [monitor]);

  return (
    <div className="towerBodyInnerContainer">
      <div className="towerSettingsBatchContainer">
        <div
          className={`powerMonitorButton ${monitor ? "activePowerBtnImg" : ""}`}
          onClick={() => {
            setThrottling(false);
            setMonitor(!monitor);
            setSelectedBatch("");
          }}
        >
          <img src={powerImg} alt="Power" className="towerPowerBtnImg" />
          <p className="powerBtnTxt">MONITOR</p>
        </div>

        <p className={`${throttling && monitor ? "throttlingIcon" : "none"}`}>
          MONGO IS THROTTLING
        </p>
        {/*  BATCH UNIT START */}

        <div
          className={`batchUnit jamBatchUnit ${jam ? "jamBlueBg" : ""} ${
            !monitor ? "none" : ""
          }`}
          onClick={() => {}}
        >
          <div className="batchUnitRow1">
            <p className="batchName">
              <span
                className={`${
                  jam && monitor ? "jamActiveLight" : "batchRunningLightOff"
                }`}
              >
                &nbsp;
              </span>
              Jam system
            </p>
            {/*  KNOB BEGIN */}
            <div
              className="editUserReqClockOutKnobContainer clockInKnob"
              onClick={(e) => {
                e.stopPropagation();
                // fire request here
                axios.post(
                  `${serverURL}/jam-system`,
                  { data: !jam },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                );
              }}
            >
              <div className="knobContainer batchKnob">
                <div
                  className={`switchKnob ${
                    jam && monitor ? "knobOn" : "knobOff"
                  }`}
                ></div>
                <div className="knobBg"></div>
              </div>
            </div>
            {/*  END OF KNOB */}
          </div>
          <div className="batchUnitRow2">
            <p className="lastRanTxt">Since</p>
            <p className="lastRanValue">
              {" "}
              {jam
                ? dateStringer.printedDateWithTimeFromDs(
                    dateStringer.createStringFromTimestamp(lastJamTs)
                  )
                : dateStringer.printedDateWithTimeFromDs(
                    dateStringer.createStringFromTimestamp(lastUnjamTs)
                  )}
            </p>
          </div>
        </div>
        {/* BATCH UNIT END */}
        <div className={`${monitor ? "jamDivider" : "none"}`}></div>
        {/*  BATCH UNIT START */}

        <div
          className={`batchUnit ${
            selectedBatch === "midnight"
              ? `selectedBatchUnit ${
                  midnightBatchLastStart > midnightBatchLastFinished
                    ? "jamBlueBg"
                    : ""
                }`
              : ""
          } ${!monitor ? "none" : ""}`}
          onClick={() => {
            if (selectedBatch === "midnight") {
              setSelectedBatch("none");
            } else {
              setSelectedBatch("midnight");
            }
          }}
        >
          <div className="batchUnitRow1">
            <p className="batchName">
              <span
                className={`${
                  midnightBatchActive
                    ? `${
                        midnightBatchLastStart > midnightBatchLastFinished &&
                        monitor
                          ? "batchRunningLight"
                          : "batchRunningLightOff idleBatchLight"
                      }`
                    : "batchRunningLightOff"
                }`}
              >
                &nbsp;
              </span>
              Midnight batch
            </p>
            {/*  KNOB BEGIN */}
            <div
              className="editUserReqClockOutKnobContainer clockInKnob"
              onClick={(e) => {
                e.stopPropagation();
                // fire request here
              }}
            >
              <div className="knobContainer batchKnob">
                <div
                  className={`switchKnob ${
                    midnightBatchActive ? "knobOn" : "knobOff"
                  }`}
                ></div>
                <div className="knobBg"></div>
              </div>
            </div>
            {/*  END OF KNOB */}
          </div>
          <div className="batchUnitRow2">
            <p className="lastRanTxt">Last ran</p>
            <p className="lastRanValue">
              {dateStringer.printedDateWithTimeFromDs(
                dateStringer.createStringFromTimestamp(midnightBatchLastStart)
              )}
            </p>
          </div>
        </div>
        {/* BATCH UNIT END */}
        {/*  BATCH UNIT START */}

        <div
          className={`batchUnit ${
            selectedBatch === "fivemins" ? "selectedBatchUnit" : ""
          } ${!monitor ? "none" : ""}`}
          onClick={() => {
            if (selectedBatch === "fivemins") {
              setSelectedBatch("none");
            } else {
              setSelectedBatch("fivemins");
            }
          }}
        >
          <div className="batchUnitRow1">
            <p className="batchName">
              <span
                className={`${
                  fiveMinsBatchActive
                    ? "batchRunningLight"
                    : "batchRunningLightOff"
                }`}
              >
                &nbsp;
              </span>
              Five mins batch
            </p>
            {/*  KNOB BEGIN */}
            <div
              className="editUserReqClockOutKnobContainer clockInKnob"
              onClick={(e) => {
                e.stopPropagation();
                // fire request here
                axios.post(
                  `${serverURL}/run-batch-now`,
                  { batch: "fiveMins" },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                );
              }}
            >
              <div className="knobContainer batchKnob">
                <div
                  className={`switchKnob ${
                    fiveMinsBatchActive ? "knobOn" : "knobOff"
                  }`}
                ></div>
                <div className="knobBg"></div>
              </div>
            </div>
            {/*  END OF KNOB */}
          </div>
          <div className="batchUnitRow2">
            <p className="lastRanTxt">Last ran</p>
            <p className="lastRanValue">
              {" "}
              {dateStringer.printedDateWithTimeFromDs(
                dateStringer.createStringFromTimestamp(fiveMinsBatchLastRan)
              )}
            </p>
          </div>
        </div>
        {/* BATCH UNIT END */}
      </div>
      <div className="batchDropdownContainer">
        <div
          className={`batchUnitDropdowner ${
            selectedBatch !== "none" ? "" : "none"
          } ${monitor && selectedBatch ? "" : "none"}`}
        >
          <div className="batchUnitDropUnit1">
            <p
              className={`runBatchBtn ${
                midnightRunning ? "fadeRunMidnightBtn" : ""
              }`}
              onClick={() => {
                console.log(selectedBatch);

                if (selectedBatch === "midnight") {
                  setMidnightRunning(true);
                }

                let batchString = "";
                if (selectedBatch === "fivemins") {
                  batchString = "fiveMins";
                }
                if (selectedBatch === "midnight") {
                  batchString = "midnight";
                }

                axios.post(
                  `${serverURL}/run-batch-now`,
                  { batch: batchString },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                );
              }}
            >
              Run {selectedBatch} now
            </p>
          </div>
          <div className="batchUnitDropDownUnitSplitter"></div>
        </div>
      </div>
      <div className="batchSecondContainer">Hello there</div>
    </div>
  );
};

export default TowerSettings;
