import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared up 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import serverURL from "../../../serverURL";

import dateStringer from "../../../tools/dateStringer";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import clockcard from "../../../img/general/clockcard.svg";
import teamIcon from "../../../img/general/teamIcon2.svg";

import "../../../styles/calendar.css";

import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";

const ClockOns = ({ sideBoxData, my, setMy }) => {
  const { setCalPage } = useContext(CalendarContext);
  const { setPage, availableMonths, setClockOnID, deletedClockOnID, device } =
    useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setCalPage, //
      setPage, //
      availableMonths, //
      setClockOnID, //
      deletedClockOnID, //
      device, //
      mob, //
      full, //
      fullButNarrow, //
    }),
    [
      setCalPage, //
      setPage, //
      availableMonths, //
      setClockOnID, //
      deletedClockOnID, //
      device, //
      mob, //
      full, //
      fullButNarrow, //
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}

      {/* Additional images */}
      <img
        src={clockcard}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 2) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [reverse, setReverse] = useState(false);
  let [clockOns, setClockOns] = useState([]);

  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);

  useEffect(() => {
    // clean not needed
    let today = new Date();
    let monthNum = today.getMonth();
    if (monthNum < 10) {
      monthNum = `0${monthNum}`;
    } else {
    }
    let yearNum = JSON.stringify(today.getFullYear()).substr(2, 2);

    setMy(`${monthNum}${yearNum}`);
  }, []);
  document.title = "FlowRota - My clock cards";

  let [pendingsExist, setPendingExists] = useState(false);
  let [approvedExist, setApprovedExist] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (my) {
      axios
        .post(
          `${serverURL}/get-user-clock-ons`,
          { my: my },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // setReverse(response.data.reverse);
            let pending = [];
            let nonPending = [];

            response.data.clockOns.forEach((c) => {
              if (c.approved || c.declined) {
                nonPending.push(c);
              } else {
                pending.push(c);
              }
            });

            if (pending[0]) {
              pending.push({ split: true });
              setPendingExists(true);
            } else {
              setPendingExists(false);
            }

            setClockOns([...pending, ...nonPending]);

            if (nonPending[0]) {
              setApprovedExist(true);
            } else {
              setApprovedExist(false);
            }

            console.log({ clockOns: [...pending, ...nonPending] });

            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [my]);

  // clockOns.sort(function (a, b) {
  //   //   if (!reverse) {
  //   return b.order - a.order;
  //   //   } else {
  //   //     return a.order - b.order;
  //   //   }
  // });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!loading) {
      let count = 0;
      clockOns.forEach((item) => {
        if (item) {
          count++;
        }
      });
      if (!count) {
        axios
          .post(
            `${serverURL}/get-user-clock-ons`,
            { my: my },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setClockOns(response.data.clockOns);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [loading]);

  useEffect(() => {
    // clean not needed
    let newArr = [];
    clockOns.forEach((c) => {
      if (c.clockOnID !== memoVals.deletedClockOnID && !c.split) {
        newArr.push(c);
      }
    });

    let pending = [];
    let nonPending = [];

    newArr.forEach((c) => {
      if (c.approved || c.declined) {
        nonPending.push(c);
      } else {
        pending.push(c);
      }
    });

    if (pending[0]) {
      pending.push({ split: true });
      setPendingExists(true);
    } else {
      setPendingExists(false);
    }

    setClockOns([...pending, ...nonPending]);

    // console.log("NEW CLOCKS", [...pending, ...nonPending]);
  }, [memoVals.deletedClockOnID]);

  let generateMonthOptions = useMemo(() => {
    return memoVals.availableMonths.map((month) => {
      if (dateStringer.getTsFromMy(month) <= new Date().getTime()) {
        let formatMonth = "Jan";
        let formatYear = month.substr(2, 2);
        if (month.substr(0, 2) === "00") {
          formatMonth = "January";
        }
        if (month.substr(0, 2) === "01") {
          formatMonth = "February";
        }
        if (month.substr(0, 2) === "02") {
          formatMonth = "March";
        }
        if (month.substr(0, 2) === "03") {
          formatMonth = "April";
        }
        if (month.substr(0, 2) === "04") {
          formatMonth = "May";
        }
        if (month.substr(0, 2) === "05") {
          formatMonth = "June";
        }
        if (month.substr(0, 2) === "06") {
          formatMonth = "July";
        }
        if (month.substr(0, 2) === "07") {
          formatMonth = "August";
        }
        if (month.substr(0, 2) === "08") {
          formatMonth = "September";
        }
        if (month.substr(0, 2) === "09") {
          formatMonth = "October";
        }
        if (month.substr(0, 2) === "10") {
          formatMonth = "November";
        }
        if (month.substr(0, 2) === "11") {
          formatMonth = "December";
        }

        return (
          <option key={month} value={month}>
            {formatMonth} {formatYear}
          </option>
        );
      }
    });
  }, [memoVals.availableMonths]);

  let clockScrollBox = useRef();

  useEffect(() => {
    // clean not needed
    clockScrollBox.current.scrollTop = 0;
  }, [my]);

  useEffect(() => {
    // clean not needed
    document.title = "My clock cards - FlowRota";

    memoVals.setCalPage((x) => "shifts");
    memoVals.setPage((x) => "clock-ons");
  }, []);

  let [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    // clean not needed
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.clockOnID) {
      memoVals.setClockOnID((x) => {
        return { clockOnID: params.clockOnID, isMgr: false };
      });
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let generateClockOns = useMemo(() => {
    return clockOns.map((clock) => {
      if (clock.split && approvedExist) {
        return <div className="pendingTsSplitter"></div>;
      } else {
        if (clock.startDs && clock.endDs) {
          let calcClockedMins = (ds1, ds2, brkMins) => {
            if (ds1 && ds2) {
              let ds1Ts = dateStringer.createTimestampFromString(ds1);
              let ds2Ts = dateStringer.createTimestampFromString(ds2);

              let msDiff = ds2Ts - ds1Ts;

              let brkMs = brkMins * 60 * 1000;
              let totMs = msDiff - brkMs;

              return totMs / 1000;
            }
          };

          if (
            (showShifts && clock.type === "shift") ||
            (showTils && clock.type === "til") ||
            (showOvertimes && clock.type === "overtime")
          )
            return (
              <div
                className={`clockUnit ${mob ? "borderRadius10" : ""} ${
                  clock.approved
                    ? "leftBorderGreen"
                    : `${
                        clock.declined
                          ? "leftBorderRed"
                          : `mgrReqItemPendingBorder pendClock ${
                              memoVals.mob ? "mgrReqItemPendingBorderMob" : ""
                            }`
                      }`
                } ${
                  clock.oneOfTwo === "one"
                    ? `oneOfTwoOneClock ${
                        clock.approved || clock.declined ? "x937179343" : ""
                      }`
                    : ""
                } ${clock.oneOfTwo === "two" ? "oneOfTwoTwoClock" : ""}`}
                key={clock.clockOnID}
                onClick={() => {
                  memoVals.setClockOnID((x) => {
                    return { clockOnID: clock.clockOnID, isMgr: false };
                  });
                }}
              >
                <div className="clockSectionLeftWrapper">
                  <img
                    src={clockcard}
                    alt="Clock card"
                    className="clockCardIcon"
                  />
                  <div
                    className={`reqColBarDiv reqColBarAdjust4 ${
                      clock.approved ? "" : "divPend"
                    } ${clock.declined ? "divDec" : ""}`}
                  ></div>
                  <div
                    className={`tilShiftSection1Clock ${
                      clock.declined
                        ? "declinedClockBar"
                        : clock.approved
                        ? ""
                        : "pendingClockBar"
                    }`}
                  >
                    <p className={`tilShiftDs `}>
                      <p className="tilShiftDateAndNth">
                        {dateStringer.tsToDayAndDate(
                          dateStringer.createTimestampFromString(clock.startDs),
                          memoVals.mob || memoVals.fullButNarrow ? true : true
                        )}
                        <span
                          className="ndStRdSpan"
                          onMouseEnter={() => {
                            console.log(
                              clock.startDs.substr(9, 2),
                              dateStringer.nth(
                                parseInt(clock.startDs.substr(9, 2))
                              )
                            );
                          }}
                        >
                          {dateStringer.nth(
                            parseInt(clock.startDs.substr(9, 2))
                          )}
                        </span>
                      </p>
                      <span className="shiftTilNameTxt">
                        {clock.type === "shift"
                          ? "Shift"
                          : `${
                              clock.type === "overtime"
                                ? memoVals.mob || memoVals.fullButNarrow
                                  ? "Over"
                                  : "Overtime"
                                : "Lieu"
                            }`}
                      </span>
                    </p>
                    <div className="timesAndTagContainer">
                      <div
                        className={`clockOnOffTimesFlex ${
                          clock.approved ? "" : "x3832874827"
                        }`}
                      >
                        <div className="clockOnOffSection">
                          <p className="clockOnSpan">On: </p>
                          <p className="clockOnValTxt">{`${clock.startDs.substr(
                            12,
                            2
                          )}:${clock.startDs.substr(15, 2)}`}</p>
                        </div>
                        <div className="clockOnOffSection clockOnOffSection2">
                          <p className="clockOnSpan">Off: </p>
                          <p className="clockOnValTxt">{`${clock.endDs.substr(
                            12,
                            2
                          )}:${clock.endDs.substr(15, 2)}`}</p>
                        </div>
                        <div className="clockOnOffSection clockOnOffSection3">
                          <p className="clockOnSpan">Break: </p>
                          <p className="clockOnValTxt x274831">
                            {clock.totBrkMins === 60 ||
                            clock.totBrkMins === 120 ||
                            clock.totBrkMins === 180 ||
                            clock.totBrkMins === 240
                              ? dateStringer.formatMinsDurationToHours(
                                  clock.totBrkMins
                                )
                              : `${clock.totBrkMins}m`}
                          </p>
                        </div>
                        {/* &nbsp;&nbsp;&nbsp;
                      <span className="clockOnSpan">Off: </span>
                      {clock.endDs.substr(12, 2)}:{clock.endDs.substr(15, 2)} */}
                      </div>
                    </div>
                  </div>{" "}
                </div>

                <div
                  className={`clockSection2 ${
                    !memoVals.mob ? "desktopClockSection2" : ""
                  }`}
                >
                  <p className="tilShiftTypeTxt">
                    {dateStringer.formatMinsDurationToHours(
                      calcClockedMins(
                        clock.startDs,
                        clock.endDs,
                        clock.totBrkMins
                      ) / 60
                    )}
                  </p>
                  <p
                    className={`absenceUnitDurationString teamNameColour marginTop0`}
                  >
                    {dateStringer.shorten(
                      clock.teamName,
                      memoVals.mob || memoVals.fullButNarrow ? 22 : 22
                    )}{" "}
                    <img src={teamIcon} alt="Team" className="teamIconHours" />{" "}
                    {/* {clock.declined || clock.approved ? "" : "break"} */}
                  </p>
                </div>
                <div
                  className={`itemTongue clockTongue ${
                    !clock.approved && !clock.declined ? "x019481" : ""
                  }`}
                >
                  <p className="tongueTxt clockTongueTxt">Clock card</p>
                </div>
              </div>
            );
        }
      }
    });
  }, [clockOns, reverse, memoVals.mob, memoVals.fullButNarrow]);

  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      <ShiftsHeader my={my} setMy={setMy} />

      <div className={`desktopMaster ${memoVals.mob ? "desktopWhiteBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
        >
          <div
            className={`shiftsPageFilterBar ${
              memoVals.mob ? "userHoursShoulderBar2" : ""
            } ${!memoVals.mob && "borderLeftGrey"} noBorderBottom ${
              memoVals.full ? "" : "noBorderRight"
            }`}
          >
            <div className="shiftsPageFilterBarLeft">
              <select
                className="shiftsTilMonthDropdown"
                value={my}
                onChange={(e) => {
                  setDataLoaded(false);
                  setMy(e.target.value);
                }}
              >
                {generateMonthOptions}
              </select>
            </div>{" "}
            <div className="shiftsPageFilterBarRight">
              {/* <div
                className={`${
                  showFilter ? "shiftTilFilterBtnActive" : "shiftTilFilterBtn"
                }`}
                onClick={() => {
                  setShowFilter(!showFilter);
                  console.log(clockOns);
                }}
              >
                Filter{" "}
                <img
                  className={`${
                    showFilter ? "closeFilterImgTilShifts" : "none"
                  }`}
                  alt="close"
                  src={whitecross}
                />
              </div> */}
            </div>
          </div>

          {/* <div className="shiftTilFilterHolder">
            <div
              className={`${
                showFilter ? "shfitTilFilterBtnsContainer" : "none"
              }`}
            >
              <div
                className={`shiftsOrTilFilter ${
                  showShifts ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowShifts(!showShifts);
                }}
              >
                Shifts{" "}
                <img
                  src={showShifts ? checkbox2tick : checkbox1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showOvertimes ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowOvertimes(!showOvertimes);
                }}
              >
                Overtime{" "}
                <img
                  src={showOvertimes ? checkbox2tick : checkbox1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showTils ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowTils(!showTils);
                }}
              >
                Time in Lieu{" "}
                <img
                  src={showTils ? checkbox2tick : checkbox1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
            </div>
          </div> */}

          <div
            className={`filterSplitterContainer ${
              !memoVals.full ? "noBorderRight" : ""
            }`}
          >
            <div className="filterSplitter"></div>
          </div>
          <div
            className={`availabilityBodyContainer ${
              !memoVals.full ? "noBorderRight" : ""
            }`}
            onClick={() => {}}
          >
            {/* render here */}
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />
            <div
              ref={clockScrollBox}
              className={`${!loading ? "clockOnsContainer" : "none"} ${
                memoVals.mob ? "mobClockOnsContainer" : "borderLeftGrey"
              } ${
                showFilter
                  ? `${
                      memoVals.mob
                        ? "showFilterClockHeight"
                        : "showFilterClockHeightDesktop"
                    }`
                  : ""
              } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
                !memoVals.full ? "noBorderRight" : ""
              }`}
            >
              {/* {!loading && pendingsExist ? (
                <p className="clocksAwaitingTitles notYetSubmitTitleArr">
                  Not yet approved
                </p>
              ) : (
                ""
              )} */}
              {clockOns[0] ? (
                generateClockOns || loading
              ) : (
                <div
                  className={`noShiftsTxtContainer noUpcomingReqs ${
                    memoVals.mob ? "x1984971" : ""
                  }`}
                >
                  <div className="noShiftsSideBar"></div>

                  <p className={`noShiftsTxt x19873`}>
                    No clock cards in{" "}
                    {dateStringer.printMonthAndYearFromMy(my, true, true)}
                  </p>

                  <div className="noShiftsSideBar"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            memoVals.mob || !memoVals.full ? "none" : "desktopMasterSecondary"
          }`}
        >
          <HoursDatabox sideBoxData={sideBoxData} my={my} setMy={setMy} />{" "}
        </div>
      </div>

      {imgPreload}
    </div>
  );
};

export default ClockOns;
