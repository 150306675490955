// Menu for the mobile view only - accessed via the burger button in the mobile navbar
import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { NavLink } from "react-router-dom";

import axios from "axios";
import serverURL from "../serverURL";

import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import "time-input-polyfill/auto";

import dateStringer from "../tools/dateStringer";
import CheckUser from "../tools/CheckUser";
import "../styles/menu.css"; // also contains css for request fullscreen modal

import Upcoming from "./Upcoming";
import Navbar from "./Navbar";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import clockOnPower from "../img/general/clockOnPower.svg";
import whitecross from "../img/general/whitecross.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import pullUp from "../img/general/pullUp.svg";
import blueLoader from "../img/general/blueLoader.gif";

const Menu = () => {
  const {
    setSelectedNav,
    setShowUnclocked,
    setShowClockOnModal,
    unclockedQty,
    activeClocksQty,
    setShowActiveClocks,
    userIsClockedOn,
    userIsOnClockedBreak,
    device,
    acceptDecline,
    setShowMgrAcceptanceModal,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setSelectedNav,
      setShowUnclocked,
      setShowClockOnModal,
      unclockedQty,
      activeClocksQty,
      setShowActiveClocks,
      userIsClockedOn,
      userIsOnClockedBreak,
      device,
      acceptDecline,
      setShowMgrAcceptanceModal,
      checkUserState,
    }),
    [
      setSelectedNav,
      setShowUnclocked,
      setShowClockOnModal,
      unclockedQty,
      activeClocksQty,
      setShowActiveClocks,
      userIsClockedOn,
      userIsOnClockedBreak,
      device,
      acceptDecline,
      setShowMgrAcceptanceModal,
      checkUserState,
    ]
  );

  let [calcBeforeDs, setCalcBeforeDs] = useState("");

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 5) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={clockOnPower}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={pullUp}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  // const {

  //   userID,

  // } = useContext(UserContext);
  let [excessLoading, setExcessLoading] = useState(true);

  CheckUser();

  // let [gmtOffset, setGmtOffset] = useState(0);

  let [propic, setPropic] = useState("");
  let [menuFName, setMenuFName] = useState("");
  let [menuLName, setMenuLName] = useState("");
  // let [upcomingShift, setUpcomingShift] = useState({});
  // let [subsequentShift, setSubsequentShift] = useState({});
  let [clockOnRequired, setClockOnRequired] = useState(false);
  let [remainingMins, setRemainingMins] = useState(0);
  let [dailyMins, setDailyMins] = useState(0);
  let [wPatt, setWPatt] = useState("fixedScheduled");
  let [topLoading, setTopLoading] = useState(true);
  let [upcomingObject, setUpcomingObject] = useState([]);
  let [subsequentObject, setSubsequentObject] = useState([]);
  let [clockOnOK, setClockOnOK] = useState(false);
  let [leaveInfo, setLeaveInfo] = useState({});
  let [showAllowances, setShowAllowances] = useState(false);
  // let [showExcessMins, setShowExcessMins] = useState(false); // if user is paid ot on reqs only
  // let [excessMpd, setExcessMpd] = useState(0);
  // let [refPeriod, setRefPeriod] = useState(52);

  let [lastUpdatedExcessTs, setLastUpdatedExcessTs] =
    useState("Y1970M00D00H00M00");

  let [excessData, setExcessData] = useState({});

  let [weeksUsed, setWeeksUsed] = useState(0);

  let [excessDataArr, setExcessDataArr] = useState([]);

  useEffect(() => {
    // clean not needed
    if (window.innerWidth > 820) {
      window.location.href = "/";
    }
  }, [window.innerWidth]);

  let generateExcessHistoryRows = excessDataArr
    ? excessDataArr.map((item) => {
        return (
          <div className="excessHistoryRow" key={item.startDs}>
            <div className="excessHistoryRowStart">
              {dateStringer.printedDateFromDs(item.startDs)} '
              {`${item.startDs.substr(3, 2)}`} to{" "}
              {dateStringer.printedDateFromDs(item.endDs)} '
              {`${item.endDs.substr(3, 2)}`}
            </div>
            <div className="excessHistoryRowTable">
              <div className="excessHistoryRowTableRow excessHistoryRowTableRowFirst">
                <p className="excessHistoryRowTableRowTitle">
                  Hours from shifts
                </p>
                <p className="excessHistoryRowTableRowValue">
                  {dateStringer.formatMinsDurationToHours(item.minsFromShifts)}
                </p>
              </div>
              <div className="excessHistoryRowTableRow">
                <p className="excessHistoryRowTableRowTitle">
                  Hours from overtime
                </p>
                <p className="excessHistoryRowTableRowValue">
                  {dateStringer.formatMinsDurationToHours(
                    item.minsFromOvertimes
                  )}
                </p>
              </div>

              <div className="excessHistoryRowTableRow">
                <p className="excessHistoryRowTableRowTitle">
                  Hours from leave
                </p>
                <p className="excessHistoryRowTableRowValue">
                  {dateStringer.formatMinsDurationToHours(item.minsFromReqs)}
                </p>
              </div>
              <div
                // className="excessHistoryRowTableRow"
                className={`${
                  item.minsFromHistory > 0 ? "excessHistoryRowTableRow" : "none"
                }`}
              >
                <p className="excessHistoryRowTableRowTitle">
                  Hours from before you were added to FlowRota
                </p>
                <p className="excessHistoryRowTableRowValue">
                  {dateStringer.formatMinsDurationToHours(item.minsFromHistory)}
                </p>
              </div>
            </div>
          </div>
        );
      })
    : [];

  let generateNextItemString = (upcoming, subsequent) => {
    if (upcoming[0]) {
      let type = "Shift";
      if (upcoming[0].type === "til") {
        type = "Shift in lieu";
      }
      if (upcoming[0].type === "overtime") {
        type = "Overtime";
      }
      return `${type} today @ ${dateStringer.dsToTimeStrip(upcoming[0].start)}`;
    }

    if (subsequent[0]) {
      let type = "Shift";
      if (subsequent[0].type === "til") {
        type = "Shift in lieu";
      }
      if (subsequent[0].type === "overtime") {
        type = "Overtime";
      }

      let when = "tomorrow";
      let todayMidnight = new Date();
      todayMidnight.setHours(0, 0, 0, 0);
      let shiftMidnight = new Date(
        dateStringer.createTimestampFromString(subsequent[0].start)
      );
      shiftMidnight.setHours(0, 0, 0, 0);

      let daysDiff =
        (shiftMidnight.getTime() - todayMidnight.getTime()) /
        (1000 * 60 * 60 * 24);
      if (daysDiff === 0) {
        when = "today";
      }
      if (daysDiff === 1) {
        when = "tomorrow";
      }
      if (daysDiff > 1) {
        if (shiftMidnight.getDay() === 0) {
          when = "on Sun";
        }
        if (shiftMidnight.getDay() === 1) {
          when = "on Mon";
        }
        if (shiftMidnight.getDay() === 2) {
          when = "on Tue";
        }
        if (shiftMidnight.getDay() === 3) {
          when = "on Wed";
        }
        if (shiftMidnight.getDay() === 4) {
          when = "on Thu";
        }
        if (shiftMidnight.getDay() === 5) {
          when = "on Fri";
        }
        if (shiftMidnight.getDay() === 6) {
          when = "on Sat";
        }
      }
      return (
        <>
          {type} {when}{" "}
          {when !== "today" && when !== "tomorrow"
            ? shiftMidnight.getDate()
            : ""}
          {when !== "today" && when !== "tomorrow" ? (
            <p className="menuSuff">
              {dateStringer.nd(shiftMidnight.getDate())}
            </p>
          ) : (
            ""
          )}{" "}
          {shiftMidnight.getMonth() === new Date().getMonth() ||
          when === "tomorrow"
            ? ""
            : dateStringer.getMonthThreeLettersFromDs(
                dateStringer.createStringFromTimestamp(shiftMidnight.getTime())
              )}{" "}
          @ {dateStringer.dsToTimeStrip(subsequent[0].start)}
        </>
      );
    }

    return "No upcoming shift";
  };

  useEffect(() => {
    memoVals.setSelectedNav((x) => 1.1);

    let nowDate = new Date();
    let todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-menu-profile-data`,
        {
          // userID: userID || "NO USER ID",
          todayDs: dateStringer.createStringFromTimestamp(
            todayMidnight.getTime()
          ),
          now: dateStringer.createStringFromTimestamp(nowDate.getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setDataLoaded(true);
          setLeaveInfo(response.data.leaveInfo);
          if (response.data.requireClockOn) {
            setClockOnRequired(true);
            if (
              response.data.upcomingItem[0] &&
              response.data.upcomingItem[0].clockOnNotNeeded
            ) {
              setClockOnRequired(false);
            }
            if (
              response.data.subsequentItem[0] &&
              response.data.subsequentItem[0].clockOnNotNeeded
            ) {
              setClockOnRequired(false);
            }
          }
          setMenuFName(response.data.fName);
          setMenuLName(response.data.lName);
          // setShowExcessMins(response.data.showExcessMins);
          // setExcessMpd(response.data.excessMpd);
          setDailyMins(response.data.dailyMins);
          setRemainingMins(response.data.remainingMins);
          setWPatt(response.data.workPatt);
          setPropic(response.data.propic);
          setClockOnOK(response.data.clockOnWithinClockOnPeriod);

          if (response.data.upcomingItem[0]) {
            setUpcomingObject([
              {
                type: response.data.upcomingItem[0].type,
                start: response.data.upcomingItem[0].start,
                itemID: response.data.upcomingItem[0].itemID,
              },
            ]);

            if (response.data.upcomingItem[0].clockOnNotNeeded) {
              setClockOnRequired(false);
            }
          } else if (response.data.subsequentItem[0]) {
            setSubsequentObject([
              {
                type: response.data.subsequentItem[0].type,
                start: response.data.subsequentItem[0].start,
                itemID: response.data.subsequentItem[0].itemID,
              },
            ]);
            if (response.data.subsequentItem[0].clockOnNotNeeded) {
              setClockOnRequired(false);
            }
          }

          setTopLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [showNotifs, setShowNotifs] = useState(false);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  useEffect(() => {
    if (showAllowances) {
      setModalPosition({ bottom: "0px" });
    }
  }, [showAllowances]);

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      setShowAllowances(false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div className="menuMainContainer">
      {/* <div className="menuWhiteOverlayContainer"> */}
      {/* <div className="menuWhiteOverlaySquare"></div> */}
      {/* <img src={homeBulge} alt="Bulge" className="homeBulge" /> */}
      {/* </div> */}
      <div className={`${topLoading ? "topMenuContentLoad" : "none"}`}>
        <img src={blueLoader} className="menuLoadingImg" alt="Loading" />
      </div>

      <div className={`${topLoading ? "none" : "topMenuContent"}`}>
        {memoVals.checkUserState.permissions < 3 && (
          <div className="mgrMobTopRight">
            {memoVals.unclockedQty ? (
              <p
                className="mgrMobTopRightBtn"
                onClick={() => {
                  memoVals.setShowUnclocked((x) => true);
                }}
              >
                Unclocked{" "}
                <span className="mgrMobTopRightBtnQty">
                  {memoVals.unclockedQty > 99 ? "99+" : memoVals.unclockedQty}
                </span>
              </p>
            ) : (
              ""
            )}
            {memoVals.activeClocksQty > 0 ? (
              <p
                className="mgrMobTopRightBtn"
                onClick={() => {
                  memoVals.setShowActiveClocks((x) => true);
                }}
              >
                Today{" "}
                <span className="mgrMobTopRightBtnQty">
                  {memoVals.activeClocksQty > 99
                    ? "99+"
                    : memoVals.activeClocksQty}
                </span>
              </p>
            ) : (
              ""
            )}
            {memoVals.acceptDecline.accepted + memoVals.acceptDecline.declined >
            0 ? (
              <p
                className="mgrMobTopRightBtn"
                onClick={() => {
                  memoVals.setShowMgrAcceptanceModal((x) => true);
                }}
              >
                Acceptance{" "}
                <span className="mgrMobTopRightBtnQty">
                  {memoVals.acceptDecline.accepted +
                    memoVals.acceptDecline.declined >
                  99
                    ? "99+"
                    : memoVals.acceptDecline.accepted +
                      memoVals.acceptDecline.declined}
                </span>{" "}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
        <div className="menuTopSectionContainer">
          <div className="menuTopRow1">
            {/* <div className='menuTopRow1SideUnit'>&nbsp;</div> */}
            {propic ? (
              <NavLink to="/account">
                <img
                  src={propic}
                  alt="Profile picture"
                  className="menuProfPicImg"
                />
              </NavLink>
            ) : (
              <NavLink to="/account">
                <div className="menuNoProPic">
                  <img
                    src={profilePicturePlaceholder}
                    alt={menuFName}
                    className="menuProPicPlaceholder"
                  />
                  <p className="menuProPicInits">
                    {menuFName[0]}
                    {menuLName[0]}
                  </p>
                </div>{" "}
              </NavLink>
            )}
            <div className="menuTopRow2">
              <p className="menuName">
                {menuFName} {menuLName}
              </p>
              <p className="menuRemaining">
                <span className="menuRemainingBoldSpan">
                  {dailyMins > 0
                    ? dateStringer.convertMinsToDays(remainingMins, dailyMins)
                    : dateStringer.formatMinsDurationToHours(remainingMins)}
                </span>{" "}
                left
              </p>
              <p className={`${dailyMins > 0 ? "menuRemainingDays" : "none"}`}>
                <span className="menuRemainingBoldSpan">
                  ({dateStringer.formatMinsDurationToHours(remainingMins)})
                </span>{" "}
              </p>
            </div>
          </div>

          <div className="menuTopRow1SideUnit">
            {/* <img
              src={showAllowances ? allowancesClose : allowancesEye}
              alt="Edit"
              className="editProfileImg"
              // onClick={() => {
              //   setShowAllowances(!showAllowances);
              // }}
            /> */}
            <div
              onClick={() => {
                if (!showAllowances && !excessDataArr[0]) {
                  axios
                    .post(
                      `${serverURL}/get-excess-data-array`,
                      {
                        someData: 1,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setExcessDataArr(
                          response.data.excessData.excessDataArr.sort(
                            (a, b) => {
                              return b.startTs - a.startTs;
                            }
                          )
                        );
                        setExcessData(response.data.excessData);
                        let dateObj = new Date(
                          dateStringer.createTimestampFromString(
                            response.data.lastUpdatedExcessTs || 0
                          )
                        );
                        if (typeof response.data.gmtOffset === "number") {
                          dateObj.setHours(
                            dateObj.getHours() + response.data.gmtOffset
                          );
                        }
                        console.log({ dateObj });
                        setLastUpdatedExcessTs(
                          dateStringer.createStringFromTimestamp(
                            dateObj.getTime()
                          )
                        );
                        let lastDayCalc = new Date(
                          dateStringer.createTimestampFromString(
                            response.data.excessData.lastDayOfCalculation
                          )
                        );
                        lastDayCalc.setDate(lastDayCalc.getDate() + 1);
                        setCalcBeforeDs(
                          dateStringer.createStringFromTimestamp(
                            lastDayCalc.getTime()
                          )
                        );
                        setExcessLoading(false);
                        // setGmtOffset(response.data.gmtOffset);
                        setWeeksUsed(
                          response.data.excessData.refPeriod.refPeriodWeeks
                          // response.data.excessData.weeksFromRefPeriodUsed +
                          //   response.data.excessData
                          //     .weeksFromMaxFarBackPeriodUsed
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
                setShowAllowances(!showAllowances);
              }}
              className={
                showAllowances ? "hideProfileStatsBtn" : "showProfileStatsBtn"
              }
            >
              <img
                src={whitecross}
                className={`${
                  showAllowances ? "profileStatsWhiteCross" : "none"
                }`}
                alt="close"
              />
              History
            </div>
          </div>
        </div>
        {showAllowances ? (
          <div
            className={`menuAllowancesContainer slideUp ${
              excessLoading ? "excessLoadingMenu" : ""
            }`}
            style={modalPosition}
            ref={modalRef}
          >
            <div
              className="menuMobHeaderSec"
              onTouchStart={handleDown}
              onTouchMove={handleMove}
              onTouchEnd={handleUp}
              onMouseDown={handleDown}
              onMouseMove={handleMove}
              onMouseUp={handleUp}
            >
              <div className="modalSwiper mobMenuModalSwiper"></div>
            </div>
            {excessLoading ? (
              <div className="allowPanelTopAndMiddle noBorder">
                <img
                  src={horizontalBalls}
                  alt="Loading"
                  className="loadingExcessImg"
                />
              </div>
            ) : (
              <div className="allowPanelTopAndMiddle">
                <div className={`${excessData ? "allowPanelTop" : "none"}`}>
                  Over the last{" "}
                  <span className="excessMpdBold">
                    {weeksUsed}
                    {/* {excessData.refPeriod &&
                      excessData.refPeriod.refPeriodWeeks} */}
                  </span>{" "}
                  weeks before{" "}
                  {dateStringer.printedDateFromDs(
                    calcBeforeDs,
                    true,
                    true,
                    false,
                    false,
                    false
                  )}
                  , you were paid for an average of approximately{" "}
                  <span className="excessMpdBold">
                    {dateStringer.formatMinsDurationToHours(
                      excessData.periodAvgPerDay && excessData.periodAvgPerDay
                    )}
                  </span>{" "}
                  per payable day. <br />
                  <br />
                  <p
                    className={`${
                      excessData.weeksFromMaxFarBackPeriodUsed ? "" : "none"
                    }`}
                  >
                    There{" "}
                    {excessData.weeksFromMaxFarBackPeriodUsed &&
                    excessData.weeksFromMaxFarBackPeriodUsed === 1
                      ? "was"
                      : "were"}{" "}
                    <span className="excessMpdBold">
                      {excessData.weeksFromMaxFarBackPeriodUsed &&
                        excessData.weeksFromMaxFarBackPeriodUsed}
                    </span>{" "}
                    seven-day period
                    {excessData.weeksFromMaxFarBackPeriodUsed &&
                    excessData.weeksFromMaxFarBackPeriodUsed === 1
                      ? ""
                      : "s"}{" "}
                    within which you were not paid, so FlowRota went back
                    further than the{" "}
                    {excessData.refPeriod &&
                      excessData.refPeriod.refPeriodWeeks}{" "}
                    week reference period.
                  </p>
                </div>{" "}
                <div className="allowPanelMiddle">
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Last calculated</p>{" "}
                    <p className="menuExcessValue">
                      {dateStringer.dsToTimeStrip(lastUpdatedExcessTs)},{" "}
                      {dateStringer.printedDateFromDs(lastUpdatedExcessTs)}
                    </p>
                  </div>
                  {/* <div className="excessDataRow">
                    <p className="menuExcessTitle">Total open days</p>{" "}
                    <p className="menuExcessValue">
                      {excessData && excessData.openDsArr}
                    </p>
                  </div>
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Total closed days</p>{" "}
                    <p className="menuExcessValue">
                      {excessData && excessData.closedDsArr}
                    </p>
                  </div> */}
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Total hours from shifts</p>{" "}
                    <p className="menuExcessValue">
                      {excessData.shifts &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.shifts.minsFromShifts
                        )}
                    </p>
                  </div>
                  <div className="excessDataRow">
                    <p className="menuExcessTitle">Total hours from overtime</p>{" "}
                    <p className="menuExcessValue">
                      {excessData.tils &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.tils.minsFromOvertimes
                        )}
                    </p>
                  </div>
                  {/* <div className="excessDataRow">
                <p className="menuExcessTitle">Total hours from time-in-lieu</p>{" "}
                <p className="menuExcessValue">
                  {excessData.tils &&
                    dateStringer.formatMinsDurationToHours(
                      excessData.tils.minsFromTils
                    )}
                </p>
              </div> */}
                  <div
                    className={`excessDataRow ${
                      excessData.history &&
                      excessData.history.totMinsFromHistory === 0
                        ? "noBorderExcessData"
                        : "none"
                    }`}
                  >
                    <p className="menuExcessTitle">Total hours from leave</p>{" "}
                    <p className="menuExcessValue">
                      {excessData.reqs &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.reqs.minsFromReqs
                        )}
                    </p>
                  </div>

                  <div
                    className={`${
                      excessData.history &&
                      excessData.history.totMinsFromHistory > 0
                        ? "excessDataRow"
                        : "none"
                    }`}
                  >
                    <p className="menuExcessTitleHistory">
                      Total hours from before you were added to FlowRota
                    </p>{" "}
                    <p className="menuExcessValue">
                      {excessData.reqs &&
                        dateStringer.formatMinsDurationToHours(
                          excessData.history
                            ? excessData.history.totMinsFromHistory
                            : 0
                        )}
                    </p>
                  </div>
                </div>{" "}
                {/* - - - - - -  */}
                <p className="excessHistoryDataTitle">Reference period data</p>
                <div className="excessHistoryRowDataDiv">
                  {generateExcessHistoryRows}
                </div>
                {/* - - - - - -  */}
              </div>
            )}
            {/* {!excessLoading ? (
              <div
                className="allowPanelBottom"
                onClick={() => {
                  setShowAllowances(false);
                }}
              >
                <img
                  src={pullUp}
                  className="menuAllowancesPullUpIcon"
                  alt="Close"
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        ) : (
          ""
        )}
        <div className="menuTopDivider"></div>
        <div className="shiftTodayRow">
          <div className="nextShiftAndNextLeaveDiv">
            <div className={`menuShiftTodayTxt `}>
              {generateNextItemString(upcomingObject, subsequentObject)}
            </div>
            <p
              className={`nextLeaveTxt ${
                leaveInfo.nextLeaveInDays ? "" : "none"
              } ${
                leaveInfo.leaveToday || leaveInfo.nextLeaveInDays < 0
                  ? "fullOpac"
                  : ""
              }`}
            >
              {leaveInfo.leaveToday
                ? "You're on leave today"
                : `${
                    leaveInfo.leaveInFuture
                      ? leaveInfo.nextLeaveInDays < 0
                        ? `You're on leave today`
                        : `Next leave in ${leaveInfo.nextLeaveInDays} days`
                      : "No upcoming leave"
                  }`}
            </p>
          </div>
          <div
            className={`${
              clockOnOK &&
              clockOnRequired &&
              (upcomingObject[0] || subsequentObject[0])
                ? `menuClockOnDiv ${
                    memoVals.userIsClockedOn ? "clockOffBtnActive" : ""
                  } ${
                    memoVals.userIsOnClockedBreak ? "clockedBrkBtnTxtSize" : ""
                  }`
                : "none"
            }`}
            onClick={() => {
              memoVals.setShowClockOnModal((x) => true);
            }}
          >
            <p className="menuClockOnBtnTxt">
              {memoVals.userIsClockedOn
                ? memoVals.userIsOnClockedBreak
                  ? "Clock back on"
                  : "Clock off"
                : "Clock on"}
            </p>{" "}
            <img
              src={clockOnPower}
              alt="Clock on/off"
              className="menuClockOnPowerImg"
            />
          </div>
        </div>
        {/* <div className="shiftTodayRow nextLeaveRow">
          <p
            className="nextLeaveTxt"
            onClick={() => {
              console.log(
                "upcomingObject: ",
                upcomingObject,
                "subsequentObject: ",
                subsequentObject
              );
            }}
          >
            {leaveInfo.leaveToday
              ? "On leave today"
              : `${
                  leaveInfo.leaveInFuture
                    ? `Next leave in ${leaveInfo.nextLeaveInDays} days`
                    : "No upcoming leave"
                }`}
          </p>
        </div> */}
      </div>
      <div className="upcomingNotificationsContainer">
        <div
          className="upcomingNotifContainer"
          onClick={() => {
            setShowNotifs(false);
          }}
        >
          <p
            className={`upcomingTitle ${
              showNotifs ? "fadedNotifUpcomingTxt" : ""
            }`}
            onClick={() => {
              memoVals.setShowUnclocked((x) => true); // SHOUDL NOT NBE HERE
            }}
          >
            Upcoming
          </p>
          <div className={`${showNotifs ? "none" : "upcomingUnderling"}`}></div>
        </div>{" "}
        <div
          className="upcomingNotifContainer"
          onClick={() => {
            setShowNotifs(true);
          }}
        >
          <div className={`upcomingTitle ${showNotifs ? "" : ""}`}>
            <span
              className={`${showNotifs ? "" : "fadedNotifUpcomingTxt"}`}
              onClick={() => {
                memoVals.setShowActiveClocks((x) => true);
              }}
            >
              Open shifts
            </span>
            <p className="notifCount">9+</p>
          </div>
          <div className={`${showNotifs ? "notifUnderling" : "none"}`}></div>
        </div>{" "}
        {memoVals.checkUserState.permissions < 3 && (
          <div
            className="upcomingNotifContainer"
            onClick={() => {
              setShowNotifs(true);
            }}
          >
            <div className={`upcomingTitle ${showNotifs ? "" : ""}`}>
              <span
                className={`${showNotifs ? "" : "fadedNotifUpcomingTxt"}`}
                onClick={() => {
                  memoVals.setShowActiveClocks((x) => true);
                }}
              >
                Manager
              </span>
              <p className="notifCount notifCountPurple">9+</p>
            </div>
            <div className={`${showNotifs ? "notifUnderling" : "none"}`}></div>
          </div>
        )}
      </div>
      <div className="menuUpcNotifDivider"></div>
      <div
        className={`menuUpcomingHolder ${
          memoVals.device.vhIssue ? "vhIssue" : ""
        }`}
      >
        {loading ? "" : <Upcoming />}
      </div>

      <Navbar />
      {imgPreload}
      {/* <p className='heresYourLeaveTxt'>Here's your dashboard, {fName}</p>

      <input
        type='time'
        className='timeInputTrial'
        value={val}
        onChange={(e) => {
          setVal(e.target.value);
        }}
      ></input> */}
    </div>
  );
};

export default Menu;
