import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";

import serverURL from "../../../serverURL";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import update from "../../../img/general/update.svg";
import dateStringer from "../../../tools/dateStringer";

const Overtime = () => {
  const { setAccNav, mob } = useContext(StyleContext);
  const { customLeaveTypes } = useContext(AdminContext);

  let [excessMinsPaidInRequests, setExcessMinsPaidInRequests] = useState(false);
  const { setSelectedNav, setIndicate, setShowUpdatedReqsModal } =
    useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      customLeaveTypes,
      setSelectedNav,
      setIndicate,
      setShowUpdatedReqsModal,
    }),
    [
      setAccNav,
      mob,
      customLeaveTypes,
      setSelectedNav,
      setIndicate,
      setShowUpdatedReqsModal,
    ]
  );

  let [loading, setLoading] = useState(true);

  let [monOtRate, setMonOtRate] = useState(1);
  let [tueOtRate, setTueOtRate] = useState(1);
  let [wedOtRate, setWedOtRate] = useState(1);
  let [thuOtRate, setThuOtRate] = useState(1);
  let [friOtRate, setFriOtRate] = useState(1);
  let [satOtRate, setSatOtRate] = useState(1);
  let [sunOtRate, setSunOtRate] = useState(1);

  let [regenMon, setRegenMon] = useState(false);
  let [regenTue, setRegenTue] = useState(false);
  let [regenWed, setRegenWed] = useState(false);
  let [regenThu, setRegenThu] = useState(false);
  let [regenFri, setRegenFri] = useState(false);
  let [regenSat, setRegenSat] = useState(false);
  let [regenSun, setRegenSun] = useState(false);

  let [loadRegenMon, setLoadRegnMon] = useState(false);
  let [loadRegenTue, setLoadRegnTue] = useState(false);
  let [loadRegenWed, setLoadRegnWed] = useState(false);
  let [loadRegenThu, setLoadRegnThu] = useState(false);
  let [loadRegenFri, setLoadRegnFri] = useState(false);
  let [loadRegenSat, setLoadRegnSat] = useState(false);
  let [loadRegenSun, setLoadRegnSun] = useState(false);

  let [showUpdateReqs, setShowUpdateReqs] = useState(false);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
    memoVals.setSelectedNav((x) => 5.1);
  }, []);
  let [showRemoved, setShowRemoved] = useState(false);

  useEffect(() => {
    const cancelSource3 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-overtime-day-rates`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource3.token,
        }
      )
      .then((resp) => {
        if (resp.data.message === "success") {
          setLoading(false);

          setExcessMinsPaidInRequests(resp.data.excessMinsPaidInRequests);

          resp.data.data.forEach((day) => {
            if (day.day === 0) {
              setSunOtRate(day.rate);
            }
            if (day.day === 1) {
              setMonOtRate(day.rate);
            }
            if (day.day === 2) {
              setTueOtRate(day.rate);
            }
            if (day.day === 3) {
              setWedOtRate(day.rate);
            }
            if (day.day === 4) {
              setThuOtRate(day.rate);
            }
            if (day.day === 5) {
              setFriOtRate(day.rate);
            }
            if (day.day === 6) {
              setSatOtRate(day.rate);
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource3.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    if (showRemoved) {
      let filt = memoVals.customLeaveTypes.filter((x) => {
        return x.removed;
      });

      if (!filt[0]) {
        setShowRemoved(false);
      }
    }
  }, [memoVals.customLeaveTypes]);

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/overtime";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  let updateDaysOnceRateHasChanges = (dayN) => {
    axios
      .post(
        `${serverURL}/update-shifts-and-tils-when-admin-rate-is-changed`,
        { dayN: dayN, overtime: true },
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (dayN === 0) {
            setRegenSun(false);
          }
          if (dayN === 1) {
            setRegenMon(false);
          }
          if (dayN === 2) {
            setRegenTue(false);
          }
          if (dayN === 3) {
            setRegenWed(false);
          }
          if (dayN === 4) {
            setRegenThu(false);
          }
          if (dayN === 5) {
            setRegenFri(false);
          }
          if (dayN === 6) {
            setRegenSat(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob ? "AdminSettingsContainerMob" : "borderRightGrey"
      }`}
    >
      {/* <div className='whiteGradientAdmin'></div> */}

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          {/* ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow x13i121 ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow x132432222">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Overtime rates</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Set the daily rates of overtime pay
                </p>

                {/* <div className="adminOtRatesDiv">Hello</div> */}
                <div className="dailyRatesDataContainer x389181">
                  {/* OT ROW ------------------------------------------------------------------------ */}

                  <div className="dailyPayRateRow">
                    <p className="dailyPayRateTxt">Monday</p>
                    <select
                      className="dailyPayRateDropdown"
                      value={monOtRate}
                      onChange={(e) => {
                        let val = e.target.value;
                        axios
                          .post(
                            `${serverURL}/change-overtime-day-rate`,
                            { data: e.target.value, day: 1 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setMonOtRate(val);
                              setRegenMon(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">0x</option>

                      <option value="0.25">0.25x</option>

                      <option value="0.5">0.5x</option>

                      <option value="0.75">0.75x</option>

                      <option value="1">1x</option>

                      <option value="1.5">1.5x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                      <option value="2.25">2.25x</option>
                      <option value="2.5">2.5x</option>
                      <option value="2.75">2.75x</option>
                      <option value="3">3x</option>
                      <option value="3.25">3.25x</option>
                      <option value="3.5">3.5x</option>
                      <option value="3.75">3.75x</option>
                      <option value="4">4x</option>
                    </select>
                  </div>
                  <div className="regenUpdateParent">
                    <div
                      className={`${
                        regenMon ? "regenDayRatesContainer" : "none"
                      }`}
                    >
                      <p className="updateRateShiftsTxt">
                        Update all overtime shifts that fall on a{" "}
                        <span className="rateDaySpanBold">Monday</span> (from
                        today) with the new pay rate of{" "}
                        <span className="rateDaySpanBold">{monOtRate}x</span>
                      </p>
                      <p
                        className="regenRateBtn"
                        onClick={() => {
                          updateDaysOnceRateHasChanges(1);
                          setRegenMon(false);
                          setLoadRegnMon(true);
                          setTimeout(() => {
                            setLoadRegnMon(false);
                          }, 3000);
                        }}
                      >
                        Update
                      </p>
                    </div>
                    <div
                      className={`${
                        loadRegenMon && !regenMon
                          ? "loadingRegenDay x2398298322"
                          : "none"
                      }`}
                    >
                      Monday overtime shifts updated
                    </div>
                  </div>
                  <div className="dailyRateRowSplitter"></div>
                  {/* OT ROW ------------------------------------------------------------------------ */}

                  <div className="dailyPayRateRow">
                    <p className="dailyPayRateTxt">Tuesday</p>
                    <select
                      className="dailyPayRateDropdown"
                      value={tueOtRate}
                      onChange={(e) => {
                        let val = e.target.value;
                        axios
                          .post(
                            `${serverURL}/change-overtime-day-rate`,
                            { data: e.target.value, day: 2 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setTueOtRate(val);
                              setRegenTue(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">0x</option>

                      <option value="0.25">0.25x</option>

                      <option value="0.5">0.5x</option>

                      <option value="0.75">0.75x</option>

                      <option value="1">1x</option>

                      <option value="1.5">1.5x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                      <option value="2.25">2.25x</option>
                      <option value="2.5">2.5x</option>
                      <option value="2.75">2.75x</option>
                      <option value="3">3x</option>
                      <option value="3.25">3.25x</option>
                      <option value="3.5">3.5x</option>
                      <option value="3.75">3.75x</option>
                      <option value="4">4x</option>
                    </select>
                  </div>
                  <div className="regenUpdateParent">
                    <div
                      className={`${
                        regenTue ? "regenDayRatesContainer" : "none"
                      }`}
                    >
                      <p className="updateRateShiftsTxt">
                        Update all overtime shifts that fall on a{" "}
                        <span className="rateDaySpanBold">Tuesday</span> (from
                        today) with the new pay rate of{" "}
                        <span className="rateDaySpanBold">{tueOtRate}x</span>
                      </p>
                      <p
                        className="regenRateBtn"
                        onClick={() => {
                          updateDaysOnceRateHasChanges(2);
                          setRegenTue(false);
                          setLoadRegnTue(true);
                          setTimeout(() => {
                            setLoadRegnTue(false);
                          }, 3000);
                        }}
                      >
                        Update
                      </p>
                    </div>
                    <div
                      className={`${
                        loadRegenTue && !regenTue
                          ? "loadingRegenDay x2398298322"
                          : "none"
                      }`}
                    >
                      Tuesday overtime shifts updated
                    </div>
                  </div>
                  <div className="dailyRateRowSplitter"></div>
                  {/* OT ROW ------------------------------------------------------------------------ */}

                  <div className="dailyPayRateRow">
                    <p className="dailyPayRateTxt">Wednesday</p>
                    <select
                      className="dailyPayRateDropdown"
                      value={wedOtRate}
                      onChange={(e) => {
                        let val = e.target.value;
                        axios
                          .post(
                            `${serverURL}/change-overtime-day-rate`,
                            { data: e.target.value, day: 3 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setWedOtRate(val);
                              setRegenWed(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">0x</option>

                      <option value="0.25">0.25x</option>

                      <option value="0.5">0.5x</option>

                      <option value="0.75">0.75x</option>

                      <option value="1">1x</option>

                      <option value="1.5">1.5x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                      <option value="2.25">2.25x</option>
                      <option value="2.5">2.5x</option>
                      <option value="2.75">2.75x</option>
                      <option value="3">3x</option>
                      <option value="3.25">3.25x</option>
                      <option value="3.5">3.5x</option>
                      <option value="3.75">3.75x</option>
                      <option value="4">4x</option>
                    </select>
                  </div>
                  <div className="regenUpdateParent">
                    <div
                      className={`${
                        regenWed ? "regenDayRatesContainer" : "none"
                      }`}
                    >
                      <p className="updateRateShiftsTxt">
                        Update all overtime shifts that fall on a{" "}
                        <span className="rateDaySpanBold">Wednesday</span> (from
                        today) with the new pay rate of{" "}
                        <span className="rateDaySpanBold">{wedOtRate}x</span>
                      </p>
                      <p
                        className="regenRateBtn"
                        onClick={() => {
                          updateDaysOnceRateHasChanges(3);
                          setRegenWed(false);
                          setLoadRegnWed(true);
                          setTimeout(() => {
                            setLoadRegnWed(false);
                          }, 3000);
                        }}
                      >
                        Update
                      </p>
                    </div>
                    <div
                      className={`${
                        loadRegenWed && !regenWed
                          ? "loadingRegenDay x2398298322"
                          : "none"
                      }`}
                    >
                      Wednesday overtime shifts updated
                    </div>
                  </div>
                  <div className="dailyRateRowSplitter"></div>
                  {/* OT ROW ------------------------------------------------------------------------ */}
                  <div className="dailyPayRateRow">
                    <p className="dailyPayRateTxt">Thursday</p>
                    <select
                      className="dailyPayRateDropdown"
                      value={thuOtRate}
                      onChange={(e) => {
                        let val = e.target.value;
                        axios
                          .post(
                            `${serverURL}/change-overtime-day-rate`,
                            { data: e.target.value, day: 4 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setThuOtRate(val);
                              setRegenThu(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">0x</option>

                      <option value="0.25">0.25x</option>

                      <option value="0.5">0.5x</option>

                      <option value="0.75">0.75x</option>

                      <option value="1">1x</option>

                      <option value="1.5">1.5x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                      <option value="2.25">2.25x</option>
                      <option value="2.5">2.5x</option>
                      <option value="2.75">2.75x</option>
                      <option value="3">3x</option>
                      <option value="3.25">3.25x</option>
                      <option value="3.5">3.5x</option>
                      <option value="3.75">3.75x</option>
                      <option value="4">4x</option>
                    </select>
                  </div>
                  <div className="regenUpdateParent">
                    <div
                      className={`${
                        regenThu ? "regenDayRatesContainer" : "none"
                      }`}
                    >
                      <p className="updateRateShiftsTxt">
                        Update all overtime shifts that fall on a{" "}
                        <span className="rateDaySpanBold">Thursday</span> (from
                        today) with the new pay rate of{" "}
                        <span className="rateDaySpanBold">{thuOtRate}x</span>
                      </p>
                      <p
                        className="regenRateBtn"
                        onClick={() => {
                          updateDaysOnceRateHasChanges(4);
                          setRegenThu(false);
                          setLoadRegnThu(true);
                          setTimeout(() => {
                            setLoadRegnThu(false);
                          }, 3000);
                        }}
                      >
                        Update
                      </p>
                    </div>
                    <div
                      className={`${
                        loadRegenThu && !regenThu
                          ? "loadingRegenDay x2398298322"
                          : "none"
                      }`}
                    >
                      Thursday overtime shifts updated
                    </div>
                  </div>
                  <div className="dailyRateRowSplitter"></div>
                  {/* OT ROW ------------------------------------------------------------------------ */}
                  <div className="dailyPayRateRow">
                    <p className="dailyPayRateTxt">Friday</p>
                    <select
                      className="dailyPayRateDropdown"
                      value={friOtRate}
                      onChange={(e) => {
                        let val = e.target.value;
                        axios
                          .post(
                            `${serverURL}/change-overtime-day-rate`,
                            { data: e.target.value, day: 5 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setFriOtRate(val);
                              setRegenFri(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">0x</option>

                      <option value="0.25">0.25x</option>

                      <option value="0.5">0.5x</option>

                      <option value="0.75">0.75x</option>

                      <option value="1">1x</option>

                      <option value="1.5">1.5x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                      <option value="2.25">2.25x</option>
                      <option value="2.5">2.5x</option>
                      <option value="2.75">2.75x</option>
                      <option value="3">3x</option>
                      <option value="3.25">3.25x</option>
                      <option value="3.5">3.5x</option>
                      <option value="3.75">3.75x</option>
                      <option value="4">4x</option>
                    </select>
                  </div>
                  <div className="regenUpdateParent">
                    <div
                      className={`${
                        regenFri ? "regenDayRatesContainer" : "none"
                      }`}
                    >
                      <p className="updateRateShiftsTxt">
                        Update all overtime shifts that fall on a{" "}
                        <span className="rateDaySpanBold">Friday</span> (from
                        today) with the new pay rate of{" "}
                        <span className="rateDaySpanBold">{friOtRate}x</span>
                      </p>
                      <p
                        className="regenRateBtn"
                        onClick={() => {
                          updateDaysOnceRateHasChanges(5);
                          setRegenFri(false);
                          setLoadRegnFri(true);
                          setTimeout(() => {
                            setLoadRegnFri(false);
                          }, 3000);
                        }}
                      >
                        Update
                      </p>
                    </div>
                    <div
                      className={`${
                        loadRegenFri && !regenFri
                          ? "loadingRegenDay x2398298322"
                          : "none"
                      }`}
                    >
                      Friday overtime shifts updated
                    </div>
                  </div>
                  <div className="dailyRateRowSplitter"></div>
                  {/* OT ROW ------------------------------------------------------------------------ */}
                  <div className="dailyPayRateRow">
                    <p className="dailyPayRateTxt">Saturday</p>
                    <select
                      className="dailyPayRateDropdown"
                      value={satOtRate}
                      onChange={(e) => {
                        let val = e.target.value;
                        axios
                          .post(
                            `${serverURL}/change-overtime-day-rate`,
                            { data: e.target.value, day: 6 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setSatOtRate(val);
                              setRegenSat(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">0x</option>

                      <option value="0.25">0.25x</option>

                      <option value="0.5">0.5x</option>

                      <option value="0.75">0.75x</option>

                      <option value="1">1x</option>

                      <option value="1.5">1.5x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                      <option value="2.25">2.25x</option>
                      <option value="2.5">2.5x</option>
                      <option value="2.75">2.75x</option>
                      <option value="3">3x</option>
                      <option value="3.25">3.25x</option>
                      <option value="3.5">3.5x</option>
                      <option value="3.75">3.75x</option>
                      <option value="4">4x</option>
                    </select>
                  </div>
                  <div className="regenUpdateParent">
                    <div
                      className={`${
                        regenSat ? "regenDayRatesContainer" : "none"
                      }`}
                    >
                      <p className="updateRateShiftsTxt">
                        Update all overtime shifts that fall on a{" "}
                        <span className="rateDaySpanBold">Saturday</span> (from
                        today) with the new pay rate of{" "}
                        <span className="rateDaySpanBold">{satOtRate}x</span>
                      </p>
                      <p
                        className="regenRateBtn"
                        onClick={() => {
                          updateDaysOnceRateHasChanges(6);
                          setRegenSat(false);
                          setLoadRegnSat(true);
                          setTimeout(() => {
                            setLoadRegnSat(false);
                          }, 3000);
                        }}
                      >
                        Update
                      </p>
                    </div>
                    <div
                      className={`${
                        loadRegenSat && !regenSat
                          ? "loadingRegenDay x2398298322"
                          : "none"
                      }`}
                    >
                      Saturday overtime shifts updated
                    </div>
                  </div>
                  <div className="dailyRateRowSplitter"></div>
                  {/* OT ROW ------------------------------------------------------------------------ */}

                  <div className="dailyPayRateRow">
                    <p className="dailyPayRateTxt">Sunday</p>
                    <select
                      className="dailyPayRateDropdown"
                      value={sunOtRate}
                      onChange={(e) => {
                        let val = e.target.value;
                        axios
                          .post(
                            `${serverURL}/change-overtime-day-rate`,
                            { data: e.target.value, day: 0 },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setSunOtRate(val);
                              setRegenSun(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      <option value="0">0x</option>

                      <option value="0.25">0.25x</option>

                      <option value="0.5">0.5x</option>

                      <option value="0.75">0.75x</option>

                      <option value="1">1x</option>

                      <option value="1.5">1.5x</option>
                      <option value="1.75">1.75x</option>
                      <option value="2">2x</option>
                      <option value="2.25">2.25x</option>
                      <option value="2.5">2.5x</option>
                      <option value="2.75">2.75x</option>
                      <option value="3">3x</option>
                      <option value="3.25">3.25x</option>
                      <option value="3.5">3.5x</option>
                      <option value="3.75">3.75x</option>
                      <option value="4">4x</option>
                    </select>
                  </div>
                  <div className="regenUpdateParent">
                    <div
                      className={`${
                        regenSun ? "regenDayRatesContainer" : "none"
                      }`}
                    >
                      <p className="updateRateShiftsTxt">
                        Update all overtime shifts that fall on a{" "}
                        <span className="rateDaySpanBold">Sunday</span> (from
                        today) with the new pay rate of{" "}
                        <span className="rateDaySpanBold">{sunOtRate}x</span>
                      </p>
                      <p
                        className="regenRateBtn"
                        onClick={() => {
                          updateDaysOnceRateHasChanges(0);
                          setRegenSun(false);
                          setLoadRegnSun(true);
                          setTimeout(() => {
                            setLoadRegnSun(false);
                          }, 3000);
                        }}
                      >
                        Update
                      </p>
                    </div>
                    <div
                      className={`${
                        loadRegenSun && !regenSun
                          ? "loadingRegenDay x2398298322"
                          : "none"
                      }`}
                    >
                      Sunday overtime shifts updated
                    </div>
                  </div>
                  {/* <div className="dailyRateRowSplitter"></div> */}
                  {/* OT ROW ------------------------------------------------------------------------ */}
                </div>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                {/* <div className="knobContainer knobContainerSettings   ">
                <div
                  className={`switchKnob ${enableTil ? "knobOn" : "knobOff"}`}
                ></div>

                <div className="knobBg"></div>
              </div> */}
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow clickShiftRowAdmin_ ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
          >
            <div
              className="settingsRow"
              // onClick={(e) => {
              //   e.stopPropagation();
              // }}
            >
              <div className="rowWithSubText">
                <p className="absenceRowMainText">
                  Pay accrued overtime within paid leave
                </p>
                {showUpdateReqs ? (
                  <p
                    className="updateOtReqsBtn"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/update-client-requests-with-excess`,
                          {
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },
                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            if (response.data.reqs && response.data.reqs[0]) {
                              memoVals.setShowUpdatedReqsModal(
                                (x) => response.data.reqs
                              );
                            }
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Requests updated",
                                colour: "blue",
                                duration: 4000,
                              };
                            });
                            setShowUpdateReqs(false);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Update requests from today{" "}
                    <img src={update} className="updatereqsImg" alt="Update" />
                  </p>
                ) : (
                  ""
                )}{" "}
                <p className="otEachUsrDisclaim">
                  This setting can be enabled or disabled for each individual
                  employee in the allowances section within their profile
                  settings.
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  By default, the duration of paid leave (including paid
                  absence) is based on the employee's contracted hours.
                  <br />
                  <br />
                  If an employee routinely works overtime, should the payable
                  duration of each paid leave and paid absence period take into
                  account their overtime hours too?
                  <br />
                  <br />
                  <div className="overtimeExample">
                    <p className="overtimeExampleTxt">Example</p>
                    <div className="exampleDiv">
                      <div className="exampleRow">
                        <p className="exampleNameTitle">Employee</p>
                        <p className="exampleNameValue">James</p>
                      </div>{" "}
                      <div className="exampleRow">
                        <p className="exampleNameTitle">Reference period</p>
                        <p className="exampleNameValue">52 weeks</p>
                      </div>
                      <div className="exampleRow">
                        <p className="exampleNameTitle">
                          Current contracted hours per week
                        </p>
                        <p className="exampleNameValue">35 hours per week</p>
                      </div>
                      <div className="exampleRow">
                        <p className="exampleNameTitle">
                          Average overtime hours worked per week (across the
                          reference period)
                        </p>
                        <p className="exampleNameValue">5 hours per week</p>
                      </div>
                      <div className="exampleRow exampleRow2">
                        <p className="exampleNameTitle">
                          Total average hours per week
                        </p>
                        <p className="exampleNameValue">40 hours per week</p>
                      </div>
                      <div className="exampleRow">
                        <p className="exampleNameTitle">
                          If enabled, 1 week of leave is equal to
                        </p>
                        <p
                          className={`exampleNameValue ${
                            excessMinsPaidInRequests ? "exampleMainVal" : ""
                          }`}
                        >
                          40 hours
                        </p>
                      </div>{" "}
                      <div className="exampleRow noBorder">
                        <p className="exampleNameTitle">
                          If disabled, 1 week of leave is equal to
                        </p>
                        <p
                          className={`exampleNameValue ${
                            !excessMinsPaidInRequests ? "exampleMainVal" : ""
                          }`}
                        >
                          35 hours
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  If enabled, the amount of leave payable in any given paid
                  leave period is determined by adding the value of accrued
                  overtime holiday leave to the contracted hours ordinarily
                  worked within the leave request. The accrued overtime value is
                  calculated by counting the amount of payable hours from within
                  the reference period (see{" "}
                  <a
                    href="/admin/settings"
                    className="makeBold x121441"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    general settings
                  </a>
                  ).
                  <br />
                  <br />
                  If disabled, employees will not be paid any overtime leave
                  during their paid leave and absence periods by default. But
                  managers can amend individual leave requests and absence
                  periods to do so.
                </p>
              </div>
              <div className="otexcess">
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings   "
                  onClick={(e) => {
                    setExcessMinsPaidInRequests(!excessMinsPaidInRequests);

                    axios
                      .post(
                        `${serverURL}/how-are-excess-mins-paid`,
                        { data: !excessMinsPaidInRequests },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setShowUpdateReqs(true);
                          excessMinsPaidInRequests(
                            response.data.excessMinsPaidInRequests
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      excessMinsPaidInRequests ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>{" "}
            {/* <br /> <br /> */}
            <a
              href="https://flowrota.com/support/pay-accrued-overtime-leave"
              target="_blank"
              className="readMoreHere x1398748713"
            >
              Read more here
            </a>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          <br />
        </div>
      )}
    </div>
  );
};

export default Overtime;
