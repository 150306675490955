import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const StyleContext = createContext();

const StyleContextProvider = (props) => {
  // settings: select between Settings and Allowances
  let [settingsAllowances, setSettingsAlowances] = useState("settings");

  // Account subnavbar selected
  let [accNav, setAccNav] = useState("settings");

  // To show textInputter or not
  let [showTextInputter, setShowTextInputter] = useState(false);

  // admin page: which title?
  // General, Users & Teams, Admins, Billing, Absence, Leave Types, Overtime, Schedule
  let [adminPage, setAdminPage] = useState("General settings"); // was Users

  // show info modal (the content is stored in DataContext)
  let [showInfoModal, setShowInfoModal] = useState(false);

  // show admin stats modal
  let [showStatsModal, setShowStatsModal] = useState(false);

  // is a modal open? The navbar/mgradmin bar should disappear when modal is open (need to account for this in the size of the div calcs)
  let [modalIsOpen, setModalIsOpen] = useState(false);

  // let showAddUser modal
  let [showAddUserModal, setShowAddUserModal] = useState(false);
  let [addUserPage, setAddUserPage] = useState("initial");
  let [addUserModalCalOpen, setAddUserModalCalOpen] = useState(false); // determines whether the modal should be full height for datepicker

  // show archived users in admin users
  let [showArchived, setShowArchived] = useState(false);

  // open modal to unarchive user (in the acrhived users screen in admin users)
  let [showUnarchiveUserModal, setShowUnarchiveUserModal] = useState(false);
  let [unarchiveName, setUnarchiveName] = useState();
  let [unarchiveId, setUnarchiveId] = useState();

  // show admin users search modal?
  let [searchAdminUsers, setSearchAdminUsers] = useState(false);
  let [searchUserValue, setSearchUserValue] = useState("");
  let [searchResult, setSearchResult] = useState(false); // to keep the search results in the view when the search box is closed

  // is the fixedSched editor open in the edit user modal?
  let [editSched, setEditSched] = useState(false);
  let [editFixedVar, setEditFixedVar] = useState(false);

  // which editUser tab? -- Details, Allowances, Shifts, Absence

  let [hideMgrAccNav, setHideMgrAccNav] = useState(false);

  let [mob, setMob] = useState(false);
  let [mobModal, setMobModal] = useState(false);
  let [full, setFull] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  let [fullButNarrow, setFullButNarrow] = useState(
    window.innerWidth >= 1000 && window.innerWidth < 1080
  );

  let [iPad, setIPad] = useState(
    window.innerWidth >= 804 && window.innerWidth <= 818
  );
  // ipad portrait (if width is > 804px and < 818px)

  let [wide, setWide] = useState(window.innerWidth >= 1140);
  return (
    <StyleContext.Provider
      value={{
        iPad,
        setIPad,
        wide,
        setWide,
        fullButNarrow,
        setFullButNarrow,
        mobModal,
        setMobModal,
        full,
        setFull,
        width,
        setWidth,
        mob,
        setMob,
        accNav,
        setAccNav,
        settingsAllowances,
        setSettingsAlowances,
        showTextInputter,
        setShowTextInputter,
        adminPage,
        setAdminPage,
        showInfoModal,
        setShowInfoModal,
        showStatsModal,
        setShowStatsModal,
        showAddUserModal,
        setShowAddUserModal,
        addUserPage,
        setAddUserPage,
        addUserModalCalOpen,
        setAddUserModalCalOpen,
        showArchived,
        setShowArchived,
        showUnarchiveUserModal,
        setShowUnarchiveUserModal,
        unarchiveName,
        setUnarchiveName,
        unarchiveId,
        setUnarchiveId,
        searchAdminUsers,
        setSearchAdminUsers,
        searchUserValue,
        setSearchUserValue,
        searchResult,
        setSearchResult,
        editSched,
        setEditSched,
        editFixedVar,
        setEditFixedVar,
        hideMgrAccNav,
        setHideMgrAccNav,
      }}
    >
      {props.children}
    </StyleContext.Provider>
  );
};

export default StyleContextProvider;
