import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
import axios from "axios";
import { CalendarContext } from "../contexts/CalendarContext";
import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";
import websiteURL from "../websiteURL";

import navyClose from "../img/general/navyClose.svg";

import refresh from "../img/general/refresh.svg";

import popup from "../img/general/popup.svg";

import unitTick from "../img/general/unitTick.svg";
import unitCross from "../img/general/unitCross.svg";

import checkboxInstall1 from "../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../img/general/checkboxInstall2purp.svg";
import warning from "../img/general/warning.svg";
import closeGrey from "../img/general/closeGrey.svg";
import blueLoader from "../img/general/blueLoader.gif";

import "../styles/calendar.css";

const AddLeaveModal = () => {
  const {
    showAddLeaveModal,
    setShowAddLeaveModal,
    setShowReqMgrModal,
    updateTabData,
    setUpdateTabData,
  } = useContext(DataContext);
  const { setUpdateInfo, updateInfo } = useContext(CalendarContext);
  const { mobModal, mob } = useContext(StyleContext);
  // const { currencySymbol } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      showAddLeaveModal,
      setShowAddLeaveModal,
      setShowReqMgrModal,
      updateTabData,
      setUpdateTabData,
      setUpdateInfo,
      updateInfo,
      mobModal,
      mob,
    }),
    [
      showAddLeaveModal, //
      setShowAddLeaveModal, //
      setShowReqMgrModal, //
      updateTabData, //
      setUpdateTabData, //
      setUpdateInfo, //
      updateInfo, //
      mobModal, //
      mob, //
    ]
  );

  let [startDateIsBeforeUserStarted, setStartDateIsBeforeUserStarted] =
    useState(false);

  let [startDateIsTooFarAhead, setStartDateIsTooFarAhead] = useState(false);

  let [endDateIsBeforeUserStarted, setEndDateIsBeforeUserStarted] =
    useState(false);
  let [endDateIsTooFarAhead, setEndDateIsTooFarAhead] = useState(false);
  let [leaveTypesArr, setLeaveTypesArr] = useState([]);
  let [selectedLeaveTypeID, setSelectedLeaveTypeID] = useState(
    memoVals.showAddLeaveModal.leaveTypeID || "annLeave"
  );
  let [fName, setFName] = useState(memoVals.showAddLeaveModal.fName);

  let [userStartDate, setUserStartDate] = useState("Y2020M00D01H00M00");
  let [maxDs, setMaxDs] = useState("Y2099M00D00H00M00"); // the last date until too far ahead on the client
  let [firstDateInput, setFirstDateInput] = useState(
    dateStringer.createDateInputFromDateString(
      memoVals.showAddLeaveModal.editing
        ? memoVals.showAddLeaveModal.editStartDs
        : memoVals.showAddLeaveModal.startDs
    )
  );
  let [lastDateInput, setLastDateInput] = useState(
    dateStringer.createDateInputFromDateString(
      memoVals.showAddLeaveModal.editing
        ? memoVals.showAddLeaveModal.editEndDs
        : memoVals.showAddLeaveModal.startDs
    )
  );

  let [startValid, setStartValid] = useState(true);
  let [endValid, setEndValid] = useState(true);

  const startInput = document.getElementById("startInput");

  if (startInput && !memoVals.mob) {
    startInput.addEventListener("focus", function (evt) {
      if (this.getAttribute("type") === "date") {
        this.showPicker();
      }
    });
  }

  const endInput = document.getElementById("endInput");

  if (endInput && !memoVals.mob) {
    endInput.addEventListener("focus", function (evt) {
      if (this.getAttribute("type") === "date") {
        this.showPicker();
      }
    });
  }

  let [needToRecalc, setNeedToRecalc] = useState(true);
  let [calced, setCalced] = useState(false);

  let [calcLoading, setCalcLoading] = useState(false);
  let [overlapsWithOtherReqs, setOverlapsWithOtherReqs] = useState([]);
  let [noLeaveRequired, setNoLeaveRequired] = useState(false);
  let [ongoing, setOngoing] = useState(
    memoVals.showAddLeaveModal.toEndOngoing
      ? false
      : memoVals.showAddLeaveModal.ongoing
  );
  let [openedToPossiblyEndOngoing, setOpenedToPossiblyEndOngoing] = useState(
    memoVals.showAddLeaveModal.ongoing ||
      memoVals.showAddLeaveModal.toEndOngoing
  );

  let [openedToEndOngoing, setOpenedToEndOngoing] = useState(
    memoVals.showAddLeaveModal.toEndOngoing
  );
  let [schedule, setSchedule] = useState([]);
  let [halfStartStrip, setHalfStartStrip] = useState("Half way through");
  let [halfEndStrip, setHalfEndStrip] = useState("Half way through");
  let [halfStartStrip2, setHalfStartStrip2] = useState("");
  let [halfEndStrip2, setHalfEndStrip2] = useState("");
  let [halfStart, setHalfStart] = useState(false);
  let [halfEnd, setHalfEnd] = useState(false);
  let [showTooFutureModal, setShowTooFutureModal] = useState(false);
  let [showNeedToCalcModal, setShowNeedToCalcModal] = useState(false);

  let [saveLoading, setSaveLoading] = useState(false);

  let [overlapsRenewal, setOverlapsRenewal] = useState(false);
  let [renewalOverlap, setRenewalOverlap] = useState(false);
  let [tooFarAhead, setTooFarAhead] = useState(false);
  let [tooFuture, setTooFuture] = useState("");
  let [showOverlapsRenewalModal, setShowOverlapsRenewalModal] = useState(false);
  let [halfStartAvailable, setHalfStartAvailable] = useState(false);
  let [halfEndAvailable, setHalfEndAvailable] = useState(false);
  let [workingPattern, setWorkingPattern] = useState("");
  let [reqDur, setReqDur] = useState(0);
  let [isPaid, setIsPaid] = useState(true);
  let [updateStrips, setUpdateStrips] = useState(false);
  let [startsBeforeEnds, setStartsBeforeEnds] = useState(false);
  let [rate, setRate] = useState(1);
  let [singleDayBothHalfs, setSingleDayBothHalfs] = useState(false);
  let [contactedMpw, setContactedMpw] = useState(0);

  let [allowData, setAllowData] = useState({});
  let [showAddNote, setShowAddNote] = useState(false);
  let [addNoteVal, setAddNoteVal] = useState("");

  let bodyScroll = useRef();
  let [lastDateUpdated, setLastDateUpdated] = useState(false);
  useEffect(() => {
    // clean not needed
    if (bodyScroll.current && (calcLoading || lastDateUpdated)) {
      setTimeout(() => {
        if (bodyScroll.current && bodyScroll.current.scrollHeight) {
          const scrollHeight = bodyScroll.current.scrollHeight;
          bodyScroll.current.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          });
        }
        console.log("SCROLL TO BOTTOM");
      }, 250);
    }
  }, [allowData, calcLoading, lastDateUpdated]);

  useEffect(() => {
    // clean not needed
    if (halfStart && halfEnd && firstDateInput === lastDateInput) {
      setSingleDayBothHalfs(true);
    } else {
      setSingleDayBothHalfs(false);
    }
  }, [halfStart, halfEnd, firstDateInput, lastDateInput]);

  let [isAbsence, setIsAbsence] = useState(
    memoVals.showAddLeaveModal.isAbsence ? true : false
  );

  let [ltName, setLtName] = useState("Annual leave");

  useEffect(() => {
    // clean not needed
    let isPay = true;
    let ltn = "Annual leave";
    if (selectedLeaveTypeID !== "annLeave") {
      leaveTypesArr.forEach((lt) => {
        if (
          (lt.absenceTypeID && lt.absenceTypeID === selectedLeaveTypeID) ||
          (lt.leaveTypeID && lt.leaveTypeID === selectedLeaveTypeID)
        ) {
          console.log("**************", lt);
          ltn = lt.name;
          if (!lt.paid || lt.rate === 0) {
            isPay = false;
            console.log("IS NOT PAID", lt);
          }
        }
      });
    }
    setLtName(ltn);
    setIsPaid(isPay);
  }, [leaveTypesArr, selectedLeaveTypeID]);

  let generateLeaves = leaveTypesArr.map((lt) => {
    return (
      <option value={lt.isAbsence ? lt.absenceTypeID : lt.leaveTypeID}>
        {lt.name}
      </option>
    );
  });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-available-request-types`,
        {
          userID: memoVals.showAddLeaveModal.userID,
          absencesToo: true,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          let leaves = response.data.custom;
          let absences = response.data.absences;
          let newArr = [
            { name: "- - - - Other leave types:", leaveTypeID: "null" },
            ...leaves,
            {
              name: "- - - - Absences:",
              absenceTypeID: "null",
              isAbsence: true,
            },
            ...absences,
          ];
          setLeaveTypesArr(newArr);
          setWorkingPattern(response.data.workingPattern);
          setSchedule(response.data.schedule);
          setContactedMpw(response.data.contractedMpw);

          if (!showAddLeaveModal.openedFromModal) {
            if (memoVals.showAddLeaveModal.isAbsence) {
              let absFilt = absences.filter((item) => {
                return item.name === "Paid absence";
              });
              if (absFilt[0]) {
                setSelectedLeaveTypeID(absFilt[0].absenceTypeID);
              } else {
                setSelectedLeaveTypeID(absences[0].absenceTypeID);
              }
            }
          }
        }
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  useEffect(() => {
    // clean not needed
    setUpdateStrips(!updateStrips);
  }, [schedule]);
  useEffect(() => {
    // clean not needed
    if (
      firstDateInput &&
      firstDateInput[0] === "2" &&
      lastDateInput &&
      lastDateInput[0] === "2"
    ) {
      let startObj = new Date(
        dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(firstDateInput)
        )
      );
      let endObj = new Date(
        dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(lastDateInput)
        )
      );

      if (endObj.getTime() < startObj.getTime() && !ongoing) {
        setStartsBeforeEnds(true);
      } else {
        setStartsBeforeEnds(false);
      }
    }
  }, [firstDateInput, lastDateInput]);

  useEffect(() => {
    // clean not needed
    if (firstDateInput && firstDateInput[0] === "2") {
      let dateObj = new Date(
        dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(firstDateInput)
        )
      );

      if (workingPattern === "fixedScheduled" && schedule[0]) {
        let schedFilt = schedule.filter((item) => {
          return item.dayN === dateObj.getDay();
        });

        if (schedFilt[0]) {
          setHalfStartStrip(
            `${schedFilt[0].start.substr(0, 2)}:${schedFilt[0].start.substr(
              2,
              2
            )}`
          );

          setHalfStartStrip2(
            `${schedFilt[0].firstHalfEnd.substr(
              0,
              2
            )}:${schedFilt[0].firstHalfEnd.substr(2, 2)}`
          );
        } else {
        }
      } else {
        setHalfStartStrip("Half way through");
      }
    }

    if (lastDateInput && lastDateInput[0] === "2") {
      let dateObj = new Date(
        dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(lastDateInput)
        )
      );

      if (workingPattern === "fixedScheduled" && schedule[0]) {
        let schedFilt = schedule.filter((item) => {
          return item.dayN === dateObj.getDay();
        });

        if (schedFilt[0]) {
          setHalfEndStrip(
            `${schedFilt[0].secondHalfStart.substr(
              0,
              2
            )}:${schedFilt[0].secondHalfStart.substr(2, 2)}`
          );
          setHalfEndStrip2(
            `${schedFilt[0].end.substr(0, 2)}:${schedFilt[0].end.substr(2, 2)}`
          );
        } else {
          setHalfEndStrip("");
        }
      } else {
        setHalfEndStrip("Half way through");
      }
    }
  }, [updateStrips]);

  let generateOverlaps = overlapsWithOtherReqs.map((item) => {
    return (
      <p
        className="overlapReqAddLeaveItem"
        onClick={() => {
          console.log({ item });
          // open req here
          memoVals.setShowReqMgrModal((x) => false);

          memoVals.setShowReqMgrModal((x) => item.reqID);
          memoVals.setShowAddLeaveModal(false);
        }}
      >
        {dateStringer.printedDateFromDs(item.ds)}
      </p>
    );
  });
  let closeModal = () => {
    setShowAddLeaveModal((x) => {
      return {
        show: false,
        startDs: "",
        userID: "",
        leaveTypeID: "annLeave",
      };
    });
  };

  useEffect(() => {
    // clean not needed
    setNeedToRecalc(true);
  }, [halfEnd, halfStart]);

  let calc = (canxToken) => {
    setNeedToRecalc(false);
    setCalcLoading(true);
    let startDs = dateStringer.createStringFromDateInput(firstDateInput);

    let endDs = dateStringer.createStringFromDateInput(lastDateInput);

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let todayDs = dateStringer.createStringFromTimestamp(today.getTime());

    axios
      .post(
        `${serverURL}/get-leave-request-pre-data`,
        {
          passedUserID: memoVals.showAddLeaveModal.userID,
          leaveTypeID: selectedLeaveTypeID,
          startDs,
          endDs: ongoing ? todayDs : endDs,
          startHalf: halfStart,
          endHalf: halfEnd,
          editingReqID: memoVals.showAddLeaveModal.reqID,
          ongoing,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: canxToken ? canxToken : "",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (
            response.data.message2 &&
            response.data.message2 === "no leave required"
          ) {
            setNoLeaveRequired(true);
          } else {
            setNoLeaveRequired(false);
            setCalced(!calced);
            console.log(response.data);
            let overlaps = [];
            if (
              response.data.overlapsWithOtherReqs &&
              response.data.overlapsWithOtherReqs[0]
            ) {
              response.data.overlapsWithOtherReqs.forEach((ds) => {
                let addToArr = true;

                if (
                  ds === startDs &&
                  halfStart &&
                  !response.data.closedHalfStarts.includes(ds)
                ) {
                  addToArr = false;
                }

                if (
                  ds === endDs &&
                  halfEnd &&
                  !response.data.closedHalfEnds.includes(ds)
                ) {
                  addToArr = false;
                }

                if (addToArr) {
                  overlaps.push(ds);
                }
              });
              setOverlapsWithOtherReqs(
                overlaps
                //   response.data.overlapsWithOtherReqs
              );
            } else {
              setOverlapsWithOtherReqs([]);
            }

            if (response.data.allowanceObject) {
              if (workingPattern === "fixedScheduled") {
                let daysOrHours = response.data.allowanceObject.daysOrHours;
                let daysPre =
                  daysOrHours === "days" || selectedLeaveTypeID === "annLeave"
                    ? dateStringer.convertMinsToDays(
                        response.data.allowanceObject.remainingAlowanceMinsPre,
                        response.data.allowanceObject.dailyMins,
                        true
                      )
                    : dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.remainingAlowanceMinsPre
                      );

                let daysPost =
                  daysOrHours === "days" || selectedLeaveTypeID === "annLeave"
                    ? dateStringer.convertMinsToDays(
                        response.data.allowanceObject
                          .remainingAllowanceMinsPost,
                        response.data.allowanceObject.dailyMins,
                        true
                      )
                    : dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.remainingAllowanceMinsPost
                      );

                let totAllow =
                  daysOrHours === "days" || selectedLeaveTypeID === "annLeave"
                    ? dateStringer.convertMinsToDays(
                        response.data.allowanceObject.totalAllow,
                        response.data.allowanceObject.dailyMins,
                        true
                      )
                    : dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.totalAllow
                      );

                if (
                  daysOrHours === "days" &&
                  selectedLeaveTypeID !== "annLeave"
                ) {
                  totAllow = `${response.data.allowanceObject.totalAllow} day${
                    response.data.allowanceObject.totalAllow === 1 ||
                    response.data.allowanceObject.totalAllow === 0.5
                      ? ""
                      : "s"
                  }`;

                  daysPre = `${
                    response.data.allowanceObject.remainingAlowanceMinsPre
                  } day${
                    response.data.allowanceObject.remainingAlowanceMinsPre ===
                      1 ||
                    response.data.allowanceObject.remainingAlowanceMinsPre ===
                      0.5
                      ? ""
                      : "s"
                  }`;

                  daysPost = `${
                    response.data.allowanceObject.remainingAllowanceMinsPost
                  } day${
                    response.data.allowanceObject.remainingAllowanceMinsPost ===
                      1 ||
                    response.data.allowanceObject.remainingAllowanceMinsPost ===
                      0.5
                      ? ""
                      : "s"
                  }`;
                }
                //     let totAllowMins =  daysOrHours === "days" ||
                // selectedLeaveTypeID === "annLeave"
                let showSub = false;
                if (
                  selectedLeaveTypeID === "annLeave" &&
                  workingPattern === "fixedScheduled"
                ) {
                  showSub = true;
                }

                setAllowData({
                  daysOrHours,
                  willUseMins: response.data.totalMinsWithoutExcess,
                  year: response.data.cliYr,
                  totAllow: totAllow,
                  currRemain: daysPre,
                  remainingPost: daysPost,
                  sufficient:
                    response.data.totalMinsWithoutExcess === 0 ||
                    response.data.allowanceObject.remainingAllowanceMinsPost >=
                      0
                      ? true
                      : false,

                  totAllowMins: showSub
                    ? response.data.allowanceObject.totalAllow
                    : 0,
                  currRemainMins: showSub
                    ? response.data.allowanceObject.remainingAlowanceMinsPre
                    : 0,
                  remainingPostMins: showSub
                    ? response.data.allowanceObject.remainingAllowanceMinsPost
                    : 0,
                });
              }

              if (workingPattern === "fixedVariable") {
                let daysOrHours = response.data.allowanceObject.daysOrHours;
                let daysPre =
                  daysOrHours === "days" && selectedLeaveTypeID !== "annLeave"
                    ? `${
                        response.data.allowanceObject.remainingAlowanceMinsPre
                      } ${
                        response.data.allowanceObject.remainingAlowanceMinsPre >
                        1
                          ? "days"
                          : "day"
                      }`
                    : dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.remainingAlowanceMinsPre
                      );

                let daysPost =
                  daysOrHours === "days" && selectedLeaveTypeID !== "annLeave"
                    ? `${
                        response.data.allowanceObject.remainingAllowanceMinsPost
                      } ${
                        response.data.allowanceObject
                          .remainingAlowanceMinsPost > 1
                          ? "days"
                          : "day"
                      }`
                    : dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.remainingAllowanceMinsPost
                      );

                let totAllow =
                  daysOrHours === "days" && selectedLeaveTypeID !== "annLeave"
                    ? `${response.data.allowanceObject.totalAllow}  ${
                        response.data.allowanceObject.totalAllow > 1
                          ? "days"
                          : "day"
                      }`
                    : dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.totalAllow
                      );

                setAllowData({
                  daysOrHours,
                  willUseMins: response.data.totalMinsWithoutExcess,

                  year: response.data.cliYr,
                  totAllow: totAllow,
                  currRemain: daysPre,
                  remainingPost: daysPost,
                  sufficient:
                    response.data.totalMinsWithoutExcess === 0 ||
                    response.data.allowanceObject.remainingAllowanceMinsPost >=
                      0
                      ? true
                      : false,
                });
              }

              if (workingPattern === "zero") {
                let daysOrHours = response.data.allowanceObject.daysOrHours;

                let daysPre =
                  daysOrHours === "hours"
                    ? dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.remainingAlowanceMinsPre
                      )
                    : `${
                        response.data.allowanceObject.remainingAlowanceMinsPre
                      } ${
                        response.data.allowanceObject.remainingAlowanceMinsPre >
                        1
                          ? "days"
                          : "day"
                      }`;
                // dateStringer.formatMinsDurationToHours(
                //   response.data.allowanceObject.remainingAlowanceMinsPre
                // );

                let daysPost =
                  daysOrHours === "hours"
                    ? dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.remainingAllowanceMinsPost
                      )
                    : `${
                        response.data.allowanceObject.remainingAllowanceMinsPost
                      } ${
                        response.data.allowanceObject
                          .remainingAllowanceMinsPost > 1
                          ? "days"
                          : "day"
                      }`;

                let totAllow =
                  daysOrHours === "hours"
                    ? dateStringer.formatMinsDurationToHours(
                        response.data.allowanceObject.totalAllow
                      )
                    : `${response.data.allowanceObject.totalAllow} ${
                        response.data.allowanceObject.totalAllow > 1
                          ? "days"
                          : "day"
                      }`;

                // let totAllow = dateStringer.formatMinsDurationToHours(
                //   response.data.allowanceObject.totalAllow
                // );

                let avgMpd = response.data.avgMpd;

                let after =
                  response.data.allowanceObject.remainingAllowanceMinsPost;

                let suff;
                if (after < 0) {
                  suff = false;
                } else {
                  suff = true;
                }

                // allowanceObject": {
                //   "remainingAlowanceMinsPre": 1,
                //   "remainingAllowanceMinsPost": 0,
                //   "daysOrHours": "days",
                //   "dailyMins": 420,
                //   "leaveTypeName": "Appointments",
                //   "totalAllow": 1
                // zorro
                setAllowData({
                  daysOrHours,
                  willUseMins: response.data.totalMinsWithoutExcess,

                  year: response.data.cliYr,
                  totAllow: totAllow,
                  currRemain: daysPre,
                  remainingPost: daysPost,
                  suff,
                  sufficient:
                    response.data.totalMinsWithoutExcess === 0 || suff
                      ? // response.data.allowanceObject.remainingAllowanceMinsPost >=
                        //   0
                        true
                      : false,
                  avgMpd,
                });
              }
            }

            setCalcLoading(false);
          }
        }
      });
  };

  let countReqDays = (firstInput, lastInput, forceOngoing) => {
    if (firstInput) {
      setNeedToRecalc(true);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let todayDs = dateStringer.createStringFromTimestamp(today.getTime());
      axios
        .post(
          `${serverURL}/count-req-days`,
          {
            startDs: dateStringer.createStringFromDateInput(firstInput),
            endDs:
              forceOngoing === "forceOngoing"
                ? todayDs
                : dateStringer.createStringFromDateInput(lastInput),
            userID: memoVals.showAddLeaveModal.userID,
            ongoing:
              forceOngoing === "forceNotOngoing"
                ? false
                : forceOngoing === "forceOngoing"
                ? true
                : ongoing,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          setReqDur(response.data.counter);

          setHalfStartAvailable(response.data.halfStartAvailable);
          if (!response.data.halfStartAvailable) {
            setHalfStart(false);
          }
          setHalfEndAvailable(response.data.halfEndAvailable);

          if (!response.data.halfEndAvailable) {
            setHalfEnd(false);
          }

          setOverlapsRenewal(response.data.overlapsRenewal);
          setRenewalOverlap(response.data.overlapsRenewal);
          setShowOverlapsRenewalModal(response.data.overlapsRenewal);

          setTooFarAhead(response.data.tooFarAhead);
          setTooFuture(response.data.tooFarAhead);

          setShowTooFutureModal(
            !response.data.overlapsRenewal && response.data.tooFarAhead
          );
          setUpdateStrips(!updateStrips);
        });
    }
  };

  useEffect(() => {
    // clean not needed
    countReqDays(firstDateInput, lastDateInput);
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`addLeaveModalBox ${
          memoVals.mobModal
            ? "addLeaveModalBoxMob mobModalShoulder slideUp"
            : "zoomIn"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {/* TOO FAR AHEAD MODAL */}
        {showTooFutureModal ? (
          <div
            className="overlapsRenewalModalUnderlay"
            onClick={() => {
              setShowTooFutureModal(false);
            }}
          >
            <div
              className="overlapsRenewalModalBox"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {" "}
              <p className="overlapsRenTxt">
                Leave can only be requested for dates up to{" "}
                {dateStringer.printedDateWithYrFromDs(tooFuture)}.<br />
                <br />
                The system administrator has yet to make leave available for
                next year.
              </p>
              <a
                className="addLeaveModalInfoBtn"
                href={`${websiteURL}/support/leave-dates-not-yet-available`}
                target="_blank"
              >
                Info{" "}
                <img
                  src={popup}
                  alt="Info"
                  className="addLeaveModalInfoPopUp"
                />
              </a>{" "}
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setShowTooFutureModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* END OF TOO FAR AHEAD MODAL */}

        {/* NEED TO RECALC MODAL */}
        {showNeedToCalcModal ? (
          <div
            className="overlapsRenewalModalUnderlay"
            onClick={() => {
              setShowNeedToCalcModal(false);
            }}
          >
            <div
              className="overlapsRenewalModalBox"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {" "}
              {showNeedToCalcModal === "overlap" ? (
                <p className="overlapsRenTxt">
                  You cannot add a leave that overlaps another leave item.
                  <br />
                  <br />
                  Either amend or remove the other item, or amend this one.
                </p>
              ) : (
                <p className="overlapsRenTxt">
                  Hit the <span className="calcSpanModalTxt">calculate</span>{" "}
                  button before adding this leave item to ensure there are no
                  issues
                </p>
              )}
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setShowNeedToCalcModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* END OF TOO FAR AHEAD MODAL */}

        {/* OVERLAPS RENEWAL MODAL */}
        {showOverlapsRenewalModal ? (
          <div
            className="overlapsRenewalModalUnderlay"
            onClick={() => {
              setShowOverlapsRenewalModal(false);
            }}
          >
            <div
              className="overlapsRenewalModalBox"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {" "}
              <p className="overlapsRenTxt">
                The period cannot overlap{" "}
                {dateStringer.printedDateWithYrFromDs(
                  dateStringer.createStringFromTimestamp(overlapsRenewal)
                )}{" "}
                as it would fall across two seperate company years. <br />
                <br />
                Make two leave items either side of the new company year
                instead.
              </p>
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setShowOverlapsRenewalModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* END OF OVERLAPS RENEWAL MODAL */}
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperAddLeaveModal"></div>
        )}
        <div
          className="addLeaveModalBoxHeader spaceBetween"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={navyClose}
            alt="Close"
            className="addLeaveModalHeaderboxCloseImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p
            className="addLeaveModalHeaderBoxTitle"
            onClick={() => {
              console.log(memoVals.showAddLeaveModal);
              console.log({ selectedLeaveTypeID });
            }}
          >
            {memoVals.showAddLeaveModal.editing ? "Edit the" : "Add"}{" "}
            {isAbsence ? "absence" : "leave"} for {fName}
          </p>
          {/* <img
            src={navyClose}
            alt="Close"
            className="addLeaveModalHeaderboxCloseImg invis"
          />{" "} */}
        </div>
        <div
          className={`editShiftModalBody addLeaveModalBody ${
            !memoVals.mobModal ? "desktopAddLeaveModalBody" : ""
          }`}
          onClick={() => {}}
          ref={bodyScroll}
        >
          <div className="addLeaveDatesRow">
            <p className="addLeaveDatesTitle">Leave / Absence type</p>
            <select
              className="addLeaveModalLeaveSelectorDropdown"
              value={selectedLeaveTypeID}
              onChange={(e) => {
                if (e.target.value === "null") {
                  setSelectedLeaveTypeID("annLeave");
                  setRate(1);
                  setIsPaid(true);
                  setLtName("Annual leave");
                  setIsAbsence(false);
                } else {
                  setNeedToRecalc(true);
                  setSelectedLeaveTypeID(e.target.value);

                  if (e.target.value === "annLeave") {
                    setIsPaid(true);
                    setRate(1);
                    setLtName("Annual leave");

                    setIsAbsence(false);
                  } else {
                    let paid = true;
                    let rate = 1;
                    let name = "";
                    let isAbs = false;
                    leaveTypesArr.forEach((lt) => {
                      console.log(
                        "absID: ",
                        lt.absenceTypeID,
                        "leavetypeID:",
                        lt.leaveTypeID,
                        "E:",
                        e.target.value
                      );
                      if (
                        (lt.leaveTypeID && lt.leaveTypeID === e.target.value) ||
                        (lt.absenceTypeID &&
                          lt.absenceTypeID === e.target.value)
                      ) {
                        console.log({ lt });
                        paid = lt.paid;
                        rate = lt.rate || 1;

                        name = lt.name;
                        if (lt.isAbsence) {
                          isAbs = true;
                        }
                      }
                    });
                    console.log({ name, isAbs, rate, paid });
                    setIsPaid(paid);
                    setRate(rate);
                    setIsAbsence(isAbs);
                    setLtName(name);
                  }
                }
              }}
            >
              <option value="annLeave">Annual leave</option>
              {generateLeaves}
            </select>
          </div>
          <div className="addLeaveDatesRow noBorderBottom">
            <div className="addLeaveDatesRowSection addLeaveFirstDayBorderBottom">
              <div className="addLeaveFirstDayRowFlex">
                <div className="addLeaveFirstDayRowFlexLeft">
                  {" "}
                  <p className="addLeaveDatesTitle">First day</p>
                  <input
                    id="startInput"
                    type="date"
                    className={`addLeaveDateInput ${
                      startsBeforeEnds ? "startsBeforeEndsAddLeave" : ""
                    }`}
                    value={firstDateInput}
                    onChange={(e) => {
                      setHalfEnd(false);
                      setHalfStart(false);
                      let ds = dateStringer.createStringFromDateInput(
                        e.target.value
                      );
                      console.log("e: ", e.target.value);
                      setLastDateUpdated(true);

                      setFirstDateInput(e.target.value);
                      setLastDateInput(e.target.value);

                      if (e.target.value[0] === "2") {
                        setStartValid(true);
                        setEndValid(true);

                        // if (lastDateInput[0] === "2") {
                        countReqDays(e.target.value, e.target.value);
                        // }
                        if (
                          dateStringer.createTimestampFromString(ds) <
                          dateStringer.createTimestampFromString(userStartDate)
                        ) {
                          setStartDateIsBeforeUserStarted(true);
                        } else {
                          setStartDateIsBeforeUserStarted(false);
                        }

                        if (
                          dateStringer.createTimestampFromString(ds) >
                          dateStringer.createTimestampFromString(maxDs)
                        ) {
                          setStartDateIsTooFarAhead(true);
                        } else {
                          setStartDateIsTooFarAhead(false);
                        }
                      } else {
                        setStartValid(false);
                      }
                    }}
                  ></input>
                </div>
                {workingPattern === "fixedScheduled" ? (
                  <div className="fixedSchedLeaveDiv">
                    <div
                      className={`lastDayRFx ${
                        !halfStartAvailable || !startValid || !endValid
                          ? "invis"
                          : ""
                      } `}
                    >
                      <div
                        className={`addLeaveFixedSchedParent ${
                          window.innerWidth < 390 ? "x7733" : ""
                        }`}
                      >
                        <p className="leaveStartsWhen">Starting</p>
                        <div className="addLeaveFixSchedStripFlex">
                          <p
                            className={`addLeaveHalfStripTxtFs ${
                              !halfStart
                                ? "selectedFsHalfStrip selectedFsHalfStripNonHalf"
                                : ""
                            }`}
                            onClick={() => {
                              if (halfStart) {
                                setHalfStart(false);
                                setReqDur(reqDur + 0.5);
                              }
                            }}
                          >
                            {halfStartStrip}
                          </p>
                          <p
                            className={`addLeaveHalfStripTxtFs ${
                              halfStart ? "selectedFsHalfStrip" : ""
                            }`}
                            onClick={() => {
                              if (!halfStart) {
                                setHalfStart(true);
                                setReqDur(reqDur - 0.5);
                              }
                            }}
                          >
                            {halfStartStrip2}
                          </p>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`lastDayR ${
                      !halfStartAvailable || !startValid || !endValid
                        ? "invis"
                        : ""
                    } ${halfStart ? "addLeaveHalfStartActive" : ""}`}
                    onClick={() => {
                      if (!halfStart) {
                        setReqDur(reqDur - 0.5);
                      }

                      if (halfStart) {
                        setReqDur(reqDur + 0.5);
                      }

                      setHalfStart(!halfStart);
                    }}
                  >
                    <p className="addLeaveHalfStripTxt">{halfStartStrip}</p>

                    {/*  KNOB BEGIN */}
                    <div className="knobContainer addLeaveHalfStartKnob">
                      <div
                        className={`switchKnob ${
                          halfStart ? "knobOn" : "knobOff"
                        }`}
                      ></div>

                      <div className="knobBg"></div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                )}
              </div>
              {halfStart ? (
                <p
                  className={`startsOrEndsHalf ${
                    workingPattern !== "zero" ? "fxLastHalfOffCorrection" : ""
                  } `}
                >
                  Last half off
                </p>
              ) : (
                ""
              )}
              {startDateIsBeforeUserStarted || startDateIsTooFarAhead ? (
                <p className="invalidDateInputAddLeaveModalStart">
                  {startDateIsTooFarAhead
                    ? "Start date is too far ahead"
                    : `Start date cannot start before ${fName} joined the company`}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="addLeaveDatesRowSection">
              <div className="lastDayDivWithNoEnd">
                <div className="lastDayL">
                  {" "}
                  <p className="addLeaveDatesTitle">Last day</p>
                  {ongoing ? (
                    <p className="ongoingInputTxt">
                      The end date will be determined at a later date
                    </p>
                  ) : (
                    <input
                      type="date"
                      className={`addLeaveDateInput ${
                        openedToEndOngoing ? "openedToEndOngoingDateInput" : ""
                      } ${startsBeforeEnds ? "startsBeforeEndsAddLeave" : ""}`}
                      id="endInput"
                      value={lastDateInput}
                      onChange={(e) => {
                        setHalfEnd(false);
                        setHalfStart(false);
                        let ds = dateStringer.createStringFromDateInput(
                          e.target.value
                        );
                        console.log("e: ", e.target.value);
                        setLastDateUpdated(true);
                        setLastDateInput(e.target.value);

                        if (e.target.value[0] === "2") {
                          setEndValid(true);
                          if (firstDateInput[0] === "2") {
                            countReqDays(firstDateInput, e.target.value);
                          }
                          if (
                            dateStringer.createTimestampFromString(ds) <
                            dateStringer.createTimestampFromString(
                              userStartDate
                            )
                          ) {
                            setEndDateIsBeforeUserStarted(true);
                          } else {
                            setEndDateIsBeforeUserStarted(false);
                          }

                          if (
                            dateStringer.createTimestampFromString(ds) >
                            dateStringer.createTimestampFromString(maxDs)
                          ) {
                            setEndDateIsTooFarAhead(true);
                          } else {
                            setEndDateIsTooFarAhead(false);
                          }
                        } else {
                          setEndValid(false);
                        }
                      }}
                    ></input>
                  )}
                </div>
                {workingPattern === "fixedScheduled" ? (
                  <div className="lastDayRparent">
                    <div
                      className={`lastDayRFx ${
                        !halfEndAvailable || ongoing || !endValid || !startValid
                          ? "invis"
                          : ""
                      }`}
                      onClick={() => {
                        if (halfEnd) {
                          setReqDur(reqDur + 0.5);
                        } else {
                          setReqDur(reqDur - 0.5);
                        }

                        setHalfEnd(!halfEnd);
                      }}
                    >
                      <div
                        className={`addLeaveFixedSchedParent ${
                          window.innerWidth < 390 ? "x7733" : ""
                        }`}
                      >
                        <p className="leaveStartsWhen">Ending</p>
                        <div className="addLeaveFixSchedStripFlex">
                          <p
                            className={`addLeaveHalfStripTxtFs ${
                              halfEnd ? "selectedFsHalfStrip" : ""
                            }`}
                            onClick={() => {
                              if (!halfEnd) {
                                setHalfEnd(true);
                                setReqDur(reqDur - 0.5);
                              }
                            }}
                          >
                            {halfEndStrip}
                          </p>
                          <p
                            className={`addLeaveHalfStripTxtFs ${
                              !halfEnd
                                ? "selectedFsHalfStrip selectedFsHalfStripNonHalf"
                                : ""
                            }`}
                            onClick={() => {
                              if (halfEnd) {
                                setReqDur(reqDur + 0.5);

                                setHalfEnd(false);
                              }
                            }}
                          >
                            {halfEndStrip2}
                          </p>
                        </div>
                      </div>
                    </div>
                    {halfEnd ? (
                      <p className="startsOrEndsHalf firstHalfOffTxtV">
                        First half off
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="lastDayRparent">
                    <div
                      className={`lastDayR ${
                        !halfEndAvailable || ongoing || !endValid || !startValid
                          ? "invis"
                          : ""
                      } ${halfEnd ? "addLeaveHalfStartActive" : ""}`}
                      onClick={() => {
                        if (halfEnd) {
                          setReqDur(reqDur + 0.5);
                        } else {
                          setReqDur(reqDur - 0.5);
                        }
                        setHalfEnd(!halfEnd);
                      }}
                    >
                      <p className="addLeaveHalfStripTxt">{halfEndStrip}</p>

                      {/*  KNOB BEGIN */}
                      <div className="knobContainer addLeaveHalfStartKnob">
                        <div
                          className={`switchKnob ${
                            halfEnd ? "knobOn" : "knobOff"
                          }`}
                        ></div>

                        <div className="knobBg"></div>
                      </div>
                      {/*  KNOB END */}
                    </div>
                    {halfEnd ? (
                      <p className="startsOrEndsHalf firstHalfOffTxtV">
                        First half off
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}{" "}
              </div>

              <div className="addLeaveOngoingAndDataDiv">
                <div
                  className={`ongoingBtn ${ongoing ? "ongoingActive" : ""}`}
                  onClick={() => {
                    if (!ongoing) {
                      setOverlapsRenewal(false);

                      setHalfEnd(false);
                      setEndValid(true);
                      setStartsBeforeEnds(false);

                      countReqDays(
                        firstDateInput,
                        firstDateInput,
                        "forceOngoing"
                      );
                    } else {
                      //   if (
                      //     !lastDateInput ||
                      //     (lastDateInput && lastDateInput[0] !== "2")
                      //   ) {
                      //     setEndValid(false);
                      //   }
                      if (openedToPossiblyEndOngoing) {
                        console.log("set end date to today because");
                        let todayMidnight = new Date();
                        todayMidnight.setHours(0, 0, 0, 0);
                        let newLastDateInput =
                          dateStringer.createDateInputFromDateString(
                            dateStringer.createStringFromTimestamp(
                              todayMidnight.getTime()
                            )
                          );

                        setLastDateInput(newLastDateInput);
                        countReqDays(
                          firstDateInput,
                          newLastDateInput,
                          "forceNotOngoing"
                        );

                        setEndValid(true);
                      } else {
                        setLastDateInput(firstDateInput);
                        countReqDays(
                          firstDateInput,
                          firstDateInput,
                          "forceNotOngoing"
                        );
                        setEndValid(true);
                      }
                    }
                    setOngoing(!ongoing);
                  }}
                >
                  On-going{" "}
                  <img
                    src={ongoing ? checkboxInstall2 : checkboxInstall1}
                    alt="Select"
                    className="ongoingSelectImg"
                  />
                </div>
                <p
                  className="addLeaveNoteBtn"
                  onClick={() => {
                    if (showAddNote) {
                      setAddNoteVal("");
                    }
                    setShowAddNote(!showAddNote);
                  }}
                >
                  Add note{" "}
                  {showAddNote ? (
                    <img
                      src={closeGrey}
                      alt="Cancel add note"
                      className="showAddNoteCloseImg"
                    />
                  ) : (
                    ""
                  )}
                </p>
              </div>
              {showAddNote ? (
                <div className="addLeaveAddNoteDiv">
                  <div className="addLeaveNoteAddNoteDivLeft">
                    <p className="addLeaveNoteTitle">Note</p>

                    <textarea
                      className="addNoteNoteTextAreaInput"
                      value={addNoteVal}
                      onChange={(e) => {
                        setAddNoteVal(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="addLeaveNoteAddNoteDivRight">
                    <p
                      className="showAddNoteAddNoteBtn"
                      onClick={() => {
                        setAddNoteVal("");
                      }}
                    >
                      Clear
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {singleDayBothHalfs ||
              overlapsRenewal ||
              tooFarAhead ||
              startsBeforeEnds ? (
                startsBeforeEnds ? (
                  <p
                    className={`addLeaveDataNoLeaveRequired singleDayBothHalfs`}
                  >
                    Check your dates. The leave period cannot end before it
                    starts.
                  </p>
                ) : tooFarAhead ? (
                  <p className="addLeaveDataNoLeaveRequired singleDayBothHalfs">
                    This period is too far ahead. The dates in this period have
                    not yet been made available by the system admin.{" "}
                    <a
                      className="redDocsBtn"
                      href={`${websiteURL}/support/leave-dates-not-yet-available`}
                      target="_blank"
                    >
                      Info{" "}
                      <img
                        src={popup}
                        alt="Info"
                        className="redDocsBtnPopUpImg"
                      />
                    </a>
                  </p>
                ) : overlapsRenewal ? (
                  <p
                    className={`addLeaveDataNoLeaveRequired singleDayBothHalfs`}
                  >
                    The leave period cannot overlap two years. Make two leave
                    items instead.
                  </p>
                ) : (
                  <p
                    className={`addLeaveDataNoLeaveRequired ${
                      singleDayBothHalfs ? "singleDayBothHalfs" : ""
                    }`}
                  >
                    {singleDayBothHalfs
                      ? "Invalid period. You cannot set a single day of leave to start and end half way through, as this creates no leave."
                      : "No leave required"}
                  </p>
                ) // henry
              ) : startValid && endValid ? (
                <div className="addLeaveDataDiv">
                  <div className="addLeaveDataDivRow">
                    <p className="addLeaveDataDivRowTitle">
                      {isAbsence ? "Absence" : "Leave"} type
                    </p>
                    <p className="addLeaveDataDivRowValue">{ltName}</p>
                  </div>
                  <div className="addLeaveDataDivRow">
                    <p className="addLeaveDataDivRowTitle">
                      Contracted weekly hours
                    </p>
                    <p className="addLeaveDataDivRowValue">
                      {dateStringer.formatMinsDurationToHours(contactedMpw)}
                    </p>
                  </div>
                  {/* : ""} */}
                  {!noLeaveRequired ? (
                    <div className="addLeaveDataDivRow">
                      <p className="addLeaveDataDivRowTitle">
                        {ongoing
                          ? `${
                              isAbsence ? "Absence" : "Leave"
                            } duration up to now`
                          : `${isAbsence ? "Absence" : "Leave"} duration`}
                      </p>
                      <p className="addLeaveDataDivRowValue">
                        {reqDur} {reqDur === 1 ? "day" : "days"}
                        {/* {ongoing ? "Not yet known" : reqDur} */}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {!noLeaveRequired ? (
                    <div className="addLeaveDataDivRow noBorderBottom">
                      <p className="addLeaveDataDivRowTitle">Paid leave</p>
                      <p className="addLeaveDataDivRowValue">
                        {isPaid ? `Yes - ${rate}x` : "No"}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {!isAbsence && (needToRecalc || noLeaveRequired) ? (
                    <div className="recalcAddLeaveDiv">
                      <p></p>
                      <p
                        className={`recalcAddLeaveBtn`}
                        onClick={() => {
                          calc();
                        }}
                      >
                        <img
                          src={refresh}
                          alt="Calculate"
                          className="calcRefreshIconImg"
                        />
                        Calculate
                      </p>
                    </div>
                  ) : !noLeaveRequired && !isAbsence ? (
                    <div
                      className={`addLeaveDataDiv whiteBg ${
                        !calcLoading
                          ? allowData.sufficient && !overlapsWithOtherReqs[0]
                            ? "greenNestedAddLeaveBorderLeft"
                            : "redNestedAddLeaveBorderLeft"
                          : ""
                      }`}
                      onClick={() => {
                        console.log(allowData);
                      }}
                    >
                      {overlapsWithOtherReqs[0] ? (
                        <div className="addLeaveOverlapsOtherReqsDiv">
                          <p className="addLeaveOverlapsOtherReqsTitle">
                            <img
                              src={warning}
                              alt="Warning - overlap"
                              className="overlapWarnIcon"
                            />
                            Overlaps {fName}
                            {fName[fName.length - 1] === "s" ? "'" : "'s"} other
                            leave:
                          </p>
                          <div className="addLeaveOverlappingDsArr">
                            {generateOverlaps}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {overlapsWithOtherReqs[0] ? (
                        ""
                      ) : (
                        <div
                          className={`addLeaveDataDivRow ${
                            allowData.totAllowMins ? "noBorderBottom" : ""
                          }`}
                        >
                          <p className="addLeaveDataDivRowTitle">
                            {/* {fName}
                          {fName[fName.length - 1] === "s" ? "'" : "'s"}{" "} */}
                            Total {allowData.year} allowance
                          </p>
                          <p className="addLeaveDataDivRowValue">
                            {calcLoading ? "-" : allowData.totAllow}
                          </p>
                        </div>
                      )}
                      {overlapsWithOtherReqs[0] || !allowData.totAllowMins ? (
                        ""
                      ) : (
                        <div className="addLeaveDataDivRow addLeaveFixedSchedDivParent">
                          <p className="addLeaveDataDivRowTitle"></p>
                          <p className="addLeaveDataDivRowValue addLeaveFixedSchedSub">
                            {calcLoading
                              ? "-"
                              : dateStringer.formatMinsDurationToHours(
                                  allowData.totAllowMins
                                )}
                          </p>
                        </div>
                      )}
                      {overlapsWithOtherReqs[0] || workingPattern !== "zero" ? (
                        ""
                      ) : (
                        <div className="addLeaveDataDivRow noBorderBottom">
                          <p className="addLeaveDataDivRowTitle">
                            Daily average hours
                          </p>
                          <p className="addLeaveDataDivRowValue">
                            {calcLoading
                              ? "-"
                              : dateStringer.formatMinsDurationToHours(
                                  allowData.avgMpd
                                )}
                          </p>
                        </div>
                      )}{" "}
                      {overlapsWithOtherReqs[0] ||
                      workingPattern !== "zero" ||
                      calcLoading ? (
                        ""
                      ) : (
                        <div className="addLeaveDataDivRow zeroAllowAddLeaveExplainDiv">
                          <p className="addLeaveDataDivRowTitle zeroAllowAddLeaveExplainTxt">
                            The average daily hours {fName} works based on the
                            reference period
                          </p>
                          <a
                            href={`${websiteURL}/support/average-hours-based-on-reference-period`}
                            target="_blank"
                            className="zeroAllowAddLeaveExplainInfoBtn"
                          >
                            Info{" "}
                            <img
                              src={popup}
                              alt="Info"
                              className="zeroAllowAddLeaveExplainImg"
                            />
                          </a>
                        </div>
                      )}
                      {overlapsWithOtherReqs[0] || calcLoading ? (
                        ""
                      ) : (
                        <div
                          className={`addLeaveDataDivRow ${
                            allowData.currRemainMins ? "noBorderBottom" : ""
                          }`}
                        >
                          <p className="addLeaveDataDivRowTitle">
                            Currently remaining{" "}
                          </p>
                          <p className="addLeaveDataDivRowValue">
                            {calcLoading ? "-" : allowData.currRemain}
                          </p>
                        </div>
                      )}
                      {overlapsWithOtherReqs[0] || !allowData.currRemainMins ? (
                        ""
                      ) : (
                        <div className="addLeaveDataDivRow addLeaveFixedSchedDivParent">
                          <p className="addLeaveDataDivRowTitle"></p>
                          <p className="addLeaveDataDivRowValue addLeaveFixedSchedSub">
                            {calcLoading
                              ? "-"
                              : dateStringer.formatMinsDurationToHours(
                                  allowData.currRemainMins
                                )}
                          </p>
                        </div>
                      )}
                      {overlapsWithOtherReqs[0] ? (
                        ""
                      ) : (
                        <div
                          className={`addLeaveDataDivRow ${
                            overlapsWithOtherReqs[0] ||
                            !allowData.remainingPostMins
                              ? ""
                              : "noBorderBottom"
                          }`}
                        >
                          <p className="addLeaveDataDivRowTitle">
                            {ongoing
                              ? "Leave to use up to now"
                              : "This leave will use"}
                          </p>
                          <p className="addLeaveDataDivRowValue">
                            {calcLoading
                              ? "-"
                              : selectedLeaveTypeID !== "annLeave" &&
                                allowData.daysOrHours === "days"
                              ? `${reqDur} day${
                                  reqDur === 1 || reqDur === 0.5 ? "" : "s"
                                }`
                              : dateStringer.formatMinsDurationToHours(
                                  allowData.willUseMins
                                )}
                          </p>
                        </div>
                      )}
                      {overlapsWithOtherReqs[0] ? (
                        ""
                      ) : (
                        <div
                          className={`none addLeaveDataDivRow ${
                            allowData.remainingPostMins ? "noBorderBottom" : ""
                          }`}
                        >
                          <p className="addLeaveDataDivRowTitle">
                            New remaining{" "}
                            {/* <span className="withOutSpan">with this leave</span> */}
                          </p>
                          <p className="addLeaveDataDivRowValue">
                            {calcLoading ? "-" : allowData.remainingPost}
                          </p>
                        </div>
                      )}
                      {overlapsWithOtherReqs[0] ||
                      !allowData.remainingPostMins ? (
                        ""
                      ) : (
                        <div className="addLeaveDataDivRow addLeaveFixedSchedDivParent">
                          <p className="addLeaveDataDivRowTitle"></p>
                          <p className="addLeaveDataDivRowValue addLeaveFixedSchedSub">
                            {calcLoading
                              ? "-"
                              : dateStringer.formatMinsDurationToHours(
                                  allowData.remainingPostMins
                                )}
                          </p>
                        </div>
                      )}
                      {/* {overlapsWithOtherReqs[0] || calcLoading ? (
                        ""
                      ) : (
                        <div className="addLeaveDataDivRow">
                          <p className="addLeaveDataDivRowTitle">Overlaps </p>
                          <p className="addLeaveDataDivRowValue">
                            {overlapsWithOtherReqs[0] ? "Yes" : "No"}
                          </p>
                        </div>
                      )} */}
                      {overlapsWithOtherReqs[0] || calcLoading ? (
                        ""
                      ) : (
                        <div className="addLeaveDataDivRow noBorderBottom">
                          <p className="addLeaveDataDivRowTitle">
                            Sufficient allowance{" "}
                          </p>
                          <p
                            className="addLeaveDataDivRowValue"
                            onClick={() => {
                              console.log({ allowData });
                            }}
                          >
                            {allowData.sufficient ? "Yes" : "No"}{" "}
                            <img
                              src={allowData.sufficient ? unitTick : unitCross}
                              alt="Sufficient"
                              className="sufficientImg"
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {noLeaveRequired && !calcLoading ? (
                    <p className={`addLeaveDataNoLeaveRequired `}>
                      No leave required
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <p className="singleDayBothHalfs">
                  {!startValid && !endValid
                    ? "Start and end dates are invalid"
                    : startValid && !endValid
                    ? "End date is invalid"
                    : "Start date is invalid"}
                </p>
              )}
              {endDateIsBeforeUserStarted || endDateIsTooFarAhead ? (
                <p className="invalidDateInputAddLeaveModalStart">
                  {endDateIsTooFarAhead
                    ? "End date is too far ahead"
                    : `End date cannot start before ${fName} joined the company`}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="addLeaveToUserModalFooter flexDirectionRow">
          <p
            className="cancelAddLeaveModalBtn"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </p>
          {!startsBeforeEnds &&
          !singleDayBothHalfs &&
          !overlapsRenewal &&
          !tooFarAhead ? (
            <p
              className={`saveAddLeaveToUserBtn ${
                (needToRecalc || overlapsWithOtherReqs[0]) && !isAbsence
                  ? "needToRecalcAddBtn"
                  : ""
              } ${saveLoading ? "none" : ""}`}
              onClick={() => {
                if (needToRecalc && !isAbsence) {
                  setShowNeedToCalcModal(true);
                } else {
                  if (overlapsWithOtherReqs[0]) {
                    setShowNeedToCalcModal("overlap");
                  } else {
                    // add leave req axios here
                    if (memoVals.showAddLeaveModal.editing) {
                      setSaveLoading(true);
                      console.log("EDIT LEAVE REUQEST DATES");
                      axios
                        .post(
                          `${serverURL}/regenerate-single-request`,
                          {
                            reqID: memoVals.showAddLeaveModal.reqID,
                            reqUserID: memoVals.showAddLeaveModal.userID,
                            start:
                              dateStringer.createStringFromDateInput(
                                firstDateInput
                              ),
                            end: dateStringer.createStringFromDateInput(
                              lastDateInput
                            ),
                            step: 1,
                            startHalf: halfStart,
                            endHalf: halfEnd,
                            regen: true,
                            makePendAgain: false,
                            leaveTypeID: selectedLeaveTypeID,
                            isAbsence,
                            ongoing,
                            force: memoVals.showAddLeaveModal.editing,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            console.log(response.data);
                            memoVals.setUpdateTabData(
                              (x) => !memoVals.updateTabData
                            );
                            setShowAddLeaveModal((x) => {
                              return { show: false, update: true };
                            });
                            memoVals.setShowReqMgrModal(
                              (x) => memoVals.showAddLeaveModal.reqID
                            );
                            memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                            setSaveLoading(false);
                          }

                          if (response.data.message === "overlap") {
                            setShowOverlapsRenewalModal(true);
                          }
                        });
                    } else {
                      setSaveLoading(true);

                      axios
                        .post(
                          `${serverURL}/add-leave-on-user`,
                          {
                            firstDs:
                              dateStringer.createStringFromDateInput(
                                firstDateInput
                              ),
                            lastDs:
                              dateStringer.createStringFromDateInput(
                                lastDateInput
                              ),
                            startHalf: halfStart,
                            endHalf: halfEnd,
                            leaveTypeID: selectedLeaveTypeID,
                            isAbsence,
                            ongoing,
                            userID: memoVals.showAddLeaveModal.userID,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                            note: addNoteVal,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            console.log(response.data);
                            setShowAddLeaveModal((x) => {
                              return { show: false, update: true };
                            });
                            memoVals.setShowReqMgrModal(
                              (x) => response.data.reqID
                            );
                            memoVals.setUpdateInfo((x) => !memoVals.updateInfo);
                            memoVals.setUpdateTabData(
                              (x) => !memoVals.updateTabData
                            );
                            setSaveLoading(false);
                          }
                        });
                    }
                  }
                }
              }}
            >
              {memoVals.showAddLeaveModal.editing ? "Save" : "Add"}
            </p>
          ) : (
            <p></p>
          )}
          {saveLoading ? (
            <img
              src={blueLoader}
              alt="Loading"
              className="addLeaveLoadingImg"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default AddLeaveModal;
