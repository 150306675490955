import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// clean not needed
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";
import close from "../../img/general/whitecross.svg";

import returnArrow from "../../img/general/returnArrow.svg";
import edit from "../../img/general/edit.svg";

import "../../styles/calendar.css";
import { Modal } from "bootstrap";

const MgrReqsDataBox = ({ modal }) => {
  const {
    hoursDataLoading,
    setHoursDataLoading,
    setShowReqStats,
    setShowEditUser,
    setReqsPage,
    updatedReq,
    device,
  } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);
  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      hoursDataLoading,
      setHoursDataLoading,
      setShowReqStats,
      setShowEditUser,
      setReqsPage,
      updatedReq,
      device,
      checkUserState,
      mob,
      mobModal,
    }),
    [
      hoursDataLoading,
      setHoursDataLoading,
      setShowReqStats,
      setShowEditUser,
      setReqsPage,
      updatedReq,
      device,
      checkUserState,
      mob,
      mobModal,
    ]
  );

  let [availableYears, setAvailableYears] = useState([]);
  let [selectedYear, setSelectedYear] = useState("");
  let [fName, setFName] = useState("");
  let [tilBlocked, setTilBlocked] = useState(false); // on the user

  useEffect(() => {
    // clean not needed
    memoVals.setHoursDataLoading((x) => true);
  }, []);

  let [customQty, setCustomQty] = useState(0);
  let [allStaffData, setAllStaffData] = useState([]);
  let [tilMinsAvail, setTilMinsAvail] = useState(0);
  let [hoveredUserID, setHoveredUserID] = useState("");

  let [prevYear, setPrevYear] = useState(false);
  useEffect(() => {
    // clean not needed

    if (parseInt(selectedYear) < memoVals.checkUserState.currClientYear) {
      setPrevYear(true);
    } else {
      setPrevYear(false);
    }
  }, [selectedYear]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      if (memoVals.mob) {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", function (event) {
          window.history.pushState(null, document.title, window.location.href);
          if (selectedUserID !== "all") {
            setSelectedUserID("all");
          } else {
            memoVals.setShowReqStats((x) => false);
          }
        });
      }
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-available-years`,
        {
          userOnly: selectedUserID === "all" ? false : true,
          userID: selectedUserID === "all" ? "" : selectedUserID,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          console.log(response.data.ay);
          setAvailableYears(response.data.data);
          setSelectedYear(response.data.currentYear);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [data, setData] = useState({});
  let [selectedUserID, setSelectedUserID] = useState("all");

  useEffect(() => {
    // clean not needed
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.userID && params.fName && params.lName) {
      console.log("params: ", params);
      memoVals.setReqsPage((x) => {
        return {
          userID: params.userID,
          fName: params.fName,
          lName: params.lName,
        };
      });
      setFName(params.fName);
      setSelectedUserID(params.userID);
    }
  }, []);
  let [staff, setStaff] = useState([]);
  let [enableTil, setEnableTil] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (selectedYear) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      memoVals.setHoursDataLoading((x) => true);
      axios
        .post(
          `${serverURL}/get-requests-data-box`,
          {
            year: selectedYear,
            annLeaveOnly: false,
            userID: selectedUserID,
            getTilInfo: true,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setData(response.data.allowanceData);
            setAllStaffData(response.data.allowanceData || []);
            if (response.data.staff) {
              setStaff(response.data.staff);
            }
            setTilMinsAvail(response.data.availableToil);
            if (
              response.data.allowanceData &&
              response.data.allowanceData.customData
            ) {
              setCustomQty(response.data.allowanceData.customData.length);
            }
            setEnableTil(response.data.enableTil);
            memoVals.setHoursDataLoading((x) => false);
            setTilBlocked(response.data.blockTil);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedYear, selectedUserID, memoVals.updatedReq]);

  let breakName = (val) => {
    let output = "";
    if (val) {
      let spaceFound = false;
      for (let i = 0; i < val.length; i++) {
        if (!spaceFound) {
          if (val[i] !== " ") {
            output += val[i];
          } else {
            spaceFound = true;
          }
        }
      }

      if (output.length > 11) {
        return true;
      } else {
        return false;
      }
    }
  };

  let [showTopWhiteBorder, setShowTopWhiteBorder] = useState(false);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 140) {
      memoVals.setShowReqStats((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [showCustom, setShowCustom] = useState(modal ? true : true);
  let generateCustoms = useMemo(() => {
    return data && data.customData
      ? data.customData.map((item) => {
          return (
            <div
              className={`customMappedItemMgr ${
                memoVals.mob ? "noBorderTopBottom" : ""
              }`}
            >
              <div className="desktopDataRow mgrDesktopDataRow desktopRowThickBorder customDataBoxTitleMgr dataBoxBorderLeft">
                <p
                  className={`desktopDataTitle boldDataVal customLeaveDataTitle colPur
                ${breakName(item.name) ? "font12px" : ""}
                `}
                  onClick={() => {}}
                >
                  {item.name}
                </p>
                <p
                  className={`${
                    memoVals.hoursDataLoading ? "none" : ""
                  } desktopDataValue boldDataVal`}
                >
                  {item.daysOrHours !== "days"
                    ? dateStringer.formatMinsDurationToHours(item.totAllow)
                    : `${item.totAllow} ${
                        item.totAllow === 0 || item.totAllow > 1
                          ? "days"
                          : "day"
                      }`}
                </p>{" "}
                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
              <div className="desktopDataRow mgrDesktopDataRow">
                <p className="desktopDataTitle">Used</p>
                <div className={`desktopDataValue boldDataVal`}>
                  {item.daysOrHours === "days" ? (
                    <p>
                      {item.used}{" "}
                      {item.used > 1 || item.used === 0 ? "days" : "day"}
                    </p>
                  ) : (
                    <p>{dateStringer.formatMinsDurationToHours(item.used)}</p>
                  )}
                </div>

                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
              <div className="desktopDataRow noBorder">
                <p className="desktopDataTitle">Remaining</p>
                <div
                  className={`${
                    memoVals.hoursDataLoading ? "none" : ""
                  } desktopDataValue boldDataVal customRemainingRow colourPink`}
                >
                  {item.daysOrHours === "days" ? (
                    <p>
                      {item.remaining}{" "}
                      {item.remaining > 1 || item.remaining === 0
                        ? "days"
                        : "day"}
                    </p>
                  ) : (
                    <p>
                      {dateStringer.formatMinsDurationToHours(item.remaining)}
                    </p>
                  )}
                  {item.daysOrHours === "days" && !prevYear ? (
                    <span
                      className="leaveDataMinsSpan"
                      onClick={() => {
                        console.log(
                          "remaining: ",
                          item.remaining,
                          data.userDailyMins
                        );
                      }}
                    >
                      {dateStringer.formatMinsDurationToHours(
                        item.remaining * data.userDailyMins
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
            </div>
          );
        })
      : "";
  }, [
    showCustom,
    selectedUserID,
    selectedYear,
    data.customData,
    hoursDataLoading,
  ]);
  let customScrollBox = useRef();
  useEffect(() => {
    // clean not needed
    if (customScrollBox.current) {
      customScrollBox.current.scrollTop = 0;
    }
  }, [showCustom, selectedUserID, selectedYear]);

  // stella
  let generateAllStaffData = useMemo(() => {
    return allStaffData[0]
      ? allStaffData.map((item) => {
          return (
            <div
              className="mgrReqDataBoxAllStaffDataItem"
              onClick={() => {
                setFName(item.fName);
                setSelectedUserID(item.userID);
              }}
              onMouseEnter={() => {
                if (!memoVals.mob) {
                  setHoveredUserID(item.userID);
                }
              }}
              onMouseLeave={() => {
                setHoveredUserID("");
              }}
            >
              <div
                className={`mgrReqFullName ${
                  memoVals.mob ? "txtFontSize16px" : ""
                }`}
              >
                <p className="mgrReqDataName">
                  {item.fName} {modal ? item.lName : item.lName[0]}
                </p>
                {/* <p className="mgrReqJt1">{item.jt}</p> */}
                <p className="mgrReqJt2">{item.teamName}</p>
              </div>

              <p
                className={`allStaffReqsDataValue  ${
                  memoVals.mob ? "txtFontSize16px" : ""
                }`}
              >
                {dateStringer.convertMinsToDays(
                  item.data.remaining,
                  Math.floor(item.data.userDailyMins),
                  true
                )}
              </p>
            </div>
          );
        })
      : "";
  }, [allStaffData]);

  let generateAvailableYears = useMemo(() => {
    return (
      availableYears &&
      availableYears.map((item) => (
        <option value={item} key={item}>
          {item}
        </option>
      ))
    );
  }, [availableYears]);

  let generateStaff = useMemo(() => {
    return (
      staff &&
      staff.map((item) => {
        return (
          <option value={item.userID} key={item.userID}>
            {item.fName} {item.lName}
          </option>
        );
      })
    );
  }, [staff, selectedUserID]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let [isAtTop, setIsAtTop] = useState(true);
  // master return
  return (
    <div
      className={`desktopDataBox noBottomPadding ${
        modal ? "modalReqDataBox" : "reqsDesktopBoxScrollMgr"
      } ${
        customQty === 0 && selectedUserID !== "all"
          ? "noCustomsDataBoxSize"
          : ""
      } mgrReqsDataBoxColours`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {memoVals.mobModal && (
        <div className="modalSwiper modalSwiperStaffAllow"></div>
      )}
      <div
        className="desktopDataBoxHeader reqsSideBoxHeader"
        onTouchStart={handleDown}
        onTouchMove={memoVals.mobModal ? handleMove : null}
        onTouchEnd={memoVals.mobModal ? handleUp : null}
        onMouseDown={memoVals.mobModal ? handleDown : null}
        onMouseMove={memoVals.mobModal ? handleMove : null}
        onMouseUp={memoVals.mobModal ? handleUp : null}
      >
        <div className="mgrAllowsHeaderLeft">
          {" "}
          {modal ? (
            <img
              src={close}
              alt="Close"
              className="closeMgrReqsBtn"
              onClick={() => {
                memoVals.setShowReqStats((x) => false);
              }}
            />
          ) : (
            ""
          )}
          <p
            className={modal ? "modalMgrReqsTitle" : ""}
            onClick={() => {
              if (modal) {
                memoVals.setShowReqStats((x) => false);
              }
            }}
          >
            {" "}
            Staff allowances
          </p>
        </div>

        <select
          className="reqsDataBoxYearSelect reqDataBoxPurpleBg"
          value={selectedYear}
          onChange={(e) => {
            setSelectedYear(e.target.value);
          }}
        >
          {generateAvailableYears}
        </select>
      </div>
      <div className="mgrReqDataBoxFilterBar">
        {selectedUserID !== "all" ? (
          <img
            src={returnArrow}
            alt="Back"
            className="backSelUserIDReqDataImg"
            onClick={() => {
              const urlSearchParams = new URLSearchParams(
                window.location.search
              );
              const params = Object.fromEntries(urlSearchParams.entries());

              if (params && params.userID) {
                window.location.href = "/team-requests";
              } else {
                setFName("");
                memoVals.setReqsPage((x) => "team");
                setSelectedUserID("all");
              }
            }}
          />
        ) : (
          ""
        )}
        {params && params.userID ? (
          <p className="reqsIndividualNameTitle">
            {params.fName} {params.lName}
          </p>
        ) : (
          <select
            value={selectedUserID}
            className={`mgrReqDataBoxDropdown reqDataBoxPurpleBg ${
              selectedUserID !== "all" ? "showBackIcon" : ""
            }`}
            onChange={(e) => {
              staff.forEach((name) => {
                if (name.userID === e.target.value) {
                  setFName(name.fName);
                }
              });
              setSelectedUserID(e.target.value);
            }}
          >
            <option value="all">-- Staff you manage</option>
            {generateStaff}
          </select>
        )}
      </div>
      {selectedUserID === "all" ? (
        <p className="teamReqsAnnLeaveUsersTitle">Remaining annual leave</p>
      ) : (
        ""
      )}
      {selectedUserID === "all" ? (
        <div
          onScroll={(e) => {
            if (e.target.scrollTop > 0) {
              setShowTopWhiteBorder(true);
              setIsAtTop(false);
            } else {
              setShowTopWhiteBorder(false);
              setIsAtTop(true);
            }
          }}
          calc-leave-req-days-duration
          onTouchStart={memoVals.mobModal && isAtTop ? handleDown : null}
          onTouchMove={memoVals.mobModal && isAtTop ? handleMove : null}
          onTouchEnd={memoVals.mobModal && isAtTop ? handleUp : null}
          onMouseDown={memoVals.mobModal && isAtTop ? handleDown : null}
          onMouseMove={memoVals.mobModal && isAtTop ? handleMove : null}
          onMouseUp={memoVals.mobModal && isAtTop ? handleUp : null}
          className={`allStaffAnnLeaveDiv ${
            modal ? "allStaffAnnLeaveDivModal" : ""
          } ${
            !memoVals.mob && !modal ? "allStaffAnnLeaveDivModalDesktop" : ""
          } ${showTopWhiteBorder ? "topWhiteBorder" : ""} ${
            memoVals.device.vhIssue
              ? "mgrReqsVHissueSideBoxHeight"
              : `${modal ? "x49829752" : "x178141"}`
          }`}
        >
          {generateAllStaffData}
        </div>
      ) : (
        ""
      )}
      {selectedUserID !== "all" ? (
        <div className="topMgrLeaveInfo">
          <div className="desktopDataRow mgrDesktopDataRow desktopRowThickBorder mgrReqDataBoxHighlightTitleBar dataBoxBorderLeft">
            <p
              className={`desktopDataTitle boldDataVal customLeaveDataTitle colPur x660o`}
              onClick={() => {}}
            >
              {data
                ? dateStringer.convertMinsToDays(
                    data.totalAllowMins || 0,
                    Math.floor(data.userDailyMins),
                    true
                  ).length >= 12 && !modal
                  ? "Ann. leave"
                  : "Annual leave"
                : ""}
            </p>
            <p
              className={`${
                memoVals.hoursDataLoading ? "none" : ""
              } desktopDataValue boldDataVal`}
            >
              {data &&
              (!prevYear ||
                (prevYear &&
                  dateStringer.convertMinsToDays(
                    data.totalAllowMins,
                    Math.floor(data.userDailyMins),
                    true
                  ) ===
                    dateStringer.formatMinsDurationToHours(
                      data.totalAllowMins
                    )))
                ? dateStringer.convertMinsToDays(
                    data.totalAllowMins,
                    Math.floor(data.userDailyMins),
                    true
                  )
                : ""}

              <span
                className={`leaveDataMinsSpan ${
                  dateStringer.convertMinsToDays(
                    data.totalAllowMins,
                    Math.floor(data.userDailyMins),
                    true
                  ) ===
                  dateStringer.formatMinsDurationToHours(data.totalAllowMins)
                    ? "none"
                    : `${prevYear ? "makeTxtPrimary" : ""}`
                }`}
              >
                {data &&
                  dateStringer.formatMinsDurationToHours(data.totalAllowMins)}
              </span>
            </p>{" "}
            <p
              className={`${
                memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
              }`}
            >
              -
            </p>
          </div>
          <div className="desktopDataRow mgrDesktopDataRow remainingMgrDataReqTitle">
            <p className={`desktopDataTitle`}>Used</p>
            <p
              className={`${
                memoVals.hoursDataLoading ? "none" : ""
              } desktopDataValue boldDataVal`}
            >
              {data &&
              (!prevYear ||
                (prevYear &&
                  dateStringer.formatMinsDurationToHours(data.usedMins) ===
                    dateStringer.convertMinsToDays(
                      data.usedMins,
                      Math.floor(data.userDailyMins),
                      true
                    )))
                ? dateStringer.convertMinsToDays(
                    data.usedMins,
                    Math.floor(data.userDailyMins),
                    true
                  )
                : ""}
              <span
                className={`leaveDataMinsSpan ${
                  dateStringer.formatMinsDurationToHours(data.usedMins) ===
                  dateStringer.convertMinsToDays(
                    data.usedMins,
                    Math.floor(data.userDailyMins),
                    true
                  )
                    ? "none"
                    : `${prevYear ? "makeTxtPrimary" : ""}`
                }`}
              >
                {data && dateStringer.formatMinsDurationToHours(data.usedMins)}
              </span>
            </p>{" "}
            <p
              className={`${
                memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
              }`}
            >
              -
            </p>
          </div>
          <div className="desktopDataRow noBorder">
            <p className={`desktopDataTitle x37467283`}>Remaining</p>
            <p
              className={`${
                memoVals.hoursDataLoading ? "none" : ""
              } desktopDataValue boldDataVal colourPink`}
            >
              {data &&
              (!prevYear ||
                (prevYear &&
                  dateStringer.formatMinsDurationToHours(data.remaining) ===
                    dateStringer.convertMinsToDays(
                      data.remaining,
                      Math.floor(data.userDailyMins),
                      true
                    )))
                ? dateStringer.convertMinsToDays(
                    data.remaining,
                    Math.floor(data.userDailyMins),
                    true
                  )
                : ""}
              <span
                className={`leaveDataMinsSpan ${
                  dateStringer.formatMinsDurationToHours(data.remaining) ===
                  dateStringer.convertMinsToDays(
                    data.remaining,
                    Math.floor(data.userDailyMins),
                    true
                  )
                    ? "none"
                    : `${prevYear ? "makeTxtPrimary" : ""}`
                }`}
              >
                {data && dateStringer.formatMinsDurationToHours(data.remaining)}
              </span>
            </p>{" "}
            <p
              className={`${
                memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
              }`}
            >
              -
            </p>
          </div>
          {/* - - - - */}
          {/* <div className="emptyDesktopDataRow"></div> */}
        </div>
      ) : (
        ""
      )}
      {!modal && selectedUserID !== "all" ? (
        <div className={`otherCustomsContainer`}>
          <div className="fixedReqUserContainer">
            <p
              className="amendAllowBtn"
              onClick={(e) => {
                e.stopPropagation();
                memoVals.setShowEditUser((x) => selectedUserID);
              }}
            >
              {dateStringer.possession(fName)} allowances{" "}
              <img
                src={edit}
                alt="Amend allowances"
                className="amendAllowsImg"
              />
            </p>
            {/* <img
              src={teamSolo}
              alt="view user"
              className="viewUserOnReqSideBoxImg"
              onClick={() => {
                memoVals.setShowEditUser(x =>selectedUserID);
              }}
            /> */}
          </div>
          {/* <div
            className={`reqsDataBoxShowCustomsBtn mgrShowCustomsBtn ${
              showCustom ? "reqsDataBoxShowCustomsBtnActiveMgr" : ""
            }  ${customQty === 0 ? "invis" : ""}`}
            onClick={() => {
              setShowCustom(!showCustom);
            }}
          >
            <span className="customQtySpan">{customQty}</span> other leave type
            {customQty > 1 ? "s" : ""}
            {showCustom ? (
              <img
                src={purplecross}
                alt="Close"
                className="closeOtherLeaveTypesImg"
              />
            ) : (
              <img
                src={downWhite}
                alt="Close"
                className="closeOtherLeaveTypesImg"
              />
            )}
          </div> */}
        </div>
      ) : (
        ""
      )}
      {showCustom && selectedUserID !== "all" && !modal ? (
        <div
          className={`showCustomDataSplitterMgr ${
            modal ? "modalShowCustomDataSplitter" : ""
          }`}
        ></div>
      ) : (
        ""
      )}
      {showCustom && selectedUserID !== "all" ? (
        <div
          ref={customScrollBox}
          className={`reqDataCustomScrollBoxMgr ${
            modal ? "modalCustomScrollBox" : ""
          }`}
        >
          {/* --------------------------------------------------------------------- */}
          {enableTil ? (
            <div
              className={`customMappedItemMgr ${
                memoVals.mob ? "noBorderTopBottom" : ""
              } x485839823`}
            >
              <div className="desktopDataRow mgrDesktopDataRow desktopRowThickBorder customDataBoxTitleMgr dataBoxBorderLeft">
                <p
                  className={`desktopDataTitle boldDataVal customLeaveDataTitle txtColPur`}
                  onClick={() => {}}
                >
                  Time off in lieu
                </p>
                <p
                  className={`${
                    memoVals.hoursDataLoading ? "none" : ""
                  } desktopDataValue boldDataVal`}
                ></p>{" "}
                <p
                  className={`${
                    memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : ""
                  }`}
                >
                  {/*  KNOB BEGIN */}
                  <div
                    className="knobContainer enableTilKnob"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/toggle-user-til-blocked`,
                          {
                            userID: selectedUserID,
                            data: !tilBlocked,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setTilBlocked(!response.data.blockTil);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <div
                      className={`switchKnob ${
                        !tilBlocked ? "knobOn" : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                  {/*  KNOB END */}
                </p>
              </div>
              <div
                className={`desktopDataRow mgrDesktopDataRow ${
                  tilBlocked ? "noBorder" : ""
                }`}
              >
                <p className="desktopDataTitle txtColPur2">Enabled</p>
                <div className={`desktopDataValue boldDataVal txtColPur3`}>
                  {memoVals.hoursDataLoading ? "" : !tilBlocked ? "Yes" : "No"}
                </div>

                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
              <div
                className={`desktopDataRow mgrDesktopDataRow noBorder ${
                  tilBlocked ? "none" : ""
                }`}
              >
                <p className="desktopDataTitle txtColPur2">Available to take</p>
                <div
                  className={`desktopDataValue boldDataVal txtColPur3 ${
                    tilMinsAvail < 0 ? "colourRed" : ""
                  }`}
                >
                  {tilMinsAvail < 0 ? "-" : ""}
                  {dateStringer.formatMinsDurationToHours(tilMinsAvail)}
                </div>

                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
              <div className="desktopDataRow noBorder none">
                <p className="desktopDataTitle">Remaining</p>
                <div
                  className={`${
                    memoVals.hoursDataLoading ? "none" : ""
                  } desktopDataValue boldDataVal customRemainingRow colourPink`}
                >
                  hurdy
                  {/* {item.daysOrHours === "days" && !prevYear ? (
                  <span
                    className="leaveDataMinsSpan"
                    onClick={() => {
                      console.log(
                        "remaining: ",
                        item.remaining,
                        data.userDailyMins
                      );
                    }}
                  >
                    {dateStringer.formatMinsDurationToHours(
                      item.remaining * data.userDailyMins
                    )}
                  </span>
                ) : (
                  "" */}
                  {/* )} */}
                </div>{" "}
                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* --------------------------------------------------------------------- */}
          {generateCustoms}
        </div>
      ) : (
        ""
      )}
      {modal ? (
        <div
          className={`mgrAllowsFooter ${
            selectedUserID !== "all" ? "noBorder" : ""
          }`}
        >
          <p></p>{" "}
          <p
            className={`closeEditShiftBtn closeMgrPurpleBtn`}
            onClick={() => {
              memoVals.setShowReqStats((x) => false);
            }}
          >
            Close
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MgrReqsDataBox;
