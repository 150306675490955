import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wed 24 jul 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23
// image ready 4 oct 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import dateStringer from "../../../tools/dateStringer";
import serverURL from "../../../serverURL";
import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";
import "../../../styles/calendar.css";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import whitecross from "../../../img/general/whitecross.svg";
import shiftChangeArrow from "../../../img/general/shiftChangeArrow.svg";
import shiftChangeArrowGreen from "../../../img/general/shiftChangeArrowGreen.svg";
import shiftChangeArrowRed from "../../../img/general/shiftChangeArrowRed.svg";
import blueLoader from "../../../img/general/blueLoader.gif";
import checkboxInstall1 from "../../../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../../../img/general/checkboxInstall2.svg";
import greyTick from "../../../img/general/greyTick.svg";
import unitTick from "../../../img/general/unitTick.svg";
import darkRedCross from "../../../img/general/darkRedCross.svg";
import rotaClock2 from "../../../img/general/rotaClock2.svg";
import rotaCoffee2 from "../../../img/general/rotaCoffee2.svg";
import filterWhite from "../../../img/general/filterWhite.svg";
import greyThreeDots from "../../../img/general/greyThreeDots.svg";
import time from "../../../img/general/time.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import doubleTick from "../../../img/general/doubleTick.svg";
import lightBlueQuestionMark from "../../../img/general/lightBlueQuestionMark.svg";
import teamIcon from "../../../img/general/teamIcon2.svg";

const UserShifts = ({ sideBoxData, my, setMy }) => {
  let todayMidnight = new Date();
  todayMidnight.setHours(0, 0, 0, 0);
  let [today00Ts, setToday00Ts] = useState(todayMidnight.getTime());

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 19) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={teamIcon}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lightBlueQuestionMark}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={doubleTick}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrow}
        alt="Shift Change Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowGreen}
        alt="Shift Change Arrow Green"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowRed}
        alt="Shift Change Arrow Red"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="Blue Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall2}
        alt="Checkbox Install 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyTick}
        alt="Grey Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={darkRedCross}
        alt="Dark Red Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock2}
        alt="Rota Clock 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee2}
        alt="Rota Coffee 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterWhite}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyThreeDots}
        alt="Grey Three Dots"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={time}
        alt="Time"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  const { setCalPage, updateChanges, setUpdateChanges, reverse } =
    useContext(CalendarContext);

  const {
    updateShifts,
    setUpdateShifts,
    setShowMyShiftModal,
    availableMonths,
    setPage,
    device,
    countMyChanges,
    setCountMyChanges,
    showChanges,
    setShowChanges,
    removeMyChangeEditID,
    updateMyShiftsAndTils,
    notYetAccepted,
    setNotYetAccepted,
    showYetToAcceptOnly,
    setShowYetToAcceptOnly,
    acceptedShiftID,
    setAcceptedShiftID,
    declinedShiftID,
    setDeclinedShiftID,
    setIndicate,
  } = useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      updateShifts,
      setUpdateShifts,
      setShowMyShiftModal,
      availableMonths,
      setPage,
      device,
      countMyChanges,
      setCountMyChanges,
      showChanges,
      setShowChanges,
      removeMyChangeEditID,
      updateMyShiftsAndTils,
      notYetAccepted,
      setNotYetAccepted,
      showYetToAcceptOnly,
      setShowYetToAcceptOnly,
      acceptedShiftID,
      setAcceptedShiftID,
      declinedShiftID,
      setDeclinedShiftID,
      setIndicate,
      mob,
      full,
      fullButNarrow,
      setCalPage,
      updateChanges,
      setUpdateChanges,
      reverse,
    }),
    [
      updateShifts,
      setUpdateShifts,
      setShowMyShiftModal,
      availableMonths,
      setPage,
      device,
      countMyChanges,
      setCountMyChanges,
      showChanges,
      setShowChanges,
      removeMyChangeEditID,
      updateMyShiftsAndTils,
      notYetAccepted,
      setNotYetAccepted,
      showYetToAcceptOnly,
      setShowYetToAcceptOnly,
      acceptedShiftID,
      setAcceptedShiftID,
      declinedShiftID,
      setDeclinedShiftID,
      setIndicate,
      mob,
      full,
      fullButNarrow,
      setCalPage,
      updateChanges,
      setUpdateChanges,
      reverse, //
    ]
  );

  useEffect(() => {
    if (typeof memoVals.showChanges === "boolean" && memoVals.showChanges) {
      memoVals.setShowChanges((x) => false);
    }

    if (memoVals.showYetToAcceptOnly) {
      memoVals.setShowYetToAcceptOnly((x) => false);
    }
  }, []);

  // shifts, team-shifts, clock-ons
  let [loadChange, setLoadChange] = useState(false);

  // shifts, swaps, stats
  useEffect(() => {
    // clean not needed
    if (memoVals.showChanges) {
      // memoVals.setShowChanges(x =>false);
    }
  }, []);

  let [allShiftsAndTils, setAllShiftsAndTils] = useState([]);
  useEffect(() => {
    if (memoVals.acceptedShiftID) {
      let newArr = allShiftsAndTils.map((x) => {
        if (
          (x.data.shiftID && x.data.shiftID === memoVals.acceptedShiftID) ||
          (x.data.tilID && x.data.tilID === memoVals.acceptedShiftID)
        ) {
          return Object.assign({}, x, {
            accepted: dateStringer.createStringFromTimestamp(
              new Date().getTime()
            ),
          });
        }
        return x;
      });
      setAllShiftsAndTils(newArr);
      memoVals.setNotYetAccepted((x) => memoVals.notYetAccepted - 1);

      memoVals.setAcceptedShiftID((x) => "");
    }

    if (memoVals.declinedShiftID) {
      let newArr = [];
      allShiftsAndTils.forEach((x) => {
        if (x.data.shiftID && x.data.shiftID === memoVals.declinedShiftID) {
        } else if (x.data.tilID && x.data.tilID === memoVals.declinedShiftID) {
        } else {
          newArr.push(x);
        }
      });
      setAllShiftsAndTils(newArr);
      memoVals.setNotYetAccepted((x) => memoVals.notYetAccepted - 1);
      memoVals.setDeclinedShiftID((x) => "");
    }
  }, [memoVals.acceptedShiftID, memoVals.declinedShiftID]);
  let [showFilter, setShowFilter] = useState(false);
  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);
  let [sureCancel, setSureCancel] = useState({ show: false });
  let [newChanges, setNewChanges] = useState(false);

  let [changesData, setChangesData] = useState([]);

  let [showGhostModal, setShowGhostModal] = useState(false);
  let [ghostTxt, setGhostTxt] = useState("");

  useEffect(() => {
    // clean not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.changes) {
      console.log("params: ", params);
      memoVals.setShowChanges((x) => true);
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }

    console.log("!!!!!!", { params });

    if (params && params.shiftID && params.type && !params.mgr) {
      if (params.type === "unknown") {
        axios
          .post(
            `${serverURL}/check-shift-til`,
            {
              shiftID: params.shiftID,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              memoVals.setShowMyShiftModal((x) => {
                return {
                  unitType: response.data.type,
                  unitID: params.shiftID,
                  otherUser: params.other,
                };
              });
            }
          });
      } else {
        memoVals.setShowMyShiftModal((x) => {
          return {
            unitType: params.type,
            unitID: params.shiftID,
            showNotes: params.notes,
            otherUser: params.other,
          };
        });
      }
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const cancelSource1 = axios.CancelToken.source();

    let m = my.substr(0, 2);
    let y = my.substr(2, 2);

    if (m === "00" && y === "00") {
      let today = new Date();
      m = today.getMonth();
      y = today.getFullYear();
    }

    if (!memoVals.showChanges) {
      axios
        .post(
          `${serverURL}/get-my-shifts-and-til`,
          {
            month: parseInt(m),
            year: parseInt(y),
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            showYetToAcceptOnly,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            memoVals.setNotYetAccepted((x) => response.data.notYetAccepted);
            setAllShiftsAndTils(response.data.data);
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
            memoVals.setCountMyChanges((x) => response.data.countMyChanges);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [
    memoVals.updateShifts,
    memoVals.showChanges,
    memoVals.updateMyShiftsAndTils,
    memoVals.showYetToAcceptOnly,
  ]);

  let hoursScrollBox = useRef();
  useEffect(() => {
    // clean not needed
    hoursScrollBox.current.scrollTop = 0;
  }, [my]);

  let generateMonthOptions = memoVals.availableMonths.map((month, i) => {
    let formatMonth = "Jan";
    let formatYear = month.substr(2, 2);
    if (month.substr(0, 2) === "00") {
      formatMonth = "January";
    }
    if (month.substr(0, 2) === "01") {
      formatMonth = "February";
    }
    if (month.substr(0, 2) === "02") {
      formatMonth = "March";
    }
    if (month.substr(0, 2) === "03") {
      formatMonth = "April";
    }
    if (month.substr(0, 2) === "04") {
      formatMonth = "May";
    }
    if (month.substr(0, 2) === "05") {
      formatMonth = "June";
    }
    if (month.substr(0, 2) === "06") {
      formatMonth = "July";
    }
    if (month.substr(0, 2) === "07") {
      formatMonth = "August";
    }
    if (month.substr(0, 2) === "08") {
      formatMonth = "September";
    }
    if (month.substr(0, 2) === "09") {
      formatMonth = "October";
    }
    if (month.substr(0, 2) === "10") {
      formatMonth = "November";
    }
    if (month.substr(0, 2) === "11") {
      formatMonth = "December";
    }

    return (
      <option key={i} value={month}>
        {formatMonth} {formatYear}
      </option>
    );
  });

  allShiftsAndTils.sort(function (a, b) {
    //   if (!memoVals.reverse) {
    //     return b.startTs - a.startTs;
    //   } else {
    return a.startTs - b.startTs;
    //   }
  });

  useEffect(() => {
    // clean not needed
    document.title = "My hours - FlowRota";

    memoVals.setCalPage((x) => "shifts");
    if (memoVals.showChanges) {
      memoVals.setPage((x) => "reqs");
    } else {
      memoVals.setPage((x) => "shifts");
    }
  }, []);

  // let renderMyShiftModal = () => {
  //   if (showMyShiftModal) {
  //     return <MyShiftModal unitType={itemType} unitID={itemID} />;
  //   }
  // };

  // ------------------------------------------------------------------------
  let [loadingCancel, setLoadingCancel] = useState(false);

  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  let generateMyShiftsAndTils = React.useMemo(() => {
    return allShiftsAndTils.map((unit, i) => {
      let printShiftDurationSubTxt = () => {
        let dur = 0;
        if (unit.type === "shift") {
          dur = (unit.data.duration || 0) / 1000 / 60;
        } else {
          dur = unit.data.durMins || 0;
        }

        if (unit.tilApproved) {
          dur -= unit.tilMins;
        }

        if (
          // unit.type === "shift" &&
          unit.data.absent &&
          !unit.data.partialAbsent &&
          unit.data.unpaidAbsent
        ) {
          return "0 hours";
        } else {
          return `${dateStringer.formatMinsDurationToHours(dur, false, true)}`;
        }
      };

      let click = () => {
        if (!unit.data.ghost) {
          if (unit.type === "shift") {
            memoVals.setShowMyShiftModal((x) => {
              return {
                unitType: "shift",
                unitID: unit.data.shiftID,
              };
            });
          } else {
            memoVals.setShowMyShiftModal((x) => {
              return {
                unitType: unit.type,
                unitID: unit.data.tilID,
              };
            });
          }
        } else {
          // unit is ghost
          if (unit.type === "shift") {
            axios
              .post(
                `${serverURL}/get-ghost-shift-info`,
                {
                  ds: `${unit.data.start.substr(0, 11)}H00M00`,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  setGhostTxt(response.data.result);
                  setShowGhostModal(true);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            // is til
            axios
              .post(
                `${serverURL}/get-ghost-shift-info`,
                {
                  ds: `${unit.data.ds.substr(0, 11)}H00M00`,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  setGhostTxt(response.data.result);
                  setShowGhostModal(true);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        }
      };

      let oldStart = "00:00";
      let oldEnd = "00:00";
      if (unit.type === "shift") {
        if (unit.data && unit.data.start && unit.data.end) {
          oldStart = `${unit.data.start.substr(12, 2)}:${unit.data.start.substr(
            15,
            2
          )}`;
          oldEnd = `${unit.data.end.substr(12, 2)}:${unit.data.end.substr(
            15,
            2
          )}`;
        }
      } else {
        if (unit.data) {
          if (unit.data && unit.data.start && unit.data.end) {
            oldStart = unit.data.start;
            oldEnd = unit.data.end;
          }
        }
      }

      let tonight2359 = new Date();
      tonight2359.setHours(23, 59, 0, 0);
      let unitDateObj = new Date(unit.startTs);
      let dateStrip1 = dateStringer.tsToDayAndDate(unit.startTs, true);
      let long = true;
      if (unit.type === "overtime" && dateStrip1.includes("Wed")) {
        long = false;
      }
      let dateStrip = memoVals.showYetToAcceptOnly
        ? dateStringer.printedDateWithYrFromDs(
            dateStringer.createStringFromTimestamp(unit.startTs),
            false,
            true,
            false
          )
        : dateStringer.tsToDayAndDate(unit.startTs, long);
      return (
        <div
          key={i}
          className={`myTilShiftUnitParent ${
            unit.data.tags && unit.data.tags[0] ? "myTilShiftUnitWithTags" : ""
          } ${
            (showShifts && unit.type === "shift") ||
            (showTils && unit.type === "til") ||
            (showOvertimes && unit.type === "overtime")
              ? ""
              : "none"
          } ${
            unit.requireAccept &&
            !unit.accepted &&
            unit.midnightTimestamp >= today00Ts
              ? "myTilShiftUnitParentWithAccept"
              : ""
          }`}
        >
          <div
            onClick={() => {
              click();
            }}
            className={`${`tilShiftUnit myTilShiftUnit ${
              memoVals.mob ? "borderRadius10" : ""
            }`} 
 
       `}
            key={unit.key}
          >
            {/* {unit.partialAbsent ? (
          <div
            className={`${
              unit.startTs <= tonight2359.getTime()
                ? `partialAbsentBlockWorked 
                
                  `
                : `partialAbsentBlockUnWorked `
            } `}
          ></div>
        ) : (
          <div
            className={`partAbsDivPlaceholder ${
              unit.ghost ? "ghostAbsDivPlaceholderColour" : ""
            }`}
          ></div>
        )} */}
            {/* <div className={`tilShiftSection0 none`}>
          <div
            className={`${
              unit.startTs <= tonight2359.getTime() &&
              !unit.data.absent &&
              !unit.data.partialAbsent
                ? "myShiftWorkedDot"
                : `${
                    unit.data.absent
                      ? `${
                          unit.data.partialAbsent
                            ? "myShiftWorkedDotAbsentPartial"
                            : "myShiftWorkedDotAbsent"
                        }`
                      : `${
                          unit.data.ghost
                            ? "myShiftGhostDot"
                            : "myShiftUnWorkedDot"
                        }`
                  }
                }`
            }`}
          ></div>
        </div> */}
            <div className="myHoursLeftWrapper">
              {unit.outstandingChanges ? (
                <p className="shiftUnitOutstandingChanges myOutstandingColours">
                  {/* {unit.outstandingChanges > 9 ? "9+" : unit.outstandingChanges}{" "} */}
                  You've made a change request
                  {unit.outstandingChanges > 1 ? "s" : ""}
                </p>
              ) : (
                ""
              )}
              <div className="myHoursLeftSec">
                {unit.requireAccept ? (
                  !unit.accepted ? (
                    <img
                      src={lightBlueQuestionMark}
                      alt="Accepted"
                      className={`acceptedDoubleTickMyHours xg27381`}
                    />
                  ) : (
                    // <div
                    //   className={`reqAcceptIcon ${
                    //     unit.outstandingChanges ? "x389481912222" : ""
                    //   }`}
                    // ></div>
                    <img
                      src={doubleTick}
                      alt="Accepted"
                      className={`acceptedDoubleTickMyHours ${
                        unit.outstandingChanges ? "acceptedByChangeReq" : ""
                      }`}
                    />
                  )
                ) : (
                  ""
                )}
                <img src={time} alt="My hours" className="timeHoursImg" />
              </div>

              <div
                className={`reqColBarDiv ${
                  unit.startTs <= new Date().getTime()
                    ? "reqColBarAdjust3 divApp"
                    : "reqColBarAdjust6"
                } divPend ${unit.absent ? "divDec" : ""} `}
              ></div>

              <div
                className={`myHoursShiftSecion1 ${
                  memoVals.mob
                    ? `mobMyHoursShiftSecion1 ${
                        unit.pTags && unit.pTags.length > 1
                          ? "tagsMobMyHoursShiftsSection1"
                          : ""
                      }`
                    : ""
                } ${unit.outstandingChanges ? "pushSec1Down" : ""}`}
              >
                <div
                  className={`tilShiftDs ${
                    printShiftDurationSubTxt() === "0m" ? "noBorder" : ""
                  }`}
                >
                  <div
                    className={`tilShiftDateAndNth ${
                      (dateStrip &&
                        dateStrip.includes("Wed") &&
                        !memoVals.showYetToAcceptOnly) ||
                      (unit.type === "overtime" &&
                        !memoVals.showYetToAcceptOnly)
                        ? "tilShiftDateAndNthSmaller"
                        : ""
                    }`}
                  >
                    {dateStrip}
                    <span
                      className={`ndStRdSpan ${
                        memoVals.showYetToAcceptOnly ? "none" : ""
                      }`}
                    >
                      {dateStringer.nd(unitDateObj.getDate())}
                      {/* {dateStrip && dateStrip[dateStrip.length - 1] === "1"
                        ? dateStrip && dateStrip[dateStrip.length - 2] === "1"
                          ? "th"
                          : "st"
                        : dateStrip && dateStrip[dateStrip.length - 1] === "2"
                        ? "nd"
                        : dateStrip && dateStrip[dateStrip.length - 1] === "3"
                        ? "rd"
                        : "th"} */}
                    </span>{" "}
                    <p className="shiftTilNameTxt shiftTilNameTxtHours">
                      {unit.type === "til"
                        ? "Lieu"
                        : unit.type === "shift"
                        ? "Shift"
                        : "Overtime"}
                    </p>
                  </div>
                  {/* <span
                className={`${!unit.data.ghost ? "shiftTilNameTxt" : "none"}`}
              >
                {unit.type === "shift"
                  ? "Shift"
                  : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`}
              </span> */}
                </div>
                {/* if til strings are needed: */}
                <div className="timesAndTagContainer x198313">
                  <p
                    className={`${
                      !unit.ghost && unit.tilApproved
                        ? `tilShiftTimes ${
                            dateStringer.generateTilShiftString(
                              unit.tilStart || "00:00",
                              unit.tilEnd || "00:00",
                              oldStart || "00:00",
                              oldEnd || "00:00",
                              false,
                              false,
                              unit.type
                            ).length > 17
                              ? "smallerTxtTimeTil"
                              : ""
                          }`
                        : "none"
                    } ${printShiftDurationSubTxt() === "0m" ? "none_" : ""}`}
                  >
                    <img
                      src={rotaClock2}
                      alt="Shift times"
                      className="hoursUnitRotaClockImg"
                      onClick={() => {
                        console.log({ unit });
                      }}
                    />
                    {dateStringer.generateTilShiftString(
                      unit.tilStart || "00:00",
                      unit.tilEnd || "00:00",
                      oldStart || "00:00",
                      oldEnd || "00:00",
                      false,
                      false,
                      unit.type
                    )}{" "}
                  </p>
                  {/* if not a til, no til string needed, just use normal time string: */}
                  <p
                    className={`${
                      !unit.tilApproved && !unit.ghost
                        ? "tilShiftTimes"
                        : "none"
                    }`}
                  >
                    {" "}
                    <img
                      src={rotaClock2}
                      alt="Shift times"
                      className="hoursUnitRotaClockImg"
                    />
                    {unit.type === "shift"
                      ? `${unit.data.start.substr(
                          12,
                          2
                        )}:${unit.data.start.substr(
                          15,
                          2
                        )} - ${unit.data.end.substr(
                          12,
                          2
                        )}:${unit.data.end.substr(15, 2)}`
                      : `${unit.data.start} - ${unit.data.end}`}
                  </p>

                  <p
                    className={`${
                      !unit.ghost ? "tilShiftTimes x3891974" : "none"
                    } ${
                      dateStringer.formatMinsDurationToHours(
                        unit.type === "shift" ? unit.data.break : unit.data.brk,
                        true,
                        true
                      ).length > 6
                        ? "x39142318"
                        : ""
                    } ${
                      dateStringer
                        .generateTilShiftString(
                          unit.tilStart || "00:00",
                          unit.tilEnd || "00:00",
                          oldStart || "00:00",
                          oldEnd || "00:00",
                          false,
                          false,
                          unit.type
                        )
                        .includes("Whole")
                        ? "none_"
                        : ""
                    }`}
                  >
                    <img
                      src={rotaCoffee2}
                      alt="Shift times"
                      className="hoursUnitRotaClockImgCoffee"
                    />

                    {dateStringer.formatMinsDurationToHours(
                      unit.type === "shift"
                        ? unit.data.break || 0
                        : unit.data.brk || 0,
                      true,
                      true
                    )}
                  </p>

                  <p className={`${unit.ghost ? "tilShiftTimes" : "none"}`}>
                    Paid day off
                  </p>
                  {/* {unit.pTags && unit.pTags.length > 0 ? (
                  <div
                    className={`pTagsSplitter ${memoVals.mob ? "mobpTagsSplitter" : ""}`}
                  ></div>
                ) : (
                  ""
                )}
                <p
                  className={`${
                    unit.pTags && unit.pTags.length > 0 ? "pTagTxt" : "none"
                  }`}
                >
                  {formatTag(unit.pTags[0])}
                </p>
                <p
                  className={`${
                    unit.pTags.length > 1 ? "severalTagsTxt" : "none"
                  }`}
                >
                  +{unit.pTags.length - 1}
                </p> */}
                </div>
              </div>
            </div>
            <div
              className={`myHoursShiftSecion2 ${
                memoVals.mob ? "" : "desktopMyHoursShiftSection2"
              }`}
            >
              <p
                className={`tilShiftTypeTxt ${
                  unit.absent
                    ? unit.partialAbsent
                      ? "partialAbsentShiftDurString"
                      : "fullyAbsentShiftDurString"
                    : ""
                } ${unit.data.selfLoggedAbsence ? "reportAbsColMySh" : ""}
            `}
              >
                {/* {unit.type === "shift"
            ? "Shift"
            : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`} */}
                {unit.data.selfLoggedAbsence
                  ? "Reported absent"
                  : printShiftDurationSubTxt()}
              </p>
              {/* {unit.cost ? (
              <p
                className={`myShiftsUnitDurationSubTxt ${
                  unit.startTs <= tonight2359.getTime()
                    ? ""
                    : "unworkedShiftTilItem"
                } `}
              >
                {currencySymbol}
                {Math.round(unit.cost)}
 
              </p>
            ) : (
              ""
            )} */}
              <p
                className={`myHoursUnitTeamName ${
                  memoVals.mob || memoVals.fullButNarrow
                    ? "myHoursUnitTeamNameMob"
                    : ""
                }`}
              >
                {dateStringer.shorten(unit.teamName, memoVals.mob ? 31 : 35)}{" "}
                <img src={teamIcon} alt="Team" className="teamIconHours" />{" "}
              </p>
            </div>
            <div
              className={`itemTongue shiftTongue_ ${
                unit.type === "shift"
                  ? ""
                  : unit.type === "til"
                  ? "tilTongue_"
                  : "overtimeTongue_"
              } 
              overtimeTongue ${unit.ghost ? "dayOffTongue" : ""}`}
            >
              <p className={`tongueTxt shTongueTxt`}>
                {unit.ghost ? "Day off" : "Hours"}
                {/* // : unit.type === "til" // ? "Lieu" // : unit.type === "shift"
                // ? "Shift" // : "Overtime"} */}
              </p>
            </div>
          </div>
          {unit.data.tags && unit.data.tags[0] ? (
            <div
              className={`myShiftUnitTagsRow ${
                memoVals.mob ? "myShiftUnitTagsRowMobOnly" : ""
              }`}
            >
              {" "}
              <div className="usrShiftTagsContainer">
                <p className="teamShiftTagItem">
                  {formatTag(unit.data.tags[0])}
                </p>
                <p
                  className={`${
                    unit.data.tags[1] && !memoVals.mob
                      ? "teamShiftTagItem"
                      : "none"
                  }`}
                >
                  {formatTag(unit.data.tags[1])}
                </p>
                <p
                  className={`${
                    unit.data.tags[memoVals.mob ? 1 : 2]
                      ? "teamShiftTagsSurplusIndicator"
                      : "none"
                  }`}
                >
                  +
                  {memoVals.mob
                    ? unit.data.tags.length - 1
                    : unit.data.tags.length - 2}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {unit.requireAccept &&
          !unit.accepted &&
          unit.midnightTimestamp >= today00Ts ? (
            <div
              className={`myHourAcceptRow ${
                unit.data.tags[0] ? "myHourAcceptRowWithTags" : ""
              }`}
            >
              <p
                className="myHoursAcceptBtn"
                onClick={() => {
                  let nowDs = dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  );
                  axios
                    .post(
                      `${serverURL}/accept-shift`,
                      {
                        shiftID: unit.data.shiftID || unit.data.tilID,
                        type: unit.type,
                        nowDs,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        let newArr = allShiftsAndTils.map((x) => {
                          if (
                            (x.data.shiftID &&
                              x.data.shiftID === unit.data.shiftID) ||
                            (x.data.tilID && x.data.tilID === unit.data.tilID)
                          ) {
                            return Object.assign({}, x, { accepted: nowDs });
                          }
                          return x;
                        });
                        setAllShiftsAndTils(newArr);
                        memoVals.setNotYetAccepted(
                          (x) => memoVals.notYetAccepted - 1
                        );
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `${
                              unit.type === "til"
                                ? "Lieu shift"
                                : unit.type === "overtime"
                                ? "Overtime"
                                : "Shift"
                            } accepted`,
                            duration: 4000,
                          };
                        });
                      }
                    });
                }}
              >
                Accept {unit.type === "til" ? "lieu shift" : unit.type}
                <img
                  src={whiteTick}
                  alt="Accept shift"
                  className="acceptShiftWhiteTick"
                />{" "}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  }, [allShiftsAndTils, memoVals.reverse, showOvertimes, showTils, showShifts]);

  useEffect(() => {
    if (memoVals.showYetToAcceptOnly) {
      if (!showShifts) {
        setShowShifts(true);
      }

      if (!showOvertimes) {
        setShowOvertimes(true);
      }

      if (!showTils) {
        setShowTils(true);
      }

      if (showFilter) {
        setShowFilter(false);
      }
    }
  }, [memoVals.showYetToAcceptOnly]);

  // ------------------------------------------------------------------------
  let [changesLoading, setChangesLoading] = useState(true);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (memoVals.showChanges) {
      setLoading(true);
      setChangesLoading(true);

      let m = my.substr(0, 2);
      let y = my.substr(2, 2);

      if (m === "00" && y === "00") {
        let today = new Date();
        m = today.getMonth();
        y = today.getFullYear();
      }

      axios
        .post(
          `${serverURL}/get-all-my-shift-changes`,
          {
            m,
            y,
            // showLatest: true,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // let needsAck = [];
            // let doesNotNeedAck = [];
            // response.data.data.forEach((obj) => {
            //   if (obj.requiresAck) {
            //     needsAck.push(obj);
            //   } else {
            //     doesNotNeedAck.push(obj);
            //   }
            // });

            // needsAck.sort(function (a, b) {
            //   return b.timestamp - a.timestamp;
            // });

            // doesNotNeedAck.sort(function (a, b) {
            //   return b.timestamp - a.timestamp;
            // });
            // setChangesData(
            let arr = response.data.data.sort((a, b) => {
              return b.timestamp - a.timestamp;
            });
            // );

            let pending = [];
            let nonPending = [];
            arr.forEach((x) => {
              if (!x.approved && !x.declined) {
                pending.push(x);
              } else {
                nonPending.push(x);
              }
            });

            if (pending[0]) {
              pending.push({ split: true });
            }

            setChangesData([...pending, ...nonPending]);

            setChangesLoading(false);
            setLoading(false);
            // nathan
            setAllShiftsAndTils([]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.showChanges, my, memoVals.removeMyChangeEditID]);

  let pendingsExist = () => {
    let pend = 0;

    changesData.forEach((x) => {
      if (!x.approved && !x.declined && !x.split) {
        pend++;
      }
    });
    return pend;
  };

  let [selectedChangeID, setSelectedChangeID] = useState("");

  let generateChanges = changesData.map((change, i) => {
    if (change.split) {
      return <div className="pendingTsSplitter" key={i}></div>;
    } else {
      return (
        <div
          key={i}
          className={` ${changesLoading ? "none" : ""} ${
            selectedChangeID === change.editID
              ? "shiftChangeRow selectedShiftChangeRow"
              : "shiftChangeRow"
          } ${change.editID.includes("not an edit") ? "" : ""} ${
            change.oldStart === ":" ? "none" : ""
          } ${change.requiresAck ? "changeRequiresAckBorder" : ""} ${
            change.declined ? "changeDeclinedBorder_" : ""
          } ${change.approved ? "changeApprovedBorder_" : ""} ${
            !change.approved && !change.declined
              ? "mgrReqItemPendingBorder whiteBg"
              : ""
          } ${memoVals.mob ? "borderRadius10" : ""}`}
          onClick={() => {
            // if (change.editID === selectedChangeID) {
            //   setSelectedChangeID("");
            // } else {
            //   setSelectedChangeID(change.editID);
            // }
          }}
        >
          <div className="shiftChangeTitleRow">
            <p
              className={`${
                change.isTakeTil || change.tilEdit ? "none" : "shiftChangeType"
              }`}
            >
              {`${
                change.requested && !change.tilEdit
                  ? "You requested a change to your "
                  : "Your "
              }`}
              <span className="shiftTypeSpan">
                {change.oldType === "shift"
                  ? "shift"
                  : `${
                      change.oldType === "overtime" ? "overtime" : "TOIL leave"
                    }`}
              </span>{" "}
              {`${change.requested ? "" : "was changed"}`}
            </p>
            <p
              className={`${
                change.isTakeTil && change.approved && !change.tilEdit
                  ? "shiftChangeType"
                  : "none"
              }`}
            >
              Your request to take <span className="shiftTypeSpan">TOIL</span>{" "}
              on your
              <span className="shiftTypeSpan">
                {change.type === "shift" ? " shift" : " overtime"}
              </span>{" "}
              was <span className="approvedChangeSpan">approved</span> by{" "}
              <span className="shiftTypeSpan">{change.decidedBy}</span>
            </p>
            <p
              className={`${
                change.isTakeTil && change.declined && !change.tilEdit
                  ? "shiftChangeType"
                  : "none"
              }`}
            >
              Your request to take <span className="shiftTypeSpan">TOIL</span>{" "}
              on your
              <span className="shiftTypeSpan">
                {change.type === "shift" ? " shift" : " overtime"}
              </span>{" "}
              was <span className="declinedChangeSpan">declined</span> by{" "}
              <span className="shiftTypeSpan">{change.decidedBy}</span>
            </p>
            <p
              className={`${
                change.isTakeTil &&
                !change.declined &&
                !change.approved &&
                !change.tilEdit
                  ? "shiftChangeType"
                  : "none"
              }`}
            >
              You requested to take <span className="shiftTypeSpan">TOIL</span>{" "}
              on your
              <span className="shiftTypeSpan">
                {change.type === "shift" ? " shift" : " overtime"}
              </span>{" "}
            </p>

            <p className={`${change.tilEdit ? "shiftChangeType" : "none"}`}>
              The times of your TOIL taken on your{" "}
              <span className="shiftTypeSpan">
                {change.type === "shift" ? " shift" : " overtime"}
              </span>{" "}
              have been changed
            </p>
          </div>
          <div className="changeTitleRowSubBar">
            <p className="shiftChangeMadeDate">
              {dateStringer.printedDateFromDs(
                dateStringer.createStringFromTimestamp(change.timestamp)
              )}
              , {dateStringer.tsToHHMM(change.timestamp)}
            </p>
            <div
              className={`${
                change.requested
                  ? `${
                      change.approved && !change.declined
                        ? "changeStatusApproved"
                        : `${
                            change.declined
                              ? "changeStatusDeclined"
                              : "changeStatus"
                          }`
                    } `
                  : "none"
              } noBorderRight`}
            >
              {`${
                change.approved && !change.declined
                  ? "Approved"
                  : `${change.declined ? "Declined" : "Pending"}`
              }`}
              {change.requested && change.approved ? (
                <img
                  src={unitTick}
                  alt="Approved"
                  className="changeStatusIconImg"
                />
              ) : (
                ""
              )}
              {change.requested && change.declined ? (
                <img
                  src={darkRedCross}
                  alt="Declined"
                  className="changeStatusIconImg"
                />
              ) : (
                ""
              )}
              {change.requested && !change.declined && !change.approved ? (
                <img
                  src={greyThreeDots}
                  alt="Declined"
                  className="changeStatusIconImg"
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`${
                !change.approved && !change.declined
                  ? "changeStatus"
                  : `${
                      change.approved
                        ? "changeStatusApproved"
                        : "changeStatusDeclined"
                    }`
              } ${change.isTakeTil ? "" : "none"} noBorderRight`}
            >
              {change.approved
                ? "Approved"
                : `${change.declined ? "Declined" : "Pending"}`}
              {change.isTakeTil && !change.declined && !change.approved ? (
                <img
                  src={greyThreeDots}
                  alt="Declined"
                  className="changeStatusIconImg"
                />
              ) : (
                <img
                  src={change.approved ? unitTick : darkRedCross}
                  alt="Approved"
                  className="changeStatusIconImg"
                />
              )}
            </div>
          </div>
          <div className={`${change.tilEdit ? "tilEditContainer" : "none"}`}>
            {/*  -START----------------------------------------------------------------------------------------------------------- */}

            <div className={` ${change.tilEdit ? "changeRowMain" : "none"}`}>
              <div
                className={`changeRowLeft ${
                  !memoVals.mob ? "changeRowLeftDesktop" : ""
                }`}
                onClick={() => {
                  memoVals.setShowMyShiftModal((x) => {
                    return {
                      unitType: change.type === "shift" ? "shift" : "til",
                      unitID: change.shiftID,
                    };
                  });
                }}
              >
                <p className="oldShiftDate textAlignLeft">
                  {dateStringer.printedDateWithYrFromDs(change.oldDate)}
                </p>
                <p className="oldShiftTimes textAlignLeft">
                  {change.oldTilStart} - {change.oldTilEnd}
                </p>
                <p className="oldShiftBrk">Old TOIL</p>
              </div>
              <div className="changeRowMiddle">
                <img
                  src={shiftChangeArrowGreen}
                  alt="arrow"
                  className="changeShiftArrowImg"
                />
              </div>
              <div
                className={`changeRowRight ${
                  !memoVals.mob ? "changeRowRightDesktop" : ""
                }`}
                onClick={() => {
                  console.log("oldType:... ", change.oldType);
                  memoVals.setShowMyShiftModal((x) => {
                    return {
                      unitType: change.oldType === "shift" ? "shift" : "til",
                      unitID: change.shiftID,
                    };
                  });
                }}
              >
                <p
                  className={`oldShiftDate ${
                    change.newDate &&
                    change.newDate.substr(0, 11) !==
                      change.oldDate.substr(0, 11)
                      ? "highlightedChangeElement"
                      : ""
                  }`}
                >
                  {dateStringer.printedDateWithYrFromDs(
                    change.newDate ? change.newDate : ""
                  )}
                </p>

                <p className="oldShiftTimes">
                  <span
                    className={`${
                      change.newTilStart !== change.oldTilStart &&
                      change.newTilEnd !== change.oldTilEnd
                        ? "highlightedChangeElement"
                        : ""
                    }`}
                  >
                    <span
                      className={`${
                        change.newTilStart !== change.oldTilStart &&
                        change.newTilEnd === change.oldTilEnd
                          ? "highlightedChangeElement"
                          : ""
                      }`}
                    >
                      {change.newTilStart}
                    </span>{" "}
                    -{" "}
                    <span
                      className={`${
                        change.newTilEnd !== change.oldTilEnd &&
                        change.newTilStart === change.oldTilStart
                          ? "highlightedChangeElement"
                          : ""
                      }`}
                    >
                      {change.newTilEnd}
                    </span>
                  </span>
                </p>
                <p className="oldShiftBrk">New TOIL</p>
              </div>
            </div>

            {/*  ------------------------------------------------------------------------------------------------------------ */}
          </div>
          <div
            className={`changeRowMain ${
              change.requested && change.declined ? "declinedChange" : ""
            } ${change.tilEdit || change.oldStart === ":" ? "none" : ""}`}
          >
            <div
              className={`changeRowLeft ${
                !memoVals.mob ? "changeRowLeftDesktop" : ""
              }`}
              onClick={() => {
                memoVals.setShowMyShiftModal((x) => {
                  return {
                    unitType: change.type === "shift" ? "shift" : "til",
                    unitID: change.shiftID,
                  };
                });
              }}
            >
              <p className="oldShiftDate textAlignLeft">
                {dateStringer.printedDateWithYrFromDs(change.oldDate)}
              </p>
              <p className="oldShiftTimes textAlignLeft">
                {change.oldStart[0] === "Y"
                  ? `${change.oldStart.substr(12, 2)}:${change.oldStart.substr(
                      15,
                      2
                    )}`
                  : change.oldStart}{" "}
                -{" "}
                {change.oldEnd[0] === "Y"
                  ? `${change.oldEnd.substr(12, 2)}:${change.oldEnd.substr(
                      15,
                      2
                    )}`
                  : change.oldEnd}
              </p>
              <p className="oldShiftBrk">{change.oldBrk}m break</p>
            </div>
            <div className="changeRowMiddle">
              <img
                src={`${
                  change.requested
                    ? `${
                        !change.declined && change.approved
                          ? shiftChangeArrowGreen
                          : `${
                              change.declined
                                ? shiftChangeArrowRed
                                : shiftChangeArrow
                            }`
                      }`
                    : shiftChangeArrow
                }`}
                alt="arrow"
                className="changeShiftArrowImg"
              />
            </div>
            <div
              className={`changeRowRight ${
                !memoVals.mob ? "changeRowRightDesktop" : ""
              }`}
              onClick={() => {
                memoVals.setShowMyShiftModal((x) => {
                  return {
                    unitType: change.oldType === "shift" ? "shift" : "til",
                    unitID: change.shiftID,
                  };
                });
              }}
            >
              <p
                className={`oldShiftDate ${
                  change.newDate &&
                  change.newDate.substr(0, 11) !== change.oldDate.substr(0, 11)
                    ? "highlightedChangeElement"
                    : ""
                }`}
              >
                {dateStringer.printedDateWithYrFromDs(
                  change.newDate ? change.newDate : ""
                )}
              </p>
              <div className={`${change.isTakeTil ? "tilTimesStrip" : "none"}`}>
                <p className="tilStripTitle">TOIL: </p>
                <p
                  className="oldShiftTimes highlighedTilTimes"
                  // {`oldShiftTimes
                  // ${
                  //   change.oldStart !== change.newStart ||
                  //   change.oldEnd !== change.newEnd
                  //     ? "highlightedChangeElement"
                  //     : ""
                  // }
                  // `}
                >
                  <span className="">{change.newStart}</span> -{" "}
                  <span className="">{change.newEnd}</span>
                </p>
              </div>
              <p
                className={`${
                  change.isTakeTil
                    ? "none"
                    : `oldShiftTimes
                ${
                  change.oldStart !== change.newStart ||
                  change.oldEnd !== change.newEnd
                    ? "highlightedChangeElement"
                    : ""
                }
                `
                }`}
              >
                <span className="">{change.newStart}</span> -{" "}
                <span className="">{change.newEnd}</span>
              </p>
              <p
                className={`${
                  change.isTakeTil
                    ? `tilNewShiftTime ${
                        change.newStart !== change.oldStart &&
                        change.newEnd !== change.oldEnd
                          ? "smallerTilTwoShifts"
                          : ""
                      }`
                    : "none"
                }`}
              >
                {dateStringer.generateTilShiftString(
                  change.newStart,
                  change.newEnd,
                  change.oldStart,
                  change.oldEnd,
                  false,
                  false,
                  change.type,
                  true
                )}
              </p>
              <p
                className={`${
                  change.isTakeTil
                    ? "none"
                    : `tilNewShiftTime ${
                        change.newBrk !== change.oldBrk
                          ? "highlightedChangeElement"
                          : ""
                      }`
                }`}
              >
                {change.newBrk}m break
              </p>
            </div>
          </div>
          {(change.approved && !change.isChange) ||
          (change.requested && change.declined) ||
          (change.isTakeTil && change.declined) ? (
            ""
          ) : (
            <div
              className="deleteChangeUnit"
              onClick={() => {
                console.log({ change });
              }}
            >
              <div className="deleteChangeUnitLeft"></div>
              <div
                className="deleteAndViewBtns"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  className={`${
                    loadingCancel ? "cancelChangeLoadingImgContainer" : "none"
                  }`}
                >
                  <img
                    className="cancelChangeLoaderImg"
                    alt="Loading"
                    src={blueLoader}
                  />
                </div>
                <p
                  className={`${
                    !change.approved && !change.declined
                      ? "deleteChangeBtn"
                      : "deleteChangeBtn invisDeleteChangeBtn"
                  } ${loadingCancel ? "none" : ""}`}
                  onClick={() => {
                    if (
                      !change.approved &&
                      !change.declined &&
                      !change.isTakeTil
                    ) {
                      // setLoadingCancel(true);
                      setSureCancel({
                        show: true,
                        change: change.newType,
                        shiftID: change.shiftID,
                        correspondingEditID: change.editID,
                        til: false,
                      });
                    }

                    if (change.isTakeTil) {
                      // setLoadingCancel(true);
                      setSureCancel({
                        show: true,
                        takeTilID: change.takeTilID,
                        type: change.type,
                        shiftID: change.shiftID,
                        til: true,
                        editID: change.editID,
                      });
                    }
                  }}
                >
                  Cancel request
                </p>
                <img
                  src={blueLoader}
                  className={`${loadChange ? "loadChangeLoaderImg" : "none"}`}
                  alt="loading"
                />
                {change.read && change.read[0] === "Y" ? (
                  <div className="acknowledgedTxt">
                    Acknowledged{" "}
                    <img
                      src={greyTick}
                      alt="Acknowledged"
                      className="ackTickImg"
                    />
                  </div>
                ) : (
                  ""
                  // <p
                  //   className={`${
                  //     (loadChange && !change.requiresAck) ||
                  //     change.requested ||
                  //     change.isTakeTil ||
                  //     !change.requiresAck
                  //       ? "none"
                  //       : "viewChangeUnitShift"
                  //   }`}
                  //   onClick={() => {
                  //     axios
                  //       .post(
                  //         `${serverURL}/acknowledge-change`,
                  //         {
                  //           shiftID: change.shiftID,
                  //           type: change.type,
                  //           changeID: change.changeID,
                  //           nowDs: dateStringer.createStringFromTimestamp(
                  //             new Date().getTime()
                  //           ),
                  //         },

                  //         {
                  //           withCredentials: true,
                  //           credentials: "include",
                  //         }
                  //       )
                  //       .then((response) => {
                  //         if (response.data.message === "success") {
                  //           let newArr = [];
                  //           changesData.forEach((c) => {
                  //             let newObj = c;
                  //             if (c.editID === change.editID) {
                  //               newObj["read"] =
                  //                 dateStringer.createStringFromTimestamp(
                  //                   new Date().getTime()
                  //                 );
                  //               newObj["requiresAck"] = false;
                  //             }

                  //             newArr.push(newObj);
                  //           });

                  //           let needsAck = [];
                  //           let doesNotNeedAck = [];
                  //           newArr.forEach((obj) => {
                  //             if (obj.requiresAck) {
                  //               needsAck.push(obj);
                  //             } else {
                  //               doesNotNeedAck.push(obj);
                  //             }
                  //           });

                  //           needsAck.sort(function (a, b) {
                  //             return b.timestamp - a.timestamp;
                  //           });

                  //           doesNotNeedAck.sort(function (a, b) {
                  //             return b.timestamp - a.timestamp;
                  //           });
                  //           memoVals.setCountMyChanges(
                  //             (x) => memoVals.countMyChanges - 1
                  //           );
                  //           setChangesData([...needsAck, ...doesNotNeedAck]);
                  //         }
                  //       })
                  //       .catch((err) => {
                  //         console.error(err);
                  //       });
                  //   }}
                  // >
                  //   Acknowledge
                  // </p>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  });

  let printMonth = (my) => {
    let m = my.substr(0, 2);
    if (m === "00") {
      return "January";
    }
    if (m === "01") {
      return "February";
    }
    if (m === "02") {
      return "March";
    }
    if (m === "03") {
      return "April";
    }
    if (m === "04") {
      return "May";
    }
    if (m === "05") {
      return "June";
    }
    if (m === "06") {
      return "July";
    }
    if (m === "07") {
      return "August";
    }
    if (m === "08") {
      return "September";
    }
    if (m === "09") {
      return "October";
    }
    if (m === "10") {
      return "November";
    }
    if (m === "11") {
      return "December";
    }
  };

  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      {" "}
      <div className="blackCornerBg"></div>
      {/* insert header here */}
      <ShiftsHeader my={my} setMy={setMy} />
      <div className={`desktopMaster ${memoVals.mob ? "desktopWhiteBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""} ${
            memoVals.mob ? "mob100Witdth" : ""
          } `}
        >
          <div
            className={`shiftsPageFilterBar ${
              memoVals.mob ? "userHoursShoulderBar2" : ""
            } ${!memoVals.mob && "borderLeftGrey"} ${
              !memoVals.full ? "noBorderRight" : ""
            } noBorderBottom`}
          >
            <div className="shiftsPageFilterBarLeft">
              {memoVals.showYetToAcceptOnly ? (
                <p
                  className="closeYetToAcceptBtn"
                  onClick={() => {
                    memoVals.setShowYetToAcceptOnly((x) => false);
                    memoVals.setNotYetAccepted((x) => allShiftsAndTils.length);
                  }}
                >
                  Shifts to accept{" "}
                  <img
                    src={whitecross}
                    alt="Close"
                    className="closeYetToAccCrossBtn"
                  />
                </p>
              ) : (
                <select
                  className={`${
                    memoVals.showChanges
                      ? "makeFilter InvisAndInactive invis"
                      : ""
                  } shiftsTilMonthDropdown`}
                  value={my}
                  onChange={(e) => {
                    setLoading(true);
                    memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                    setMy(e.target.value);
                  }}
                >
                  {generateMonthOptions}
                </select>
              )}
            </div>
            <div className="shiftsPageFilterBarRight">
              <div
                className={`${
                  // selectedDay !== "allDays" ||
                  // selectedName !== "allNames" ||
                  !showOvertimes ||
                  !showShifts ||
                  !showTils ||
                  memoVals.showChanges ||
                  memoVals.showYetToAcceptOnly
                    ? "none"
                    : `${
                        newChanges && !memoVals.showChanges
                          ? `changesNewChangesBtn ${
                              showFilter ? "makeFilterInvisAndInactive" : ""
                            }`
                          : `changesNoChangesBtn ${
                              showFilter ? "makeFilterInvisAndInactive" : ""
                            }`
                      }`
                }`}
                onClick={() => {
                  // setNewChanges(!newChanges);
                  setSelectedChangeID("");
                  if (changesData.length === 0) {
                    setChangesLoading(true);

                    memoVals.setUpdateChanges((x) => !memoVals.updateChanges);
                  }
                  memoVals.setShowChanges((x) => !memoVals.showChanges);
                  memoVals.setPage((x) => "reqs");
                }}
              >
                Requests{" "}
              </div>
              <div
                className={`${
                  memoVals.showChanges ? "showChangesBtnActive" : "none"
                }`}
                onClick={() => {
                  // setNewChanges(!newChanges);
                  memoVals.setShowChanges((x) => !memoVals.showChanges);
                  setLoading(true);
                  // if (memoVals.showChanges) {
                  // memoVals.setPage( x=>"hours");
                  // } else {
                  memoVals.setPage((x) => "shifts");
                  // }
                }}
              >
                Requests{" "}
                <img
                  className={`${
                    memoVals.showChanges ? "closeChangesImgTilShifts" : "none"
                  }`}
                  alt="close"
                  src={whitecross}
                />
              </div>
              <div
                className={`${
                  showFilter ? "shiftTilFilterBtnActive" : "shiftTilFilterBtn"
                } ${
                  memoVals.showChanges
                    ? "makeFilterInvisAndInactive maxW58px"
                    : ""
                }`}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <img
                  src={filterWhite}
                  alt="Close filter"
                  className={`filtImg ${!showFilter ? "x19831" : ""}`}
                />{" "}
                <img
                  className={`${
                    showFilter ? "closeFilterImgTilShifts" : "none"
                  }`}
                  alt="close"
                  src={whitecross}
                />
              </div>
            </div>
          </div>
          <div className="shiftTilFilterHolder">
            <div
              className={`${
                showFilter ? "shfitTilFilterBtnsContainer" : "none"
              }`}
            >
              <div
                className={`shiftsOrTilFilter ${
                  showShifts ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowShifts(!showShifts);
                }}
              >
                Shifts{" "}
                <img
                  src={showShifts ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showOvertimes ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowOvertimes(!showOvertimes);
                }}
              >
                Overtimes{" "}
                <img
                  src={showOvertimes ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showTils ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowTils(!showTils);
                }}
              >
                Shifts in lieu{" "}
                <img
                  src={showTils ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
            </div>
          </div>
          <div
            className={`filterSplitterContainer ${
              !memoVals.mob && "borderLeftGrey"
            } ${!memoVals.full ? "noBorderRight" : ""}`}
          >
            <div className="filterSplitter"></div>
          </div>
          {/* - - - - */}
          <div
            className={`${
              memoVals.showChanges
                ? `availabilityBodyContainer changesOverflow borderLeftGrey ${
                    !memoVals.full ? "noBorderRight" : ""
                  }`
                : "none"
            } ${memoVals.mob ? "mobChangesContainer" : ""}`}
          >
            <img
              src={horizontalBalls}
              className={`${changesLoading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />
            {/* <p className={`${changesLoading ? "none" : "showingChangesFor"}`}>
              Showing changes made to shifts in {printMonth(my)}
            </p> */}
            {!loading && pendingsExist() ? (
              <p className="clocksAwaitingTitles notYetSubmitTitleArr">
                Not yet approved - {pendingsExist()}
              </p>
            ) : (
              ""
            )}

            {(changesData && changesData[0]) || loading ? (
              generateChanges
            ) : (
              <div className="noShiftsTxtContainer noUpcomingReqs">
                <div className="noShiftsSideBar"></div>

                <p className="noShiftsTxt noUpcChangesTxt">
                  Any change requests that you make to your upcoming shifts will
                  appear here
                </p>

                <div className="noShiftsSideBar"></div>
              </div>
            )}
          </div>
          <div
            ref={hoursScrollBox}
            className={`${
              memoVals.showChanges ? "none" : "myHoursContainer"
              // `shiftsBodyContainer ${
              //     !memoVals.mob ? "shiftsBodyContainerDesktop" : ""
              //   }`
            } ${!memoVals.mob ? "borderLeftGrey" : ""}  ${
              memoVals.mob
                ? `mobMyHoursContainer ${
                    showFilter ? "showFilterMobMyHoursContainer" : ""
                  }`
                : `${showFilter ? "showFilterDesktopMyHoursContainer" : ""}`
            } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
              !memoVals.full ? "noBorderRight" : ""
            }`}
          >
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />
            {loading ? (
              ""
            ) : allShiftsAndTils[0] ? (
              generateMyShiftsAndTils
            ) : (
              <div
                className={`noShiftsTxtContainer noUpcomingReqs ${
                  memoVals.mob ? "x1984971" : ""
                }`}
              >
                <div className="noShiftsSideBar"></div>

                <p className={`noShiftsTxt x19873`}>
                  No shifts, overtime or shifts in lieu in{" "}
                  {dateStringer.printMonthAndYearFromMy(my, true, true)}
                </p>

                <div className="noShiftsSideBar"></div>
              </div>
            )}
          </div>
          {/* {renderMyShiftModal()} */}
          <div
            className={`${
              showGhostModal ? "editShiftRequestModalUnderlay" : "none"
            }`}
            onClick={() => {
              setShowGhostModal(false);
            }}
          >
            <div
              className="ghostModalBox"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {ghostTxt}
              <div
                className="closeGhostModalBtn"
                onClick={() => {
                  setShowGhostModal(false);
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
        {!memoVals.mob ? (
          <div className="desktopMasterSecondary">
            <HoursDatabox sideBoxData={sideBoxData} my={my} setMy={setMy} />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      {sureCancel.show ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setSureCancel({ show: false });
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              {sureCancel.til
                ? "Are you sure you want to cancel this TOIL request?"
                : "Are you sure you want to cancel this change request?"}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  console.log("hello");
                  console.log({ sureCancel });
                  if (sureCancel.til) {
                    setLoadingCancel(true);
                    axios
                      .post(
                        `${serverURL}/remove-til-request`,
                        {
                          takeTilID: sureCancel.takeTilID,
                          type: sureCancel.type,
                          shiftID: sureCancel.shiftID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          console.log(response.data);
                          // remove change from changesData
                          setSureCancel({ show: false });
                          let newArr = [];
                          changesData.forEach((c) => {
                            if (c.editID !== sureCancel.editID) {
                              newArr.push(c);
                            }
                          });

                          let needsAck = [];
                          let doesNotNeedAck = [];
                          newArr.forEach((obj) => {
                            if (obj.requiresAck) {
                              needsAck.push(obj);
                            } else {
                              doesNotNeedAck.push(obj);
                            }
                          });

                          needsAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });

                          doesNotNeedAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });
                          setChangesData([...needsAck, ...doesNotNeedAck]);

                          setLoadingCancel(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    setLoadingCancel(true);

                    console.log("Cancel shift req here");
                    axios
                      .post(
                        `${serverURL}/cancel-shift-change-request`,
                        {
                          change: sureCancel.change,
                          shiftID: sureCancel.shiftID,
                          correspondingEditID: sureCancel.correspondingEditID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          // memoVals.setUpdateChanges( x=>!memoVals.updateChanges);
                          setSureCancel({ show: false });
                          // remove change from changesData
                          let newArr = [];
                          changesData.forEach((c) => {
                            if (c.editID !== sureCancel.correspondingEditID) {
                              newArr.push(c);
                            }
                          });

                          let needsAck = [];
                          let doesNotNeedAck = [];
                          newArr.forEach((obj) => {
                            if (obj.requiresAck) {
                              needsAck.push(obj);
                            } else {
                              doesNotNeedAck.push(obj);
                            }
                          });

                          needsAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });

                          doesNotNeedAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });
                          setChangesData([...needsAck, ...doesNotNeedAck]);

                          setLoadingCancel(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureCancel({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default UserShifts;
