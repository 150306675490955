import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const RequestContext = createContext();
// context for request makers

const RequestContextProvider = (props) => {
  // show to add thing overlay when + pressed on Menu.js
  let [showAdd, setShowAdd] = useState(false);

  let [monthsAvailable, setMonthsAvailable] = useState();

  // store available leave types (stored in useEffect hook in Menu.js)
  let [availableLeaveTypes, setAvailableLeaveTypes] = useState([]); // holds annual leave request end ts
  // let [showAnnLeave, setShowAnnLeave] = useState(); // whether to show ann leave as available to take off - probably never be false
  let [tilAvailable, setTilAvailable] = useState();

  // let [halfStart, setHalfStart] = useState();
  // let [halfEnd, setHalfEnd] = useState();

  let [showReqModal, setShowReqModal] = useState(false);
  // let [alReqStartTs, setAlReqStartTs] = useState(); // holds annual leave request start ts
  // let [alReqEndTs, setAlReqEndTs] = useState(); // holds annual leave request end ts

  // fixedSchedudled && !fixedSchedOdd users only: (days users)
  // let [localSunSchedTimes, setLocalSunSchedTimes] = useState();
  // let [localMonSchedTimes, setLocalMonSchedTimes] = useState();
  // let [localTueSchedTimes, setLocalTueSchedTimes] = useState();
  // let [localWedSchedTimes, setLocalWedSchedTimes] = useState();
  // let [localThuSchedTimes, setLocalThuSchedTimes] = useState();
  // let [localFriSchedTimes, setLocalFriSchedTimes] = useState();
  // let [localSatSchedTimes, setLocalSatSchedTimes] = useState();

  let [reqMidnightTsArray, setReqMidnightTsArray] = useState([]); // array of actual leave midnightTs, ones that actually qualify as leave
  // let [reqDudDays, setReqDudDays] = useState([]); // array of midnightTs that the leave request will not affect, ie nonworking days, closed public hols, closed specDays
  let [understaffedDays, setUnderstaffedDays] = useState([]); // array of midnightTs that are understaffed, will also hold team name too in the object alongside the ts

  let [understaffedLocal, setUnderstaffedLocal] = useState([]); // local array of datestrings of understaffed objects, stored upon menu load, for react to check each date period without having to make a request
  let [reqDur, setReqDur] = useState();

  // for use with api row 156
  let [fixedSchedArray, setFixedSchedArray] = useState([]);
  let [halfStartMins, setHalfStartMins] = useState(0); // mins to deduct for fixedVar/zero/fixedScheddOdd leave if first day is half
  let [halfEndMins, setHalfEndMins] = useState(0); // mins to deduct for fixedVar/zero/fixedScheddOdd leave if last day is half
  // let [zMins, setZMins] = useState(0);
  let [reqTsArr, setReqTsArr] = useState([]); // store timestamps of valid days in the request

  // to be used by zero + fixedVar only, when making request to calc zMins
  let [currAvgMinsPerDay, setCurrAvgMinsPerDay] = useState(0);
  let [nextAvgMinsPerDay, setNextAvgMinsPerDay] = useState(0);

  let [currOrNext, setCurrOrNext] = useState("curr");
  // let [reqDur2, setReqDur2] = useState(0);

  let [showDatepickerPrefModal, setShowDatepickerPrefModal] = useState(false);

  let [leaveTypeIsPaid, setLeaveTypeIsPaid] = useState(true);
  let [excessMpd, setExcessMpd] = useState(0);
  let [annLeavePercentage, setAnnLeavePercentage] = useState(0);

  // for use on the request maker -- should react show how many hours in total? estimated hours incl excess
  // let [overtimePaidOnReqs, setOvertimePaidOnReqs] = useState(false);

  let [showViewTeamOnRequest, setShowViewTeamOnRequest] = useState(false);
  let [viewTeamReqID, setViewTeamReqID] = useState("");

  let [halfStartOffs, setHalfStartOffs] = useState([]);
  let [halfEndOffs, setHalfEndOffs] = useState([]);
  // let [overlappingDs, setOverlappingDs] = useState([]);

  let [showEditDateModal, setShowEditDateModal] = useState(false);

  let [showUserReqModal, setShowUserReqModal] = useState(""); // reqID

  // let [closedDayNums, setClosedDayNums] = useState([]);

  // let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");

  let [updateReq, setUpdateReq] = useState(false);

  // let [existingHalfDaysCount, setExistingHalfDaysCount] = useState(0);

  return (
    <RequestContext.Provider
      value={{
        // existingHalfDaysCount,
        // setExistingHalfDaysCount,
        updateReq,
        setUpdateReq,
        // startDate,
        // setStartDate,
        endDate,
        setEndDate,
        // closedDayNums,
        // setClosedDayNums,
        showUserReqModal,
        setShowUserReqModal,
        showEditDateModal,
        setShowEditDateModal,
        // overlappingDs,
        // setOverlappingDs,
        halfStartOffs,
        setHalfStartOffs,
        halfEndOffs,
        setHalfEndOffs,
        showViewTeamOnRequest,
        setShowViewTeamOnRequest,
        viewTeamReqID,
        setViewTeamReqID,
        showAdd,
        setShowAdd,
        showReqModal,
        setShowReqModal,
        // alReqStartTs,
        // setAlReqStartTs,
        // alReqEndTs,
        // setAlReqEndTs,
        // showAnnLeave,
        // setShowAnnLeave,
        availableLeaveTypes,
        setAvailableLeaveTypes,
        tilAvailable,
        setTilAvailable,
        // localSunSchedTimes,
        // setLocalSunSchedTimes,
        // localMonSchedTimes,
        // setLocalMonSchedTimes,
        // localTueSchedTimes,
        // setLocalTueSchedTimes,
        // localWedSchedTimes,
        // setLocalWedSchedTimes,
        // localThuSchedTimes,
        // setLocalThuSchedTimes,
        // localFriSchedTimes,
        // setLocalFriSchedTimes,
        // localSatSchedTimes,
        // setLocalSatSchedTimes,
        monthsAvailable,
        setMonthsAvailable,
        reqMidnightTsArray,
        setReqMidnightTsArray,

        understaffedDays,
        setUnderstaffedDays,
        // halfStart,
        // setHalfStart,
        // halfEnd,
        // setHalfEnd,
        understaffedLocal,
        setUnderstaffedLocal,

        reqDur,
        setReqDur,
        fixedSchedArray,
        setFixedSchedArray,
        halfStartMins,
        setHalfStartMins,
        halfEndMins,
        setHalfEndMins,
        // zMins,
        // setZMins,
        reqTsArr,
        setReqTsArr,
        currAvgMinsPerDay,
        setCurrAvgMinsPerDay,
        nextAvgMinsPerDay,
        setNextAvgMinsPerDay,
        currOrNext,
        setCurrOrNext,
        // reqDur2,
        // setReqDur2,
        showDatepickerPrefModal,
        setShowDatepickerPrefModal,

        leaveTypeIsPaid,
        setLeaveTypeIsPaid,
        excessMpd,
        setExcessMpd,
        annLeavePercentage,
        setAnnLeavePercentage,
        // overtimePaidOnReqs,
        // setOvertimePaidOnReqs,
      }}
    >
      {props.children}
    </RequestContext.Provider>
  );
};

export default RequestContextProvider;
