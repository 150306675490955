import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useMemo,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";

import navyClose from "../img/general/navyClose.svg";
import greyEdit from "../img/general/editGrey.svg";
import whiteTick from "../img/general/whiteTick.svg";
import returnArrow from "../img/general/returnArrow.svg";
import crossWhite from "../img/general/crossWhite.svg";
import whitebin from "../img/general/whitebin.svg";
import editReq from "../img/general/editReq.svg";
import popup from "../img/general/popup.svg";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/calendar.css";

const ViewTimesheetModal = ({ isMgr }) => {
  const {
    showViewTimesheet,
    setShowViewTimesheet,
    setIndicate,
    setShowTimesheet,
    showMyShiftModal,
    setShowMyShiftModal,
    updateUserTimesheets,
    setUpdateUserTimesheets,
    reloadTs,
    setClockOnID,
    setOpenManagerShiftModal,
    device,
    setShowEditUser,
    updatedDashData,
    setUpdatedDashData,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      showViewTimesheet, //
      setShowViewTimesheet, //
      setIndicate, //
      setShowTimesheet, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      updateUserTimesheets, //
      setUpdateUserTimesheets, //
      reloadTs, //
      setClockOnID, //
      setOpenManagerShiftModal, //
      device, //
      setShowEditUser, //
      updatedDashData, //
      setUpdatedDashData, //
    }),
    [
      showViewTimesheet, //
      setShowViewTimesheet, //
      setIndicate, //
      setShowTimesheet, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      updateUserTimesheets, //
      setUpdateUserTimesheets, //
      reloadTs, //
      setClockOnID, //
      setOpenManagerShiftModal, //
      device, //
      setShowEditUser, //
      updatedDashData, //
      setUpdatedDashData, //
    ]
  );

  useEffect(() => {
    memoVals.setShowTimesheet((x) => false);
  }, []);

  let calcDurMins = (start, end, brk) => {
    // start and end to be 00:00
    if (start && end) {
      let startTs = dateStringer.HHMMToMsToday(start);
      let endTs = dateStringer.HHMMToMsToday(end);
      return (endTs - startTs) / 1000 / 60 - brk || 0;
    }
  };
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 8) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyEdit}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={returnArrow}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={crossWhite}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitebin}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editReq}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  const { mob, mobModal } = useContext(StyleContext);
  let [availableTags, setAvailableTags] = useState([]);
  let [dataEdited, setDataEdited] = useState(false);
  let [editDecision, setEditDecision] = useState(false);
  let [sureDelete, setSureDelete] = useState(false);
  let [sureDeleteMgr, setSureDeleteMgr] = useState(false);

  let [showCreateNewTag, setShowCreateNewTag] = useState("");
  let [newTagValue, setNewTagValue] = useState("");
  let [updatedData, setUpdatedData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      type: "",
      start: "",
      end: "",
      break: 0,
      tags: [],
      note: "",
      mgrNote: "",
      rate: 1,
    }
  );
  let [approved, setApproved] = useState("");
  let [decidedBy, setDecidedBy] = useState("");
  let [declined, setDeclined] = useState("");
  let [declinedReason, setDeclinedReason] = useState("");

  let [showRecordedOnly, setShowRecordedOnly] = useState(false);
  let [clocksAndSchedExist, setClocksAndSchedExist] = useState(false);
  let [showSureChangeFocus, setShowSureChangeFocus] = useState(false);
  let [saveable, setSaveable] = useState(false);
  let [submitted, setSubmitted] = useState("");
  let [sureExit, setSureExit] = useState(false);
  let [teamID, setTeamID] = useState("");
  let mobileWidthBreak = 820;
  mobileWidthBreak = 99999999999999;
  const [width, setWidth] = useState(window.innerWidth);
  let [editingEntry, setEditingEntry] = useState("");
  //   output,
  //   startDs: getTimesheet[0].startDs,
  //   endDs: `${getTimesheet[0].endDs.substr(0, 11)}H00M00`,
  //   teamName,
  //   approved: getTimesheet[0].approved,
  //   declined: getTimesheet[0].declined,
  //   decidedBy: getTimesheet[0].decidedBy,
  //   decidedDs: getTimesheet[0].decidedDs,

  let [headerData, setHeaderData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      startDs: "",
      endDs: "",
      teamName: "",
      approved: false,
      declined: false,
      decidedBy: "",
      decidedDs: "",
      declinedReason: "",
    }
  );

  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  //   let [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    // cleaned
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let [dsArr, setDsArr] = useState([]);

  let calcDayDurMins = (ds) => {
    let mins = 0;

    let filt = dsArr.filter((x) => {
      return x.ds === ds;
    });

    if (filt[0] && filt[0].items && filt[0].items[0]) {
      filt[0].items.forEach((item) => {
        let startObj = new Date(
          dateStringer.HHMMToMsToday(item.start, false, ds)
        );
        let endObj = new Date(dateStringer.HHMMToMsToday(item.end, false, ds));

        if (endObj.getTime() <= startObj.getTime()) {
          endObj.setDate(endObj.getDate() + 1);
        }

        let totalMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
        if (typeof parseInt(item.brk) === "number") {
          totalMins -= parseInt(item.brk || 0);
        }

        mins += totalMins;
      });
    }

    let clocksFilt = dsArr.filter((x) => {
      return x.ds === ds;
    });

    if (clocksFilt[0]) {
      clocksFilt[0].clocks.forEach((c) => {
        let startObj = new Date(c.startTs);
        let endObj = new Date(dateStringer.createTimestampFromString(c.endDs));
        let m = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
        if (typeof c.break1durMins && c.break1durMins === "number") {
          m -= c.break1durMins;
        }
        if (typeof c.break2durMins && c.break2durMins === "number") {
          m -= c.break2durMins;
        }
        if (typeof c.break3durMins && c.break3durMins === "number") {
          m -= c.break3durMins;
        }
        mins += m;
      });

      clocksFilt[0].scheduled.forEach((s) => {
        mins += s.payableDurMins;
      });
    }

    return mins;
  };

  let calcTotMins = () => {
    let mins = 0;
    let dsStringArr = [];
    dsArr.forEach((ds) => {
      dsStringArr.push(ds.ds);
    });
    dsStringArr.forEach((ds) => {
      mins += calcDayDurMins(ds) || 0;
    });

    return mins;
  };

  let generateDsArr = dsArr.map((item) => {
    let recordedExist = false;
    item.items.forEach((item) => {
      if (!item.ghostTitle) {
        recordedExist = true;
      }
    });
    return (
      <div
        className={`timesheetDsItem2 ${!isMgr ? "timesheetDsItem2User" : ""} ${
          !item.items[0] && !item.scheduled[0] && !item.clocks[0]
            ? "x398721"
            : ""
        } ${showRecordedOnly && !recordedExist ? "none" : ""} ${
          !isMgr ? "x2938" : ""
        }`}
        onClick={() => {
          console.log({ item });
        }}
      >
        <div className="tsItemHeader">
          {" "}
          <p className={`tsItemTitle`}>
            {dateStringer.printedDateWithYrFromDs(item.ds, true, true, true)}
          </p>{" "}
        </div>

        {item.clocks[0] && !showRecordedOnly ? (
          <div className="tsClocks">
            {/* <p className="tsClocksTitle">Your clocked times</p> */}
            {item.clocks.map((clock) => {
              return (
                <div
                  className={`tsClockItem colourAuto whiteBg_ ${
                    !clock.schedStartDs ? "x972421_" : ""
                  }`}
                >
                  <p className="tsSchedClockType">
                    Clocked{" "}
                    {clock.type === "shift"
                      ? "shift"
                      : clock.type === "til"
                      ? "lieu shift"
                      : "overtime"}
                  </p>

                  {clock.schedStartDs ? (
                    <div
                      className={`tsSchedParent x208420 `}
                      onClick={() => {
                        console.log("Opening shift");
                        if (!isMgr) {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType: clock.schedType,
                              unitID:
                                clock.schedType === "shift"
                                  ? clock.schedShiftID
                                  : clock.schedTilID,
                            };
                          });
                        } else {
                          // open manager shift here
                          memoVals.setOpenManagerShiftModal((x) => {
                            return {
                              shiftID: clock.schedShiftID,
                              tilID: clock.schedTilID,
                              fName: clock.schedFName,
                              lName: clock.schedLName,
                              openedFromShiftsPage: false,
                              type: clock.schedType,
                            };
                          });
                        }
                      }}
                    >
                      <div className="tsClockItemChild">
                        <p className="tsClockOnTitle">Scheduled start</p>
                        <p className="tsClockOnVal">
                          {dateStringer.dsToTimeStrip(clock.schedStartDs, true)}
                        </p>
                      </div>

                      <div className="tsClockItemChild">
                        <p className="tsClockOnTitle">Scheduled finish</p>
                        <p className="tsClockOnVal">
                          {dateStringer.dsToTimeStrip(clock.schedEndDs, true)}
                        </p>
                      </div>

                      <div className="tsClockItemChild">
                        <p className="tsClockOnTitle">Scheduled break</p>
                        <p className="tsClockOnVal">
                          {dateStringer.formatMinsDurationToHours(
                            clock.schedBrk || 0
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    className={`tsSchedParent ${
                      clock.schedStartDs ? "x10830184" : ""
                    } `}
                    onClick={() => {
                      console.log("** ** **");
                      if (isMgr) {
                        // open mgrshift
                        console.log("Opening clock card");
                        memoVals.setClockOnID((x) => {
                          return {
                            clockOnID: clock.clockOnID,
                            isMgr: true,
                          };
                        });
                      } else {
                        memoVals.setClockOnID((x) => {
                          return {
                            clockOnID: clock.clockOnID,
                            isMgr: false,
                          };
                        });
                      }
                    }}
                  >
                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Clocked on</p>
                      <p className="tsClockOnVal">
                        {dateStringer.dsToTimeStrip(clock.startDs, true)}
                      </p>
                    </div>

                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Clocked off</p>
                      <p className="tsClockOnVal">
                        {clock.endDs
                          ? dateStringer.dsToTimeStrip(clock.endDs, true)
                          : "-"}
                      </p>
                    </div>

                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Clocked break</p>
                      <p className="tsClockOnVal">
                        {dateStringer.formatMinsDurationToHours(
                          (clock.break1durMins || 0) +
                            (clock.break2durMins || 0) +
                            (clock.break3durMins || 0)
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`tsApprovalStatusDiv ${
                      !clock.schedStartDs ? "x984292" : ""
                    }`}
                  >
                    <p
                      className={`tsClockStat ${
                        clock.approved ? "x29393883423" : ""
                      } ${clock.schedStartDs ? "" : "x18318341"} ${
                        !clock.approved && !clock.declined
                          ? `pendClockStat ${
                              clock.schedStartDs ? "x2984291" : ""
                            }`
                          : clock.declined
                          ? "declinedClockStat"
                          : ""
                      }`}
                    >
                      {clock.approved
                        ? "Clock card approved"
                        : clock.declined
                        ? "Clock card declined"
                        : "Awaiting approval"}
                    </p>
                    {!clock.approved && !clock.declined && isMgr ? (
                      <p
                        className="approveTsClockBtn"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isMgr) {
                            axios
                              .post(
                                `${serverURL}/approve-clock-card`,
                                { clockOnID: clock.clockOnID },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setReload(!reload);
                                  memoVals.setUpdatedDashData(
                                    (x) => !memoVals.updatedDashData
                                  );
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      >
                        Approve
                      </p>
                    ) : (
                      ""
                    )}
                    {!clock.approved && !clock.declined && isMgr ? (
                      <p
                        className="approveTsClockBtn x197492"
                        onClick={(e) => {
                          e.stopPropagation();

                          if (isMgr) {
                            axios
                              .post(
                                `${serverURL}/decline-clock-card`,
                                { clockOnID: clock.clockOnID },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setReload(!reload);
                                  memoVals.setUpdatedDashData(
                                    (x) => !memoVals.updatedDashData
                                  );
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      >
                        Decline
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  {clock.approved && !clock.declined ? (
                    <p
                      className="viewApprovedTsItem mgrCtaColours"
                      onClick={(e) => {
                        e.stopPropagation();

                        console.log(clock);

                        if (isMgr) {
                          memoVals.setOpenManagerShiftModal((x) => {
                            return {
                              shiftID:
                                clock.type === "shift" ? clock.itemID : "",
                              tilID: clock.type !== "shift" ? clock.itemID : "",
                              fName: fName,
                              lName: lName,
                              openedFromShiftsPage: false,
                              type: clock.type,
                            };
                          });
                        } else {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType:
                                clock.type === "shift" ? "shift" : "til",
                              unitID: clock.itemID,
                            };
                          });
                        }
                      }}
                    >
                      View{" "}
                      {clock.type === "shift"
                        ? "shift"
                        : clock.type === "til"
                        ? "lieu shift"
                        : "overtime"}{" "}
                      <img
                        src={popup}
                        alt="View item"
                        className="viewTsItemPopUp"
                      />
                    </p>
                  ) : (
                    ""
                  )}
                  <p
                    className="viewApprovedTsItem mgrCtaColours"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!clock.schedStartDs) {
                        memoVals.setClockOnID((x) => {
                          return {
                            clockOnID: clock.clockOnID,
                            isMgr: isMgr,
                          };
                        });
                      }
                    }}
                  >
                    View clock card
                    <img
                      src={popup}
                      alt="View item"
                      className="viewTsItemPopUp"
                    />
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {item.scheduled[0] && !showRecordedOnly ? (
          <div className="tsClocks">
            {/* <p className="tsClocksTitle">Your clocked times</p> */}
            {item.scheduled.map((sched) => {
              return (
                <div
                  className={`tsClockItem colourAuto whiteBg_ x972421`}
                  onClick={() => {
                    console.log(sched);
                    if (!isMgr) {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType: sched.shiftID ? "shift" : sched.tilType,
                          unitID: sched.shiftID ? sched.shiftID : sched.tilID,
                        };
                      });
                    } else {
                      // open manager shift here
                      memoVals.setOpenManagerShiftModal((x) => {
                        return {
                          shiftID: sched.shiftID ? sched.shiftID : "",
                          tilID: sched.tilID ? sched.tilID : "",
                          fName: sched.fName,
                          lName: sched.lName,
                          openedFromShiftsPage: false,
                          type: sched.type,
                        };
                      });
                    }
                  }}
                >
                  {!sched.ghostTitle ? (
                    <p className="tsSchedClockType">
                      Scheduled{" "}
                      {sched.shiftID
                        ? "shift"
                        : sched.tilType === "til"
                        ? "lieu shift"
                        : "overtime"}
                    </p>
                  ) : (
                    <p className="tsSchedClockType colorBlue">
                      {sched.ghostTitle}
                    </p>
                  )}
                  {sched.ghostTitle ? (
                    <div className="tsSchedParent">
                      <p className="tsGhostTitle">
                        Closed day, but {sched.fName} is to be paid for{" "}
                        {dateStringer.formatMinsDurationToHours(
                          sched.payableDurMins
                        )}
                        .{" "}
                      </p>
                      <div className="tsSchedItem"> </div>
                    </div>
                  ) : (
                    <div className="tsSchedParent">
                      <div className="tsClockItemChild">
                        <p className="tsClockOnTitle">Scheduled start:</p>
                        <p className="tsClockOnVal">
                          {dateStringer.dsToTimeStrip(sched.startDs, true)}
                        </p>
                      </div>

                      <div className="tsClockItemChild">
                        <p className="tsClockOnTitle">Scheduled end</p>
                        <p className="tsClockOnVal">
                          {sched.endDs
                            ? dateStringer.dsToTimeStrip(sched.endDs, true)
                            : "-"}
                        </p>
                      </div>

                      <div className="tsClockItemChild">
                        <p className="tsClockOnTitle">Scheduled break</p>
                        <p className="tsClockOnVal">
                          {dateStringer.formatMinsDurationToHours(
                            sched.brkMins || 0
                          )}
                        </p>
                      </div>
                      <div className="tsSchedItem"> </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {item.items[0]
          ? item.items.map((rItem, i) => {
              return (
                <div
                  className={`tsClockItem colourAuto whiteBg ${
                    headerData.approved || headerData.declined
                      ? isMgr
                        ? "x29834297"
                        : "x249842"
                      : ""
                  } ${
                    editingEntry === `${item.ds}${i}` ? "editingTsRecord" : ""
                  }`}
                >
                  <p className="tsSchedClockType">
                    Recorded{" "}
                    {editingEntry === `${item.ds}${i}` ? (
                      <select
                        className="tsEditItemTypeDropdown"
                        value={updatedData.type}
                        onChange={(e) => {
                          setUpdatedData({
                            type: e.target.value,
                          });
                        }}
                      >
                        <option value="shift">Shift</option>{" "}
                        <option value="overtime">Overtime</option>
                        <option value="til">Lieu shift</option>
                      </select>
                    ) : rItem.type === "shift" ? (
                      "shift"
                    ) : rItem.type === "til" ? (
                      "lieu shift"
                    ) : (
                      "overtime"
                    )}{" "}
                    {/* {editingEntry === `${item.ds}${i}` ? (
                      <select
                        value={updatedData.type}
                        onChange={(e) => {
                          setUpdatedData({
                            type: e.target.value,
                          });
                        }}
                      >
                        <option value="shift">Shift</option>{" "}
                        <option value="overtime">Overtime</option>
                        <option value="til">Time in lieu </option>
                      </select>
                    ) : (
                      ""
                    )} */}
                  </p>
                  <div className="tsSchedParent">
                    <div className="tsClockItemChild">
                      <p
                        className={`tsClockOnTitle ${
                          editingEntry === `${item.ds}${i}` ? "noBorder" : ""
                        }`}
                      >
                        Recorded start
                      </p>
                      {editingEntry === `${item.ds}${i}` ? (
                        <input
                          type="time"
                          value={updatedData.start}
                          onChange={(e) => {
                            setUpdatedData({ start: e.target.value });
                            setDataEdited(true);
                          }}
                          className="tsAddInputTime x1084"
                        ></input>
                      ) : (
                        <p className="tsClockOnVal">{rItem.start}</p>
                      )}
                    </div>

                    <div className="tsClockItemChild">
                      <p
                        className={`tsClockOnTitle ${
                          editingEntry === `${item.ds}${i}` ? "noBorder" : ""
                        }`}
                      >
                        Recorded finish
                      </p>
                      {editingEntry === `${item.ds}${i}` ? (
                        <input
                          type="time"
                          value={updatedData.end}
                          onChange={(e) => {
                            setUpdatedData({ end: e.target.value });
                            setDataEdited(true);
                          }}
                          className="tsAddInputTime x1084"
                        ></input>
                      ) : (
                        <p className="tsClockOnVal">{rItem.end}</p>
                      )}{" "}
                    </div>

                    <div className="tsClockItemChild">
                      <p
                        className={`tsClockOnTitle ${
                          editingEntry === `${item.ds}${i}` ? "noBorder" : ""
                        }`}
                      >
                        Recorded break
                      </p>
                      {editingEntry === `${item.ds}${i}` ? (
                        <div className="tsEditBrkFlexDiv">
                          <input
                            type="number"
                            value={updatedData.break}
                            onChange={(e) => {
                              setDataEdited(true);

                              setUpdatedData({ break: e.target.value });
                            }}
                            className="tsAddInputTime x38713"
                          ></input>
                          <p className="x1381314">m</p>
                        </div>
                      ) : (
                        <p className="tsClockOnVal">
                          {dateStringer.formatMinsDurationToHours(rItem.brk)}
                        </p>
                      )}
                    </div>
                    {isMgr && !headerData.approved && !headerData.declined ? (
                      editingEntry === `${item.ds}${i}` ? (
                        <div className="editingEntryEditDiv">
                          {/* <img
                            src={whiteTick}
                            alt="Edit times"
                            className="saveEditingEntryBtn"
                            onClick={() => {
                              setEditingEntry("");
                            }}
                          /> */}
                          <p
                            className="saveEditingEntryBtn"
                            onClick={() => {
                              //  axios here
                              axios
                                .post(
                                  `${serverURL}/edit-ts-item`,
                                  {
                                    timesheetID:
                                      memoVals.showViewTimesheet.timesheetID,
                                    ds: item.ds,
                                    itemNum: i,
                                    newStart: updatedData.start,
                                    newEnd: updatedData.end,
                                    newBrk: parseInt(updatedData.break) || 0,
                                    newType: updatedData.type || "shift",
                                    newTags: updatedData.tags || [],
                                    mgrNote: updatedData.mgrNote || "",
                                    newRate: parseFloat(updatedData.rate || 1),
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setReload(!reload);
                                    memoVals.setUpdatedDashData(
                                      (x) => !memoVals.updatedDashData
                                    );
                                    setEditingEntry("");
                                    setDataEdited(false);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Save
                          </p>
                          <p
                            className="saveEditingEntryBtn greyColoursTsBtn"
                            onClick={() => {
                              setUpdatedData({
                                start: "",
                                end: "",
                                break: "",
                                type: "",
                                tags: [],
                                note: "",
                                rate: 1,
                              });
                              setEditingEntry("");
                              setDataEdited(false);
                            }}
                          >
                            Cancel
                          </p>

                          {/* <img
                            src={crossWhite}
                            alt="Edit times"
                            className="saveEditingEntryBtn greyColours"
                            onClick={() => {
                              setUpdatedData({
                                start: "",
                                end: "",
                                break: "",
                                type: "",
                                tags: [],
                                note: "",
                              });
                              setEditingEntry("");
                            }}
                          /> */}
                        </div>
                      ) : (
                        <img
                          src={greyEdit}
                          alt="Edit times"
                          className="viewTsEditTimeimg"
                          onClick={() => {
                            if (editingEntry && dataEdited) {
                              setShowSureChangeFocus(`${item.ds}${i}`);
                            } else {
                              setUpdatedData({
                                start: rItem.start,
                                end: rItem.end,
                                break: rItem.brk,
                                type: rItem.type,
                                tags: rItem.tags || [],
                                note: rItem.note,
                                mgrNote: rItem.mgrNote,
                                rate: rItem.rate,
                              });
                              setEditingEntry(`${item.ds}${i}`);
                            }
                          }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div>

                  <p className="tsItemDurMins">
                    Recorded duration: <br />
                    <span className="recDurTxt">
                      {dateStringer.formatMinsDurationToHours(
                        calcDurMins(rItem.start, rItem.end, rItem.brk)
                      )}
                    </span>
                  </p>

                  {rItem.tags[0] ||
                  (editingEntry === `${item.ds}${i}` && updatedData.tags[0]) ? (
                    <div className="tsInlineTags">
                      {editingEntry === `${item.ds}${i}`
                        ? updatedData.tags.map((tag) => {
                            return (
                              <p className="tsInlineTagItem editinTsTsBorder">
                                {tag}{" "}
                                <img
                                  src={navyClose}
                                  alt="Remove tag"
                                  className="removeTsTagBtnImg"
                                  onClick={() => {
                                    setDataEdited(true);

                                    let newArr = [];
                                    updatedData.tags.forEach((t) => {
                                      if (t !== tag) {
                                        newArr.push(t);
                                      }
                                    });
                                    setUpdatedData({
                                      tags: newArr,
                                    });
                                  }}
                                />
                              </p>
                            );
                          })
                        : rItem.tags.map((tag) => {
                            return (
                              <p className="tsInlineTagItem x1411415">{tag}</p>
                            );
                          })}
                    </div>
                  ) : (
                    <div className="noTagsTsWrap">
                      <p className="noTsTagstxt">No tags</p>

                      {/* {editingEntry === `${item.ds}${i}` && !updatedData.tags[0]
                        ? updatedData.tags.map((tag) => {
                            return (
                              <select
                                className="addTagToTsItemDropdown"
                                onChange={(e) => {
                                  if (e.target.value !== "-") {
                                    setUpdatedData({
                                      tags: [
                                        ...updatedData.tags,
                                        e.target.value,
                                      ],
                                    });
                                  }
                                }}
                              >
                                <option value="-">Add tag</option>
                                {availableTags.map((item) => {
                                  return <option value={item}>{item}</option>;
                                })}
                              </select>
                            );
                          })
                        : ""} */}
                    </div>
                  )}

                  <div className="tsRateContainer">
                    <p
                      className={`tsClockOnTitle tsRateTitle ${
                        editingEntry === `${item.ds}${i}` ? "noBorder" : ""
                      }`}
                    >
                      Pay rate
                    </p>
                    {editingEntry === `${item.ds}${i}` ? (
                      <select
                        className="addTagToTsItemDropdown"
                        value={updatedData.rate}
                        onChange={(e) => {
                          setUpdatedData({
                            rate: e.target.value,
                          });
                          setDataEdited(true);
                        }}
                      >
                        <option value="0">0x</option>

                        <option value="0.25">0.25x</option>

                        <option value="0.5">0.5x</option>

                        <option value="0.75">0.75x</option>

                        <option value="1">1x</option>

                        <option value="1.5">1.5x</option>
                        <option value="1.75">1.75x</option>
                        <option value="2">2x</option>
                        <option value="2.25">2.25x</option>
                        <option value="2.5">2.5x</option>
                        <option value="2.75">2.75x</option>
                        <option value="3">3x</option>
                        <option value="3.25">3.25x</option>
                        <option value="3.5">3.5x</option>
                        <option value="3.75">3.75x</option>
                        <option value="4">4x</option>
                      </select>
                    ) : rItem.type === "til" ? (
                      <p className="tsRateVal unpaidTilDur">
                        Unpaid. Duration to be added to TOIL balance.
                      </p>
                    ) : (
                      <p className="tsRateVal">{rItem.rate}x</p>
                    )}
                  </div>
                  {editingEntry === `${item.ds}${i}` ? (
                    <select
                      className="addTagToTsItemDropdown"
                      value="-"
                      onChange={(e) => {
                        if (
                          e.target.value !== "-" &&
                          e.target.value !== "_create"
                        ) {
                          setUpdatedData({
                            tags: [...updatedData.tags, e.target.value],
                          });
                          setDataEdited(true);
                        }
                        if (e.target.value === "_create") {
                          setShowCreateNewTag(`${item.ds}${i}`);
                          setDataEdited(true);
                        }
                      }}
                    >
                      <option value="-">Add tag</option>
                      {availableTags.map((item) => {
                        if (!updatedData.tags.includes(item)) {
                          return <option value={item}>{item}</option>;
                        }
                      })}
                      <option value="_create">-- New tag</option>
                    </select>
                  ) : (
                    ""
                  )}

                  {editingEntry === `${item.ds}${i}` &&
                  showCreateNewTag === `${item.ds}${i}` ? (
                    <div className="addingTsTagDiv">
                      <input
                        type="text"
                        className="tsCreateTagInput"
                        value={newTagValue}
                        onChange={(e) => {
                          setDataEdited(true);

                          setNewTagValue(e.target.value);
                        }}
                      />
                      <p
                        className="addTagTsAddBtn"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/add-tag-to-bank`,
                              {
                                teamID: teamID,
                                tag: newTagValue,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setUpdatedData({
                                  tags: [...updatedData.tags, newTagValue],
                                });
                                setShowCreateNewTag("");
                                setNewTagValue("");
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Add
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {rItem.note ? (
                    <div className="viewTsItemNote">
                      <span className="x2849242 x2849242">
                        {isMgr ? dateStringer.possession(fName) : "My"} note:
                      </span>

                      {rItem.note}
                    </div>
                  ) : (
                    ""
                  )}
                  {editingEntry === `${item.ds}${i}` ? (
                    <p className="tsYourNote">Your note:</p>
                  ) : (
                    ""
                  )}
                  {editingEntry === `${item.ds}${i}` ? (
                    <input
                      type="text"
                      className="mgrTsNoteInput"
                      value={updatedData.mgrNote}
                      onChange={(e) => {
                        setDataEdited(true);

                        setUpdatedData({
                          mgrNote: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {rItem.mgrNote && editingEntry !== `${item.ds}${i}` ? (
                    <div className="viewTsItemNote">
                      <span className="x10813111 x2849242">
                        {rItem.mgrNoteName
                          ? dateStringer.possession(rItem.mgrNoteName)
                          : "Manager"}{" "}
                        note:
                      </span>

                      {rItem.mgrNote}
                    </div>
                  ) : (
                    ""
                  )}

                  {headerData.approved && !headerData.declined ? (
                    <p
                      className="viewApprovedTsItem mgrCtaColours"
                      onClick={() => {
                        console.log({ isMgr });
                        if (isMgr) {
                          memoVals.setOpenManagerShiftModal((x) => {
                            return {
                              shiftID:
                                rItem.type === "shift" ? rItem.itemID : "",
                              tilID: rItem.type !== "shift" ? rItem.itemID : "",
                              fName: fName,
                              lName: lName,
                              openedFromShiftsPage: false,
                              type: rItem.type,
                            };
                          });
                        } else {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType:
                                rItem.type === "shift" ? "shift" : "til",
                              unitID: rItem.itemID,
                            };
                          });
                        }
                      }}
                    >
                      View{" "}
                      {rItem.type === "shift"
                        ? "shift"
                        : rItem.type === "til"
                        ? "lieu shift"
                        : "overtime"}{" "}
                      <img
                        src={popup}
                        alt="View item"
                        className="viewTsItemPopUp"
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          : ""}

        {!item.items[0] && !item.scheduled[0] && !item.clocks[0] ? (
          <p className="viewTsNoItems">No recorded items</p>
        ) : (
          ""
        )}
      </div>
    );
  });

  let [reload, setReload] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (memoVals.showViewTimesheet.timesheetID) {
      axios
        .post(
          `${serverURL}/open-timesheet`,
          {
            timesheetID: memoVals.showViewTimesheet.timesheetID, // "new" is new one needs to be made
            isMgr: isMgr,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setHeaderData({
              startDs: response.data.startDs,
              fName: response.data.fName,
              endDs: response.data.endDs,
              teamName: response.data.teamName,
              approved: response.data.approved,
              declined: response.data.declined,
              decidedBy: response.data.decidedByName,
              decidedDs: response.data.decidedDs,
              userID: response.data.userID,
            });
            if (!response.data.output[0]) {
              setDsArr([{ ds: "", items: [], clocks: [], scheduled: [] }]);
              setClocksAndSchedExist(false);
            } else {
              setDsArr(response.data.output || []);
              let clocks = 0;
              let sched = 0;
              response.data.output.forEach((dsObj) => {
                if (dsObj.clocks) {
                  dsObj.clocks.forEach((c) => {
                    clocks++;
                  });
                }

                if (dsObj.scheduled) {
                  dsObj.scheduled.forEach((c) => {
                    sched++;
                  });
                }
              });
              if (clocks || sched) {
                setClocksAndSchedExist(true);
              }
            }
            setSubmitted(response.data.submitted || "");
            if (isMgr) {
              setFName(response.data.fName);
              setLName(response.data.lName);
            }
            setAvailableTags(response.data.availableTags);
            setTeamID(response.data.teamID);
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [reload, memoVals.reloadTs]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      if (saveable) {
        setSureExit(true);
      } else {
        memoVals.setShowViewTimesheet((x) => {
          return { show: false };
        });
      }
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`bulkEditLimitUnderlay ${
        memoVals.showMyShiftModal.unitID ? "none" : ""
      }`}
      onClick={() => {
        if (saveable) {
          setSureExit(true);
        } else {
          memoVals.setShowViewTimesheet((x) => {
            return { show: false };
          });
        }
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`availModalBox ${
            mobModal || memoVals.device.vhIssue ? "x13892843" : ""
          } ${
            mobModal ? "availModalBoxMob mobModalShoulder" : ""
          } timesheetModalBox ${isMgr ? "mgrModal" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {loading || !dsArr[0] ? (
            <img
              src={horizontalBalls}
              alt="Loading"
              className="loadingTimesheetImg"
              onClick={() => {
                console.log({ loading, dsArr });
              }}
            />
          ) : (
            ""
          )}

          {mobModal && !loading && dsArr[0] && (
            <div className="modalSwiper modalSwiperViewTs"></div>
          )}
          {!loading && dsArr[0] ? (
            <div
              className="bulkEditLimitModalHeader"
              onTouchStart={handleDown}
              onTouchMove={mobModal ? handleMove : null}
              onTouchEnd={mobModal ? handleUp : null}
              onMouseDown={mobModal ? handleDown : null}
              onMouseMove={mobModal ? handleMove : null}
              onMouseUp={mobModal ? handleUp : null}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon"
                onClick={() => {
                  if (saveable) {
                    setSureExit(true);
                  } else {
                    memoVals.setShowViewTimesheet((x) => {
                      return { show: false };
                    });
                  }
                }}
              />
              <p
                className={`bulkEditLimitModalHeaderTitle ${
                  loading ? "none" : ""
                }`}
                onClick={() => {
                  if (mob) {
                    if (saveable) {
                      setSureExit(true);
                    } else {
                      memoVals.setShowViewTimesheet((x) => {
                        return { show: false };
                      });
                    }
                  }
                }}
              >
                {isMgr
                  ? `${dateStringer.possession(fName)} timesheet`
                  : `My timesheet`}
              </p>
              <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
              />{" "}
            </div>
          ) : (
            ""
          )}

          <div
            className={`${
              width > mobileWidthBreak
                ? "timesheetBoxContainerWide"
                : "timesheetBoxContainerMob"
            }`}
          >
            {loading || !dsArr[0] ? (
              ""
            ) : (
              // <img
              //   src={horizontalBalls}
              //   alt="Loading"
              //   className="loadingTsHorizBalls"
              // />
              <div
                className={`timesheetDataHeader ${
                  isMgr
                    ? `timesheetDataHeaderMgr ${
                        headerData.declined
                          ? "declinedTsHeaderColours"
                          : headerData.approved
                          ? "approvedTsHeaderColours"
                          : ""
                      } `
                    : headerData.declined
                    ? "declinedTsHeaderColours"
                    : headerData.approved
                    ? "approvedTsHeaderColours"
                    : ""
                }`}
              >
                {isMgr ? (
                  <div className="tsDataHeaderRow">
                    <p className="tsDataHeaderTitle">Employee</p>
                    <p
                      className="tsDataHeaderValue cursorPointer"
                      onClick={() => {
                        memoVals.setShowEditUser((x) => headerData.userID);
                      }}
                    >
                      {fName} {lName}{" "}
                      <img
                        src={popup}
                        alt="View item"
                        className="viewTsItemPopUp viewEmpTsPopup"
                      />
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {isMgr ? <div className="tsDataHeaderRowSplitter"></div> : ""}
                <div className="tsDataHeaderRow">
                  <p className="tsDataHeaderTitle">Timesheet period</p>
                  <p className="tsDataHeaderValue">
                    {dateStringer.createDatePeriod(
                      headerData.startDs,
                      headerData.endDs
                    )}
                  </p>
                </div>
                <div className="tsDataHeaderRowSplitter"></div>
                <div className="tsDataHeaderRow">
                  <p className="tsDataHeaderTitle">Team</p>
                  <p className="tsDataHeaderValue">
                    {dateStringer.shorten(headerData.teamName, 25)}
                  </p>
                </div>
                <div className="tsDataHeaderRowSplitter"></div>
                <div className="tsDataHeaderRow">
                  <p className="tsDataHeaderTitle">Recorded hours</p>
                  <p className="tsDataHeaderValue">
                    {dateStringer.formatMinsDurationToHours(calcTotMins())}
                  </p>
                </div>{" "}
                <div className="tsDataHeaderRowSplitter"></div>
                <div className="tsDataHeaderRow">
                  <p className="tsDataHeaderTitle">Status</p>
                  <p className="tsDataHeaderValue">
                    {headerData.approved
                      ? "Approved"
                      : headerData.declined
                      ? "Declined"
                      : "Not yet approved"}
                  </p>
                </div>
              </div>
            )}
            {/* <div
            className={`availUnit1 ${
              width <= mobileWidthBreak
                ? "availUnit1MobOnly marginTopMinus12"
                : ""
            } ${loading ? "none" : ""}`}
          >

            <div className="addAvailSelectRow timesheetSelectorRow x28291">
              <p className="selectDateRange">Employee</p>
              <p className="timesheetPeriodDates">Jamie Butler</p>
            </div>
            <div className="addAvailSelectRow timesheetSelectorRow x28291">
              <p className="selectDateRange">Timesheet period</p>
              <p className="timesheetPeriodDates">12 Jul - 14 Jul 2023</p>
            </div>
          </div> */}
          </div>

          {!loading && clocksAndSchedExist ? (
            <div
              className="showRecordedBtnDiv"
              onClick={() => {
                setShowRecordedOnly(!showRecordedOnly);
              }}
            >
              <div className="showRecLeft">
                <p className="showRecordOnlyBtnTxt">
                  Show timesheet items only
                </p>
                <p className="showRecordOnlyBtnTxtSub">
                  Hide clocked and scheduled shifts created outside of the
                  timesheet.
                </p>
              </div>
              <div className="showRecRight">
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer recordOnlyKnob"
                  onClick={() => {
                    // fire allow swaps request here
                  }}
                >
                  <div
                    className={`switchKnob ${
                      showRecordedOnly ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          ) : (
            ""
          )}
          {loading ? (
            ""
          ) : (
            <div className="timesheetBody viewTsBorder">
              {!loading ? generateDsArr : ""}{" "}
            </div>
          )}
          <div
            className={`availModalFooter ${loading || !dsArr[0] ? "none" : ""}`}
          >
            {isMgr ? (
              <div className="mgrTsDecideBtnsContainer">
                {!headerData.approved && !headerData.declined ? (
                  <p
                    className="approveClockBtn"
                    onClick={() => {
                      if (editingEntry && dataEdited) {
                        setShowSureChangeFocus(true);
                      } else {
                        // approve ts here --
                        // NOTE! Also do this in the showSure modal at the bottom x198191
                        axios
                          .post(
                            `${serverURL}/approve-ts`,
                            {
                              timesheetID:
                                memoVals.showViewTimesheet.timesheetID,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              console.log(response.data);
                              // setTimeout(() => {
                              setReload(!reload);
                              memoVals.setUpdatedDashData(
                                (x) => !memoVals.updatedDashData
                              );
                              console.log("APPROVING TS 1");
                              memoVals.setUpdateUserTimesheets(
                                (x) => !memoVals.updateUserTimesheets
                              );

                              memoVals.setIndicate((x) => {
                                return {
                                  show: true,
                                  message: `Approved ${dateStringer.possession(
                                    fName
                                  )} timesheet`,
                                  colour: "green",
                                  duration: 4000,
                                };
                              });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Approve{" "}
                    <img
                      src={whiteTick}
                      alt="Approve"
                      className="approveClockTickImg"
                    />
                  </p>
                ) : (
                  ""
                )}
                {!headerData.approved &&
                !headerData.declined &&
                !editDecision ? (
                  <p
                    className="declineClockBtn"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/decline-ts`,
                          {
                            timesheetID: memoVals.showViewTimesheet.timesheetID,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },
                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setHeaderData({
                              declined: true,
                              approved: false,
                              decidedBy: response.data.decidedBy,
                              declinedReason: response.data.declinedReason,
                              deciedDs: response.data.decidedDs,
                            });
                            setReload(!reload);
                            memoVals.setUpdatedDashData(
                              (x) => !memoVals.updatedDashData
                            );
                            memoVals.setUpdateUserTimesheets(
                              (x) => !memoVals.updateUserTimesheets
                            );

                            // setDeclined(true);
                            // setApproved(false);
                            // setDecidedBy(response.data.decidedBy);
                            // setDeclinedReason(response.data.declinedReason);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Decline{" "}
                    <img
                      src={crossWhite}
                      alt="Approve"
                      className="approveClockTickImg x34981"
                    />
                  </p>
                ) : (
                  ""
                )}

                {
                  headerData.declined || headerData.approved ? (
                    <div className="deleteAndShiftHolder">
                      <p
                        className={`deleteShiftBtn deleteClockBtn width38px`}
                        onClick={() => {
                          setSureDeleteMgr(true);
                        }}
                      >
                        <img
                          src={whitebin}
                          className="clockWhiteBin"
                          alt="Delete"
                        />
                        {/* Delete */}
                      </p>

                      {!editDecision ? (
                        <p
                          className="editClockDecisionBtn"
                          onClick={() => {
                            setEditDecision(true);
                          }}
                        >
                          <img
                            src={editReq}
                            alt="Edit"
                            className="editClockDecImg"
                          />
                        </p>
                      ) : (
                        ""
                      )}

                      {editDecision ? (
                        <p
                          className="backEditDecision"
                          onClick={() => {
                            setEditDecision(false);
                          }}
                        >
                          <img
                            src={returnArrow}
                            alt="Back"
                            className="backeditdecisionImg"
                          />
                        </p>
                      ) : (
                        ""
                      )}

                      {editDecision && headerData.declined ? (
                        <p
                          className="approveClockBtn"
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/approve-ts`,
                                {
                                  timesheetID:
                                    memoVals.showViewTimesheet.timesheetID,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },
                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  // setTimeout(() => {
                                  setReload(!reload);
                                  memoVals.setUpdatedDashData(
                                    (x) => !memoVals.updatedDashData
                                  );
                                  console.log("APPROVING TS 2");

                                  memoVals.setUpdateUserTimesheets(
                                    (x) => !memoVals.updateUserTimesheets
                                  );

                                  memoVals.setIndicate((x) => {
                                    return {
                                      show: true,
                                      message: `Approved ${dateStringer.possession(
                                        fName
                                      )} timesheet`,
                                      colour: "green",
                                      duration: 4000,
                                    };
                                  });
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                            setEditDecision(false);
                          }}
                        >
                          Approve{" "}
                          <img
                            src={whiteTick}
                            alt="Approve"
                            className="approveClockTickImg"
                          />
                        </p>
                      ) : (
                        ""
                      )}

                      {editDecision && headerData.approved ? (
                        <p
                          className="declineClockBtn"
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/decline-ts`,
                                {
                                  timesheetID:
                                    memoVals.showViewTimesheet.timesheetID,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },
                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setHeaderData({
                                    declined: true,
                                    approved: false,
                                    decidedBy: response.data.decidedBy,
                                    declinedReason:
                                      response.data.declinedReason,
                                    deciedDs: response.data.decidedDs,
                                  });
                                  setReload(!reload);
                                  memoVals.setUpdatedDashData(
                                    (x) => !memoVals.updatedDashData
                                  );
                                  memoVals.setUpdateUserTimesheets(
                                    (x) => !memoVals.updateUserTimesheets
                                  );

                                  // setDeclined(true);
                                  // setApproved(false);
                                  // setDecidedBy(response.data.decidedBy);
                                  // setDeclinedReason(response.data.declinedReason);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                            setEditDecision(false);
                          }}
                        >
                          Decline{" "}
                          <img
                            src={crossWhite}
                            alt="Approve"
                            className="approveClockTickImg x34981"
                          />
                        </p>
                      ) : (
                        ""
                      )}

                      {!editDecision ? (
                        <p
                          className="tsSubmittedTs x140814"
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/decline-ts`,
                                {
                                  timesheetID:
                                    memoVals.showViewTimesheet.timesheetID,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },
                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setHeaderData({
                                    declined: true,
                                    approved: false,
                                    decidedBy: response.data.decidedBy,
                                    declinedReason:
                                      response.data.declinedReason,
                                    deciedDs: response.data.decidedDs,
                                  });
                                  setReload(!reload);
                                  memoVals.setUpdatedDashData(
                                    (x) => !memoVals.updatedDashData
                                  );
                                  memoVals.setUpdateUserTimesheets(
                                    (x) => !memoVals.updateUserTimesheets
                                  );

                                  // setDeclined(true);
                                  // setApproved(false);
                                  // setDecidedBy(response.data.decidedBy);
                                  // setDeclinedReason(response.data.declinedReason);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          <span className="tsSubmittedTsTitleSpan">
                            {headerData.approved ? "Approved" : "Declined"} by
                          </span>
                          <br />
                          {headerData.decidedBy}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <p className="tsSubmittedTs x23256786">
                      <span className="tsSubmittedTsTitleSpan">Submitted</span>
                      <br />
                      {dateStringer.printedDateFromDs(submitted)}
                    </p>
                  )

                  // (
                  //   <p className="tsSubmittedTs x140814">
                  //     <span className="tsSubmittedTsTitleSpan">Declined by</span>
                  //     <br />
                  //     {headerData.decidedBy}
                  //   </p>
                  // ) : (
                  //   <p className="tsSubmittedTs">
                  //     <span className="tsSubmittedTsTitleSpan">Submitted</span>
                  //     <br />
                  //     {dateStringer.printedDateFromDs(submitted)}
                  //   </p>
                  // )
                }
              </div>
            ) : (
              <div className="availSaveAndDeleteContainer">
                {/* {changesMade ? (
              <p className="submitTsBtn" onClick={() => {}}>
                Submit
              </p>
            ) : (
              ""
            )} */}

                {!headerData.approved && !headerData.declined ? (
                  <p
                    className={`saveTsBtn ${submitted ? "marginLeft0" : ""}`}
                    onClick={() => {
                      memoVals.setShowTimesheet((x) => {
                        return {
                          timesheetID: memoVals.showViewTimesheet.timesheetID,
                          openViewTimesheetAfterClose: true,
                        };
                      });
                      memoVals.setShowViewTimesheet((x) => {
                        return { show: false };
                      });
                    }}
                  >
                    Amend
                  </p>
                ) : (
                  ""
                )}

                <p
                  className={`deleteAvailBtn deleteTsBtn  ${
                    submitted ||
                    headerData.declined ||
                    (!headerData.approved && !headerData.declined)
                      ? "marginLeft0"
                      : ""
                  }`}
                  onClick={() => {
                    setSureDelete(true);
                  }}
                >
                  Delete
                </p>

                {submitted ? (
                  <p className="tsSubmittedTs">
                    <span className="tsSubmittedTsTitleSpan">Submitted</span>
                    <br />
                    {dateStringer.printedDateFromDs(submitted)}
                  </p>
                ) : (
                  ""
                )}
              </div>
            )}

            <p
              className="cancelBulkModalBtn"
              onClick={() => {
                if (saveable) {
                  setSureExit(true);
                } else {
                  memoVals.setShowViewTimesheet((x) => {
                    return { show: false };
                  });
                }
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {sureExit ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureExit(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              You have unsaved changes.
              <br />
              <br />
              Are you sure you want to exit this timesheet?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  memoVals.setShowViewTimesheet((x) => {
                    return { show: false };
                  });
                }}
              >
                Exit{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureExit(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDelete ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureDelete(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you sure you want to delete this timesheet?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-timesheet`,
                      {
                        timesheetID: memoVals.showViewTimesheet.timesheetID, // "new" is new one needs to be made
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setShowViewTimesheet((x) => {
                          return { show: false };
                        });
                        memoVals.setUpdateUserTimesheets(
                          (x) => !memoVals.updateUserTimesheets
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDelete(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showSureChangeFocus ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setShowRequestAllModal(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You haven't saved the last recorded item you were amending.
              <br />
              <br />
              {typeof showSureChangeFocus === "string"
                ? "Are you sure you want to discard any changes?"
                : "Are you sure you want to approve this timesheet without saving any changes?"}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  if (typeof showSureChangeFocus === "string") {
                    setEditingEntry(showSureChangeFocus);
                    setShowSureChangeFocus(false);
                    setDataEdited(false);
                  } else {
                    // approve request here x198191
                    axios
                      .post(
                        `${serverURL}/approve-ts`,
                        {
                          timesheetID: memoVals.showViewTimesheet.timesheetID,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          console.log(response.data);
                          // setTimeout(() => {

                          memoVals.setUpdateUserTimesheets(
                            (x) => !memoVals.updateUserTimesheets
                          );
                          console.log("APPROVING TS 3");

                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `Approved ${dateStringer.possession(
                                fName
                              )} timesheet`,
                              colour: "green",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });

                    setShowSureChangeFocus(false);
                    setEditingEntry("");
                    setDataEdited(false);
                  }
                }}
              >
                Yes -{" "}
                {typeof showSureChangeFocus !== "string"
                  ? "Approve"
                  : "Discard"}
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowSureChangeFocus(false);
                  setDataEdited(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDeleteMgr ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setSureDelete(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to delete this timesheet?
              {clocksAndSchedExist ? <br /> : ""}{" "}
              {clocksAndSchedExist ? <br /> : ""}
              {clocksAndSchedExist
                ? "Any scheduled shifts and shifts created from clock cards will not be removed."
                : ""}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-timesheet`,
                      {
                        timesheetID: memoVals.showViewTimesheet.timesheetID, // "new" is new one needs to be made
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setShowViewTimesheet((x) => {
                          return { show: false };
                        });
                        memoVals.setUpdateUserTimesheets(
                          (x) => !memoVals.updateUserTimesheets
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes - Delete
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteMgr(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default ViewTimesheetModal;
